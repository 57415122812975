export class AttributeFieldPath {
  public get path(): string | null {
    return this._path ?? null;
  }

  constructor(private _path: string | null = null) {}

  public nextLevel() {
    const newPath = new AttributeFieldPath();
    newPath._path = this._path != null ? this._path + '.0' : '0';
    return newPath;
  }

  public increaseLevel(levels?: number): AttributeFieldPath {
    if (this._path == null) {
      this._path = '0';

      if (levels == null) {
        return this;
      }

      if (levels !== 0) {
        levels--;
      }

      if (levels === 0) {
        return this;
      }
    }

    const parts = this._path.split('.');

    const last = parts[parts.length - 1];

    const lastNumber = parseInt(last);

    parts[parts.length - 1] = (lastNumber + (levels != null ? levels : 1)).toString();

    return new AttributeFieldPath(parts.join('.'));
  }
}
