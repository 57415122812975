<template>
  <div class="grid">
    <div v-for="i in 12" :key="i" class="col-12 lg:col-3 mb-2 md:col-4 p-1">
      <div class="p-3 shadow-1">
        <p-skeleton width="100%" height="150px" class="mb-2" />
        <div class="align-items-center flex justify-content-between mb-3">
          <div class="mr-3 w-full">
            <p-skeleton width="100%" class="mb-2" />
            <p-skeleton width="80%" />
          </div>
          <p-skeleton shape="circle" size="3rem" class="flex-shrink-0 mr-2" />
        </div>
        <p-skeleton width="100%" height="2px" class="mb-3" />
        <p-skeleton width="30%" class="mb-2" />
        <p-skeleton width="100%" height="1.5rem" border-radius="2rem" class="mb-2" />
      </div>
    </div>
  </div>
</template>
