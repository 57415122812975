import { ToastServiceMethods } from 'primevue/toastservice';
import { unref, Ref } from 'vue';
import { ComposerTranslation } from 'vue-i18n';
import { Store } from 'vuex';
import {
  DispatchData,
  DispatchSuccess,
  DispatchReject,
  DisplayToastSetting,
} from '@/general/composables/UseDispatch/types';

//TODO
export function displayErrorToast<T>(
  t: ComposerTranslation,
  toast: ToastServiceMethods,
  dispatchData: DispatchData<T>,
  errorMessage: string,
) {
  if (
    dispatchData.toast?.displaySetting == DisplayToastSetting.BOTH ||
    dispatchData.toast?.displaySetting == DisplayToastSetting.REJECT_ONLY
  ) {
    toast.add({
      severity: 'error',
      summary: t('errors.title'),
      detail: !dispatchData.toast.rejectMessage ? errorMessage : dispatchData.toast.rejectMessage,
      life: 3000,
    });
  }
}

export function displaySuccessToast<T>(
  t: ComposerTranslation,
  toast: ToastServiceMethods,
  dispatchData: DispatchData<T>,
) {
  if (
    dispatchData.toast?.displaySetting == DisplayToastSetting.BOTH ||
    dispatchData.toast?.displaySetting == DisplayToastSetting.SUCCESS_ONLY
  ) {
    toast.add({
      severity: !dispatchData.toast.successMessage ? 'error' : 'success',
      summary: !dispatchData.toast.successMessage ? t('global.warning') : t('success.title'),
      detail: !dispatchData.toast.successMessage
        ? t('errors.no_message')
        : dispatchData.toast.successMessage,
      life: 3000,
    });
  }
}

export async function handleDispatch<T>(
  store: Store<any>,
  action: string,
  dispatchData: DispatchData<T>,
): Promise<T> {
  let data;
  dispatchData.pagination
    ? (data = await dispatchPagination(store, action, dispatchData.parameters, {
        page: dispatchData.page!,
        perPage: dispatchData.perPage!,
      }))
    : (data = await store.dispatch(action, dispatchData.parameters));

  return data;
}

export async function dispatchPagination(
  store: Store<any>,
  action: string,
  params: any = {},
  { page, perPage }: { page: number | string; perPage: number | string },
) {
  params.page = unref(page);
  params.limit = unref(perPage);
  return await store.dispatch(action, params);
}

export function toggleLoading(loading: Ref<boolean>, value?: boolean) {
  if (value != null) {
    loading.value = value;
  } else {
    loading.value = !loading.value;
  }
}

export function handleSuccess<T>(dispatchData: DispatchData<T>, data: T | T[]): void {
  if (dispatchData.successCallback) {
    dispatchData.successCallback({
      passed: true,
      data: data,
    } as DispatchSuccess<T>);
  }
}

export function handleReject<T>(dispatchData: DispatchData<T>, err: any): void {
  if (dispatchData.rejectCallback) {
    dispatchData.rejectCallback({
      passed: false,
      message: err.message,
      response: err.response,
    } as DispatchReject);
  }
}
