export enum ModuleComponentTypes {
  TextArea = 'text_area',
  Text = 'text',
  Image = 'image',
  ImageTextBlock = 'image_text_block',
  ImageTextCaption = 'image_text_caption',
  StandardHeaderTextListBlock = 'standard_header_text_list_block',
  StandardTextBlock = 'standard_text_block',
  StandardTextListBlock = 'standard_text_list_block',
  TextItem = 'text_item',
}
