import { Product } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

export default class AttachAssetsService {
  visible = false;
  loading = false;
  checkedProducts: Product[] = [];
  private _ts: TranslationService;
  private _store;
  constructor(ts: TranslationService, store: any) {
    this._ts = ts;
    this._store = store;
  }

  public completeAttachAssets(checkedAssets: Upload[]) {
    this.loading = true;
    this._store
      .dispatch('products/ATTACH_MANY', {
        productIds: this.checkedProducts.map((product: Product) => product.id),
        uploadIds: checkedAssets.map((asset) => asset.id),
      })
      .then(() =>
        ToastService.getInstance().displaySuccessToast(
          this._ts.tModule(`attach_media_dialog.success`),
        ),
      )
      .catch(() =>
        ToastService.getInstance().displayErrorToast(this._ts.tModule('attach_media_dialog.error')),
      )
      .finally(() => {
        this.loading = false;
        this.visible = false;
      });
  }

  public toggleVisible() {
    this.visible = !this.visible;
    this.checkedProducts = [];
  }

  public checkMultipleProducts(products: Product[]) {
    this.checkedProducts = products;
  }
}
