<script setup lang="ts">
import { FeaturePermission } from 'platform-unit2-api/roles';
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { authorizationMethods } from 'composables/authorization.plugin';
import { useRoute } from 'vue-router';
import navigationSkeleton from '@/general/ui/components/skeletons/navigation-skeleton.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import WorkspaceMenu from './components/workspace-menu.vue';

// navigation types
import userNavigations from '@/platforms/supplier/navigation/index';
import retailerNavigations from '@/platforms/retailer/navigation/index';
import { User } from 'platform-unit2-api/users';
import { Navigation } from 'platform-unit2-api/core';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';

//assets
import PuPLogo from '@/assets/images/pup_logo_all_white_big.svg';
import SmallPuPLogo from '@/assets/images/pup_logo_all_white_small.svg';

// Props
defineProps<{
  isCollapsed: boolean;
}>();

const { hasPermission } = authorizationMethods;

// Emits
const emit = defineEmits<{ (e: 'hide'): void }>();
// Constants
const store = useStore();
const route = useRoute();
const ts = new TranslationService('general', 'components');

const isNavLoading = ref(false);

const currentUser = computed<User>(() => store.getters['users/currentUser']);
const navigations = ref<Navigation[]>();
// const billingUsers = computed(() => {
//   return store.getters['users/currentUser'].organisation.billing_users.map((user: any) => user.id);
// });

async function getNavigation(): Promise<Navigation[]> {
  if (currentUser.value == null) {
    return [];
  }

  isNavLoading.value = true;
  switch (currentUser.value.workspace?.workspace_type?.type) {
    case ClientTypeEnum.RETAILER:
      isNavLoading.value = false;
      return retailerNavigations;
    case ClientTypeEnum.SUPPLIER:
      isNavLoading.value = false;
      return await userNavigations();
    default:
      isNavLoading.value = false;

      return await userNavigations();
  }
}

function userIsPermitted(permission?: FeaturePermission): boolean {
  return permission != null ? hasPermission(permission) : true;
}

// Lifecycle hooks
watch(
  () => currentUser.value,
  async () => {
    navigations.value = await getNavigation();
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  navigations.value = await getNavigation();
});
</script>
<template>
  <div id="sidebar">
    <!-- Logo -->
    <div class="logo-container" :class="{ smallLogo: isCollapsed }">
      <img
        class="cursor-pointer logo"
        :src="isCollapsed ? SmallPuPLogo : PuPLogo"
        @click="
          $router.push({
            name: 'dashboard',
          })
        "
      />
    </div>
    <!-- Navigation items-->
    <nav class="menu-items">
      <div v-if="isNavLoading"><navigationSkeleton /></div>
      <ul v-else>
        <li v-for="(item, index) in navigations" :key="index" @click="emit('hide')">
          <router-link
            :is="item.disabled"
            v-if="item.to && userIsPermitted(item.permission)"
            v-tooltip.right="{
              value: ts.tModule(`navigation.entities.${item.title}`),
              disabled: !isCollapsed,
            }"
            :to="item.to"
            :class="[
              item.disabled ? 'pointer-events-none opacity-70' : '',
              (item.group != null && (item.group === (route?.meta as any)?.group) || route.path === item.to) && 'exact-active-item',
            ]"
          >
            <i
              v-if="item.icon"
              class="item-icon mdi"
              :class="`${item.icon} ${isCollapsed && 'scaleup'}`"
            />
            <span :class="{ hideTitle: isCollapsed }">
              {{ ts.tModule(`navigation.entities.${item.title}`) }}
            </span>
            <p-chip
              v-if="item.disabled && !isCollapsed"
              class="bg-orange-600 font-bold ml-auto opacity-70 text-white uppercase"
            >
              deprecated
            </p-chip>
            <p-chip
              v-if="item.title === 'import_mappings' && !isCollapsed"
              class="beta-chip ml-2"
              :class="route.path.includes(item.to) ? 'bg-white' : 'bg-primary'"
            >
              beta
            </p-chip>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- Workspace selector -->
    <WorkspaceMenu :is-collapsed="isCollapsed" />
  </div>
</template>

<style lang="scss" scoped>
#sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #00456c;
}

.logo-container {
  padding-bottom: 11px;
  margin-bottom: 20px;
  margin: 16px;
  height: 54px;
  object-fit: cover;
  justify-items: center;
  display: grid;
  align-content: center;
  transition: all $animation-duration ease;
  img.logo {
    width: 50%;
  }

  &.smallLogo {
    opacity: 0;
    animation: fade $animation-duration ease forwards;

    img {
      width: 30px;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.menu-items {
  line-height: 12px;
  display: block;
  overflow-y: auto;

  ul {
    overflow-x: hidden;
  }

  li {
    margin: 4px 10px;
  }

  a {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
    width: 100%;
    display: flex;
    padding-left: 16px;
    padding: 12px;
    transition: all $animation-duration ease;
    cursor: pointer;
    border-radius: 2px;

    &:hover:not(.exact-active-item) {
      background-color: #00609766;
    }

    i {
      color: $icon-active;
      font-size: 16px;
      transition: all $animation-duration ease;

      &.scaleup {
        transform: scale(1.2);
      }
    }

    span {
      padding-left: 10px;
      max-width: 200px;
      transition: all $animation-duration ease;
      display: block;

      &.hideTitle {
        overflow: hidden;
        max-width: 0;
        padding-left: 0;
        opacity: 0;
      }
    }
  }

  .exact-active-item {
    background-color: #00609766;
    background-image: linear-gradient(to right, #00609766 0% 100%);
    background-repeat: no-repeat;
    animation: navigation $animation-duration ease forwards;
    color: $white;
    i {
      color: $white;
    }
  }
}
</style>
