<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

const service = inject(datamodelAttributeServiceKey)!;
const ts = service.ts;
</script>

<template>
  <div class="align-items-center flex flex-column justify-content-center w-full">
    <i class="bg-gray-100 border-circle mdi mdi-database-outline p-2 text-3xl text-primary"></i>
    <span class="block font-bold mb-2 mt-4 text-lg">{{ ts.tModule('datamodel_empty.title') }}</span>
    <p>{{ ts.tModule('datamodel_new.subtitle') }}</p>
  </div>
</template>
