import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { UpdateProductField } from 'platform-unit2-api/products';
import { InputSelectType } from '../interfaces/input-select.interface';
import { InputSelectAttributeFieldService } from '../input-select-attribute-field.service';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class InputSelectFieldService extends InputSelectAttributeFieldService {
  constructor(
    attributeField: AttributeField<InputSelectType>,
    productAttributeField: ProductAttributeField<InputSelectType>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
  }

  /*
   * Backend expects a string consisting of the {decimal unit} or null
   */
  protected createPayload(): UpdateProductField {
    const finalValue =
      this.decimal == null && this.unit == null ? null : `${this.decimal} ${this.unit}`;

    return {
      attribute_id: this._productAttributeField.attribute_id,
      value: finalValue,
      locale_id: this._productAttributeField.locale.id,
      path: this._productAttributeField?.path ?? null,
      overwrite: false,
    };
  }
}
