import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import Import from 'retailer/modules/import/views/import.view.vue';
import ImportScheduled from 'retailer/modules/import/views/scheduled-import.view.vue';
import { RouteRecordRaw } from 'vue-router';
import ImportNewSettings from 'retailer/modules/import/components/create-import-steps/import-form-settings.vue';
import ImportNewUpload from 'retailer/modules/import/components/create-import-steps/import-form-upload.vue';
import ImportNewOptionalSettings from 'retailer/modules/import/components/create-import-steps/import-form-optional-settings.vue';
import ImportScheduledFormSettings from 'retailer/modules/import/components/create-scheduled-import-steps/scheduled-import-form-settings.vue';
import ImportScheduledFormCron from 'retailer/modules/import/components/create-scheduled-import-steps/scheduled-import-form-cron.vue';
import ImportScheduledFormOptionalSettings from 'retailer/modules/import/components/create-scheduled-import-steps/scheduled-import-form-optional-settings.vue';
import { RouterService } from '@/core/router/router.service';
const factory = new RouteFactory({
  platform: 'retailer',
  tabs: [
    {
      label: 'Import',
      key: 'import',
      command: () => {
        RouterService.getInstance().router.push({ name: 'import' });
      },
    },
    {
      label: 'Scheduled Import',
      key: 'import-scheduled',
      command: () => {
        RouterService.getInstance().router.push({ name: 'import-scheduled' });
      },
    },
  ],
  navigationGroup: 'import',
});

const importRoutes: RouteRecordRaw[] = [
  factory.createAppRoute({
    path: '/import',
    name: 'import',
    title: 'Import',
    component: Import,
    translations: {
      module: 'imports',
    },
    rights: [FeaturePermission.READ_PIPELINES, FeaturePermission.IMPORT_MEDIA_BULK],
    children: [
      factory.createChildRoute({
        path: 'new',
        name: 'new-import',
        title: 'Create import',
        redirect: { name: 'new-import-settings' },
        rights: [FeaturePermission.READ_PIPELINES],
      }),
      factory.createChildRoute({
        path: 'new/settings',
        name: 'new-import-settings',
        component: ImportNewSettings,
      }),
      factory.createChildRoute({
        path: 'new/upload',
        name: 'new-import-upload',
        component: ImportNewUpload,
      }),
      factory.createChildRoute({
        path: 'new/optional-settings',
        name: 'new-import-optional',
        component: ImportNewOptionalSettings,
      }),
      factory.createChildRoute({
        path: ':id',
        name: 'restart-import',
        redirect: { name: 'update-import-settings' },
        title: 'Restart import',
        rights: [FeaturePermission.READ_PIPELINES],
      }),
      factory.createChildRoute({
        path: ':id?/settings',
        name: 'update-import-settings',
        component: ImportNewSettings,
      }),
      factory.createChildRoute({
        path: ':id?/upload',
        name: 'update-import-upload',
        component: ImportNewUpload,
      }),
      factory.createChildRoute({
        path: ':id?/optional-settings',
        name: 'update-import-optional',
        component: ImportNewOptionalSettings,
      }),
    ],
  }),
  factory.createAppRoute({
    path: '/import/scheduled',
    name: 'import-scheduled',
    component: ImportScheduled,
    translations: {
      module: 'scheduledImport',
    },
    rights: [FeaturePermission.READ_PIPELINES, FeaturePermission.IMPORT_MEDIA_BULK],
    children: [
      factory.createChildRoute({
        path: 'new',
        name: 'create-scheduled-import',
        redirect: { name: 'new-scheduled-import-settings' },
        title: 'Create scheduled import',
        rights: [FeaturePermission.READ_PIPELINES],
      }),
      factory.createChildRoute({
        path: 'new/settings',
        name: 'new-scheduled-import-settings',
        component: ImportScheduledFormSettings,
      }),
      factory.createChildRoute({
        path: 'new/frequency',
        name: 'new-scheduled-import-upload',
        component: ImportScheduledFormCron,
      }),
      factory.createChildRoute({
        path: 'new/optional-settings',
        name: 'new-scheduled-import-optional',
        component: ImportScheduledFormOptionalSettings,
      }),

      factory.createChildRoute({
        path: ':id',
        name: 'update-scheduled-import',
        redirect: { name: 'update-scheduled-import-settings' },
        rights: [FeaturePermission.READ_PIPELINES],
      }),
      factory.createChildRoute({
        path: ':id?/settings',
        name: 'update-scheduled-import-settings',
        component: ImportScheduledFormSettings,
      }),
      factory.createChildRoute({
        path: ':id?/upload',
        name: 'update-scheduled-import-upload',
        component: ImportScheduledFormCron,
      }),
      factory.createChildRoute({
        path: ':id?/optional-settings',
        name: 'update-scheduled-import-optional',
        component: ImportScheduledFormOptionalSettings,
      }),
    ],
  }),
];

export default importRoutes;
