<script setup lang="ts">
import { CategoryViewService } from '@/general/services/overview-service/modules/category-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import CategoriesTable from 'retailer/modules/categories/components/categories-table.vue';
import CategoriesForm from 'retailer/modules/categories/components/categories-form.vue';
import { categoryServiceKey } from 'retailer/modules/categories/ts/keys/categories.keys';
import { tsKey } from '@/general/services/translations/translation.key';

import { Ref, provide, ref } from 'vue';
import OverView from '@/general/ui/views/over-view/over-view.vue';
import OverviewSidebar from '@/general/ui/components/over-view/overview-sidebar.vue';

const ts = ref(new TranslationService('retailer', 'categories'));
const service = ref(new CategoryViewService(ts.value as TranslationService));

provide(categoryServiceKey, service as Ref<CategoryViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView platform="retailer" module-name="categories" :service="service">
    <template #content>
      <CategoriesTable />
    </template>
    <template #crudComponent>
      <OverviewSidebar :service="service"
        ><template #form>
          <CategoriesForm />
        </template>
      </OverviewSidebar>
    </template>
    <template #others>
      <pConfirmDialog group="category" />
    </template>
  </OverView>
</template>
