<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import UserSelect from '@/general/ui/components/selects/user-select.vue';
import { inject } from 'vue';
import { taskViewServiceKey } from '../../ts/keys/task-view-service.key';

//Services
const service = inject(taskViewServiceKey);
const ts = new TranslationService('supplier', 'tasks');
</script>
<template>
  <div v-if="service != null">
    <h4 class="font-bold my-5">
      {{ ts.tModule('addTask.assignDescription') }}
    </h4>
    <div class="my-5">
      <div class="align-items-center flex">
        <pCheckbox
          v-model="service.isAssignedToWorkspace"
          input-id="task-supplier-checkbox"
          :binary="true"
          :disabled="service.users.length > 0"
        />
        <label class="ml-3" for="task-supplier-checkbox">
          {{
            ts.tModule('addTask.workspaceCheckbox', {
              params: { workspace: service.currentUser?.workspace?.name },
            })
          }}
        </label>
      </div>

      <p-divider layout="horizontal">
        <b>{{ ts.tGlobal('or') }}</b>
      </p-divider>

      <UserSelect v-model="service.users" multiselect :disabled="!!service.isAssignedToWorkspace" />
    </div>
  </div>
</template>
