import { watch, Ref, ref, inject, onMounted } from 'vue';
import { unref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { OrderableItem } from './types';
import { datamodelAttributeServiceKey } from '@/platforms/supplier/modules/datamodel-attributes/ts/datamodel-attribute.keys';
import { Attribute } from 'platform-unit2-api/attributes';
/**
 * @deprecated
 * Hard coded to data model attributes, new one need to be made when needed for more usecases
 * A composable which handles reordering of an array and its corresponding callbacks
 * @param dataList a ref of the array you want to order
 * @param onReorderCallback the function which will be called when the reorder is completed
 * @returns reorder, orderBy, orderedList
 */
export function useDataModelOrderable<T>(dataList: Ref<T[]>) {
  const datamodelService = inject(datamodelAttributeServiceKey);

  const orderedList = ref<OrderableItem<T>[]>(unref(dataList).map((item) => ({ value: item })));

  function assignUUIDs() {
    (orderedList.value as OrderableItem<Attribute>[]).forEach((item, index: number) => {
      item.itemUUID = item.itemUUID ?? uuidv4();
      item.order = item.value.order ?? index + 1;
    });
  }

  function orderBy(skip?: boolean): void {
    if (orderedList.value.every(<T>(item: OrderableItem<T>) => item.order == null)) {
      orderedList.value.forEach(<T>(item: OrderableItem<T>, index: number) => {
        item.order = index + 1;
      });
    }

    if (orderedList.value.some(<T>(item: OrderableItem<T>) => item.order == null)) {
      return;
    }

    orderedList.value.sort(<T>(a: OrderableItem<T>, b: OrderableItem<T>) =>
      a.order! > b.order! ? 1 : a.order === b.order ? 0 : -1,
    );
    if (!skip) {
      datamodelService?.setOrderedAttributes(orderedList.value as OrderableItem<Attribute>[]);
      reorder();
    }
  }

  function reorder(minOrder = 1, oldIndex?: number, newIndex?: number): void {
    if (oldIndex == null || newIndex == null) {
      return;
    }

    orderedList.value.splice(newIndex, 0, orderedList.value.splice(oldIndex, 1)[0]);

    (orderedList.value as OrderableItem<Attribute>[]).forEach((item, index) => {
      item.order = index + minOrder;
      datamodelService?.updateOrder(item.value.id, item.order);
    });
  }

  function toOrderableItem<T>(item: T, index: number): OrderableItem<T> {
    return {
      ...orderedList.value[index],
      value: item,
    };
  }

  onMounted(() => {
    (orderedList.value as OrderableItem<T>[]) = unref(dataList)
      .filter(Boolean)
      .map(toOrderableItem);
    orderBy(true);
    assignUUIDs();
    datamodelService?.setOrderedAttributes(orderedList.value as OrderableItem<Attribute>[]);
  });

  watch(
    () => unref(dataList).length,
    () => {
      (orderedList.value as OrderableItem<T>[]) = unref(dataList)
        .filter(Boolean)
        .map(toOrderableItem);
      datamodelService?.setOrderedAttributes(orderedList.value as OrderableItem<Attribute>[]);
    },
    { deep: true },
  );

  watch(
    () => datamodelService?.isSavingAttributes,
    () => {
      if (datamodelService?.isSavingAttributes) {
        orderedList.value = [];
      }
    },
  );

  return { reorder, orderBy, orderedList };
}
