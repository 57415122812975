<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { ProductStatus, ProductStatusesRestService } from 'platform-unit2-api/product-statuses';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<ProductStatus>>();
const productStatusRestService: any = ProductStatusesRestService;
</script>
<template>
  <BaseSelect
    :rest-service="productStatusRestService"
    option-label="label"
    display="chip"
    module-name="status"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
