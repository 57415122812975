import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Thumbnail | Thumbnails',
  placeholder: 'Select thumbnail',
};

const tranlation: Translation = {
  thumbnails: translationObject,
};

export default tranlation;
