<script setup lang="ts">
import { inject, ref } from 'vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { MultipleChoiceFieldService } from 'services/attribute-fields/field-services/multiple-choice-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.vue';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
interface Props {
  attributeField: AttributeField<string[] | undefined>;
  productAttributeField: ProductAttributeField<string[] | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const ts = new TranslationService('general', 'components');

/** Services */
const multipleChoiceFieldService = ref(
  new MultipleChoiceFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="multipleChoiceFieldService">
    <pMultiselect
      v-model="multipleChoiceFieldService.productAttributeFieldValue"
      filter
      reset-filter-on-hide
      :options="multipleChoiceFieldService.attributeField.attribute.options?.choices"
      :option-label="(option: string) => option"
      :disabled="multipleChoiceFieldService.productAttributeField.locked"
      :placeholder="ts.tModule('attributeFields.chooseValue')"
      class="relative"
      :class="multipleChoiceFieldService.getErrorTypeClass()"
      :pt="{
        root: {
          style: 'width: 100px',
        },
        panel: {
          class: 'max-w-full',
        },
        item: {
          style: 'overflow: hidden; text-wrap: wrap;',
        },
      }"
    />
  </WrapperField>
</template>
