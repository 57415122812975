import {
  ImportState,
  MatchedProductUploadsResponse,
} from '@/platforms/supplier/modules/import/ts/import.types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<ImportState> = {
  setMatchedProductUploads(state: ImportState, matchedUploads: MatchedProductUploadsResponse[]) {
    state.matchedProductUploads = matchedUploads;
  },
};
