<script setup lang="ts">
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { Datamodel } from 'platform-unit2-api/datamodels';
import AttributeFieldsRenderer from '@/general/ui/components/attribute-fields/attribute-fields-renderer.vue';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import ProductAttributeSkeleton from '@/platforms/supplier/modules/products/components/skeletons/product-attribute-skeleton.vue';
import ProductAttributeEmptyStates from '@/platforms/supplier/modules/products/components/empty-state/product-attribute-empty-states.vue';
import { onBeforeRouteLeave } from 'vue-router';
withDefaults(
  defineProps<{
    selectedDatamodel?: Datamodel;
  }>(),
  {
    selectedDatamodel: undefined,
  },
);

//** Constants */
const store = useStore();
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

const isInMissingFieldMode = computed(() => {
  return store.getters['products/showMissingFields'];
});

const turnOffIsMissingMode = () => {
  if (isInMissingFieldMode.value) {
    store.dispatch('products/SET_MISSING_FIELDS');
  }
};

onBeforeRouteLeave((_, __, next) => {
  turnOffIsMissingMode();
  productAttributeFieldService.value.showDialog(next);
});

const showMissingFields = computed(() => {
  return store.getters['products/showMissingFields'];
});
</script>
<template>
  <div>
    <ProductAttributeSkeleton
      v-if="
        productAttributeFieldService.loading ||
        !productAttributeFieldService.currentProduct ||
        productAttributeFieldService.isSaving
      "
    />
    <ProductAttributeEmptyStates :selected-datamodel="selectedDatamodel" />
    <AttributeFieldsRenderer
      v-if="
        productAttributeFieldService.attributeFields.length !== 0 &&
        !productAttributeFieldService.loading &&
        productAttributeFieldService.currentProduct &&
        !productAttributeFieldService.isSaving
      "
      :attribute-fields="
        showMissingFields
          ? productAttributeFieldService?.emptyFields
          : productAttributeFieldService?.attributeFields
      "
    />
  </div>
</template>
