<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';

const ts = new TranslationService('supplier', 'products');
</script>

<template>
  <ApplicationHeader>
    <template #breadcrumbs>
      <router-link :to="{ name: 'products' }">
        <pButton
          outlined
          class="custom-button-styles p-button-sm"
          icon="mdi mdi-chevron-left"
          :label="ts.tGlobal('products', { params: { count: 2 } })"
        />
      </router-link>
    </template>
    <template #content>
      <div class="align-items-center flex gap-3 pb-4">
        <h3>{{ ts.tModule('deletedProducts.title') }}</h3>
      </div>
    </template>
  </ApplicationHeader>
</template>
<style lang="scss" scoped>
.custom-button-styles {
  border-color: #dce5ef !important;
  color: black !important;
}
</style>
