import TableColumn from './table-column';
import { FilterColumn } from './filter-column.interface';
import { TableColumn as AdvancedSearchTableColumn } from '@/general/ui/components/data-table-column/data-table-column.interface';
interface TablePropsConstructor<T> {
  columns: TableColumn<T>[];
  loadData: (fetchProps: any) => void;
}

export class TableProps<T> {
  columns: TableColumn<T>[] = [];
  loadData: (fetchProps: any) => void;

  constructor(val: TablePropsConstructor<T>) {
    this.columns = val.columns;
    this.loadData = val.loadData;
  }

  /**
   * Returns the column with the specified key if it exists
   * @param key string
   * @returns TableColumn<T> | undefined
   */
  getColumn(key: string): TableColumn<T> | undefined {
    return this.columns.find((column) => column.key === key);
  }

  /**
   * Converts column filters to filters needed by Prime Vue
   * @returns Record<string, { value: Record<'id' | 'name', string>[]; matchMode?: string }> (the type that prime vue expects)
   */
  filtersToTableFilters(): Record<
    string,
    { value: Record<'id' | 'name', string>[]; matchMode?: string }
  > {
    return this.columns.reduce((finalObj, current) => {
      if (current.filter != null) {
        return {
          ...finalObj,
          [current.key]: { value: undefined, matchMode: current.filter?.matchMode },
        };
      }

      return finalObj;
    }, {});
  }

  /**
   * Maps TableColumns to FilterColumns
   * @returns FilterColumn[]
   */
  columnsToFilters(): (FilterColumn | undefined)[] {
    return this.columns.map((c) => c.filter).filter(Boolean);
  }

  /**
   * Gets the filter key out of a column key
   * @param key
   * @returns string
   */
  getFilterKeyFromColumnFilter = (key: string): string => {
    return this.getColumn(key)?.filter?.filterKey ?? '';
  };

  /**
   * Determines whether a column is visible or not
   * @param key string
   * @param showedColumns TableColumn<T>[]
   * @returns boolean
   */
  isColumnVisible = (key: string, showedColumns: TableColumn<T>[]): boolean => {
    const columnsWithoutName = this.columns.filter((c) => c.label === '');
    if (columnsWithoutName.some((column) => column.key === key)) {
      return true;
    }

    return showedColumns.some((column) => column.key === key);
  };

  /**
   * Converts the table columns to AdvancedSearchTableColumn[] which is needed for the advanced search
   * @returns AdvancedSearchTableColumn[]
   */
  toAdvancesSearchColumns(): AdvancedSearchTableColumn[] {
    return this.columns.map(
      (column) =>
        ({
          field: column.key,
          selected: true,
          header: column.label,
          sortable: column.isSortable,
          type: column.key,
          size: '',
          visible: true,
        } as AdvancedSearchTableColumn),
    );
  }
}
