<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';
import { ValidationModel, ValidationModelsRestService } from 'platform-unit2-api/validation-models';

interface Props extends DefaultSelectProps<ValidationModel> {
  moduleId?: number;
}

const validationModelRestService: any = ValidationModelsRestService;

const props = defineProps<Props>();
</script>
<template>
  <BaseSelect
    :rest-service="validationModelRestService"
    class="w-full"
    :custom-fetch-params="[{ module_id: moduleId }]"
    module-name="variantValidationModel"
    option-label="name"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
