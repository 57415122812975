import { ref } from 'vue';

export default function (): any {
  const sort = ref('');

  const onSortChange = (value: string, callback?: () => void) => {
    sort.value = value;
    callback ? callback() : null;
  };

  return {
    sort,
    onSortChange,
  };
}
