import { Upload } from 'platform-unit2-api/uploads';
import { ToastService } from '../toasts/toast.service';
import { TranslationService } from '../translations/translation.service';
import i18n from '@/core/i18n';

export interface ImageSizeErrorMessages {
  wrongImageSize: string;
  wrongImageHeight: string;
  wrongImageWidth: string;
}

export class ImageValidatorService {
  /**
   *
   */
  constructor(
    private _minimunImageSize: `${string} : ${string}`,

    private _ts: TranslationService = new TranslationService(
      'supplier',
      'storyManager',
      false,
      i18n.global as any,
    ),
    private _errorMessages: ImageSizeErrorMessages = {
      wrongImageSize: 'storyBuilder.wrongImageSize',
      wrongImageHeight: 'storyBuilder.wrongImageHeight',
      wrongImageWidth: 'storyBuilder.wrongImageWidth',
    },
  ) {}

  public hasCorrectDimensions(upload: Upload): boolean {
    const subStrings: string[] = this._minimunImageSize.split(' ');
    const sizes: number[] = [Number(subStrings[0]), Number(subStrings[2])];

    if (
      upload.resolution_in_pixels_height < sizes[1] &&
      upload.resolution_in_pixels_width < sizes[0]
    ) {
      ToastService.getInstance().displayErrorToast(
        this._ts.tModule(this._errorMessages.wrongImageSize),
      );
      return false;
    }

    if (upload.resolution_in_pixels_height < sizes[1]) {
      ToastService.getInstance().displayErrorToast(
        this._ts.tModule(this._errorMessages.wrongImageHeight),
      );
      return false;
    }

    if (upload.resolution_in_pixels_width < sizes[0]) {
      ToastService.getInstance().displayErrorToast(
        this._ts.tModule(this._errorMessages.wrongImageWidth),
      );
      return false;
    }

    return true;
  }
}
