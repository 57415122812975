import { FilterColumn } from './filter-column.interface';
import { RowComponent } from './row-component.interface';

export interface TableColumnProps<T> {
  key: keyof T;
  isSortable?: boolean;
  sortingColumn?: string;
  label: string;
  hidden?: boolean;
  additionalStyling?: any;
  fallbackValue?: string | RowComponent;
  isFrozen?: boolean;
  customValue?: (row: T) => string | RowComponent;
  filter?: FilterColumn;
}

class TableColumn<T> {
  key: keyof T;
  isSortable: boolean;
  sortingColumn?: string;
  label: string;
  additionalStyling?: any;
  hidden: boolean;
  fallbackValue?: string | RowComponent;
  isFrozen?: boolean;
  customValue?: (row: T) => string | RowComponent;
  filter?: FilterColumn;

  constructor(val: TableColumnProps<T>) {
    this.key = val.key;
    this.isSortable = val.isSortable === true;
    this.sortingColumn = val.sortingColumn;
    this.label = val.label;
    this.hidden = val.hidden ?? false;
    this.additionalStyling = val.additionalStyling;
    this.customValue = val.customValue;
    this.fallbackValue = val.fallbackValue;
    this.isFrozen = val.isFrozen;
    this.filter = val.filter;
  }

  /**
   * Is customValue defined
   * @returns boolean
   */
  hasCustomValue = (): boolean => {
    return this.customValue != null;
  };

  /**
   * Determines whether the customValue is a component
   * @param row T
   * @returns boolean
   */
  isCustomValueComponent = (row: T): boolean => {
    if (this.hasCustomValue()) {
      return (this.customValue?.(row) as RowComponent).component != null;
    }

    return false;
  };
}

export default TableColumn;
