<script setup lang="ts">
import { inject } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';
import TableColumnSelectInterface from '@/general/ui/components/table-column-select-sidebar/table-column-select.interface';
import TableColumnSelectSidebar from '@/general/ui/components/table-column-select-sidebar/table-column-select-sidebar.vue';

const productTableService = inject(productTableServiceKey)!;
</script>

<template>
  <div>
    <pButton
      v-tooltip.bottom="{
        value: productTableService.ts.tModule('column_configuration'),
        class: 'text-sm',
        autoHide: false,
        pt: {
          text: {
            class: 'w-13rem flex justify-content-center',
          },
        },
      }"
      class="bg-white border-100 text-800"
      @click="productTableService.openColumnSelect()"
      ><i class="mdi mdi-tune-vertical"></i
    ></pButton>
    <TableColumnSelectSidebar
      :service="(productTableService as unknown as TableColumnSelectInterface)"
    />
  </div>
</template>
