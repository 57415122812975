/**
 * DebounceService
 *
 * Debounce functions with a delay
 */
export class DebounceService {
  /**
   * Timer id
   */
  private timerId?: NodeJS.Timeout;

  /**
   * Timeout/delay
   */
  private _timeout: number;

  /**
   * Debounce function
   * @param callback function to debounce
   * @param delay overwrite constructor delay in ms
   */
  debounce(callback: (...args: any[]) => void, delay?: number): void {
    // clear existing timeout
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    // set new timeout
    this.timerId = setTimeout(
      () => {
        callback();
        delete this.timerId;
      },
      delay != null ? delay : this._timeout,
    );
  }

  cancel(): void {
    // clear existing timeout
    if (this.timerId != null) {
      clearTimeout(this.timerId);
    }
  }

  /**
   * Create instance of the DebounceService.
   * @param timeout timeout/delay in ms
   */
  constructor(timeout: number) {
    this._timeout = timeout;
  }
}
