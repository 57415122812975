<script lang="ts" setup generic="T">
import { SelectFilterEvent, SelectProps, SelectSlots } from 'primevue/select';
import { MultiSelectFilterEvent, MultiSelectProps, MultiSelectSlots } from 'primevue/multiselect';
import { ref, watchEffect, onMounted, watch } from 'vue';
import { TranslationGlobal } from '@/core/i18n/ts/interfaces/translation-global.interface';
import { PaginationObject } from 'platform-unit2-api/core';
import { BaseSelectService } from './ts/base-select.service';
import cacheService from '@/general/services/cache.service';
import { differenceWith, isEqual, isFunction, uniqBy, uniqWith } from 'lodash';

interface Props
  extends Omit<SelectProps, 'aria-label' | 'aria-labelledby' | 'overlayClass' | 'overlayStyle'>,
    Omit<
      MultiSelectProps,
      | 'pt'
      | 'panelStyle'
      | 'panelClass'
      | 'inputProps'
      | 'aria-label'
      | 'aria-labelledby'
      | 'overlayClass'
      | 'overlayStyle'
    > {
  label?: string;
  hideLabel?: boolean;
  multiselect?: boolean;
  restService: any;
  fetchFunctionName?: string;
  customFetchParams?: any[];
  paginationParams?: PaginationObject;
  skipFetchOnShow?: boolean;
  moduleName?: keyof TranslationGlobal;
  errorMessage?: string;
  // Used to manipulate options if needed
  editOptions?: (options: T[]) => T[];
}

const props = withDefaults(defineProps<Props>(), {
  multiselect: false,
  filter: true,
  showClear: true,
  resetFilterOnHide: true,
  skipFetchOnShow: false,
  dataKey: 'id',
  errorMessage: '',
  fetchFunctionName: 'getAll',
  showToggleAll: undefined,
  selectAll: undefined,
  label: undefined,
  moduleName: undefined,
  paginationParams: undefined,
  customFetchParams: () => [],
  editOptions: (options: T[]) => options,
});

const emit = defineEmits<{}>();

const slots = defineSlots<MultiSelectSlots & SelectSlots>();
const service = ref<BaseSelectService<T> | undefined>();
const finalOptions = ref<T[]>([]);
const multiselectDOM = ref();

const selectIdentifier =
  (props.moduleName ?? props.restService.name?.replace(/RestService$/, '').toLocaleLowerCase()) +
  props.multiselect;

const handleFilterOptions = async () => {
  if (!props.skipFetchOnShow) {
    await service.value?.fetchOptions(props.modelValue);
    service.value?.filterOptions(props.editOptions);
  }
};

onMounted(async () => {
  let defaultSort = props.paginationParams?.sortBy;
  if (typeof props.optionLabel !== 'function') {
    defaultSort = props.optionLabel;
  }

  service.value = new BaseSelectService(
    new props.restService(),
    props.fetchFunctionName,
    selectIdentifier,
    props.paginationParams,
    defaultSort as string,
  );

  service.value.customFetchParams = props.customFetchParams;
  await handleFilterOptions();

  if (multiselectDOM.value != null) {
    const originalOnToggleAll = multiselectDOM.value.onToggleAll;

    multiselectDOM.value.onToggleAll = async function (e: Event) {
      await service.value?.handleCheckAll((e.target as any)?.checked);

      if (!service.value?.queryIsEmpty()) {
        if (service.value?.allChecked) {
          (emit as any)(
            'update:modelValue',
            uniqWith(
              (props.modelValue ?? []).concat(
                props.editOptions
                  ? props.editOptions(service.value.lastRawFetchedItems)
                  : service.value.lastRawFetchedItems,
              ),
              isEqual,
            ),
          );
        } else {
          (emit as any)(
            'update:modelValue',
            differenceWith(
              props.modelValue,
              props.editOptions
                ? props.editOptions(service.value?.lastRawFetchedItems ?? [])
                : service.value?.lastRawFetchedItems ?? [],
              isEqual,
            ),
          );
        }
      } else {
        originalOnToggleAll(e);
      }
    };
  }
});

watchEffect(() => {
  if (service.value) {
    finalOptions.value = props.options ?? service.value.options;
    (finalOptions.value as T[]) =
      props.editOptions?.(finalOptions.value as T[]) ?? finalOptions.value;

    finalOptions.value = uniqBy(finalOptions.value, props.dataKey);
  }
});

watch(
  () => props.customFetchParams,
  () => {
    const isEveryParamEqualAsTheOneBefore = service.value?.customFetchParams?.every(
      (param, index) => isEqual(param, props.customFetchParams?.[index]),
    );
    if (service.value != null && !isEveryParamEqualAsTheOneBefore) {
      service.value.customFetchParams = props.customFetchParams;
      cacheService.resetItemsForCacheModuleRecord(selectIdentifier);
      handleFilterOptions();
    }
  },
);
</script>
<template>
  <div v-if="service != null" class="flex flex-column gap-1">
    <label v-if="hideLabel !== true">
      {{ props.label ?? service.getLabel(props.multiselect, props.moduleName) ?? '' }}
    </label>
    <pSelect
      v-if="!multiselect"
      class="w-full"
      :pt="{
        root: {
          style: {
            border: Boolean(errorMessage) ? '1px solid red' : '',
          },
        },
      }"
      v-bind="{ ...props, ...$attrs }"
      :show-clear="showClear"
      :filter="filter"
      :data-key="dataKey"
      :reset-filter-on-hide="resetFilterOnHide"
      :options="finalOptions"
      :loading="service.loading"
      :placeholder="placeholder ?? service.getPlaceholder(multiselect, moduleName)"
      @before-show="handleFilterOptions"
      @hide="() => service?.resetQuery()"
      @filter="(event: SelectFilterEvent) => service?.search(event.value, editOptions, props.modelValue)"
    >
      <template
        v-for="(slot, key_index) of Object.keys(slots)"
        :key="key_index"
        #[slot]="{
          option,
          index,
          value,
          placeholder,
          options,
          items,
          styleClass,
          contentRef,
          getItemOptions,
          onClick,
        }"
      >
        <slot
          :name="(slot as keyof Readonly<MultiSelectSlots & SelectSlots>)"
          v-bind="{
            option,
            index,
            value,
            placeholder,
            options,
            items,
            styleClass,
            contentRef,
            getItemOptions,
            onClick,
          }"
        />
      </template>
      <template #value="{ value, placeholder }">
        <div v-if="optionLabel != null && value != null">
          <slot
            name="value"
            v-bind="{
              placeholder,
              value,
            }"
            >{{ value[isFunction(optionLabel) ? optionLabel(value) : optionLabel] }}</slot
          >
        </div>
        <span v-else>
          {{ placeholder }}
        </span>
      </template>
    </pSelect>
    <pMultiselect
      v-else
      ref="multiselectDOM"
      class="w-full"
      :pt="{
        root: {
          style: {
            border: Boolean(errorMessage) ? '1px solid red' : '',
          },
        },
      }"
      v-bind="{ ...props, ...$attrs }"
      :filter="filter"
      :data-key="dataKey"
      :show-toggle-all="showToggleAll"
      :select-all="selectAll"
      :reset-filter-on-hide="resetFilterOnHide"
      :options="finalOptions"
      :loading="service.loading"
      :placeholder="placeholder ?? service.getPlaceholder(multiselect, moduleName)"
      @before-show="handleFilterOptions"
      @before-hide="() => service?.resetQuery()"
      @filter="(event: MultiSelectFilterEvent) => service?.search(event.value, editOptions, props.modelValue)"
    >
      <template
        v-for="(slot, key_index) of Object.keys(slots)"
        :key="key_index"
        #[slot]="{
          option,
          index,
          value,
          placeholder,
          options,
          items,
          styleClass,
          contentRef,
          getItemOptions,
          onClick,
        }"
      >
        <slot
          :name="(slot as keyof Readonly<MultiSelectSlots & SelectSlots>)"
          v-bind="{
            option,
            index,
            value,
            placeholder,
            options,
            items,
            styleClass,
            contentRef,
            getItemOptions,
            onClick,
          }"
        />
      </template>
      <template #footer>
        <span
          class="block footer-selected-items pup-pl-4 pup-py-3 w-full"
          style="background-color: '#f8f9fa !important'"
        >
          {{ modelValue?.length ?? 0 }} items selected
        </span>
      </template>
    </pMultiselect>
    <small v-if="Boolean(errorMessage)" class="p-error">{{ errorMessage }}</small>
  </div>
</template>
<style>
.footer-selected-items {
  background: #f8f9fa;
  border-top: #0000001a 1px solid;
}
</style>
