import { reactive } from 'vue';

/**
 * Returns a reactive object and provides it to the injection key.
 * @param key the injection key that will be used to inject the reactive object
 * @param target object that will be made reactive and provided to the injection key
 */
export function state<T extends object>(target: T): T {
  const obj = reactive(target);

  return obj as T;
}
