<script setup lang="ts">
import { ref, onMounted } from 'vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import Upload from '@/general/ui/components/upload.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Product } from 'platform-unit2-api/products';
import { CreateExport } from '../../ts/products.types';

/** Props */
interface Props {
  formData: CreateExport;
  checkedRows: Product[];
}
const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (
    e: 'complete',
    pageObject: {
      pageIndex: number;
      products: Product[];
    },
  ): void;
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const loading = ref(true);
const loadingButton = ref(false);
const products = ref<Product[]>([]);

const removeProductFromExport = (id: number) => {
  products.value = products?.value.filter((x: Product) => x.id !== id) ?? [];
  if (!products.value.length) emit('complete', { pageIndex: 1, products: [] });
};

const complete = () => {
  loadingButton.value = true;
  emit('complete', {
    pageIndex: 1,
    products: products.value,
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 1,
  });
};

onMounted(() => {
  setTimeout(() => {
    if (props.checkedRows) {
      products.value = props.checkedRows;
    }

    loading.value = false;
  }, 500);
});
</script>
<template>
  <div>
    <LoadingIndicator v-if="loading" class="pb-4 px-4" />
    <div v-else class="px-4">
      <div class="mb-5">
        <div class="block mb-2 text-center">
          <h3 class="text-4xl">
            {{ ts.tModule('export_modal.product_overview.title') }}
          </h3>
        </div>
        <div class="block mb-3 text-center">
          <p class="font-normal text-gray-700 text-xl">
            {{ ts.tModule('export_modal.product_overview.subtitle') }}
          </p>
        </div>
      </div>
      <pDataTable
        :value="products"
        :scrollable="true"
        scroll-height="400px"
        :loading="false"
        class="data-table-custom mb-5"
        striped-rows
      >
        <p-column frozen style="min-width: 10%; flex-grow: 1">
          <template #body="slotProps: { data: Product }">
            <Upload :upload="slotProps.data.thumbnail" />
          </template>
          <template #editor="slotProps">
            <Upload :upload="slotProps.data.thumbnail" />
          </template>
        </p-column>
        <p-column
          field="gtin"
          :header="ts.tModule('export_modal.product_overview.column_gtin')"
          style="min-width: 20%; flex-grow: 1"
        ></p-column>
        <p-column
          field="display_name"
          :header="ts.tModule('export_modal.product_overview.column_product_name')"
          style="min-width: 20%; flex-grow: 1"
        ></p-column>
        <p-column
          field="datamodel.name"
          :header="ts.tModule('export_modal.product_overview.column_data_model')"
          style="min-width: 20%; flex-grow: 1"
        ></p-column>
        <p-column
          field="owner.name"
          :header="ts.tModule('export_modal.product_overview.column_varient_name')"
          style="min-width: 20%; flex-grow: 1"
        ></p-column>
        <p-column style="min-width: 5%; flex-grow: 1">
          <template #body="slotProps: { data: Product }">
            <p-button
              v-tooltip.bottom="ts.tModule('export_modal.product_overview.tooltip')"
              plain
              text
              rounded
              icon="mdi mdi-close"
              size
              @click="removeProductFromExport(slotProps.data.id)"
            />
          </template>
        </p-column>
      </pDataTable>
    </div>
  </div>
  <div v-if="!loading">
    <p-divider class="flex my-0 px-0 w-full" />
    <div
      class="border-round-bottom-md flex justify-content-between px-4 py-4"
      style="background-color: #f5f7fa"
    >
      <div>
        <p-button
          :label="ts.tGlobal('cancel')"
          plain
          text
          class="mr-2"
          @click="emit('cancel-export')"
        />
      </div>
      <div>
        <p-button
          :label="ts.tGlobal('previous')"
          severity="secondary"
          class="mr-2"
          icon="mdi mdi-chevron-left"
          icon-pos="left"
          @click="previousPage()"
        />
        <p-button
          :label="ts.tGlobal('export')"
          :loading="loadingButton"
          :disabled="loadingButton || !products.length"
          icon="mdi mdi-chevron-right"
          icon-pos="right"
          @click="complete()"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.data-table-custom:deep(.p-datatable-wrapper) {
  border: 1px solid #ccc;
  border-radius: 10px;
}
</style>
