<script setup lang="ts">
import { DropdownChangeEvent } from 'primevue/dropdown';
import { ref, watch } from 'vue';
import { useDispatch } from '@/general/composables/UseDispatch';
import { TranslationService } from '@/general/services/translations/translation.service';
import { BulkCategoryType } from 'platform-unit2-api/categories';
import { CategoryDictionaryItem } from '../../ts/interfaces/category-dictionary-item.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CategoryRestService } from 'platform-unit2-api/categories';
import { ToastService } from '@/general/services/toasts/toast.service';

/** Props */
const props = defineProps<{
  ids: Array<number>;
  isActive: boolean;
}>();

/** Emit */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'loading'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const tsCategories = new TranslationService('supplier', 'categories');
const tsDatamodels = new TranslationService('supplier', 'datamodels');
const categoryAPI = new CategoryRestService();
const toastService = ToastService.getInstance();

/** Constants */
const categoriesPerVariant = ref<BulkCategoryType>();
const categoryDictionary = new Map<string, CategoryDictionaryItem>();
const showDialog = ref(false);
const selectedCategory = ref<number[]>([]);
const selectedMasterDataCategory = ref();
const { loading } = useDispatch();

const insertInDictionary = (event: DropdownChangeEvent, productIds: number[], key: string) => {
  const productCategory: CategoryDictionaryItem = {
    product_ids: productIds,
    category_id: event.value,
  };
  categoryDictionary.set(key, productCategory);
};

const update = async () => {
  emit('loading');
  buttonsOptions.value.saving = true;
  for (const key of categoryDictionary?.keys() || []) {
    const category = categoryDictionary?.get(key);

    try {
      if (category != null) {
        await categoryAPI.bulkAttachCategory(category.product_ids, [category.category_id]);
        toastService.displaySuccessToast(tsCategories.updateSuccess());
      }
    } catch (err) {
      toastService.displayErrorToast(ts.updateFailed());
    }
  }

  buttonsOptions.value.saving = false;
  hideDialog();
};

const loadAsyncData = async () => {
  try {
    categoriesPerVariant.value = await categoryAPI.bulkGetVariant(props.ids);
    selectedCategory.value = [];
  } catch (err) {
    toastService.displayErrorToast(tsDatamodels.tModule('override_rejected'));
  }
};

const hideDialog = () => {
  selectedCategory.value = [];
  selectedMasterDataCategory.value = [];
  categoryDictionary.clear();
  emit('hide');
  showDialog.value = false;
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    hideDialog();
  },
});

const attachButton = new CreateButton({
  label: () => ts.tGlobal('assign') + ' ' + props.ids.length,
  buttonIcon: 'mdi mdi-checkbox-multiple-marked-outline',
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!selectedMasterDataCategory.value,
  onClick: update,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, attachButton],
});

/** Lifecycles */
watch(
  () => props.isActive,
  async (isActive) => {
    if (isActive) {
      emit('loading');
      await loadAsyncData();
      emit('loading');
      showDialog.value = true;
    }
  },
);
</script>
<template>
  <BaseDialog
    :title="tsCategories.tModule('bulkAttachTitle')"
    :visible="showDialog"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="hideDialog"
  >
    <!-- #region: Category-product field -->
    <p-message severity="info" :closable="false" class="my-5">
      {{ tsCategories.tModule('bulkAttachDescription') }}
    </p-message>
    <div v-if="buttonsOptions.saving">
      <p-skeleton height="4rem" class="mb-2" border-radius="16px"></p-skeleton>
      <p-skeleton height="4rem" class="mb-2" border-radius="16px"></p-skeleton>
      <p-skeleton height="4rem" class="mb-2" border-radius="16px"></p-skeleton>
    </div>

    <!-- #region: this region is for the variants -->
    <div v-if="!loading && !buttonsOptions.saving">
      <div
        v-for="(rows, index) in categoriesPerVariant?.modules"
        :key="index"
        class="border-1 border-gray-200 border-round flex mb-3 w-full"
        :style="{ overflowY: 'clip' }"
      >
        <div class="bg-gray-50 border-round pl-2 pt-2 relative variant w-6">
          <div
            class="absolute arrow bg-gray-50 border-gray-200 border-right-1 border-top-1 top-0"
            :style="{
              height: '100%',
              aspectRatio: '1',
              transform: 'rotate(45deg) skew(10deg, 10deg)',
              right: '-5%',
            }"
          ></div>
          <div class="grid">
            <p class="col font-semibold text-black-alpha-60 text-xl">
              {{ rows.category.name }}
            </p>
            <div class="align-items-center col flex justify-content-center">
              <span
                style="z-index: 1"
                class="align-items-center border-circle flex font-bold h-2rem justify-content-center ml-7 surface-700 text-white text-xl w-2rem"
              >
                {{ rows.product_ids.length }}
              </span>
            </div>
          </div>
        </div>
        <div class="align-items-center category flex pl-6 w-6">
          <pSelect
            v-model="selectedCategory[index]"
            class="w-full"
            :options="rows.category"
            :filter="true"
            option-label="name"
            option-value="id"
            @change="(event: DropdownChangeEvent) =>insertInDictionary(event, rows.product_ids, Object.keys(rows).toString())"
          />
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region: this region is for the Master Data-->
      <div
        class="border-1 border-gray-200 border-round flex mb-3 w-full"
        :style="{ overflowY: 'clip' }"
      >
        <div class="bg-gray-50 border-round pl-2 pt-2 relative variant w-6">
          <div
            class="absolute arrow bg-gray-50 border-gray-200 border-right-1 border-top-1 top-0"
            :style="{
              height: '100%',
              aspectRatio: '1',
              transform: 'rotate(45deg) skew(10deg, 10deg)',
              right: '-5%',
            }"
          ></div>
          <div class="grid">
            <p class="col font-semibold text-black-alpha-60 text-xl">Master Data</p>
            <div class="align-items-center col flex justify-content-center">
              <span
                style="z-index: 1"
                class="align-items-center border-circle flex font-bold h-2rem justify-content-center ml-7 surface-700 text-white text-xl w-2rem"
              >
                {{ categoriesPerVariant?.master_data_product_ids?.length }}
              </span>
            </div>
          </div>
        </div>
        <div class="align-items-center category flex pl-6 w-6">
          <pSelect
            v-model="selectedMasterDataCategory"
            class="w-full"
            :options="categoriesPerVariant?.current_categories"
            :filter="true"
            option-label="name"
            option-value="id"
            @change="(event: DropdownChangeEvent) => insertInDictionary(event, categoriesPerVariant?.master_data_product_ids ?? [], 'Master data')"
          />
        </div>
      </div>
    </div>

    <!-- #endregion -->
  </BaseDialog>
</template>
<style scoped>
.p-dropdown {
  border: none;
}

.p-focus {
  box-shadow: none !important;
}
</style>
