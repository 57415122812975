import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import Users from 'retailer/modules/users/views/users.view.vue';
import { RouteRecordRaw } from 'vue-router';

const userRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/users',
    name: 'users',
    component: Users,
    title: 'Users',
    translations: {
      module: 'users',
      platform: 'retailer',
    },
    rights: [FeaturePermission.MANAGE_USERS],
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-user',
        title: 'New user',
        rights: [FeaturePermission.MANAGE_USERS],
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'edit-user',
        title: 'Edit user',
        rights: [FeaturePermission.MANAGE_USERS],
      }),
    ],
  }),
];

export default userRoutes;
