import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Shared Products',
  emptyState: {
    sharedByTitle: 'There are no products shared by you yet.',
    sharedBySubtitle:
      'Start by sharing your first product on the products overview page. When\nyou shared a product it will show here.',
    sharedWithTitle: 'There are no products shared with you yet.',
    sharedWithSubtitle:
      'When someone shared a product with you it will show here. You can\nalso share products with others on the product overview page.',
  },
  sharedProducts: 'Shared {count} product(s)',
  table_header: {
    shared_to: 'Shared to',
    amount: 'Amount',
    eans: 'EANS',
    from: 'From',
    comment: 'Comment',
  },
  statuses: {
    pending: 'Pending',
    imported: 'Imported',
    declined: 'Declined',
  },
  declineShare: {
    success: 'The share has been declined',
    failed: 'We were not able to decline this share. Please try again.',
  },
  copyEans: {
    success: 'The EANS have successfully been copied to your clipboard',
    failed: 'It was not possible to copy the EANS to your clipboard',
  },
  decline_modal: {
    title: 'Decline share',
    message: 'Are you sure you want to decline the share request?',
  },
  approve_modal: {
    title: 'Approve share select',
    subtitle: 'Select a workspace where shared products will be imported',
    success: 'Succesfully imported the products to your workspace.',
    failed: 'Failed to import the prouducts to your workspace. Please try again.',
  },
  goToProducts: 'Go to products',
};

const tranlation: Translation = {
  shared: translationObject,
};

export default tranlation;
