<script lang="ts" setup>
import usePagination from 'composables/usePagination/pagination';
import MediaGrid from '../components/media-grid.view.vue';
import { useStore } from 'vuex';
import { ref, computed, provide } from 'vue';
import { disable, colSize, assetIds } from '../media.keys';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Upload } from 'platform-unit2-api/uploads';

interface Props {
  multiple?: boolean;
  selectedIds?: number[];
}

const props = withDefaults(defineProps<Props>(), {
  multiple: true,
  selectedIds: () => [],
});

const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'choose-image'): void;
  (e: 'choose-multiple-images', images: Upload[]): void;
}>();
provide(disable, true);
provide(colSize, 2);
provide(assetIds, props.selectedIds);

// Services
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'media');

// Constants
const store = useStore();
const { page } = usePagination();
const assetsRef = ref();
const uploader = ref();
const loading = ref(false);
const isUploading = ref(false);
const selectedImages = ref<Upload[]>([]);
const visible = ref(false);
const selectedImagesIds = computed(() => {
  return selectedImages.value.map((image) => image.id);
});

// Helper functions
const selectImage = (image: Upload): void => {
  selectedImages.value.push(image);
};

const selectMultiple = (images: Upload[]): void => {
  selectedImages.value = images;
};

const deselectMultiple = (images: Upload[]) => {
  selectedImages.value = selectedImages.value.filter(function (value: Upload, index: number) {
    images.splice(index, 1);
  });
};

const isLoading = (isLoading: boolean) => {
  loading.value = isLoading;
};

const deselectImage = (image: Upload): void => {
  selectedImages.value = selectedImages.value.filter((item) => item.id !== image.id);
};

const setImages = (): void => {
  emit('choose-multiple-images', selectedImages.value);
  selectedImages.value = [];
  page.value = 1;
  assetsRef.value?.loadAsyncData();
  emit('hide');
};

const closeMediaPicker = (): void => {
  page.value = 1;
  assetsRef.value?.loadAsyncData();
  emit('hide');
};

const uploadAssets = async (event: any): Promise<void> => {
  isUploading.value = true;
  event.files.forEach(async (file: any) => {
    try {
      await store.dispatch('uploads/UPLOAD', file);
      await assetsRef.value?.loadAsyncData();
    } catch (err) {
      toastService.displayErrorToast(ts.uploadFailed());
    } finally {
      uploader.value.clear();
      isUploading.value = false;
    }
  });
};

const onSearch = async (query: string): Promise<void> => {
  page.value = 1;

  setTimeout(() => {
    assetsRef.value.loadAsyncData(query);
  }, 500);
};
</script>

<template>
  <pDrawer v-model:visible="visible" position="full" @hide="closeMediaPicker">
    <template #header>
      <div class="flex justify-content-between mb-5">
        <h2>{{ ts.tModule('media_picker.title') }}</h2>

        <div class="flex">
          <p-input-text
            type="text"
            class="mr-3"
            :placeholder="ts.tModule('media_picker.search_placeholder')"
            @update:model-value="(event:string|undefined) => onSearch(event ?? '')"
          />
          <p-file-upload
            ref="uploader"
            mode="basic"
            choose-label="Upload"
            :disabled="isUploading || loading"
            :multiple="true"
            :auto="true"
            :custom-upload="true"
            @uploader="uploadAssets"
          />
        </div>
      </div>
    </template>
    <div class="flex flex-column h-full">
      <MediaGrid
        ref="assetsRef"
        :selected-ids="selectedIds"
        :is-uploading="isUploading"
        @is-loading="isLoading"
        @select-image="selectImage"
        @deselect-image="deselectImage"
        @select-multiple-images="selectMultiple"
        @deselect-multiple="deselectMultiple"
        @upload-assets="uploadAssets"
      />

      <div
        v-if="selectedImagesIds.length"
        class="align-items-center bg-white bottom-0 flex justify-content-between left-0 mt-5 p-3 shadow-3 sticky w-full z-5"
      >
        <p>
          <b>{{ selectedImagesIds.length }}</b> {{ ts.tModule('media_picker.image_selected') }}
        </p>
        <p-button :label="ts.tModule('media_picker.choose_image')" @click="setImages"></p-button>
      </div>

      <p-confirm-popup group="delete-asset" />
    </div>
  </pDrawer>
</template>
<style lang="scss" scoped>
:deep(.p-speeddial-action) {
  width: 3rem;
  height: 3rem;
}
</style>
