import { DatamodelCompact, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DatamodelServiceConstructor } from 'retailer/modules/datamodels/ts/interfaces/datamodel-vue-constructor.interface';
import { TranslationService } from '@/general/services/translations/translation.service';

/**
 * @description This service holds the logic for the datamodels
 */

export class DatamodelVueService {
  public isLoading = false;

  // #region vue and prime related
  private ts: TranslationService;
  private _datamodelService: DatamodelsRestService = new DatamodelsRestService();

  private _toastService: ToastService;
  private emitClose: () => void;
  private emitReload: () => void;

  private emitCloseError() {
    throw new Error('Close emit is not set.');
  }

  private emitReloadError() {
    throw new Error('Reload emit is not set.');
  }
  // #endregion

  constructor({ emitClose, emitReload }: DatamodelServiceConstructor) {
    this._toastService = ToastService.getInstance();
    this.emitClose = emitClose ?? this.emitCloseError;
    this.emitReload = emitReload ?? this.emitReloadError;

    const ts = new TranslationService('retailer', 'datamodels');
    this.ts = ts;
  }

  public async renameDatamodel({ id, name }: DatamodelCompact) {
    this.isLoading = true;
    try {
      this._datamodelService.update(id, {
        id: id,
        name: name,
        is_odm: false,
      });
      this.emitReload?.();
      this._toastService.displaySuccessToast(
        this.ts.tModule('datamodelRename.succes', { params: { name: name } }),
      );
    } catch (ex) {
      this._toastService.displayErrorToast(this.ts.unexpectedError);
    } finally {
      this.isLoading = false;
      this.emitClose?.();
    }
  }
}
