<script setup lang="ts">
import {
  Attribute,
  AttributeOptions,
  AttributeTypeEnum,
  KeyValueOptions,
} from 'platform-unit2-api/attributes';
import { DatamodelAttributeConfig } from 'platform-unit2-api/datamodels';
import useFormValidation from 'composables/form-validation';
import { onMounted, ref, watch } from 'vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { DatamodelsRestService } from 'platform-unit2-api/datamodels';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'datamodels');
const confirmService = new ConfirmService();
const datamodelApi = new DatamodelsRestService();
const loading = ref();

/** Constants */
const { fieldErrorMessage, hasError } = useFormValidation();
const editorField = ref<DatamodelAttributeConfig>();

/** Props */
const props = defineProps<{
  attribute: Attribute;
  datamodelId?: number;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'newOverride', override: DatamodelAttributeConfig): void;
  (e: 'close'): void;
  (e: 'refresh'): void;
}>();

/** Check type of the field */
const conditionallyRender = (requiredType: string) => {
  return editorField.value?.options?.type == requiredType;
};

/** manage choice field */
const addChoice = () => {
  if (!editorField.value?.options?.choices) {
    if (editorField.value?.options) editorField.value.options.choices = [];
  }

  editorField.value?.options?.choices?.push({ key: '', value: '' });
};

const deleteChoice = (index: number) => {
  editorField.value?.options?.choices?.splice(index, 1);

  if (editorField.value?.options?.choices?.length === 0) {
    delete editorField.value.options.choices;
  }
};

/** Remove empty options */
const getObjectWithoutEmptyValues = (object: AttributeOptions): AttributeOptions => {
  Object.keys(object).forEach((key) => {
    if ((object as any)[key].length === 0) {
      delete (object as any)[key];
    }
  });
  return object;
};

/** Get overrides */
const overrides = ref();
const getoverride = async () => {
  try {
    loading.value = true;

    if (!props.datamodelId) {
      overrides.value = [];
    } else {
      overrides.value = await datamodelApi.getFieldDefinitionsConfig(
        props.datamodelId,
        props.attribute.id,
      );
    }

    editorField.value = overrides.value
      ? overrides.value
      : {
          name: props.attribute.key,
          options: { ...props.attribute.options },
          required: props.attribute.required,
        };
  } finally {
    if (editorField.value) {
      editorField.value.name = editorField.value.name ?? props.attribute.key;
    }

    if (editorField.value?.options) {
      editorField.value.options.type = props.attribute?.options?.type ?? AttributeTypeEnum.DEFAULT;
    } //Because we don't save this option in database at the moment

    loading.value = false;
  }
};

/** Override the attributes */
const createOverride = async () => {
  if (!editorField.value || !props.datamodelId) return;

  try {
    loading.value = true;
    editorField.value.enabled = true;
    editorField.value.options = {
      ...editorField.value.options,
      ...{ type: props.attribute.options?.type ?? AttributeTypeEnum.DEFAULT },
    };

    await datamodelApi.setDatamodelFieldDefinitionsConfig(
      props.datamodelId,
      props.attribute.id,
      editorField.value,
    );

    emit('newOverride', editorField.value);
  } catch (error) {
    loading.value = false;
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    emit('refresh');
    emit('close');
  }
};

const confirmResetOverride = (event: PointerEvent) => {
  confirmService.confirmDelete({
    event: event,
    callback: () => resetOverride(),
    group: 'resetOverrides',
    message: ts.tModule('confirm_reset_override'),
  });
};

const resetOverride = async () => {
  if (!props.datamodelId) {
    toastService.displayErrorToast(ts.tModule('override_rejected'));
    return;
  }

  try {
    loading.value = true;

    await datamodelApi.deleteDatamodelAttributeOverride(props.datamodelId, [props.attribute.id]);

    emit('refresh');
    emit('close');

    toastService.displaySuccessToast(ts.tModule('override_deleted'));
  } catch {
    toastService.displayErrorToast(ts.tModule('override_rejected'));
  } finally {
    loading.value = false;
  }
};

const getTitle = () => {
  return `${ts.tModule('datamodels_overwrite.header.name_prepend')} "${
    props.attribute.key
  }" ${ts.tModule('datamodels_overwrite.header.name_append')}`;
};

/** onMounted lifecycle hook */
onMounted(() => {
  getoverride();
});

/** Watch the attribute changes and get the overrides */
watch(
  () => props.attribute,
  () => getoverride(),
);

watch(
  () => editorField.value,
  () => {
    if (editorField.value?.options)
      editorField.value.options = getObjectWithoutEmptyValues(editorField.value.options);
  },
  {
    deep: true,
  },
);
</script>

<template>
  <BaseDialog
    :title="getTitle()"
    :subtitle="ts.tModule('datamodels_overwrite.header.info_text')"
    :visible="true"
    size="medium"
    @update:visible="emit('close')"
  >
    <!-- #region: Attribute override  -->
    <div class="flex px-4 text-gray-800">
      <!-- Default values -->
      <div class="flex flex-column" :style="{ width: '47%' }">
        <span class="font-bold text-lg">{{ ts.tModule('datamodels_overwrite.body.default') }}</span>
        <div class="bg-gray-50 border-1 border-gray-100 flex flex-grow-1 my-2 p-3 pb-0">
          <div class="grid justify-content-center place-items-center text-center w-full">
            <div class="flex flex-column mx-2 p-3 w-full">
              <div class="flex mb-3 text-left w-full">
                <span class="font-bold w-5">{{
                  ts.tModule('datamodels_overwrite.body.name')
                }}</span>
                <span>{{ attribute.key }}</span>
              </div>
              <div class="flex mb-3 text-left w-full">
                <span class="font-bold w-5">{{
                  ts.tModule('datamodels_overwrite.body.field_type')
                }}</span>
                <span>{{ attribute.options && attribute.options['type'] }}</span>
              </div>
              <div class="flex mb-3 text-left w-full">
                <span class="font-bold w-5">{{
                  ts.tModule('datamodels_overwrite.body.required')
                }}</span>
                <span>{{ attribute.required ? 'Yes' : 'No' }}</span>
              </div>
              <div class="flex mb-3 text-left w-full">
                <span class="font-bold w-5">{{
                  ts.tModule('datamodels_overwrite.body.help_text')
                }}</span>
                <span>{{ attribute.options && attribute.options['helpText'] }}</span>
              </div>

              <div v-for="(value, key) in attribute.options" :key="key">
                <div v-if="key !== 'type' && key !== 'helpText'" class="flex mb-3 text-left w-full">
                  <span class="font-bold w-5">{{ key }}</span>
                  <span>{{ value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Divider -->
      <div :style="{ width: '6%' }">
        <p-divider layout="vertical">
          <i class="mdi mdi-progress-pencil rotate-90 text-3xl text-gray-600"></i
        ></p-divider>
      </div>

      <!-- override form -->
      <div
        v-if="loading"
        class="align-items-center flex justify-content-center"
        :style="{ width: '47%' }"
      >
        <LoadingIndicator />
      </div>
      <div v-else :style="{ width: '47%' }">
        <span class="font-bold text-lg">{{
          ts.tModule('datamodels_overwrite.form.overwrite')
        }}</span>
        <div class="my-2 p-3 pb-0">
          <div class="grid justify-content-center place-items-center text-center">
            <div class="flex flex-column text-left w-full">
              <!-- Name -->
              <div class="field mb-3">
                <label for="name" class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.body.name')
                }}</label>
                <p-input-text
                  v-if="editorField"
                  id="name"
                  v-model="editorField.name"
                  class="w-full"
                  :class="{ 'p-invalid': hasError('key') }"
                  type="text"
                />
                <small
                  v-if="hasError('key')"
                  :class="{ 'p-error block': hasError('key') }"
                  class="hidden"
                >
                  {{ fieldErrorMessage('key').toString() }}
                </small>
              </div>

              <!-- Requiered -->
              <div class="align-items-center field flex mb-3">
                <label class="font-bold mr-3 text-sm"
                  >{{ ts.tModule('datamodels_overwrite.body.required') }} ?</label
                >
                <div>
                  <pToggleSwitch
                    v-if="editorField?.options"
                    :model-value="editorField.options.required"
                    @update:model-value="
                      editorField?.options && (editorField.options.required = JSON.parse($event))
                    "
                  />
                </div>
              </div>

              <!-- Help text -->
              <div class="field mb-3">
                <label class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.body.help_text')
                }}</label>
                <div>
                  <p-textarea
                    v-if="editorField?.options"
                    v-model="editorField.options.helpText"
                    rows="2"
                    class="w-full"
                    :auto-resize="true"
                  />
                </div>
              </div>

              <!-- Max length -->
              <div class="field mb-3">
                <label class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.body.max_length')
                }}</label>
                <div>
                  <p-input-text
                    v-if="editorField?.options"
                    v-model="editorField.options.maxLength"
                    rows="2"
                    class="w-full"
                    :auto-resize="true"
                  />
                </div>
              </div>
              <div
                v-if="
                  conditionallyRender('MULTIPLE_CHOICE_FIELD') ||
                  conditionallyRender('CHOICE_FIELD')
                "
                class="field mb-3"
              >
                <label class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.form.choices')
                }}</label>

                <pAutoComplete
                  v-if="editorField?.options"
                  v-model="editorField.options.choices"
                  style="width: 100%"
                  multiple
                  :typeahead="false"
                />
              </div>

              <!-- Input-select values -->
              <div v-if="conditionallyRender('INPUT_SELECT_FIELD')" class="field mb-3">
                <label class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.form.input_select_values')
                }}</label>
                <pAutoComplete
                  v-if="editorField?.options"
                  v-model="editorField.options.selectValues"
                  style="width: 100%"
                  separator=","
                  multiple
                  :typeahead="false"
                />

                <div v-if="editorField?.options?.selectValues" class="flex flex-column mt-4">
                  <label class="font-bold text-sm">{{
                    ts.tModule('datamodels_overwrite.form.choose_default_unit')
                  }}</label>
                  <pSelect
                    v-model="editorField.options.default_unit"
                    class="mt-2"
                    :options="editorField.options.selectValues"
                  />
                </div>
              </div>

              <div v-if="conditionallyRender('FINANCIAL_FIELD')" class="field mb-3">
                <label class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.form.currencies')
                }}</label>
                <pAutoComplete
                  v-if="editorField?.options"
                  v-model="editorField.options.currencies"
                  style="width: 100%"
                  separator=","
                  multiple
                  :typeahead="false"
                />

                <div v-if="editorField?.options?.currencies" class="flex flex-column mt-4">
                  <label>{{
                    ts.tModule('datamodels_overwrite.form.choose_default_currency')
                  }}</label>
                  <pSelect
                    v-model="editorField.options.default_unit"
                    class="mt-2"
                    :options="editorField.options.currencies"
                  />
                </div>
              </div>

              <!-- Pre-defined options -->
              <div v-if="conditionallyRender('KEY_VALUE_FIELD')" class="field mb-3 w-full">
                <div class="field mb-3 w-full">
                  <div class="mb-2 w-full">
                    <label>{{ ts.tModule('datamodels_overwrite.form.select_type') }}</label>
                  </div>
                  <pToggleSwitch
                    :model-value="editorField?.options?.multiSelect"
                    @update:model-value="
                      editorField?.options && (editorField.options.multiSelect = JSON.parse($event))
                    "
                  />
                </div>
                <label class="font-bold text-sm">{{
                  ts.tModule('datamodels_overwrite.form.pre_defined_options')
                }}</label>
                <div class="h-22rem overflow-y-scroll pt-3 relative">
                  <div
                    v-for="(choice, index) in editorField?.options?.choices"
                    :key="index"
                    class="align-items-center flex mb-3"
                  >
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">Key</span>
                      <p-input-text
                        v-if="editorField?.options?.choices"
                        v-model="(editorField.options.choices[index] as KeyValueOptions).key"
                        placeholder="Key"
                        disabled
                      />
                    </div>
                    <span style="color: #708da9" class="mx-2">
                      <i class="mdi mdi-arrow-right"></i>
                    </span>
                    <div class="mr-1 p-inputgroup">
                      <span class="p-inputgroup-addon">Value</span>
                      <p-input-text
                        v-if="editorField?.options?.choices"
                        v-model="(editorField.options.choices[index] as KeyValueOptions).value"
                        placeholder="Value"
                        disabled
                      />
                    </div>
                    <p-button
                      style="color: #708da9"
                      text
                      rounded
                      class="flex-shrink-0 p-button-small"
                      icon="mdi mdi-delete-outline"
                      disabled
                      @click="deleteChoice(index)"
                    />
                  </div>
                  <p-button
                    style="color: #708da9"
                    class="bg-white block border-dashed bottom-0 mt-3 p-button-outlined p-button-secondary sticky w-full"
                    label="Add pair"
                    icon="mdi mdi-plus"
                    disabled
                    @click="addChoice"
                  ></p-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->

    <!--  #region: Footer -->
    <template #footer>
      <div class="align-items-start flex justify-content-between w-full">
        <div>
          <p-button
            icon="mdi mdi-reload"
            :disabled="overrides ? false : true"
            :label="ts.tModule('reset_override')"
            text
            severity="danger"
            @click="confirmResetOverride($event)"
          />
        </div>
        <div class="flex gap-3">
          <p-button :label="ts.tModule('cancel')" text plain @click="emit('close')" />
          <p-button
            :label="overrides ? ts.tGlobal('update') : ts.tGlobal('create')"
            :disabled="!editorField?.name"
            @click="createOverride"
          />
        </div>
      </div>
      <p-confirm-popup group="resetOverrides" />
    </template>
    <!-- #endregion -->
  </BaseDialog>
</template>
