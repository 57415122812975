import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'HTML editor',
  placeholder: '',

  copyContents: 'Copy HTML to clipboard',
  insert: 'Insert',
  instructions:
    '<p>Use this editor to style your text and convert it to HTML.</p><p>This editor supports basic formatting for <strong>bold</strong>, <em>italic</em>, and <u>underlined text</u>.</p><p>Bullet lists are also supported, but try to avoid using regular bullets ("•"). Instead use the bullet point button in this editor, which generates a list like this:</p><ul><li>Item a</li><li>Item b</li><li>Item c</li></ul><p>When creating sections, simply start the section with one of these:</p><h2>Large Heading</h2><h3>Smaller Heading</h3><p>Finally, when done editing press the "Copy HTML to clipboard" button.</p>',
  insertHtml: 'Insert HTML',
  insertHtmlPlaceholder: '<p>Insert raw HTML</p>',
  insertHtmlWarning: 'Note: this will replace your current input.',
  succesfullyCopied: 'Successfully copied HTML to clipboard!',
  dialog: {
    title: 'Insert HTML',
    subtitle: 'Here you can easily insert your HTML code',
  },
};

const tranlation: Translation = {
  editor: translationObject,
};

export default tranlation;
