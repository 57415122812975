<script setup lang="ts">
//vue
import { inject } from 'vue';

//types
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import { ReorderModuleEnum } from '../../../ts/enums/reorder-module.enum';
import { StoryModule } from '@/api/v1/content-story/ts/story-types/modules/story-modules.type';

//services
import { TranslationService } from '@/general/services/translations/translation.service';
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';

/** Props */
export interface Props {
  uuid: string;
}

const props = defineProps<Props>();

//services
const ts = new TranslationService('supplier', 'storyManager');

//inject/provide
const storyService = inject(storyBuilderServiceKey);

//const / refs
const storyModule: BaseStoryModule<StoryModule> | undefined = storyService?.value.getModule(
  props.uuid,
);
</script>

<template>
  <div class="absolute position w-4" data-testid="module-hover-state">
    <div class="flex w-full">
      <div
        class="bg-primary border-round-left-xs flex justify-content-center p-3 w-6"
        data-testid="hover-state-module-name"
      >
        <p>
          {{
            storyModule?.moduleTranslationKey
              ? ts.tModule(storyModule?.moduleTranslationKey)
              : ts.tModule('storyBuilder.emptyHoverState.defaultModule')
          }}
        </p>
      </div>
      <p-button
        icon="mdi mdi-chevron-up text-xl"
        :class="`p-button w-2 bg-white text-600 first-button ${
          !storyService?.isAbleToReorder(uuid, ReorderModuleEnum.UP) && 'disabled-button-override'
        }`"
        :disabled="!storyService?.isAbleToReorder(uuid, ReorderModuleEnum.UP)"
        @click="storyService?.reorderModule(uuid, ReorderModuleEnum.UP)"
      />
      <p-button
        icon="mdi mdi-chevron-down text-xl"
        :class="`p-button w-2 bg-white text-600 middle-button ${
          !storyService?.isAbleToReorder(uuid, ReorderModuleEnum.DOWN) && 'disabled-button-override'
        }`"
        :disabled="!storyService?.isAbleToReorder(uuid, ReorderModuleEnum.DOWN)"
        @click="storyService?.reorderModule(uuid, ReorderModuleEnum.DOWN)"
      />
      <p-button
        icon="mdi mdi-delete-outline text-xl"
        class="bg-white last-button p-button text-600 w-2"
        @click="storyService?.deleteStoryModule(uuid)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.position {
  z-index: 2;
  top: -1.5rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.last-button {
  border: 1px solid #cad9e7 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.middle-button {
  border: 1px solid #cad9e7 !important;
  border-radius: 0 !important;
  border-left: none !important;
  border-right: none !important;
}
.first-button {
  border: 1px solid #cad9e7 !important;
  border-radius: 0 !important;
}

.disabled-button-override {
  opacity: 1 !important;
  background-color: #e7ebee !important;
}
</style>
