<script setup lang="ts">
import { Metric, MetricData } from 'platform-unit2-api/dashboard';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';

const props = withDefaults(
  defineProps<{
    selectedDates?: string[];
    uriKey: string;
    refreshKey: number;
  }>(),
  {
    selectedDates: undefined,
    uriKey: '',
    refreshKey: undefined,
  },
);
/** Services */
const ts = new TranslationService('supplier', 'dashboard');

//** Constants */
const store = useStore();

const loading = ref(true);
const hasMetric = ref(false);
const hasError = ref(false);
const metric = ref<MetricData>();

const metricTranslateKey = computed(() => {
  return props.uriKey?.replace(new RegExp('-', 'g'), '_');
});

const getPercentage = (metric?: MetricData) => {
  if (!metric?.total) return 0;
  const result = (metric.difference / metric.total) * 100;
  return result < 0 ? Math.floor(result) : Math.ceil(result);
};

const getTrendIcon = (metric?: MetricData) => {
  if (!metric) return 'mdi-arrow-neutral';
  return metric.difference > 0
    ? 'mdi-arrow-up'
    : metric.difference < 0
    ? 'mdi-arrow-down'
    : 'mdi-arrow-neutral';
};

const getTextClass = (metric?: MetricData) => {
  if (!metric) return '';
  return metric.difference > 0 ? 'trend-up' : metric.difference < 0 ? 'trend-down' : '';
};

const setMetric = () => {
  const result = store.getters['dashboard/metrics'].find(
    (metric: Metric) => metric.uri_key === props.uriKey,
  );
  if (result) {
    metric.value = result.data;
    hasMetric.value = true;
  }
};

const getData = async () => {
  hasError.value = false;
  loading.value = true;

  let rangeStart = new Date();
  let rangeEnd = new Date();

  if (
    props.selectedDates == null ||
    props.selectedDates[0] == null ||
    props.selectedDates[1] == null
  ) {
    rangeStart.setDate(rangeStart.getDate());
    rangeStart.setHours(0, 0, 0, 0);

    rangeEnd.setHours(23, 59, 59, 999);
  } else {
    rangeStart = new Date(props.selectedDates[0]);
    rangeEnd = new Date(props.selectedDates[1]);
  }

  try {
    await store.dispatch('dashboard/GET_METRIC_DATA', {
      rangeStart,
      rangeEnd,
      uriKey: props.uriKey,
    });

    setMetric();
  } catch (err) {
    hasError.value = true;
  } finally {
    loading.value = false;
  }
};

watch(
  () => props.selectedDates,
  () => {
    if (props.selectedDates && props.selectedDates[0] != null && props.selectedDates[1] != null) {
      getData();
    }
  },
);

watch(
  () => props.refreshKey,
  () => {
    getData();
  },
);
</script>

<template>
  <pCard v-if="loading && !hasError">
    <template #subtitle>
      <pSkeleton class="mb-2" />
      <pSkeleton class="mb-2 w-6" />
    </template>
  </pCard>
  <pCard v-else-if="hasError">
    <template #content>
      <div class="align-items-center flex flex-column justify-content-center">
        <h4>
          {{ ts.tModule('user_dashboard.metrics.metric_data') }}
        </h4>
        <p-button class="p-button-rounded" :label="ts.tGlobal('retry')" @click="getData" />
      </div>
    </template>
  </pCard>
  <p-card v-else-if="hasMetric">
    <template #title>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-between">
          <h4>{{ ts.tModule(`user_dashboard.metrics.${metricTranslateKey}_title`) }}</h4>
          <span>
            {{ metric?.difference.toLocaleString() }}
            <i class="mdi" :class="[getTrendIcon(metric), getTextClass(metric)]" />
          </span>
        </div>
      </div>
    </template>
    <template #subtitle>
      <div class="flex flex-row">
        <span class="mr-3"
          >{{ metric?.total.toLocaleString() }}
          {{ ts.tModule('user_dashboard.metrics.in_total') }}</span
        >
        <span :class="getTextClass(metric)" class="mr-3">{{ getPercentage(metric) }}%</span>
      </div>
    </template>
  </p-card>
</template>

<style lang="scss" scoped>
.p-card :deep(.p-card-content) {
  padding: 0;
}
.p-card :deep(.p-card-footer) {
  padding: 0;
}
.p-progressbar {
  height: 0.5rem;
}
</style>
