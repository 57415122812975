export default function (): any {
  const getFileTypeColor = (filename: string): string => {
    const array = filename.split('.');
    const type = array[array.length - 1];
    let colorHEX = '';

    switch (type.toLowerCase()) {
      case 'pdf':
        colorHEX = '#EF4836';
        break;
      case 'zip':
        colorHEX = '#90C695';
        break;
      case 'tar':
      case 'rar':
        colorHEX = '#D34D58';
        break;
      case 'rtf':
      case 'txt':
        colorHEX = '#6D7A8A';
        break;
      case 'mp3':
        colorHEX = '#674172';
        break;
      case 'flac':
      case 'mp4':
        colorHEX = '#67809F';
        break;
      case 'webm':
      case 'avi':
        colorHEX = '#EF4836';
        break;
      case 'gif':
        colorHEX = '#4ECDC4';
        break;
      case 'mpg':
      case 'mov':
        colorHEX = '#1E8BC3';
        break;
      case 'docx':
      case 'docm':
      case 'odt':
      case 'doc':
        colorHEX = '#446CB2';
        break;
      case 'pptx':
      case 'pptm':
      case 'ppt':
        colorHEX = '#F2774B';
        break;
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
        colorHEX = '#F2774B';
        break;
      case 'csv':
        colorHEX = '#F7CA17';
        break;
      case 'eot':
      case 'otf':
      case 'ttf':
      case 'woff':
      case 'html':
        colorHEX = '#F99406';
        break;
      case 'js':
        colorHEX = '#67809F';
        break;
      case 'xml':
      case 'css':
        colorHEX = '#03C9A8';
        break;
      case 'php':
        colorHEX = '#D1527F';
        break;
      case 'psd':
        colorHEX = '#4183D7';
        break;
      case 'json':
        colorHEX = '#67809F';
        break;
      case 'wav':
        colorHEX = '#F37835';
        break;

      case 'mob':
        colorHEX = '#2474A9';
        break;
      case 'jpeg':
      case 'jpg':
        colorHEX = '#6CB9F1';
        break;
      case 'png':
        colorHEX = '#BF55ED';
        break;
      case 'eps':
        colorHEX = '#F7CA17';
        break;
      case 'dll':
        colorHEX = '#95A5A5';
        break;
      case 'raw':
        colorHEX = '#F7CA17';
        break;
      case 'tiff':
        colorHEX = '#6B846F';
        break;
      case 'bmp':
        colorHEX = '#EC6607';
        break;
      default:
        colorHEX = '#6B846F';
    }

    return colorHEX;
  };

  return {
    getFileTypeColor,
  };
}
