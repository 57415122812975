import { FeaturePermission } from 'platform-unit2-api/roles';
import Organisations from 'retailer/modules/settings/views/organisations.vue';
import Security from 'retailer/modules/settings/views/security.vue';
import Workspaces from 'retailer/modules/settings/views/workspaces.vue';
import Workspace from 'retailer/modules/settings/views/workspace.vue';
import Preferences from 'retailer/modules/settings/views/preferences.vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';
import PersonalInfo from 'retailer/modules/settings/views/profile.vue';
import NotificationPreferences from 'retailer/modules/settings/views/notification-preferences.vue';
import { RouterService } from '@/core/router/router.service';

const settingsRouteFactory = new RouteFactory({
  platform: 'retailer',
  module: 'settings',
  tabs: [
    {
      label: 'Preferences',
      key: 'profile-preferences',
      command: () => {
        RouterService.getInstance().router.push({ name: 'profile-preferences' });
      },
    },
    {
      label: 'Notifications',
      key: 'notifications',
      command: () => {
        RouterService.getInstance().router.push({ name: 'notifications' });
      },
    },
    {
      label: 'Organisation',
      key: 'organisation-settings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'organisation-settings' });
      },
    },
    {
      label: 'Workspace',
      key: 'workspace-settings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'workspace-settings' });
      },
    },
    {
      label: 'Connected workspaces',
      key: 'workspaces-settings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'workspaces-settings' });
      },
    },
  ],
});
const accountRouteFactory = new RouteFactory({
  tabs: [
    {
      label: 'Personal info',
      key: 'personal-info',
      command: () => {
        RouterService.getInstance().router.push({ name: 'personal-info' });
      },
    },
    {
      label: 'Security',
      key: 'security',
      command: () => {
        RouterService.getInstance().router.push({ name: 'security' });
      },
    },
  ],
});
const settingsRoutes: RouteRecordRaw[] = [
  {
    name: 'settings',
    path: '/settings',
    redirect: { name: 'preferences' },
  },

  settingsRouteFactory.createAppRoute({
    name: 'profile-preferences',
    path: '/settings/preferences',
    component: Preferences,
    title: 'Preferences',
  }),

  settingsRouteFactory.createAppRoute({
    name: 'notifications',
    path: '/settings/notifications',
    component: NotificationPreferences,
    title: 'Notifications',
  }),

  settingsRouteFactory.createAppRoute({
    name: 'organisation-settings',
    path: '/settings/organisation-settings',
    component: Organisations,
    title: 'Organisations',
  }),

  settingsRouteFactory.createAppRoute({
    name: 'workspaces-settings',
    path: '/settings/connected-workspaces',
    component: Workspaces,
    title: 'Workspace',
  }),

  settingsRouteFactory.createAppRoute({
    name: 'workspace-settings',
    path: '/settings/workspace-settings',
    component: Workspace,
    title: 'Workspace',
  }),
  //Account routes
  accountRouteFactory.createAppRoute({
    name: 'Account',
    path: '/account',
    redirect: { name: 'personal-info' },
  }),

  accountRouteFactory.createAppRoute({
    name: 'personal-info',
    title: 'Personal info',
    path: '/account/personal-info',
    rights: [FeaturePermission.MANAGE_PROFILE],
    component: PersonalInfo,
  }),
  accountRouteFactory.createAppRoute({
    name: 'security',
    path: '/account/security',
    title: 'Personal info',
    rights: [FeaturePermission.MANAGE_PROFILE],
    component: Security,
  }),
];

export default settingsRoutes;
