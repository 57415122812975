import { Translation } from '../../ts/interfaces/translation.interface';

const validation: Translation = {
  validations: {
    empty_field: '@:enter {name}',
  },
  validation: {
    accepted: 'The field must be accepted.',
    accepted_if: 'The field must be accepted when another field is accepted.',
    active_url: 'The field is not a valid URL.',
    after: 'The field must be on a later day',
    after_or_equal: 'The field must be a date after or equal to another date',
    alpha: 'The field must only contain letters.',
    alpha_dash: 'The field must only contain letters, numbers, dashes and underscores.',
    alpha_num: 'The field must only contain letters and numbers.',
    array: 'The field must be an array.',
    before: 'The field must be an earlier date',
    before_or_equal: 'The field must be earlier than or equal to that date.',
    between: {
      numeric: 'The field must be within the margin',
      file: 'The field must be within a certain kilobytes.',
      string: 'The field does not satisfy the minimum or maximum amount of characters.',
      array: 'The field must does not satisfy the minimum or maximum amount of items.',
    },
    boolean: 'The field must be true or false.',
    confirmed: 'The field confirmation does not match.',
    current_password: 'The password is incorrect.',
    date: 'The field is not a valid date.',
    date_equals: 'The field must be a date equal to another date.',
    date_format: 'The field does not match correct date format.',
    different: 'This field must be different.',
    digits: 'The field does not have enough/has to much digits.',
    digits_between: 'The field does not satisfy the maximum or minimum amount of digits.',
    dimensions: 'The field has invalid image dimensions.',
    distinct: 'The field has a duplicate value.',
    email: 'The field must be a valid e-mail address.',
    ends_with: 'The field must end with one of the following: :values.',
    exists: 'The selected field is invalid.',
    fields: {
      invalidNumber: 'Invalid number',
      invalidUnit: 'Invalid unit of measure',
      invalidCurrency: 'Invalid currency',
    },
    file: 'The field must be a file.',
    filled: 'The field must have a value.',
    koag: 'it is not a valid KAOG number. The field should be like this (123-1234-1234)',
    gt: {
      numeric: 'The field must be greater than :value.',
      file: 'The field must be greater than :value kilobytes.',
      string: 'The field must be greater than :value characters.',
      array: 'The field must have more than :value items.',
    },
    gte: {
      numeric: 'The field must be greater than or equal :value.',
      file: 'The field must be greater than or equal :value kilobytes.',
      string: 'The field must be greater than or equal :value characters.',
      array: 'The field must have :value items or more.',
    },
    gtin: {
      numeric: 'GTIN must be numeric',
      length: 'The length of the GTIN should be {length} digits',
    },
    image: 'The field must be an image.',
    in: 'The selected field is invalid.',
    in_array: 'The field does not exist in :other.',
    integer: 'The field must be an integer.',
    ip: 'The field must be a valid IP address.',
    ipv4: 'The field must be a valid IPv4 address.',
    ipv6: 'The field must be a valid IPv6 address.',
    json: 'The field must be a valid JSON string.',
    lt: {
      numeric: 'The field must be less than :value.',
      file: 'The field must be less than :value kilobytes.',
      string: 'The field must be less than :value characters.',
      array: 'The field must have less than :value items.',
    },
    lte: {
      numeric: 'The field must be less than or equal :value.',
      file: 'The field must be less than or equal :value kilobytes.',
      string: 'The field must be less than or equal :value characters.',
      array: 'The field must not have more than :value items.',
    },
    max: {
      numeric: 'The field must not be greater than { max }.',
      file: 'The field must not be greater than { max } kilobytes.',
      string: 'The field must not be greater than { max } characters.',
      array: 'The field must not have more than :max items.',
    },
    mimes: 'The field must be a file of type: :values.',
    mimetypes: 'The field must be a file of type: :values.',
    min: {
      numeric: 'The field must be at least :min.',
      file: 'The field must be at least :min kilobytes.',
      string: 'The field must be at least :min characters.',
      array: 'The field must have at least :min items.',
    },
    multiple_of: 'The field must be a multiple of :value.',
    not_in: 'The selected field is invalid.',
    not_regex: 'The field format is invalid.',
    numeric: 'The field must be a number.',
    password: 'The password is incorrect.',
    channels: {
      password: 'The password for this channel must be ...',
    },
    present: 'The field must be present.',
    regex: 'The field format is invalid.',
    required: 'The field is required.',
    required_if: 'The field is required when :other is :value.',
    required_unless: 'The field is required unless :other is in :values.',
    required_with: 'The field is required when :values is present.',
    required_with_all: 'The field is required when :values are present.',
    required_without: 'The field is required when :values is not present.',
    required_without_all: 'The field is required when none of :values are present.',
    prohibited: 'The field is prohibited.',
    prohibited_if: 'The field is prohibited when :other is :value.',
    prohibited_unless: 'The field is prohibited unless :other is in :values.',
    same: 'The field and :other must match.',
    size: {
      numeric: 'The field must be :size.',
      file: 'The field must be :size kilobytes.',
      string: 'The field must be :size characters.',
      array: 'The field must contain :size items.',
    },
    starts_with: 'The field must start with one of the following: :values.',
    string: 'The field must be a string.',
    timezone: 'The field must be a valid timezone.',
    unique: 'The field has already been taken.',
    uploaded: 'The field failed to upload.',
    url: 'The field must be a valid URL.',
    uuid: 'The field must be a valid UUID.',
    custom: {
      attributename: {
        rulename: 'custom-message',
      },
    },
  },
};

export default validation;
