<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import UserAvatar from 'ui/components/user-avatar.vue';
import WorkspaceMenuContent from './workspace-menu-content.vue';
import { User } from 'platform-unit2-api/users';

const props = defineProps<{
  isCollapsed: boolean;
}>();

const store = useStore();
const currentUser = computed((): User => store.getters['users/currentUser']);

/** Toggle profile menu */
const isMenuOpen = ref(false);
const profileDiv = ref<HTMLDivElement>();
const profileDivsize = reactive({
  width: '86%',
  height: '100px',
});
const menuDiv = ref<HTMLDivElement>();
const menuDivHeight = ref('600px');

const toggleProfileMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  setMenuSize();
};

const hideMenu = () => {
  isMenuOpen.value = false;
};

const setMenuSize = () => {
  profileDivsize.width = profileDiv.value ? profileDiv.value.offsetWidth + 'px' : '240px';
  profileDivsize.height = profileDiv.value ? profileDiv.value.offsetHeight + 12 + 'px' : '100px';
  menuDivHeight.value = menuDiv.value ? menuDiv.value.offsetHeight + 100 + 'px' : '600px';
};

const calculateMenuWidth = computed(() => {
  if (isMenuOpen.value) {
    return props.isCollapsed ? '220px' : profileDivsize.width;
  } else {
    return props.isCollapsed ? 0 : profileDivsize.width;
  }
});

watch(
  () => props.isCollapsed,
  () => (isMenuOpen.value = false),
);
</script>
<template>
  <div class="border-top"></div>
  <!-- Workspace Menu -->
  <div
    ref="menuDiv"
    class="menu"
    :style="{
      width: calculateMenuWidth,
      bottom: isMenuOpen ? profileDivsize.height : '-' + menuDivHeight,
      display: isMenuOpen ? 'block' : 'none',
    }"
  >
    <WorkspaceMenuContent :current-user="currentUser" :is-collapsed="isCollapsed" />
  </div>

  <!-- Workspace link box -->
  <div
    ref="profileDiv"
    v-click-outside="hideMenu"
    class="mb-2 profile"
    :class="{ active: isMenuOpen, collapsed: isCollapsed }"
    @click="toggleProfileMenu"
  >
    <!-- Avatar -->
    <div
      v-tooltip.right="{
        value: currentUser.workspace?.name,
        disabled: !isCollapsed,
      }"
      class="avatar"
    >
      <!-- Workspace thumbnail -->
      <UserAvatar
        class="flex-shrink-0"
        :url="
          currentUser?.workspace?.thumbnail?.public_thumbnail_url ||
          currentUser?.workspace?.thumbnail?.public_url
        "
        :username="currentUser?.workspace?.name"
        style="width: 32px; height: 32px"
        shape="square"
      />
    </div>
    <!-- Workspace name -->
    <div v-if="!isCollapsed">
      <strong class="name">{{ currentUser?.workspace?.name }} </strong>
    </div>
    <!-- Chevron icon -->
    <div v-if="!isCollapsed" class="chevron">
      <i class="mdi mdi-unfold-more-horizontal text-2xl" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.border-top {
  margin-top: auto;
  margin-left: 16px;
  margin-right: 16px;
}
.profile {
  position: relative;
  width: auto;
  box-sizing: content-box;
  padding: 8px;
  color: $white;
  margin: 0 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: transform $animation-duration ease, background-color $animation-duration ease,
    border $animation-duration ease, padding $animation-duration ease;
  cursor: pointer;
  border: 1.5px solid transparent;
  border-radius: 3px;
  z-index: 15;

  .avatar {
    margin-right: 10px;
    transition: all $animation-duration ease;
  }
  .username {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;

    strong {
      display: block;
    }

    span {
      font-size: 12px;
    }
  }
  .chevron {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  &.active {
    border: 1.5px solid #0089d7;
    background-color: #00609766;
    border-radius: 3px;
    z-index: 15;
  }

  &.collapsed {
    padding: 5px;
  }

  &:hover:not(.collapsed) {
    background-color: #00609766;

    z-index: 15;
    .avatar {
      transform: scale(1.06);
    }
  }
}
.menu {
  position: fixed;
  background: #fff;
  border: 1px solid #cad9e7;
  left: 8px;
  border-radius: 3px;
  // transition: bottom $animation-duration ease, width $animation-duration ease;
  z-index: 10;
  overflow: hidden;
  white-space: nowrap;
}

.name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
