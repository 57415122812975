<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { Ref, provide, ref } from 'vue';
import { importMappingServiceKey } from '../ts/keys/import-mapping.keys';
import { tsKey } from '@/general/services/translations/translation.key';

import OverView from '@/general/ui/views/over-view/over-view.vue';
import ImportMappingTable from '../components/display/import-mapping-table.vue';
import ImportMappingNewDialog from '../components/crud/import-mapping-crud-dialog.vue';

import { useService } from '@/general/composables/useService/useService';
import { RetailerImportMappingItemsViewService } from '../../import-mapping-items/ts/services/retailer-import-mapping-items-view.service';
import { importMappingItemsServiceKey } from '../../import-mapping-items/ts/keys/import-mapping-items.keys';
import { useRetailerImportMappingService } from '../ts/useImportMappingService';

import MappingDuplicateWarningModal from 'retailer/modules/import-mappings/components/duplicate/import-mapping-duplicate-warning-modal.vue';
import ImportMappingDuplicateModal from 'retailer/modules/import-mappings/components/duplicate/import-mapping-duplicate-modal.vue';

const ts = ref(new TranslationService('retailer', 'importMappings'));

const service = useRetailerImportMappingService();
useService(importMappingItemsServiceKey, RetailerImportMappingItemsViewService);

provide(importMappingServiceKey, service);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView platform="retailer" module-name="importMappings" :service="service">
    <template #content>
      <ImportMappingTable />
    </template>
    <template #crudComponent>
      <ImportMappingNewDialog />
    </template>
    <template #others>
      <!-- confirm dialogs/popups -->
      <pConfirmDialog group="delete-dialog" />

      <pConfirmPopup :group="service.confirmPopUpGroup" />

      <!-- duplicate -->
      <ImportMappingDuplicateModal />

      <!-- If the mapping already exists -->
      <MappingDuplicateWarningModal />
    </template>
  </OverView>
</template>
