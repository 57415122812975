<script lang="ts" setup>
//Components
import EmptyState from '@/general/ui/components/empty-state.vue';
import { MenuItem } from 'primevue/menuitem';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';

//Composables and Services
import { jsonSchemaServiceKey } from '../ts/keys/json-schemas-service.key';
import { inject } from 'vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { JsonSchema } from 'platform-unit2-api/json-schemas';
import { AmazonLocales } from '../ts/amazon-locales.enum';
import { capitalize } from 'lodash';

/** Services */
const ts = inject(tsKey)!;
const jsonSchemasService = inject(jsonSchemaServiceKey!);

const menuItems = (jsonSchema: JsonSchema): MenuItem[] => [
  {
    label: ts.value.tModule('menuItems.viewInBrowser'),
    icon: 'mdi mdi-open-in-new',
    command: () => jsonSchemasService?.value.viewInBrowser(jsonSchema),
  },
  {
    label: ts.value.tGlobal('download'),
    icon: 'mdi mdi-download',
    command: () => jsonSchemasService?.value.downloadJsonSchema(jsonSchema),
  },
  {
    separator: true,
  },
  {
    label: ts.value.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => jsonSchemasService?.value.confirmDeleteJsonSchema(jsonSchema),
  },
];
</script>
<template>
  <pDataTable
    v-if="jsonSchemasService != null && ts != null"
    :paginator="jsonSchemasService.total > (jsonSchemasService.limit ?? 0)"
    :value="jsonSchemasService.jsonSchemas"
    filter-display="menu"
    lazy
    removable-sort
    auto-layout
    scrollable
    scroll-height="flex"
    :loading="jsonSchemasService.isLoadingOverView"
    :rows="jsonSchemasService.limit"
    :first="jsonSchemasService.first"
    :total-records="jsonSchemasService.total"
    :sort-field="jsonSchemasService.sortField"
    :sort-order="jsonSchemasService.sortOrder"
    @page="jsonSchemasService.onPageChange($event.page)"
    @sort="jsonSchemasService.onSortChange($event)"
  >
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-label="ts.tModule('retrieveJsonSchema.dialogTitle')"
        :button-icon="'mdi mdi-plus'"
        :empty-state-title="ts.tModule('emptyStateTitle')"
        :icon-name="'json'"
        @clicked="jsonSchemasService.openCrudComponent()"
      />
    </template>

    <pColumn field="product_type" :header="ts.tModule('tableHeaders.productType')" />
    <pColumn field="marketplace_id.value" :header="ts.tModule('tableHeaders.marketplaceId')" />
    <pColumn field="seller_type" :header="ts.tModule('tableHeaders.merchant')">
      <template #body="{ data }: { data: JsonSchema }">
        {{ capitalize(data.seller_type) }}
      </template>
    </pColumn>
    <pColumn field="locale" :header="ts.tModule('tableHeaders.language')">
      <template #body="{ data }: { data: JsonSchema }">
        {{ AmazonLocales[data.locale as keyof typeof AmazonLocales] }}
      </template>
    </pColumn>
    <pColumn field="updated_at" :header="ts.tModule('tableHeaders.lastUpdated')" />

    <pColumn>
      <template #body="{ data }: { data: JsonSchema }">
        <ActionsMenu :menu-items="menuItems(data)" />
      </template>
    </pColumn>
  </pDataTable>
</template>
