<script setup lang="ts">
import { inject } from 'vue';
import { modulesServiceKey } from 'supplier/modules/modules/ts/keys/modules.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import CredentialEditSettingsFields from './credential-edit-settings-fields.vue';
import CredentialEditDynamicSettingsFields from './credential-edit-dynamic-settings-fields.vue';

const service = inject(modulesServiceKey)!;
const ts = inject(tsKey)!;
</script>
<template>
  <form>
    <div class="field flex mb-3">
      <div :style="{ width: '50px' }">{{ ts.tModule('credential_edit.name') }}</div>
      <div class="text-blue-800">{{ service.partialObject.name }}</div>
    </div>

    <div class="field flex mb-3">
      <div :style="{ width: '50px' }">{{ ts.tModule('credential_edit.type') }}</div>
      <div class="text-blue-800">{{ service.partialObject.type }}</div>
    </div>

    <!-- Generate input fields based on CredentialValues -->
    <div v-if="service.partialObject.settings_fields">
      <div v-for="(_, key) in service.credentialValues" :key="key">
        <CredentialEditSettingsFields v-if="key !== 'dynamic_settings'" :field-name="key" />
        <CredentialEditDynamicSettingsFields v-else />
      </div>
    </div>
  </form>
</template>
