import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import ProductNew from 'supplier/modules/products/components/product-new.vue';
import Products from 'supplier/modules/products/views/products.view.vue';
import DeletedProducts from 'supplier/modules/products/views/deleted-products.view.vue';
import DeletedProductsHeader from 'supplier/modules/products/components/deleted-products/deleted-products-header.vue';

import ProductAttributes from 'supplier/modules/products/views/product-attributes.view.vue';
import ProductDetailsView from 'supplier/modules/products/views/product-details.view.vue';

import ProductAssets from 'supplier/modules/products/views/product-assets.view.vue';
import ProductStory from 'supplier/modules/products/views/product-story.view.vue';
import ProductCategory from 'supplier/modules/products/views/product-category.view.vue';
import ProductHierarchy from 'supplier/modules/products/views/product-hierarchy.view.vue';

import Describe from 'supplier/modules/tasks/components/add-task/describe.view.vue';
import Assign from 'supplier/modules/tasks/components/add-task/assign.view.vue';
import Settings from 'supplier/modules/tasks/components/add-task/settings.view.vue';
import Channel from 'supplier/modules/products/views/export/channel.view.vue';
import Contact from 'supplier/modules/products/views/export/contact.view.vue';
import SettingsExport from 'supplier/modules/products/views/export/settings-export.view.vue';
import ScheduleExport from 'supplier/modules/products/views/export/schedule.view.vue';
import InfoExport from 'supplier/modules/products/views/export/info-export.view.vue';
import Type from 'supplier/modules/products/views/export-generic/type.view.vue';
import GenericExport from 'supplier/modules/products/views/export-generic/generic.view.vue';
import ProductOverviewGenericExport from 'supplier/modules/products/views/export-generic/product-overview.view.vue';
import { RouteRecordRaw } from 'vue-router';

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'products',
});

export async function getProductRoutes(): Promise<RouteRecordRaw[]> {
  return [
    routeFactory.createAppRoute({
      path: '/products',
      name: 'products',
      component: Products,
      rights: [FeaturePermission.READ_PRODUCTS],
      title: 'Products',

      children: [
        routeFactory.createChildRoute({
          path: 'new',
          name: 'new-product',
          component: ProductNew,
          title: 'Create product',
          rights: [FeaturePermission.MANAGE_PRODUCTS],
        }),
        routeFactory.createChildRoute({
          path: 'task/add',
          name: 'products-new-task',
          redirect: { name: 'products-new-task-describe' },
        }),
        routeFactory.createChildRoute({
          path: 'task/add/describe',
          name: 'products-new-task-describe',
          component: Describe,
        }),
        routeFactory.createChildRoute({
          path: 'task/add/assign',
          name: 'products-new-task-assign',
          component: Assign,
        }),
        routeFactory.createChildRoute({
          path: 'task/add/settings',
          name: 'products-new-task-settings',
          component: Settings,
        }),
        routeFactory.createChildRoute({
          name: 'export-channel',
          path: 'export/channel',
          component: Channel,
          title: 'Select Channel for Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-settings',
          path: 'export/settings',
          component: SettingsExport,
          title: 'Select Settings for Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-schedule',
          path: 'export/schedule',
          component: ScheduleExport,
          title: 'Schedule the Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-contact',
          path: 'export/contact',
          component: Contact,
          title: 'Select Contact for Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-info',
          path: 'export/info',
          component: InfoExport,
          title: 'Confirm the Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-type',
          path: 'export/type',
          component: Type,
          title: 'Select Type of Export',
        }),
        routeFactory.createChildRoute({
          name: 'export-generic',
          path: 'export/generic',
          component: GenericExport,
          title: 'Export Type generic',
        }),
        routeFactory.createChildRoute({
          name: 'export-product-overview',
          path: 'export/products',
          component: ProductOverviewGenericExport,
          title: 'Product overview',
        }),
      ],
    }),
    routeFactory.createAppRoute({
      path: '/products/:id',
      name: 'product-details',
      component: ProductDetailsView,
      title: 'Product',
      rights: [FeaturePermission.READ_PRODUCTS],
      children: [
        routeFactory.createChildRoute({
          path: 'attributes',
          name: 'product-attributes',
          component: ProductAttributes,
          title: 'Product attributes',
        }),
        routeFactory.createChildRoute({
          path: 'assets',
          name: 'product-assets',
          component: ProductAssets,
          title: 'Product assets',
        }),
        routeFactory.createChildRoute({
          path: 'story',
          name: 'product-story',
          component: ProductStory,
          title: 'Product story',
        }),
        routeFactory.createChildRoute({
          path: 'category',
          name: 'product-category',
          component: ProductCategory,
          title: 'Product categories',
        }),
        routeFactory.createChildRoute({
          path: 'hierarchy',
          name: 'product-hierarchy',
          component: ProductHierarchy,
          title: 'Product hierarchy',
        }),
      ],
    }),
    routeFactory.createAppRoute({
      path: '/products/deleted-products',
      name: 'deleted-products',
      component: DeletedProducts,
      header: DeletedProductsHeader,
      rights: [FeaturePermission.MANAGE_PRODUCTS],
      title: 'Deleted Products',
    }),
  ];
}

export default getProductRoutes;
