import { ApiAccessToken, ApiAccessTokenRestService } from 'platform-unit2-api/api-access-tokens';
import { User } from 'platform-unit2-api/users';
import { Store, useStore } from 'vuex';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

import moment from 'moment';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
export class DeveloperService {
  private store: Store<any>;
  private toast: ToastService;
  private confirmService;

  private translation: TranslationService;
  private apiService: ApiAccessTokenRestService;
  private _currentUser: User | undefined = undefined;
  private _apiKey: ApiAccessToken[] = [];
  private _loading = false;

  private _regenerateLoading = false;
  private _isCopiedToClipboard = false;

  private _isApiKeyHidden = true;

  constructor(store?: Store<any>, toast?: ToastService, translation?: TranslationService) {
    this.store = store || useStore();
    this.toast = toast || ToastService.getInstance();
    this.translation = translation || new TranslationService('supplier', 'developers');
    this._currentUser = this.store.getters['users/currentUser'];
    this.apiService = new ApiAccessTokenRestService();
    this.confirmService = new ConfirmService();
  }

  async getApiKey() {
    this._loading = true;
    try {
      this._apiKey = (await this.apiService.getAll()).data;
    } catch (error) {
      this.toast.displayErrorToast(this.translation.tModule('get_key_failed'));
    } finally {
      this._loading = false;
    }
  }

  async generateNewKey() {
    this._loading = true;
    try {
      await this.apiService.post({});
      await this.getApiKey();
      this.toast.displaySuccessToast(this.translation.tModule('generate_key_success'));
    } catch (error) {
      this.toast.displayErrorToast(this.translation.tModule('generate_key_failed'));
    } finally {
      this._loading = false;
    }
  }

  async deleteKey(id: number) {
    this._loading = true;
    try {
      await this.apiService.delete(id);
      this.toast.displaySuccessToast(this.translation.tModule('delete_key_success'));
    } catch (error) {
      this.toast.displayErrorToast(this.translation.tModule('delete_key_failed'));
    } finally {
      await this.getApiKey();
      this._loading = false;
    }
  }

  async refreshKey(id: number) {
    this._regenerateLoading = true;
    try {
      await this.apiService.refresh(id);
      this.toast.displaySuccessToast(this.translation.tModule('refresh_key_success'));
      await this.getApiKey();
    } catch (error) {
      this.toast.displayErrorToast(this.translation.tModule('refresh_key_failed'));
    } finally {
      this._regenerateLoading = false;
    }
  }

  async copyToClipboard(text: string) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this._isCopiedToClipboard = true;
          setTimeout(() => {
            this._isCopiedToClipboard = false;
          }, 1000);
        })
        .catch(() => {
          this.toast.displayErrorToast(this.translation.tModule('copy_to_clipboard_failed'));
        });
    }
  }

  isTokenActive(ExpirationDate: Date) {
    return moment(ExpirationDate).diff(moment(new Date()), 'day') > 0;
  }

  changeApiKeyVisibility() {
    this._isApiKeyHidden = !this._isApiKeyHidden;
  }

  hiddenKey(key: string) {
    return this.isApiKeyHidden ? `${key.slice(0, 4)}***************${key.slice(-4)}` : key;
  }

  confirmApiDelete(id: number) {
    this.confirmService.confirmDelete({
      callback: () => this.deleteKey(id),
      group: 'api',
      header: this.translation.tModule('delete_api_key'),
      message: this.translation.tModule('delete_confirmation'),
      acceptLabel: this.translation.tGlobal('delete'),
    });
  }

  confirmApiGenerate(id: number) {
    this.confirmService.confirmDelete({
      callback: async () => {
        await this.refreshKey(id);
      },
      group: 'api',
      header: this.translation.tModule('regenerate_api_key'),
      message: this.translation.tModule('regenerate_confirmation'),
      acceptLabel: this.translation.tModule('regenerate_btn'),
    });
  }

  get apiKey() {
    return this._apiKey;
  }

  get isApiKeyHidden() {
    return this._isApiKeyHidden;
  }

  get currentUser() {
    return this._currentUser;
  }

  get loading() {
    return this._loading;
  }

  get regenerateLoading() {
    return this._regenerateLoading;
  }

  get isCopiedToClipboard() {
    return this._isCopiedToClipboard;
  }
}
