/**
 * Added this file because we cant use pinea at this point in time 13/7/2023
 * This is because we need to upgrade the dependencies of the platform
 * this will be done later this summer, when this is done this implementation
 * can be removed and we can use pinea.
 */

import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';
import { getters } from './story-manager.getters';
import { mutations } from './story-manager.mutations';
import { actions } from './story-manager.actions';

const namespaced = true;
export interface StoryManagerVuexState {
  loading: boolean;
  activeStoryInStoryBuilder?: ContentStory;
  changesSaved: boolean;
}
export const state: StoryManagerVuexState = {
  loading: true,
  changesSaved: false,
  activeStoryInStoryBuilder: undefined,
};

export const storyManagerStore = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
