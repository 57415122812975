<script setup lang="ts">
import { ref } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';

import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { DeleteButton } from '@/general/ui/components/crud-buttons/ts/classes/delete-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  ids: () => [],
  isActive: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'refresh'): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const store = useStore();
const includeVariants = ref(false);
const exceptThumbnail = ref(false);
const remove = async () => {
  try {
    buttonsOptions.value.saving = true;
    await store.dispatch('products/BULK_ACTION_DETACH_ASSETS', {
      ids: props.ids,
      includeVariants: includeVariants.value,
      exceptThumbnail: exceptThumbnail.value,
    });
    toastService.displaySuccessToast(
      ts.tModule('product_assets_old.detach_assets.detached_successfully', {
        params: {
          amount: props.ids.length,
        },
      }),
    );
    emit('hide');
    emit('refresh');
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('product_assets_old.detach_assets.detached_failed'));
  } finally {
    buttonsOptions.value.saving = false;
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const deleteButton = new DeleteButton({
  label: ts.tGlobal('remove'),
  position: CrudButtonPosition.RIGHT,
  onClick: remove,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, deleteButton],
});
</script>
<template>
  <BaseDialog
    :title="ts.tModule('product_assets_old.detach_assets.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="emit('hide')"
  >
    <div class="mb-3">
      <p class="mb-3">
        {{
          ts.tModule('product_assets_old.detach_assets.confirm_message', {
            params: { amount: ids.length },
          })
        }}
      </p>
      <div class="field-checkbox">
        <p-checkbox v-model="includeVariants" :binary="true" />
        <label>{{ ts.tModule('product_assets_old.detach_assets.include_variants') }}</label>
      </div>
      <div class="field-checkbox">
        <p-checkbox v-model="exceptThumbnail" :binary="true" />
        <label>{{ ts.tModule('product_assets_old.detach_assets.except_thumbnail') }}</label>
      </div>
    </div>
  </BaseDialog>
</template>
