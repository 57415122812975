<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { Ref, provide, ref } from 'vue';
import { SupplierImportMappingItemsViewService } from 'supplier/modules/import-mapping-items/ts/services/supplier-import-mapping-items-view.service';
import OverView from '@/general/ui/views/over-view/over-view.vue';
import ImportMappingItemsTable from 'supplier/modules/import-mapping-items/components/import-mapping-items-table.vue';
import { importMappingItemsServiceKey } from 'supplier/modules/import-mapping-items/ts/keys/import-mapping-items.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import { MenuItem } from 'primevue/menuitem';
import { useService } from '@/general/composables/useService/useService';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';

const ts = ref(new TranslationService('supplier', 'importMappingItems'))!;
const service = useService(importMappingItemsServiceKey, SupplierImportMappingItemsViewService)!;

const menuItems: MenuItem[] = [
  {
    label: ts?.value.tGlobal('delete'),
    command: () => {
      service.delete();
    },
    class: 'delete',
    icon: 'mdi mdi-delete',
  },
];

provide(importMappingItemsServiceKey, service);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView platform="supplier" module-name="importMappingItems" :service="service">
    <template #actions>
      <pButton
        :disabled="service.isLoadingOverView || !service.isDirty"
        class="ml-auto mr-2 px-5"
        @click="service?.syncImportMappings()"
      >
        {{ ts.saveButton() }}
      </pButton>
      <ActionsMenu :menu-items="menuItems" />
    </template>
    <template #skeleton>
      <LoadingIndicator :columns="2" :has-thumbnail="false" />
    </template>
    <template #content>
      <ImportMappingItemsTable v-if="!service.isLoadingOverView && service.isInitialized" />
    </template>
  </OverView>
</template>
