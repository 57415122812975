import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Category',
  subTitle: 'Here you can create a new Category.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Category',
};

const update: TranslationObjectAction = {
  title: 'Edit category',
  subTitle: 'Here you can edit an category.',
};

const translationObject: TranslationObject = {
  title: 'Category | Categories',
  placeholder: 'Select a category | Select Categories',
  emptyStateSubtitle:
    'Start by creating or importing your first category.\n When a category is created or added it will show here.',

  bulkAttachDescription: 'Here you can bulk attach your products to a category',
  bulkAttachSuccess: 'Successfully attached {count} products their respective categories',
  bulkAttachTitle: 'Bulk attach categories',
  bulkAttachNoVariants: 'No variants selected',
  parent: 'Parent',
  parentDescription:
    'This should be the category you want your category to be part of. Leave empty if you want it as a root category.',

  import: {
    title: 'Import Categories',
    subtitle: 'Here you can import categories from file',
  },

  detachAttributeSuccess: 'Successfully detached attribute from the category',
  detachAttributeError: 'There was a problem with detaching your attribute from the category',
  getAttributesError: 'There was a problem with getting the attributes for this category',
  noAttributes: 'No attributes',

  detach: 'Detach',

  create: create,
  delete: deleteTranslation,
  update: update,
};

const tranlation: Translation = {
  categories: translationObject,
};

export default tranlation;
