<script setup lang="ts">
//vue
import { Ref, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';

//types
import { storyBuilderServiceKey } from '../ts/helpers/story-manager-keys';

//services / composables
import StoryManagerService from '../services/story-manager.service';
import { StoryBuilderViewerService } from '../services/story-builder-viewer.service';
import { useStore } from 'vuex';
import { DebounceService } from '@/general/services/debounce-service/debounce.service';

//services
const storyManagerService = new StoryManagerService();
const storyBuilderViewerService = ref<StoryBuilderViewerService>(new StoryBuilderViewerService());
const debounceService = new DebounceService(1500);
const store = useStore();

//provide/inject
provide(storyBuilderServiceKey, storyBuilderViewerService as Ref<StoryBuilderViewerService>);

onMounted(async () => {
  const story = await storyManagerService.getStory();
  if (story != null) {
    storyBuilderViewerService.value.currentStory = story;
    store.dispatch('storyManager/UPDATE_CURRENT_STORY', story);
    store.dispatch('storyManager/UPDATE_LOADING', false);
  }
});
//life cycles
onBeforeUnmount(() => {
  storyManagerService.resetStory();
  debounceService.cancel();
  //if added to prevent the toast error to be popped when sale demo,
  if (storyBuilderViewerService.value.currentStory?.is_draft) {
    storyBuilderViewerService.value.saveStory();
  }
});

watch(
  () =>
    storyBuilderViewerService.value.hasChanges() &&
    JSON.stringify(storyBuilderViewerService.value.moduleData),
  () => {
    //if added to prevent the toast error to be popped when sale demo,
    if (
      storyBuilderViewerService.value.hasChanges() &&
      storyBuilderViewerService.value.currentStory?.is_draft
    ) {
      debounceService.debounce(() => storyBuilderViewerService.value.saveStory());
    } else {
      debounceService.cancel();
    }
  },
);
</script>

<template>
  <router-view
    class="gray-content-builder h-full"
    :current-story-loading="storyBuilderViewerService.currentStory == null"
  />
</template>
<style scoped>
.gray-content-builder {
  background-color: #f5f7fa;
}
</style>
