<script lang="ts" setup>
import BaseSelect from './base-select.vue';
import { computed } from 'vue';
import {
  Datamodel,
  DatamodelCompact,
  GetDatamodelsRequest,
  DatamodelsRestService,
} from 'platform-unit2-api/datamodels';
import { DefaultSelectPropsWithCompact } from './ts/default-select-props.interface';

interface Props extends DefaultSelectPropsWithCompact<Datamodel, DatamodelCompact> {
  datamodelsToExclude?: Datamodel[] | DatamodelCompact[];
  options?: Datamodel[] | DatamodelCompact[]; // only show these options if provided
  workspaceId?: number;
  flattenChildrenAttributes?: boolean;
  onlyMaster?: boolean;
  templateId?: number;
  channelId?: number;
}

const props = defineProps<Props>();
const data = computed<GetDatamodelsRequest>(() => ({
  only_master: props.onlyMaster,
  template_id: props.templateId,
  channel_id: props.channelId,
  flatten_children_attributes: props.flattenChildrenAttributes,
  workspace_id: props.workspaceId,
}));

const datamodelRestService = DatamodelsRestService;
</script>
<template>
  <BaseSelect
    :rest-service="datamodelRestService"
    option-label="name"
    display="chip"
    :custom-fetch-params="[{ ...data }]"
    :edit-options="(datamodels: Datamodel[]) => datamodels.filter(datamodel => !props.datamodelsToExclude?.map((c) => c.id).includes(datamodel.id))"
    module-name="datamodel"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
