import { App, ComponentPublicInstance } from 'vue';

export function registerWarnHandler(app: App) {
  const ignoreStrictWarnings = import.meta.env.VITE_APP_IGNORE_STRICT_WARNINGS;

  if (!ignoreStrictWarnings && window.location.hostname === 'localhost') {
    app.config.warnHandler = function (
      msg: string,
      instance: ComponentPublicInstance | null,
      trace: string,
    ) {
      if (msg.includes('Failed to resolve component:')) {
        console.error('MISSING IMPORT IN VUE FILE: ' + msg, '\n' + trace);
        throw msg;
      }

      if (msg.includes('Extraneous non-props attributes')) {
        console.warn('COMPONENT HAS MORE THAN ONE PARENT ELEMENT\n', msg, '\n' + trace, instance);
        return;
      }

      if (msg.includes('Missing required prop:')) {
        console.error(
          msg + '\n',
          "Make sure you mark the prop as optional if it doesn't always need to be there, and give it a !DEFAULT value!. If the prop is not optional, double-check you're not sending an undefined value.\n",
          trace,
          instance,
        );
        return;
      }

      if (msg.includes('Invalid prop:')) {
        console.error(msg + '\n', trace);
        return;
      }

      console.warn(msg, instance, trace);
    };
  }
}
