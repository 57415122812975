<script setup lang="ts">
import { computed, watchEffect, ref, onMounted } from 'vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { DatamodelVueService } from 'retailer/modules/datamodels/services/datamodel-vue.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';

/**Props */
const props = defineProps<{
  datamodel: Datamodel;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'reload'): void;
  (e: 'close'): void;
}>();

function emitClose(): void {
  emit('close');
}

function emitReload(): void {
  emit('reload');
}

/** Constants */
const datamodelVueService = ref(
  new DatamodelVueService({ emitClose: emitClose, emitReload: emitReload }),
);
const ts = new TranslationService('retailer', 'datamodels');

/** Create datamodel and pass the id */
const datamodelName = ref<string>();
const datamodelNameEl = ref();

const renameDatamodel = async () => {
  if (submitDisabled.value) return;
  datamodelVueService.value.renameDatamodel({
    id: props.datamodel.id,
    name: datamodelName.value!,
  });
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('close');
  },
});

const createButton = new CreateButton({
  label: ts.tModule('rename'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !datamodelVueService.value.isLoading || !submitDisabled.value,
  onClick: renameDatamodel,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, createButton],
});

watchEffect(() => {
  if (datamodelNameEl.value) {
    datamodelNameEl.value.$el.focus();
  }
});

onMounted(() => {
  datamodelName.value = props.datamodel.name;
});

/** return disable/enable status of submit button */
const submitDisabled = computed(() => {
  return !datamodelName.value;
});
</script>

<template>
  <BaseDialog
    :visible="true"
    :buttons-options="buttonsOptions"
    :title="ts.tModule('datamodelRename.title')"
    :subtitle="ts.tModule('datamodelRename.subtitle')"
    size="small"
    @update:visible="emit('close')"
  >
    <!-- #region: Attribute override  -->
    <LoadingIndicator v-if="datamodelVueService.isLoading" />
    <div v-else class="flex flex-column py-2">
      <p-input-text
        ref="datamodelNameEl"
        v-model="datamodelName"
        class="w-full"
        type="text"
        :placeholder="ts.tModule('datamodelRename.title')"
        @keyup.enter="renameDatamodel"
      >
      </p-input-text>
    </div>
    <!-- #endregion -->
  </BaseDialog>
</template>
