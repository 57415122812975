import {
  AttributeField,
  ProductAttributeField,
  FieldErrorEnum,
  ProductAttributeFieldError,
} from 'platform-unit2-api/attribute-fields';
import { MaxLengthAttributeFieldService } from '../max-length-attribute-field.service';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class GtinFieldService extends MaxLengthAttributeFieldService {
  private MAX_LENGTH = 14;
  private ALLOWED_LENGHTS = [8, 12, 13, 14];

  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }

  get maxLength(): number {
    return this.MAX_LENGTH;
  }

  public validate(): boolean {
    // Call the base class validate method
    const baseValidationResult = super.validate();

    // Additional check if GTIN is a number
    if (
      this.productAttributeField.value != '' &&
      this.productAttributeField.value != null &&
      !new RegExp('^[0-9]+$').test(this.productAttributeField.value)
    ) {
      this.productAttributeField.errors.push({
        message: this.ts.t('validation.gtin.numeric'),
        severity: FieldErrorEnum.ERROR,
      } as ProductAttributeFieldError);
      return false;
    }

    // Additional check if GTIN has a specific length
    if (
      this.productAttributeField.value != '' &&
      this.productAttributeField.value != null &&
      this.ALLOWED_LENGHTS?.indexOf(this.textAreaLength()) === -1
    ) {
      this.productAttributeField.errors.push({
        message: this.ts.t('validation.gtin.length', {
          params: { length: this.ALLOWED_LENGHTS.join(', ') },
        }),
        severity: FieldErrorEnum.ERROR,
      } as ProductAttributeFieldError);
      return false;
    }

    return baseValidationResult;
  }
}
