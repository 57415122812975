<script setup lang="ts">
import BaseSelect from './base-select.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';
import { ActionStatus, ActionStatusRestService } from 'platform-unit2-api/action-statuses';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';

const props = defineProps<DefaultSelectProps<ActionStatus>>();
const actionStatusRestService: any = ActionStatusRestService;
</script>
<template>
  <BaseSelect
    :rest-service="actionStatusRestService"
    option-label="label"
    data-key="label"
    display="chip"
    class="w-full"
    module-name="taskStatus"
    v-bind="{ ...$attrs, ...props }"
  >
    <template #value="slotProps: { value: ActionStatus | ActionStatus[] }">
      <StatusChip
        v-if="!Array.isArray(slotProps.value)"
        :label="slotProps.value?.label"
        :severity="ActionStatusSeverityEnum[slotProps.value?.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
        label-only
      />
      <span v-else>
        <StatusChip
          v-for="status in slotProps.value"
          :key="status.value"
          :label="status.label"
          :severity="ActionStatusSeverityEnum[status.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
          label-only
        />
      </span>
    </template>
    <template #option="slotProps: { option: ActionStatus }">
      <StatusChip
        :label="slotProps.option.label"
        :severity="ActionStatusSeverityEnum[slotProps.option?.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
        label-only
      />
    </template>
  </BaseSelect>
</template>
