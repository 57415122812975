<script setup lang="ts">
//Core
import { inject, ref, watch } from 'vue';

//Components
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';

//Types
import { useRetailerImportMappingService } from '../../ts/useImportMappingService';
import { tsKey } from '@/general/services/translations/translation.key';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';

const service = useRetailerImportMappingService();
const ts = inject(tsKey)!;

const cancelButton = new CancelButton({
  label: ts.value.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => service.closeDuplicateDialog(),
});

const duplicateButton = new CreateButton({
  label: ts.value.tGlobal('duplicate'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !service.isDuplicateButtonDisabled && !service.isSavingDuplicate,
  onClick: () => service.validateDuplicate(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, duplicateButton],
});

watch(
  () => service.duplicateWorkspaceTarget,
  () => (service.duplicateDatamodelTarget = undefined),
);
</script>
<template>
  <BaseDialog
    :visible="service.duplicateDialog"
    :title="ts.tModule('duplicate_mappings.modal_header')"
    :subtitle="ts.tModule('duplicate_mappings.modal_description')"
    :buttons-options="buttonsOptions"
    @update:visible="() => service.closeDuplicateDialog()"
  >
    <div class="field mb-3">
      <div class="flex flex-column gap-2 mb-3">
        <label>{{ ts.tGlobal('name') }}</label>
        <pInputText v-model="service.partialObject.name" />
      </div>
    </div>

    <!-- Workspace -->
    <div class="field mb-3">
      <label class="block mb-1">{{ ts.tGlobal('workspace') }}</label>
      <pSelect
        v-model="service.duplicateWorkspaceTarget"
        :options="service.userWorkspaces"
        class="w-full"
        option-label="name"
        data-key="id"
        filter
        :placeholder="ts.tModule('duplicate_mappings.modal_client_placeholder')"
      />
    </div>

    <!-- Data model -->
    <div class="field mb-3">
      <DatamodelSelect
        v-model="service.duplicateDatamodelTarget"
        :workspace-id="service.duplicateWorkspaceTarget?.id"
        :disabled="!service.duplicateWorkspaceTarget"
      />
    </div>
  </BaseDialog>
</template>
