<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';

const ts = new TranslationService('general', 'views');
const router = useRouter();
const route = useRoute();
const errorCode = route.params['errorCode'];
</script>

<template>
  <div
    style="min-height: 90vh"
    class="align-items-center bg-white border-round flex justify-content-center p-4 shadow-1"
  >
    <div class="mt-4 text-center">
      <img class="m-auto" src="@/assets/images/403.svg" style="width: 30%" />
      <h3 class="my-3 text-6xl">{{ ts.tModule('auth.noPermission.title') }}</h3>
      <p class="mb-5 text-xl">
        {{
          ts.tModule(
            errorCode
              ? `auth.noPermission.errorCodes.${errorCode}`
              : 'auth.noPermission.description',
          )
        }}
      </p>
      <p-button :label="ts.tModule('auth.previousRoute')" @click="router.go(-1)" />
    </div>
  </div>
</template>
