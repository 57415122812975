\
<script setup lang="ts">
//vue
import { inject } from 'vue';

//components
import ContentHeadline from './content-headline.vue';
import ContentImage from './content-image.vue';
import ContentTextarea from './content-textarea.vue';

//types
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';

//services
import StandardFourImageTextClass from '@/api/v1/content-story/ts/classes/four-image-text.class';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
export interface Props {
  uuid: string;
  block: 'block1' | 'block2' | 'block3' | 'block4';
  customStyle?: string;
  customTextBoxStyle?: string;
}

const props = withDefaults(defineProps<Props>(), {
  customStyle: 'width: 33%;"',
  customTextBoxStyle: '"80%"',
});

// inject / provide
const storyService = inject(storyBuilderServiceKey);

// const / refs
const storyModule = storyService?.value.getModule<StandardFourImageTextClass>(props.uuid);
const ts = new TranslationService('supplier', 'storyManager');

/** Lifecycle */
</script>

<template>
  <div class="flex gap-2 h-full justify-content-center pt-5" :style="props.customStyle">
    <div class="flex flex-column">
      <ContentImage
        :custom-container-class="'w-full'"
        :minimal-image-size="storyModule?.minimumImageSize"
        :accepted-file-types="storyModule?.acceptedFileTypes"
        :upload-url="storyModule?.getUploadUrl(block)"
        :is-upload-set="storyModule?.hasUploadSet(block)"
        @uploaded-image="storyModule?.setUploadData($event, block)"
      />
      <ContentHeadline
        :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
        :custom-class="'mt-3'"
        :headline="storyModule?.getAltText(block)"
        @headline-value="storyModule?.setAltText($event, block)"
      />
    </div>

    <div class="flex flex-column max-width-35">
      <ContentHeadline
        :custom-class="'mb-2'"
        :headline="storyModule?.data[block]?.headline?.value"
        @headline-value="storyModule?.setHeadline(block, $event)"
      />
      <ContentTextarea
        :textbox="storyModule?.data[block]?.body?.value"
        :additional-container-style="props.customTextBoxStyle"
        @html-text-area="storyModule?.setTextArea(block, $event)"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.max-width-35 {
  max-width: 35% !important;
}
</style>
