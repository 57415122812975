import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { ICrudButton } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button.interface';

export default class BaseButton implements ICrudButton {
  isEnabled: () => boolean;
  label: string | (() => string);
  type: 'submit' | 'reset' | 'button';
  position: CrudButtonPosition;
  iconPos?: 'left' | 'right' | 'top' | 'bottom';
  onClick: () => void;
  class: string;
  buttonIcon: string;

  constructor(
    isEnabled: () => boolean,
    label: string | (() => string),
    type: 'submit' | 'reset' | 'button',
    position: CrudButtonPosition,
    styleClasses: string,
    buttonIcon: string,
    onClick: () => void,
    iconPos?: 'left' | 'right' | 'top' | 'bottom',
  ) {
    this.isEnabled = isEnabled;
    this.type = type;
    this.label = label;
    this.position = position;
    this.onClick = onClick;
    this.class = styleClasses;
    this.buttonIcon = buttonIcon;
    this.iconPos = iconPos;
  }
}
