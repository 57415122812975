<script setup lang="ts">
import OverView from '@/general/ui/views/over-view/over-view.vue';
import { Ref, provide, ref } from 'vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import { DatamodelViewService } from '@/general/services/overview-service/modules/datamodel-view.service';
import { datamodelServiceKey } from '../ts/keys/datamodels.keys';
import DatamodelTable from '../components/overview/datamodel-table.vue';
import DatamodelNaming from '../components/overview/datamodel-naming.vue';

const ts = ref(new TranslationService('supplier', 'datamodels'));
const service = ref(new DatamodelViewService()) as unknown as Ref<DatamodelViewService>;

provide(datamodelServiceKey, service);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="supplier" module-name="datamodels" :service="service">
    <template #content>
      <DatamodelTable />
    </template>
    <template #crudComponent>
      <DatamodelNaming />
    </template>
  </OverView>
</template>
