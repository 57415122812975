import { KeycloakService } from '@/core/security/keycloak.service';
import { ApplicationTaskService } from '../application.task.service';

export class KeyCloakTask extends ApplicationTaskService {
  public async handle(): Promise<void> {
    await KeycloakService.getInstance().init();

    this._complete();
  }

  constructor() {
    super('keycloak');
  }
}
