import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Download | Downloads',
  placeholder: '',

  downloadReady: 'Your files are ready!',
  senderMessage: '{name} from the {workspace} workspace has send you some files.',
  downloading: 'Downloading...',
  downloadAll: 'Download all {amount} files',
  helpText: 'Having trouble?  <span class="underline cursor-pointer">Send us a message</span>',
  noDownloadsTitle: 'Oops, looks like something went wrong!',
  noDownloadsSubtitle: 'Please check the Export or contact our support team',
  goToExports: 'Return to exports',
};

const translation: Translation = {
  downloads: translationObject,
};

export default translation;
