export class ConfirmDialogPhraseService {
  public refDialogPhrase: any | undefined;

  public typedPhrase = '';
  public phrase = '';

  public get isPhraseMatched(): boolean {
    return this.phrase === this.typedPhrase;
  }

  public get dialogIsVisible(): boolean {
    return this.refDialogPhrase != null && this.refDialogPhrase?.$data.visible;
  }

  constructor(_phrase: string) {
    this.phrase = _phrase;
  }

  public setDialogPhraseRef(ref: any) {
    if (this.refDialogPhrase != null || ref == null) {
      return;
    }

    this.refDialogPhrase = ref;
  }

  public cleanUp() {
    this.typedPhrase = '';
  }
}
