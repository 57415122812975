import {
  Product,
  ProductLinkedChildrenRequest,
  ProductsRestService,
} from 'platform-unit2-api/products';
import { ListResponse } from 'platform-unit2-api/core';
import { Datamodel } from 'platform-unit2-api/datamodels';

export class ProductService {
  private _api = new ProductsRestService();
  // #region rest api

  public saveLinkedProducts = async (
    productId: number,
    linkedProducts: ProductLinkedChildrenRequest[],
  ): Promise<Product> => await this._api.saveLinkedProducts(productId, linkedProducts);

  public getProductDatamodels = async (productId: number): Promise<ListResponse<Datamodel>> =>
    await this._api.getProductDatamodels(productId);

  // #endregion
}
