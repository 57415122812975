<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Tiptap from 'supplier/modules/editor/components/tiptap.vue';
import UploadBox from 'ui/components/upload-box.vue';
import { FeaturePermission } from 'platform-unit2-api/roles';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { authorizationMethods } from '@/general/composables/authorization.plugin';
import { UploadedFile } from 'platform-unit2-api/core';
import { ProductStory } from 'platform-unit2-api/product-stories';

/**Props */
const props = defineProps<{
  story: ProductStory;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'input', value: boolean): void;
}>();

const { hasPermission } = authorizationMethods;

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();

/** Consts */
const store = useStore();
const editing = ref(false);
const loading = ref(false);
const deleting = ref(false);
const storyValue = ref<string>();
const showNewImageComponent = ref(false);
const imageUrl = ref<string>();

const saveProductStory = async (): Promise<void> => {
  loading.value = true;

  try {
    await store.dispatch('products/UPDATE_PRODUCT_STORY', {
      story: {
        data: storyValue.value ? storyValue.value : '',
        product_id: store.getters['products/currentProduct'].id,
      },
      storyId: props.story.id,
    });
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    loading.value = false;
  }
};

const deleteStory = async (): Promise<void> => {
  deleting.value = true;
  try {
    await store.dispatch('products/DELETE_PRODUCT_STORY', props.story.id);
    emit('input', true);
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    deleting.value = false;
  }
};

const clickDeleteStory = (event: PointerEvent): void => {
  if (storyValue.value !== '' && props.story.id > 0) {
    confirmService.confirmDelete({
      event: event,
      callback: () => deleteStory(),
      group: 'story',
      message: ts.deleteConfirm(),
    });
  } else {
    deleteStory();
  }
};

const cancelEditing = async (): Promise<void> => {
  if (storyValue.value === '' && props.story.data === '') {
    await deleteStory();
  } else {
    storyValue.value = props.story.data;
  }

  showNewImageComponent.value = false;
  editing.value = false;
};

const reloadData = async (): Promise<void> => {
  loading.value = true;
  try {
    await store.dispatch(
      'products/GET_PRODUCT_STORIES',
      store.getters['products/currentProduct'].id,
    );
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tModule('product_story.title')));
  } finally {
    loading.value = false;
  }
};

const toggleEditing = async (): Promise<void> => {
  if (editing.value) {
    await saveProductStory();
    await reloadData();
  }

  editing.value = !editing.value;
};

const showImageComponent = (): void => {
  showNewImageComponent.value = true;
};

const hideNewImageComponent = (): void => {
  showNewImageComponent.value = false;
};

const insertImage = (file: UploadedFile): void => {
  if (file) {
    imageUrl.value = file.url;
    hideNewImageComponent();
  }
};

watch(
  () => props.story,
  () => {
    if (props.story.data !== '' && storyValue.value === '') {
      storyValue.value = props.story.data;
    }
  },
);

onMounted(() => {
  storyValue.value = props.story.data;
  if (!storyValue.value || storyValue.value === '') {
    editing.value = true;
  }
});
</script>
<template>
  <div class="border-1 border-200 border-round mb-5 p-4">
    <div class="flex justify-content-between mb-3">
      <div>
        <p-button
          plain
          text
          :icon="!loading ? 'mdi mdi-delete' : 'mdi mdi-loading pi-spin'"
          @click="clickDeleteStory($event)"
        />
      </div>
      <div>
        <p-button v-if="editing" plain text class="mr-2" @click="cancelEditing">
          {{ ts.tGlobal('cancel') }}
        </p-button>
        <p-button
          :icon="editing ? 'mdi mdi-content-save-outline' : 'mdi mdi-pencil-outline'"
          @click="toggleEditing"
        />
      </div>
    </div>
    <div v-if="!editing && storyValue !== ''" class="content editor-content" v-html="storyValue" />
    <div
      v-if="editing && hasPermission(FeaturePermission.MANAGE_PRODUCTS)"
      class="border-1 border-200 editor-wrapper w-full"
      style="min-height: 300px"
    >
      <Tiptap
        :model-value="storyValue"
        :image-url="imageUrl"
        @update:model-value="($event) => (storyValue = $event)"
        @add-image="showImageComponent"
      ></Tiptap>
    </div>
    <div v-if="showNewImageComponent" class="editor__uploadBox mt-3">
      <UploadBox @input="insertImage($event)" />
    </div>

    <p-confirm-popup group="story" />
  </div>
</template>
<style lang="scss">
/* Basic editor styles */
.ProseMirror,
.editor-content {
  padding: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  ul {
    list-style-type: disc !important;
  }
  ol {
    list-style-type: decimal !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 80%;
    height: auto;
    margin: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  iframe,
  video {
    width: 100%;
    aspect-ratio: 16/9;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  &.ProseMirror-focused {
    outline: none;
  }
}
</style>
