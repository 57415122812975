<script setup lang="ts">
import { ref, watch, inject } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { LocaleDetails } from 'platform-unit2-api/locales';
import { isNegative } from '@/general/utils/isNegative';

/** Props */
const props = withDefaults(
  defineProps<{
    datamodel?: Datamodel;
    isLoading: boolean;
    locales: LocaleDetails[];
    filteredLocales: LocaleDetails[];
  }>(),
  {
    datamodel: undefined,
    fields: () => [],
    isLoading: false,
    locales: () => [],
    filteredLocales: () => [],
  },
);

/** Services */
const ts = new TranslationService('retailer', 'products');

/** Consts */
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

const calculatedFilledInFields = ref<number>(0);

watch(
  () =>
    productAttributeFieldService.value.isSaving ||
    productAttributeFieldService.value.hasLocalesChanged ||
    props.datamodel?.name,
  () => {
    const dmAttributes = props.datamodel?.attributes.map((attribute) => attribute.id);

    calculatedFilledInFields.value =
      productAttributeFieldService.value.getFilledInAttributesForDatamodel(dmAttributes ?? []) ??
      calculatedFilledInFields.value;
  },
  {
    deep: true,
  },
);
</script>
<template>
  <section>
    <div class="bg-white border-200 border-bottom-1 sticky top-0 z-2">
      <div class="align-items-center flex justify-content-between mb-3">
        <h3 v-if="datamodel && $route.name === 'product-attributes'">
          {{ datamodel?.name }}
          <h5 class="inline-block m-0 text-gray-400">
            ({{ isNegative(calculatedFilledInFields) ? 0 : calculatedFilledInFields }}/{{
              datamodel?.attributes_count
            }})
          </h5>
        </h3>
        <h3 v-else-if="$route.name === 'product-assets' || $route.name === 'product-story'">
          {{ $route.name === 'product-assets' ? ts.tGlobal('assets') : ts.tModule('story') }}
        </h3>
      </div>
    </div>
  </section>
</template>
