<script setup lang="ts">
import { User } from 'platform-unit2-api/users';
import store from '@/core/store';
import { TranslationService } from '@/general/services/translations/translation.service';
import { computed } from 'vue';

const ts = new TranslationService('general', 'components');

const currentUser = computed<User>(() => store.getters['users/currentUser']);

const billingUsers = computed(() => {
  return store.getters['users/currentUser'].organisation.billing_users.map((user: any) => user.id);
});
</script>

<template>
  <pMessage
    v-if="
      currentUser &&
      !currentUser.admin_mode &&
      currentUser?.organisation?.friendly_payment_reminder &&
      billingUsers.includes(currentUser.id)
    "
    severity="warn"
    style="height: 40px"
    :sticky="true"
    >{{ ts.tModule('app.friendly_payment_reminder') }}</pMessage
  >
</template>
