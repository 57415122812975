import { DashboardState } from 'supplier/modules/dashboard/dashboard.types';
import { actions } from 'supplier/modules/dashboard/store/dashboard.actions';
import { getters } from 'supplier/modules/dashboard/store/dashboard.getters';
import { mutations } from 'supplier/modules/dashboard/store/dashboard.mutations';
import { Module } from 'vuex';

const namespaced = true;
export const state: DashboardState = {
  data: undefined,
  metrics: [],
  cached_at: undefined,
};

export const dashboardStore: Module<DashboardState, {}> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
