// Specifying the color palette
export const colorPalette = {
  surface: {
    0: '#ffffff',
    50: '#f3f6f9',
    100: '#e8edf2',
    200: '#dee5ec',
    300: '#d0d9e2',
    400: '#a3afbb',
    500: '#536271',
    600: '#2f4255',
    700: '#13293D',
    800: '#424242',
    900: '#212121',
  },
  skyBlue: {
    0: '#e5f3fb',
    50: '#cce7f7',
    100: '#cce7f7',
    200: '#cce7f7',
    300: '#99d0ef',
    400: '#66b8e7',
    500: '#33a1df',
    600: '#0089d7',
    700: '#006097',
    800: '#00456c',
  },
  gray: {
    50: '#f3f6f9',
    100: '#e8edf2',
    200: '#dee5ec',
    300: '#d0d9e2',
    400: '#a3afbb',
    500: '#536271',
    600: '#2f4255',
    700: '#13293D',
    800: '#424242',
    900: '#212121',
  },
  azure: {
    50: '#d4f1ff',
    100: '#d4f1ff',
    200: '#d4f1ff',
    300: '#a9e3ff',
    400: '#7fd5ff',
    500: '#54c7ff',
    600: '#29b9ff',
    700: '#1d81b3',
    800: '#145d80',
  },
  blue: {
    50: '#cfd9e4',
    100: '#cceeff',
    200: '#a3e1ff',
    300: '#7ad4ff',
    400: '#52c6ff',
    500: '#29b9ff',
    600: '#239dd9',
    700: '#1d82b3',
    800: '#17668c',
  },
  iris: {
    50: '#e2def7',
    100: '#e2def7',
    200: '#e2def7',
    300: '#c6bef0',
    400: '#a99de8',
    500: '#8d7de1',
    600: '#705cd9',
    700: '#4e4098',
    800: '#382e6d',
  },
  green: {
    50: '#d5efdc',
    100: '#d5efdc',
    200: '#d5efdc',
    300: '#aadeb9',
    400: '#80ce95',
    500: '#6bc684',
    600: '#2bad4f',
    700: '#1e7937',
    800: '#155727',
  },
  red: {
    50: '#F043590A',
    100: '#fcd9de',
    200: '#fcd9de',
    300: '#f9b4bd',
    400: '#f68e9b',
    500: '#f3697a',
    600: '#f04359',
    700: '#a82f3e',
    800: '#78212c',
  },
  orange: {
    50: '#ffe6d5',
    100: '#ffe6d5',
    200: '#ffe6d5',
    300: '#ffceab',
    400: '#ffb580',
    500: '#ff9d56',
    600: '#ff842c',
    700: '#b35c1f',
    800: '#804216',
  },
  yellow: {
    50: '#fff6d8',
    100: '#fff6d8',
    200: '#fff6d8',
    300: '#feedb2',
    400: '#fee58b',
    500: '#fddc65',
    600: '#fdd33e',
    700: '#b1942b',
    800: '#7f6a1f',
  },
};

// Defining theme colors & main styling
export const semantic = {
  primary: {
    0: '{skyBlue.0}',
    50: '{skyBlue.50}',
    100: '{skyBlue.50}',
    200: '{skyBlue.50}',
    300: '{skyBlue.300}',
    400: '{skyBlue.400}',
    500: '{skyBlue.500}',
    600: '{skyBlue.600}',
    700: '{skyBlue.700}',
    800: '{skyBlue.800}',
  },
  colorScheme: {
    primary: {
      color: '{skyBlue.600}',
      inverseColor: '#ffffff',
      hoverColor: '#33a1df',
      activeColor: '#33a1df',
    },
    highlight: {
      color: '{surface.0}',
      background: '{primary.color}',
      inverseColor: '#ffffff',
      hoverColor: '#cce7f7',
      activeColor: '#006097',
    },
    danger: {
      color: '{red.600}',
      hoverColor: '{red.500}',
    },
    formField: {
      borderRadius: '3px',
      borderColor: '{gray.200}',
      hoverBorderColor: '{gray.200}',
      placeholderColor: '{gray.400}',
      focusRingShadow: 'none',
      focusBorderColor: '{primary.color}',
      disabledBackground: 'white',
      disabledOpacity: '0.5 !important',
    },
    focusRing: {},
  },
};
