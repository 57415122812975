<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ScheduledImportViewService } from '@/general/services/overview-service/modules/scheduled-import-view.service';
import { scheduledImportServiceKey } from '../../ts/keys/scheduled-import-service.key';
import { TranslationService } from '@/general/services/translations/translation.service';

const service = useService(scheduledImportServiceKey, ScheduledImportViewService);
const ts = new TranslationService('retailer', 'imports');
</script>
<template>
  <div v-if="service.partialObject.settings" class="m-5">
    <div class="p-2">
      <div>
        <div class="font-bold mb-2">{{ ts.tModule('select_source') }}</div>
        <div class="align-items-center flex gap-2">
          <p-checkbox
            v-model="service.partialObject.settings.import_data"
            :binary="true"
            :class="{
              error:
                !service.partialObject.settings?.import_assets &&
                !service.partialObject.settings?.import_data,
            }"
          />
          <label class="mb-0 mr-4">{{ ts.tModule('attributes') }}</label>

          <p-checkbox
            v-model="service.partialObject.settings.import_assets"
            :binary="true"
            :class="{
              error:
                !service.partialObject.settings.import_assets &&
                !service.partialObject.settings.import_data,
            }"
          />
          <label class="mb-0">{{ ts.tModule('assets') }}</label>
        </div>
        <small
          v-if="
            !service.partialObject.settings.import_assets &&
            !service.partialObject.settings.import_data
          "
          id="username2-help"
          class="p-error"
        >
          {{ ts.tModule('error.selectSource') }}
        </small>
      </div>
      <div class="my-3" style="border-top: 1px solid #ecf1f5"></div>
      <div class="flex flex-row justify-content-between">
        <div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="trim_ean">{{ ts.tModule('trim_ean') }}</label>
            <p-checkbox
              v-model="service.partialObject.settings.trim_ean"
              input-id="trim_ean"
              :binary="true"
              class="mr-3"
            />
          </div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="update_brand">{{ ts.tModule('update_brands') }}</label>
            <p-checkbox
              v-model="service.partialObject.settings.update_brand"
              input-id="update_brand"
              :binary="true"
              class="mr-3"
            />
          </div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="multiple_variants">{{ ts.tModule('multiple_variants') }}</label>
            <p-checkbox
              v-model="service.multipleVariants"
              input-id="multiple_variants"
              :binary="true"
              class="mr-3"
            />
          </div>
        </div>
        <div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="update_category">{{ ts.tModule('update_category') }}</label>
            <p-checkbox
              v-model="service.partialObject.settings.update_categories"
              input-id="update_category"
              :binary="true"
              class="mr-3"
            />
          </div>

          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="update_variants">{{ ts.tModule('update_variants') }}</label>
            <p-checkbox
              v-model="service.partialObject.update_variants"
              input-id="update_variants"
              :binary="true"
              class="mr-3"
            />
          </div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="overwrite_data">{{ ts.tModule('overwrite_data') }}</label>
            <p-checkbox
              v-model="service.partialObject.settings.overwrite_data"
              input-id="overwrite_data"
              :binary="true"
              class="mr-3"
            />
          </div>
        </div>
        <div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="update_title">{{ ts.tModule('update_title') }}</label>
            <p-checkbox
              v-model="service.partialObject.settings.update_title"
              input-id="update_title"
              :binary="true"
              class="mr-3"
            />
          </div>
          <div class="field flex flex-row-reverse justify-content-end mb-3">
            <label for="is_paused">{{ ts.tModule('import_scheduled.paused') }}</label>
            <p-checkbox
              v-model="service.partialObject.is_paused"
              :binary="true"
              input-id="is_paused"
              class="mr-3"
            />
          </div>
        </div>
      </div>
      <div v-if="service.multipleVariants" class="field">
        <label>{{ ts.tModule('variants_column') }}</label>
        <p-input-text
          v-model="service.partialObject.settings.variant_column"
          class="w-full"
          :class="{
            'p-invalid': service.multipleVariants && !service.partialObject.settings.variant_column,
          }"
        />
        <small
          v-if="service.multipleVariants && !service.partialObject.settings.variant_column"
          :class="{ 'p-error block': true }"
          class="hidden"
          >{{ ts.t('validation.required') }}</small
        >
      </div>
    </div>
  </div>
</template>
