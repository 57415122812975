import { RouteFactory } from '@/core/router/route.factory';
import MatchMediaResult from 'supplier/modules/media/assets/components/import-assets/match-media-result.vue';
import MatchMediaUpload from '../assets//components/import-assets/match-media-upload.vue';
import MatchMediaImport from '../assets/components/import-assets/match-media-import.vue';
import { RouteRecordRaw } from 'vue-router';
import Collection from 'supplier/modules/media/collection/collection.view.vue';
import AssetTypes from 'supplier/modules/asset-types/views/asset-type.view.vue';
import Assets from '../assets/views/assets.view.vue';
import MatchMedia from '../assets/components/match-media.vue';
import AssetsHeader from '../assets/components/assets-header.vue';
import assetsForm from '../assets/components/assets-form.vue';
import { RouterService } from '@/core/router/router.service';

const tabs = [
  {
    label: 'Assets',
    key: 'media-assets',
    command: () => {
      RouterService.getInstance().router.push({ name: 'media-assets' });
    },
  },
  {
    label: 'Collection',
    key: 'media-collection',
    command: () => {
      RouterService.getInstance().router.push({ name: 'media-collection' });
    },
  },
  {
    label: 'Asset types',
    key: 'asset-types',
    command: () => {
      RouterService.getInstance().router.push({ name: 'asset-types' });
    },
  },
  {
    label: 'Match media',
    key: 'match-media',
    command: () => {
      RouterService.getInstance().router.push({ name: 'match-media' });
    },
  },
];

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'media',
  tabs: tabs,
  navigationGroup: 'media',
});

const mediaRoutes: RouteRecordRaw[] = [
  {
    path: '/media',
    name: 'media',
    redirect: { name: 'media-assets' },
  },

  routeFactory.createAppRoute({
    path: '/media/assets',
    name: 'media-assets',
    title: 'Assets',
    header: AssetsHeader,
    component: Assets,
    children: [
      routeFactory.createChildRoute({
        name: 'update-media-asset',
        path: ':id',
        component: assetsForm,
        title: 'Edit upload',
      }),
    ],
  }),

  RouteFactory.createAppRoute({
    path: '/media/collection',
    name: 'media-collection',
    title: 'Collection',
    component: Collection,
    tabs: tabs,
  }),

  RouteFactory.createAppRoute({
    path: '/media/match',
    name: 'match-media',
    title: 'Match Media',
    tabs: tabs,
    component: MatchMedia,
    redirect: { name: 'import-assets-upload' },
    children: [
      routeFactory.createChildRoute({
        name: 'import-assets-upload',
        path: 'upload',
        component: MatchMediaUpload,
        title: 'Import assets upload',
      }),
      routeFactory.createChildRoute({
        name: 'import-assets-result',
        path: 'result',
        component: MatchMediaResult,
        title: 'Import assets result',
      }),
      routeFactory.createChildRoute({
        name: 'import-assets-match',
        path: 'import',
        component: MatchMediaImport,
        title: 'Import assets',
      }),
    ],
  }),

  routeFactory.createAppRoute({
    path: '/media/asset-types',
    name: 'asset-types',
    title: 'Asset types',
    translations: {
      module: 'assetTypes',
    },
    component: AssetTypes,
    children: [
      RouteFactory.createChildRoute({
        name: 'create-asset-type',
        path: 'create',
        title: ' Create asset type',
      }),
      RouteFactory.createChildRoute({
        name: 'update-asset-type',
        path: ':id',
        title: 'Edit asset type',
      }),
    ],
  }),
];

export default mediaRoutes;
