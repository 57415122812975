<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

import ConfirmDialogPhrase from '@/general/ui/components/confirm-dialog-phrase/confirm-dialog-phrase.vue';

const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <div class="align-items-center flex justify-content-between mb-1">
    <span class="font-bold"
      >{{ service.ts.tModule('attributes_added') }}
      <!-- Attributes count -->
      <span class="bg-primary border-round-sm inline-block ml-1 px-1 text-sm text-white">{{
        service.userAttributes.length
      }}</span>
    </span>
    <!-- Remove all -->
    <p-button
      v-if="service.userAttributes.length !== 0"
      text
      :label="service.ts.tModule('removeAll')"
      class="text-red-700"
      @click="service.bulkRemoveAttributesConfirm()"
    />
  </div>
  <ConfirmDialogPhrase
    group="bulkDelete"
    :amount-to-delete="service.userAttributes.length"
    :entity-name-to-delete="
      service.userAttributes.length > 1
        ? service.ts.tGlobal('attributes', { choice: 2 })
        : service.ts.tGlobal('attributes')
    "
  />
</template>
