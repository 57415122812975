import { DatamodelCompact, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DatamodelServiceConstructor } from 'supplier/modules/datamodels/ts/interfaces/datamodel-vue-constructor.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Client } from 'platform-unit2-api/clients';
import { User } from 'platform-unit2-api/users';
import { Store, useStore } from 'vuex';

/**
 * @description This service holds the logic for the datamodels
 */

export class DatamodelVueService {
  public isLoading = false;
  private _currentUser: User;
  private _datamodelApiService: DatamodelsRestService;
  // #region vue and prime related
  private _ts: TranslationService;

  private _toastService: ToastService;
  private _store: Store<any>;
  private emitClose: (() => void) | undefined;
  private emitReload: (() => void) | undefined;

  // #endregion

  constructor(
    { emitClose, emitReload }: DatamodelServiceConstructor,
    store?: Store<any>,
    toast?: ToastService,
  ) {
    this._toastService = toast ?? ToastService.getInstance();
    this._store = store ?? useStore();
    this.emitClose = emitClose;
    this.emitReload = emitReload;
    this._currentUser = this._store.getters['users/currentUser'];

    this._datamodelApiService = new DatamodelsRestService();
    this._ts = new TranslationService('supplier', 'datamodels');
  }

  public async renameDatamodel({ id, name }: DatamodelCompact) {
    this.isLoading = true;
    try {
      this._datamodelApiService.update(id, {
        id: id,
        name: name,
        is_odm: false,
      });
      this.emitReload?.();
      this._toastService.displaySuccessToast(
        this._ts.tModule('datamodelRename.succes', { params: { name: name } }),
      );
    } catch (ex) {
      this._toastService.displayErrorToast(this._ts.unexpectedError);
    } finally {
      this.isLoading = false;
      this.emitClose?.();
    }
  }

  // #region Duplicate data model
  duplicationModalVisible = false;
  selectedClientToDuplicate: Client | undefined = undefined;
  shouldDuplicateImportMappings = false;
  shouldDuplicateExportMappings = false;

  clearDuplicationForm() {
    this.selectedClientToDuplicate = undefined;
    this.shouldDuplicateImportMappings = false;
    this.shouldDuplicateExportMappings = false;
  }

  async duplicate(datamodelId: number) {
    if (!this.selectedClientToDuplicate) return;
    try {
      await this._datamodelApiService.duplicateDatamodel(datamodelId, {
        workspace_id: this.selectedClientToDuplicate.id,
        duplicate_export_mappings: this.shouldDuplicateExportMappings,
        duplicate_import_mappings: this.shouldDuplicateImportMappings,
      });

      //Reload the page if the user is on the same workspace as the one that was duplicated to
      this._currentUser.workspace?.id === this.selectedClientToDuplicate.id && this.emitReload?.();
      this._toastService.displaySuccessToast(this._ts.tModule('datamodel_duplicate.success'));
    } catch {
      this._toastService.displayErrorToast(this._ts.tModule('datamodel_duplicate.fail'));
    } finally {
      this.emitClose?.();
    }
  }

  get userWorkspaces() {
    return this._currentUser?.clients;
  }
  // #endregion
}
