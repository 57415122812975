import { computed, Ref, ref } from 'vue';

export default function (): any {
  const checkable = ref(false);

  const checkedRows: Ref<
    Array<{
      id: string | number;
      [key: string]: any;
    }>
  > = ref([]);

  const checkedIds = computed((): Array<string | number> => {
    return checkedRows.value.map((row) => row.id);
  });

  return {
    checkable,
    checkedRows,
    checkedIds,
  };
}
