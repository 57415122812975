export enum AmazonLocales {
  DEFAULT = 'Default',
  nl = 'Dutch',
  nl_BE = 'Dutch (Belgium)',
  nl_NL = 'Dutch (The Netherlands)',
  en = 'English',
  en_GB = 'English (UK)',
  en_US = 'English (US)',
  fr = 'French',
  fr_BE = 'French (Belgium)',
  fr_FR = 'French (France)',
  de = 'German',
  de_DE = 'German (Germany)',
  it = 'Italian',
  it_IT = 'Italian (Italy)',
  pl = 'Polish',
  pl_PL = 'Polish (Poland)',
  pt = 'Portuguese',
  pt_PT = 'Portuguese (Portugal)',
  es = 'Spanish',
  es_ES = 'Spanish (Spain)',
  sv = 'Swedish',
  sv_SE = 'Swedish (Sweden)',
  tr = 'Turkish',
  tr_TR = 'Turkish (Turkey)',
}
