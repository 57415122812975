<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { useRoute, useRouter } from 'vue-router';
import { ChildRoute } from 'platform-unit2-api/core';
import BaseDialog from './dialog/base-dialog.vue';
import { CrudButtonPosition } from './crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from './crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from './crud-buttons/ts/classes/create-crud-button.class';

// Types
export interface DisableButtonCondition {
  pageIndex: number;
  disableNextStep?: boolean;
  disablePreviousStep?: boolean;
}

export interface FormDataChange {
  key: string;
  data?: any;
}

// Props
interface Props {
  isActive: boolean;
  header: string;
  childRoutes: ChildRoute[];
  formData?: any;
  disableButtons?: DisableButtonCondition[];
  dialogContentClass?: string;
  finalButtonLabel?: string;
  size: 'small' | 'medium' | 'large';
}

const props = withDefaults(defineProps<Props>(), {
  header: '',
  formData: {},
  disableButtons: () => [],
  dialogContentClass: undefined,
  finalButtonLabel: undefined,
});

// Emits
const emit = defineEmits<{
  (e: 'hide', value?: boolean): void;
  (e: 'complete'): void;
  (e: 'update:formData', data?: FormDataChange): void;
}>();

// Constants
const router = useRouter();
const route = useRoute();
const ts = new TranslationService('general', 'components');
const currentPageIndex = ref<number>(0);
// Helper functions
const handleHide = (value: boolean) => {
  emit('hide', value);
};

const handlePrevPage = () => {
  const childRouteLinks = props.childRoutes.map((cr) => cr.to);
  if (childRouteLinks.includes(props.childRoutes[currentPageIndex.value - 1]?.to)) {
    router.push(props.childRoutes[--currentPageIndex.value].to);
  }
};

const handleNextPage = () => {
  const childRouteLinks = props.childRoutes.map((cr) => cr.to);
  if (childRouteLinks.includes(props.childRoutes[currentPageIndex.value + 1]?.to)) {
    router.push(props.childRoutes[++currentPageIndex.value].to);
  }
};

const disablePrevStep = (): boolean => {
  return (
    props.disableButtons.find((b) => b.pageIndex === currentPageIndex.value)?.disablePreviousStep ??
    false
  );
};

const disableNextStep = (): boolean => {
  return (
    props.disableButtons.find((b) => b.pageIndex === currentPageIndex.value)?.disableNextStep ??
    false
  );
};

let pageIndex = 0;

// Lifecycle hooks
watch(
  () => route.name,
  () => {
    // Child routes should pass the name of the route, not its path
    pageIndex = props.childRoutes.findIndex(
      (childRoute) => ((childRoute.to as { name: string }).name ?? childRoute.to) === route.name,
    );

    if (pageIndex === -1) {
      pageIndex = 0;
    }

    currentPageIndex.value = pageIndex;
  },
);

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.LEFT,
  onClick: () => {
    emit('hide');
  },
});

const previousButton = new CreateButton({
  label: ts.tGlobal('previous'),
  styleClasses: 'p-button-outlined',
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !disablePrevStep(),
  onClick: handlePrevPage,
});

const nextButton = new CreateButton({
  label: () =>
    currentPageIndex.value === props.childRoutes.length - 1
      ? props.finalButtonLabel ?? ts.tGlobal('finish')
      : ts.tGlobal('next'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !disableNextStep(),
  onClick: () =>
    currentPageIndex.value === props.childRoutes.length - 1 ? emit('complete') : handleNextPage(),
});

const buttonsOptions = computed(() => {
  if (currentPageIndex.value != 0) {
    return {
      buttons: [cancelButton, previousButton, nextButton],
    };
  } else {
    return {
      buttons: [cancelButton, nextButton],
    };
  }
});
</script>
<template>
  <BaseDialog
    :visible="isActive"
    :title="header"
    class="pup-modal"
    :size="size"
    :buttons-options="buttonsOptions"
    @update:visible="handleHide"
  >
    <pSteps
      v-if="childRoutes?.length > 1"
      exact
      readonly
      :model="childRoutes"
      class="m-3"
      :active-step="pageIndex"
    />
    <RouterView
      v-slot="{ Component }"
      :key="route.fullPath"
      :form-data="formData"
      @update:form-data="(data?: FormDataChange) => emit('update:formData', data)"
      @complete="emit('complete')"
    >
      <KeepAlive>
        <Component :is="Component" />
      </KeepAlive>
    </RouterView>
  </BaseDialog>
</template>
