import axios, { Method } from 'axios';
import { CoreRestService } from 'platform-unit2-api/core';

/**
 * Service for uploading files to AWS S3
 */
export class AwsFileService {
  private _coreApi;

  constructor(api: CoreRestService = new CoreRestService()) {
    this._coreApi = api;
  }
  /**
   * Sign a file to upload to AWS S3
   * @param file file to sign and upload
   * @returns reference or undefined if failed
   */
  async signAndUpload(
    file: File,
    onUploadProgress?: (e: any) => void,
  ): Promise<string | undefined> {
    const sign = await this._coreApi.signFile(file);

    await axios.request({
      url: sign.url,
      method: sign.method as Method,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: onUploadProgress,
    });

    return sign.reference;
  }
}
