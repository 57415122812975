import { RouteFactory } from '@/core/router/route.factory';
import { FeaturePermission } from 'platform-unit2-api/roles';
import BrandsView from 'supplier/modules/brands/views/brands.view.vue';
import IncompleteProductsListView from 'supplier/modules/incomplete-products/views/incomplete-products-list.vue';
import { RouteRecordRaw } from 'vue-router';

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'brands',
});

const brandsRoutes: RouteRecordRaw[] = [
  routeFactory.createAppRoute({
    path: '/brands',
    name: 'brands',
    component: BrandsView,
    title: 'Brands',
    rights: [FeaturePermission.MANAGE_BRANDS],
    children: [
      routeFactory.createChildRoute({
        path: '/brands/new',
        name: 'new-brand',
        title: 'New brand',
        rights: [FeaturePermission.MANAGE_BRANDS],
      }),
      routeFactory.createChildRoute({
        path: '/brands/:id',
        name: 'edit-brand',
        title: 'Edit brand',
        rights: [FeaturePermission.MANAGE_BRANDS],
      }),
    ],
  }),
  routeFactory.createAppRoute({
    path: '/brands/:id/incomplete',
    name: 'brand-products-incomplete',
    translations: {
      module: 'incompleteProducts',
    },
    group: 'products',
    component: IncompleteProductsListView,
    title: 'Incomplete products',
    rights: [FeaturePermission.MANAGE_PRODUCTS],
  }),
];

export default brandsRoutes;
