import { ListResponse, BaseRest } from 'platform-unit2-api/core';
import { ContentStory } from './ts/content-story.interface';
import { CreateContentStoryRequest } from './ts/create-content-story-request.interface';
import { ContentStoryProduct } from './ts/content-story-product.interface';
import { StoryModule } from './ts/story-types/modules/story-modules.type';
import { GetAttachableProductsRequest } from './ts/interfaces/getAttachableProducts.interface';
import { UpdateContentStoryRequest } from './ts/update-content-story-request.interface';

export class ContentStoryRestService extends BaseRest<
  ContentStory,
  CreateContentStoryRequest,
  UpdateContentStoryRequest,
  {}
> {
  protected readonly URL = 'content-story';

  async duplicate(id: number): Promise<ContentStory> {
    return (await this._client.post(`${this.URL}/${id}/duplicate`)).data;
  }

  async getContentStoryProducts(storyId: number): Promise<ListResponse<ContentStoryProduct>> {
    return (await this._client.get(`${this.URL}/${storyId}/products`)).data;
  }

  async attachProductsToStory(storyId: number, productIds: number[]): Promise<ContentStory> {
    return (
      await this._client.post(`${this.URL}/${storyId}/bulk-attach-products`, {
        product_ids: productIds,
      })
    ).data;
  }

  async detachProductsToStory(storyId: number, productIds: number[]): Promise<ContentStory> {
    return (
      await this._client.post(`${this.URL}/${storyId}/bulk-detach-products`, {
        product_ids: productIds,
      })
    ).data;
  }

  async getModules(): Promise<{ modules: StoryModule[] }> {
    return (await this._client.get(`${this.URL}/modules`)).data;
  }

  async getAttachableProducts(getParams: GetAttachableProductsRequest) {
    const params = [
      getParams.query && `query=${getParams.query}`,
      getParams.page && `page=${getParams.page}`,
      getParams.limit && `limit=${getParams.limit}`,
    ];

    return (
      await this._client.post(
        `${this.URL}/product-by-channels?${params.filter((value) => value).join('&')}`,
        {
          variant_keys: getParams.variant_keys,
          story_id: getParams.story_id,
        },
      )
    ).data;
  }
}
