import { Upload } from 'platform-unit2-api/uploads';

const filenameOrderRegex = /_[\S]?(\d+)/;

export const orderSorter = (prev: Upload, curr: Upload): 1 | 0 | -1 => {
  const prevMatch = prev.filename.match(filenameOrderRegex);
  const currMatch = curr.filename.match(filenameOrderRegex);

  const prevOrder = prevMatch && prevMatch.length >= 2 ? parseInt(prevMatch[0].substr(1)) : 0;
  const currOrder = currMatch && currMatch.length >= 2 ? parseInt(currMatch[0].substr(1)) : 0;

  if (prevOrder < currOrder) {
    return -1;
  }

  if (prevOrder > currOrder) {
    return 1;
  }

  return 0;
};

export const sortByOrderNumber = (uploads: Upload[]): Upload[] => {
  return uploads.sort(orderSorter);
};
