<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

//Components
import EmptyState from '@/general/ui/components/empty-state.vue';
import { MenuItem } from 'primevue/menuitem';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import ImportDetails from './import-details.vue';

//Types
import { User } from 'platform-unit2-api/users';
import StatusChip, { StatusSeverity } from '@/general/ui/components/status-chip.vue';

//Composables and Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { PipelinesRestService } from 'platform-unit2-api/pipelines';
import { ImportService } from '@/general/services/overview-service/modules/import.service';
import { useService } from '@/general/composables/useService';
import { importServiceKey } from '../ts/keys/import-service.key';
import { ImportPipeline } from 'platform-unit2-api/imports';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import { DataTableFilterEvent, DataTableRowClickEvent } from 'primevue/datatable';
import { formatDate } from '@/general/utils/format-date';

/**translation services */
const ts = new TranslationService('supplier', 'imports');

/** Services */
const importService = useService(importServiceKey, ImportService);

/** constants */
const store = useStore();
const expandedRows = ref([]);

/** Methods */
const currentUser = computed((): User => {
  return store.getters['users/currentUser'];
});

const menuItems = (pipeline: ImportPipeline): MenuItem[] => [
  {
    label: ts.tModule('tooltip.download'),
    icon: 'mdi mdi-download',
    visible: () => Boolean(pipeline?.files?.length),
    command: () => {
      importService.downloadFiles(pipeline?.files ?? []);
    },
  },
  {
    label: ts.tModule('tooltip.restart'),
    icon: 'mdi mdi-restart',
    command: () => importService.openCrudComponent(pipeline),
  },
  {
    label: ts.tModule('go_to_products'),
    icon: 'mdi mdi-open-in-new',
    visible: () => !pipeline?.settings.dry_run && (pipeline?.product_count ?? -1) > 0,
    command: () => {
      importService.goToProducts(pipeline?.id);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('delete'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      importService.deleteAction(pipeline.id);
    },
  },
];
</script>
<template>
  <pDataTable
    v-if="importService != null && ts != null"
    v-model:expanded-rows="expandedRows"
    v-model:filters="importService.filters"
    :paginator="importService.total > (importService.limit ?? 0)"
    filter-display="menu"
    lazy
    removable-sort
    row-hover
    auto-layout
    scrollable
    scroll-height="flex"
    :loading="importService.isLoadingOverView"
    :value="importService.imports"
    :rows="importService.limit"
    :first="importService.first"
    :global-filter-fields="['module.name']"
    :total-records="importService.total"
    :sort-field="importService.sortField"
    :sort-order="importService.sortOrder"
    class="cursor-pointer"
    @page="importService.onPageChange($event.page)"
    @filter="(event: DataTableFilterEvent) => importService.applyFilter(event)"
    @sort="importService.onSortChange($event)"
    @row-click="(event: DataTableRowClickEvent) => importService.togglePipelineDetails(true, event.data)"
  >
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-visible="true"
        :button-icon="'mdi mdi-plus'"
        :icon-name="'imports'"
        @clicked="importService.openCrudComponent()"
      />
    </template>

    <p-column field="status" :header="ts.tModule('table_headers.status')">
      <template #body="slotProps: { data: ImportPipeline }">
        <StatusChip
          icon-only
          :severity="importService.getCurrentStatus(slotProps.data)?.level as StatusSeverity ?? 'info'"
          :label="importService.getCurrentStatus(slotProps.data)?.text"
        />
      </template>
    </p-column>

    <p-column field="dry_run" :header="ts.tModule('table_headers.saved_products')">
      <template #body="slotProps: { data: ImportPipeline }">
        <p-tag
          v-if="!slotProps.data.settings?.dry_run"
          severity="success"
          :value="ts.tGlobal('yes')"
        />
        <p-tag v-else severity="danger" :value="ts.tGlobal('no')" />
      </template>
    </p-column>

    <p-column
      field="module.name"
      :header="ts.tModule('table_headers.channel')"
      :show-filter-match-modes="false"
    >
      <template #body="slotProps: { data: ImportPipeline }">
        {{ slotProps.data?.module?.name }}
      </template>
      <template #filter="{ filterModel }">
        <ModuleSelect
          v-model="filterModel.value"
          hide-label
          multiselect
          class="max-w-20rem"
          :rest-service="PipelinesRestService"
          fetch-function-name="getFilterChannels"
        />
      </template>
    </p-column>

    <p-column field="user" :header="ts.tModule('table_headers.user')">
      <template #body="slotProps: { data: ImportPipeline }">
        {{
          slotProps.data.user != null
            ? currentUser && slotProps.data.user.id === currentUser.id
              ? ts.tGlobal('me')
              : slotProps.data.user.name
            : ts.tGlobal('system')
        }}
      </template>
    </p-column>

    <p-column field="created_at" :header="ts.tModule('table_headers.started_at')">
      <template #body="slotProps: { data: ImportPipeline }">
        {{ slotProps.data.created_at ? formatDate(new Date(slotProps.data.created_at)) : '' }}
      </template>
    </p-column>

    <p-column class="flex-none" header-style="width: 5rem" body-style="width: 5rem">
      <template #body="slotProps: { data: ImportPipeline }">
        <div class="flex justify-content-end">
          <ActionsMenu :menu-items="menuItems(slotProps.data)" />
        </div>
      </template>
    </p-column>
  </pDataTable>
  <ImportDetails
    v-if="importService.pipelineDetails"
    :ts="ts"
    :service="importService"
    :visible="importService.isDetailsModalVisible"
    :pipeline="importService.pipelineDetails"
  />
</template>
