<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute } from 'platform-unit2-api/attributes';

/** Props */
const props = defineProps<{
  values?: Attribute[];
  modelValue?: Attribute;
  clearable?: boolean;
}>();

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', selectedAttribute?: Attribute): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'mappings');

/** Use Constants */

/** Search for attributes */
const selectedAttribute = ref<Attribute>();
const filteredDefinitions = ref<Attribute[]>([]);
const attributes = ref<Attribute[]>([]);

const search = (query = '') => {
  filteredDefinitions.value = attributes.value.filter((def) => {
    return def.key.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  });
};

/** Executed when the selected values are changed */
const change = (event: { value?: Attribute }) => {
  selectedAttribute.value = event.value;
  emit('update:modelValue', selectedAttribute.value);
};

/** Used for filtering the values */
const filter = (event: any) => {
  search(event.value);
};

/** Lifecycle hooks */
watch(
  () => props.modelValue,
  () => {
    selectedAttribute.value = props.modelValue;
    search(props.modelValue?.key ?? '');
  },
);

watch(
  () => props.values,
  () => {
    attributes.value = props.values ?? [];
    search('');
  },
);

onMounted(async () => {
  attributes.value = props.values as Attribute[];
  if (props.modelValue) {
    search(props.modelValue.key);
    selectedAttribute.value = props.modelValue;
  } else {
    search('');
  }
});
</script>

<template>
  <pSelect
    v-model="selectedAttribute"
    :options="filteredDefinitions"
    option-label="key"
    data-key="id"
    :filter="true"
    placeholder="Select a field definition"
    :show-clear="clearable"
    class="w-full"
    @change="change"
    @filter="filter"
  >
    <template #empty> {{ ts.notFoundWarning }} </template>
  </pSelect>
</template>
