<script setup lang="ts">
import { ref, provide, Ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';
import { modulesServiceKey } from 'retailer/modules/modules/ts/keys/modules.keys';
import OverView from 'ui/views/over-view/over-view.vue';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';
import { ModulesViewService } from '@/general/services/overview-service/modules/modules-view.service';
import ModulesList from 'retailer/modules/modules/components/modules-list.vue';
import CredentialEdit from 'retailer/modules/modules/components/credential-edit.vue';
import OverviewSidebar from '@/general/ui/components/over-view/overview-sidebar.vue';

const ts = ref(new TranslationService('retailer', 'modules'));
const service = ref(new ModulesViewService(ts.value as TranslationService));

provide(modulesServiceKey, service as Ref<ModulesViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="retailer" module-name="modules" :service="service">
    <template #skeleton>
      <CardSkeleton />
    </template>
    <template #content>
      <ModulesList />
    </template>
    <template #crudComponent>
      <OverviewSidebar
        :service="service"
        :title="ts.tModule('credential_edit.title')"
        :subtitle="ts.tModule('credential_edit.subtitle')"
      >
        <template #form>
          <CredentialEdit />
        </template>
      </OverviewSidebar>
    </template>
  </OverView>
</template>
