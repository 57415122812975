<script setup lang="ts">
import { inject } from 'vue';
import { editImageServiceKey } from '../../ts/keys/supplier-ai.keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';

const service = inject(editImageServiceKey)!;
const ts = new TranslationService('supplier', 'ai-enhancements');
</script>
<template>
  <div>
    <div class="font-bold text-2xl">Generated Images</div>
    <div class="mb-3 text-gray-500 text-sm">Images generated from the given prompt</div>

    <div class="flex flex-wrap gap-4">
      <div v-if="service.prompt && !service.isLoading">
        <div class="gap-4 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
          <p-image
            v-for="{ public_url, id, filename } in service.prompt.uploads"
            :key="id"
            image-class="mr-3 mt-3"
            :alt="filename"
            :src="public_url"
          />
        </div>
      </div>
      <div v-if="service.isLoading" class="flex flex-wrap gap-4">
        <div class="gap-4 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
          <pSkeleton
            v-for="(_, index) in service.promptRequest.amount"
            :key="index"
            :style="{
              height: service.promptRequest.size?.split('x')[0] + 'px' ?? '256px',
              width: service.promptRequest.size?.split('x')[0] + 'px' ?? '256px',
            }"
          />
        </div>
      </div>
    </div>

    <div v-if="!service.prompt && !service.isLoading" class="flex flex-wrap gap-4">
      <EmptyState
        :translation-service="ts"
        :empty-state-title="'AI Image generation'"
        :empty-state-subtitle="'No images found, fill in the generation settings to get started'"
        :button-visible="false"
        :icon-name="'media'"
      ></EmptyState>
    </div>
  </div>
</template>
