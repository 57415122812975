import { FeatureFlagsRestService } from 'platform-unit2-api/feature-flags';
import { GrowthBook } from '@growthbook/growthbook';
import { Buffer } from 'buffer';
import { UserRestService, User } from 'platform-unit2-api/users';
import { QueueService } from '@/general/services/queue-service/queue.service';
import { DebugLogger } from '@/core/main/debug.logger';

/**
 * Feature flag setup service is used to setup the GrowthBook library functionality.
 * The encryption is done manually in the backend and the frontend only needs to decrypt the
 * base64 encoded string.
 * @example
 * //get instance
 * const featureFlagSetupService = new FeatureFlagSetupService('backend endpoint', boolean, 'encryption token');
 */
export class FeatureFlagSetupService {
  private _featuresEndpoint: string;
  private _growthBook: GrowthBook;

  private _encryptionToken: string;

  private _fetched: number | null = null;

  private _initializedGrowthBookFeatures = false;

  public static featureFlagEnvironment = {
    featuresEndpoint: '/features',
    enableDevMode: process.env.NODE_ENV !== 'production',
    encryptionToken: import.meta.env.VITE_APP_FEATURE_FLAG_ENCRYPTION,
  };

  private _userService: UserRestService;
  constructor(
    featuresEndpoint = FeatureFlagSetupService.featureFlagEnvironment.featuresEndpoint,
    enableDevMode = FeatureFlagSetupService.featureFlagEnvironment.enableDevMode,
    encryptionToken = FeatureFlagSetupService.featureFlagEnvironment.encryptionToken,
  ) {
    this._featuresEndpoint = featuresEndpoint;
    this._encryptionToken = encryptionToken;

    this._userService = new UserRestService();
    this._growthBook = new GrowthBook({
      enableDevMode,
    });
  }

  /**
   * To prevent fetching the feature flags too often, we cache the result for 5 minutes.
   * When using anything else then the production enviorment caching is disabled.
   * @returns boolean
   */
  private isRecentlyFetched(): boolean {
    if (
      import.meta.env.VITE_APP_FEATURE_FLAG_CACHE == null ||
      import.meta.env.VITE_APP_FEATURE_FLAG_CACHE == 'false'
    ) {
      if (this._initializedGrowthBookFeatures == false || process.env.NODE_ENV !== 'production') {
        return false;
      }
    }

    return this._fetched != null && this._growthBook && this._fetched > Date.now() - 300_000;
  }

  //Private method to fetch the feature flags from the backend.
  private async getFeaturesJson() {
    return await new FeatureFlagsRestService().getFeaturesJson(this._featuresEndpoint);
  }

  /**
   * Set the user in the growthbook instance.
   * @param user
   */
  public setUser(user: User) {
    const attributes: any = {
      id: user.id,
      email: user.email,
      clientId: user.workspace?.id,
    };

    //for user.preferenes add keys to attributes
    if (user.preferences) {
      Object.keys(user.preferences).forEach((key) => {
        attributes['user_preferences#' + key] = (user.preferences as any)[key];
      });
    }

    DebugLogger.info('[FeatureFlag] Setting user', attributes);

    this._growthBook.setAttributes(attributes);
  }

  //Private method to set the feature flags in _growthBook.
  private async setEncryptedFeatures(features: any) {
    await this._growthBook.setEncryptedFeatures(
      features.iv + '.' + features.value,
      this._encryptionToken,
      window.crypto.subtle,
    );

    const user = await this._userService.getCurrentUser();
    if (user) {
      this.setUser(user);
    }
  }

  /**
   * Fetch the feature flags from the backend and decrypt them and store them in the growhtbook instance.
   * @returns GrowthBook
   */
  private async _getGrowthBook(refetch = false): Promise<GrowthBook | undefined> {
    try {
      let isRecentlyFetched = this._initializedGrowthBookFeatures;

      if (!this.isRecentlyFetched()) {
        isRecentlyFetched = false;
      }

      if (!refetch && isRecentlyFetched) {
        return this._growthBook;
      }

      const base64 = await this.getFeaturesJson();

      if (!base64) {
        return undefined;
      }

      const features = JSON.parse(Buffer.from(base64, 'base64').toString());

      await this.setEncryptedFeatures(features);

      this._fetched = Date.now();
      this._initializedGrowthBookFeatures = true;

      return this._growthBook;
    } catch {
      return undefined;
    }
  }

  /**
   * Fetch the feature flags from the backend and decrypt them and store them in the growhtbook instance.
   * @returns GrowthBook
   */
  public async getGrowthBook(refetch = false): Promise<GrowthBook | undefined> {
    return QueueService.instance.queue(
      'isFetchingGrowthBook',
      async () => await this._getGrowthBook(refetch),
    );
  }
}
