<script setup lang="ts">
import { inject, ref } from 'vue';
import { sharedServiceKey } from '../ts/keys/shared.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';

// Constants
const service = inject(sharedServiceKey)!;
const ts = inject(tsKey)!;

const cancelButton = new CancelButton({
  label: ts.value.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => service.value.toggleApproveSharedDialog(false),
});

const saveButton = new CreateButton({
  label: ts.value.tGlobal('approve'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!service.value.shareApproveSelectedClient,
  onClick: () => {
    if (service.value.activeId) {
      service.value.approveShared(service.value.activeId);
    }
  },
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, saveButton],
});
</script>

<template>
  <BaseDialog
    :visible="service.isApproveSharedDialogVisible"
    :title="ts.tModule(`approve_modal.title`)"
    :subtitle="ts.tModule(`approve_modal.subtitle`)"
    :buttons-options="buttonsOptions"
    size="small"
    @update:visible="service.toggleApproveSharedDialog(false)"
  >
    <ClientSelect v-model="service.shareApproveSelectedClient" />
  </BaseDialog>
</template>
