import { createApp } from 'vue';
import App from '@/core/app/App.vue';
import store from '@/core/store';
import VueGtag from 'vue-gtag';
import i18n from '@/core/i18n';

import '/node_modules/flag-icons/css/flag-icons.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'prismjs/themes/prism.min.css';

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import { installSentry } from './core/main/sentry.install';
import { RouterService } from './core/router/router.service';
import vAutofocus from './general/utils/directives/v-autofocus';
import vClickOutside from './general/utils/directives/v-click-outside';
import vOnFocus from './general/utils/directives/v-on-focus';

import 'primeicons/primeicons.css';
import '@/assets/theme/theme.scss';
import 'primeflex/primeflex.css';
import { registerWarnHandler } from './core/main/registerWarnHandler';
import { installPrimeVue } from './core/main/primeVue.install';
import { installFeatureFlag } from './core/main/featureFlagVue.install';

const app = createApp(App);

app.config.globalProperties.window = window;

app.use(i18n);
app.use(store);

app.directive('click-outside', vClickOutside);
app.directive('autofocus', vAutofocus);
app.directive('on-focus', vOnFocus);

app.use(VueGtag, {
  config: { id: 'GTM-TTV939G' },
  enabled: true,
});

app.use(ConfirmationService);
app.use(ToastService);

const router = RouterService.getInstance().router;

app.use(router);

installSentry(app, router);
registerWarnHandler(app);
installPrimeVue(app);
installFeatureFlag(app);

router.isReady().then(() => {
  app.mount('#app');
});
