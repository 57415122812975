import CreatedComponent from 'retailer/modules/activities/components/created.vue';
import AttachedComponent from 'retailer/modules/activities/components/attachedBox.vue';
import DeletedComponent from 'retailer/modules/activities/components/deleted.vue';
import UpdatedComponent from 'retailer/modules/activities/components/updated.vue';
import DetachedComponent from 'retailer/modules/activities/components/detachedBox.vue';
import { Activity, ActivityEventTypeEnum } from 'platform-unit2-api/activities';

export default class ActivityService {
  /** Generate the correct component to show the activity details */
  generateActivityBox = (activity: Activity[]) => {
    switch (activity[0]?.event) {
      case ActivityEventTypeEnum.CREATE:
        return CreatedComponent;
      case ActivityEventTypeEnum.UPDATE:
        return UpdatedComponent;
      case ActivityEventTypeEnum.DELETE:
        return DeletedComponent;
      case ActivityEventTypeEnum.ATTACH:
        return AttachedComponent;
      case ActivityEventTypeEnum.DETACH:
        return DetachedComponent;
      default:
        return UpdatedComponent;
    }
  };
}
