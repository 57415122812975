<script setup lang="ts">
//vue
import { inject } from 'vue';

//components
import ContentHeadline from './content-headline.vue';
import ContentImage from './content-image.vue';
import ContentTextarea from './content-textarea.vue';

//types
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';

//services
import StandardThreeImageTextClass from '@/api/v1/content-story/ts/classes/three-image-text.class';
import StandardFourImageTextClass from '@/api/v1/content-story/ts/classes/four-image-text.class';
import StandardHeaderImageTextClass from '@/api/v1/content-story/ts/classes/header-image-text.class';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
export interface Props {
  uuid: string;
  block: 'block1' | 'block2' | 'block3' | 'block4';
  customStyle?: string;
  customTextBoxStyle?: string;
}

const props = withDefaults(defineProps<Props>(), {
  customStyle: 'width: 33%;"',
  customTextBoxStyle: '"80%"',
});

// inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

// const / refs
const storyModule = storyService?.value.getModule<
  StandardThreeImageTextClass | StandardFourImageTextClass | StandardHeaderImageTextClass
>(props.uuid);

/** Lifecycle */

/**  the casts to any are added because the type check*/
</script>

<template>
  <div class="flex flex-column h-full justify-content-center" :style="props.customStyle">
    <ContentImage
      :minimal-image-size="storyModule?.minimumImageSize"
      :accepted-file-types="storyModule?.acceptedFileTypes"
      :upload-url="storyModule?.getUploadUrl(block as any)"
      :is-upload-set="storyModule?.hasUploadSet(block as any)"
      @uploaded-image="storyModule?.setUploadData($event, block as any)"
    />
    <ContentHeadline
      :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
      :headline="storyModule?.getAltText(block as any)"
      @headline-value="storyModule?.setAltText($event, block as any)"
    />
    <ContentHeadline
      :headline="(storyModule?.data as any)[block]?.headline.value"
      @headline-value="storyModule?.setHeadline(block as any, $event)"
    />
    <ContentTextarea
      :textbox="(storyModule?.data as any)[block]?.body.value"
      :additional-container-style="props.customTextBoxStyle"
      @html-text-area="storyModule?.setTextArea(block as any, $event)"
    />
  </div>
</template>
<style lang="scss" scoped></style>
