<script setup lang="ts">
import EmptyState from '@/general/ui/components/empty-state.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { productAttributeValidationServiceKey } from '@/general/services/attribute-fields/service-keys';

interface Props {
  ts: TranslationService;
}

const props = defineProps<Props>();
const productAttributesValidationService = inject(productAttributeValidationServiceKey)!;
</script>
<template>
  <div class="empty-state">
    <EmptyState
      icon-name="validation-panel"
      :empty-state-title="props.ts.tModule('valildationPanel.emptyStateTitle')"
      :empty-state-subtitle="ts.tModule('valildationPanel.emptyStateSubtitle')"
      :button-label="ts.tModule('valildationPanel.emptyStateButtonLabel')"
      button-icon="mdi mdi-plus"
      @clicked="productAttributesValidationService.validationModelSelectDialogOpen = true"
    />
  </div>
</template>
<style scoped lang="scss">
.empty-state {
  width: 450px;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
