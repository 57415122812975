import { RouteFactory } from '@//core/router/route.factory';
import Calendar from '@/platforms/supplier/modules/dashboard/views/calendar.view.vue';
import { RouteRecordRaw } from 'vue-router';
import DashboardView from '@/platforms/supplier/modules/dashboard/views/dashboard.view.vue';
import { RouterService } from '@/core/router/router.service';

const factory = new RouteFactory(
  'supplier',
  'dashboard',
  [
    {
      label: 'Insights',
      key: 'insights',
      command: () => {
        RouterService.getInstance().router.push({ name: 'insights' });
      },
    },
    {
      label: 'Calendar',
      key: 'calendar',
      command: () => {
        RouterService.getInstance().router.push({ name: 'calendar' });
      },
    },
  ],
  'dashboard',
);

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: { name: 'insights' },
  },

  factory.createAppRoute({
    path: '/dashboard/insights',
    name: 'insights',
    title: 'Dashboard',
    component: DashboardView,
  }),

  factory.createAppRoute({
    path: '/dashboard/calendar',
    name: 'calendar',
    title: 'Calander',
    component: Calendar,
  }),
];

export default dashboardRoutes;
