<script setup lang="ts">
import { computed } from 'vue';

import noThumbnailSvg from '@/assets/images/no_image_thumbnail.svg';
import { useRoute } from 'vue-router';
import { Upload } from 'platform-unit2-api/uploads';
import { Thumbnail } from 'platform-unit2-api/thumbnails';
import { Download } from 'platform-unit2-api/exports';

interface UploadProps {
  upload?: Upload | Thumbnail | Download;
  preview?: boolean;
  isUploading?: boolean;
  additionalImageClass?: string;
  additionalContainerStyle?: string;
  additionalImageStyle?: string;
  uploadThumbnail?: boolean;
  size?: string;
}

const props = withDefaults(defineProps<UploadProps>(), {
  upload: undefined,
  size: '60',
  additionalImageClass: '',
  additionalContainerStyle: '',
  additionalImageStyle: '',
  uploadThumbnail: false,
});
const emit = defineEmits(['upload']);

const route = useRoute();

const isTiff = computed(() => {
  const isRouteDownloads = route.name === 'downloads';
  return !(props.upload?.filename?.includes('.tiff') && isRouteDownloads);
});
</script>

<template>
  <div class="h-full w-full" :style="additionalContainerStyle">
    <p-image
      v-if="isUploading"
      :image-style="'border-radius: 0.25rem; width: 100%; height: 100%; object-fit: contain'"
      alt="loading image"
      :class="['w-full h-full ' + additionalImageClass]"
      :src="noThumbnailSvg"
      :width="size"
    />
    <div class="container">
      <p-image
        v-if="!isUploading"
        alt="upload"
        :image-style="`border-radius: 0.25rem; width: 100%; height: 100%; object-fit: contain; ${additionalImageStyle}`"
        class="h-full img w-full"
        :image-class="['flex align-items-center justify-content-center ' + additionalImageClass]"
        :src="
        upload != null && isTiff
          ? (upload as Upload | Thumbnail).public_thumbnail_url  ?? upload.public_url
          : noThumbnailSvg
      "
        :preview="preview && upload && isTiff"
        :width="size"
      >
        <template #indicator> <i class="mdi mdi-eye-outline text-xl"></i> </template>
      </p-image>
      <pButton
        v-if="!upload && uploadThumbnail"
        class="bg-gray-500 border-none flex h-full justify-content-center w-full"
        @click="emit('upload')"
      >
        <i class="mdi mdi-plus" />
      </pButton>
    </div>
  </div>
</template>
<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}
.container:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
}
.container .img {
  display: block;
}
.container button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  opacity: 0;
  border-radius: 0.25rem;
}
.container:hover button {
  opacity: 0.7;
}
</style>
