<script setup lang="ts">
import { nextTick, onMounted, ref, unref, watch } from 'vue';
import { useFetch } from '@/general/composables/useFetch';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { FetchOptions } from '@/general/composables/useFetch/types';
import { DisplayToastSetting } from '@/general/composables/UseDispatch/types';
import { Activity } from 'platform-unit2-api/activities';

/** Props */
interface Props {
  endpoint: string;
  params: Record<string, string | number>;
  limit?: number;
}
const props = withDefaults(defineProps<Props>(), { limit: 15 });

/** constants */
const data: ReturnType<() => typeof fetchedData> = ref([]);
/** Define default options to send through useFetch */
const options = ref<FetchOptions<Activity[]>>({
  parameters: { ...props.params, query: '' },
  pagination: true,
  page: 1,
  perPage: props.limit,
  autoLoad: true,
  toast: {
    displaySetting: DisplayToastSetting.NONE,
  },
});

/** useFetch  */
const {
  loading,
  fetchData,
  data: fetchedData,
  total,
  page,
  perPage,
} = useFetch({
  action: props.endpoint,
  options,
});

/** custom directive for scrolling */
const scrollDivHeight = ref(0);

const vScroll = {
  mounted: (el: HTMLElement) => {
    let lastScrollTop = 0; // To detect if the user is scrolling up or down
    scrollDivHeight.value = el!.scrollHeight;
    el.addEventListener('scroll', () => {
      if (el!.scrollTop < lastScrollTop) {
        // user is scrolling up
        return;
      }

      lastScrollTop = el!.scrollTop <= 0 ? 0 : el!.scrollTop;

      if (Math.ceil(el!.scrollTop + (el! as HTMLElement).offsetHeight) >= el!.scrollHeight) {
        // User has reached to the end of scrollbar
        fetchTheNextPageData();
      }
    });
  },
};

const fetchTheNextPageData = () => {
  if (+unref(page)! * +unref(perPage)! < unref(total)!) {
    //If there is more data to fetch
    options.value.page = +unref(page)! + 1;
    fetchData();
  }
};

/** Check if the height of data loaded is less than scroll container */
const vHeight = {
  updated: (el: HTMLElement) => {
    nextTick(() => {
      // check for > 0 to make sure the data is loaded
      if (el.offsetHeight > 0 && el.offsetHeight <= scrollDivHeight.value) {
        fetchTheNextPageData();
      }
    });
  },
};

onMounted(() => {
  data.value = unref(fetchedData);
});

/** Watch changes in fetched Data */
watch(
  () => unref(fetchedData),
  () => {
    data.value = [...data.value, ...unref(fetchedData)]; // Add the newly fetched data to exsisting data array
  },
);
</script>

<template>
  <div v-scroll class="overflow-scroll">
    <div v-height>
      <slot name="layout" :data="data" :loading="loading"></slot>
    </div>
    <slot v-if="loading" name="loading"><LoadingIndicator /></slot>
  </div>
</template>
