import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Scheduled import',
};

const translationObject: TranslationObject = {
  title: 'Scheduled import | Scheduled imports',

  create: create,
};

const tranlation: Translation = {
  scheduledImport: translationObject,
};

export default tranlation;
