import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Export Mapping',
  subTitle: 'Here you can create a new Export Mapping.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Export Mapping',
};

const update: TranslationObjectAction = {
  title: 'Edit Export Mapping',
  subTitle: 'Here you can edit a Export Mapping.',
};

const translationObject: TranslationObject = {
  title: 'Export Mapping | Export Mappings',
  placeholder: 'Select Export Mapping | Select Export Mappings',
  emptyStateSubtitle:
    'Start by selecting a datamodel and channel to create your first export\nmapping. When an export mapping is created it will show here.',

  create: create,
  delete: deleteTranslation,
  update: update,

  mapping: 'Mapping',
  compare_warning:
    'There are not enough mappings to compare. Please duplicate the mappings and try again.',
  sync_warning:
    'There are not enough mappings to sync. Please duplicate the mappings and try again.',
  duplicate_warning:
    'There are not enough mappings to duplicate. Please create some mappings to duplicate.',
  field_sources: {
    take_value: 'Take value',
    take_key: 'Take key',
    take_number: 'Take number',
    take_unit: 'Take unit',
    take_text: 'Take text',
    take_html: 'Take HTML',
    take_list: 'Take list',
    take_list_item: 'Take list item:',
    take_locale: 'Take locale',
  },
  duplicate_mappings: {
    title: 'Duplicate mappings',
  },
  export_mapping_supported: {
    select_source: 'Select a source',
    attribute_exists: 'Attribute already exists in field',
    adding_failed: 'Adding attributes failed',
    original: 'original',
  },
  export_mapping_unsupported: {
    source: 'Source',
    target: 'Target',
    placeholder: 'Select type',
    add_records: 'Add new records',
    copy_to_target: 'Click to copy to `target`',
  },
  sync_mappings_modal: {
    title: 'Sync mappings',
    info: 'Select the workspace/client to use as base for Syncing. Most of the time you would select Master Mappings. This are the mappings provided/and maintained by Productsup. Second, select the set of mappings to sync with the previously selected mappings.',
    warning: ' Note, this is only syncs the supported mappings.',
    sync: 'Sync',
    sync_successful: 'Synced mappings!',
    attach_failed: 'We were unable attach products to the task. Please try again',
  },
};

const tranlation: Translation = {
  export_mappings: translationObject,
};

export default tranlation;
