import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const update: TranslationObjectAction = {
  title: 'Edit upload',
  subTitle: 'Here you can edit your upload',
};

const create: TranslationObjectAction = {
  title: 'Created',
  subTitle: 'Here you can edit your upload',
};

const translationObject: TranslationObject = {
  title: 'Media',
  placeholder: 'Select a datamodel | Select datamodels',
  emptyStateSubtitle:
    "Start by uploading your first media file. Use our 'Match media' feature to match media files to existing products.",
  create: create,
  update: update,

  assets: 'Asset | Assets',
  variant_name: 'Variant name',
  resolution: 'Resolution',
  choose_image: 'Choose image',
  collection: {
    title: 'Collection | Collections',
    emptyState: {
      subtitle:
        'A collection is automatically generated based on Variant name, Datamodel name, Brand or Status\nwhen the media is attached to a product.',
      button: 'Go to assets',
    },
  },
  datamodels: {
    title: 'Datamodel | Datamodels',
  },
  brands: {
    title: 'Brand | Brands',
  },
  actions: {
    delete_images: 'Delete images',
    attach_labels: 'Attach images to label(s)',
    download_images: 'Download images',
  },
  media_picker: {
    title: 'Media picker',
    search_placeholder: 'Search images',
    image_selected: '{count} image selected | {count} images selected',
    view_original: 'View original',
    choose_image: 'Choose image',
  },
  match: 'Match',
  startOver: 'Start over',
  filter_placeholder: 'Filter for collection',
  import_assets: {
    title: 'Import assets',
    success: 'Successfully imported files.',
    error: 'Failed to import matched assets. Please try again.',
    no_asset_info:
      "Bulk media imports let you automatically add product images, video's and documents to many products at once by matching the GTIN in the filename. You can also add the order in which the files should be imported. For example:",
    match_description:
      'You are about to attach {amountUploads} files to {amountProducts} products. Do you wish to continue?',
    existing_files: 'Overwrite existing filenames',
    overwrite_tooltip:
      'When selecting this option, duplicate filenames will be overwritten with the newly imported media.',
    add_to_variants: {
      title: 'Add media to all variants',
      tooltip:
        'When selecting this option, the uploaded media will be added to all variants of a product.',
    },
    unmatched_assets: 'The following {amount} file(s) could not be matched automatically',
    selected: '{amount} selected files',
    match_product: 'Attach to product',
    matched_assets: 'The following products will be updated',
    match_failed: 'Failed to match assets to products. Please try again.',
  },
  informations: 'Information | Informations',
  attach_media_dialog: {
    title: 'Select labels',
    subtitle: 'Select the labels you want to attach images to',
    attach_to:
      'Attach {imageCount} asset to {productCount} label | Attach {imageCount} assets to {productCount} labels',
    success: 'The assets have been added to the selected labels.',
    error: 'There was an error adding the assets to the selected labels.',
  },
  sort: {
    new_old: 'New - Old',
    old_new: 'Old - New',
  },
  uploads: 'Upload | Uploads',
};

const translation: Translation = {
  media: translationObject,
};

export default translation;
