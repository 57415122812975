<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import ImportMappingSelect from '@/general/ui/components/selects/import-mapping-select.vue';
import { useService } from '@/general/composables/useService';
import { importServiceKey } from 'supplier/modules/import/ts/keys/import-service.key';
import { ImportService } from '@/general/services/overview-service/modules/import.service';
import { Module } from 'platform-unit2-api/modules';
import { DropdownChangeEvent } from 'primevue/dropdown';

const ts = new TranslationService('supplier', 'imports');
const service = useService(importServiceKey, ImportService);

const handleUpdateModule = (selectedModule: Module) => {
  service.partialObject.module = {
    ...selectedModule,
    allowed_file_types: selectedModule.allowed_file_types?.map((fileType) => '.' + fileType),
  };
};
</script>
<template>
  <section class="m-4">
    <div class="field mb-3">
      <ClientModuleSelect
        v-model="service.partialObject.module"
        type="import"
        @change="(event: DropdownChangeEvent) => handleUpdateModule(event.value)"
      />
    </div>
    <div class="field mb-3">
      <ImportMappingSelect v-model="service.partialObject.mapping" />
    </div>
    <div class="field mb-3">
      <label>{{ ts.tModule('language') }}</label>
      <LocaleSelect v-model="service.partialObject.locale" hide-label />
    </div>
  </section>
</template>
