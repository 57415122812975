import { AttributeField } from 'platform-unit2-api/attribute-fields';
import { AttributeTypeEnum } from 'platform-unit2-api/attributes';

/**
 * Array of advanced field types, can be used to check if attribute type is advanced
 */
const advancedFieldTypes: AttributeTypeEnum[] = [
  AttributeTypeEnum.TAB_FIELD,
  AttributeTypeEnum.GROUP_FIELD,
];
/**
 * Check if the field is advanced
 * @param attributeField AttributeField<any>
 */
const isTabField = (attributeField: AttributeField<any>) => {
  if (!attributeField.attribute) {
    return false;
  }

  return AttributeTypeEnum.TAB_FIELD === attributeField.attribute?.options?.type ?? false;
};

/**
 * Check if the field is advanced
 * @param attributeField AttributeField<any>
 */
const isAdvanced = (attributeField: AttributeField<any>) => {
  if (!attributeField.attribute) {
    return false;
  }

  return advancedFieldTypes.includes(attributeField.attribute?.options?.type) ?? false;
};

const isFinacialFieldOrInputSelectField = (attributeField: AttributeField<any>) => {
  if (!attributeField.attribute) {
    return false;
  }

  return (
    [AttributeTypeEnum.FINANCIAL_FIELD, AttributeTypeEnum.INPUT_SELECT_FIELD].includes(
      attributeField.attribute?.options?.type,
    ) ?? false
  );
};

export { isTabField, isAdvanced, isFinacialFieldOrInputSelectField };
