<script setup lang="ts">
import { JsonSchemasRestService, AmazonModule } from 'platform-unit2-api/json-schemas';
import { DefaultSelectProps } from '../../ts/default-select-props.interface';
import BaseSelect from '../../base-select.vue';

interface Props extends DefaultSelectProps<AmazonModule> {}

const props = defineProps<Props>();
const restService = JsonSchemasRestService;
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    fetch-function-name="getChannels"
    option-label="name"
    module-name="channel"
    :filter="false"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
