<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { inject } from 'vue';
import { taskViewServiceKey } from '../../ts/keys/task-view-service.key';
import TaskTypeSelect from '@/general/ui/components/selects/task-type-select.vue';

//Services
const service = inject(taskViewServiceKey);
const ts = new TranslationService('supplier', 'tasks');
</script>
<template>
  <div v-if="service != null" class="flex flex-column h-full justify-content-between">
    <div class="px-4">
      <p-message severity="info" :closable="false" class="my-5">
        {{ ts.tModule('addTask.describeTask') }}
      </p-message>
      <div class="field mb-3">
        <label for="title">{{ ts.titlePlural }}</label>
        <pInputText id="title" v-model="service.partialObject.title" class="w-full" />
      </div>
      <div class="field mb-3">
        <TaskTypeSelect v-model="service.partialObject.action_type" />
      </div>
      <div class="field mb-3">
        <label for="description">{{ ts.tGlobal('description') }}</label>
        <pTextarea
          id="description"
          v-model="service.partialObject.description"
          class="w-full"
          rows="5"
          cols="60"
          align-items-start
        />
      </div>
    </div>
  </div>
</template>
