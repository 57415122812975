import { MutationTree } from 'vuex';
import { StoryManagerVuexState } from './story-manager.state';
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';

export const mutations: MutationTree<StoryManagerVuexState> = {
  setLoading(state: StoryManagerVuexState, loading: boolean) {
    state.loading = loading;
  },

  setCurrentStory(state: StoryManagerVuexState, story: ContentStory) {
    state.activeStoryInStoryBuilder = story;
  },

  setChangesSaved(state: StoryManagerVuexState, changesSaved: boolean) {
    state.changesSaved = changesSaved;
  },
};
