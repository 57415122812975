import { MenuItem } from 'primevue/menuitem';
import { RouteRecordRaw } from 'vue-router';
import ApplicationSidebar from '@/core/app/components/application-sidebar.vue';
import Header from '@/general/ui/layout/components/application-header.vue';
import {
  CreateAppRouteParams,
  CreateRouteParams,
  StaticCreateAppRouteParams,
} from './interfaces/createRouteParams.interface';

export type Platform = 'retailer' | 'supplier' | 'general' | 'global';
interface RouterFactoryParams {
  navigationGroup?: string;
  platform?: Platform;
  module?: string;
  tabs?: MenuItem[];
}

/**
 * Factory for creating routes
 */
export class RouteFactory {
  //#region properties
  /**
   * The application title in the browser tab
   */
  private static applicationTitle = 'Productsup Platform';

  /**
   * The tabs for the header
   */
  private _tabs: MenuItem[] = [];

  public set tabs(value: MenuItem[]) {
    this._tabs = value;
  }

  /**
   * The navigation group, used for highlighting the navigation group in the sidebar
   */
  private _group: string | undefined;

  /**
   * The module name for translations
   */
  private _module: string | undefined;

  /**
   * The platform name for translations
   */
  private _platform: string | undefined;
  //#endregion properties

  //#region constructor

  //Overloads
  constructor(platform?: Platform, moduleName?: string, tabs?: MenuItem[], group?: string);

  constructor(params?: RouterFactoryParams);

  //Implementation
  /**
   * Create a instance to auto inject default values injected in constructor
   * @param platformOrParams Platform string or params object
   * @param moduleName module name
   * @param tabs tabs
   * @param group group
   */
  constructor(
    platformOrParams?: RouterFactoryParams | Platform,
    moduleName?: string,
    tabs?: MenuItem[],
    group?: string,
  ) {
    if (platformOrParams instanceof Object) {
      this._module = platformOrParams?.module ?? moduleName;
      this._platform = platformOrParams.platform;
      this._group = platformOrParams?.navigationGroup;

      if (platformOrParams.tabs) {
        this._tabs = platformOrParams.tabs;
      }
    } else {
      this._platform = platformOrParams;
      this._module = moduleName;
      this._group = group;

      if (tabs) {
        this._tabs = tabs;
      }
    }
  }

  //#endregion constructor

  //#region private methods

  //#region helper functions

  /**
   * Create route with default components
   * @param params Route params
   */
  private static _createDefaultRoute(params: CreateRouteParams): RouteRecordRaw {
    return {
      path: params.path,
      name: params.name,
      components: {
        sidebar: params.sidebar === undefined ? ApplicationSidebar : params.sidebar,
        default: params.component,
        header: params.header === undefined ? Header : params.header,
        footer: params.footer,
      },
      props: params.props,
      meta: params.meta ?? {},
      children: params.children,
    };
  }

  /**
   * Create route without default components
   * @param params Route params
   */
  private static _createEmtpyRoute(params: CreateRouteParams): RouteRecordRaw {
    return {
      path: params.path,
      name: params.name,
      components: {
        sidebar: params.sidebar,
        default: params.component,
        header: params.header,
        footer: params.footer,
      },
      props: params.props,
      meta: params.meta ?? {},
      children: params.children,
    };
  }

  /**
   * Set meta title and/or group
   * @param route Route to change values from
   * @param params Route params
   */
  private static _setMeta(route: RouteRecordRaw, params: CreateRouteParams): void {
    //Overwrite title in meta, this line can be changed when required.
    if (params.group) {
      params.meta.group = params.group;
    }

    //Meta is set while route is created
    if (params.title) {
      route.meta!.title = `${RouteFactory.applicationTitle} - ${params.title}`;
    } else {
      route.meta!.title = `${RouteFactory.applicationTitle}`;
    }

    if (params.rights) {
      route.meta!.rights = params.rights;
    }

    if (params.breadcrumbs != undefined) {
      route.meta!.breadcrumbs = params.breadcrumbs;
    }
  }

  /**
   * Removes components from the route when they are undefined (default values)
   * @param route Route to change values from
   */
  private static _removeEmptyComponents(route: RouteRecordRaw): void {
    if (!route.components?.default) {
      delete route.components?.default;
    }

    if (!route.components?.sidebar) {
      delete route.components?.sidebar;
    }

    if (!route.components?.header) {
      delete route.components?.header;
    }

    if (!route.components?.footer) {
      delete route.components?.footer;
    }
  }

  /**
   * Remove children from route when value is undefined
   * @param route Route to change values from
   */
  private static _removeEmptyChildren(route: RouteRecordRaw): void {
    if (!route.children) {
      delete route.children;
    }
  }

  /**
   * Set header props when header component is used. Sets tabs when they are given.
   * @param params Route params
   * @param tabs tab menu tabs
   */
  private static _setTabHeaderProps(
    params: CreateAppRouteParams,
    tabs?: MenuItem[],
    platform?: string,
    moduleName?: string,
  ) {
    params.props = {
      ...params.props,
      header: { ...params.props?.header, title: params.title },
    };

    if (tabs && tabs.length > 0) {
      //Tabs param always overwrites the prop from the header
      params.props.header.tabs = params.props.header.tabs ? params.props.header.tabs : tabs;
    }

    if (platform) {
      params.props.header.platform = platform;
    }

    if (moduleName) {
      params.props.header.module = moduleName;
    }

    if (params.creatable != undefined) {
      params.props.header.creatable = params.creatable;
    }
  }

  /**
   * Set meta.group when group is passed.
   * @param params Route params
   */
  private static _setMetaGroup(params: CreateRouteParams, group?: string) {
    if (params.group != undefined) {
      params.meta = params.meta ? params.meta : {};
      params.meta.group = params.group;
    } else if (group != undefined) {
      params.meta = params.meta ? params.meta : {};
      params.meta.group = group;
    }
  }

  private static _setRedirect(route: RouteRecordRaw, params: CreateRouteParams) {
    if (params.redirect) {
      route.redirect = params.redirect;
    }
  }

  //#endregion helper functions

  /**
   * Create a route
   * @param params properties passed to the route
   */
  private static _createRoute(params: CreateRouteParams, defaults = true): RouteRecordRaw {
    let route: RouteRecordRaw;

    //if default components have to be injected
    if (defaults) {
      route = RouteFactory._createDefaultRoute(params);
    } else {
      route = RouteFactory._createEmtpyRoute(params);
    }

    //Delete chilren if they don't exist
    RouteFactory._removeEmptyChildren(route);
    RouteFactory._setMeta(route, params);
    RouteFactory._removeEmptyComponents(route);
    RouteFactory._setRedirect(route, params);

    return route;
  }
  //#endregion private methods

  /**
   * Create a child route
   * @param params Route params
   */
  public static createChildRoute(params: CreateRouteParams): RouteRecordRaw {
    if (params.breadcrumbs == undefined) {
      params.breadcrumbs = false;
    }

    return this.createEmptyRoute(params);
  }

  //#region create empty route
  /**
   * Create route with only default component, no sidebar or header etc.
   *
   * @note route.meta.title will be overwriten with the tile passed in params
   * @param params route params
   */
  public static createEmptyRoute(params: CreateRouteParams): RouteRecordRaw {
    RouteFactory._setMetaGroup(params);

    return RouteFactory._createRoute(params, false);
  }

  /**
   * Create a child route
   * @param params Route params
   */
  public createChildRoute(params: CreateRouteParams): RouteRecordRaw {
    if (params.breadcrumbs == undefined) {
      params.breadcrumbs = false;
    }

    return this.createEmptyRoute(params);
  }

  /**
   * Create route with only default component, no sidebar or header etc.
   *
   * @note route.meta.title will be overwriten with the tile passed in params
   * @param params route params
   */
  public createEmptyRoute(params: CreateRouteParams): RouteRecordRaw {
    RouteFactory._setMetaGroup(params, this._group);

    return RouteFactory._createRoute(params, false);
  }

  //#endregion create empty route

  //#region create route
  /**
   * Create route using header and sidebar
   * @param params object for creating the route
   */
  public static createAppRoute(params: StaticCreateAppRouteParams): RouteRecordRaw {
    RouteFactory._setTabHeaderProps(
      params,
      params.tabs,
      params.translations?.platform,
      params.translations?.module,
    );
    RouteFactory._setMetaGroup(params);

    return RouteFactory._createRoute(params);
  }

  /**
   * Create route using header and sidebar
   *
   * Auto injects tabs and title into the meta and header props.
   * @param params object for creating the route
   */
  createAppRoute(params: CreateAppRouteParams): RouteRecordRaw {
    RouteFactory._setTabHeaderProps(
      params,
      params.tabs ?? this._tabs,
      params.translations?.platform ?? this._platform,
      params.translations?.module ?? this._module,
    );
    RouteFactory._setMetaGroup(params, this._group);

    return RouteFactory._createRoute(params);
  }
  //#endregion create route
}
