import { HttpClient } from 'platform-unit2-api/core';
import { KeycloakService } from '../security/keycloak.service';
import { Defaults } from '@/general/utils/constants/defaults';

export function installHttpClient() {
  HttpClient.instance.updateConfig({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    isAuthenticated: KeycloakService.getInstance().isAuthenticated(),
    updateToken: () => KeycloakService.getInstance().updateToken(),
    logout: () => KeycloakService.getInstance().logout(window.location.href),
    defaultRequestLimit: Defaults.REQUEST_LIMIT,
  });
}

export function mockInstallHttpClient() {
  HttpClient.instance.updateConfig({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    isAuthenticated: true,
    updateToken: async () => 'token',
    logout: () => Promise.resolve(),
    defaultRequestLimit: Defaults.REQUEST_LIMIT,
  });

  const mockClient = {
    get: vi.fn().mockResolvedValue({ data: 'success' }),
    post: vi.fn().mockResolvedValue({ data: 'success' }),
    delete: vi.fn().mockResolvedValue({ data: 'success' }),
    put: vi.fn().mockResolvedValue({ data: 'success' }),
    patch: vi.fn().mockResolvedValue({ data: 'success' }),
  };

  (HttpClient.instance as any)._axios = mockClient;
}
