import {
  ImportState,
  MatchedProductUploadsResponse,
} from '@/platforms/supplier/modules/import/ts/import.types';
import { GetterTree } from 'vuex';

export const getters: GetterTree<ImportState, {}> = {
  matchedProductUploads(state: ImportState): MatchedProductUploadsResponse[] {
    return state.matchedProductUploads;
  },
};
