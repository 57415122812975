import { Metric } from 'platform-unit2-api/dashboard';
import { DashboardData, DashboardState } from 'supplier/modules/dashboard/dashboard.types';
import { GetterTree } from 'vuex';

export const getters: GetterTree<DashboardState, {}> = {
  data(state: DashboardState): DashboardData | undefined {
    return state.data;
  },
  metrics(state: DashboardState): Metric[] {
    return state.metrics;
  },
  cachedAt(state: DashboardState): Date | undefined {
    return state.cached_at;
  },
};
