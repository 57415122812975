import { FeaturePermission } from 'platform-unit2-api/roles';

import Datamodels from 'supplier/modules/datamodel-attributes/views/datamodel-attributes.view.vue';
import DatamodelHeader from 'supplier/modules/datamodel-attributes/components/datamodel-header.vue';

import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';

const datamodelAttributesRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/datamodels/:id/attributes',
    name: 'datamodels-attributes',
    translations: {
      module: 'datamodels',
      platform: 'supplier',
    },
    header: DatamodelHeader,
    component: Datamodels,
    title: 'Datamodel Attributes',
    rights: [FeaturePermission.MANAGE_DATAMODELS],
  }),
];

export default datamodelAttributesRoutes;
