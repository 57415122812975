<script setup lang="ts">
//components
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import ContentImageAndText from '../content-components/content-image-and-text.vue';
import StandardThreeImageTextClass from '@/api/v1/content-story/ts/classes/three-image-text.class';

interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);

// const / refs
const storyModule = storyService?.value.getModule<StandardThreeImageTextClass>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid">
    <template #content>
      <div class="flex flex-column h-full">
        <ContentHeadline
          class="main-header"
          :headline="storyModule?.data.headline?.value"
          @headline-value="storyModule?.setMainHeadline($event)"
        />
        <div class="flex gap-3 justify-content-around main-content">
          <ContentImageAndText :uuid="uuid" :block="'block1'" custom-text-box-style="70%" />
          <ContentImageAndText :uuid="uuid" :block="'block2'" custom-text-box-style="70%" />
          <ContentImageAndText :uuid="uuid" :block="'block3'" custom-text-box-style="70%" />
        </div>
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped>
.main-header {
  height: 5%;
}
.main-content {
  height: 95%;
}
</style>
