//vue
import { Component, markRaw } from 'vue';

//component
import StandardSingleSideImage from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-single-side-image.vue';
//types
import { Upload } from 'platform-unit2-api/uploads';
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { BaseStoryModule } from './base-story-module.class';
import { SingleSideImage } from '../story-types/modules/single-side-image.interface';
import { ImagePosition } from '../enums/image-position.enum';

//services
import { IContentImage } from './interfaces/IContentImage.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export class StandardSingleSideImageClass
  extends BaseStoryModule<SingleSideImage>
  implements IContentImage<unknown>
{
  ts: TranslationService;
  moduleTranslationKey = 'storyBuilder.singleSideImageModule';
  component: Component = markRaw(StandardSingleSideImage);
  minimumImageSize: `${string} : ${string}` = '300 : 300';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';

  private _imageValidatorService: ImageValidatorService;

  constructor(data?: SingleSideImage) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._imageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (this.data?.type == null) {
      this.data.imagePositionType = ImagePosition.LEFT;
      this.data.type = ContentStoryModulesEnum.SingleSideImage;
      this.data.block = {
        type: ModuleComponentTypes.ImageTextBlock,
        image: {
          uploadDestinationId: '',
          type: ModuleComponentTypes.Image,
          altText: '',
        },
        headline: { type: ModuleComponentTypes.Text, value: '' },
        body: { type: ModuleComponentTypes.TextArea, value: '' },
      };
    }
  }

  getAltText(): string | undefined {
    return this.data.block.image?.altText ?? '';
  }

  setAltText(altText?: string | undefined): void {
    this.data.block = {
      type: ModuleComponentTypes.ImageTextBlock,
      image: {
        uploadDestinationId: this.data.block.image?.uploadDestinationId ?? '',
        type: ModuleComponentTypes.Image,
        altText: altText ?? '',
      },
    };
  }

  public getUploadUrl(): string | undefined {
    return this.data.block.image?.uploadDestinationId;
  }

  public hasUploadSet(): boolean {
    return this.data.block?.image?.uploadDestinationId ? true : false;
  }
  public setUploadData(upload: Upload) {
    if (this._imageValidatorService.hasCorrectDimensions(upload)) {
      this.data.block.image = {
        type: ModuleComponentTypes.Image,
        altText: this.data.block.image?.altText ?? '',
        uploadDestinationId: upload.public_url,
      };
    }
  }

  public setHeadline(headlineText?: string) {
    if (headlineText) {
      this.data.block.headline = { type: ModuleComponentTypes.Text, value: headlineText };
    }
  }

  public setTextArea(textAreaValue: string) {
    this.data.block.body = { type: ModuleComponentTypes.TextArea, value: textAreaValue };
  }

  public setImagePosition(position: ImagePosition) {
    this.data.imagePositionType = position;
  }
}
