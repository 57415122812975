<script setup lang="ts" generic="T">
import { ref, watchEffect } from 'vue';
import { DataTableProps } from 'primevue/datatable';
import useTable from '@/general/composables/useTable/table';
import TableColumn from '@/general/composables/useTable/table-column';
import { RowComponent } from '@/general/composables/useTable/row-component.interface';
import { ITable } from '@/general/composables/useTable/table.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import { TableProps } from '@/general/composables/useTable/table-props';

export interface Props<T> extends Omit<DataTableProps, 'tableProps'> {
  tableProps: TableProps<T>;
  composable?: ITable<T>;
  selectable?: boolean;
  hasColumnFilter?: boolean;
}
const props = defineProps<Props<T>>();
const ts = new TranslationService('global');
const filters = ref(props.tableProps.filtersToTableFilters() ?? {});
const {
  rows,
  loading,
  changePagination,
  changeFilters,
  changeSortField,
  changeSortDirection,
  perPage,
  total,
  checkedRows,
  checkAll,
  toggleCheckAll,
  toggleShownColumns,
  showedColumns,
} = props.composable ?? useTable(props.tableProps);

watchEffect(() => (checkAll.value = checkedRows.value.length === rows.value?.length));
// https://vuejs.org/guide/components/attrs.html -> some information about v-bind="$attrs" and what is it for
</script>

<template>
  <pDataTable
    lazy
    paginator
    scrollable
    auto-layout
    removable-sort
    :value="rows"
    data-testid="generic-table"
    :total-records="total"
    :rows="perPage"
    :filters="filters"
    scroll-height="flex"
    :selection="checkedRows"
    filter-display="menu"
    :loading="loading"
    :paginator-template="`${
      total > perPage
        ? 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport'
        : ''
    } RowsPerPageDropdown`"
    :rows-per-page-options="[5, 15, 50, 100, 250, 500]"
    current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
    responsive-layout="scroll"
    :row-hover="!loading && rows?.length > 0"
    v-bind="$attrs"
    @page="changePagination"
    @update:filters="changeFilters"
    @update:sort-field="changeSortField"
    @update:sort-order="changeSortDirection"
  >
    <template v-if="hasColumnFilter || $slots['header']" #header>
      <pMultiselect
        v-if="hasColumnFilter"
        :model-value="showedColumns"
        :options="tableProps.columns.filter((c) => c.label !== '')"
        option-label="label"
        placeholder="Select Columns"
        class="mr-3"
        style="width: 20em"
        @update:model-value="toggleShownColumns"
      >
        <template #option="{ option }: { option: TableColumn<unknown> }">
          {{ option.label }}
        </template>
      </pMultiselect>
      <slot name="header" />
    </template>
    <template #empty>
      <slot name="emptyState">
        <p-message class="w-full" severity="info" :closable="false">
          {{ ts.tGlobal('nothingFound') }}
        </p-message>
      </slot>
    </template>
    <template #loading>
      <pProgressSpinner class="table-progress-spinner" stroke-width="5" />
    </template>
    <pColumn v-if="selectable" frozen class="check-column">
      <template #header>
        <pCheckbox v-model="checkAll" :binary="true" @change="toggleCheckAll" />
      </template>
      <template #body="slotProps">
        <pCheckbox v-model="checkedRows" :value="slotProps.data" />
      </template>
    </pColumn>
    <pColumn
      v-for="col of tableProps.columns"
      :key="col.key.toString() + col.label"
      :frozen="col.isFrozen"
      :field="col.key"
      :header="col.label"
      :show-filter-match-modes="false"
      :sortable="col.isSortable"
      :style="{
        'min-width': '14rem',
        ...col.additionalStyling,
        display: tableProps.isColumnVisible(col.key.toString(), showedColumns) ? '' : 'none',
      }"
    >
      <template #body="{ data }">
        <slot :name="col.key.toString()" :data="data[col.key.toString()]">
          <component
            :is="(tableProps.getColumn(col.key.toString())?.customValue?.(data) as RowComponent)?.component"
            v-if="tableProps.getColumn(col.key.toString())?.isCustomValueComponent(data)"
            v-bind="(tableProps.getColumn(col.key.toString())?.customValue?.(data) as RowComponent)?.props"
          />
          <div v-if="!tableProps.getColumn(col.key.toString())?.isCustomValueComponent(data)">
            {{
              tableProps.getColumn(col.key.toString())?.customValue?.(data) ??
              data[col.key.toString()] ??
              tableProps.getColumn(col.key.toString())?.fallbackValue
            }}
          </div>
        </slot>
      </template>
      <template
        v-if="tableProps.getColumn(col.key.toString())?.filter != null"
        #filter="{ filterModel }"
      >
        <component
          :is="tableProps.getColumn(col.key.toString())!.filter!.component.component"
          v-model="filterModel.value"
          v-bind="tableProps.getColumn(col.key.toString())!.filter!.component.props"
        />
      </template>
    </pColumn>
    <slot name="additionalColumns"></slot>

    <pColumn v-if="$slots['actions']" style="max-width: 10rem">
      <template #body="{ data }">
        <slot name="actions" v-bind="{ data }"></slot>
      </template>
    </pColumn>
  </pDataTable>
</template>
<style lang="scss">
.table-progress-spinner svg {
  width: 35px;
  height: 35px;
}
.check-column {
  min-width: 4rem;
  flex-grow: 0 !important;
}
</style>
