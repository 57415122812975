//vue
import { Component, markRaw } from 'vue';

//component
import StandardCompanyLogo from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-company-logo.vue';

//types
import { SingleImageLogo } from '../story-types/modules/single-image-logo.interface';
import { Upload } from 'platform-unit2-api/uploads';
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { BaseStoryModule } from './base-story-module.class';

//services
import { IContentImage } from './interfaces/IContentImage.interface';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export class SingleImageLogoModule
  extends BaseStoryModule<SingleImageLogo>
  implements IContentImage<unknown>
{
  ts: TranslationService;
  moduleTranslationKey = 'storyBuilder.brandModuleDisplayName';
  component: Component = markRaw(StandardCompanyLogo);
  minimumImageSize: `${string} : ${string}` = '600 : 180';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';

  private _imageValidatorService: ImageValidatorService;

  constructor(data?: SingleImageLogo) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._imageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (this.data?.type == null) {
      this.data.type = ContentStoryModulesEnum.SingleImageLogo;
    }
  }
  public getAltText(): string | undefined {
    return this.data.image?.altText ?? '';
  }
  public setAltText(altText?: string): void {
    if (altText == null) {
      return;
    }

    this.data.image = {
      type: ModuleComponentTypes.Image,
      altText: altText,
      uploadDestinationId: this.data.image?.uploadDestinationId ?? '',
    };
  }
  public getUploadUrl(): string | undefined {
    return this.data.image?.uploadDestinationId;
  }

  public hasUploadSet(): boolean {
    return this.data.image?.uploadDestinationId != null;
  }
  public setUploadData(upload: Upload) {
    if (this._imageValidatorService.hasCorrectDimensions(upload)) {
      this.data.image = {
        type: ModuleComponentTypes.Image,
        altText: this.data.image?.altText ?? '',
        uploadDestinationId: upload.public_url,
      };
    }
  }
}
