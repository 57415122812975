import { MaxLengthAttributeFieldService } from 'services/attribute-fields/max-length-attribute-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';

import { FieldFunctions } from '../interfaces/field-functions.interface';

export class RichTextFieldService extends MaxLengthAttributeFieldService {
  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
  }

  /**
   * Calculate and return the length of the cleaned-up text content of the product attribute field.
   * @returns {number} The length of the cleaned-up text content of the product attribute field.
   * If the cleaned-up text is non-empty, returns the character count; otherwise, returns 0.
   */
  public textAreaLength(): number {
    const val =
      this.productAttributeField.value === '<p><br></p>'
        ? undefined
        : this.productAttributeField.value?.replace('&nbsp;', ' ');

    const text = val?.replace(/<[^>]*>?/gm, '') ?? '';
    return text ? text.length : 0;
  }
}
