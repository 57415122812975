import { RouterService } from '@/core/router/router.service';
import { ApplicationTaskService } from '../application.task.service';

export class GoToApplicationTask extends ApplicationTaskService {
  public async handle(): Promise<void> {
    const routerService = RouterService.getInstance();

    if (routerService.hasRouteByPath(this.redirectRoute)) {
      routerService.router.push({ path: this.redirectRoute });
    } else {
      routerService.router.push({ name: 'notFound' });
    }

    this._complete();
  }

  constructor(private redirectRoute: string) {
    super('GoToApplicationTask');
  }
}
