export abstract class ApplicationTaskService {
  public name: string;
  public _handled = false;

  public get handled(): boolean {
    return this._handled;
  }
  public abstract handle(): void | Promise<void>;
  constructor(name: string) {
    this.name = name;
  }
  protected _complete(): void {
    this._handled = true;
  }
}
