<script setup lang="ts">
import { ref, watch, Ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { GroupingMode, ChartDataset } from 'supplier/modules/dashboard/dashboard.types';
import { Metric } from 'platform-unit2-api/dashboard';
import { useStore } from 'vuex';
import moment from 'moment';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DashboardRestService } from 'platform-unit2-api/dashboard';

const props = withDefaults(
  defineProps<{
    selectedDates?: string[];
    uriKey: string;
    refreshKey: number;
  }>(),
  {
    selectedDates: undefined,
    uriKey: '',
    refreshKey: undefined,
  },
);

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'dashboard');
const tsBrands = new TranslationService('supplier', 'brands');
const tsCategories = new TranslationService('supplier', 'categories');
const dashboardApi = new DashboardRestService();

//** Constants */
Chart.register(zoomPlugin);
const store = useStore();
const chartComponent = ref();
const showFilterSidebar = ref(false);
let chart: Chart | undefined = undefined;
const allDatasets: Ref<ChartDataset[]> = ref([]);
const selectedDatasets = ref<ChartDataset[]>();
const historicCompletenessData = ref<Metric>();
const loading = ref(true);

const groupingModes: Ref<GroupingMode[]> = ref([
  {
    id: 1,
    title: ts.tGlobal('channel'),
    value: 'module',
  },
  {
    id: 2,
    title: ts.tGlobal('workspace'),
    value: 'owner',
  },
  {
    id: 3,
    title: tsBrands.tModule('title', { choice: 2 }),
    value: 'brand',
  },
  {
    id: 4,
    title: tsCategories.tModule('title', { choice: 2 }),
    value: 'category',
  },
]);

const activeGroup = ref(3);

const changeGroupingMode = (group: GroupingMode) => {
  activeGroup.value = group.id;
  loadData(group.value);
};

const colors = [
  '22300d',
  '36080d',
  '333333',
  '50701f',
  'AF8131',
  '470a11',
  '967AB8',
  '858585',
  '8fc93a',
  '9D586F',
  'D9AF30',
  '5F461B',
  '7D121E',
  '685B86',
  '17223b',
  'F4B915',
  'B3192B',
  '4B4261',
  '223258',
  '29524a',
  'eaae0b',
  'E0293E',
  '9F59C5',
  '1b3631',
  '283b67',
  '3e7a6e',
  'f6c43c',
  'DE172E',
  '803AA6',
  '4565b0',
  '065143',
  'EA860B',
  'EA3449',
  '5D2A79',
  '0076f5',
  '04392f',
  '000000',
  'F59929',
  '710938',
  '3A1B4B',
  '0a81ff',
  '7BA739',
  'F57200',
  '970C4B',
  '634058',
  '2f7fda',
  '4E6A24',
  'FF9233',
  'E21270',
  'dddddd',
];

const calcDateExpression = (start: Date, end: Date): string => {
  const diffTime = end.getTime() - start.getTime();
  const diffDays = diffTime / (1000 * 3600 * 24);
  if (diffDays > 90) return 'month';
  if (diffDays > 14) return 'week';
  return 'day';
};

const initChart = () => {
  chart = new Chart(chartComponent.value, {
    type: 'line',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',

          labels: {
            usePointStyle: true,
            boxWidth: 8,
          },
        },
        tooltip: {
          mode: 'point',
          usePointStyle: true,
        },
        zoom: {
          pan: {
            enabled: false,
          },
          limits: {
            y: { min: 0, max: 100 },
          },
          zoom: {
            wheel: {
              enabled: false,
            },
            pinch: {
              enabled: false,
            },
            mode: 'y',
          },
        },
      },
      scales: {
        x: {
          title: {
            display: false,
            text: 'Days',
          },
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: false,
            text: ts.tModule('user_dashboard.activity.completeness_score'),
          },
          grid: {
            display: false,
          },
          suggestedMax: 100,
          beginAtZero: true,
        },
      },
    },
  });
};

const updateChart = (metric?: Metric) => {
  if (!chart) return;
  allDatasets.value = [];
  chart.data.datasets = [];
  chart.data.labels = [];

  metric?.data?.labels.map((item: string) => {
    chart?.data?.labels?.push(
      // new Date(item).toLocaleDateString().split('T')[0],
      moment(new Date(item)).format('DD MMM Y'),
    );
  });

  allDatasets.value = sortedObjects(
    (allDatasets.value = metric?.data
      ? metric.data.series.map((item, index) => {
          const filled: number[] = [];
          item.data.reduce((carry: number, item: number) => {
            if (item == null && carry != null) {
              item = carry;
            } else if (item == null && carry == null) {
              item = 0;
            }

            filled.push(item);
            return item;
          }, item.data[0]);

          if (item.name == null) {
            item.name = ts.tModule('user_dashboard.activity.master_data');
            item.color = '0080fa';
          }

          if (item.color == null) {
            item.color = colors[index];
          }

          return {
            label: item.name,
            data: filled,
            fill: false,
            borderWidth: 1.5,
            borderColor: '#' + item.color,
            backgroundColor: '#' + item.color,
            tension: 0.3,
            pointStyle: 'circle',
            hoverRadius: 3,
            radius: 1,
          };
        })
      : []),
    'label',
  );
  if (groupingModes.value[activeGroup.value - 1]?.title === 'Channels') {
    const masterData = allDatasets.value.find((dataset) => dataset.label === 'Master Data');
    const otherDatasets = allDatasets.value.filter((dataset) => dataset.label !== 'Master Data');
    allDatasets.value =
      masterData !== undefined ? [masterData, ...otherDatasets] : allDatasets.value;
  }

  selectedDatasets.value = selectedDatasets.value ? selectedDatasets.value : allDatasets.value; //Selected the first one as default while loading the data
  chart.data.datasets = selectedDatasets.value;
  chart.update();
};

const sortedObjects = (data: any, sortBy: string) => {
  return data.sort((a: any, b: any) =>
    a[sortBy].toLocaleLowerCase() > b[sortBy].toLocaleLowerCase()
      ? 1
      : b[sortBy].toLocaleLowerCase() > a[sortBy].toLocaleLowerCase()
      ? -1
      : 0,
  );
};

const loadData = async (groupName: string) => {
  loading.value = true;
  allDatasets.value = [];

  try {
    let rangeStart = new Date();
    let rangeEnd = new Date();

    if (!props.selectedDates || props.selectedDates[0] == null || props.selectedDates[1] == null) {
      rangeStart.setDate(rangeStart.getDate());
      rangeStart.setHours(0, 0, 0, 0);

      rangeEnd.setHours(23, 59, 59, 999);
    } else {
      rangeStart = new Date(props.selectedDates[0]);
      rangeEnd = new Date(props.selectedDates[1]);
    }

    historicCompletenessData.value = await dashboardApi.getHistoricMetricDate(
      props.uriKey,
      rangeStart,
      rangeEnd,
      calcDateExpression(rangeStart, rangeEnd),
      groupName,
    );

    selectedDatasets.value = undefined;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const showFilters = () => {
  showFilterSidebar.value = true;
};

const updateDatasets = (data: ChartDataset[] = []) => {
  selectedDatasets.value = data;
};

watch(
  () => props.selectedDates,
  () => {
    if (props.selectedDates && props.selectedDates[0] != null && props.selectedDates[1] != null) {
      loadData(groupingModes.value[activeGroup.value - 1].value);
    }
  },
);

watch(
  () => historicCompletenessData.value,
  () => {
    updateChart(historicCompletenessData.value);
  },
);

watch(
  () => props.refreshKey,
  () => {
    loadData(groupingModes.value[activeGroup.value - 1].value);
  },
);
watch(
  () => selectedDatasets.value,
  () => {
    updateChart(historicCompletenessData.value);
  },
);

onMounted(async () => {
  initChart();

  const currentUserType = await store.getters['users/currentUser'].workspace?.workspace_type?.type;
  // Setting visibility of buttons
  groupingModes.value = groupingModes.value.map((group) => {
    if (group.value === 'module' && currentUserType !== ClientTypeEnum.SUPPLIER)
      return { ...group, visible: false };
    return { ...group, visible: true };
  });

  groupingModes.value = groupingModes.value.filter((group) => group.visible);

  if (currentUserType === ClientTypeEnum.SUPPLIER) {
    activeGroup.value = 1;
    loadData('module');
  } else if (currentUserType === ClientTypeEnum.RETAILER) {
    activeGroup.value = 2;
    loadData('owner');
  } else {
    activeGroup.value = 3;
    loadData('brand');
  }
});
</script>
<template>
  <div>
    <pCard>
      <template #subtitle>
        <div class="flex justify-content-between">
          <h4>
            {{ ts.tModule('user_dashboard.activity.title') }}
            <i
              v-tooltip.right="{
                value: ts.tModule('user_dashboard.activity.info'),
              }"
              class="mdi mdi-help-circle-outline text-primary"
              :style="{ transform: 'translateY(3px)' }"
            ></i>
          </h4>
          <div>
            <span class="p-buttonset">
              <p-button
                v-for="group in groupingModes"
                :key="group.id"
                :label="group.title"
                :class="[
                  'border-1 border-solid border-gray-100 text-black-alpha-90',
                  activeGroup === group.id ? ' text-white' : 'p-button-outlined',
                ]"
                @click="changeGroupingMode(group)"
            /></span>
            <p-button
              v-tooltip.top="{ value: 'filter', class: 'text-sm' }"
              icon="mdi mdi-filter-outline"
              class="ml-2 p-2 p-button-outlined text-xl"
              @click="showFilters"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div v-if="loading" class="h-25rem mt-4 w-full">
          <div class="align-items-end flex justify-content-between">
            <pSkeleton
              v-for="skeleton in Array.from(Array(19).keys())"
              :key="skeleton"
              :class="`h-${Math.floor(Math.random() * 20) + 2}rem border-noround`"
              style="width: 3px"
            />
          </div>
          <pSkeleton class="border-noround" style="height: 3px" />
        </div>
        <canvas v-show="!loading" ref="chartComponent" width="600" height="250" />
      </template>
    </pCard>

    <!-- Filter Sidebar -->
    <pDrawer
      v-model:visible="showFilterSidebar"
      :modal="false"
      class="filters-sidebar p-drawer-sm"
      position="right"
    >
      <template #header>
        <h3 class="font-bold text-2xl">
          {{ ts.tModule('user_dashboard.activity.filters') }}
        </h3>
      </template>
      <main>
        <div>
          <div class="border-100 border-bottom-1 justify-content-between mt-2">
            <div class="flex justify-content-between mb-1">
              <p-button
                :label="ts.tModule('user_dashboard.select_all')"
                text
                class="px-2"
                @click="updateDatasets(allDatasets)"
              />
              <p-button
                :label="ts.tGlobal('clear')"
                text
                severity="danger"
                class="px-2"
                @click="updateDatasets()"
              />
            </div>
          </div>
          <div class="border-100 border-bottom-1 mt-3">
            <div v-for="(data, index) of allDatasets" :key="index" class="field-checkbox">
              <p-checkbox :id="index" v-model="selectedDatasets" name="category" :value="data" />
              <label :for="(index as any)" class="cursor-pointer"
                >{{ data.label }} <i class="mdi mdi-circle" :style="{ color: data.borderColor }"></i
              ></label>
            </div>
          </div>
        </div>
      </main>
    </pDrawer>
  </div>
</template>
<style lang="scss" scoped>
.p-card {
  border: 1px solid $gray-border-color;
  box-shadow: none;
}

.filters-sidebar .p-sidebar-header {
  justify-content: space-between !important;
}
</style>
