import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ref } from 'vue';

export function useCopyText() {
  const copySuccess = ref(false);

  const ts = new TranslationService('global', 'general');
  const toast = ToastService.getInstance();

  const copyToClipboard = async (message: string) => {
    await navigator.clipboard
      .writeText(message)
      .then(() => {
        copySuccess.value = true;
      })
      .catch(() => {
        toast.displayErrorToast(ts.unexpectedError);
      })
      .finally(() => {
        setTimeout(() => {
          copySuccess.value = false;
        }, 1000);
      });
  };

  return { copyToClipboard, copySuccess };
}
