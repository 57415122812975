import { actions } from 'supplier/modules/users/store/user.actions';
import { getters } from 'supplier/modules/users/store/user.getters';
import { mutations } from 'supplier/modules/users/store/user.mutations';
import { UserState } from 'supplier/modules/users/user.types';
import { Module } from 'vuex';

const namespaced = true;
export const state: UserState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  expiration: undefined,
};

export const userStore: Module<UserState, {}> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
