import { User, UserRestService } from 'platform-unit2-api/users';
import { RouterService } from '@/core/router/router.service';
import { UserState } from 'supplier/modules/users/user.types';
import { MutationTree } from 'vuex';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';

export const mutations: MutationTree<UserState> = {
  async updateUser(state: UserState, user: User) {
    state.user = user;
    RouterService.getInstance().currentUser = undefined;
    UserRestService.currentUser = user;
    FeatureFlagService.getInstance().setUser(user);
  },

  resetUser(state: UserState) {
    state.user = undefined;
    state.accessToken = undefined;
    state.refreshToken = undefined;
    state.expiration = undefined;
  },
};
