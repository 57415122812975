import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create a Enhancements',
  subTitle: 'Here you can create a new Enhancement',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Enhancement',
};

const update: TranslationObjectAction = {
  title: 'Edit Enhancement',
  subTitle: 'Here you can edit a Enhancement.',
};

const translationObject: TranslationObject = {
  title: 'AI Enhancement | AI Enhancements',

  create: create,
  delete: deleteTranslation,
  update: update,
};

const translation: Translation = {
  'ai-enhancements': translationObject,
};

export default translation;
