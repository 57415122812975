<script setup lang="ts">
import { Module } from 'platform-unit2-api/modules';
import BaseSelect from './base-select.vue';
import { ClientsRestService } from 'platform-unit2-api/clients';
import { useStore } from 'vuex';
import { DefaultSelectProps } from './ts/default-select-props.interface';

interface Props extends DefaultSelectProps<Module> {
  type?: 'export' | 'import' | undefined;
}

const props = defineProps<Props>();
const clientRestService = ClientsRestService;
/** Composables */
const store = useStore();
</script>
<template>
  <BaseSelect
    :rest-service="clientRestService"
    fetch-function-name="getModulesForClient"
    :custom-fetch-params="[store.getters['users/currentUser']?.workspace?.id, props.type]"
    option-label="name"
    module-name="channel"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
