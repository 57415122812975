<script setup lang="ts">
//Components
import OverView from 'ui/views/over-view/over-view.vue';
import JsonSchemasOverviewTable from '../components/json-schemas-overview-table.vue';
import JsonSchemasOverviewDialog from '../components/json-schemas-overview-dialog.vue';

//Keys
import { tsKey } from '@/general/services/translations/translation.key';
import { jsonSchemaServiceKey } from '../ts/keys/json-schemas-service.key';

//Vue
import { ref, provide, Ref } from 'vue';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { JsonSchemasService } from '@/general/services/overview-service/modules/json-schemas-view.service';

const ts = ref(new TranslationService('supplier', 'jsonSchemas'));
const service = ref(new JsonSchemasService(ts.value as TranslationService));

provide(jsonSchemaServiceKey, service as Ref<JsonSchemasService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="supplier" module-name="jsonSchemas" :service="service" :has-search="false">
    <template #content>
      <JsonSchemasOverviewTable />
    </template>
    <template #crudComponent>
      <JsonSchemasOverviewDialog />
    </template>
    <template #others>
      <pConfirmDialog :group="service.confirmPopUpGroup" />
    </template>
  </OverView>
</template>
