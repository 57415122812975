<script setup lang="ts">
import { ref, computed } from 'vue';
import { RouteLocationRaw, useRouter, useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product } from 'platform-unit2-api/products';
import { ChildRoute } from 'platform-unit2-api/core';
import { AdvancedExportSettings, CreateExport, PageIndex } from '../../products.types';
import { StartGenericExportRequest, ExportRestService } from 'platform-unit2-api/exports';

/** Props */
const props = withDefaults(
  defineProps<{
    ids: number[];
    checkedRows: Product[];
    inputData?: AdvancedExportSettings;
    isActive?: boolean;
    childRoutesGeneric?: ChildRoute[];
  }>(),
  {
    childRoutesGeneric: () => [],
    inputData: undefined,
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'toTypeSelection', isTypeSelection: boolean): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const exportApi = new ExportRestService();

/** Constants */
const router = useRouter();
const route = useRoute();
const formObject = ref({} as CreateExport);
const isExporting = ref(false);

const isWideDialog = computed(() => {
  return route.fullPath === '/products/export/products';
});

const nextPage = (event: CreateExport) => {
  switch (event.pageIndex) {
    case PageIndex.GENERIC_VIEW_PAGE:
      formObject.value.selectedLocale = event.selectedLocale;
      formObject.value.includeData = event.includeData;
      formObject.value.includeAssets = event.includeAssets;
      formObject.value.useOverwrittenNames = event.useOverwrittenNames;
      break;
  }

  router.push(props.childRoutesGeneric[(event?.pageIndex ?? PageIndex.GENERIC_VIEW_PAGE) + 1].to);
};

const prevPage = (event: CreateExport) => {
  if (event.pageIndex === PageIndex.GENERIC_VIEW_PAGE) {
    emit('toTypeSelection', true);
    return;
  }

  router.push(
    props.childRoutesGeneric[(event.pageIndex ? event.pageIndex : PageIndex.GENERIC_VIEW_PAGE) - 1]
      .to,
  );
};

const complete = async (event: CreateExport) => {
  isExporting.value = true;
  if (!event.products.length) {
    emit('hide');
    return;
  }

  try {
    const genericExportRequest: StartGenericExportRequest = {
      product_ids: event.products.map((x: Product) => x.id) ?? [],
      locale_id: formObject.value.selectedLocale.id ?? 0,

      use_overwritten_names: formObject.value?.useOverwrittenNames ?? false,
    };

    await exportApi.startGenericExport(genericExportRequest);

    toastService.displaySuccessToast(ts.tModule('export_modal.started_successfully'));
  } catch (err: any) {
    toastService.displayErrorToast(ts.tModule('export_modal.starting_failed'));
    await router.push({ name: 'products' } as RouteLocationRaw);
  } finally {
    isExporting.value = false;
    emit('hide');
  }
};
</script>

<template>
  <p-dialog
    :visible="isActive"
    header=" "
    :style="{
      width: isWideDialog ? '45vw' : '30vw',
    }"
    :modal="true"
    position="center"
    closable
    :close-on-escape="false"
    :draggable="true"
    content-style="overflow-y: visible; padding:0;"
    @update:visible="emit('hide')"
  >
    <p-steps v-show="false" :model="childRoutesGeneric" :readonly="true" class="3 mb" />
    <router-view
      v-slot="{ Component }"
      :key="$route.fullPath"
      :form-data="formObject"
      :checked-rows="props.checkedRows"
      @prev-page="prevPage($event)"
      @next-page="nextPage($event)"
      @complete="complete($event)"
      @cancel-export="emit('hide')"
    >
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </p-dialog>
</template>
