import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import {
  AttributeField,
  ProductAttributeField,
  ProductAttributeFieldError,
  FieldErrorEnum,
} from 'platform-unit2-api/attribute-fields';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class KoagFieldService extends AttributeFieldService<string | undefined> {
  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }

  public validate(): boolean {
    // Call the base class validate method
    const baseValidationResult = super.validate();

    // Additional check for maxLength
    if (
      this.productAttributeField.value != '' &&
      this.productAttributeField.value != null &&
      !new RegExp('^[0-9]{1,4}-[0-9]{1,4}-[0-9]{1,4}$').test(this.productAttributeField.value)
    ) {
      this.productAttributeField.errors.push({
        message: this.ts.t('validation.koag'),
        severity: FieldErrorEnum.ERROR,
      } as ProductAttributeFieldError);
      return false;
    }

    return baseValidationResult;
  }
}
