<script setup lang="ts">
import { Locale } from 'platform-unit2-api/locales';
import { TabFieldService } from '@/general/services/attribute-fields/field-services/tab-field.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { TabViewChangeEvent } from 'primevue/tabview';
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { uniqueId } from 'lodash';
import EmptyState from '../../empty-state.vue';

interface Props {
  locale: Locale;
  tabFieldService: TabFieldService; // localeId: AdvancedFieldTab[]
  tabUUID: string;
}

const props = defineProps<Props>();
const ts = new TranslationService('general');

const store = useStore();

const isInMissingFieldMode = computed(() => {
  return store.getters['products/showMissingFields'];
});

const droppableItemIndex = ref();
const droppedLocaleId = ref();
const tabView = ref();
const time = ref(0);
const authorize = ref(false);
const fromDragging = ref({
  fromIndex: 0,
  toIndex: 0,
});

//Used to make sure the tab isnt able to
//go to the last tab
const activeIndex = ref(0);

/** switching tabs by dragg and drop */
const handleDragStart = (e: DragEvent, itemIndex: number) => {
  (e.target as HTMLElement).style.opacity = '0.5';
  e.dataTransfer!.dropEffect = 'move';
  e.dataTransfer!.effectAllowed = 'move';
  document.body.style.cursor = 'grabbing';
  fromDragging.value.fromIndex = itemIndex;
  authorize.value = true;
};

const handleDragEnd = (e: DragEvent) => {
  document.body.style.cursor = 'default';
  (e.target as HTMLElement).style.opacity = '1';
  authorize.value = false;
};

const handleDragEnter = (e: DragEvent, itemIndex: number) => {
  fromDragging.value.toIndex = itemIndex;
  time.value = new Date().getTime();
  e.preventDefault();
  (e.currentTarget as HTMLElement).classList.add('tab-over');
};

const handleDragLeave = (e: DragEvent) => {
  if (new Date().getTime() - time.value > 3) {
    (e.currentTarget as HTMLElement).classList.remove('tab-over');
  }
};

const handleDrop = (e: DragEvent, itemIndex: number, localeId: number) => {
  if (itemIndex != null && localeId != null) {
    droppableItemIndex.value = itemIndex;
    droppedLocaleId.value = localeId;
    return;
  }

  if ((e.currentTarget as HTMLElement)?.dataset?.droppableId != null && authorize.value === true) {
    const toIndex = droppableItemIndex.value;
    const fromIndex = fromDragging.value.fromIndex;

    props.tabFieldService.changeTheIndexInTabMap(droppedLocaleId.value, fromIndex, toIndex);
  }
};

function canSwap(event: TabViewChangeEvent, localeId: number) {
  if (
    event.index === props.tabFieldService.panels.get(localeId)?.filter((tab) => !tab.deleted).length
  ) {
    (tabView.value as any).changeActiveIndex(
      event,
      {
        props: {
          disabled: false,
        },
      },
      activeIndex.value,
    );
  }
}

function setActiveIndex(index: number, localeId: number) {
  if (index == null) {
    index = 0;
  }

  if (index !== props.tabFieldService.panels.get(localeId)?.filter((tab) => !tab.deleted).length) {
    activeIndex.value = index;
  }
}

function deleteTabConfirmCallback(): void {
  activeIndex.value = 0;
  (tabView.value as any).changeActiveIndex(
    {},
    {
      props: {
        disabled: false,
      },
    },
    activeIndex.value,
  );
}

onMounted(() => {
  (tabView.value as any).onTabEnterKey = () => {};
});
</script>

<template>
  <pTabView
    ref="tabView"
    :data-droppable-id="tabUUID"
    @update:active-index="setActiveIndex($event, locale?.id)"
    @tab-change="(e: TabViewChangeEvent) => canSwap(e, locale?.id)"
    @drop="handleDrop"
    @dragover.prevent
    @dragenter.prevent
  >
    <pTabPanel
      v-for="(tab, index) in tabFieldService.panels.get(locale?.id)?.filter((tab) => !tab.deleted)"
      :key="uniqueId(tab.path ?? '')"
      :pt="{
        header: {
          style: 'min-width: max-content',
        },
      }"
    >
      <template #header>
        <div
          :draggable="!isInMissingFieldMode"
          class="align-items-center draggable-tab flex h-3rem"
          @dragstart="(e) => handleDragStart(e, index)"
          @dragend="handleDragEnd"
          @dragenter="(e) => handleDragEnter(e, index)"
          @dragleave="handleDragLeave"
          @drop="(e) => handleDrop(e, index, locale?.id)"
        >
          <span class="mdi mdi-drag-vertical text-400" :class="{ handle: !isInMissingFieldMode }" />
          <slot
            name="tabHeader"
            :tab="tab"
            :index="index"
            :active-index="activeIndex"
            :tab-view-ref="tabView"
            :delete-tab-confirm-callback="deleteTabConfirmCallback"
          />
        </div>
      </template>
      <template #default>
        <slot :tab="tab" />
      </template>
    </pTabPanel>

    <pTabPanel
      :pt="{
        headerAction: () => ({
          style:
            (tabFieldService.panels.get(locale?.id) ?? []).filter((tab) => !tab.deleted).length ===
            0
              ? 'border-color: #dee2e6'
              : '',
          class:
            (tabFieldService.panels.get(locale?.id) ?? []).filter((tab) => !tab.deleted).length > 0
              ? 'border-none'
              : '',
        }),
      }"
    >
      <template #header>
        <pButton
          icon="mdi mdi-plus"
          outlined
          :disabled="tabFieldService.isCreatingTab"
          :loading="tabFieldService.isCreatingTab"
          @click="async () => await tabFieldService.addTab(locale?.id)"
        />
      </template>
      <div
        v-if="tabFieldService.panels.get(locale?.id)?.filter((tab) => !tab.deleted)?.length === 0"
      >
        <EmptyState
          :button-visible="false"
          :icon-name="'illustration-nutrients'"
          :empty-state-subtitle="ts.t('info.noTabsFound')"
          :empty-state-title="ts.t('info.noTabsTitle')"
        />
      </div>
    </pTabPanel>
  </pTabView>
</template>

<style lang="scss" scoped>
.tab-over {
  border-radius: 5px;
  background-color: #0000000d;
}
</style>
