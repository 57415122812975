import navigationGuard from '@/general/guards/navigation.guard';
import { RouterService } from './../router/router.service';

export async function installRouter() {
  const routerService = RouterService.getInstance();

  await routerService.resolveRoutes();

  //Add navigation guard
  routerService.router.beforeEach(navigationGuard);
}
