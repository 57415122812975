import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Developer | Developers',
  description:
    "Here you can manage everything related to developers. Please visit our developer's guide for more information.",
  guide: 'Developer guide',
  api_key: 'API key',
  api_log: 'API log',
  no_access_title: 'API key feature is disabled',
  no_access_description:
    'Please contact sales to upgrade your plan. When your plan is upgraded, you can generate your API key here.',
  no_key_title: 'There is no API key yet',
  no_key_description:
    'Start by generating your API key. When the API key is generated, it will be shown here.',
  generate_btn: 'Generate API key',
  regenerate_btn: 'Generate',
  regenerate_confirmation:
    'By generating a new API key, you will lose the connection with your current key. This action cannot be undone.',
  contact_sales: 'Contact sales',
  get_key_failed: 'Failed to get API key',
  generate_key_failed: 'Failed to generate API key',
  generate_key_success: 'API key generated successfully',
  refresh_key_success: 'API key refreshed successfully',
  refresh_key_failed: 'Failed to refresh API key',
  delete_key_success: 'API key deleted successfully',
  delete_key_failed: 'Failed to delete API key',
  overview_description:
    'With this key you can authenticate API requests throughout the platform. Please remember that the generated API key is unique to this workspace, which is why you should keep it private.',
  workspace_id: 'Workspace ID',
  created_on: 'Created on',
  expires_on: 'Expiration date',
  expiration_guide:
    'This key will automatically renew on the expiration date. We will notify you a month in advance',
  delete_key: 'Delete key',
  delete_api_key: 'Delete API key',
  regenerate_api_key: 'Generate new key',
  copy_to_clipboard: 'Copy',
  delete_confirmation:
    'You are about to delete the API key. The API connection will be broken and the settings will be lost. This action cannot be undone.',
  copy_to_clipboard_failed: 'Failed to copy to clipboard',
  log_coming_soon: 'API log feature coming soon.',
  log_description:
    'We are working on creating an API log. When the API log becomes available you can find it here.',
};

const tranlation: Translation = {
  developers: translationObject,
};

export default tranlation;
