import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Mapping | Mappings',
  result: 'result',
};

const tranlation: Translation = {
  mappings: translationObject,
};

export default tranlation;
