import { CoreRestService } from 'platform-unit2-api/core';
import { CreateUploadRequest, Upload, UploadsRestService } from 'platform-unit2-api/uploads';
import axios, { Method } from 'axios';
import { ActionTree } from 'vuex';

const uploadApi = new UploadsRestService();
const coreApi = new CoreRestService();

export const actions: ActionTree<{}, {}> = {
  async UPLOAD({}, file): Promise<Upload> {
    const sign = await coreApi.signFile(file);

    await axios.request({
      url: sign.url,
      method: sign.method as Method,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent: any) => {
        Math.round((progressEvent.loaded * 100) / progressEvent.event.total);
      },
    });

    const createUpload: CreateUploadRequest = {
      filename: file.name,
      contenttype: file.type,
      reference: sign.reference,
      resolution_in_pixels_height: 0,
      resolution_in_pixels_width: 0,
    };

    return await uploadApi.post(createUpload);
  },
};
