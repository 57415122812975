import { ApplicationTaskService } from '../application.task.service';
import { DebugLogger } from '@/core/main/debug.logger';
import { KeycloakService } from '@/core/security/keycloak.service';

export class InitDebugTask extends ApplicationTaskService {
  public handle(): void {
    DebugLogger.init();
    KeycloakService.getInstance().resolveDebug();

    DebugLogger.info('Application version: ', import.meta.env.VITE_APP_VERSION);

    this._complete();
  }

  constructor() {
    super('InitDebugTask');
  }
}
