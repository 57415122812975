import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Asset Type',
  subTitle: 'Here you can create a new asset type.',
};

const update: TranslationObjectAction = {
  title: 'Edit Asset Type',
  subTitle: 'Here you can edit an asset type.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'delete asset type',
  failedIsUsed: 'We were unable to delete the asset type, because it is currently being used.',
};

const translationObject: TranslationObject = {
  title: 'Asset Type | Asset Types',
  placeholder: 'Asset type',
  create: create,
  update: update,
  delete: deleteTranslation,

  emptyStateTitle: 'No Asset types found',
  emptyStateSubTitle:
    'Start by creating your asset types. Once you have created a asset type it will be shown here.',
  createButton: 'Create asset type',
};

const tranlation: Translation = {
  assetTypes: translationObject,
};

export default tranlation;
