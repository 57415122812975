import { cloneDeep, isEqual } from 'lodash';
import { ConfirmService } from '../confirm/confirm.service';

export class DirtyStateService<T> {
  public initialData: T;
  private confirmService = new ConfirmService();
  public dataToCompare?: T;

  constructor(dataToTrack?: T) {
    this.initialData = cloneDeep(dataToTrack) as T;
    this.dataToCompare = dataToTrack;
  }

  isTouched(): boolean {
    if (
      Array.isArray(this.initialData) &&
      this.dataToCompare != null &&
      Array.isArray(this.dataToCompare)
    ) {
      return (
        this.initialData.length !== this.dataToCompare.length ||
        this.initialData.some((item, index) => !isEqual(item, (this.dataToCompare as T[])[index]))
      );
    }

    return !isEqual(this.initialData, this.dataToCompare);
  }

  changeInitialData(newData: T) {
    this.initialData = cloneDeep(newData);
  }

  changeDataToCompare(newData: T) {
    this.dataToCompare = newData;
  }

  resetData(data: T) {
    this.initialData = cloneDeep(data);
    this.dataToCompare = data;
  }

  showDirtyDialog(
    accept?: Function,
    reject?: Function,
    onClean?: Function,
    groupName?: string,
    optionalCondition?: boolean,
  ) {
    if (this.isTouched() || optionalCondition) {
      this.confirmService.dirtyState({
        group: groupName ?? 'dirty-dialog',
        accept: (() => async () => {
          accept?.();
        })(),
        reject: (() => async () => {
          reject?.();
        })(),
      });

      return;
    }

    onClean?.();
  }
}
