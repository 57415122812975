<script lang="ts" setup>
import { ref } from 'vue';

interface Props {
  text: string;
  lineCount: number;
  charCount: number;
}

defineProps<Props>();

const readMore = ref(false);

function toggleReadMore() {
  readMore.value = !readMore.value;
}
</script>

<template>
  <div :class="readMore ? '' : `ellipsis-line-${lineCount}`">
    {{ readMore ? text : text?.slice(0, charCount - 10)
    }}{{ text?.length > charCount ? '... ' : '' }}
    <a
      v-if="text?.length > charCount && !readMore"
      href="javasript:void(0)"
      @click="toggleReadMore()"
      >Read more</a
    >
    <a
      v-if="text?.length > charCount && readMore"
      href="javasript:void(0)"
      @click="toggleReadMore()"
      >Read less</a
    >
  </div>
</template>
