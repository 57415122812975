<script setup lang="ts">
//vue
import { computed, ref } from 'vue';

//components
import SvgIcon from '@/general/ui/components/svg-icon.vue';

//services
import { TranslationService } from '@/general/services/translations/translation.service';

/* Define Props */
interface Props {
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateTitleVisible?: boolean;
  iconName: string;
  dragAndDrop?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  emptyStateTitleVisible: true,
  emptyStateSubtitle: '',
  emptyStateTitle: '',
});

/** define emits */
const emit = defineEmits<{
  (e: 'on-drop'): void;
}>();

//services / composables
const ts = new TranslationService('supplier', 'storyManager');

//const / refs
const isBeingDraggedOver = ref(false);

const iconPath = computed(() => {
  return `/src/assets/empty-state/${props.iconName}.svg`;
});

const onDragEnter = (e: DragEvent) => {
  e.preventDefault();
  isBeingDraggedOver.value = true;
};

const onDragLeave = (e: DragEvent) => {
  e.preventDefault();
  isBeingDraggedOver.value = false;
};

const onDrop = () => {
  isBeingDraggedOver.value = false;
  emit('on-drop');
};
</script>

<template>
  <div
    v-if="!dragAndDrop"
    data-testid="empty-dnd-state"
    class="align-items-center empty-state flex flex-column justify-content-center p-3 w-full"
    @dragover="onDragEnter"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @drop="onDrop"
    @dragstart="(e) => e.preventDefault()"
  >
    <div class="mb-6">
      <SvgIcon :iconpath="iconPath" custom-style="width: 6rem" />
    </div>
    <div class="mb-2">
      <h2
        class="empty-state-title"
        :style="{ display: emptyStateTitleVisible ? 'block' : 'none' }"
        data-testid="empty-state-title"
      >
        {{ emptyStateTitle }}
      </h2>
    </div>
    <div class="flex justify-content-center mb-2 mb-5">
      <p class="empty-state-subtitle text-l w-7" data-testid="empty-state-subtitle">
        {{ emptyStateSubtitle }}
      </p>
    </div>
  </div>
  <div
    v-else
    data-testid="empty-dnd-state"
    :class="`drag-empty-state flex-column align-items-center justify-content-center mt-3 mb-3 flex w-full p-3 ${
      isBeingDraggedOver ? ' drag-empty-state-hover' : ''
    }`"
    @dragover="onDragEnter"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @drop="onDrop"
    @dragstart="(e) => e.preventDefault()"
  >
    <div class="flex justify-content-center mb-2 mb-5">
      <p class="empty-state-subtitle text-l">
        {{ ts.tModule('storyBuilder.emptyState.dropIt') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty-state {
  height: 50%;
  border: 1px dashed #cad9e7;
  margin: 10px 0 10px 0;
  border-radius: 6px;
}

.drag-empty-state {
  height: 50%;
  color: #2a71e5;
  border: 1px dashed #377ef2;
  border-radius: 6px;
}

.drag-empty-state-hover {
  color: #2a71e5;
  background-color: #f0f8ff;
}

.empty-state-title {
  text-align: center;
}

.empty-state-subtitle {
  text-align: center;
  color: #8f9099;
}
</style>
