import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import { TranslationGlobal } from '@/core/i18n/ts/interfaces/translation-global.interface';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import CacheService from '@/general/services/cache.service';
import { isEqual, uniqWith } from 'lodash';
import { Defaults } from '@/general/utils/constants/defaults';
import { DebugLogger } from '@/core/main/debug.logger';

export class BaseSelectService<T> extends RefetchService {
  private _restService: any;
  private _fetchFunctionName: string;
  private _toastService: ToastService;
  private _selectIdentifier: string;
  customFetchParams?: any[] = [];

  public options: T[] = [];
  public ts: TranslationService;
  public loading = false;
  public selectedItem?: T | T[];
  public allChecked = false;
  public lastRawFetchedItems: T[] = [];

  constructor(
    restService: any,
    fetchFunctionName: string,
    selectIdentifier: string,
    fetchParams?: PaginationObject,
    defaultSortBy?: string,
  ) {
    super();
    this._restService = restService;
    this._fetchFunctionName = fetchFunctionName;
    this.ts = new TranslationService('general', 'components');

    this._toastService = ToastService.getInstance();
    if (fetchParams != null) {
      this._paginationParams = fetchParams;
    }

    this._paginationParams.limit = fetchParams?.limit ?? Defaults.REQUEST_LIMIT;
    this._paginationParams.query = fetchParams?.query ?? '';
    this._paginationParams.sortBy = fetchParams?.sortBy ?? defaultSortBy ?? '';
    this.refetch = () => {};

    this._selectIdentifier = selectIdentifier;
  }

  public get fetchVariables(): PaginationObject {
    return this._paginationParams;
  }

  public set query(value: string | undefined) {
    this._paginationParams.query = value;
    this._paginationParams.page = 1;
  }

  public getPlaceholder(
    multiselect?: boolean,
    moduleName?: keyof TranslationGlobal,
  ): string | undefined {
    if (moduleName == null) {
      return;
    }

    return this.ts.tModule('select.placeholder', {
      params: {
        module: this.ts
          .tGlobal(moduleName, { params: { count: multiselect === true ? 2 : 1 } })
          .toLowerCase(),
      },
    });
  }

  public getLabel(multiselect?: boolean, moduleName?: keyof TranslationGlobal): string | undefined {
    if (moduleName == null) {
      return;
    }

    return this.ts.tGlobal(moduleName, { params: { count: multiselect === true ? 2 : 1 } });
  }

  filterOptions(filterCond?: (options: T[]) => T[]) {
    if (filterCond != null) {
      this.options = filterCond(this.options);
    }
  }

  public async fetchOptions(selectedOptions?: T[]): Promise<void> {
    try {
      this.loading = true;
      let response: ListResponse<T>;

      if (!CacheService.hasItemsForRequest(this._selectIdentifier, this.fetchVariables)) {
        response = await this._restService[this._fetchFunctionName](
          this.fetchVariables,
          ...(this.customFetchParams ?? []),
        );

        this.lastRawFetchedItems = response.data ?? [];
        CacheService.cacheResponse(
          response.data,
          response.meta?.total ?? 0,
          this._selectIdentifier,
          this.fetchVariables,
        );
      }

      // Caches response in the base service vue
      const newResponse = CacheService.getItemsForRequest(
        this._selectIdentifier,
        this.fetchVariables,
      );

      this.lastRawFetchedItems = newResponse.items as T[];
      const finalOptions = uniqWith(
        (newResponse.items ?? []).concat(selectedOptions ?? []),
        isEqual,
      );

      this.total = newResponse.total;

      this.options = uniqWith((finalOptions as T[]).concat(selectedOptions ?? []), isEqual);
    } catch (e) {
      DebugLogger.error(e);
      this._toastService.displayErrorToast(this.ts.tModule('select.failedToLoad'));
    } finally {
      this.loading = false;
    }
  }

  public queryIsEmpty(): boolean {
    return this._paginationParams.query === '';
  }

  public async search(
    query = '',
    filterCond?: (options: T[]) => T[],
    selectedOptions?: T[],
  ): Promise<void> {
    this.query = query;
    this._debounceService.debounce(async () => {
      await this.fetchOptions(selectedOptions);
      this.filterOptions(filterCond);
    });
  }

  public resetQuery() {
    this._paginationParams.query = '';
  }

  public async handleCheckAll(checked: boolean) {
    this.allChecked = checked;
    if (checked) {
      if (this.options.length < this.total) {
        this.limit = this.total;
        this.loading = true;
        await this.fetchOptions();
        this.loading = false;
      }
    }
  }
}
