<script setup lang="ts">
import { onMounted, ref } from 'vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Locale } from 'platform-unit2-api/locales';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { DeleteButton } from '@/general/ui/components/crud-buttons/ts/classes/delete-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  ids: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const store = useStore();
const selectedLocale = ref<Locale>();

onMounted(() => {
  if (store.getters['users/currentUser'] && store.getters['users/currentUser'].locale) {
    selectedLocale.value = store.getters['users/currentUser'].locale;
  }
});

const deleteLocaleContent = async () => {
  try {
    buttonsOptions.value.saving = true;
    await store.dispatch('products/BULK_ACTION_DELETE_LOCALE_CONTENT', {
      productIds: props.ids,
      localeId: selectedLocale.value?.id,
    });
    toastService.displaySuccessToast(ts.tModule('delete_locale_content.deleted_successfully'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('delete_locale_content.delete_failed'));
  } finally {
    buttonsOptions.value.saving = false;
    emit('hide');
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const deleteButton = new DeleteButton({
  label: ts.tGlobal('delete'),
  position: CrudButtonPosition.RIGHT,
  onClick: deleteLocaleContent,
  isEnabled: () => selectedLocale.value != null,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, deleteButton],
});
</script>
<template>
  <BaseDialog
    :title="ts.tModule('delete_locale_content.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="emit('hide')"
  >
    <div class="field mb-3">
      <label class="font-bold">{{ ts.tGlobal('select_language') }}</label>
      <LocaleSelect v-model="selectedLocale" hide-label :show-clear="false" />
    </div>
  </BaseDialog>
</template>
