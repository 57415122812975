import { RouteFactory } from '@/core/router/route.factory';
import { RouterService } from '@/core/router/router.service';
import { FeaturePermission } from 'platform-unit2-api/roles';
import Shared from 'supplier/modules/shared/views/shared.view.vue';
import { RouteRecordRaw } from 'vue-router';

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'shared',
  navigationGroup: 'shared',
  tabs: [
    {
      label: 'Shared by me',
      key: 'shared-by-me',
      command: () => {
        RouterService.getInstance().router.push({ name: 'shared-by-me' });
      },
    },
    {
      label: 'Shared with me',
      key: 'shared-with-me',
      command: () => {
        RouterService.getInstance().router.push({ name: 'shared-with-me' });
      },
    },
  ],
});

const sharedRoutes: RouteRecordRaw[] = [
  {
    path: '/shared',
    name: 'shared',
    redirect: { name: 'shared-by-me' },
  },
  routeFactory.createAppRoute({
    path: '/shared/by-me',
    name: 'shared-by-me',
    component: Shared,
    title: 'Shared by me',
    rights: [FeaturePermission.MANAGE_SHARES],
  }),
  routeFactory.createAppRoute({
    path: '/shared/with-me',
    name: 'shared-with-me',
    component: Shared,
    title: 'Shared with me',
    rights: [FeaturePermission.MANAGE_SHARES],
  }),
];

export default sharedRoutes;
