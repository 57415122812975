import { PaginationObject } from 'platform-unit2-api/core';

class CacheService<T> {
  private static _instance = new CacheService();
  cachedItems: { [key: string]: { items: T[]; total: number } } = {};

  public static get instance() {
    return CacheService._instance;
  }
  getKey(module: string, params?: PaginationObject) {
    return [module, params?.limit, params?.page, params?.query, params?.sortBy].join('_');
  }

  cacheResponse(
    responseItems: T[],
    responseTotal: number,
    module: string,
    params?: PaginationObject,
  ) {
    this.cachedItems[this.getKey(module, params)] = { items: responseItems, total: responseTotal };
  }

  hasItemsForRequest(module: string, params?: PaginationObject): boolean {
    return this.cachedItems[this.getKey(module, params)] != null;
  }

  getItemsForRequest(module: string, params?: PaginationObject): { items: T[]; total: number } {
    return this.cachedItems[this.getKey(module, params)];
  }

  public resetItemsForCacheModuleRecord(module: string) {
    Object.keys(this.cachedItems).forEach((key) => {
      if (key.includes(module)) {
        delete this.cachedItems[key];
      }
    });
  }

  public emptyCache() {
    Object.keys(this.cachedItems).forEach((key) => {
      delete this.cachedItems[key];
    });
  }
}

export default CacheService.instance;
