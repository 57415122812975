<script setup lang="ts">
import { ref, provide, Ref } from 'vue';
import OverViewSideBar from 'ui/components/over-view/overview-sidebar.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';
import UserViewService from '@/general/services/overview-service/modules/user-view.service';
import { serviceKey } from 'retailer/modules/users/ts/keys/service-key';
import OverView from 'ui/views/over-view/over-view.vue';
import UserForm from 'retailer/modules/users/components/user-form.vue';
import UserList from 'retailer/modules/users/components/user-list.vue';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';

const ts = ref(new TranslationService('retailer', 'users'));
const service = ref(new UserViewService(ts.value as TranslationService));

provide(serviceKey, service as Ref<UserViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="retailer" module-name="users" :service="service">
    <template #skeleton>
      <CardSkeleton />
    </template>
    <template #content>
      <UserList />
    </template>
    <template #crudComponent>
      <OverViewSideBar :service="service">
        <template #form>
          <UserForm />
        </template>
      </OverViewSideBar>
    </template>
  </OverView>
</template>
