import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Language | Languages',
  placeholder: 'Select a language | Select multiple languages',

  locale_select: {
    label: 'Language',
    placeholder: 'Select a language | Select multiple languages',
    receiving_failed: 'Receiving locales failed.',
  },
};

const translation: Translation = {
  locales: translationObject,
};

export default translation;
