import { AxiosError } from 'axios';
import HttpStatus from 'http-status-codes';
import { Ref, ref } from 'vue';

export default function (): any {
  const formErrors: Ref<{ [key: string]: string[] } | null> = ref(null);
  const resetFormErrors = () => {
    formErrors.value = null;
  };

  const fieldErrorMessage = (name: string): string[] => {
    return formErrors.value && formErrors.value.hasOwnProperty(name) ? formErrors.value[name] : [];
  };

  const hasError = (field: string): boolean => {
    return !!(formErrors.value && formErrors.value.hasOwnProperty(field));
  };

  const parseFormError = (
    error: AxiosError<any, any>,
    defaultHandler: (error: AxiosError<any, any>) => void,
  ) => {
    if (error.response !== undefined && error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      if (error.response!.data.errors) {
        formErrors.value = error.response!.data.errors;
      }
    } else {
      defaultHandler(error);
    }
  };

  return {
    formErrors,
    resetFormErrors,
    fieldErrorMessage,
    parseFormError,
    hasError,
  };
}
