import { DirtyStateService } from '../dirty-state/dirty-state.service';
import { AttributeFieldNode } from './attribute-field-node';
import { AttributeField } from 'platform-unit2-api/attribute-fields';
import { AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { flattenDeep, startsWith, uniqBy } from 'lodash';
import { ProductAttributeFieldsService } from './product-attribute-fields.service';
import { NavigationGuardNext } from 'vue-router';

export class AttributeFieldLinkedList<T> {
  private _nodes: AttributeFieldNode<any>[] = [];
  private _allAttributeFields: AttributeField<T>[] = [];
  private _dirtyStateService: DirtyStateService<AttributeFieldNode<any>[]> = new DirtyStateService(
    [],
  );

  /**
   * Initialize the attribute field linked list
   * @param attributeFields - List of attribute fields
   */
  public init(attributeFields: AttributeField<T>[]) {
    this._nodes = [];
    this._allAttributeFields = attributeFields;
    this.logData(attributeFields);
    this.loadChildren();
    this._nodes = uniqBy(this._nodes, (node) => node.identifier);
    this._dirtyStateService.changeInitialData(this._nodes);
  }

  /**
   * get the node from the linked list
   * @param node - The node to get from the linked list
   * @returns AttributeFieldNode | undefined
   */
  public getNodeFromList<T>(node: AttributeFieldNode<T>): AttributeFieldNode<T> | undefined {
    return this._nodes.find((n) => n.identifier === node.identifier);
  }

  /**
   * Check if the initial data has already been assigned
   * @example const linkedList = new AttributeFieldLinkedList();
   * linkedList.hasInitialDataAlreadyAssigned(); // false
   * linkedList.init(attributeFields);
   * linkedList.hasInitialDataAlreadyAssigned(); // true
   * @returns boolean
   */
  public hasInitialDataAlreadyAssigned(): boolean {
    return this._dirtyStateService.initialData.length > 0;
  }

  /**
   * Gets the initial data from the dirty state,
   * this is done by comparing the identifier of the node
   * @param node - The node to get from the dirty state
   * @returns AttributeFieldNode | undefined
   */
  public getNodeFromDirtyState<T>(node: AttributeFieldNode<T>): AttributeFieldNode<T> | undefined {
    return this._dirtyStateService.initialData.find((n) => n.identifier === node.identifier);
  }

  /**
   * Update the node with the new value
   * or add the node to the linked list if it does not exist
   * this happens when the node is an advanced field
   * @param node - The node to update
   * @param preset - The preset to update the node with
   * @param deletedPaths - The deleted paths
   */
  public updateNode<T>(
    node: AttributeFieldNode<T>,
    preset?: AttributeField<any>[],
    deletedPaths?: (string | null)[],
  ) {
    const nodeFromList = this.getNodeFromList(node);

    if (nodeFromList != null) {
      nodeFromList.value = node.value;
    }

    if (!node.isNodeAdvancedField()) {
      this._nodes.push(node);
    }

    if (node.isNodeAdvancedField()) {
      this.logData(preset ?? []);
      this.loadChildren();

      if ((deletedPaths?.length ?? 0) > 0) {
        this.handleDeletedPaths(nodeFromList ?? node, deletedPaths);
      }
    }
  }

  public getNodeParent<T>(parentId?: number): AttributeFieldNode<T> | undefined {
    return this._nodes.find((n) => n.attrId === parentId);
  }

  public getNodeChildren<T>(node: AttributeFieldNode<T>): AttributeFieldNode<any>[] {
    return this._nodes.filter((n) => n.parentId === node.attrId);
  }

  public nodeChildrenHaveChanges<T>(node: AttributeFieldNode<T>): boolean {
    return this.getNodeChildren(node).some((child) => child.hasChanges());
  }

  public getFlattenedAttributes(): AttributeField<any>[] {
    return this.flattenAttributes(this._allAttributeFields, []);
  }

  public flattenAttributes(
    attributeFields: AttributeField<any>[],
    array: AttributeField<any>[],
  ): AttributeField<any>[] {
    attributeFields.forEach((af) => {
      af.children?.forEach((child) => {
        return this.flattenAttributes(flattenDeep(child.instances ?? []), array);
      });
      array?.push(af);
    });

    return array;
  }

  public findAttributeFromNode(
    node: AttributeFieldNode<any>,
    attributeFields?: AttributeField<any>[],
  ): AttributeField<any> | undefined {
    const attrField = this.flattenAttributes(attributeFields ?? this._allAttributeFields, []).find(
      (af) =>
        af.attribute.id === node.attrId &&
        af.path === node.path &&
        af.attribute.parent_id === node.parentId,
    );

    return attrField;
  }

  public advancedFieldExists(
    attrId: number,
    localeId: number,
    advancedFieldPath: string | null,
    childrenPath: string | null,
    parentId?: number,
  ): boolean {
    const advancedField = this.getNode(attrId, localeId, advancedFieldPath, parentId);
    return advancedField != null
      ? advancedField.childrenPathExists(childrenPath ?? '')
      : true ?? false;
  }

  public isTouched(): boolean {
    return (
      this._nodes.some((node) => node.hasChanges()) ||
      this._dirtyStateService.initialData.length > this._nodes.length
    );
  }

  public showDialog(service: ProductAttributeFieldsService, next: NavigationGuardNext) {
    if (this.isTouched()) {
      this._dirtyStateService.showDirtyDialog(
        () => {
          service.discardChanges();

          next();
        },
        () => {
          return;
        },
        () => {},
        'dirty-state',
        this.isTouched(),
      );
      return;
    }

    next();
  }

  /**
   * loops through the attribute fields and the attached childeren to make
   * a node out of them, this node is then added to the linked list.
   * @param attributeFields - List of attribute fields
   */
  private logData(attributeFields: AttributeField<T>[]): void {
    attributeFields.forEach((af) => {
      //if it has children, call the function again with the children
      if (af.children != null) {
        this.logData(
          af.children
            .map((c) => c.instances)
            ?.flat()
            .flat() as AttributeField<T>[],
        );
      }

      if (af.attribute.options.type !== AttributeTypeEnum.GROUP_FIELD && af.values != null) {
        af.values?.forEach((v) => {
          const value =
            af.attribute.options.type === AttributeTypeEnum.TAB_FIELD && v.value == null
              ? []
              : v?.value;

          const node = new AttributeFieldNode(
            af.attribute.id,
            v?.locale?.id,
            af.attribute.options.type,
            v?.path,
            value,
          );

          node.parentId = af.attribute.parent_id;
          node.global = v.locale?.value === 'global';
          this._nodes.push(node);
        });
      } else {
        //group field case
        const node = new AttributeFieldNode(
          af.attribute.id,
          af.children?.[0]?.locale?.id ?? NaN,
          af.attribute.options.type,
          af.path,
          null,
        );

        node.global = af.children?.[0]?.locale?.value === 'global';
        node.parentId = af.attribute.parent_id;
        this._nodes.push(node);
      }
    });
  }

  /**
   * Load the children of the nodes, this is done after the nodes are created
   * and the children are added to the nodes.
   * This is done by comparing all the nodes.parentId with the nodes.attrId
   * @returns void
   */
  private loadChildren(): void {
    this._nodes.forEach((node) => {
      node.children = this.getNodeChildren(node);
    });
  }

  /**
   * Get the node from the linked list
   * @param attrId - The attribute id
   * @param localeId - The locale id
   * @param path - The path of the node
   * @param parentId - The parent id of the node, optional
   * @returns  AttributeFieldNode | undefined
   */
  private getNode(
    attrId: number,
    localeId: number,
    path: string | null,
    parentId?: number,
  ): AttributeFieldNode<any> | undefined {
    return this._nodes.find(
      (node) =>
        node.attrId === attrId &&
        node.localeId === localeId &&
        node.path == path &&
        node.parentId == parentId,
    );
  }

  /**
   * Removes the nodes that have been deleted and there for have there path in the deletedPaths array
   * @param node - The node to handle the deleted paths for
   * @param deletedPaths - The deleted paths
   */
  private handleDeletedPaths<T>(node: AttributeFieldNode<T>, deletedPaths?: (string | null)[]) {
    deletedPaths?.forEach((path) => {
      this.deleteNode(node, path!);
    });

    this.loadChildren();
  }

  /**
   * Deletes the children of a node with the given path
   * and removes the node from the linked list
   * @param node  The node to delete the children from
   * @param path  The path that is deleted
   */
  private deleteNode(node: AttributeFieldNode<any>, path: string) {
    //gets all childeren that start with the given deleted path
    //if path 0 is deleted then all children with path 0.1, 0.2, 0.3 etc. are deleted
    let children = this.getNodeChildren(node).filter((c) => startsWith(c.path ?? '', path));

    if (!node.global) {
      children = children.filter((c) => c.localeId === node.localeId);
    }

    children.forEach((child) => {
      if (child.children.length > 0) {
        this.deleteNode(child, path);
      }

      this._nodes = this._nodes.filter((n) => n.identifier !== child.identifier);
    });

    // remove from the children list of the node itself
    node?.removeChildren(path);
  }
}
