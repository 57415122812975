import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create task',
  subTitle: 'Here you can create a new task.',
};

const update: TranslationObjectAction = {
  title: 'Edit task',
  subTitle: 'Here you can edit an task.',
};

const translationObject: TranslationObject = {
  title: 'Task | Tasks',
  placeholder: 'Select task',
  setStatus: 'Set status',
  selectType: 'Select type',
  statusChanged: 'Status has been changed.',
  totalTasks: 'Total Number Of Tasks',
  emptyStateSubtitle:
    'Start by creating a task to streamline the workflow of your team inside the Productsup platform.\nWhen a task is created it will show here.',
  currentWorkspace: 'Current Workspace',
  tableHeaders: {
    assignee: 'Assignee / Workspace',
    taskName: 'Task Name',
    taskType: 'Task Type',
    assignedBy: 'Assigned by',
    status: 'Status',
    dueDate: 'Due Date',
  },
  noGtin: 'No attached GTIN',

  addTask: {
    title: 'Add Task',
    describe: 'Describe',
    assign: 'Assign',
    deadline: 'Deadline',
    settings: '@:global.settings',
    assignDescription: 'Please select who needs to complete the task:',
    workspaceCheckbox:
      'By selecting this option, everyone in the {workspace} workspace will receive notifications about this task.',
    selectTask: 'Please select when the task needs to be completed.',
    describeTask:
      '  Please give the task a name. And select the type of task it is. If needed add a description so the assignee knows what needs to happen.',
    taskType: 'Type of Task',
    selectOption: 'Please select the wanted options.',
    createSuccess: 'Task has been created and assignee has been notified.',
  },

  create: create,
  update: update,
};

const tranlation: Translation = {
  tasks: translationObject,
};

export default tranlation;
