import { DashboardRestService, Metric } from 'platform-unit2-api/dashboard';
import { DashboardState } from 'supplier/modules/dashboard/dashboard.types';
import { ActionTree } from 'vuex';

const dashboardApi = new DashboardRestService();

export const actions: ActionTree<DashboardState, {}> = {
  async GET_DATA({ commit }) {
    try {
      const data = await dashboardApi.getDashboardData();
      commit('setData', data);
    } catch (err) {
      throw err;
    }
  },

  async GET_METRIC_DATA(
    { commit, getters },
    { uriKey, rangeStart, rangeEnd }: { uriKey: string; rangeStart: Date; rangeEnd: Date },
  ): Promise<void> {
    let newMetric: Metric;
    try {
      newMetric = await dashboardApi.getMetricData(uriKey, rangeStart, rangeEnd);
      commit('setCachedAt', new Date(newMetric.data?.updated_at ?? new Date()));
    } catch (err) {
      throw err;
    }

    const metrics = getters.metrics.filter(
      (metric: Metric) => metric.uri_key !== newMetric.uri_key,
    );

    metrics.push(newMetric);

    commit('setMetrics', metrics);
  },
};
