import { ToastService } from '@/general/services/toasts/toast.service';
import { ProductService } from '@/general/services/products/product.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ProductsSaveLinkedChildren } from '../ts/interface/products-save-linked-children.interface';

/**
 * @description The service that holds the logic for the product view & components.
 */
export class ProductVueService {
  public isLoading = false;
  private _productService: ProductService;

  // #region vue and prime related
  private ts: TranslationService;
  // #endregion

  constructor(private emitHide?: () => void, private _toastService = ToastService.getInstance()) {
    this._productService = new ProductService();

    const ts = new TranslationService('retailer', 'products');
    this.ts = ts;
  }

  /**
   * Attaches the linked products to the original product.
   * @param productId Id of the product to which linked products are attached
   * @param linkedProducts ProductLinkedChild[]
   */
  public async saveLinkedProducts({ productId, linkedProducts }: ProductsSaveLinkedChildren) {
    this.isLoading = true;
    try {
      await this._productService.saveLinkedProducts(productId, linkedProducts);
      this._toastService.displaySuccessToast(this.ts.tModule('link_products.success'));
    } catch (ex) {
      this._toastService.displayErrorToast(this.ts.updateFailed());
    } finally {
      this.isLoading = false;
      this.emitHide?.();
    }
  }
}
