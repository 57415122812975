<script setup lang="ts">
import { MenuItem } from 'primevue/menuitem';
import { TabMenuChangeEvent } from 'primevue/tabmenu';
import { watch, ref, onUpdated, onMounted } from 'vue';
import { useRoute } from 'vue-router';

/** Props */
export interface Props {
  tabs?: MenuItem[];
}

const route = useRoute();
const props = defineProps<Props>();
const activeIndex = ref(0);

const onTabChange = (event: TabMenuChangeEvent) => {
  activeIndex.value = event.index;
};

const updateTab = () => {
  if (props.tabs && props.tabs.length > 0) {
    activeIndex.value = props.tabs.findIndex((tab) => tab.key === route.name);
  }
};

watch(
  () => route,
  () => {
    updateTab();
  },
  { deep: true },
);

onMounted(updateTab);
onUpdated(updateTab);
</script>
<template>
  <div v-if="tabs && tabs.length > 0">
    <pTabMenu
      :pt="{
        root: {
          style: 'margin-bottom: -2px;',
        },
      }"
      :model="tabs"
      :exact="false"
      :active-index="activeIndex"
      data-testid="header-tab-menu"
      @tab-change="onTabChange"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep(.p-menuitem-link) {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 1.5rem !important;
  padding-top: 0px !important;
}

:deep(.p-menuitem-link:focus) {
  box-shadow: none !important;
}
</style>
