<script setup lang="ts">
import { Datamodel } from 'platform-unit2-api/datamodels';
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

/** Props */
export interface Props {
  selectedDatamodel: Datamodel;
}
defineProps<Props>();

const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <span class="align-items-center flex">
    <p class="mr-1">{{ selectedDatamodel.name }}</p>
    <span class="font-normal text-gray-500 text-sm"
      >({{ service.selectedAttribuesCountByDatamodel(selectedDatamodel) }}/{{
        selectedDatamodel.attributes.length
      }})</span
    >
  </span>
</template>
