export default function (): any {
  const getFallbackIcon = (filename: string): string => {
    const array = filename.split('.');
    const type = array[array.length - 1];
    const iconFolderPath = '/src/assets/icons/';
    let iconName = '';

    switch (type.toLowerCase()) {
      case 'pdf':
        iconName = 'pdf';
        break;
      case 'zip':
      case 'tar':
      case 'rar':
        iconName = 'zip';
        break;
      case 'txt':
      case 'rtf':
        iconName = 'txt';
        break;
      case 'mp3':
      case 'flac':
        iconName = 'sound';
        break;
      case 'mp4':
      case 'webm':
      case 'avi':
      case 'gif':
      case 'mov':
      case 'mpg':
      case 'ogg':
        iconName = 'video';
        break;
      case 'docx':
      case 'doc':
      case 'docm':
      case 'odt':
        iconName = 'docx';
        break;
      case 'pptx':
      case 'pptm':
      case 'ppt':
        iconName = 'pptx';
        break;
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
      case 'csv':
        iconName = 'xlsx';
        break;
      case 'eot':
      case 'otf':
      case 'ttf':
      case 'woff':
        iconName = 'font';
        break;
      case 'html':
      case 'js':
      case 'css':
      case 'xml':
      case 'php':
        iconName = 'code';
        break;
      case 'psd':
        iconName = 'psd';
        break;
      case 'json':
        iconName = 'json';
        break;
      default:
        iconName = 'image';
    }

    return iconFolderPath + iconName + '-format.svg';
  };

  return {
    getFallbackIcon,
  };
}
