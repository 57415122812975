import { UploadedFile } from 'platform-unit2-api/core';
import { Upload } from 'platform-unit2-api/uploads';
import { TranslationService } from '@/general/services/translations/translation.service';

export class EmptyStateUploadOrMediaPickerService {
  private _ts: TranslationService;
  private _isAbleToPickMultipleFiles: boolean;

  constructor(
    isAbleToPickMultipleFiles: boolean,
    private _emitChosenMultipleFiles?: (event: Upload[] | Upload) => void,
    private _emitChosenFile?: (event?: Upload) => void,
    _ts?: TranslationService,
  ) {
    this._isAbleToPickMultipleFiles = isAbleToPickMultipleFiles;
    this._ts = _ts ? _ts : new TranslationService('general', 'components');
  }

  public get ts(): TranslationService {
    return this._ts;
  }

  public emitSelectedImages = (event: Upload[]) => {
    this._isAbleToPickMultipleFiles
      ? this._emitChosenMultipleFiles?.(event)
      : this._emitChosenFile?.(event[0]);
  };

  public handleUploadAssets = async (event: UploadedFile): Promise<void> => {
    //Due to limitation of the uploadbox the current function wil
    //be called multiple times when the if the input upload multiple files
    this._isAbleToPickMultipleFiles
      ? this._emitChosenMultipleFiles?.(event.upload ?? [])
      : this._emitChosenFile?.(event.upload);
  };
}
