<script setup lang="ts">
import { provide } from 'vue';
import { GenerateImageService } from '../ts/generate-image.service';
import { generateImageServiceKey } from '../ts/keys/supplier-ai.keys';
import GenerateImageForm from '../components/generate-image/generate-image-form.vue';
import GenerateImageGrid from '../components/generate-image/generate-image-grid.vue';
import { state } from '@/general/utils/state';

const service = state(new GenerateImageService());

provide(generateImageServiceKey, service);
</script>

<template>
  <div class="flex flex-column h-full p-5 w-full">
    <GenerateImageForm />
    <GenerateImageGrid />
  </div>
</template>
