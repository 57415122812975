import { AttributeField } from 'platform-unit2-api/attribute-fields';
import { AttributeTypeEnum, AttributeCompact } from 'platform-unit2-api/attributes';
import { Component } from 'vue';

import BaseFieldComponent from 'ui/components/attribute-fields/base-field.vue';
import TextAreaFieldComponent from 'ui/components/attribute-fields/text-area-field.vue';
import BrandFieldComponent from 'ui/components/attribute-fields/brand-field.vue';
import CategoryFieldComponent from 'ui/components/attribute-fields/category-field.vue';
import NumberFieldComponent from 'ui/components/attribute-fields/number-field.vue';
import RichTextFieldComponent from 'ui/components/attribute-fields/rich-text-field.vue';
import SwitchFieldComponent from 'ui/components/attribute-fields/switch-field.vue';
import TagFieldComponent from 'ui/components/attribute-fields/tag-field.vue';
import KoagFieldComponent from 'ui/components/attribute-fields/koag-field.vue';
import KeyValueFieldComponent from 'ui/components/attribute-fields/key-value-field.vue';
import DateTimeFieldComponent from 'ui/components/attribute-fields/date-time-field.vue';
import financialFieldComponent from 'ui/components/attribute-fields/financial-field.vue';
import GtinFieldComponent from 'ui/components/attribute-fields/gtin-field.vue';
import InputSelectFieldComponent from 'ui/components/attribute-fields/input-select-field.vue';
import MultipleChoiceFieldComponent from 'ui/components/attribute-fields/multiple-choice-field.vue';
import ChoiceFieldComponent from 'ui/components/attribute-fields/choice-field.vue';
import GroupFieldComponent from 'ui/components/attribute-fields/group-field/group-field.vue';
import TabFieldComponent from 'ui/components/attribute-fields/tabs-field/tabs-field.vue';

export class AttributeFieldsRendererService {
  public getComponent(attributeField: AttributeField<any>): Component {
    if (!attributeField.attribute.options.type) {
      return BaseFieldComponent;
    }

    switch (attributeField.attribute.options.type) {
      case AttributeTypeEnum.KOAG_FIELD:
        return KoagFieldComponent;
      case AttributeTypeEnum.BRAND_FIELD:
        return BrandFieldComponent;
      case AttributeTypeEnum.CATEGORY_FIELD:
        return CategoryFieldComponent;
      case AttributeTypeEnum.TEXT_AREA:
        return TextAreaFieldComponent;
      case AttributeTypeEnum.RICHT_TEXT_FIELD:
        return RichTextFieldComponent;
      case AttributeTypeEnum.SWITCH_FIELD:
        return SwitchFieldComponent;
      case AttributeTypeEnum.NUMBER_FIELD:
        return NumberFieldComponent;
      case AttributeTypeEnum.TAG_FIELD:
        return TagFieldComponent;
      case AttributeTypeEnum.KEY_VALUE_FIELD:
        return KeyValueFieldComponent;
      case AttributeTypeEnum.DATE_TIME_FIELD:
        return DateTimeFieldComponent;
      case AttributeTypeEnum.FINANCIAL_FIELD:
        return financialFieldComponent;
      case AttributeTypeEnum.GTIN_FIELD:
        return GtinFieldComponent;
      case AttributeTypeEnum.INPUT_SELECT_FIELD:
        return InputSelectFieldComponent;
      case AttributeTypeEnum.MULTIPLE_CHOICE_FIELD:
        return MultipleChoiceFieldComponent;
      case AttributeTypeEnum.CHOICE_FIELD:
        return ChoiceFieldComponent;
      case AttributeTypeEnum.TAB_FIELD:
        return TabFieldComponent;
      case AttributeTypeEnum.GROUP_FIELD:
        return GroupFieldComponent;
      case AttributeTypeEnum.DEFAULT:
        return BaseFieldComponent;
      default:
        return BaseFieldComponent;
    }
  }

  public helpText(attribute: AttributeCompact) {
    return attribute.options?.helpText;
  }

  public articleLink(attribute: AttributeCompact) {
    return attribute.options?.articleLink;
  }
  public hasOverwrittenKey(attribute: AttributeCompact) {
    return !!attribute.original_key && attribute.original_key !== attribute.key && !!attribute.key;
  }
}
