<script setup lang="ts">
import { provide, reactive } from 'vue';
import { EditImageService } from '../ts/edit-image.service';
import { editImageServiceKey } from '../ts/keys/supplier-ai.keys';
import EditImageForm from 'supplier/modules/ai-enhancements/components/edit-image/edit-image-form.vue';
import EditImageGrid from 'supplier/modules/ai-enhancements/components/edit-image/edit-image-grid.vue';

const service = reactive(new EditImageService()) as EditImageService;

provide(editImageServiceKey, service);
</script>

<template>
  <div class="flex flex-column h-full p-5 w-full">
    <EditImageForm />
    <EditImageGrid />
  </div>
</template>
