import { TranslationActions } from '@/core/i18n/ts/interfaces/translation-actions.interface';
import { TranslationAction } from '../../ts/interfaces/translation-action.interface';

const create: TranslationAction = {
  title: 'Create',
  success: 'The {entity} was succesfully created. | "{entity}" was succesfully created.',
  failed:
    'We are unable to create the {entity}. Please try again later. | We are unable to create "{entity}". Please try again later.',
  button: 'Create | Create {entity}',
};

const deleteTranslation: TranslationAction = {
  title: 'Delete',
  success: 'The {entity} was succesfully deleted. | "{entity}" was succesfully deleted.',
  failed:
    'We are unable to delete the {entity}. Please try again later. | We are unable to delete "{entity}". Please try again later.',
  confirm: 'Are you sure you want to delete "{entity}"? | Are you sure you want to delete?',
  button: 'Delete | Delete {entity}',
  tabSpecific: 'Are you sure you want to delete {tab}?',
  tab: 'Are you sure you want to delete the selected tab?',
};

const update: TranslationAction = {
  title: 'Update',
  success: 'The {entity} was succesfully updated. | "{entity}" was succesfully updated.',
  failed:
    'We are unable to update the {entity}. Please try again later. | We are unable to update "{entity}". Please try again later.',
  button: 'Update | Update {entity}',
};

const get: TranslationAction = {
  title: 'Retrieve',
  success: 'The {entity} was succesfully retrieved. | "{entity}" was succesfully retrieved.',
  failed:
    'We are unable to retrieve the {entity}. Please try again later. | We are unable to retrieve "{entity}". Please try again later.',
  notFound: 'No {entity} found.',
  noResults: "Oops, it looks like you don't have any {entity} yet.",
  button: 'Retrieve | Retrieve {entity}',
};

const search: TranslationAction = {
  title: 'Search',
  success: 'The {entity} was succesfully found. | "{entity}" was succesfully found.',
  failed:
    'We are unable to look for {entity}. Please try again later. | We are unable to look for "{entity}". Please try again later.',
  noResults: 'No {entity} could be found.',
  button: 'Search | Search {entity}',
};

const send: TranslationAction = {
  title: 'Send',
  success: 'The {entity} was succesfully sent. | "{entity}" was succesfully sent.',
  failed:
    'We are unable to send the {entity}. Please try again later. | We are unable to send "{entity}". Please try again later.',
  button: 'Send | Send {entity}',
};

const upload: TranslationAction = {
  title: 'Upload',
  success: 'The {entity} was succesfully uploaded. | "{entity}" was succesfully uploaded.',
  failed:
    'We are unable to upload the {entity}. Please try again later. | We are unable to upload "{entity}". Please try again later.',
  button: 'Upload | Upload {entity}',
};

const load: TranslationAction = {
  title: 'Load',
  success: 'The {entity} was succesfully loaded. | "{entity}" was succesfully loaded.',
  failed:
    'We are unable to load the {entity}. Please try again later. | We are unable to load "{entity}". Please try again later.',
  button: 'Load | Load {entity}',
};

const importTranslation: TranslationAction = {
  title: 'Import',
  success: 'The {entity} was succesfully imported. | "{entity}" was succesfully imported.',
  failed:
    'We are unable to import the {entity}. Please try again later. | We are unable to import "{entity}". Please try again later.',
  button: 'Import | Import {entity}',
};

const exportTranslation: TranslationAction = {
  title: 'Export',
  success: 'The {entity} was succesfully export. | "{entity}" was succesfully export.',
  failed:
    'We are unable to export the {entity}. Please try again later. | We are unable to export "{entity}". Please try again later.',
  button: 'Export | Export {entity}',
};

const duplicate: TranslationAction = {
  title: 'Duplicate',
  success: 'The {entity} was succesfully duplicated. | "{entity}" was succesfully duplicated.',
  failed:
    'We are unable to duplicate the {entity}. Please try again later. | We are unable to duplicate "{entity}". Please try again later.',
  button: 'Duplicate | Duplicate {entity}',
};

const download: TranslationAction = {
  title: 'Download',
  success: 'The {entity} was succesfully downloaded. | "{entity}" was succesfully downloaded.',
  failed:
    'While trying to download the {entity} something went wrong. Please try again later. | While trying to download:"{entity}" something went wrong. Please try again later.',
  button: 'Duplicate | Duplicate {entity}',
};

const saveTranslation: TranslationAction = {
  title: 'Save',
  success: 'The {entity} was succesfully saved. | "{entity}" was succesfully saved.',
  failed:
    'While trying to save the {entity} something went wrong. Please try again later. | While trying to save:"{entity}" something went wrong. Please try again later.',
  button: 'Save | Save {entity}',
  confirm: 'Are you sure you want to save "{entity}"? | Are you sure you want to save?',
};

const actions: TranslationActions = {
  create: create,
  delete: deleteTranslation,
  update: update,
  get: get,
  search: search,
  send: send,
  upload: upload,
  load: load,
  import: importTranslation,
  export: exportTranslation,
  duplicate: duplicate,
  download: download,
  save: saveTranslation,
};

export default actions;
