import { Prompt } from 'platform-unit2-api/prompts';
import { Attribute, AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { ProductField, ProductFieldsRestService } from 'platform-unit2-api/product-fields';
import { TranslationService } from '@/general/services/translations/translation.service';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Locale } from 'platform-unit2-api/locales';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { ProductsRestService, UpdateProductField } from 'platform-unit2-api/products';
import { ToastService } from '@/general/services/toasts/toast.service';
import { GenerateFieldRestService } from 'platform-unit2-api/generate-field';
import { last } from 'lodash';

export class GenerateFieldDataService {
  private _ts: TranslationService;
  private _store = useStore();
  private _route = useRoute();
  private api = new ProductsRestService();
  private productFieldApi = new ProductFieldsRestService();
  public attributes: Attribute[] = [];
  private _allAttributes: Attribute[] = [];
  private _availableReferences: Attribute[] = [];
  public _customPrompt?: string = undefined;
  private _productId: number = +this._route.params.id;
  public prompt?: Prompt = undefined;
  public attribute?: Attribute = undefined;
  public references?: Attribute[] = undefined;
  private _toastService: ToastService;
  public isGenerating = false;
  public sourceLocale?: Locale = undefined;
  public targetLocale?: Locale = undefined;

  public get availableReferences(): Attribute[] {
    return this._availableReferences;
  }

  public get response(): string | undefined {
    return last(this.prompt?.chat.messages)?.response;
  }

  private generateService = new GenerateFieldRestService();

  private _modalVisability = false;

  // private _locale: Locale = undefined;
  public locales: Locale[] = [];

  public fields: ProductField[] = [];

  public get modalVisability(): boolean {
    return this._modalVisability;
  }

  public get customPrompt(): string | undefined {
    return this._customPrompt;
  }

  public set customPrompt(value: string | undefined) {
    this._customPrompt = value == '' ? undefined : value;
  }

  public get canGenerate(): boolean {
    return !this.attribute;
  }

  public get generateButtonLabel(): string {
    if (this.isGenerating) {
      return 'Generating...';
    }

    return this.prompt == null ? 'Generate' : 'Generate variant';
  }

  constructor() {
    this._ts = new TranslationService('supplier', 'ai-enhancements');
    this._toastService = ToastService.getInstance();
  }

  public openModal(): void {
    this.init();
    this._modalVisability = true;
  }

  public closeModal(): void {
    this._modalVisability = false;
    this._customPrompt = undefined;
    this.attribute = undefined;
    this.references = [];
    this._allAttributes = [];
    this.prompt = undefined;
    this.locales = [];
    this.fields = [];
  }

  public async init() {
    try {
      await this.fetchFields();
      await this.getDatamodelAttributes();
      this.getAvailableReferences();
    } catch {
      this._toastService.displayErrorToast(
        'Something went wrong while preparing the data required for AI generation.',
        'Error while preparing',
      );
    }
  }

  public async fetchFields() {
    this.fields = await this.productFieldApi.getProductFields(
      this._productId,
      this.locales.map((locale) => locale.id),
    );
  }

  public getAvailableReferences(): void {
    this._availableReferences = [];

    this.fields.forEach((field: ProductField) => {
      if (!field.value || field.value == '') {
        return;
      }

      if (this.sourceLocale?.id != field.locale_id) {
        return;
      }

      const attribute = this._allAttributes.find(
        (attribute: Attribute) => attribute.id == field.attribute_id,
      );

      if (!attribute) {
        return;
      }

      this._availableReferences.push(attribute);
    });
  }

  public async getDatamodelAttributes() {
    this.attributes = [];

    const datamodels = await this.api.getProductDatamodels(this._productId);

    datamodels.data.forEach((item: Datamodel) => {
      const availableAttributes = item.attributes.filter(
        (attribute: Attribute) =>
          attribute?.options?.type == AttributeTypeEnum.DEFAULT ||
          attribute?.options?.type == AttributeTypeEnum.TEXT_AREA ||
          (attribute?.options?.type == AttributeTypeEnum.RICHT_TEXT_FIELD &&
            attribute.parent_id == null),
      );

      item.attributes.forEach((attribute: Attribute) => {
        this._allAttributes.push(attribute);
      });

      availableAttributes.forEach((attribute: Attribute) => {
        this.attributes.push(attribute);
      });
    });
  }

  public async generate() {
    this.isGenerating = true;
    try {
      if (!this.attribute || !this.targetLocale) {
        return;
      }

      const references = this.fields.filter((field: ProductField) =>
        this.references?.find((attribute: Attribute) => attribute.id == field.attribute_id)
          ? true
          : false,
      );

      const prompt = await this.generateService.getGenerateField({
        prompt: this._customPrompt,
        product_fields: references.map((field: ProductField) => field.id),
        target_attribute_id: this.attribute!.id,
        chat_id: this.prompt?.chat_id,
        target_locale_id: this.targetLocale.id,
      });

      this.prompt = prompt;
    } catch {
      this._toastService.displayErrorToast(
        'Something went wrong while generating the data.',
        'Error while generating',
      );
    } finally {
      this.isGenerating = false;
    }
  }

  public async submit(reloadProducts: () => Promise<void>, reloadFields: () => Promise<void>) {
    try {
      if (!this.targetLocale || !this.attribute || !this.response) {
        this._toastService.displayErrorToast(
          'You are missing required values to save the generated data.',
          'Error while generating',
        );

        return;
      }

      const targetField: UpdateProductField = {
        attribute_id: this.attribute.id,
        locale_id: this.targetLocale.id,
        value: this.response,
        overwrite: true,
        path: '',
      };

      await this.api.setProductFields(this._productId, [targetField as UpdateProductField], []);

      this.closeModal();
      await reloadProducts();
      await reloadFields();
    } catch {
      this._toastService.displayErrorToast(
        'Something went wrong while submitting the data.',
        'Error while submitting',
      );
    }
  }
}
