<script setup lang="ts">
import OverView from '@/general/ui/views/over-view/over-view.vue';
import { Ref, provide, reactive, ref } from 'vue';
import { SupplierAiService } from '../ts/supplier-prompt.service';
import { promptServiceKey } from '../ts/keys/supplier-ai.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import PromptTable from '../components/prompt/prompt-table.vue';

const service = reactive(new SupplierAiService()) as SupplierAiService;

provide(promptServiceKey, service);
provide(
  tsKey,
  ref(new TranslationService('supplier', 'ai-enhancements')) as Ref<TranslationService>,
);
</script>

<template>
  <OverView :service="service" platform="supplier" module-name="ai-enhancements">
    <template #content>
      <PromptTable />
    </template>
  </OverView>
</template>
