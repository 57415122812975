<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { Mapping } from 'platform-unit2-api/mappings';
import { DefaultSelectProps } from './ts/default-select-props.interface';
import { ImportMappingsRestService } from 'platform-unit2-api/import-mappings';

const props = defineProps<DefaultSelectProps<Mapping>>();
const importMappingsRestService: any = ImportMappingsRestService;
</script>
<template>
  <BaseSelect
    :rest-service="importMappingsRestService"
    option-label="name"
    display="chip"
    class="w-full"
    module-name="importMapping"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
