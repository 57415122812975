import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Comment | Comments',

  commentInput: {
    placeholder: 'Start typing your message',
    suffix: 'Send',
  },
  errors: {
    sendFailed: 'We are unable to send the {name}. Please try again.',
  },

  empty: 'Someone has to be first. Start chatting now!',
};

const tranlation: Translation = {
  comments: translationObject,
};

export default tranlation;
