import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import moment from 'moment';
import { ActionRestService, Product, ProductsRestService } from 'platform-unit2-api';

/**
 * Service for the Deleted Products view.
 */
export class DeletedProductsService extends BaseViewService<
  ProductsRestService,
  Product,
  {},
  {} & { id: number }
> {
  private _actionsRest = new ActionRestService();
  public restoreVariantsCheckbox = false;

  constructor(ts: TranslationService) {
    super({
      Api: ProductsRestService,
      fetchAllFunction: 'getDeletedProducts',
      ts: ts,
      overviewRouteName: 'deleted-products',
      createRouteName: '',
      updateRouteName: '',
      confirmPopUpGroup: 'deleted-products',
    });

    this._paginationParams.limit = 15;
  }

  public get deletedProducts() {
    return this._data;
  }

  public createBody(): {} | undefined {
    throw new Error('Method not implemented.');
  }
  public updateBody(): { id: number } | undefined {
    throw new Error('Method not implemented.');
  }
  public get validated(): boolean {
    throw new Error('Method not implemented.');
  }

  public requestRestoreProduct(product: Product) {
    if (!product.variant_name && !product.module_id) {
      this.confirmRestoringMasterData(product.id);
      return;
    }

    this.confirmRestoringVariants(product.id);
  }

  private confirmRestoringVariants(productId: number) {
    this._confirmService.confirmSave({
      header: this._ts.tModule('deletedProducts.restoreVariant'),
      callback: () => this.restoreProduct(productId, false),
      group: 'restoreVariant',
      message: this._ts.tModule('deletedProducts.restoreVariantMessage'),
      acceptLabel: this._ts.tModule('deletedProducts.restore'),
      rejectLabel: this._ts.tGlobal('cancel'),
    });
  }

  private confirmRestoringMasterData(productId: number) {
    this._confirmService.confirmSave({
      header: this._ts.tModule('deletedProducts.restoreMasterData'),
      callback: () => this.restoreProduct(productId, this.restoreVariantsCheckbox),
      group: 'restoreMasterData',
      message: this._ts.tModule('deletedProducts.restoreMasterDataMessage'),
      acceptLabel: this._ts.tModule('deletedProducts.restore'),
      rejectLabel: this._ts.tGlobal('cancel'),
    });

    this.restoreVariantsCheckbox = false;
  }

  private async restoreProduct(productId: number, withVariants: boolean) {
    try {
      await this._actionsRest.restoreProduct({
        product_id: productId,
        restore_variants: withVariants,
      });
      this._toastService.displaySuccessToast(this._ts.tModule('deletedProducts.restoreSuccess'));
    } catch {
      this._toastService.displayErrorToast(this._ts.tModule('deletedProducts.restoreError'));
    } finally {
      this.refetch();
    }
  }

  public daysLeftBeforePermanentDeletion(startDate: Date) {
    return Math.max(0, 30 - moment().diff(moment(startDate), 'days'));
  }
}
