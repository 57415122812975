<script setup lang="ts">
import { Attribute } from 'platform-unit2-api/attributes';
import PredefinedDatamodels from 'retailer/modules/datamodels/components/create-datamodel/predefined-datamodels.vue';
import RetailerDatamodel from 'retailer/modules/datamodels/components/create-datamodel/retailer-datamodel.vue';
import { onMounted, ref } from 'vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { Datamodel, DatamodelsRestService } from 'platform-unit2-api/datamodels';
import { DatamodelService } from 'retailer/modules/datamodels/ts/services/datamodel.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';

/** Props */
const props = defineProps<{ datamodel?: Datamodel }>();

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'refresh'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'datamodels');
const dataModelApi = new DatamodelsRestService();

/**Constants */
const loading = ref(false);
const datamodelName = ref(props.datamodel?.name);
const datamodels = ref<Datamodel[]>([]);
const datamodelService = new DatamodelService();

/** Load datamodels */
const getDatamodels = async () => {
  loading.value = true;
  try {
    datamodels.value = await datamodelService.searchDatamodels({
      pagination: { page: 1, limit: 100 },
      data: {
        only_master: true,
      },
    });
  } catch (ex) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

/** Close the create datamodel modal */
const closeDialog = () => {
  userAttributes.value = [];
  emit('close');
  emit('refresh');
};

/** add attribute to user datamodel */
const userAttributes = ref<Attribute[]>([]);

const addAttributesToUserDatamodel = (attributes: Attribute[]) => {
  userAttributes.value = [...attributes, ...userAttributes.value];
};

/** Remove attributes from user's datamodel and add back to the predefined ones */
const addBackToDatamodel = ref<Attribute[]>([]);

const removeFromUserAttributes = (selectedAttributes: Attribute[]) => {
  const selectedAttributeIds = selectedAttributes.map((attr) => attr.id);
  userAttributes.value = userAttributes.value.filter(
    (attr) => !selectedAttributeIds.includes(attr.id),
  );

  addBackToDatamodel.value = selectedAttributes;
};

/** Save the attributes in new datamodel */
const submitDatamodelForm = async () => {
  loading.value = true;
  try {
    if (datamodelName.value && props.datamodel) {
      await dataModelApi.update(props.datamodel.id, {
        id: props.datamodel.id,
        name: datamodelName.value,
      });
    } else {
      toastService.displayErrorToast(ts.updateFailed());
    }
  } catch {
    toastService.displayErrorToast(ts.updateFailed());
    emit('close');
    emit('refresh');
  }

  try {
    if (!props.datamodel) {
      toastService.displayErrorToast(ts.tModule('datamodel_attributes.saving_error'));
    } else {
      const data = userAttributes.value.map((attr) => {
        return {
          id: attr.id,
          order: attr.order,
          public: attr.public,
        } as { id: number; order: number; public?: boolean };
      });

      await dataModelApi.attachDatamodelFields(props.datamodel?.id, {
        attributes: data,
      });
    }
  } catch (error) {
    toastService.displayErrorToast(ts.tModule('datamodel_attributes.saving_error'));
  } finally {
    loading.value = false;
    userAttributes.value = [];
    emit('close');
    emit('refresh');
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: closeDialog,
});

const createButton = new CreateButton({
  label: ts.tGlobal('create'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!datamodelName.value,
  onClick: submitDatamodelForm,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, createButton],
});

onMounted(() => {
  getDatamodels();
});
</script>
<template>
  <BaseDialog
    :visible="true"
    :buttons-options="buttonsOptions"
    size="large"
    :title="ts.tModule('datamodel_create.title')"
    :subtitle="ts.tModule('datamodel_create.details')"
    class="relative"
    @update:visible="closeDialog"
  >
    <!-- #region: Attribute selection  -->
    <div class="flex h-full px-4 text-gray-800">
      <!-- Loading -->
      <div v-if="loading" class="flex h-full w-full">
        <LoadingIndicator />
      </div>
      <div v-else class="flex h-full w-full">
        <!-- Predefined datamodel -->
        <PredefinedDatamodels
          :user-attributes="userAttributes"
          :add-to-datamodel="addBackToDatamodel"
          :datamodel="datamodel"
          :datamodels="datamodels"
          @remove-from-user-attributes="removeFromUserAttributes"
          @add-attributes-to-user-datamodel="addAttributesToUserDatamodel"
        />

        <!-- Divider -->
        <div :style="{ width: '6%' }">
          <p-divider layout="vertical">
            <i class="mdi mdi-arrow-right-thin text-3xl text-gray-600"></i
          ></p-divider>
        </div>

        <!--   User's datamodel -->
        <RetailerDatamodel
          v-model:datamodel-name="datamodelName"
          :user-attributes="userAttributes"
          :datamodel-id="datamodel?.id"
          :datamodels="datamodels"
          @remove-from-user-attributes="removeFromUserAttributes"
        />
      </div>
    </div>
    <!-- #endregion -->
  </BaseDialog>
</template>
