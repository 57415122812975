<script setup lang="ts">
import { inject } from 'vue';
import { exportServiceKey } from '../ts/keys/exports.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import StatusChip from '@/general/ui/components/status-chip.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import ExportVerticalList from './export-vertical-list.vue';
import ExportLog from './export-log.vue';
import { ExportParameters, Pipeline } from 'platform-unit2-api/pipelines';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import Upload from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';

interface Props {
  pipeline: Pipeline;
  visible: boolean;
}
defineProps<Props>();

const ts = inject(tsKey)!;
const exportService = inject(exportServiceKey)!;
</script>

<template>
  <BaseDialog
    :visible="visible"
    :title="ts.tModule('details.title')"
    :show-footer="false"
    size="large"
    class="bg-white"
    content-class="mb-5"
    @update:visible="exportService.isDetailsModalVisible = false"
  >
    <div>
      <!-- Title -->
      <div class="align-items-center flex gap-3 pup-mb-5">
        <Upload class="w-4rem" :upload="pipeline?.retailer?.thumbnail" />
        <h3 class="font-bold">
          {{ pipeline?.module?.name ?? ts.tModule('details_modal.unknown') }}
        </h3>
      </div>

      <!-- Columns -->
      <div class="grid">
        <div class="col-3">
          <div class="grid">
            <div class="col-3 flex flex-column gap-3">
              <span>{{ ts.tModule('details_modal.date') }}:</span>
              <span class="pup-my-2">{{ ts.tModule('details_modal.status') }}:</span>
              <span>{{ ts.tModule('details_modal.errors') }}:</span>
            </div>
            <div class="col-6 flex flex-column gap-3">
              <span>{{ formatDate(new Date(pipeline?.created_at)) }}</span>
              <div>
                <StatusChip
                  v-if="pipeline?.current_status"
                  :label="exportService.getExportStatus(pipeline).label"
                  :severity="exportService.getExportStatus(pipeline).severity"
                />
              </div>
              <div class="align-items-center flex">
                <span>{{ pipeline?.error_count }}</span>
                <img v-if="pipeline?.error_count" src="@/assets/icons/error-icon.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="grid">
            <div class="col-4 flex flex-column gap-3">
              <span>{{ ts.tModule('details_modal.products') }}:</span>
              <span>{{ ts.tModule('details_modal.exported_by') }}:</span>
              <span>{{ ts.tModule('details_modal.exportId') }}:</span>
            </div>
            <div class="col-4 flex flex-column gap-3">
              <span>{{
                (pipeline?.payload?.parameters as ExportParameters)?.product_ids?.length ?? 0
              }}</span>
              <span>{{ pipeline?.user?.name }}</span>
              <span>{{ pipeline?.id }}</span>
            </div>
          </div>
        </div>
        <div class="col-3">
          <table class="w-full">
            <tr>
              <td class="pb-2">{{ ts.tModule('details_modal.channel') }}:</td>
              <td class="pb-2">{{ pipeline?.module?.name }}</td>
            </tr>
            <tr>
              <td class="pb-2">{{ ts.tModule('details_modal.locale') }}:</td>
              <td class="pb-2">{{ pipeline?.locale?.value }}</td>
            </tr>
            <tr>
              <td class="pb-2">{{ ts.tModule('details_modal.mailed_to') }}:</td>
              <td class="pb-2">
                <template
                  v-for="email in (pipeline?.payload?.parameters as ExportParameters).mail_to"
                  :key="email"
                >
                  {{ email }},
                  <br />
                </template>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-3">
          <div
            v-if="(pipeline?.payload?.parameters as ExportParameters)?.include_data"
            class="flex gap-3 pup-mb-5"
          >
            <span><i class="pi pi-check" /></span>
            <span>{{ ts.tModule('details_modal.data_included') }}</span>
          </div>
          <div
            v-if="(pipeline?.payload?.parameters as ExportParameters)?.include_assets"
            class="flex gap-3 pup-mb-5"
          >
            <span><i class="pi pi-check" /> </span>
            <span>{{ ts.tModule('details_modal.assets_included') }}</span>
          </div>
          <div
            v-if="(pipeline?.payload?.parameters as ExportParameters)?.ignore_product_status"
            class="flex gap-3 pup-mb-5"
          >
            <span><i class="pi pi-check" /></span>
            <span>{{ ts.tModule('details_modal.ignore_product_status') }}</span>
          </div>
        </div>
      </div>
    </div>

    <h3 class="pup-mt-5">
      {{ ts.tModule('details_modal.export_log') }}
    </h3>
    <pTabs value="export-statuses" class="mb-1">
      <pTabList>
        <pTab value="export-statuses">
          {{ ts.tModule('details_modal.tab_pipeline_statuses') }}
        </pTab>
        <pTab value="export-errors">{{
          ts.tModule('details_modal.tab_product_errors') +
          ' (' +
          (pipeline?.error_count ?? '-') +
          ')'
        }}</pTab>
      </pTabList>
      <pTabPanel value="export-statuses">
        <div v-if="!pipeline?.status">
          <LoadingIndicator />
        </div>
        <ExportVerticalList :pipeline="pipeline" />
      </pTabPanel>
      <pTabPanel value="export-errors">
        <ExportLog :pipeline="pipeline" :pipeline-id="pipeline.id"></ExportLog>
      </pTabPanel>
    </pTabs>
  </BaseDialog>
</template>
