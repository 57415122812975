<script setup lang="ts">
import OverView from '@/general/ui/views/over-view/over-view.vue';
import { SupplierBrandViewService } from '../ts/brand-view.service';
import { Ref, provide, ref } from 'vue';
import { brandServiceKey } from '../ts/keys/brand.keys';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import BrandCardGrid from '../components/brand-card-grid.vue';
import BrandForm from '../components/brand-form.vue';
import OverViewSideBar from '@/general/ui/components/over-view/overview-sidebar.vue';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';

const ts = ref(new TranslationService('supplier', 'brands'));
const service = ref(new SupplierBrandViewService());

provide(brandServiceKey, service as Ref<SupplierBrandViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="supplier" module-name="brands" :service="service">
    <template #skeleton>
      <CardSkeleton />
    </template>
    <template #content>
      <BrandCardGrid />
    </template>
    <template #crudComponent>
      <OverViewSideBar :service="service"
        ><template #form>
          <BrandForm />
        </template>
      </OverViewSideBar>
    </template>
  </OverView>
</template>
