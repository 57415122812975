//vue
import { Component, markRaw } from 'vue';

//components
import StandardText from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-text.vue';

//types
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';

//services
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { StandardText as StandardTextType } from '../story-types/modules/standard-text.interface';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export default class StandardTextClass extends BaseStoryModule<StandardTextType> {
  ts: TranslationService;
  moduleTranslationKey = 'storyBuilder.standardTextModuleDisplayName';
  component: Component = markRaw(StandardText);

  constructor(data?: StandardTextType) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);

    if (this.data?.type == null) {
      this.data.type = ContentStoryModulesEnum.StandardText;
      this.data.headline = { type: ModuleComponentTypes.Text, value: '' };
      this.data.body = { type: ModuleComponentTypes.TextArea, value: '' };
    }
  }

  public setHeadline(headlineText?: string) {
    if (headlineText) {
      this.data.headline = { type: ModuleComponentTypes.Text, value: headlineText };
    }
  }

  public setTextArea(textAreaValue: string) {
    this.data.body = { type: ModuleComponentTypes.TextArea, value: textAreaValue };
  }
}
