<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { RolesRestService, Role, RoleCompact } from 'platform-unit2-api/roles';
import { DefaultSelectPropsWithCompact } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectPropsWithCompact<Role, RoleCompact>>();
const rolesRestService = RolesRestService;
</script>
<template>
  <BaseSelect
    :rest-service="rolesRestService"
    option-label="name"
    class="w-full"
    module-name="role"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
