<script setup lang="ts">
import { Ref, provide, ref } from 'vue';

// Services
import { ScheduledExportViewService } from '@/general/services/overview-service/modules/scheduled-export-view.service';
import { scheduledExportServiceKey } from '../ts/keys/exports.keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';

// Components
import OverView from '@/general/ui/views/over-view/over-view.vue';
import OverviewSidebar from '@/general/ui/components/over-view/overview-sidebar.vue';
import ScheduledExportTable from '@/platforms/supplier/modules/export/components/scheduled-export-table.vue';
import ExportScheduledForm from '../components/export-scheduled-form.vue';

const ts = ref(new TranslationService('supplier', 'exports'));
const scheduledExportService = ref(new ScheduledExportViewService(ts.value as TranslationService));

provide(scheduledExportServiceKey, scheduledExportService as Ref<ScheduledExportViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView platform="supplier" module-name="scheduledExports" :service="scheduledExportService">
    <template #content>
      <ScheduledExportTable />
    </template>
    <template #crudComponent>
      <OverviewSidebar :service="scheduledExportService">
        <template #form>
          <ExportScheduledForm />
        </template>
      </OverviewSidebar>
    </template>
    <template #others>
      <pConfirmDialog group="scheduled-export-row" />
    </template>
  </OverView>
</template>
