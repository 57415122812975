<script lang="ts" setup>
//Core
import { ref } from 'vue';

//Composables and Services
import usePagination from 'composables/usePagination/pagination';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import {
  MediaRestService,
  MediaBrandCompact,
  MediaDatamodelCompact,
  MediaUploadStatus,
  MediaVariantCompact,
} from 'platform-unit2-api/media';

//Types
import { Upload } from 'platform-unit2-api/uploads';
import { PaginationObject } from 'platform-unit2-api/core';

import EmptyState from '@/general/ui/components/empty-state.vue';
import { useRouter } from 'vue-router';
import AssetCard from '../assets/components/asset-card.vue';
import { onMounted } from 'vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { formatDate } from '@/general/utils/format-date';

interface OptionCollection {
  header: string;
  type: 'VARIANTS' | 'DATAMODELS' | 'BRANDS' | 'STATUS';
  key: string;
  callback: Function;
}

//Services
const ts = new TranslationService('supplier', 'media');
const toastService = ToastService.getInstance();
const mediaApi = new MediaRestService();

// Constants
const router = useRouter();
const { page, perPage, onPageChange: $onPageChange, query } = usePagination();
const collections = ref<
  MediaVariantCompact[] | MediaUploadStatus[] | MediaBrandCompact[] | MediaDatamodelCompact[]
>([]);
const selectedCollection = ref<Upload[]>();
const previousCollection = ref<Upload[]>();
const currentCollection = ref<
  MediaVariantCompact | MediaUploadStatus | MediaBrandCompact | MediaDatamodelCompact
>();
const loading = ref(false);
const total = ref(0);
const selectedFilterOption = ref<OptionCollection>();
const optionsCollection = ref<OptionCollection[]>([
  {
    header: ts.tModule('variant_name'),
    type: 'VARIANTS',
    key: 'variant_name',
    callback: async () => await mediaApi.getAllVariantCollections(),
  },
  {
    header: ts.tModule('datamodels.title', { params: { count: 2 } }),
    type: 'DATAMODELS',
    key: 'datamodel_id',
    callback: async () => await mediaApi.getAllDatamodels(),
  },
  {
    header: ts.tModule('brands.title', { params: { count: 2 } }),
    type: 'BRANDS',
    key: 'brand_id',
    callback: async () => await mediaApi.getAllBrands(),
  },
  {
    header: ts.tGlobal('status'),
    type: 'STATUS',
    key: 'status_id',
    callback: async () => await mediaApi.getAllStatuses(),
  },
]);

const generateCollection = async (): Promise<void> => {
  loading.value = true;
  collections.value = [];
  selectedCollection.value = undefined;
  page.value = 1;

  try {
    collections.value = await (await selectedFilterOption.value?.callback?.())?.data;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

const getUploads = async (
  collection?: MediaVariantCompact | MediaUploadStatus | MediaBrandCompact | MediaDatamodelCompact,
): Promise<void> => {
  loading.value = true;
  query.value = `model-type=${selectedFilterOption.value?.type.toLowerCase()}&${
    selectedFilterOption.value?.key
  }=${
    selectedFilterOption.value?.type === 'VARIANTS'
      ? (collection as MediaVariantCompact).name
      : (collection as MediaUploadStatus | MediaBrandCompact | MediaDatamodelCompact).id
  }`;

  try {
    const repsonse = await mediaApi.getUploadsOfCollection({
      query: query.value,
      page: page.value,
      limit: perPage.value,
    } as PaginationObject);

    selectedCollection.value = repsonse.data;
    total.value = repsonse.meta?.total ?? 0;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed('upload'));
  } finally {
    currentCollection.value = collection;
    loading.value = false;
  }
};

const prev = (): void => {
  previousCollection.value = selectedCollection.value;
  selectedCollection.value = undefined;
};

const onPageChangeCollection = (event: any): void => {
  $onPageChange(event.page + 1);
  getUploads(currentCollection.value);
};

onMounted(async () => {
  //set a default value for the dropdown
  selectedFilterOption.value = optionsCollection.value[1];
  await generateCollection();
});
</script>
<template>
  <div
    class="h-full overflow-x-hidden overflow-y-scroll pt-4 px-4 relative remove-p-from-upper-element w-full"
  >
    <div
      style="background: #eff3f8; border: 1px solid #dfe7ef; border-width: 1px 0 1px 0"
      class="align-items-center flex h-5rem justify-content-between mb-3"
    >
      <div class="ml-3 p-buttonset">
        <p-button
          :disabled="!selectedCollection"
          :label="ts.tGlobal('previous')"
          text
          icon="mdi mdi-arrow-left"
          @click="prev()"
        />
        <pSelect
          v-model="selectedFilterOption"
          option-label="header"
          class="w-15rem"
          :options="optionsCollection"
          :placeholder="ts.tModule('filter_placeholder')"
          @change="generateCollection"
        />
      </div>
    </div>
    <LoadingIndicator v-if="loading" />
    <div
      v-else-if="
        !loading && collections?.length > 0 && selectedFilterOption != null && !selectedCollection
      "
      class="grid"
    >
      <div
        v-for="(collection, index) in collections"
        :key="index"
        class="border-round col-3 cursor-pointer hover:bg-black-alpha-10"
        @click="getUploads(collection)"
      >
        <div class="grid h-full p-2">
          <div class="align-items-center col-12 flex">
            <div class="col-4">
              <svg
                class="w-full"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0H2C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V4C20 2.89 19.1 2 18 2H10L8 0Z"
                  fill="#046ED3"
                />
                <path
                  d="M0 14V2H10H18C19.1 2 20 2.89 20 4V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14Z"
                  fill="#0080FA"
                />
                <text
                  text-anchor="middle"
                  dominant-baseline="middle"
                  fill="white"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-size="5"
                  text-align="center"
                  letter-spacing="0px"
                >
                  <tspan x="50%" y="60%">
                    {{ collection.upload_count > 9999 ? '9999+' : collection.upload_count }}
                  </tspan>
                </text>
              </svg>
            </div>
            <div class="col-8">
              <div class="mb-2">
                <h2 v-if="selectedFilterOption?.type != 'STATUS'" class="ellipsis text-xl">
                  {{
                    (collection as MediaVariantCompact | MediaBrandCompact | MediaDatamodelCompact)
                      .name == null
                      ? ts.tGlobal('master_data')
                      : (
                          collection as
                            | MediaVariantCompact
                            | MediaBrandCompact
                            | MediaDatamodelCompact
                        ).name
                  }}
                </h2>
                <h2 v-else class="text-xl">
                  {{ (collection as MediaUploadStatus).label }}
                </h2>
              </div>
              <div class="">
                <span class="text-500 text-xs">{{
                  (collection as MediaBrandCompact | MediaDatamodelCompact | MediaUploadStatus)
                    .updated_at == null
                    ? ''
                    : formatDate(
                        new Date(
                          (
                            collection as
                              | MediaBrandCompact
                              | MediaDatamodelCompact
                              | MediaUploadStatus
                          ).updated_at,
                        ),
                      )
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full">
      <pDataView :value="selectedCollection" layout="grid">
        <template #grid="slotProps">
          <div class="grid">
            <div
              v-for="(item, index) in slotProps.items"
              :key="index"
              class="col-12 flex flex-column lg:col-3 md:col-6 p-2 xl:col-3"
            >
              <AssetCard hide-select :image="item" />
            </div>
          </div>
        </template>
        <template #empty>
          <div>
            <EmptyState
              :empty-state-title="
                ts.tGlobal('emptyStateTitle', {
                  choice: 2,
                  entity: ts.tModule('collection.title'),
                })
              "
              :empty-state-subtitle="ts.tModule('collection.emptyState.subtitle')"
              :translation-service="ts"
              :icon-name="'media-collection'"
              :button-label="ts.tModule('collection.emptyState.button')"
              @clicked="router.push({ name: 'media-assets' })"
            /></div
        ></template>
      </pDataView>
      <pPaginator
        v-if="!loading && selectedFilterOption && selectedCollection != null && total > perPage"
        lazy
        :first="(page - 1) * perPage"
        :rows="perPage"
        :total-records="total"
        class="bottom-0 m-0 sticky w-full"
        @page="onPageChangeCollection($event)"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
@import '@/assets/theme/settings/mixins.scss';
.remove-p-from-upper-element {
  padding-bottom: 0 !important;
}

.ellipsis {
  @include ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
