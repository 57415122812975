import FeatureFlag from '@/general/ui/components/feature-flag/feature-flag.vue';
import { App } from 'vue';
import { FeatureFlagService } from '../featureflags/ts/service/feature-flags.service';
import { featureFlagServiceKey } from '../featureflags/ts/keys/feature-flag-service.key';

export function installFeatureFlag(app: App) {
  const featureFlagService = FeatureFlagService.getInstance();

  app.provide<FeatureFlagService>(featureFlagServiceKey, featureFlagService);
  app.component('FeatureFlag', FeatureFlag);
}
