import { Upload } from 'platform-unit2-api/uploads';
import { Prompt } from 'platform-unit2-api/prompts';
import { EditImageRestService, EditImageRequest } from 'platform-unit2-api/edit-image';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

export class EditImageService {
  private _ts: TranslationService;
  private _isLoading = false;
  private _toastService: ToastService;
  private _generateImageRest: EditImageRestService;
  private _chosenTags: string[] = [];
  public prompt?: Prompt;
  public sourceImage?: Upload;
  public sourcePickerVisible = false;

  private _promptRequest?: EditImageRequest | Partial<EditImageRequest>;

  public get promptRequest(): Partial<EditImageRequest> {
    if (this._promptRequest) {
      return this._promptRequest;
    }

    this._promptRequest = {
      size: '256x256',
      amount: 1,
    };

    return this._promptRequest;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get tags(): string[] {
    return this._chosenTags;
  }

  public set tags(tags: string[]) {
    this._chosenTags = tags;
  }

  public get tagOptions(): string[] {
    return [
      'realistic',
      'anime',
      'cartoon',
      'sketch',
      'painting',
      'pixel-art',
      '3d-render',
      'logo',
      'icon',
      'professional brand image',
    ];
  }

  constructor() {
    this._ts = new TranslationService('supplier', 'ai-enhancements');

    this._toastService = ToastService.getInstance();
    this._generateImageRest = new EditImageRestService();
  }

  public setSourceImage(image: Upload): void {
    this.sourceImage = image;
  }

  public showSourcePicker(): void {
    this.sourcePickerVisible = true;
  }

  public hideSourcePicker(): void {
    this.sourcePickerVisible = false;
  }

  public resolveCrudComponent(): void {
    return;
  }

  private createEditImageRequest(): EditImageRequest | undefined {
    if (
      !this.promptRequest.prompt ||
      this.promptRequest.prompt == '' ||
      !this.promptRequest.amount ||
      !this.promptRequest.size ||
      !this.sourceImage
    ) {
      return;
    }

    return {
      upload_id: this.sourceImage.id as number,
      prompt: this.promptRequest.prompt as string,
      amount: this.promptRequest.amount as number,
      size: this.promptRequest.size as '256x256' | '512x512' | '1024x1024',
      tags: this.tags,
    };
  }

  public generateImages(): void {
    this._isLoading = true;

    const body = this.createEditImageRequest();

    if (body === undefined) {
      this._toastService.displayErrorToast('Error while generating images');
      return;
    }

    this._generateImageRest
      .editImage(body)
      .then((_result) => {
        this.prompt = _result;
      })
      .catch(() => {
        this._toastService.displayErrorToast(
          'Error while generating images, either the service is unavailable or the prompt is innapropriate',
        );
      })
      .finally(() => {
        this._isLoading = false;
      });
  }
}
