<script setup lang="ts">
import { onMounted, ref } from 'vue';
import SelectType from 'retailer/modules/products/views/export-generic/type.view.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

/** Props */
const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    isActive: boolean;
  }>(),
  {
    modelValue: false,
    isActive: false,
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'update:modelValue', isGeneric: boolean): void;
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('retailer', 'products');

const nextPage = (): void => {
  emit('update:modelValue', isGeneric.value);
};

const isGeneric = ref<boolean>(true);
const updateIsGeneric = (value: boolean) => {
  isGeneric.value = value;
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const nextButton = new CreateButton({
  label: ts.tGlobal('next'),
  position: CrudButtonPosition.RIGHT,
  onClick: nextPage,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, nextButton],
});

onMounted(() => {
  isGeneric.value = props.modelValue;
});
</script>

<template>
  <BaseDialog
    :visible="isActive"
    title=" "
    size="small"
    :buttons-options="buttonsOptions"
    @update:visible="emit('hide')"
  >
    <select-type :is-generic="isGeneric" @export-type="updateIsGeneric" />
  </BaseDialog>
</template>
