import Downloads from 'supplier/modules/downloads/views/downloads.view.vue';
import { RouteRecordRaw } from 'vue-router';

const downloadsRoutes: RouteRecordRaw[] = [
  {
    path: '/downloads/:id',
    name: 'downloads',
    component: Downloads,
    meta: {
      title: 'Productsup Platform - Downloads',
      disableAuthentication: true,
    },
  },
];

export default downloadsRoutes;
