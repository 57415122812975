import { useStore } from 'vuex';

import { MappingsRestService } from 'platform-unit2-api/mappings';
import { Client } from 'platform-unit2-api/clients';
import { Module } from 'platform-unit2-api/modules';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { User } from 'platform-unit2-api/users';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { DuplicateMappingPayload } from '../ts/interfaces/duplicate-mapping-payload.interface';
import { MappingType } from '../ts/enums/mapping-type.enum';

export class MappingService {
  //Duplicate Mapping
  selectedClientToDuplicate: Client | undefined = undefined;
  selectedDatamodelToDuplicate: Datamodel | undefined = undefined;
  selectedModuleToDuplicate: Module | undefined = undefined;
  duplicateDialogVisible = false;
  duplicateWarningDialogVisible = false;

  private _mappingApiService = new MappingsRestService();
  private _currentUser: User;

  constructor(
    private _emit: (e: any, payload?: any) => void = () => {},
    private _store = useStore(),
    private _toast = ToastService.getInstance(),
    private _translation = new TranslationService('supplier', 'mappings'),
  ) {
    this._currentUser = this._store.getters['users/currentUser'];
  }

  // #region Duplicate Mapping

  isDuplicateButtonDisabled() {
    //Duplicate button in the modal is disabled when no client/datamodel/module is selected
    return (
      !this.selectedClientToDuplicate ||
      !this.selectedDatamodelToDuplicate ||
      !this.selectedModuleToDuplicate
    );
  }

  openModal() {
    this.duplicateDialogVisible = true;
  }

  openWarningModal() {
    this.duplicateWarningDialogVisible = true;
  }

  clearDuplicationForm() {
    this.selectedClientToDuplicate = undefined;
    this.selectedDatamodelToDuplicate = undefined;
    this.selectedModuleToDuplicate = undefined;
  }

  private async _mappingExists({
    type,
    moduleId,
    datamodelId,
  }: {
    type: MappingType;
    moduleId: number;
    datamodelId: number;
  }) {
    return await this._mappingApiService.mappingExists({ type, moduleId, datamodelId });
  }

  async duplicate({
    type = MappingType.EXPORT,
    payload,
  }: {
    type: MappingType;
    payload: DuplicateMappingPayload;
  }) {
    // If the user has selected a datamodel and module to duplicate from
    if (payload.sourceModuleId && payload.sourceDatamodelId) {
      // If the user has selected the same datamodel and module to duplicate from and to
      if (
        payload.datamodelId === payload.sourceDatamodelId &&
        payload.moduleId === payload.sourceModuleId
      ) {
        this._toast.displayErrorToast(
          this._translation.tModule('duplicate_mappings.duplication_error_same_destination'),
        );
        return;
      }

      try {
        const doesMappingExist = await this._mappingExists({
          type,
          moduleId: payload.moduleId,
          datamodelId: payload.datamodelId,
        });
        doesMappingExist ? this._showWarning() : this.duplicateMapping({ type, payload });
      } catch {
        this._toast.displayErrorToast(
          this._translation.tModule('duplicate_mappings.duplication_fail'),
        );
      }
    } else {
      this._toast.displayErrorToast(
        this._translation.tModule('duplicate_mappings.duplication_fail'),
      );
    }
  }

  private _showWarning() {
    this.duplicateWarningDialogVisible = true;
  }

  closeDuplicateWarningDialog(closeAllDialogs = false) {
    this.duplicateWarningDialogVisible = false;
    closeAllDialogs && this._emit('hide'); // To close the main duplicate dialog
  }

  async duplicateMapping({
    type,
    payload,
  }: {
    type: MappingType;
    payload: DuplicateMappingPayload;
  }) {
    try {
      const requestPayload = {
        workspace_id: payload.clientId,
        datamodel_id: payload.datamodelId,
        module_id: payload.moduleId,
        source_datamodel_id: payload.sourceDatamodelId,
        source_module_id: payload.sourceModuleId,
      };
      const duplicationResult = await this._mappingApiService.duplicateUserMapping({
        type,
        payload: requestPayload,
      });

      // Check if the mapping was overwritten or duplicate without being overwritten
      duplicationResult.overwritten
        ? this._toast.displaySuccessToast(
            this._translation.tModule('duplicate_mappings.duplication_overwrite_success'),
          )
        : this._toast.displaySuccessToast(
            this._translation.tModule('duplicate_mappings.duplication_success'),
          );

      // If the mapping was overwritten but some attributes were not overwritten
      duplicationResult.warning &&
        this._toast.displayWarningToast(
          this._translation.tModule('duplicate_mappings.duplication_warning'),
        );
    } catch (e) {
      this._toast.displayErrorToast(
        this._translation.tModule('duplicate_mappings.duplication_fail'),
      );
    } finally {
      this._emit('hide', { closeAllDialogs: true });
    }
  }

  get userWorkspaces() {
    return this._currentUser?.clients;
  }

  //#endregion
}
