import { Module } from 'vuex';
import { ImportState } from '@/platforms/supplier/modules/import/ts/import.types';
import { actions } from 'supplier/modules/media/store/match-media.actions';
import { getters } from 'supplier/modules/media/store/match-media.getters';
import { mutations } from 'supplier/modules/media/store/match-media.mutations';

const namespaced = true;

export const state: ImportState = {
  matchedProductUploads: [],
};

export const matchMediaStore: Module<ImportState, {}> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
