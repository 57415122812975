import { ActionTree } from 'vuex';
import { StoryManagerVuexState } from './story-manager.state';
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';

export const actions: ActionTree<StoryManagerVuexState, {}> = {
  UPDATE_LOADING({ commit }, loading: boolean) {
    commit('setLoading', loading);
  },

  UPDATE_CURRENT_STORY({ commit }, story: ContentStory) {
    commit('setCurrentStory', story);
  },

  UPDATE_CHANGES_SAVED({ commit }, changesSaved: boolean) {
    commit('setChangesSaved', changesSaved);
  },
};
