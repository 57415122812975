import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { UpdateProductField } from 'platform-unit2-api/products';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class TagFieldService extends AttributeFieldService<string[] | undefined> {
  constructor(
    attributeField: AttributeField<string[] | undefined>,
    productAttributeField: ProductAttributeField<string[] | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    if (typeof productAttributeField.value === 'string') {
      productAttributeField.value = (productAttributeField.value as string).split(',');
    }

    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }

  /*
   * Backend expects a string that is created by joining the array by commas or null
   */
  protected createPayload(): UpdateProductField {
    return {
      attribute_id: this._productAttributeField.attribute_id,
      value:
        (this._productAttributeField.value?.length ?? 0) > 0
          ? this._productAttributeField.value?.join(', ')
          : null,
      locale_id: this._productAttributeField.locale.id,
      path: this._productAttributeField?.path ?? null,
      overwrite: false,
    };
  }
}
