import { AttributeTypeEnum } from 'platform-unit2-api/attributes';
import { UpdateProductField } from 'platform-unit2-api/products';
import { cloneDeep, isEqual } from 'lodash';

/**
 * Attribute field node class
 * Used to represent a single attribute field
 * @param T - Type of the value
 */
export class AttributeFieldNode<T> {
  private _initialDataValue?: T;
  public attrId: number;
  public localeId: number;
  public path: string | null;
  public global = false;
  public value?: T | null;
  public children: AttributeFieldNode<any>[] = [];
  public parentId?: number;
  public type: AttributeTypeEnum;

  /**
   * Unique identifier of the attribute field
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, null, 'value');
   * node.identifier; // 1_1
   *
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, 'path', 'value');
   * node.parentId = 2;
   * node.identifier; // 1_1_path_2
   *
   * @returns string
   */
  get identifier(): string {
    return `${this.attrId}_${this.localeId}${this.path ? '_' + this.path : ''}${
      this.parentId ? '_' + this.parentId : ''
    }`;
  }

  /**
   * Used to create a node that represents the attribute field.
   * this node can be used to see if the attribute field has changes or to remove children or check if they exist.
   *
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, null, 'value');
   * node.hasChanges(); // false
   * node.value = 'new value';
   * node.hasChanges(); // true
   *
   * @param attrId - Attribute id
   * @param localeId - Locale id
   * @param type - Type of the attribute field
   * @param path - Path of the attribute field
   * @param value - Value of the attribute field
   */
  constructor(
    attrId: number,
    localeId: number,
    type: AttributeTypeEnum,
    path: string | null,
    value?: T,
  ) {
    this.attrId = attrId;
    this.localeId = localeId;
    this.type = type;
    this.path = path;
    this.value = value;
    this._initialDataValue = cloneDeep(value);
  }

  /**
   * Check if the node is an advanced field
   *
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, null, 'value');
   * node.isNodeAdvancedField(); // false
   *
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.TAB_FIELD, null, 'value');
   * node.isNodeAdvancedField(); // true
   *
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.TAB_FIELD, null, 'value');
   * node.isNodeAdvancedField(); // true
   * @returns boolean
   */
  public isNodeAdvancedField(): boolean {
    return this.type === AttributeTypeEnum.GROUP_FIELD || this.type === AttributeTypeEnum.TAB_FIELD;
  }

  /**
   * Check if the node has changes, the inital value is set when the node is created,
   * this is done by cloning the value, so the initial value is not a reference to the value.
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, null, 'value');
   * node.hasChanges(); // false
   * node.value = 'new value';
   * node.hasChanges(); // true
   * @returns boolean
   */
  public hasChanges(): boolean {
    return !isEqual(this.value, this._initialDataValue);
  }

  /**
   * Remove children from the node,
   * if the path is provided only the child with the path will be removed
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, null, 'value');
   * node.children.push(new AttributeFieldNode(2, 1, AttributeTypeEnum.DEFAULT, null, 'value'));
   * node.children.push(new AttributeFieldNode(3, 1, AttributeTypeEnum.DEFAULT, null, 'value'));
   * node.removeChildren(); // []
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.DEFAULT, 0, 'value');
   * node.children.push(new AttributeFieldNode(2, 1, AttributeTypeEnum.DEFAULT, 0.1, 'value'));
   * node.children.push(new AttributeFieldNode(3, 1, AttributeTypeEnum.DEFAULT, 0.2, 'value'));
   * node.removeChildren('0.2'); // [AttributeFieldNode - with path 0.1]
   * @param path - Path of the child node
   */
  public removeChildren(path?: string): void {
    this.children = path ? this.children.filter((c) => c.path !== path) : [];
  }

  /**
   * Does a child/children exist with the given path in the node
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.TAB_FIELD, null, 'value');
   * node.childrenPathExists('path'); // false
   * @example const node = new AttributeFieldNode(1, 1, AttributeTypeEnum.TAB_FIELD, 0, 'value');
   * node.children.push(new AttributeFieldNode(2, 1, AttributeTypeEnum.DEFAULT, '0.1', 'value'));
   * node.children.push(new AttributeFieldNode(3, 1, AttributeTypeEnum.DEFAULT, '0.2', 'value'));
   * node.childrenPathExists('0.2'); // true
   * @param path - Path of the child node
   * @returns boolean
   */
  public childrenPathExists(path: string): boolean {
    return this.isNodeAdvancedField() && this.children.some((c) => c.path === path);
  }

  /**
   * creates for a given UpdateProductField a new instance of AttributeFieldNode
   * @example const field = {
   *  attribute_id: 1,
   * locale_id: 1,
   * path: null,
   * value: 'value',
   * };
   * const node = AttributeFieldNode.fromUpdatedField(field, AttributeTypeEnum.DEFAULT);
   * @param field - A field of the updated fields array with type UpdateProductField
   * @param type - The attribute type of the field
   * @param parentId - The parent id of the field, optional
   * @returns instance of AttributeFieldNode
   */
  public static fromUpdatedField<T>(
    field: UpdateProductField,
    type: AttributeTypeEnum,
    parentId?: number,
  ): AttributeFieldNode<T> {
    const node = new AttributeFieldNode(
      field.attribute_id,
      field.locale_id,
      type,
      field.path ?? null,
      field.value,
    );
    node.parentId = parentId;
    return node;
  }
}
