import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { FeaturePermission } from 'platform-unit2-api/roles';
import ExportScheduledEdit from 'retailer/modules/export/components/export-scheduled-edit.vue';
import ExportScheduled from 'retailer/modules/export/views/export-scheduled.view.vue';
import ExportRestart from 'retailer/modules/export/components/export-restart.vue';
import ExportLayout from 'retailer/modules/export/views/export.view.vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';
import ExportV2View from 'retailer/modules/export/views/export-v2.view.vue';
import { RouterService } from '@/core/router/router.service';

const factory = new RouteFactory({
  platform: 'retailer',
  tabs: [
    {
      label: 'Export',
      key: 'export',
      command: () => {
        RouterService.getInstance().router.push({ name: 'export' });
      },
    },
    {
      label: 'Scheduled export',
      key: 'export-scheduled',
      command: () => {
        RouterService.getInstance().router.push({ name: 'export-scheduled' });
      },
    },
  ],
  navigationGroup: 'export',
});

export async function getExportRetailerRoutes(): Promise<RouteRecordRaw[]> {
  const service = FeatureFlagService.getInstance();

  const isOn = await service.isOn('marketplaces-errorhandling');

  return [
    factory.createAppRoute({
      path: '/export',
      name: 'export',
      component: isOn ? ExportV2View : ExportLayout,
      translations: {
        module: 'exports',
      },
      rights: [FeaturePermission.READ_PIPELINES],
      children: [
        factory.createChildRoute({
          path: ':id',
          name: 'restart-export',
          component: ExportRestart,
        }),
      ],
    }),
    factory.createAppRoute({
      path: '/export/scheduled',
      name: 'export-scheduled',
      component: ExportScheduled,
      rights: [FeaturePermission.READ_PIPELINES],
      title: 'Scheduled exports',
      creatable: false,
      children: [
        factory.createChildRoute({
          path: ':id',
          name: 'edit-scheduled-export',
          component: ExportScheduledEdit,
        }),
      ],
    }),
  ];
}

export default getExportRetailerRoutes;
