<script setup lang="ts">
import { PageState } from 'primevue/paginator';
import { inject } from 'vue';
import { modulesServiceKey } from 'supplier/modules/modules/ts/keys/modules.keys';
import Upload from '@/general/ui/components/upload.vue';
import { useRouter } from 'vue-router';
import EmptyState from 'ui/components/empty-state.vue';

const service = inject(modulesServiceKey)!;
const router = useRouter();
</script>
<template>
  <div>
    <div class="grid">
      <div
        v-for="mod in service.modules"
        :key="mod.id"
        class="col-12 flex flex-column lg:col-3 md:col-6 p-2"
      >
        <pCard>
          <template #header>
            <div class="align-items-center flex flex-column justify-content-center">
              <Upload class="h-18rem max-w-full w-18rem" :upload="mod.retailer?.thumbnail" />
              <p class="font-bold mb-2 mt-4 text-xl">
                {{ mod.name }}
              </p>
            </div>
          </template>
          <template #content>
            <div class="align-items-center flex h-2rem justify-content-center">
              <p class="capitalize">
                {{ mod.type }}
              </p>
              <pButton
                v-if="
                  (mod.settings_fields?.fields?.length ?? 0) > 0 ||
                  (mod.settings_fields?.dynamic_settings?.length ?? 0) > 0
                "
                v-tooltip.bottom="{ value: 'Add credentials', class: 'text-sm' }"
                icon="mdi mdi-cog"
                class="ml-2"
                text
                rounded
                @click="
                  router.push({
                    name: 'new-credentials',
                    params: { id: mod.id },
                  })
                "
              />
            </div>
          </template>
        </pCard>
      </div>
    </div>
    <div>
      <EmptyState
        v-if="service.isSearchResultEmpty"
        :translation-service="service.ts"
        :icon-name="'channels'"
        :empty-state-subtitle="service.ts.tModule('emptySearchStateSubTitle')"
        :button-visible="false"
      />
      <EmptyState
        v-if="service.hasNoModules"
        :translation-service="service.ts"
        :icon-name="'channels'"
        :button-visible="false"
      />
    </div>
    <pPaginator
      v-if="service.modules.length > 0 && service.total > (service.limit ?? 0)"
      :rows="service.limit"
      :total-records="service.total"
      :first="service.first"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @page="(event: PageState) => service.onPageChange(event.page)"
    />
  </div>
</template>
