import { CrudButtonConstructorInterface } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-buttons-contructor.interface';
import BaseButton from '@/general/ui/components/crud-buttons/ts/classes/base-button.class';

export class CreateButton extends BaseButton {
  constructor({
    label,
    position,
    type = 'button',
    onClick,
    styleClasses = 'p-button',
    buttonIcon = '',
    isEnabled = () => true,
    iconPos = undefined,
  }: CrudButtonConstructorInterface) {
    super(isEnabled, label, type, position, styleClasses, buttonIcon, onClick, iconPos);
  }
}
