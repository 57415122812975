<script setup lang="ts">
import { JsonSchemasRestService, ProductType } from 'platform-unit2-api/json-schemas';
import { DefaultSelectProps } from '../../ts/default-select-props.interface';
import BaseSelect from '../../base-select.vue';

interface Props extends DefaultSelectProps<ProductType> {
  moduleId?: number;
}

const props = defineProps<Props>();
const restService: any = JsonSchemasRestService;
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    fetch-function-name="getProductTypes"
    :custom-fetch-params="[moduleId]"
    option-label="name"
    option-value="name"
    data-key="name"
    module-name="productTypes"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  >
    <template #value="{ value, placeholder }"> {{ value ?? placeholder }} </template>
  </BaseSelect>
</template>
