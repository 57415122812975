import { Datamodel } from 'platform-unit2-api/datamodels';
import {
  ImportMapping,
  CreateImportMappingRequest,
  UpdateImportMappingRequest,
  ImportMappingsRestService,
  ImportMappingDuplicateRequest,
} from 'platform-unit2-api/import-mappings';
import { Client } from 'platform-unit2-api/clients';
import { useStore } from 'vuex';
import { User } from 'platform-unit2-api/users';
import { cloneDeep } from 'lodash';
import { BaseViewService } from '../../view/base-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
/**
 * Service for the Mapping type view.
 */
export class ImportMappingViewService extends BaseViewService<
  ImportMappingsRestService,
  ImportMapping,
  CreateImportMappingRequest,
  UpdateImportMappingRequest
> {
  private _store = useStore();

  //#region Duplicate variables
  public duplicateWorkspaceTarget: undefined | Client;
  public duplicateDatamodelTarget: undefined | Datamodel;
  private _duplicateDialog = false;
  public duplicateWarningDialog = false;
  public isSavingDuplicate = false;

  private _currentUser: undefined | User;

  /**
   * Get the current user's workspaces.
   */
  get userWorkspaces() {
    return this._currentUser?.clients;
  }

  /**
   * Get the visibility state of the duplicate dialog.
   */
  public get duplicateDialog(): boolean {
    return this._duplicateDialog;
  }

  /**
   * Set the duplicate dialog and reset the targets if the dialog is closed.
   */
  public set duplicateDialog(value: boolean) {
    if (value === false) {
      this.duplicateWorkspaceTarget = undefined;
      this.duplicateDatamodelTarget = undefined;
    }

    this._duplicateDialog = value;
  }

  /**
   * Chekcs if the duplication can happen.
   */
  private get _canDuplicate(): boolean {
    if (
      !this.current?.id ||
      !this.current?.name ||
      !this.duplicateDatamodelTarget?.id ||
      !this.duplicateWorkspaceTarget?.id
    ) {
      return false;
    }

    return true;
  }

  /**
   * Checks if the duplicate button should be disabled.
   */
  public get isDuplicateButtonDisabled(): boolean {
    return (
      !this.duplicateWorkspaceTarget ||
      !this.duplicateDatamodelTarget ||
      !(this.current?.name?.length != 0)
    );
  }
  //#endregion Duplicate variables

  /**
   * @inheritdoc
   * Check if the current mapping is valid. Name is required.
   */
  public get validated(): boolean {
    if (!this.current?.name || this.current.name.length < 1) {
      return false;
    }

    return true;
  }
  /**
   * @inheritdoc
   * Create CreateImportMappingRequest from current mapping.
   */
  public createBody(): CreateImportMappingRequest | undefined {
    if (this.current == null || !this.validated || !this.current.datamodel?.id) {
      return;
    }

    const body: CreateImportMappingRequest = {
      name: this.current.name!,
      datamodel_id: this.current.datamodel?.id,
    };

    return body;
  }

  _create(): void {
    this._isLoadingCrudComponent = true;

    const body = this.createBody();

    if (body != null && this.validated) {
      this._restService
        .post(body)
        .then((response) => {
          this._toastService.displaySuccessToast(this._ts.createSuccess());
          this.closeCrudComponent();
          this._router.push({ name: this._updateRouteName, params: { id: response.id } });
        })
        .catch((e) => {
          this._formValidationService.handleError(e, () => {
            this._toastService.displayErrorToast(this._ts.createFailed());
          });
        })
        .finally(() => {
          this._isLoadingCrudComponent = false;
        });
    } else {
      this._isLoadingCrudComponent = false;
    }
  }

  /**
   * @inheritdoc
   * Create UpdateMappingRequest from current mapping.
   */
  public updateBody(): UpdateImportMappingRequest | undefined {
    if (!this.validated || this.current?.id == null) {
      return;
    }

    const body: UpdateImportMappingRequest = {
      id: this.current.id!,
      name: this.current.name,
    };

    return body;
  }

  /**
   * Get mappings, this.data is assigned to mappings for readabilty.
   */
  public get mappings(): ImportMapping[] {
    return this._data;
  }

  /**
   * @inheritdoc
      title: 'World Of Content - Import mappings',
   */

  constructor(ts: TranslationService) {
    super({
      Api: ImportMappingsRestService,
      ts: ts,
      overviewRouteName: 'mappings',
      createRouteName: 'new-import-mapping',
      updateRouteName: 'edit-import-mapping',
      confirmPopUpGroup: 'mappings',
    });

    this._currentUser = this._store.getters['users/currentUser'];
  }

  /**
   * Delete a mapping through a confimation dialog.
   * @param id id of the mapping to delete.
   */
  public deleteAction(id: number): void {
    this._confirmService.confirmDelete({
      callback: () => {
        this._isLoadingOverview = true;

        this._restService
          .delete(id)
          .then((_) => {
            this._toastService.displaySuccessToast(this._ts.deleteSuccess());
            this.refetch();
          })
          .catch(() => {
            this._toastService.displayErrorToast(this._ts.deleteFailed());
          })
          .finally(() => {
            this._isLoadingOverview = false;
          });
      },
      group: 'delete-dialog',
    });
  }

  //#region Duplicate functions
  /**
   * Open a dialog to duplicate a mapping.
   * @param mapping mapping to duplicate
   */
  public openDuplicateDialog(mapping: ImportMapping): void {
    this._currentObject = cloneDeep(mapping);
    this.partialObject.name = `${mapping.name} - copy`;
    this.duplicateDialog = true;
  }

  /**
   * Close the duplicate dialog.
   */
  public closeDuplicateDialog(): void {
    this._currentObject = undefined;
    this.duplicateDialog = false;
  }

  /**
   * Check if a mapping with the same name already exists in the targeted client through the datamodel.
   */
  private async _mappingExists() {
    if (!this.current?.id || !this.duplicateDatamodelTarget?.id || !this.current?.name) {
      return false;
    }

    return this._restService.doesMappingExist({
      name: this.current.name,
      mapping_id: this.current.id,
      datamodel_id: this.duplicateDatamodelTarget.id,
    });
  }

  /**
   * Check if the duplication will overwrite an existing mapping.
   */
  public validateDuplicate() {
    // If the user has selected a datamodel and module to duplicate from
    if (!this.current?.id || !this.duplicateDatamodelTarget?.id) {
      this._toastService.displayErrorToast(this._ts.tModule('duplicate_mappings.duplication_fail'));

      return;
    }

    this.isSavingDuplicate = true;

    this._mappingExists()
      .then((exist) => (exist ? this._showWarning() : this.duplicate()))
      .catch(() => {
        this._toastService.displayErrorToast(
          this._ts.tModule('duplicate_mappings.duplication_fail'),
        );
        this.isSavingDuplicate = false;
      });
  }

  /**
   * Show a warning dialog if the duplication will overwrite an existing mapping.
   */
  private _showWarning() {
    this.duplicateWarningDialog = true;
    this.isSavingDuplicate = false;
  }

  /**
   * Close the warning dialog.
   */
  public closeWarningDialog() {
    this.duplicateWarningDialog = false;
  }

  /**
   * Get the payload for the duplication request.
   */
  private _getDuplicationPayload():
    | Partial<ImportMappingDuplicateRequest>
    | ImportMappingDuplicateRequest {
    return {
      datamodel_id: this.duplicateDatamodelTarget!.id,
      workspace_id: this.duplicateWorkspaceTarget!.id,
      mapping_id: this.current!.id,
      name: this.current!.name,
    };
  }

  private _warnUserOfWrongOverwrite(warn: boolean): void {
    warn &&
      this._toastService.displayWarningToast(
        this._ts.tModule('duplicate_mappings.duplication_warning'),
      );
  }

  private _displayOverWriteToast(overwritten: boolean): void {
    overwritten
      ? this._toastService.displaySuccessToast(
          this._ts.tModule('duplicate_mappings.duplication_overwrite_success'),
        )
      : this._toastService.displaySuccessToast(
          this._ts.tModule('duplicate_mappings.duplication_success'),
        );
  }

  /**
   * Duplicate a mapping.
   */
  public duplicate(): void {
    if (!this._canDuplicate) {
      this._toastService.displayErrorToast(this._ts.duplicateFailed());

      return;
    }

    this._restService
      .duplicate(this._getDuplicationPayload() as ImportMappingDuplicateRequest)
      .then((result) => {
        // Check if the mapping was overwritten or duplicate without being overwritten
        this._displayOverWriteToast(result.overwritten);
        // If the mapping was overwritten but some attributes were not overwritten
        this._warnUserOfWrongOverwrite(result.warning);

        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.duplicateFailed());
      })
      .finally(() => {
        this.duplicateDialog = false;
        this.duplicateWarningDialog = false;
        this.isSavingDuplicate = false;
      });
  }

  //#endregion Duplicate functions
}
