<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { ProductsRestService } from 'platform-unit2-api/products';
import { DefaultSelectProps } from './ts/default-select-props.interface';

interface Props extends DefaultSelectProps<string> {
  omitMasterData?: boolean;
}

const productRestService: any = ProductsRestService;

const props = withDefaults(defineProps<Props>(), {
  omitMasterData: false,
});

const editOptions = (options: { id: string; name: string }[]) => {
  let newOptions = options
    .filter((variant) => variant.name !== 'Master Data')
    .map((variant) => ({ id: variant.name, name: variant.name }))
    .sort((a, b) => a.name.localeCompare(b.name));

  if (!props.omitMasterData) {
    newOptions = [{ id: 'Master Data', name: 'Master Data' } as any].concat(newOptions);
  }

  return newOptions;
};
</script>
<template>
  <BaseSelect
    :rest-service="productRestService"
    fetch-function-name="getVariants"
    class="w-full"
    module-name="variant"
    option-label="name"
    :edit-options="editOptions"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
