//Types
import { KeycloakService } from '@/core/security/keycloak.service';
import { ApplicationTaskService } from './application.task.service';
import { KeyCloakTask } from './tasks/keycloak.task';
import { fetchCurrentUserTask } from './tasks/fetchCurrentUserTask';
import { InstallRouterTask } from './tasks/installRouter.task';
import { InitDebugTask } from './tasks/initDebug.task';
import { GoToApplicationTask } from './tasks/goToApplication.task';
import { useRoute } from 'vue-router';
import { RouterService } from '@/core/router/router.service';
import { DebugLogger } from '@/core/main/debug.logger';
import { InitHeapTask } from './tasks/InitHeapTask';

/**
 * AppService holds all the logic needed for the initial load of the platform
 */
export class AppService {
  private route = useRoute();

  public errorHasOccured = false;

  private messages = [
    'Fetching the latest updates...',
    'Bringing everything up to date...',
    'Loading, almost done...',
    'Just a moment...',
    'Loading...',
  ];

  public getRandomMessage(): string {
    const random = Math.floor(Math.random() * 4) + 1;
    const time = new Date();
    const date = time.getMonth() === 3 && time.getDate() === 1;

    if (random === 4 && date) {
      return this._hMessage;
    }

    return this.messages[Math.floor(Math.random() * this.messages.length)];
  }

  public initialized = false;

  //#region Singleton
  private static _instance: AppService;

  public get instance(): AppService {
    return AppService._instance;
  }
  //#endregion

  constructor() {
    if (AppService._instance) {
      return AppService._instance;
    }

    AppService._instance = this;
  }

  public async resolveKeycloak() {
    await KeycloakService.getInstance().init();
  }

  public tasks: ApplicationTaskService[] = [
    new KeyCloakTask(),
    new InitHeapTask(),
    new fetchCurrentUserTask(),
    new InstallRouterTask(),
    new InitDebugTask(),
    new GoToApplicationTask(this.route.path),
  ];

  private async _loadApp() {
    const pulbicRouteNames = RouterService.getInstance().publicRouteNames;

    if (pulbicRouteNames.includes(this.route.name as string)) {
      return;
    }

    if (this.route.name)
      if (!this.initialized) {
        for (const task of this.tasks) {
          await task.handle();

          if (!task.handled) {
            throw new Error(`Task ${task.name} failed to handle`);
          }
        }

        this.initialized = true;
      }
  }

  public async loadApp() {
    try {
      await this._loadApp();
    } catch (error) {
      this.errorHasOccured = true;

      DebugLogger.error(error);
    }
  }

  private _hMessage =
    '01000100 01100101 01110110 00100000 01110111 01100001 01110011 00100000 01101000 01100101 01110010 01100101';
}
