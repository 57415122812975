<script setup lang="ts">
import { inject, ref, onMounted } from 'vue';
import { featureFlagServiceKey } from '@/core/featureflags/ts/keys/feature-flag-service.key';

const props = withDefaults(
  defineProps<{
    flag: string;
    enabled?: boolean;
  }>(),
  {
    enabled: undefined,
  },
);

const featureFlagService = inject(featureFlagServiceKey);
const isOn = ref(false);
const loading = ref(true);

onMounted(async () => {
  if (props.flag != null) {
    try {
      isOn.value = (await featureFlagService?.isOn(props.flag.toString())) ?? false;
    } catch {
      isOn.value = false;
    }
  }

  loading.value = false;
});
</script>

<template>
  <div v-if="!loading" data-testid="feature-flag">
    <!-- if only flag is set or enabled is the same as the function state -->
    <slot v-if="(enabled == null && isOn) || (enabled != null && enabled == isOn)"></slot>

    <!-- if flag is on and enabled is true display default slot -->
    <slot v-if="isOn && enabled == null" name="enabled"></slot>
    <!-- if flags is not set or enabled is false -->
    <slot v-else-if="enabled == null" name="disabled"></slot>
  </div>
</template>
