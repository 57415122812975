import {
  UserPreferenceRestService,
  UserPreferences,
  UserPreferencesUpdate,
} from 'platform-unit2-api/user-preference';
import { UserRestService, User } from 'platform-unit2-api/users';
import storage from '@/general/utils/storage';

export class PreferencesService {
  //#region Singleton
  private static _instance: PreferencesService;
  //#endregion

  //#region Services
  private _userRestService: UserRestService;
  private _userPreferencesService: UserPreferenceRestService;
  //#endregion

  //#region Properties
  private _currentUser?: User;

  private _preferences?: UserPreferences;
  //#endregion

  //#region Getters & Setters
  public get preferences(): UserPreferences | undefined {
    return this._preferences;
  }

  public set preferences(value: UserPreferences | undefined) {
    this._preferences = value;

    if (this.preferences) {
      document.documentElement.style.fontSize = this.preferences.scale + 'px';

      storage().setStorageItem('preferences', this._preferences);
      this.updatePreferences();
    }
  }

  public get autoFillRetailerDetails(): boolean {
    return this.preferences?.autofill_retailer_details ?? false;
  }

  public set autoFillRetailerDetails(value: boolean) {
    if (this.preferences) {
      this.preferences.autofill_retailer_details = value;
    }
  }

  public get defaultArchivedNotVisible(): boolean {
    return this.preferences?.default_archived_not_visible ?? false;
  }

  public set defaultArchivedNotVisible(value: boolean) {
    if (this.preferences) {
      this.preferences.default_archived_not_visible = value;
    }
  }

  public get expandExportMappings(): boolean {
    return this.preferences?.expand_export_mappings ?? false;
  }

  public set expandExportMappings(value: boolean) {
    if (this.preferences) {
      this.preferences.expand_export_mappings = value;
    }
  }

  public get defaultMasterDataFilter(): boolean {
    return this.preferences?.default_master_data_filter ?? false;
  }

  public set defaultMasterDataFilter(value: boolean) {
    if (this.preferences) {
      this.preferences.default_master_data_filter = value;
    }
  }

  public get newLayout(): boolean {
    return this.preferences?.new_layout ?? false;
  }

  public set newLayout(value: boolean) {
    if (this.preferences) {
      this.preferences.new_layout = value;
    }
  }

  public get scale(): number {
    return this.preferences?.scale ?? 12;
  }

  public set scale(value: number) {
    if (this.preferences) {
      this.preferences.scale = value;
      document.documentElement.style.fontSize = this.preferences.scale + 'px';
    }
  }

  //#endregion

  constructor() {
    this._userRestService = new UserRestService();
    this._userPreferencesService = new UserPreferenceRestService();

    if (PreferencesService._instance) {
      return PreferencesService._instance;
    }

    this._userRestService.getCurrentUser().then((user) => {
      this._currentUser = user;

      if (user) {
        this.preferences = user.preferences;
      }
    });

    this._loadPreferences();

    PreferencesService._instance = this;
  }

  private _loadPreferences(): void {
    const preferences = storage().getStorageItem('preferences');

    if (preferences) {
      this.preferences = preferences;
    }
  }

  public updatePreferences(preferences?: UserPreferencesUpdate): Promise<UserPreferences> | void {
    if (!this._currentUser || !this._currentUser.preferences) {
      return;
    }

    let upatePreferenceBody: UserPreferencesUpdate;

    if (preferences) {
      upatePreferenceBody = {
        autofill_retailer_details: preferences.autofill_retailer_details,
        default_archived_not_visible: preferences.default_archived_not_visible,
        expand_export_mappings: preferences.expand_export_mappings,
        default_master_data_filter: preferences.default_master_data_filter,
        new_layout: preferences.new_layout,
        scale: preferences.scale,
      };

      storage().setStorageItem('preferences', {
        id: this._currentUser.preferences.id,
        ...upatePreferenceBody,
      });

      return this._userPreferencesService.updateUserPreferences(
        this._currentUser.preferences.id,
        upatePreferenceBody,
      );
    } else if (this.preferences) {
      upatePreferenceBody = {
        autofill_retailer_details: this.preferences.autofill_retailer_details,
        default_archived_not_visible: this.preferences.default_archived_not_visible,
        expand_export_mappings: this.preferences.expand_export_mappings,
        default_master_data_filter: this.preferences.default_master_data_filter,
        new_layout: this.preferences.new_layout,
        scale: this.preferences.scale,
      };

      storage().setStorageItem('preferences', {
        id: this._currentUser.preferences.id,
        ...upatePreferenceBody,
      });

      return this._userPreferencesService.updateUserPreferences(
        this._currentUser.preferences.id,
        upatePreferenceBody,
      );
    }
  }
}
