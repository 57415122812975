import { actions } from 'supplier/modules/thumbnails/store/thumbnails.actions';
import { Module } from 'vuex';

const namespaced = true;
export const state: {} = {};

export const thumbnailsStore: Module<{}, {}> = {
  namespaced,
  state,
  actions,
};
