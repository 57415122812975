import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Upload | Uploads',
  placeholder: 'Select upload',
  dragDrop: 'Drag and drop files here to upload.',
  uploadedSuccessfully:
    'Successfully uploaded {count} asset | Successfully uploaded {count} assets.',
};

const tranlation: Translation = {
  uploads: translationObject,
};

export default tranlation;
