<script setup lang="ts">
import { computed } from 'vue';
import useFallbackIcon from '@/general/utils/fallback-icon';

/**Props */
interface Props {
  filename?: string;
  iconpath?: string;
  customStyle?: string;
}
const props = withDefaults(defineProps<Props>(), {
  filename: '',
  iconpath: '',
  customStyle: '',
});

/**Consts */
const { getFallbackIcon } = useFallbackIcon();

/**Logic */
const svgPath = computed(() => {
  return props.filename ? getFallbackIcon(props.filename) : props.iconpath;
});
</script>
<template>
  <img :src="svgPath" alt="format-icon" :style="customStyle ? customStyle : { width: '100%' }" />
</template>
