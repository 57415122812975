<script setup lang="ts">
import { assetViewServiceKey } from '../keys/asset-view-service.key';
import { ref } from 'vue';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import ApplicationHeader from '@/general/ui/layout/components/application-header.vue';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { useService } from '@/general/composables/useService';
import { TranslationService } from '@/general/services/translations/translation.service';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new TranslationService('supplier', 'media');
const fileUpload = ref();
</script>
<template>
  <ApplicationHeader>
    <template #createButton>
      <pFileUpload
        ref="fileUpload"
        class="headerButton p-button-rounded primary-button"
        :choose-label="(ts.tGlobal('upload') + ' ' + ts.title).toUpperCase()"
        :pt="{
          pcChooseButton: {
            label: {
              style: 'font-size: 12px !important',
            },
            icon: {
              style: 'font-size: 20px',
            },
          },
        }"
        :disabled="service.isUploading"
        choose-icon="mdi mdi-upload"
        auto
        multiple
        custom-upload
        mode="basic"
        @uploader="(e: FileUploadUploaderEvent) =>
      service.uploadAssets(e)"
      />
    </template>
  </ApplicationHeader>
</template>
<style>
.primary-button {
  height: 40px;
}

.headerButton {
  position: absolute !important;
  margin-bottom: -3rem;
  right: 2rem;
  transform: translate(0, -50%);
  width: fit-content;
}
</style>
