import { Upload, ImportAssetsRequest } from 'platform-unit2-api/uploads';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Router, useRoute } from 'vue-router';
import { Store } from 'vuex';

export default class ImportAssetsService {
  visible = false;
  loading = false;
  _ts: TranslationService;
  _router: Router;
  _store: Store<any>;
  importModalChildRoutes: { label: string; to: { name: string } }[] = [];
  partialObject: Partial<ImportAssetsRequest> = {};
  activeStep = 0;
  route;

  constructor(ts: TranslationService, router: Router, store: Store<any>) {
    this._ts = ts;
    this._router = router;
    this.importModalChildRoutes = [
      { label: this._ts.tModule('uploads'), to: { name: 'import-assets-upload' } },
      { label: this._ts.tModule('match'), to: { name: 'import-assets-result' } },
      { label: this._ts.tGlobal('import'), to: { name: 'import-assets-match' } },
    ];
    this.route = useRoute();
    this._store = store;

    this.activeStep = this.importModalChildRoutes.findIndex(
      (route) => route.to.name === this.route.name,
    );

    if (this.activeStep < 0) {
      this.activeStep = 0;
    }
  }

  public toggleVisible() {
    this.visible = !this.visible;
    this._router.push({ name: this.visible ? 'import-assets-upload' : 'media-assets' });
  }

  upload(value: Upload[]) {
    this.partialObject.data = value;
  }

  nextStep() {
    const newStep = ++this.activeStep;
    if (this.importModalChildRoutes[newStep] != null) {
      this._router.push(this.importModalChildRoutes[newStep].to);
    }
  }

  prevStep() {
    const newStep = --this.activeStep;
    if (this.importModalChildRoutes[newStep] != null) {
      this._router.push(this.importModalChildRoutes[newStep].to);
    }
  }

  disablePrevButton(): boolean {
    return this.activeStep === 1 && this.getNextButtonLabel() === this._ts.tModule('startOver');
  }

  disableNextButton(): boolean {
    return this.activeStep === 0 && (this.partialObject.data?.length ?? 0) < 1;
  }

  getNextButtonLabel(): string {
    if (this.activeStep === 1 && this.partialObject.product_upload_length === 0) {
      return this._ts.tModule('startOver');
    }

    if (this.importModalChildRoutes.length - 1 === this.activeStep) {
      return this._ts.tGlobal('finish');
    }

    return this._ts.tGlobal('next');
  }

  getNextButtonAction(): Function {
    if (this.activeStep === 1 && this.partialObject.product_upload_length === 0) {
      return this.startOver.bind(this);
    }

    if (this.importModalChildRoutes.length - 1 === this.activeStep) {
      return this.completeImportAssets.bind(this);
    }

    return this.nextStep.bind(this);
  }

  public startOver() {
    this.activeStep = 0;
    this.partialObject.data = [];
    this._router.push(this.importModalChildRoutes[0].to);
  }

  public completeImportAssets() {
    if (!this.partialObject) {
      return;
    }

    this.loading = true;

    try {
      this._store.dispatch('matchMedia/ATTACH_UPLOADS', {
        overwrite: this.partialObject.overwrite ?? false,
        updateVariants: this.partialObject.update_variants ?? false,
      });
      ToastService.getInstance().displaySuccessToast(this._ts.tModule('import_assets.success'));
      this._router.push({ name: 'products' });
    } catch (err) {
      ToastService.getInstance().displayErrorToast(this._ts.tModule('import_assets.error'));
    } finally {
      this.loading = false;
    }
  }
}
