import { KeycloakService } from '@/core/security/keycloak.service';
import * as Sentry from '@sentry/vue';

export async function logout() {
  Sentry.configureScope((scope) => {
    scope.setTag('user-roles', '');
    scope.setUser(null);
  });

  await KeycloakService.getInstance().logout(window.location.href);
}
