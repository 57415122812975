<script setup lang="ts">
import DialogSkeleton from '@/general/ui/components/skeletons/dialog-skeleton.vue';
import { SkeletonDialog } from '@/general/ui/components/skeletons/skeletons.types';

const layout: SkeletonDialog[] = [{ numberOfFields: 1 }];

interface Props {
  widthInPixels: number;
}
// Props
defineProps<Props>();
</script>

<template>
  <DialogSkeleton :width-in-pixels="widthInPixels" :skeleton="layout" />
</template>
