<script setup lang="ts">
import ShareProductsModal from 'supplier/modules/products/components/actions/share-products-modal.vue';
import DetachAllAssetsModal from 'supplier/modules/products/components/actions/detach-all-assets-modal.vue';
import MassUpdateFieldsModal from 'supplier/modules/products/components/actions/mass-update-fields-modal.vue';
import MassAttachBrandModal from 'supplier/modules/products/components/actions/mass-attach-brand-product-modal.vue';
import ExportProductsModal from 'supplier/modules/products/components/actions/export-products-modal.vue';
import ExportProductsModalGeneric from 'supplier/modules/products/components/actions/export-products-modal-generic.vue';
import ExportTypeModal from 'supplier/modules/products/components/actions/export-type-modal.vue';
import CreateProductVariantsModal from 'supplier/modules/products/components/actions/create-product-variants-modal.vue';
import AddTaskModal from 'supplier/modules/tasks/components/add-task-modal.vue';
import MassDeleteLocaleContentModal from 'supplier/modules/products/components/actions/mass-delete-locale-content.vue';
import AttachTaskModal from 'supplier/modules/products/components/actions/attach-task-modal.vue';
import { computed, inject, ref } from 'vue';
import MassSyncProductDisplayNameModal from 'supplier/modules/products/components/actions/mass-sync-product-display-name-modal.vue';
import MassSyncProductBrandModal from 'supplier/modules/products/components/actions/mass-sync-product-brand-modal.vue';
import TransitionStateModal from 'supplier/modules/products/components/actions/mass-transition-state.vue';
import PublicationModal from 'supplier/modules/products/components/actions/publication-modal.vue';
import { useStore } from 'vuex';
import { RouteLocationRaw, useRouter } from 'vue-router';
import LockFieldsModal from 'supplier/modules/products/components/actions/lock-fields-modal.vue';
import BulkAttachCategoryModal from 'supplier/modules/products/components/actions/bulk-attach-category-modal.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product, ProductsRestService } from 'platform-unit2-api/products';
import { ProductVueService } from '../../services/product-vue.service';
import AssignToClientModal from 'supplier/modules/products/components/actions/assign-to-client-modal.vue';
import { productTableServiceKey } from '../../key/product-table-service.key';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { onMounted } from 'vue';
import { MenuItem } from 'primevue/menuitem';

import { UpdateSharedStatus } from 'platform-unit2-api/actions';
/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const tsCategories = new TranslationService('supplier', 'categories');
const confirmService = new ConfirmService();

const productsApi = new ProductsRestService();
const productService = ref(new ProductVueService());

/** Constants */

const store = useStore();
const router = useRouter();
const loading = ref(false);
const loadingProductIds = ref<string[]>([]);
const assignToClientActive = ref(false);
const shareProductsActive = ref(false);
const detachAllAssetsActive = ref(false);
const massUpdateFieldActive = ref(false);
const massUpdateProductActive = ref(false);
const exportActive = ref(false);
const exportTypeActive = ref(false);
const exportGenericActive = ref(false);
const attachTaskVisible = ref(false);
const bulkAttachCategoryModelActive = ref(false);
const createProductVariantsActive = ref(false);
const syncProductDisplayNameActive = ref(false);
const syncProductBrandActive = ref(false);
const deleteLocaleContentActive = ref(false);
const transitionStateModalActive = ref(false);
const lockFieldsActive = ref(false);
const isGeneric = ref(false);
const showPublicationButton = ref(false);

const productTableService = inject(productTableServiceKey)!;

const lockPermission = computed(() =>
  store.getters['users/currentUser']?.permissions.includes('update-productfield'),
);

const items = ref<MenuItem[]>([
  {
    label: ts.tGlobal('actions'),
    disabled: () => !productTableService.value.checkedProductRows.length,
    items: [
      {
        label: ts.tGlobal('delete'),
        icon: 'mdi mdi-delete-outline',
        class: 'delete',
        command: () => bulkDeleteProductsConfirm(),
        disabled: () => (!productTableService.value.checkedProductRows.length ? true : false),
      },

      {
        label: ts.tModule('actions.transition_state'),
        icon: 'mdi mdi-send-check-outline',
        command: () => transitionStateModalVisible(true),
        disabled: () => (!productTableService.value.checkedProductRows.length ? true : false),
      },
      {
        label: ts.tModule('actions.create_variant'),
        icon: 'mdi mdi-cards-variant',
        command: () => createProductVariantsVisible(true),
      },
      {
        label: ts.tModule('actions.detach_assets'),
        icon: 'mdi mdi-image-remove',
        command: () => detachAllAssetsVisible(true),
      },
      {
        label: ts.tModule('actions.add_task'),
        icon: 'mdi mdi-clipboard-plus-outline',
        command: () => addTaskModalVisible(),
      },
      {
        label: tsCategories.tModule('bulkAttachTitle'),
        icon: 'mdi mdi-clipboard-plus-outline',
        command: () => bulkAttachCategoryModelVisible(true),
      },
      {
        label: ts.tModule('actions.share'),
        icon: 'mdi mdi-share-variant-outline',
        command: () => shareProductsVisible(true),
      },
      {
        label: ts.tModule('actions.share_with_retailer'),
        icon: 'mdi mdi-share-variant-outline',
        command: async () => await shareProductsWithRetailer(true),
      },
      {
        label: ts.tModule('actions.unshare_with_retailer'),
        icon: 'mdi mdi-share-variant-outline',
        command: () => shareProductsWithRetailer(false),
      },
      {
        label: ts.tModule('actions.update_field'),
        icon: 'mdi mdi-form-textbox',
        command: () => massUpdateFieldVisible(true),
      },
      {
        label: ts.tModule('actions.bulkAttachBrand'),
        icon: 'mdi mdi-shopping-outline',
        command: () => massUpdateProductVisible(true),
      },
      {
        label: ts.tModule('actions.sync_name'),
        icon: 'mdi mdi-sync',
        command: () => syncProductDisplayNameVisible(true),
      },
      {
        label: ts.tModule('actions.sync_brand'),
        icon: 'mdi mdi-sync',
        command: () => syncProductBrandVisible(true),
      },
      {
        label: ts.tModule('actions.delete_locale_content'),
        icon: 'mdi mdi-translate-off',
        command: () => deleteLocaleContentVisible(true),
      },
      {
        label: ts.tModule('actions.attach_to_task'),
        icon: 'mdi mdi-arrow-decision',
        command: () => attachToTaskVisible(true),
      },
      {
        label: ts.tModule('actions.lock_fields'),
        icon: 'mdi mdi-lock-outline',
        command: () => lockFieldsVisible(lockPermission.value),
        disabled: !lockPermission.value,
      },
      {
        label: ts.tModule('actions.duplicate'),
        icon: 'mdi mdi-content-copy',
        command: () => duplicateProductHandler(),
      },
    ],
  },

  {
    label: ts.tModule('actions.publication'),
    icon: 'mdi mdi-export',
    command: () => publicationModalVisible(true),
    disabled: () => productTableService.value.checkedProductRows.length === 0,
    visible: () => showPublicationButton.value,
  },
  {
    label: ts.tModule('actions.export'),
    icon: 'mdi mdi-export',
    command: () => exportTypeVisible(true),
    disabled: () => productTableService.value.checkedProductRows.length === 0,
  },
]);

const exportChildRoutes = [
  {
    label: ts.tModule('export_modal.channel.title'),
    to: { name: 'export-channel' },
  },
  {
    label: ts.tModule('export_modal.settings.title'),
    to: { name: 'export-settings' },
  },
  {
    label: ts.tModule('export_modal.schedule.title'),
    to: { name: 'export-schedule' },
  },
  {
    label: ts.tModule('export_modal.contact.title'),
    to: { name: 'export-contact' },
  },
  {
    label: ts.tModule('export_modal.overview.title'),
    to: { name: 'export-info' },
  },
];

const exportChildRoutesGeneric = [
  {
    label: ts.tModule('export_modal.overview.title'),
    to: { name: 'export-generic' },
  },
  {
    label: ts.tModule('export_modal.overview.title'),
    to: { name: 'export-product-overview' },
  },
];

const bulkDeleteProductsConfirm = () => {
  confirmService.confirmDelete({
    group: 'bulk-delete-dialog',
    message: ts.tModule('confirm_delete', {
      params: {
        amount: productTableService.value.getSelectedProductIds().length,
      },
    }),
    callback: () => bulkDeleteProductsHandler(),
  });
};

const bulkDeleteProductsHandler = async () => {
  loadingProductIds.value.push(...(productTableService.value.getSelectedProductIds() as any));
  try {
    await store.dispatch(
      'products/BULK_ACTION_DELETE',
      productTableService.value.getSelectedProductIds() as any,
    );
    productTableService.value.getProducts();
    productTableService.value.checkedProductRows = [];
    toastService.displaySuccessToast(ts.tModule('deleteProduct.successMessage'));
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  }

  loadingProductIds.value = loadingProductIds.value.filter(
    (uuid) => !productTableService.value.getSelectedProductIds().includes(uuid as any),
  );
};

const duplicateProductHandler = async (): Promise<void> => {
  const variantUuids: string[] = productTableService.value.checkedProductRows.map(
    (row: { variant_uuid: string }) => {
      return row.variant_uuid;
    },
  );
  try {
    variantUuids.forEach(async (variantUuid: string): Promise<void> => {
      loadingProductIds.value.push(variantUuid);
      await productsApi.duplicateProduct(variantUuid);

      loadingProductIds.value = loadingProductIds.value.filter(
        (uuid: string) => uuid !== variantUuid,
      );
    });
    productTableService.value.checkedProductRows = [];
    toastService.displaySuccessToast(ts.duplicateSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.duplicateFailed());
  } finally {
    productTableService.value.getProducts();
  }
};

const createProductVariantsVisible = (value: boolean) => {
  createProductVariantsActive.value = value;
};

const detachAllAssetsVisible = (value: boolean) => {
  detachAllAssetsActive.value = value;
};

const shareProductsVisible = (value: boolean) => {
  shareProductsActive.value = value;
};

const assignToClientVisible = (value: boolean) => {
  assignToClientActive.value = value;
};

const massUpdateFieldVisible = (value: boolean) => {
  massUpdateFieldActive.value = value;
};

const massUpdateProductVisible = (value: boolean) => {
  massUpdateProductActive.value = value;
};

const deleteLocaleContentVisible = (value: boolean) => {
  deleteLocaleContentActive.value = value;
};

const exportVisible = async (value: boolean) => {
  exportActive.value = value;

  value ? await router.push({ name: 'export-channel' }) : reRoute();
};

const exportGenericVisible = async (value: boolean) => {
  value ? await router.push({ name: 'export-generic' }) : reRoute();
  exportGenericActive.value = value;
};

const exportTypeVisible = (value: boolean) => {
  exportTypeActive.value = value;

  if (!value) reRoute();
};

const reRoute = async () => {
  await router.push({ name: 'products' } as RouteLocationRaw);
};

const showExportModel = async (value: boolean) => {
  if (!value) {
    await router.push({ name: 'export-channel' });
    await exportVisible(true);
  } else {
    await exportGenericVisible(true);
  }

  exportTypeActive.value = false;
};

const toTypeSelection = async (value: boolean) => {
  isGeneric.value = value;
  value ? (exportGenericActive.value = false) : (exportActive.value = false);

  await router.push({ name: 'products' } as RouteLocationRaw);
  exportTypeVisible(true);
};

const attachToTaskVisible = (value: boolean) => {
  attachTaskVisible.value = value;
};

const bulkAttachCategoryModelVisible = (value: boolean) => {
  bulkAttachCategoryModelActive.value = value;
};

const syncProductDisplayNameVisible = (value: boolean) => {
  syncProductDisplayNameActive.value = value;
};

const syncProductBrandVisible = (value: boolean) => {
  syncProductBrandActive.value = value;
};

const transitionStateModalVisible = (value: boolean) => {
  transitionStateModalActive.value = value;
};

const addTaskModalVisible = () => {
  router.push({ name: 'products-new-task' } as RouteLocationRaw);
};

const lockFieldsVisible = (value: boolean) => {
  lockFieldsActive.value = value;
};

const toggleLoadingValue = () => {
  loading.value = !loading.value;
};

const shareProductsWithRetailer = async (share: boolean) => {
  loading.value = true;
  let noVariantWarning = false;
  try {
    //filter out the products that don't have a variant
    const variantIds = productTableService.value.checkedProductRows
      .filter((product: Product) => {
        if (
          productTableService.value.getSelectedProductIds().includes(product.id) &&
          product.variant_name != null
        ) {
          return product;
        } else if (productTableService.value.getSelectedProductIds().includes(product.id)) {
          noVariantWarning = true;
          return;
        }

        return;
      })
      .map((product: Product) => product.id);

    const data: UpdateSharedStatus = {
      product_ids: variantIds,
    };

    share
      ? await store.dispatch('products/SHARE_WITH_RETAILER', data)
      : await store.dispatch('products/UNSHARE_WITH_RETAILER', data);
    if (!noVariantWarning) {
      toastService.displaySuccessToast(
        ts.tModule(share ? 'share_with_retailer_success' : 'unshare_with_retailer_success', {
          params: {
            amount: productTableService.value.getSelectedProductIds().length,
          },
        }),
      );
    } else {
      ts.tModule(share ? 'share_with_retailer_warn' : 'unshare_with_retailer_warn', {
        params: {
          shared: variantIds.length,
          amount: productTableService.value.getSelectedProductIds().length,
        },
      });
    }

    productTableService.value.getProducts();
    productTableService.value.checkedProductRows = [];
  } catch (err) {
    toastService.displayErrorToast(
      noVariantWarning
        ? ts.tModule('unshare_with_retailer_fail_no_variants')
        : ts.tModule('share_with_retailer_failed'),
    );
  } finally {
    loading.value = false;
  }
};

/** GTIN Publication Modal */
const publicationModalVisible = (status = true) => {
  productService.value.publicationModalActive = status;
};

onMounted(async () => {
  productService.value.generatePublicationButton().then(() => {
    showPublicationButton.value = productService.value.showPublicationMenu;
  });
});
</script>

<template>
  <!-- #region: Modals -->
  <PublicationModal
    :is-active="productService.publicationModalActive"
    :channel="productService.publicationChannel"
    :product-ids="productTableService.getSelectedProductIds()"
    @hide="publicationModalVisible(false)"
  />
  <CreateProductVariantsModal
    v-if="createProductVariantsActive"
    :is-active="createProductVariantsActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="createProductVariantsVisible(false)"
    @close="productTableService.getProducts()"
  />
  <DetachAllAssetsModal
    v-if="detachAllAssetsActive"
    :is-active="detachAllAssetsActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="detachAllAssetsVisible(false)"
  />
  <ShareProductsModal
    v-if="shareProductsActive"
    :is-active="shareProductsActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="shareProductsVisible(false)"
  />
  <LockFieldsModal
    v-if="lockFieldsActive"
    :is-active="lockFieldsActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="lockFieldsVisible(false)"
  />
  <MassUpdateFieldsModal
    v-if="massUpdateFieldActive"
    :is-active="massUpdateFieldActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="massUpdateFieldVisible(false)"
  />
  <MassAttachBrandModal
    v-if="massUpdateProductActive"
    :is-active="massUpdateProductActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="massUpdateProductVisible(false)"
  />
  <AssignToClientModal
    v-if="assignToClientActive"
    :is-active="assignToClientActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="assignToClientVisible(false)"
  />
  <ExportProductsModal
    v-if="exportActive"
    :is-active="exportActive"
    :ids="productTableService.getSelectedProductIds()"
    :checked-rows="productTableService.checkedProductRows"
    :child-routes="exportChildRoutes"
    @hide="exportVisible(false)"
    @to-type-selection="toTypeSelection"
  />
  <ExportProductsModalGeneric
    v-if="exportGenericActive"
    :is-active="exportGenericActive"
    :ids="productTableService.getSelectedProductIds()"
    :checked-rows="productTableService.checkedProductRows"
    :child-routes-generic="exportChildRoutesGeneric"
    @hide="exportGenericVisible(false)"
    @to-type-selection="toTypeSelection"
  />
  <ExportTypeModal
    v-if="exportTypeActive"
    :is-active="exportTypeActive"
    :model-value="isGeneric"
    @update:model-value="showExportModel"
    @hide="exportTypeVisible(false)"
  />
  <MassSyncProductDisplayNameModal
    v-if="syncProductDisplayNameActive"
    :is-active="syncProductDisplayNameActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="syncProductDisplayNameVisible(false)"
  />
  <MassSyncProductBrandModal
    v-if="syncProductBrandActive"
    :is-active="syncProductBrandActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="syncProductBrandVisible(false)"
  />
  <MassDeleteLocaleContentModal
    v-if="deleteLocaleContentActive"
    :is-active="deleteLocaleContentActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="deleteLocaleContentVisible(false)"
  />
  <AddTaskModal :ids="productTableService.getSelectedProductIds()" />
  <TransitionStateModal
    v-if="transitionStateModalActive"
    :is-active="transitionStateModalActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="transitionStateModalActive = false"
  />
  <AttachTaskModal
    :is-active="attachTaskVisible"
    :ids="productTableService.getSelectedProductIds()"
    @hide="attachToTaskVisible(false)"
  />
  <BulkAttachCategoryModal
    :is-active="bulkAttachCategoryModelActive"
    :ids="productTableService.getSelectedProductIds()"
    @hide="bulkAttachCategoryModelVisible(false)"
    @loading="toggleLoadingValue()"
  />

  <pMenubar
    :model="items"
    class="justify-content-end z-2"
    :pt="{
      root: {
        style: 'background-color: inherit;',
      },
    }"
  />
</template>
<style lang="scss" scoped>
.p-menubar:deep(.p-submenu-list) {
  width: 20rem;
}
</style>
