<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product, ProductCategory } from 'platform-unit2-api/products';
import { CategoryTreeItem } from 'platform-unit2-api/categories';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { ProductField } from 'platform-unit2-api/product-fields';
import { EnrichedCategoryTreeItem } from 'supplier/modules/categories/ts/categories.types';
import { LocalesRestService, Locale } from 'platform-unit2-api/locales';
import { CategoryRestService } from 'platform-unit2-api/categories';
import { Defaults } from '@/general/utils/constants/defaults';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const localeAPI = new LocalesRestService();
const categoryAPI = new CategoryRestService();

/** Consts */
const store = useStore();
const router = useRouter();
const route = useRoute();

const loading = ref(false);
let localeDutch: Locale | undefined;

/* Mounted hook */
onMounted(async () => {
  await getTheCurrentProduct();
  if (currentProduct.value?.module_id) {
    await loadAsyncCategories();
    await loadAttachedCategories();
    getFieldData();
  }
});
/** Get the current User */
const currentUser = computed(() => store.getters['users/currentUser']);

/** Get the current product */
const currentProduct = ref<Product>();
const getTheCurrentProduct = async (): Promise<void> => {
  try {
    loading.value = true;
    currentProduct.value = await store.dispatch(
      'products/GET_PRODUCT_DETAILS',
      router.currentRoute.value.params.id,
    );
  } catch {
    toastService.displayErrorToast(ts.getFailed(ts.tGlobal('unknown')));
  } finally {
    loading.value = false;
  }
};

/* Fetching categories */
const nodes = ref([] as EnrichedCategoryTreeItem[]);

const mapTree = (items: CategoryTreeItem[]): EnrichedCategoryTreeItem[] => {
  return items.map((item: CategoryTreeItem) => {
    return {
      id: item.id,
      key: item.id,
      label: item.name,
      parent_id: item.parent_id,
      owner_id: item.owner_id,
      children: mapTree(item.children),
      selectable: item.children.length ? false : true,
    } as EnrichedCategoryTreeItem;
  });
};

const loadAsyncCategories = async (): Promise<void> => {
  try {
    loading.value = true;

    if (currentProduct.value) {
      const categoryTree = await categoryAPI.getChannelCategoryTree(currentProduct.value.module_id);

      nodes.value = mapTree(categoryTree.data);
    }
  } catch {
    toastService.displayErrorToast(ts.getFailed(ts.tGlobal('unknown')));
  } finally {
    loading.value = false;
  }
};

/* Add category to the product */
const selectedNodes = ref<Record<number, boolean>>({});
const selectedCategoryIds = computed<string[]>(() => {
  return Object.keys(selectedNodes.value);
});

const addCategories = async (): Promise<void> => {
  try {
    loading.value = true;
    await store.dispatch('products/ADD_CATEGORIES_TO_PRODUCT', {
      productId: router.currentRoute.value.params.id,
      categories: selectedCategoryIds.value,
    });
    toastService.displaySuccessToast(
      ts.getSuccess(ts.tModule('product_catrgories.categoryAddedSuccessfully')),
    );
  } catch {
    toastService.displayErrorToast(ts.getFailed(ts.tGlobal('unknown')));
  } finally {
    loading.value = false;
    loadAttachedCategories();
  }
};

/* Show attached categories */
const attachedCategories = ref<ProductCategory[]>([]);
const attributeIds = ref<number[]>([]);
const loadAttachedCategories = async (): Promise<void> => {
  try {
    loading.value = true;
    const result = await store.dispatch(
      'products/GET_ATTACHED_CATEGORIES',
      router.currentRoute.value.params.id,
    );

    result.forEach((cat: ProductCategory) => {
      selectedNodes.value[cat.id] = true;
    });
    attachedCategories.value = result;
    //attached attribute ids to use when we want to find the saved values
    attributeIds.value = result.reduce(
      (ids: number[], current: ProductCategory) =>
        current.attributes && [...ids, ...current.attributes.map((attr) => attr.id)],
      [],
    );
  } catch {
    toastService.displayErrorToast(ts.getFailed(ts.tGlobal('unknown')));
  } finally {
    loading.value = false;
  }
};

/*  select attribute options  */
const selectedOptions = ref<Record<string, string[] | string | null>>({});

const updateFieldValue = async (attributeId: number, value: string) => {
  await store.dispatch('products/SET_EDITING_FIELD_DATA', {
    attribute_id: attributeId,
    value: value,
    locale_id: currentUser.value.locale.id,
    overwrite: false,
  });
};

/* get the saved selected options */
const fields = ref<ProductField[]>([]);
const getFieldData = async () => {
  fields.value = [];
  try {
    localeDutch = await (
      await localeAPI.getAll({ query: 'nl-NL', page: 1, limit: Defaults.REQUEST_LIMIT })
    ).data[0];
    loading.value = true;
    fields.value = await store.dispatch('products/GET_PRODUCT_FIELDS', {
      productId: router.currentRoute.value.params.id,
      locales: [localeDutch.id],
    });
    fields.value.forEach((value) => {
      attributeIds.value.includes(value.attribute_id) &&
        (selectedOptions.value[value.attribute_id] = value.value);
    });
  } catch (error) {
    toastService.displayErrorToast(ts.loadFailed(ts.tGlobal('data')));
  } finally {
    loading.value = false;
  }
};

/** if the variant changes */
watch(
  () => route,
  async () => {
    if (router.currentRoute.value.name === 'product-category') {
      await getTheCurrentProduct();
      if (currentProduct.value?.module_id) {
        await loadAsyncCategories();
        await loadAttachedCategories();
        getFieldData();
      }
    }
  },
  {
    deep: true,
  },
);
</script>
<template>
  <div>
    <loading-indicator v-if="loading"></loading-indicator>
    <div v-else>
      <div v-if="currentProduct?.module_id" class="mb-4">
        <span class="block font-bold mb-3 text-xl w-full">{{
          ts.tModule('product_catrgories.add_category')
        }}</span>
        <p-tree-select
          v-model="selectedNodes"
          class="w-20rem"
          :options="nodes"
          :placeholder="ts.tModule('product_catrgories.select_placeholder')"
        ></p-tree-select>
        <p-button
          class="ml-2 p-button-rounded"
          icon="mdi mdi-check"
          :label="ts.tModule('product_catrgories.add_btn')"
          :disabled="!selectedCategoryIds || !selectedCategoryIds.length"
          @click="addCategories"
        ></p-button>
        <p-divider></p-divider>
      </div>
      <!-- Show the attributes -->
      <div
        v-if="currentProduct?.module_id"
        class="border-1 border-200 border-round-xl p-4 surface-50"
      >
        <div v-for="category in attachedCategories" :key="category.id">
          <h3 class="mb-4">{{ category.name }}</h3>
          <div class="flex grid">
            <div v-for="attribute in category.attributes" :key="attribute.id" class="col-3 w-4">
              <span class="font-bold">{{ attribute.key }}</span>
              <div class="mt-3">
                <pSelect
                  v-model="selectedOptions[attribute.id]"
                  :options="attribute.options?.choices"
                  :filter="true"
                  :placeholder="
                    currentUser.workspace.workspace_type?.type !== ClientTypeEnum.RETAILER
                      ? ts.tModule('product_catrgories.select_placeholder')
                      : ts.tModule('product_catrgories.categories_not_filled')
                  "
                  :show-clear="true"
                  class="mb-3 w-full"
                  @change="(event: DropdownChangeEvent) => updateFieldValue(attribute.id, event.value)"
                >
                </pSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
