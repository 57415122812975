import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create brand',
  subTitle: 'Here you can create a new brand.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete brand',
};

const update: TranslationObjectAction = {
  title: 'Edit brand',
  subTitle: 'Here you can edit a brand.',
};

const translationObject: TranslationObject = {
  title: 'Brand | Brands',
  placeholder: 'Select a brand | Select brands',

  create: create,
  delete: deleteTranslation,
  update: update,
};

const translation: Translation = {
  brands: translationObject,
};

export default translation;
