<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import { onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ModuleDetail } from 'platform-unit2-api/modules';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';
import { DashboardRestService } from 'platform-unit2-api/dashboard';

//Services
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'dashboard');
const dashboardApi = new DashboardRestService();

/** Constants */
const modules = ref<ModuleDetail[]>([]);
const loading = ref(true);

const startDataFetching = async () => {
  loading.value = true;
  try {
    modules.value = (await dashboardApi.getDashboardModules()).modules;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    loading.value = false;
  }
};

/** Lifecycle Methods */
onMounted(async () => {
  await startDataFetching();
});
</script>
<template>
  <p-card v-if="loading">
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('userDashboard.moduleCompleteness.title') }}</h4>
      <TableSkeleton />
    </template>
  </p-card>
  <p-card v-else>
    <template #content>
      <h4 class="mb-4 text-left">{{ ts.tModule('userDashboard.moduleCompleteness.title') }}</h4>
      <div v-if="!modules.length">
        <p-message class="w-full" p-message severity="info" :closable="false">
          {{ ts.getEmpty }}
        </p-message>
      </div>
      <div v-else class="h-30rem overflow-y-scroll">
        <div
          v-for="data in modules"
          :key="data.id"
          class="align-items-center cursor-pointer flex flex-row hover:surface-50 mb-3 p-3 w-11"
          @click="
            $router.push({
              name: 'module-products-incomplete',
              params: { id: data.id.toString() },
            })
          "
        >
          <upload :upload="data.retailer?.thumbnail" class="mr-3 w-6rem" />
          <div class="text-left w-full">
            {{ data.name }}
            <ProgressBar
              :progress="data.completeness ? Math.round(data.completeness) : 0"
              class="pup-mt-2"
            />
          </div>
        </div>
      </div>
    </template>
  </p-card>
</template>
<style scoped lang="scss">
.table-wrapper {
  position: relative;
  min-height: 200px;
}
.ui-datatable tr.ui-datatable-even:hover,
.ui-datatable tr.ui-datatable-odd:hover {
  background: #78bcff;
}
.p-progressbar {
  flex-grow: 1;
  height: 0.5rem;
}
</style>
