<script lang="ts" setup>
import { Attribute, AttributeRestService, AttributeTypeEnum } from 'platform-unit2-api/attributes';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

interface Props extends DefaultSelectProps<Attribute> {
  attributesToExclude?: Attribute[];
  showType?: boolean;
  allowedTypesToShow?: AttributeTypeEnum[];
}

const props = defineProps<Props>();
const attributeRestService = AttributeRestService;

const editOptions = (options: Attribute[]) => {
  let newOptions = options;
  if (props.attributesToExclude) {
    newOptions = newOptions.filter(
      (attribute) => !props.attributesToExclude?.map((c) => c.id).includes(attribute.id),
    );
  }

  if (props.allowedTypesToShow) {
    newOptions = newOptions.filter((attribute) =>
      props.allowedTypesToShow?.includes(attribute.options.type ?? AttributeTypeEnum.DEFAULT),
    );
  }

  return newOptions;
};
</script>
<template>
  <BaseSelect
    :rest-service="attributeRestService"
    option-label="key"
    display="chip"
    module-name="attributes"
    :edit-options="(options: Attribute[]) => editOptions(options)"
    v-bind="{ ...$attrs, ...props }"
  >
    <template v-if="multiselect !== true" #value="{ value, placeholder }">
      <div v-if="value && Object.keys(value).length !== 0" class="flex flex-column">
        <p
          class="text-color"
          :class="{
            'font-bold text-color': showType,
          }"
        >
          {{ value.key }}
        </p>
        <p v-if="showType">Type: {{ value.options?.type ?? 'DEFAULT' }}</p>
      </div>
      <span v-else>
        {{ placeholder }}
      </span>
    </template>
    <template #option="{ option }">
      <div class="flex flex-column">
        <p
          class="text-color"
          :class="{
            'font-bold text-color': showType,
          }"
        >
          {{ option.key }}
        </p>
        <p v-if="showType">Type: {{ option.options?.type ?? 'DEFAULT' }}</p>
      </div>
    </template>
  </BaseSelect>
</template>
