import AttributeTranslations from 'retailer/modules/attributes/translation/en';
import DatamodelTranslations from 'retailer/modules/datamodels/translation/en';
import EditorTranslations from 'retailer/modules/editor/translation/en';
import LocaleTranslations from 'retailer/modules/locales/translation/en';
import ModuleTranslations from 'retailer/modules/modules/translation/en';
import SearchTranslations from 'retailer/modules/search/translation/en';
import ModulesTranslations from 'retailer/modules/modules/translation/en';
import ExportTranslations from 'retailer/modules/export/translation/en';
import DashboardTranslations from 'retailer/modules/dashboard/translation/en';
import CategoryTranslations from 'retailer/modules/categories/translation/en';
import ProductTranslations from 'retailer/modules/products/translation/en';
import CommentTranslations from 'retailer/modules/comments/translation/en';
import DownloadTranslations from 'retailer/modules/downloads/translation/en';
import IncompleteProductTranslations from 'retailer/modules/incomplete-products/translation/en';
import TasksTranslations from 'retailer/modules/tasks/translation/en';
import UsersTranslations from 'retailer/modules/users/translation/en';
import ClientsTranslations from 'retailer/modules/clients/translation/en';
import BrandsTranslations from 'retailer/modules/brands/translation/en';
import ActivitiesTranslation from 'retailer/modules/activities/translations/en';
import SettingsTranslation from 'retailer/modules/settings/translation/en';
import MediaTranslations from 'retailer/modules/media/translation/en';
import MappingsTranslations from 'retailer/modules/mappings/translation/en';
import ExportMappingsTranslations from 'retailer/modules/export-mappings/translation/en';
import ImportMappingsTranslations from 'retailer/modules/import-mappings/translation/en';
import ImportMappingItemsTranslations from 'retailer/modules/import-mapping-items/translation/en';
import ImportTranslations from 'retailer/modules/import/translation/en';
import ScheduledImport from 'retailer/modules/import/translation/import-scheduled.en';
import DevelopersTranslations from 'retailer/modules/developers/translations/en';

export default {
  retailer: {
    ...BrandsTranslations,
    ...AttributeTranslations,
    ...DashboardTranslations,
    ...DatamodelTranslations,
    ...EditorTranslations,
    ...LocaleTranslations,
    ...CategoryTranslations,
    ...ProductTranslations,
    ...CommentTranslations,
    ...DownloadTranslations,
    ...IncompleteProductTranslations,
    ...ModuleTranslations,
    ...SearchTranslations,
    ...ModulesTranslations,
    ...ExportTranslations,
    ...TasksTranslations,
    ...UsersTranslations,
    ...ClientsTranslations,
    ...ActivitiesTranslation,
    ...SettingsTranslation,
    ...MediaTranslations,
    ...MappingsTranslations,
    ...ExportMappingsTranslations,
    ...ImportMappingsTranslations,
    ...ImportTranslations,
    ...ScheduledImport,
    ...ImportMappingItemsTranslations,
    ...DevelopersTranslations,
  },
};
