//vue
import { Component, markRaw } from 'vue';

//components
import StandardHeaderImageText from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-header-image-text.vue';

//types
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { IContentImage } from './interfaces/IContentImage.interface';
import { Upload } from 'platform-unit2-api/uploads';
import { HeaderImageText } from '../story-types/modules/header-image-text.interface';

//services
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export default class StandardHeaderImageTextClass
  extends BaseStoryModule<HeaderImageText>
  implements IContentImage<'block1' | undefined>
{
  ts: TranslationService;
  moduleTranslationKey = 'storyBuilder.headerImageTextModuleDisplayName';
  component: Component = markRaw(StandardHeaderImageText);
  minimumImageSize: `${string} : ${string}` = '970 : 600';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';

  private _imageValidatorService: ImageValidatorService;
  constructor(data?: HeaderImageText) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._imageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (this.data?.type == null) {
      this.data.type = ContentStoryModulesEnum.HeaderImageText;
      this.data.headline = { type: ModuleComponentTypes.Text, value: '' };
      this.data.block1 = {
        type: ModuleComponentTypes.ImageTextBlock,
        image: {
          type: ModuleComponentTypes.Image,
          altText: '',
          uploadDestinationId: '',
        },
        headline: { type: ModuleComponentTypes.Text, value: '' },
        body: { type: ModuleComponentTypes.TextArea, value: '' },
      };
    }
  }
  getAltText(selector?: 'block1' | undefined): string | undefined {
    if (!selector) {
      return '';
    }

    return this.data.block1?.image?.altText;
  }
  setAltText(altText?: string | undefined, selector?: 'block1' | undefined): void {
    if (!selector) {
      return;
    }

    this.data.block1 = {
      type: ModuleComponentTypes.ImageTextBlock,
      body: this.data.block1?.body ?? { type: ModuleComponentTypes.TextArea, value: '' },
      headline: this.data.block1?.headline ?? { type: ModuleComponentTypes.Text, value: '' },
      image: {
        type: ModuleComponentTypes.Image,
        altText: altText ?? '',
        uploadDestinationId: this.data.block1?.image?.uploadDestinationId ?? '',
      },
    };
  }

  public getUploadUrl(): string | undefined {
    return this.data.block1?.image?.uploadDestinationId;
  }

  public hasUploadSet(block?: 'block1'): boolean {
    if (!block) {
      return false;
    }

    return this.data[block]?.image?.uploadDestinationId ? true : false;
  }

  public setHeadline(block: 'block1', headlineText?: string) {
    if (headlineText) {
      this.data[block] = {
        type: ModuleComponentTypes.ImageTextBlock,
        image: this.data[block]?.image ?? {
          type: ModuleComponentTypes.Image,
          uploadDestinationId: '',
          altText: '',
        },
        headline: { type: ModuleComponentTypes.Text, value: headlineText },
        body: this.data[block]?.body ?? { type: ModuleComponentTypes.TextArea, value: '' },
      };
    }
  }

  public setUploadData(upload: Upload, block?: 'block1'): void {
    if (!block || !this._imageValidatorService.hasCorrectDimensions(upload)) {
      return;
    }

    this.data[block] = {
      type: ModuleComponentTypes.ImageTextBlock,
      image: {
        type: ModuleComponentTypes.Image,
        altText: this.data.block1?.image?.altText ?? '',
        uploadDestinationId: upload.public_url,
      },
      headline: this.data[block]?.headline ?? { type: ModuleComponentTypes.Text, value: '' },
      body: this.data[block]?.body ?? { type: ModuleComponentTypes.TextArea, value: '' },
    };
  }

  public setMainHeadline(headlineText?: string) {
    if (headlineText) {
      this.data.headline = { type: ModuleComponentTypes.Text, value: headlineText };
    }
  }

  public setTextArea(block: 'block1', textAreaValue: string) {
    this.data[block] = {
      type: ModuleComponentTypes.ImageTextBlock,
      image: this.data[block]?.image ?? {
        type: ModuleComponentTypes.Image,
        uploadDestinationId: '',
        altText: '',
      },
      headline: this.data[block]?.headline ?? { type: ModuleComponentTypes.Text, value: '' },
      body: { type: ModuleComponentTypes.TextArea, value: textAreaValue },
    };
  }
}
