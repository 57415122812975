import { sortByOrderNumber } from '@/general/utils/sortByGtinOrder';
import { ImportState } from '@/platforms/supplier/modules/import/ts/import.types';
import {
  AttachUploadsRequest,
  MatchedProductUploadsResponse,
  MatchProductUpload,
  ProductCompact,
  ProductsRestService,
} from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import { ActionTree } from 'vuex';

const productsApi = new ProductsRestService();

export const actions: ActionTree<ImportState, {}> = {
  async MATCH_PRODUCT_UPLOADS({ commit, getters }, uploads: MatchProductUpload[]) {
    const data = await productsApi.matchProductUploads(uploads);
    const matchedProductUploads = [
      ...getters.matchedProductUploads,
    ] as MatchedProductUploadsResponse[];
    // Merge data
    data.forEach((matchedProductUpload) => {
      const existingUpload = matchedProductUploads.find(
        (upload) => upload.product?.id === matchedProductUpload.product?.id,
      );
      if (existingUpload) {
        // Merge two arrays of thumbnails into a single array, with only unique items
        const thumbnailNamesInExistingUpload = existingUpload.thumbnails.map(
          (thumbnail) => thumbnail.filename,
        );
        existingUpload.thumbnails = sortByOrderNumber([
          ...existingUpload.thumbnails,
          ...matchedProductUpload.thumbnails.filter(
            (thumbnail: Upload) => !thumbnailNamesInExistingUpload.includes(thumbnail.filename),
          ),
        ]);
      } else {
        matchedProductUpload.thumbnails = sortByOrderNumber(matchedProductUpload.thumbnails);
        matchedProductUploads.push(matchedProductUpload);
      }
    });
    commit('setMatchedProductUploads', matchedProductUploads);
  },

  CONNECT_UPLOADS_TO_PRODUCT(
    { getters, commit },
    { product, uploads }: { product: ProductCompact; uploads: Upload[] },
  ) {
    // Clone matched product uploads
    const matchedProductUploads = [
      ...getters.matchedProductUploads,
    ] as MatchedProductUploadsResponse[];

    // Add new thumbnails to correct (new or existing) product
    const productUpload = matchedProductUploads.find(
      (matchedProductUpload) => matchedProductUpload.product?.id === product.id,
    );

    if (productUpload) {
      const thumbnailNamesInExistingUpload = productUpload.thumbnails.map(
        (thumbnail) => thumbnail.filename,
      );
      productUpload.thumbnails.push(
        ...uploads.filter(
          (thumbnail) => !thumbnailNamesInExistingUpload.includes(thumbnail.filename),
        ),
      );

      productUpload.thumbnails = sortByOrderNumber(productUpload.thumbnails);
    } else {
      matchedProductUploads.push({
        value: product.id.toString(), // Should be gtin, but thats not availabe on Product, so using id
        product,
        thumbnails: sortByOrderNumber(uploads),
      });
    }

    // Remove thumbnails from unmatched product
    const unmatchedProductUpload = matchedProductUploads.find(
      (matchedProductUpload) => matchedProductUpload.product == null,
    );

    if (unmatchedProductUpload) {
      const uploadIds = uploads.map((upload) => upload.id);
      unmatchedProductUpload.thumbnails = unmatchedProductUpload.thumbnails.filter(
        (thumbnail) => !uploadIds.includes(thumbnail.id),
      );
    }

    // Save new matchedProductUploads
    commit('setMatchedProductUploads', matchedProductUploads);
  },

  async ATTACH_UPLOADS(
    { commit, getters },
    input: { overwrite: boolean; updateVariants: boolean },
  ) {
    const attachUploads: AttachUploadsRequest[] = [];
    (getters.matchedProductUploads as MatchedProductUploadsResponse[]).forEach(
      (matchedProductUpload) => {
        if (matchedProductUpload.product != null) {
          attachUploads.push({
            product_id: matchedProductUpload.product?.id,
            uploads: matchedProductUpload.thumbnails.map((thumbnail) => thumbnail.id),
          });
        }
      },
    );

    await productsApi.attachProductUploads(attachUploads, input.overwrite, input.updateVariants);
    commit('setMatchedProductUploads', []);
  },

  async CLEAR_UPLOADS({ commit }) {
    commit('setMatchedProductUploads', []);
  },

  REMOVE_MATCHED_UPLOAD(
    { commit, getters },
    { product, upload }: { product?: ProductCompact; upload: Upload },
  ) {
    // Clone matched product uploads
    const matchedProductUploads = [
      ...getters.matchedProductUploads,
    ] as MatchedProductUploadsResponse[];
    const foundProductUpload = matchedProductUploads.find(
      (productUpload) => productUpload.product?.id === product?.id,
    );
    if (foundProductUpload) {
      foundProductUpload.thumbnails = foundProductUpload.thumbnails.filter(
        (thumbnail) => thumbnail.id !== upload.id,
      );
      // Filter product that no longer have thumbnails
      const newMatchedProductUploads = matchedProductUploads.filter(
        (productUpload) => productUpload.thumbnails.length > 0,
      );
      // Save new matchedProductUploads
      commit('setMatchedProductUploads', newMatchedProductUploads);
    }
  },

  REMOVE_MATCHED_UPLOADS({ commit, getters }, uploadIds: number[]) {
    // Clone matched product uploads
    const matchedProductUploads = [
      ...getters.matchedProductUploads,
    ] as MatchedProductUploadsResponse[];

    const foundProductUpload = matchedProductUploads.find(
      (productUpload) => productUpload.product == null,
    );

    if (foundProductUpload) {
      foundProductUpload.thumbnails = foundProductUpload.thumbnails.filter(
        (thumbnail) => !uploadIds.includes(thumbnail.id),
      );
      // Filter product that no longer have thumbnails
      const newMatchedProductUploads = matchedProductUploads.filter(
        (productUpload) => productUpload.thumbnails.length > 0,
      );
      // Save new matchedProductUploads
      commit('setMatchedProductUploads', newMatchedProductUploads);
    }
  },

  MOVE_MATCHED_UPLOAD(
    { commit, getters },
    {
      product,
      fromIndex,
      toIndex,
    }: { product: ProductCompact; fromIndex: number; toIndex: number },
  ) {
    const matchedProductUploads = [
      ...getters.matchedProductUploads,
    ] as MatchedProductUploadsResponse[];
    const foundProductUpload = matchedProductUploads.find(
      (productUpload) => productUpload.product?.id === product?.id,
    );

    if (
      !foundProductUpload ||
      !foundProductUpload.thumbnails[fromIndex] ||
      !foundProductUpload.thumbnails[toIndex]
    ) {
      return;
    }

    // Remove item
    const item = foundProductUpload.thumbnails.splice(fromIndex, 1)[0];
    // Add item at different index
    foundProductUpload.thumbnails.splice(toIndex, 0, item);
    // Save new matchedProductUploads
    commit('setMatchedProductUploads', matchedProductUploads);
  },
};
