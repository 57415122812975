import { PromptGroup, Prompt, PromptRestService } from 'platform-unit2-api/prompts';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { OverviewBase } from '../interfaces/overview-base.interface';
import { RefetchService } from '../refetch.service';

export class PromptViewService extends RefetchService implements OverviewBase {
  private _ts: TranslationService;
  private _isLoadingOverview = true;
  private _isInitialized = false;
  private _toastService: ToastService;
  private _promptService: PromptRestService;
  private _prompts: PromptGroup[] = [];

  public get prompts(): PromptGroup[] {
    return this._prompts;
  }

  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  public get isLoadingCrudComponent(): boolean {
    return false;
  }

  public get isLoadingOverView(): boolean {
    return this._isLoadingOverview;
  }

  constructor(ts: TranslationService) {
    super();
    this._ts = ts;

    this._toastService = ToastService.getInstance();
    this._promptService = new PromptRestService();

    this.refetch = this.fetchAll;
  }

  hasError(_field: string): boolean {
    return false;
  }

  public resolveCrudComponent(): void {
    return;
  }

  public fetchAll(): void {
    this._isLoadingOverview = true;

    this._promptService
      .getPrompts(this._paginationParams)
      .then((_result) => {
        const promptGroup = [];

        const keys = Object.keys(_result.data);
        for (const key of keys) {
          promptGroup.push({
            prompt: key,
            data: (_result.data as any)[key],
          });
        }

        this._prompts = promptGroup;
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._prompts = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this._isInitialized = true;
      });
  }

  public getChatPrompts(data: PromptGroup): Prompt[] {
    return data.data.filter((prompt) => prompt.type === 'chat');
  }

  public getUploadPrompts(data: PromptGroup): Prompt[] {
    return data.data.filter((prompt) => prompt.type === 'generatedPhotos');
  }
}
