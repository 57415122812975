import { GetterTree } from 'vuex';
import { StoryManagerVuexState } from './story-manager.state';
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';

export const getters: GetterTree<StoryManagerVuexState, {}> = {
  loading(state: StoryManagerVuexState): boolean {
    return state.loading;
  },

  activeStoryInStoryBuilder(state: StoryManagerVuexState): ContentStory | undefined {
    return state.activeStoryInStoryBuilder;
  },

  changesSaved(state: StoryManagerVuexState): boolean {
    return state.changesSaved;
  },
};
