import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import Editor from 'supplier/modules/editor/views/editor.view.vue';
import StoryManager from 'supplier/modules/story-manager/views/story-manager.view.vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';
import StoryBuilder from 'supplier/modules/story-manager/views/story-builder.view.vue';
import PublishStory from 'supplier/modules/story-manager/views/publish-story.view.vue';
import StoryBuilderPage from 'supplier/modules/story-manager/components/story-builder/story-builder-page.vue';
import CustomStoryBuilderHeader from 'supplier/modules/story-manager/components/story-builder/custom-story-builder-header.vue';
import { RouterService } from '@/core/router/router.service';
import JsonSchemasView from '../../json-schemas/views/json-schemas.view.vue';
const service = FeatureFlagService.getInstance();
const isStorymanagerEnabled = await service.isOn(featureFlagEnum.STORY_MANAGER);

const factory = new RouteFactory({
  platform: 'supplier',
  navigationGroup: 'tools',
  tabs: isStorymanagerEnabled
    ? [
        {
          label: 'HTML Editor',
          key: 'editor',
          command: () => {
            RouterService.getInstance().router.push({ name: 'editor' });
          },
        },
        {
          label: 'Story manager',
          key: 'story-manager',
          command: () => {
            RouterService.getInstance().router.push({ name: 'story-manager' });
          },
        },
        {
          label: 'JSON schema library',
          key: 'json-schemas',
          command: () => {
            RouterService.getInstance().router.push({ name: 'json-schemas' });
          },
        },
      ]
    : [
        {
          label: 'HTML Editor',
          key: 'editor',
          command: () => {
            RouterService.getInstance().router.push({ name: 'editor' });
          },
        },
        {
          label: 'JSON schema library',
          key: 'json-schemas',
          command: () => {
            RouterService.getInstance().router.push({ name: 'json-schemas' });
          },
        },
      ],
});

const contentBuilderFactory = new RouteFactory({
  platform: 'supplier',
  tabs: [
    {
      label: 'Story builder',
      key: 'story-builder-build',
      command: () => {
        RouterService.getInstance().router.push({ name: 'story-builder-build' });
      },
    },
    {
      label: 'Link & publish',
      key: 'link-and-publish',
      command: () => {
        RouterService.getInstance().router.push({ name: 'link-and-publish' });
      },
    },
  ],
  navigationGroup: 'tools',
});

async function toolRoutes(): Promise<RouteRecordRaw[]> {
  const toolRoutes: RouteRecordRaw[] = [
    factory.createAppRoute({
      path: '/tools/editor',
      name: 'editor',
      props: {
        header: {
          createButtonText: 'Insert HTML',
        },
      },
      component: Editor,
      translations: {
        module: 'editor',
      },
      children: [
        factory.createChildRoute({
          path: 'new',
          name: 'new-html-editor',
        }),
      ],
    }),
    factory.createAppRoute({
      path: '/tools/json-schemas',
      name: 'json-schemas',
      props: {
        header: {
          createButtonText: 'Retrieve JSON Schema',
        },
      },
      component: JsonSchemasView,
      translations: {
        module: 'jsonSchemas',
      },
      children: [
        factory.createChildRoute({
          path: 'new',
          name: 'new-schema',
        }),
        factory.createChildRoute({
          path: ':id',
          name: 'edit-schema',
        }),
      ],
    }),
  ];

  if (isStorymanagerEnabled) {
    toolRoutes.push(
      factory.createAppRoute({
        path: '/tools/story-manager',
        name: 'story-manager',
        component: StoryManager,
        translations: {
          module: 'storyManager',
          platform: 'supplier',
        },
        title: 'Tools',
        children: [
          factory.createChildRoute({
            path: 'new',
            name: 'new-story',
            component: StoryManager,
          }),
        ],
      }),

      contentBuilderFactory.createAppRoute({
        path: '/tools/story-manager/builder/:id',
        name: 'story-builder',
        component: StoryBuilder,
        header: CustomStoryBuilderHeader,
        footer: undefined,
        title: 'Story Builder',
        children: [
          contentBuilderFactory.createChildRoute({
            name: 'story-builder-build',
            path: 'build',
            component: StoryBuilderPage,
            title: 'StoryBuilder',
          }),
          contentBuilderFactory.createChildRoute({
            name: 'link-and-publish',
            path: 'link-and-publish',
            component: PublishStory,
            title: 'Link and publish',
          }),
        ],
      }),
    );
  }

  return toolRoutes;
}

export default toolRoutes;
