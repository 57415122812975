<script setup lang="ts">
//UI
import OverView from 'ui/views/over-view/over-view.vue';
import StoryManagerOverviewTable from '../components/story-manager/story-manager-overview-table.vue';

//Keys
import { tsKey } from '@/general/services/translations/translation.key';
import { serviceKey } from '../ts/helpers/story-manager-keys';

//Vue
import { ref, provide, Ref } from 'vue';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { SupplierStoryManagerOverviewService } from '../services/story-manager-overview.service';
import OverViewDialog from '@/general/ui/components/over-view/over-view-dialog.vue';
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';
import StoryManagerOverviewDialogSkeleton from '@/general/ui/components/skeletons/customs/story-manager-overview-dialog-skeleton.vue';

const ts = ref(new TranslationService('supplier', 'storyManager'));
const service = ref(new SupplierStoryManagerOverviewService());

provide(serviceKey, service as Ref<SupplierStoryManagerOverviewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="supplier" module-name="storyManager" :service="service" :has-search="false">
    <template #content>
      <StoryManagerOverviewTable />
    </template>
    <template #crudComponent>
      <OverViewDialog
        :service="service"
        :has-subtitle="false"
        size="small"
        :overwrite-call-to-action-label="
          service.isCreating ? ts.tGlobal('create') : ts.tGlobal('rename')
        "
        :title="
          service.isCreating ? ts.tModule('overwriteCreateTile') : ts.tModule('overwriteUpdateTile')
        "
      >
        <template #form="{ data }: { data: Partial<ContentStory> }">
          <div class="px-2">
            <label for="story_name" class="mr-2 text-800 text-sm">{{
              ts.tModule('storyName')
            }}</label>
            <pInputText
              id="story_name"
              v-model="data.story_name"
              autofocus
              :placeholder="ts.tModule('storyName')"
              class="w-full"
            />
          </div>
        </template>
        <template #skeleton>
          <StoryManagerOverviewDialogSkeleton :width-in-pixels="500" />
        </template>
      </OverViewDialog>
    </template>
    <template #others>
      <p-confirm-dialog :group="service.confirmPopUpGroup" />
      <p-confirm-dialog group="dirty-dialog" />
    </template>
  </OverView>
</template>
<style lang="scss" scoped></style>
