import useStorage from 'utils/storage';

export class AdminModeToggleService {
  private _urlMap = new Map<string, string>([
    ['localhost', 'http://localhost:8088'],
    ['dev.worldofcontent.com', 'https://dev-retail-admin.productsup.dev'],
    ['staging.worldofcontent.com', 'https://staging-retail-admin.productsup.dev'],
    ['retail.productsup.com', 'https://retail-admin.productsup.com/'],
  ]);

  public async toggleAdminMode(status: boolean): Promise<void> {
    this.switchToAdminPanel(status);
  }

  private _storeWorkspaceAdminStatus(status: boolean): void {
    const storage = useStorage();
    const workspace = storage.getStorageItem('workspace');
    if (workspace) {
      workspace.admin = status;
      storage.setStorageItem('workspace', workspace);
    }
  }

  public switchToAdminPanel(status: boolean): void {
    if (!status) {
      this.toggleAdminMode(false);
      return;
    }

    const url = this._urlMap.get(window.location.hostname);

    if (!url) return;

    this._storeWorkspaceAdminStatus(status);

    window.location.href = url;
  }
}
