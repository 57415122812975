import { UpdateProductField } from 'platform-unit2-api/products';
import { cloneDeep } from 'lodash';
import { AdvancedFieldService } from '../advanced-field.service';
import { AdvancedFieldPanel } from '../interfaces/advanced-field-tab.interface';

export class TabFieldService extends AdvancedFieldService {
  private _isCreatingTab = false;

  public tempHeader = new Map<{ localeId: number; path: string | null }, string>();

  public get isCreatingTab(): boolean {
    return this._isCreatingTab;
  }

  public flushTempHeaders() {
    this.flushing = true;

    this.tempHeader.forEach((value, key) => {
      const tabs = this.panels.get(key.localeId);
      if (tabs) {
        const tab = tabs.find((tab) => tab.path === key.path);

        if (tab) {
          tab.header = value;
        }

        this.panels.set(key.localeId, tabs);
        this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(key.localeId));
        this._fieldFunctions?.handleDirtyState?.(
          this.attributeField,
          this.createPayload(key.localeId),
        );
      }
    });

    this.tempHeader.clear();
    this.flushing = false;
  }

  private flushTabInfo() {
    const values = this._attributeField.values;
    const children = this._attributeField.children;
    this.locales.forEach((locale) => {
      const tabs = this.panels.get(locale.id);

      if (tabs) {
        const localeValue = values?.find((values) => values.locale?.id === locale?.id);

        if (localeValue) {
          localeValue.value = tabs.filter((tabs) => !tabs.deleted).map((tab) => tab.header ?? '');
          values?.map((value) => {
            if (value.locale.id === locale.id) {
              value.value = localeValue.value;
            }
          });
        }

        if (children) {
          const child = children?.find((child) => child.locale?.id === locale.id);
          if (child) {
            child.instances = tabs.map((tab) => tab.attributes);
            children[children.indexOf(child)] = child;
          }
        }
      }
    });

    this._attributeField.values = values;
    this._attributeField.children = children;
  }

  public changeTheIndexInTabMap(localeId: number, from: number, to: number) {
    this.reordered = true;
    this.flushTempHeaders();

    const tabs = this.panels.get(localeId);

    if (tabs) {
      const paths = tabs.filter((tab) => !tab.deleted).map((tab) => tab.path);

      const sourcePath = paths[from];
      const targetPath = paths[to];

      const sourceIndex = tabs.findIndex((tab) => tab.path === sourcePath);
      const targetIndex = tabs.findIndex((tab) => tab.path === targetPath);

      const newTabs = [...tabs];
      const [removed] = newTabs.splice(sourceIndex, 1);
      newTabs.splice(targetIndex, 0, removed);
      this.panels.set(localeId, newTabs);
    }

    if (localeId != null) {
      this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(localeId));
      this._fieldFunctions?.handleDirtyState?.(this.attributeField, this.createPayload(localeId));
    }
  }

  public deleteTabConfirm(localeId: number, path: string | null, accept: any) {
    const tabValue = this.panels.get(localeId)?.find((tab) => tab.path == path)?.header;

    this._confirmService.confirmDelete({
      group: this._confirmGroup,
      message: tabValue
        ? this.ts.t('actions.delete.tabSpecific', { params: { tab: tabValue } })
        : this.ts.t('actions.delete.tab'),
      callback: () => {
        this._deleteTabChild(localeId, path);
        accept();
      },
    });
  }

  protected _deleteTabChild(localeId: number, path: string | null) {
    const tab = this.panels.get(localeId)?.find((tab) => tab.path === path);

    if (tab) {
      tab.deleted = true;
      const tabs = this.panels.get(localeId);

      if (tabs?.indexOf(tab)) {
        tabs[tabs.indexOf(tab)] = tab;
        this.panels.set(localeId, tabs);
      }
    }

    this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(localeId));
    this._fieldFunctions?.handleDirtyState?.(this.attributeField, this.createPayload(localeId));
  }

  public async addTab(localeId: number) {
    this._isCreatingTab = true;

    this.flushTempHeaders();
    await this.addNewPreset(localeId);

    this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(localeId));
    this._fieldFunctions?.handleDirtyState?.(this.attributeField, this.createPayload(localeId));
    this._isCreatingTab = false;
  }

  public duplicateTabConfirm(localeId: number, index: number) {
    const targetTab = this.panels.get(localeId)?.filter((tab) => !tab.deleted)?.[index];
    const tablength = this.panels.get(localeId)?.length ?? 0;

    if (targetTab) {
      const duplicateTab: AdvancedFieldPanel = cloneDeep(targetTab);

      duplicateTab.header = duplicateTab.header + ' - (copy)';

      const newPath = this._parentPath?.nextLevel()?.increaseLevel(tablength);

      duplicateTab.path = newPath?.path;

      duplicateTab.attributes = duplicateTab.attributes.map((attribute) => {
        return this.updatePathRecursive(attribute, localeId, newPath);
      });

      this.panels.get(localeId)?.push(duplicateTab);
    }

    this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(localeId));
    this._fieldFunctions?.handleDirtyState?.(this.attributeField, this.createPayload(localeId));
  }

  override createPayload(localeId: number): UpdateProductField {
    const localeTabs = this.panels.get(localeId);
    const headers = localeTabs?.filter((tab) => !tab.deleted).map((tab) => tab.header) ?? [];
    this.handleReorderedAndDeletedPaths(localeId);
    this.flushTabInfo();

    return {
      attribute_id: this._attributeField.attribute.id,
      locale_id: localeId,
      delete_paths: this.deletedPaths,
      overwrite: false,
      value: headers,
      path: this._attributeField?.path,
      reorder_paths: this.reorderPaths,
    };
  }
}
