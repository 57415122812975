<script lang="ts" setup>
import BaseSelect from './base-select.vue';
import { Client, ClientCompact, ClientsRestService } from 'platform-unit2-api/clients';
import { DefaultSelectPropsWithCompact } from './ts/default-select-props.interface';
import { ProductsRestService } from 'platform-unit2-api/products';

interface Props extends DefaultSelectPropsWithCompact<Client, ClientCompact> {
  clientsToExclude?: Client[] | ClientCompact[];
  options?: Client[] | ClientCompact[]; // only show these options
  onlyUsedByProducts?: boolean;
}

const props = defineProps<Props>();

const restService: any = props.onlyUsedByProducts ? ProductsRestService : ClientsRestService;
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    :fetch-function-name="onlyUsedByProducts ? 'searchProductsOwners' : 'getAll'"
    option-label="name"
    display="chip"
    module-name="workspace"
    :edit-options="(clients: Client[]) => clients.filter(client => !props.clientsToExclude?.map((c) => c.id).includes(client.id))"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
