import { PreferencesService } from '@/general/services/preferences/preferences.service';
import { ApplicationTaskService } from '../application.task.service';
import { UserRestService } from 'platform-unit2-api';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { RouterService } from '@/core/router/router.service';
import { KeycloakService } from '@/core/security/keycloak.service';
import { AdminModeToggleService } from '@/general/services/admin-mode-toggle.service';

export class fetchCurrentUserTask extends ApplicationTaskService {
  private _userService: UserRestService = new UserRestService();

  public async handle(): Promise<void> {
    if (!KeycloakService.getInstance().isAuthenticated()) {
      this._complete();
      return;
    }

    const user = await this._userService.getCurrentUser(true);

    if (!user) {
      return;
    }

    if (user.admin_mode) {
      new AdminModeToggleService().switchToAdminPanel(true);
    }

    new PreferencesService();
    FeatureFlagService.getInstance().setUser(user);
    RouterService.getInstance().currentUser = user;

    this._complete();
  }

  constructor() {
    super('getMe');
  }
}
