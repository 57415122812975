export interface DispatchResult {
  passed: boolean;
}

export interface DispatchReject extends DispatchResult {
  message?: string;
  response: { data: any };
}

export interface DispatchSuccess<T> extends DispatchResult {
  data?: T;
}

export interface DispatchData<T> {
  parameters?: any;
  pagination?: boolean;
  page?: number | string;
  perPage?: number | string;
  toast?: {
    displaySetting?: DisplayToastSetting;
    successMessage?: string;
    rejectMessage?: string;
  };
  successCallback?(success: DispatchSuccess<T>): void;
  rejectCallback?(error: DispatchReject): void;
}

export enum DisplayToastSetting {
  BOTH = 0,
  SUCCESS_ONLY = 1,
  REJECT_ONLY = 2,
  NONE = 3,
}
