<script setup lang="ts">
import { GroupFieldService } from '@/general/services/attribute-fields/field-services/group-field.service';
import { AdvancedFieldPanel } from '@/general/services/attribute-fields/interfaces/advanced-field-tab.interface';
import { LocaleDetails } from 'platform-unit2-api/locales';
import { TranslationService } from '@/general/services/translations/translation.service';
/** Props */
export interface Props {
  groupService: GroupFieldService;
  item: AdvancedFieldPanel;
  locale: LocaleDetails;
  disabled: boolean;
  relative: boolean;
}

defineProps<Props>();

const ts = new TranslationService('general', 'components');
</script>

<template>
  <div
    v-if="Boolean(groupService.attributeField.attribute.options.multiple)"
    v-tooltip.bottom="{
      value: ts.tModule('groupField.resetAndDeleteDisabled'),
      disabled: !disabled,
    }"
    class="flex"
    :class="{
      'w-2rem ': disabled,
      'h-2rem': disabled,
      'absolute right-0 top-0': relative,
    }"
  >
    <pButton
      v-if="(groupService.panels.get(locale.id) ?? []).filter((item) => !item.deleted).length > 1"
      text
      plain
      :disabled="disabled"
      icon="mdi mdi-delete-outline"
      severity="danger"
      :class="{
        'absolute right-0 top-0': relative,
      }"
      @click.stop="groupService.deleteGroupConfirm(locale.id!, item.path, () => {})"
    />
    <pButton
      v-else
      text
      plain
      :disabled="disabled"
      icon="mdi mdi-close"
      :class="{
        'absolute right-0 top-0': relative,
      }"
      @click.stop="groupService.resetGroupConfirm(locale.id!, item.path, () => {})"
    />
  </div>
</template>
