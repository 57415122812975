<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
interface Props {
  isGeneric: boolean;
}
defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (e: 'export-type', exportTypeIsGeneric: boolean): void;
}>();

/** Consts */
const ts = new TranslationService('retailer', 'products');
</script>
<template>
  <div>
    <div class="px-4">
      <div class="field mb-4">
        <div class="mb-5">
          <div class="block mb-2 text-center">
            <h3 class="text-4xl">
              {{ ts.tModule('export_modal.header') }}
            </h3>
          </div>
          <div class="block my-2 text-center">
            <p class="font-normal text-gray-700 text-xl">
              {{ ts.tModule('export_modal.type.type_question') }}
            </p>
          </div>
        </div>
        <div
          class="border-1 border-blue-600 border-round-md cursor-pointer flex hover:surface-50 justify-content-start mb-3 p-3"
          :class="!isGeneric ? 'border-gray-400' : 'border-primary'"
          @click="emit('export-type', true)"
        >
          <div class="w-3rem">
            <i
              class="mdi text-4xl"
              :class="
                isGeneric ? 'mdi-circle-slice-8 text-primary' : 'mdi-checkbox-blank-circle-outline'
              "
            ></i>
          </div>
          <div class="pl-2">
            <p class="block font-bold py-2 text-xl w-11" :class="isGeneric ? 'text-primary' : ''">
              {{ ts.tModule('export_modal.type.data_dump') }}
            </p>
            <p class="block">{{ ts.tModule('export_modal.type.generic_export_desc') }}</p>
          </div>
        </div>
        <div
          class="border-1 border-round-md cursor-pointer flex hover:surface-50 justify-content-start p-3"
          :class="isGeneric ? 'border-gray-300' : 'border-primary'"
          @click="emit('export-type', false)"
        >
          <div class="w-3rem">
            <i
              class="mdi text-4xl"
              :class="
                !isGeneric ? 'mdi-circle-slice-8 text-primary' : 'mdi-checkbox-blank-circle-outline'
              "
            ></i>
          </div>
          <div class="pl-2">
            <p class="block font-bold py-2 text-xl w-11" :class="!isGeneric ? 'text-primary' : ''">
              {{ ts.tModule('export_modal.type.mapping_export') }}
            </p>
            <p class="block">{{ ts.tModule('export_modal.type.mapping_export_desc') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
