import { InjectionKey, Ref } from 'vue';
import { ProductAttributeFieldsService } from './product-attribute-fields.service';
import { ProductAttributesValidationService } from './product-attribute-validation.service';

export const productAttributeFieldsServiceKey = Symbol() as InjectionKey<
  Ref<ProductAttributeFieldsService>
>;

export const productAttributeValidationServiceKey = Symbol() as InjectionKey<
  Ref<ProductAttributesValidationService>
>;
