import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create attribute',
  subTitle: 'Here you can create a new attribute.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete attribute',
};

const update: TranslationObjectAction = {
  title: 'Edit attribute',
  subTitle: 'Here you can edit an attribute.',
};

const translationObject: TranslationObject = {
  title: 'Attribute | Attributes',
  placeholder: 'Select an attribute | Select attributes',

  create: create,
  delete: deleteTranslation,
  update: update,
};

const translation: Translation = {
  attributes: translationObject,
};

export default translation;
