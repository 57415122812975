<script setup lang="ts">
import { inject } from 'vue';
import { importMappinsSettingServiceKey } from '../ts/keys/import-mapping-items.keys';
import { tsKey } from '@/general/services/translations/translation.key';

const ts = inject(tsKey)!;
const service = inject(importMappinsSettingServiceKey)!;
</script>
<template>
  <h3 class="font-bold mb-1 text-2xl">{{ ts.tModule('pathDefinition') }}</h3>

  <section class="w-5">
    <div class="field mb-3">
      <label class="font-bold">{{ ts.tModule('regex') }}</label>
      <i
        v-tooltip.right="{ value: ts.tModule('regexExplained') }"
        class="mdi mdi-help-circle mx-1"
      ></i>

      <pSelect
        v-model="service.selectedRegex"
        show-clear
        option-label="label"
        class="w-full"
        :options="service.regexOptions"
        placeholder="Select a regex"
      />
    </div>
    <div v-if="service.customRegex" class="field mb-3">
      <label class="font-bold">{{ ts.tModule('regex') }}</label>

      <pInputText
        v-model="service.partialObject.regex"
        class="w-full"
        :class="{ 'p-invalid': service.hasError('regex') }"
        type="text"
      />

      <small
        v-if="service.hasError('regex')"
        :class="{ 'p-error block': service.hasError('regex') }"
        class="hidden"
      >
        {{ service.fieldErrorMessage('regex').toString() }}
      </small>
    </div>

    <div class="field mb-3">
      <label class="font-bold">{{ ts.tModule('delimiter') }}</label>
      <i
        v-tooltip.right="{ value: ts.tModule('delimiterExplained') }"
        class="mdi mdi-help-circle mx-1"
      ></i>

      <pInputText
        v-model="service.partialObject.delimiter"
        class="w-full"
        :class="{ 'p-invalid': service.hasError('delimiter') }"
        type="text"
      />
      <small
        v-if="service.hasError('delimiter')"
        :class="{ 'p-error block': service.hasError('delimiter') }"
        class="hidden"
        >{{ service.fieldErrorMessage('delimiter').toString() }}</small
      >
    </div>

    <pDivider />

    <h3 class="font-bold mb-1 text-2xl">{{ ts.tModule('pathValidator') }}</h3>

    <div class="flex-column">
      <div class="field mb-3">
        <label class="font-bold">{{ ts.tModule('testHeader') }}</label>

        <i
          v-tooltip.right="{ value: ts.tModule('testSourceExplained') }"
          class="mdi mdi-help-circle mx-1"
        ></i>

        <pInputText
          v-model="service.exampleHeader"
          class="w-full"
          type="text"
          :placeholder="ts.tModule('testHeader')"
        />
      </div>

      <div class="mb-5">
        <p class="font-bold">
          {{ ts.tModule('headerResult') }}
          <i
            v-tooltip.right="{ value: ts.tModule('sourceResultExplained') }"
            class="mdi mdi-help-circle mx-1"
          ></i>
        </p>
        <p v-if="!service.exampleHeader" disabled>{{ ts.tModule('fillHeader') }}</p>
        <p v-else>{{ service.headerResult }}</p>
      </div>
      <div>
        <p class="font-bold">{{ ts.tModule('pathResult') }}</p>
        <p v-if="!service.exampleHeader" disabled>{{ ts.tModule('fillHeader') }}</p>
        <p v-else>{{ service.pathResult }}</p>
      </div>
    </div>
  </section>
</template>
