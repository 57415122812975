<script setup lang="ts">
import ProductPreview from 'supplier/modules/products/components/product-preview.vue';
import { Product, ProductsRestService } from 'platform-unit2-api/products';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseSelect from '@/general/ui/components/selects/base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';
import { Defaults } from '@/general/utils/constants/defaults';

/**Props */
interface Props extends DefaultSelectProps<Product> {
  excludes?: number[];
}

const props = withDefaults(defineProps<Props>(), {
  excludes: () => [],
});

const ts = new TranslationService('general', 'components');

const paginationParams = {
  page: 1,
  limit: Defaults.REQUEST_LIMIT,
  query: '',
  sortBy: '',
  variants: ['Master Data'],
} as any;

const productRestService: any = ProductsRestService;
</script>

<template>
  <BaseSelect
    :rest-service="productRestService"
    fetch-function-name="getAll"
    option-label="display_name"
    :filter-fields="['gtin', 'display_name']"
    :placeholder="ts.tGlobal('search')"
    :pagination-params="paginationParams"
    module-name="products"
    :edit-options="(products: Product[]) => products.filter(product => !props.excludes.includes(product.id))"
    v-bind="{ ...props, ...$attrs }"
  >
    <template #option="{ option }">
      <ProductPreview :product="option" />
    </template>
  </BaseSelect>
</template>
