<script setup lang="ts">
import { computed } from 'vue';

/** Props */
export interface Props {
  url?: string;
  username?: string;
  shape?: 'circle' | 'square';
  size?: 'normal' | 'large' | 'xlarge' | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  username: 'User',
  shape: 'circle',
  url: undefined,
  size: 'large',
});

const initials = computed((): string => {
  const userNames = props.username.split(' ');
  const userNameTemplate = (
    userNames[0]?.[0] + (userNames.length > 1 ? userNames[userNames.length - 1][0] : '')
  ).toUpperCase();
  return userNameTemplate !== 'UNDEFINED' ? userNameTemplate : 'U';
});
</script>

<template>
  <pAvatar v-if="url == null" :label="initials" :size="size" :shape="shape" />

  <pAvatar v-else :image="url" :size="size" :shape="shape" />
</template>
<style lang="scss" scoped>
.p-avatar:deep(span) {
  font-weight: bold;
}
</style>
