<script setup lang="ts">
//components
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentImage from '../content-components/content-image.vue';
import ContentTextarea from '../content-components/content-textarea.vue';
import ContentBulletPoints from '../content-components/content-bullet-points.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import StandardImageSidebarClass from 'api/v1/content-story/ts/classes/image-sidebar.class';

interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

// const / refs
const storyModule = storyService?.value.getModule<StandardImageSidebarClass>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid">
    <template #content>
      <div class="flex flex gap-3 h-full justify-content-around p-3">
        <div class="flex flex-column h-full w-3">
          <ContentImage
            :custom-library-button-label="ts.tGlobal('library')"
            custom-container-class="left-photo"
            :minimal-image-size="storyModule?.minimumImageSize[0]"
            :accepted-file-types="storyModule?.acceptedFileTypes"
            :upload-url="storyModule?.getUploadUrl('imageCaptionBlock')"
            :is-upload-set="storyModule?.hasUploadSet('imageCaptionBlock')"
            @uploaded-image="storyModule?.setUploadData($event, 'imageCaptionBlock')"
          />
          <ContentHeadline
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
            :custom-class="'mt-3'"
            :headline="storyModule?.getAltText('imageCaptionBlock')"
            @headline-value="storyModule?.setAltText($event, 'imageCaptionBlock')"
          />
        </div>
        <div class="flex flex-column w-6">
          <ContentHeadline
            :headline="storyModule?.getHeadline('headline')"
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.mainheadline')"
            custom-class="py-3"
            @headline-value="(text) => storyModule?.setHeadline('headline', text)"
          />
          <ContentHeadline
            :headline="storyModule?.getHeadline('descriptionTextBlock')"
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.subheadline')"
            @headline-value="(text) => storyModule?.setHeadline('descriptionTextBlock', text)"
          />
          <ContentTextarea
            :additional-container-style="'80%'"
            :textbox="storyModule?.getTextArea('descriptionTextBlock')"
            @html-text-area="(value) => storyModule?.setTextArea('descriptionTextBlock', value)"
          />
          <ContentBulletPoints selector="descriptionListBlock" :uuid="uuid" />
        </div>
        <div class="flex flex-column h-full w-3">
          <ContentImage
            custom-container-class="right-photo"
            :custom-library-button-label="ts.tGlobal('library')"
            :minimal-image-size="storyModule?.minimumImageSize[1]"
            :accepted-file-types="storyModule?.acceptedFileTypes"
            :upload-url="storyModule?.getUploadUrl('sidebarImageTextBlock')"
            :is-upload-set="storyModule?.hasUploadSet('sidebarImageTextBlock')"
            @uploaded-image="storyModule?.setUploadData($event, 'sidebarImageTextBlock')"
          />
          <ContentHeadline
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
            :custom-class="'mt-3'"
            :headline="storyModule?.getAltText('sidebarImageTextBlock')"
            @headline-value="storyModule?.setAltText($event, 'sidebarImageTextBlock')"
          />
          <ContentHeadline
            :headline="storyModule?.getHeadline('sidebarImageTextBlock')"
            @headline-value="(text) => storyModule?.setHeadline('sidebarImageTextBlock', text)"
          />
          <ContentTextarea
            :additional-container-style="'65%'"
            :textbox="storyModule?.getTextArea('sidebarImageTextBlock')"
            @html-text-area="(value) => storyModule?.setTextArea('sidebarImageTextBlock', value)"
          />
          <ContentBulletPoints selector="sidebarListBlock" override-min-height="25%" :uuid="uuid" />
        </div>
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss">
.left-photo {
  height: 25rem;
}

.right-photo {
  max-height: 20rem;
}
</style>
