import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';
import ComponentsTranslation from 'ui/components/en';
import ViewsTranslation from 'ui/views/en';

const tranlation: Translation = {
  general: {
    ...ComponentsTranslation,
    ...ViewsTranslation,
  },
};

export default tranlation;
