<script setup lang="ts">
import { ref, onMounted } from 'vue';
import useFormValidation from 'composables/form-validation';
import { useStore } from 'vuex';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import {
  OrganisationRestService,
  UpdateOrganisationRequest,
  Organisation,
} from 'platform-unit2-api/organisations';
import { DirtyStateService } from '@/general/services/dirty-state/dirty-state.service';
import { onBeforeRouteLeave } from 'vue-router';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';

/* Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/* Composables */
const store = useStore();
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/* Services */
const ts = new TranslationService('supplier', 'settings');
const toastService = ToastService.getInstance();
const organisationApi = new OrganisationRestService();
const dirtyStateService = new DirtyStateService<Organisation>();

/* Constants */
const loading = ref(true);
const saving = ref(false);
const editorOrganisation = ref<Organisation>();

const loadAsyncData = async (): Promise<void> => {
  loading.value = true;
  resetFormErrors();
  try {
    editorOrganisation.value = await organisationApi.get(
      store.getters['users/currentUser'].workspace?.organisation_id,
    );
    dirtyStateService.changeInitialData(editorOrganisation.value);
    dirtyStateService.changeDataToCompare(editorOrganisation.value);
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.loadFailed());
    });
  } finally {
    loading.value = false;
  }
};

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    if (editorOrganisation.value?.id) {
      saving.value = true;
      const organisation: UpdateOrganisationRequest = {
        id: editorOrganisation.value.id,
        name: editorOrganisation.value?.name,
        description: editorOrganisation.value?.description
          ? editorOrganisation.value.description
          : undefined,
        friendly_payment_reminder: editorOrganisation.value?.friendly_payment_reminder ?? false,
        urgent_payment_reminder: editorOrganisation.value?.urgent_payment_reminder ?? false,
      };

      await organisationApi.update(editorOrganisation.value.id, organisation);

      emit('refresh');
      emit('hide');

      toastService.displaySuccessToast(ts.updateSuccess());
    }
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.updateFailed());
    });
  } finally {
    dirtyStateService.changeInitialData(editorOrganisation.value!);
    saving.value = false;
  }
};

onBeforeRouteLeave((_, __, next) => {
  dirtyStateService.showDirtyDialog(next, undefined, next);
});

/* Lifecycle hooks */
onMounted(async () => {
  await loadAsyncData();
});
</script>

<template>
  <section class="h-full pt-3 px-4">
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <div class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          v-if="editorOrganisation"
          id="name"
          v-model="editorOrganisation.name"
          :class="{ 'p-invalid': hasError('name') }"
          class="w-full"
          type="text"
          :disabled="true"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <div class="field mb-3">
        <label for="description">{{ ts.tForms('description') }}</label>
        <p-textarea
          v-if="editorOrganisation"
          id="description"
          v-model="editorOrganisation.description"
          :class="{ 'p-error block': hasError('description') }"
          class="w-full"
          rows="5"
          cols="60"
        />
        <small
          v-if="hasError('description')"
          :class="{ 'p-error block': hasError('description') }"
          class="hidden"
          >{{ fieldErrorMessage('description').toString() }}</small
        >
      </div>
      <div class="field mb-3">
        <ClientSelect
          v-if="editorOrganisation"
          v-model="editorOrganisation.workspaces"
          multiselect
          disabled
        />
        <small class="block mb-2">{{ ts.tModule('organisation.visitWorkspace') }}</small>
      </div>
      <div class="field mb-3">
        <label for="timeline">{{ ts.tModule('organisation.workflowLabels') }}</label>
        <small class="block">{{ ts.tModule('organisation.labelsDescription') }}</small>
        <p-timeline
          v-if="editorOrganisation"
          id="timeline"
          :value="editorOrganisation.statuses"
          layout="horizontal"
        >
          <template #content="slotProps">
            <p>{{ slotProps.item.label }}</p>
          </template>
          <template #marker="slotProps">
            <div class="p-timeline-event-marker" :style="'border-color: ' + slotProps.item.color" />
          </template>
        </p-timeline>
      </div>

      <div class="col field flex flex-row justify-content-end mt-3">
        <p-button
          :disabled="saving || !dirtyStateService.isTouched()"
          :label="ts.tGlobal('save')"
          :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
          icon-pos="right"
          @click="handleSubmit"
        />
      </div>
    </div>
    <pConfirmDialog group="dirty-dialog" />
  </section>
</template>
