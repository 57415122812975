import { RefetchService } from '../refetch.service';
import { OverviewBase } from '../interfaces/overview-base.interface';
import { SharedRestService, Share } from 'platform-unit2-api/shared';
import { TranslationService } from '../../translations/translation.service';
import { ToastService } from '../../toasts/toast.service';
import { useRoute, useRouter } from 'vue-router';
import { Client } from 'platform-unit2-api/clients';
import { ConfirmService } from '../../confirm/confirm.service';

export class SharedViewService extends RefetchService implements OverviewBase {
  private _restService: SharedRestService;
  public sharedByMe: Share[] = [];
  public sharedWithMe: Share[] = [];
  public crudComponent = false;
  public share?: Share;
  public shareApproveSelectedClient?: Client;
  public isApproveSharedDialogVisible = false;
  public activeId?: number;

  private _isLoadingOverview = false;
  private _isLoadingCrudComponent = false;
  private _isInitialized = false;
  private _toastService: ToastService;
  private _confirmService: ConfirmService;
  public ts: TranslationService;
  private _router = useRouter();
  private _route = useRoute();

  constructor(ts: TranslationService) {
    super();
    this.ts = ts;
    this._restService = new SharedRestService();
    this._toastService = ToastService.getInstance();
    this._confirmService = new ConfirmService();
    this.refetch = this.fetchAll;
  }

  get isSharedByMe() {
    return this._route.name === 'shared-by-me';
  }
  get isInitialized(): boolean {
    return this._isInitialized;
  }
  get isLoadingCrudComponent(): boolean {
    return this._isLoadingCrudComponent;
  }
  get isLoadingOverView(): boolean {
    return this._isLoadingOverview;
  }
  hasError(): boolean {
    return false;
  }
  resolveCrudComponent(): void {}

  fetchAll(): void {
    this._isLoadingOverview = true;
    const fetchFunctionPromise = this.isSharedByMe
      ? this._restService.getShared(this.page ?? 1, this.limit ?? 50)
      : this._restService.getShares(this.page ?? 1, this.limit ?? 50);

    fetchFunctionPromise
      .then((res) => {
        if (this.isSharedByMe) {
          this.sharedByMe = res.data;
        } else {
          this.sharedWithMe = res.data;
        }

        this.total = res.meta?.total;
      })
      .catch(() => {
        this._toastService.displayErrorToast(this.ts.loadFailed());
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this._isInitialized = true;
      });
  }

  public resetPagination() {
    this.page = 1;
  }

  public closeCrudComponent() {
    this.crudComponent = false;
    this.share = undefined;
    this.shareApproveSelectedClient = undefined;
    this._router.push({ name: 'shared-with-me' });
  }

  public goToProducts(ids?: number[]) {
    this._router.push({
      name: 'products',
      query: { productsIds: ids ?? [] },
    });
  }

  //   Shared By Me Functionality
  public copyEans(eans: string[]) {
    navigator.clipboard.writeText(eans.toString()).then(
      () => {
        this._toastService.displaySuccessToast(this.ts.tModule('copyEans.success'));
      },
      () => {
        this._toastService.displayErrorToast(this.ts.tModule('copyEans.failed'));
      },
    );
  }

  // Shared With Me Functionality
  public declineShareConfirm(id: number) {
    this._confirmService.confirmDelete({
      callback: () => this.declineShareHandle(id),
      group: 'share',
      header: this.ts.tModule('decline_modal.title'),
      message: this.ts.tModule('decline_modal.message'),
    });
  }

  public declineShareHandle(id: number) {
    this._restService
      .declineShare(id)
      .then(() => {
        this._toastService.displaySuccessToast(this.ts.tModule('declineShare.success'));
      })
      .catch(() => {
        this._toastService.displayErrorToast(this.ts.tModule('declineShare.failed'));
      })
      .finally(() => {
        this.refetch();
      });
  }

  public toggleApproveSharedDialog(value: boolean, id?: number) {
    this.activeId = id;
    this.isApproveSharedDialogVisible = value;
  }

  public approveShared(id: number) {
    if (this.shareApproveSelectedClient?.id) {
      this._restService
        .approveShare(id, this.shareApproveSelectedClient.id)
        .then(() => {
          this._toastService.displaySuccessToast(this.ts.updateSuccess());
          this.refetch();
        })
        .catch(() => {
          this._toastService.displayErrorToast(this.ts.updateFailed());
        })
        .finally(() => {
          this.shareApproveSelectedClient = undefined;
          this.toggleApproveSharedDialog(false);
        });
    }
  }
}
