<script setup lang="ts">
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

import ODMActions from './odm-actions.vue';
import DatamodelEmptyState from './datamodel-empty-state.vue';
import OdmAttributeDisplay from './odm-attribute-display.vue';
import OdmNameDisplay from './odm-name-display.vue';
import { TranslationService } from '@/general/services/translations/translation.service';

const service = inject(datamodelAttributeServiceKey)!;
const datamodelsConfigTranslations = new TranslationService('supplier', 'datamodelsConfig');
</script>

<template>
  <div :style="{ width: '47%' }" class="flex flex-column pt-3">
    <span class="font-bold text-lg"> {{ service.ts.tModule('predefined_datamodels.title') }}</span>
    <div class="bg-gray-50 flex flex-column h-full my-2">
      <!-- select box -->
      <ODMActions />

      <div class="flex flex-grow-1 mb-6 overflow-y-auto w-full">
        <LoadingIndicator v-if="service.loadingOdm" />

        <!-- No datamodel selected -->
        <DatamodelEmptyState
          v-else-if="!service.selectedODMs.length"
          icon="mdi mdi-playlist-plus"
          :header="service.ts.tModule('datamodel_attributes.adding')"
          :subheader="service.ts.tModule('datamodel_attributes.select_datamodel')"
        />

        <!-- No attributes in the datamodels connected to the workspace -->
        <DatamodelEmptyState
          v-else-if="!service.attributesCount"
          icon="mdi mdi-playlist-plus"
          :header="service.ts.tModule('datamodel_attributes.select_datamodel')"
          :subheader="
            service.ts.tModule('datamodel_attributes.no_attributes_in_selected_datamodel')
          "
        />

        <!-- No attributes found in search query -->
        <DatamodelEmptyState
          v-else-if="
            service.filteredOdmAttributes.length === 0 &&
            service.odmSearchQuery !== '' &&
            service.odmSearchQuery != null
          "
          icon="mdi mdi-playlist-plus"
          :header="datamodelsConfigTranslations.tModule('datamodel_attributes.no_attributes_found')"
          :subheader="
            datamodelsConfigTranslations.tModule(
              'datamodel_attributes.no_attributes_found_description',
            )
          "
        />

        <!-- rendering the datamodels-->
        <p-scroll-panel
          v-else
          class="w-full"
          :pt="{
            barY: {
              class: 'bg-primary',
            },
          }"
        >
          <div v-for="selectedDatamodel in service.filteredOdms" :key="selectedDatamodel.id">
            <OdmNameDisplay :selected-datamodel="selectedDatamodel" />

            <div class="border-gray-200 border-left-1 pl-3">
              <!-- rendering the attributed of the datamodels --->
              <div
                v-for="attr in selectedDatamodel.attributes"
                :key="attr.id"
                class="bg-white border-1 border-gray-200 font-normal my-2 p-2 pl-3 w-full"
              >
                <OdmAttributeDisplay :attribute="attr" />
              </div>
            </div>
          </div>
        </p-scroll-panel>
      </div>
    </div>
  </div>
</template>
