<script setup lang="ts">
import { inject } from 'vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { datamodelServiceKey } from '../../ts/keys/datamodels.keys';
import OverViewDialog from 'ui/components/over-view/over-view-dialog.vue';
import DatamodelForm from './datamodel-form.vue';

const ts = inject(tsKey)!;
const service = inject(datamodelServiceKey)!;
</script>

<template>
  <OverViewDialog
    class="w-4"
    size="small"
    :title="service.isCreating ? ts.moduleCreateTitle : ts.tModule('datamodelRename.title')"
    :sub-title="
      service.isCreating ? ts.moduleCreateSubTitle : ts.tModule('datamodelRename.subtitle')
    "
    :service="service"
  >
    <template #form>
      <DatamodelForm />
    </template>
  </OverViewDialog>
</template>
