import { FeaturePermission } from 'platform-unit2-api/roles';
import Datamodels from 'retailer/modules/datamodels/views/datamodels.view.vue';

import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';

const datamodelRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/datamodels',
    name: 'datamodels',
    translations: {
      module: 'datamodels',
      platform: 'supplier',
    },
    component: Datamodels,
    title: 'Datamodels',
    rights: [FeaturePermission.MANAGE_DATAMODELS],
    children: [
      RouteFactory.createEmptyRoute({
        path: 'new',
        name: 'new-datamodel',
        rights: [FeaturePermission.MANAGE_DATAMODELS],
      }),
      RouteFactory.createEmptyRoute({
        path: ':id/:model?',
        name: 'edit-datamodel',
        rights: [FeaturePermission.MANAGE_DATAMODELS],
      }),
      RouteFactory.createEmptyRoute({
        path: ':id/duplicate',
        name: 'duplicate-datamodel',
        rights: [FeaturePermission.MANAGE_DATAMODELS],
      }),
    ],
  }),
];

export default datamodelRoutes;
