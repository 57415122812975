import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create channel',
  subTitle: 'Here you can create a new channel.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete channel',
};

const update: TranslationObjectAction = {
  title: 'Edit channel',
  subTitle: 'Here you can edit a channel.',
};

const translationObject: TranslationObject = {
  title: 'Channel | Channels',
  placeholder: 'Select a channel | Select channels',

  create: create,
  delete: deleteTranslation,
  update: update,

  empty: 'No channels could be found',
  credential_edit: {
    title: 'Credentials',
    subtitle: 'You can manage your credentials here',
    name: 'Name: ',
    type: 'Type: ',
  },
  channelSelect: {
    label: 'Select a channel | Select the channels',
    placeholder: 'Select a channel | Select the channels',
  },
  amazon: {
    setupSuccess: 'Setup Amazon Integration Succeeded',
    setupFail: 'Setup Amazon Integration Failed',
  },
  table: {
    name: 'Name',
    key: 'Key',
    type: 'Type',
    retailer: 'Retailer',
    template: 'Template',
  },
  fields: {
    type: 'Type',
    key: 'Key',
    name: 'Name',
    pixels: 'pixels',
    retailer: 'Select retailer',
    exportTemplate: 'Export template',
    mediaTemplate: 'Media template',
    mediaInstructions: 'Media Instructions',
    filePerProduct: 'File per product',
    exportResizeEnabled: 'Export resize enabled',
    exportResizeWidth: 'Width',
    exportResizeHeight: 'Height',
    exportResizeFormat: 'Extension',
  },

  emptySearchStateSubTitle:
    'No channels found. Try to change the search query or contact support to add the corresponding channel.',
};

const translation: Translation = {
  modules: translationObject,
};

export default translation;
