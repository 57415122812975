<script lang="ts" setup>
import { Brand, BrandCompact, BrandsRestService } from 'platform-unit2-api/brands';
import Upload from '../upload.vue';
import BaseSelect from './base-select.vue';
import { DefaultSelectPropsWithCompact } from './ts/default-select-props.interface';
import { ProductsRestService } from 'platform-unit2-api/products';

interface Props extends DefaultSelectPropsWithCompact<Brand, BrandCompact> {
  onlyUsedByProducts?: boolean;
}

const props = defineProps<Props>();
const restService: any = props.onlyUsedByProducts ? ProductsRestService : BrandsRestService;
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    :fetch-function-name="props.onlyUsedByProducts ? 'searchProductsBrands' : 'getQueuedBrands'"
    option-label="name"
    module-name="brand"
    v-bind="{ ...$attrs, ...props }"
  >
    <template #option="{ option }">
      <div class="align-items-center flex w-full">
        <Upload class="mr-2 w-3rem" :upload="option?.thumbnail" />
        <div>{{ option?.name ?? '' }}</div>
      </div>
    </template>
  </BaseSelect>
</template>
