import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';

import { UpdateProductField } from 'platform-unit2-api/products';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class SwitchFieldService extends AttributeFieldService<boolean | null> {
  constructor(
    attributeField: AttributeField<boolean | null>,
    productAttributeField: ProductAttributeField<boolean | null>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }

  /*
   * Backend expects a string representation of bthe boolean value or null
   */
  protected createPayload(): UpdateProductField {
    return {
      attribute_id: this._productAttributeField.attribute_id,
      value: this._productAttributeField.value?.toString() ?? null,
      locale_id: this._productAttributeField.locale.id,
      path: this._productAttributeField?.path ?? null,
      overwrite: false,
    };
  }

  get productAttributeFieldValue(): boolean | null {
    return this._productAttributeField.value;
  }
  set productAttributeFieldValue(value: boolean | null) {
    this._productAttributeField.value = value;
    this.updateValue(value);
  }
}
