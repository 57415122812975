import { AssetTypeViewService } from '@/general/services/overview-service/modules/asset-type-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';

/**
 * Supplier Asset Type View Service.
 * Feature proof to overwrite functions if required for the supplier.
 */
export class SupplierAssetTypeViewService extends AssetTypeViewService {
  constructor() {
    super(new TranslationService('supplier', 'assetTypes'));
  }
}
