<script setup lang="ts">
import useFormValidation from 'composables/form-validation';
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { UpdateUserRequest } from 'platform-unit2-api/users';

// Props
defineProps<{
  disableTitle?: boolean;
}>();

/** Composables */
const store = useStore();
const { fieldErrorMessage } = useFormValidation();

/** Services */
const ts = new TranslationService('retailer', 'settings');
const toastService = ToastService.getInstance();

// Constants
const saving = ref(false);
const password = ref('');
const passwordConfirmation = ref('');

const eightChars = computed(() => {
  return new RegExp('[a-zA-Z0-9]{12}').test(password.value);
});

const lowerCaseChar = computed(() => {
  return new RegExp('[a-z]').test(password.value);
});

const upperCaseChar = computed(() => {
  return new RegExp('[A-Z]').test(password.value);
});

const numericChar = computed(() => {
  return new RegExp('[0-9]').test(password.value);
});

const specialChar = computed(() => {
  return new RegExp('[@$!%*?&]').test(password.value);
});

const updatePassword = async (): Promise<void> => {
  saving.value = true;
  try {
    const updatedUser = {
      password: password.value,
      password_confirmation: passwordConfirmation.value,
    } as UpdateUserRequest;
    await store.dispatch('users/UPDATE_USER', {
      id: store.getters['users/currentUser'].id,
      updatedUser,
    });

    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    saving.value = false;
  }
};

// Lifecycle hooks
onMounted(async () => {
  password.value = '';
  passwordConfirmation.value = '';
});
</script>

<template>
  <div>
    <h2 v-if="!disableTitle" class="font-bold text-xl">
      {{ ts.tGlobal('password') }}
    </h2>
    <p-divider v-if="!disableTitle" />
    <div class="grid">
      <form class="col-12 field lg:col-6">
        <label for="password">{{ ts.tGlobal('newPassword') }}</label>
        <p-password
          id="password"
          v-model="password"
          type="password"
          class="password-mask w-full"
          input-class="w-full"
          strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          :placeholder="ts.tGlobal('newPassword')"
          :class="{ 'p-invalid': fieldErrorMessage('password').length }"
          toggle-mask
        >
          <template #footer>
            <ul class="ml-2 mt-0 pl-2" style="line-height: 1.5">
              <li v-if="!eightChars">
                {{ ts.tModule('minCharacters') }}
              </li>
              <li v-if="!lowerCaseChar">
                {{ ts.tModule('lowercase') }}
              </li>
              <li v-if="!upperCaseChar">
                {{ ts.tModule('uppercase') }}
              </li>
              <li v-if="!numericChar">
                {{ ts.tModule('numeric') }}
              </li>
              <li v-if="!specialChar">
                {{ ts.tModule('specialCharacters') }}
              </li>
            </ul>
          </template>
        </p-password>
        <small v-if="fieldErrorMessage('password').length" class="p-error">{{
          fieldErrorMessage('passwordStrengthMessage')
        }}</small>
      </form>

      <form class="col-12 field lg:col-6">
        <label for="passwordConfirm">
          {{ ts.tModule('passwordConfirmation') }}
        </label>
        <p-password
          id="passwordConfirm"
          v-model="passwordConfirmation"
          type="password"
          class="password-mask w-full"
          input-class="w-full"
          :placeholder="ts.tModule('passwordConfirmation')"
          :class="{ 'p-invalid': fieldErrorMessage('password_confirmation').length }"
          :feedback="false"
          toggle-mask
        />
        <small v-if="fieldErrorMessage('password_confirmation').length" class="p-error">{{
          fieldErrorMessage('password_confirmation')
        }}</small>
      </form>
    </div>
    <div class="field flex flex-row justify-content-end">
      <p-button
        :disabled="!password || !passwordConfirmation || saving"
        :label="ts.tGlobal('save')"
        :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
        icon-pos="right"
        @click="updatePassword"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.password-mask :deep(.p-icon) {
  right: 10px;
}
</style>
