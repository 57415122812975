import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { Category, CategoryCompact } from 'platform-unit2-api/categories';
import { UpdateProductField } from 'platform-unit2-api/products';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class CategoryFieldService extends AttributeFieldService<
  Category | CategoryCompact | undefined
> {
  constructor(
    attributeField: AttributeField<Category | CategoryCompact | undefined>,
    productAttributeField: ProductAttributeField<Category | CategoryCompact | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }

  // Backend expects only the name of the category, therefore a string is sent back when retrieving data
  // This requires this override of the getter which casts the value to a category
  get productAttributeField(): ProductAttributeField<Category | CategoryCompact | undefined> {
    if (typeof this._productAttributeField.value === 'string') {
      (this._productAttributeField.value as any) = {
        name: this._productAttributeField.value,
      };
    }

    return this._productAttributeField;
  }

  /*
   * Backend expects a string -> name of the category or null
   */
  protected createPayload(): UpdateProductField {
    return {
      attribute_id: this._productAttributeField.attribute_id,
      value: this._productAttributeField.value?.name ?? null,
      locale_id: this._productAttributeField.locale.id,
      path: this._productAttributeField?.path ?? null,
      overwrite: false,
    };
  }
}
