<script setup lang="ts">
import AttributeSelect from '@/general/ui/components/selects/attribute-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Locale } from 'platform-unit2-api/locales';
import { Attribute } from 'platform-unit2-api/attributes';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  ids: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const store = useStore();
const attribute = ref<Attribute[]>([]);
const selectedLocale = ref<Locale>();
const lock = ref(true);

onMounted(() => {
  if (store.getters['users/currentUser'] && store.getters['users/currentUser'].locale) {
    selectedLocale.value = store.getters['users/currentUser'].locale;
  }
});

const lockAllFields = async () => {
  try {
    buttonsOptions.value.saving = true;
    await store.dispatch('products/BULK_ACTION_LOCK_FIELDS', {
      ids: props.ids,
      localeId: selectedLocale.value?.id ?? 0,
      field_ids: attribute.value?.map((a) => a?.id),
      lock: lock.value,
    });
    toastService.displaySuccessToast(
      ts.tModule(
        `product_attributes.lock_field.${
          lock.value ? 'locked_successfully' : 'unlocked_successfully'
        }`,
      ),
    );
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('product_attributes.lock_field.lock_field_failed'));
  } finally {
    buttonsOptions.value.saving = false;
    emit('hide');
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const changeButton = new CreateButton({
  label: ts.tGlobal('change'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!attribute?.value.length && selectedLocale?.value?.id != null,
  onClick: lockAllFields,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, changeButton],
});
</script>

<template>
  <BaseDialog
    :title="ts.tModule('product_attributes.lock_field.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="small"
    @update:visible="emit('hide')"
  >
    <div class="grid">
      <div class="col-12">
        <AttributeSelect v-model="attribute" multiselect />
      </div>

      <div class="col-12">
        <label
          for="locale"
          class="font-bold"
          :style="{ color: attribute.length ? 'black' : 'gray' }"
          >{{ ts.tGlobal('select_language') }}</label
        >
        <LocaleSelect
          id="locale"
          v-model="selectedLocale"
          :show-clear="false"
          hide-label
          :disabled="!attribute.length"
        />
      </div>

      <div class="col-12">
        <label
          for="lock"
          class="font-bold"
          :style="{ color: attribute?.length ? 'black' : 'gray' }"
          >{{ ts.tModule('product_attributes.lock_field.set_lock') }}</label
        >
        <p-toggle-button
          id="lock"
          v-model="lock"
          :disabled="!attribute?.length"
          class="inline-block w-full"
          :on-label="ts.tModule('product_attributes.lock_field.set_lock')"
          :off-label="ts.tModule('product_attributes.lock_field.set_lock')"
          on-icon="mdi mdi-lock-outline"
          off-icon="mdi mdi-lock-open-variant-outline"
        />
      </div>
    </div>
  </BaseDialog>
</template>
