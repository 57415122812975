<script setup lang="ts">
import { DeveloperService } from '@/platforms/retailer/modules/developers/services/developers.service';
import { onMounted, ref } from 'vue';

//Components
import NoAccess from '@/platforms/retailer/modules/developers/components/no-access.vue';
import GenerateToken from '@/platforms/retailer/modules/developers/components/generateToken.vue';
import TokensOverview from '@/platforms/retailer/modules/developers/components/tokensOverview.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';

/* Services */
const developerService = ref(new DeveloperService());

onMounted(async () => {
  if (developerService.value.currentUser?.workspace?.has_public_api) {
    await developerService.value.getApiKey();
  }
});
</script>
<template>
  <section class="h-full pt-3 px-4">
    <LoadingIndicator
      v-if="developerService.loading"
      class="align-items-center flex flex-column h-full justify-content-center"
    />
    <div v-if="!developerService.loading" class="h-full">
      <!-- The user doesn't have access to generate api key -->
      <NoAccess v-if="!developerService.currentUser?.workspace?.has_public_api" />

      <!-- The user has the access but there is no API key yet -->
      <GenerateToken
        v-else-if="!developerService.apiKey.length"
        @generate-new-key="developerService.generateNewKey()"
      />
      <!-- The user has already created an API key -->
      <TokensOverview
        v-else-if="developerService.apiKey.length"
        :developer-service="developerService"
        @delete-key="(event) => developerService.deleteKey(event)"
      />
    </div>
  </section>
</template>
