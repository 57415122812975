import { ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import {
  DispatchData,
  DispatchResult,
  DispatchSuccess,
  DispatchReject,
  DisplayToastSetting,
} from '@/general/composables/UseDispatch/types';
import {
  toggleLoading,
  handleDispatch,
  handleSuccess,
  displaySuccessToast,
  handleReject,
  displayErrorToast,
} from './helpers';

export function useDispatch() {
  const toast = useToast();
  const store = useStore();
  const { t } = useI18n();
  const loading = ref(false);

  async function dispatch<T>(
    action: string,
    dispatchData: DispatchData<T>,
  ): Promise<DispatchResult> {
    if (dispatchData.toast?.displaySetting === undefined) {
      dispatchData.toast = { ...dispatchData.toast, displaySetting: DisplayToastSetting.BOTH };
    }

    toggleLoading(loading, true);

    try {
      const data = await handleDispatch(store, action, dispatchData);

      handleSuccess(dispatchData, data);

      displaySuccessToast(t, toast, dispatchData);

      return { data, passed: true } as DispatchSuccess<T>;
    } catch (err: any) {
      handleReject(dispatchData, err);

      displayErrorToast(t, toast, dispatchData, err.message);

      return {
        passed: false,
        message: err.message,
        response: err.response ?? {},
      } as DispatchReject;
    } finally {
      toggleLoading(loading, false);
    }
  }

  return { dispatch, loading };
}
