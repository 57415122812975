<script setup lang="ts">
import { inject } from 'vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { brandServiceKey } from '../ts/keys/brand.keys';
import Upload from '@/general/ui/components/upload.vue';
import BrandEmptyState from './brand-empty-state.vue';
import BrandEmptySearchState from './brand-empty-search-state.vue';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import { PageState } from 'primevue/paginator';

const ts = inject(tsKey)!;
const service = inject(brandServiceKey)!;
</script>

<template>
  <div class="grid mb-4">
    <div
      v-for="brand in service.brands"
      :key="brand.id"
      class="col-12 flex flex-column lg:col-3 md:col-6 p-2"
      @click.stop="service.openCrudComponent(brand)"
    >
      <!-- TODO: Create a 'show-brand' component -->
      <pCard class="clickable">
        <template #header>
          <div class="align-items-center flex flex-column justify-content-center">
            <Upload class="h-16rem max-w-full w-16rem" :upload="brand.thumbnail" />
            <p class="font-bold my-3 text-2xl">
              {{ brand.name }}
            </p>
          </div>
        </template>
        <template #content>
          <p class="mb-4">
            <span class="text-base">{{ ts.tGlobal('products', { choice: 2 }) }} :</span>
            {{ brand.product_count }}
          </p>
          <div class="align-items-start flex flex-column mt-3">
            <span class="mb-1 text-gray-400 text-sm">{{ ts.tModule('completeness_score') }}</span>
            <ProgressBar :progress="Math.round(brand.completeness ?? 0) ?? 0" />
          </div>
        </template>
      </pCard>
    </div>
  </div>
  <div>
    <BrandEmptyState v-if="service.hasNoBrands" />
    <BrandEmptySearchState v-if="service.isSearchResultEmpty" />
  </div>
  <pPaginator
    v-if="service.brands.length > 0 && service.total > (service.limit ?? 0)"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    :pt="{
      paginatorWrapper: {
        class: 'pagination-centered',
      },
    }"
    @page="(event: PageState) => service.onPageChange(event.page)"
  />
</template>
<style lang="scss" scoped>
.pagination-centered {
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
}
</style>
