import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Paginator } from './pagination.interface';

export default function (): Paginator {
  const router = useRouter();
  const route = useRoute();
  const page = ref<number>(route.query.page ? parseInt(route.query.page.toString()) : 1);
  const perPage = ref<number>(route.query.limit ? parseInt(route.query.limit.toString()) : 15);
  const query = ref<string>(route.query.q ? route.query.q.toString() : '');

  const onPageChange = (value: number, callback?: () => void): void => {
    page.value = value;
    router.push({
      query: { page: page.value.toString(), limit: perPage.value.toString(), q: query.value },
    });
    callback ? callback() : null;
  };

  const onRowChange = (value: number, callback?: () => void): void => {
    perPage.value = value;
    router.push({
      query: { page: page.value.toString(), limit: perPage.value.toString(), q: query.value },
    });
    callback ? callback() : null;
  };

  onMounted(() => {
    query.value = route.query.q ? route.query.q.toString() : '';
    page.value = route.query.page ? parseInt(route.query.page.toString()) ?? page.value : 1;
    perPage.value = route.query.limit
      ? parseInt(route.query.limit.toString()) ?? perPage.value
      : 15;
  });

  return {
    page: page,
    perPage: perPage,
    onPageChange: onPageChange,
    onRowChange: onRowChange,
    query: query,
  };
}
