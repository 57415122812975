import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import BaseButton from '@/general/ui/components/crud-buttons/ts/classes/base-button.class';

export class CrudButtonService {
  public filterButtonPosition(
    position: CrudButtonPosition,
    buttonsToFilter: BaseButton[],
  ): BaseButton[] {
    return buttonsToFilter.filter((button) => button.position == position) ?? [];
  }
}
