import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create Attribute',
  subTitle: 'Here you can create a new Attribute.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete Attribute',
};

const update: TranslationObjectAction = {
  title: 'Edit Attribute',
  subTitle: 'Here you can edit an Attribute.',
};

const translationObject: TranslationObject = {
  title: 'Attribute | Attributes',
  placeholder: 'Select Attribute | Select Attributes',

  create: create,
  delete: deleteTranslation,
  update: update,
};

const tranlation: Translation = {
  attributes: translationObject,
};

export default tranlation;
