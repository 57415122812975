<script setup lang="ts">
import { Brand, BrandCompact } from 'platform-unit2-api/brands';
import { inject, ref } from 'vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { BrandFieldService } from '@/general/services/attribute-fields/field-services/brand-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.vue';
import BrandSelect from '../selects/brand-select.vue';

/** Props */
interface Props {
  attributeField: AttributeField<Brand | BrandCompact | undefined>;
  productAttributeField: ProductAttributeField<Brand | BrandCompact | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const brandFieldService = ref(
  new BrandFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>

<template>
  <WrapperField :attribute-field-service="brandFieldService">
    <BrandSelect
      v-model="brandFieldService.productAttributeFieldValue"
      :disabled="brandFieldService.productAttributeField.locked"
      hide-label
      :show-clear="!brandFieldService.productAttributeField.locked"
      class="w-full"
      :pt="{
        root: {
          class: 'border-noround-left ' + brandFieldService.getErrorTypeClass(),
        },
      }"
    />
  </WrapperField>
</template>
