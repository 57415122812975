import { reactive, InjectionKey } from 'vue';

export class ServiceManager {
  static instance: ServiceManager;

  constructor() {
    if (ServiceManager.instance) {
      return ServiceManager.instance;
    }

    ServiceManager.instance = this;
  }

  private services: Map<string | symbol | InjectionKey<any>, any> = new Map();

  public getService<T extends { new (): T } | { new (args: any): T }>(
    injectKey: string | symbol | InjectionKey<T>,
    serviceType: T,
    args?: any[],
  ): InstanceType<T> {
    const service = this.services.get(injectKey);

    if (!service) {
      let instance: T;
      if (args) {
        instance = new (serviceType as any)(...args);
      } else {
        instance = new (serviceType as any)();
      }

      const service = reactive(instance as any);

      this.services.set(injectKey, service);

      return service;
    }

    return service;
  }

  public registerService<T>(injectKey: string | symbol | InjectionKey<any>, service: T): void {
    this.services.set(injectKey, service);
  }

  public unregisterService(injectKey: string | symbol | InjectionKey<any>): void {
    this.services.delete(injectKey);
  }
}
