import i18n from '@/core/i18n';
import TableColumn from '@/general/composables/useTable/table-column';
import { TranslationService } from '@/general/services/translations/translation.service';
import VariantSelect from '@/general/ui/components/selects/variant-select.vue';
import Upload from '@/general/ui/components/upload.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { Composer } from 'vue-i18n';
import { ContentStoryProduct } from '@/api/v1/content-story/ts/content-story-product.interface';

const ts = new TranslationService(
  'supplier',
  'storyManager',
  process.env.NODE_ENV !== 'production',
  i18n.global as unknown as Composer,
);
const linkAndPublishColumns: TableColumn<ContentStoryProduct>[] = [
  new TableColumn({
    key: 'thumbnail',
    label: '',
    additionalStyling: {
      'min-width': '8rem',
      width: '5rem',
      height: '5rem',
      'flex-grow': 0,
      'justify-content': 'center',
    },
    isFrozen: true,
    customValue: (row) => ({
      component: Upload,
      props: { upload: row.thumbnail },
    }),
  }),
  new TableColumn({ key: 'gtin', label: ts.tGlobal('gtin'), isSortable: true }),
  new TableColumn({
    key: 'display_name',
    label: ts.tGlobal('products'),
    isSortable: true,
  }),
  new TableColumn({
    key: 'variant_name',
    label: ts.tGlobal('variantName'),
    isSortable: true,
    fallbackValue: 'Master data',
    filter: {
      filterKey: 'variants',
      component: {
        component: VariantSelect,
        props: { display: 'comma', omitMasterData: true, multiselect: true },
      },
    },
  }),
  new TableColumn({
    key: 'variant_uuid',
    label: ts.tGlobal('status'),
    isSortable: true,
    customValue: (row) => {
      return {
        component: StatusChip,
        props: {
          label:
            (row.content_stories ?? [])[0]?.status?.status === 'draft'
              ? ts.tGlobal('concept')
              : ts.tGlobal('published'),
          severity:
            ActionStatusSeverityEnum[
              (row.content_stories ?? [])[0]?.status?.status === 'draft'
                ? (ts.tGlobal('concept').toUpperCase() as keyof typeof ActionStatusSeverityEnum)
                : (ts.tGlobal('published').toUpperCase() as keyof typeof ActionStatusSeverityEnum)
            ],
          labelOnly: true,
        },
      };
    },
  }),
];

export default linkAndPublishColumns;
