import { Upload } from 'platform-unit2-api/uploads';
import { SingleImageHighlights } from '../story-types/modules/single-image-highlights.interface';
import { IBulletPoint } from './interfaces/IBulletPoint.interface';
import { IContentImage } from './interfaces/IContentImage.interface';
import { TextItem } from '../story-types/text-item.interface';
import { BaseStoryModule } from './base-story-module.class';
import StandardSingleImageHighlights from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-single-image-highlights.vue';
import { Component } from 'vue';
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { ToastService } from '@/general/services/toasts/toast.service';
import i18n from '@/core/i18n';
import { TranslationService } from '@/general/services/translations/translation.service';
import { StandardTextBlock } from '../story-types/standard-text-block.interface';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export class SingleImageHighlightsClass
  extends BaseStoryModule<SingleImageHighlights>
  implements IContentImage<unknown>, IBulletPoint<unknown>
{
  ts: TranslationService;
  component: Component = StandardSingleImageHighlights;
  moduleTranslationKey = 'storyBuilder.singleImageHighlightsModuleDisplayName';
  minimumImageSize: `${string} : ${string}` = '300 : 300';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';

  private _ImageValidatorService: ImageValidatorService;

  constructor(data?: SingleImageHighlights) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._ImageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (!data) {
      this.data = {
        type: ContentStoryModulesEnum.SingleImageHighlights,
        headline: {
          type: ModuleComponentTypes.Text,
          value: '',
        },
        image: {
          type: ModuleComponentTypes.Image,
          uploadDestinationId: undefined,
          altText: '',
        },
        bulletedListBlock: {
          type: ModuleComponentTypes.StandardHeaderTextListBlock,
          headline: {
            type: ModuleComponentTypes.Text,
            value: '',
          },
          block: { type: ModuleComponentTypes.StandardTextListBlock, textList: [] },
        },
      };
    }
  }

  getAltText(): string | undefined {
    return this.data.image?.altText ?? '';
  }

  setAltText(altText?: string | undefined): void {
    this.data.image = {
      type: ModuleComponentTypes.Image,
      uploadDestinationId: this.data.image?.uploadDestinationId ?? '',
      altText: altText ?? '',
    };
  }

  //#region bulet points
  addBulletPoint(value: string): void {
    if (value === '') {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.bulletPoints.empty'),
      );
      return;
    }

    if ((this.data.bulletedListBlock?.block?.textList ?? []).length >= 8) {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.bulletPoints.maxAmountOfBulletPoints'),
      );
      return;
    }

    const newBulletPoint: TextItem = {
      type: ModuleComponentTypes.TextItem,
      text: {
        type: ModuleComponentTypes.Text,
        value: value,
      },
    };

    this.data.bulletedListBlock?.block?.textList.push(newBulletPoint);
  }
  removeBulletPoint(index: number): void {
    this.data.bulletedListBlock?.block?.textList.splice(index, 1);
  }
  getBulletPoints(): TextItem[] {
    if (this.data.bulletedListBlock == null) {
      this.data.bulletedListBlock = {
        type: ModuleComponentTypes.StandardHeaderTextListBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: '',
        },
        block: { type: ModuleComponentTypes.StandardTextListBlock, textList: [] },
      };
    }

    return this.data.bulletedListBlock?.block?.textList ?? [];
  }

  getBulletPointHeadline(): string {
    return this.data?.bulletedListBlock?.headline?.value ?? '';
  }

  setBulletPointHeadline(value?: string): void {
    if (value) {
      this.data.bulletedListBlock = {
        type: ModuleComponentTypes.StandardHeaderTextListBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: value,
        },
        block: {
          type: ModuleComponentTypes.StandardTextListBlock,
          textList: this.data.bulletedListBlock?.block?.textList ?? [],
        },
      };
    }
  }

  //#endregion

  //#region image
  setUploadData(upload: Upload): void {
    if (this._ImageValidatorService.hasCorrectDimensions(upload)) {
      this.data.image = {
        type: ModuleComponentTypes.Image,
        uploadDestinationId: upload.public_url,
        altText: this.data.image?.altText ?? '',
      };
    }
  }
  hasUploadSet(): boolean {
    return this.data?.image?.uploadDestinationId ? true : false;
  }
  getUploadUrl(): string | undefined {
    return this.data?.image?.uploadDestinationId;
  }

  //#endregion

  //#region set headline
  setMainHeadline(value?: string): void {
    if (value != null) {
      this.data.headline = {
        type: ModuleComponentTypes.Text,
        value: value,
      };
    }
  }

  getMainHeadline(): string {
    return this.data.headline?.value ?? '';
  }
  //#endregion

  //#region standard text block
  setStandardTextBlockHeadline(
    key: keyof Omit<SingleImageHighlights, 'type' | 'image' | 'headline' | 'bulletedListBlock'>,
    value?: string,
  ) {
    if (value != null) {
      (this.data[key] as StandardTextBlock) = {
        type: ModuleComponentTypes.StandardTextBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: value,
        },
        body: (this.data[key] as StandardTextBlock)?.body ?? {
          type: ModuleComponentTypes.TextArea,
          value: '',
        },
      };
    }
  }
  getStandardTextBlockHeadline(
    key: keyof Omit<SingleImageHighlights, 'type' | 'image' | 'headline' | 'bulletedListBlock'>,
  ) {
    return (this.data[key] as StandardTextBlock)?.headline?.value ?? '';
  }

  setStandardTextBlockText(
    key: keyof Omit<SingleImageHighlights, 'type' | 'image' | 'headline' | 'bulletedListBlock'>,
    value?: string,
  ) {
    if (value != null) {
      (this.data[key] as StandardTextBlock) = {
        type: ModuleComponentTypes.StandardTextBlock,
        headline: (this.data[key] as StandardTextBlock)?.headline ?? {
          type: ModuleComponentTypes.Text,
          value: '',
        },
        body: {
          type: ModuleComponentTypes.TextArea,
          value: value,
        },
      };
    }
  }

  getStandardTextBlockText(
    key: keyof Omit<SingleImageHighlights, 'type' | 'image' | 'headline' | 'bulletedListBlock'>,
  ) {
    return (this.data[key] as StandardTextBlock)?.body?.value ?? '';
  }
  //#endregion
}
