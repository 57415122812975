import { createI18n } from 'vue-i18n';
import en from '@/core/i18n/english';

export default createI18n<[typeof en], 'en-UK'>({
  locale: localStorage.locale ?? 'en-UK',
  fallbackLocale: 'en-UK',
  legacy: false,
  messages: {
    'en-UK': en,
  },
});
