<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';

import { tsKey } from '@/general/services/translations/translation.key';
import BaseDialog from '../dialog/base-dialog.vue';
import { CancelButton } from '../crud-buttons/ts/classes/cancel-crud-button.class';
import { CrudButtonPosition } from '../crud-buttons/ts/enums/crud-button-position.enum';
import { CreateButton } from '../crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '../crud-buttons/ts/interfaces/crud-button-option.interface';
import { OverviewDialogBase } from '@/general/services/overview-service/interfaces/overview-dialog-base.interface';

interface Props {
  service: OverviewDialogBase;
  overwriteCallToActionLabel?: string;
  title?: string;
  subTitle?: string;
  hasSubtitle?: boolean;
  size: 'small' | 'medium' | 'large';
}

const props = withDefaults(defineProps<Props>(), {
  hasSubtitle: true,
  overwriteCallToActionLabel: undefined,
  title: undefined,
  subTitle: undefined,
});

const ts = inject(tsKey)!;

const dialogSubTitle = computed(() => {
  if (props.hasSubtitle) {
    if (props.subTitle) {
      return props.subTitle;
    }

    return props.service.isCreating ? ts.value.moduleCreateSubTitle : ts.value.moduleUpdateSubTitle;
  }

  return undefined;
});

const dialogTitle = computed(() => {
  if (props.title) {
    return props.title;
  }

  return props.service.isCreating ? ts.value.moduleCreateTitle : ts.value.moduleUpdateTitle;
});

const closeDialog = () => {
  props.service.closeCrudComponent();
};

const cancelButton = new CancelButton({
  label: ts.value.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => closeDialog(),
});

const createButton = new CreateButton({
  label: props.overwriteCallToActionLabel ?? ts.value.tGlobal('save'),
  isEnabled: () => !props.service.disableSave,
  position: CrudButtonPosition.RIGHT,
  type: 'submit',
  styleClasses: 'white-space-nowrap',
  onClick: () => {},
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, createButton],
});

watch(
  () => props.overwriteCallToActionLabel,
  () => {
    buttonsOptions.value.buttons[1] = new CreateButton({
      label: props.overwriteCallToActionLabel ?? ts.value.tGlobal('save'),
      isEnabled: () => !props.service.disableSave,
      position: CrudButtonPosition.RIGHT,
      type: 'submit',
      styleClasses: 'p-button white-space-nowrap',
      onClick: () => {},
    });
  },
);
</script>

<template>
  <BaseDialog
    :visible="service.crudComponent"
    :title="dialogTitle"
    :subtitle="dialogSubTitle"
    :buttons-options="buttonsOptions"
    :size="size"
    @submit="service.save()"
    @update:visible="service.closeCrudComponent()"
  >
    <slot v-if="service.isLoadingCrudComponent" name="skeleton"> </slot>
    <slot v-else name="form" :data="service.partialObject" :is-creating="service.isCreating" />
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </BaseDialog>
</template>
