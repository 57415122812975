<script setup lang="ts">
//vue
import { inject } from 'vue';

//components
import ContentImage from '../content-components/content-image.vue';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';

//types
import { Upload } from 'platform-unit2-api/uploads';

//services
import { storyBuilderServiceKey } from '@/platforms/supplier/modules/story-manager/ts/helpers/story-manager-keys';
import { SingleImageLogoModule } from '@/api/v1/content-story/ts/classes/single-image-logo.class';
import { TranslationService } from '@/general/services/translations/translation.service';

//props
interface Props {
  uuid: string;
}
const props = defineProps<Props>();

//inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

//const
const storyModule = storyService?.value.getModule<SingleImageLogoModule>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid" custom-height="50%" data-testid="standard-company-logo-module">
    <template #content>
      <ContentImage
        custom-container-class="h85"
        :is-upload-set="storyModule?.hasUploadSet()"
        :upload-url="storyModule?.getUploadUrl()"
        :minimal-image-size="storyModule?.minimumImageSize"
        :accepted-file-types="storyModule?.acceptedFileTypes"
        @uploaded-image="(event: Upload) => storyModule?.setUploadData(event)"
      />
      <ContentHeadline
        :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
        :headline="storyModule?.getAltText()"
        @headline-value="(text) => storyModule?.setAltText(text)"
      />
    </template>
  </BaseContentComponent>
</template>
