import { DirectiveBinding } from 'vue';

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding<() => void>) {
    if (typeof binding.value === 'function') {
      el.onfocus = binding.value;
      el.addEventListener('focusin', () => {
        el.focus();
        binding.value();
      });

      el.addEventListener('focusout', () => {
        el.focus();
        binding.value();
      });

      return;
    }

    throw new Error('v-on-focus prop value must be a function');
  },
};
