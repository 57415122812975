<script setup lang="ts">
import { stringToUpperCase } from '@/general/utils/string-to-upper-case';
import { watch, ref, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const currentUser = store.getters['users/currentUser'];

const route = useRoute();
const routeItems: Ref<string[] | undefined> = ref();

function getRouteItems() {
  if (route.meta?.breadcrumbs === false) {
    return;
  }

  routeItems.value = route.path
    .split('/')
    .filter((item) => item !== '')
    .map((item) => {
      return stringToUpperCase(item).replace('-', ' ');
    });
}

watch(route, getRouteItems);
onMounted(getRouteItems);
</script>

<template>
  <div class="breadcrumb">
    <router-link to="/"><i class="mdi mdi-home"></i></router-link>
    <span class="separator"> /</span>
    <span data-testid="breadcrumb-current-workspace">{{ currentUser?.workspace?.name }}</span>

    <div v-for="item in routeItems" :key="item">
      <span class="separator"> /</span>
      <span class="text-gray-700">{{ item }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/theme/settings/spacing.scss';
@import '@/assets/theme/settings/colors.scss';
.breadcrumb {
  font-size: $font-size-s;
  a {
    color: $gray-500;
  }

  display: flex;

  span {
    display: inline-block;
    margin: 0;
    color: $gray-500;

    &.separator {
      margin: 0 $spacing-03;
      color: $gray-border-color;
    }
  }
}
</style>
