<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue';
import UploadComponent from '@/general/ui/components/upload.vue';
import { useStore } from 'vuex';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Upload } from 'platform-unit2-api/uploads';
import {
  MatchedProductUploadsResponse,
  MatchProductUpload,
  ProductCompact,
} from 'platform-unit2-api/products';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import MatchToProductModal from 'supplier/modules/media/assets/components/import-assets/match-to-product-modal.vue';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';

// Services
const ts = new TranslationService('supplier', 'media');
const toastService = ToastService.getInstance();
const route = useRoute();
const service = useService(assetViewServiceKey, AssetViewService);

// Constasnts
const store = useStore();
const files = ref<Upload[]>([]);
const loading = ref(false);
const selectedUploadIds = ref<number[]>([]);
const matchToProductModalActive = ref(false);

const matchedProductUploads = computed(
  (): MatchedProductUploadsResponse[] => store.getters['matchMedia/matchedProductUploads'],
);

const unmatchedUploads = computed((): Upload[] => {
  const unmatchedProduct = matchedProductUploads.value.find(
    (matchedUpload) => matchedUpload.product == null,
  );
  return unmatchedProduct?.thumbnails ?? [];
});

const productUploads = computed((): MatchedProductUploadsResponse[] => {
  return matchedProductUploads.value.filter(
    (matchedProductUpload) => matchedProductUpload.product != null,
  );
});

const selectedUploads = computed(() => {
  return selectedUploadIds.value
    .map((id) => {
      return unmatchedUploads.value.find((upload) => {
        return upload.id === id;
      });
    })
    .filter((upload) => upload != null);
});

const uploads = computed((): Upload[] => {
  let uploads: Upload[] = [];

  matchedProductUploads.value.reduce(
    (_: MatchedProductUploadsResponse, current: MatchedProductUploadsResponse) => {
      uploads = [...uploads, ...current.thumbnails];
      return current;
    },
    {} as MatchedProductUploadsResponse,
  );

  return uploads;
});

// Helper functions
const calculateProductUploadMatches = async () => {
  loading.value = true;
  const matchingProductUploads: MatchProductUpload[] = [];

  files.value.forEach((file: Upload) => {
    const gtinMatch = file.filename.match(/\d{8}(?:\d{4,6})?/);
    const gtin = gtinMatch ? gtinMatch[0] : undefined;
    const matchedProductUpload = matchingProductUploads.find((match) => match.gtin === gtin);

    if (matchedProductUpload) {
      matchedProductUpload.upload_ids.push(file.id);
    } else {
      matchingProductUploads.push({
        gtin,
        upload_ids: [file.id],
      });
    }
  });

  try {
    await store.dispatch('matchMedia/MATCH_PRODUCT_UPLOADS', matchingProductUploads);
    files.value = [];
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('import_assets.match_failed'));
  } finally {
    loading.value = false;
  }
};

const remove = async (product: ProductCompact | undefined, upload: Upload) => {
  if (product == null) {
    return;
  }

  await store.dispatch('matchMedia/REMOVE_MATCHED_UPLOAD', { product, upload });
};

const toggleSelect = () => {
  if (selectedUploadIds.value.length > 0) {
    selectedUploadIds.value = [];
  } else {
    selectedUploadIds.value = unmatchedUploads.value.map((upload) => upload.id);
  }
};

const toggleSelectUpload = (upload: Upload) => {
  if (!selectedUploadIds.value.includes(upload.id)) {
    selectedUploadIds.value.push(upload.id);
  } else {
    selectedUploadIds.value = selectedUploadIds.value.filter((uploadId) => uploadId !== upload.id);
  }
};

const moveUpload = async (params: {
  product?: ProductCompact;
  fromIndex: number;
  toIndex: number;
}) => {
  if (params?.product == null) {
    return;
  }

  store.dispatch('matchMedia/MOVE_MATCHED_UPLOAD', params);
};

onBeforeRouteLeave(() => {
  if (service) {
    service.importAssetsModal.partialObject.upload_length = uploads.value.length;
    service.importAssetsModal.partialObject.product_upload_length = productUploads.value.length;
  }
});

const matchToProduct = (value: boolean) => {
  if (value) {
    matchToProductModalActive.value = value;
  } else {
    matchToProductModalActive.value = value;
    selectedUploadIds.value = [];
  }
};

// Lifecycle hooks
onMounted(async () => {
  if (service?.importAssetsModal?.partialObject?.data) {
    files.value = [...service.importAssetsModal.partialObject.data];
    await calculateProductUploadMatches();
  }

  // Disable next button if a product has not been matched
  if (service != null) {
    service.importAssetsModal.partialObject.product_upload_length = productUploads.value.length;
  }
});

watch(
  () => route.name,
  async () => {
    if (route.name === 'import-assets-result') {
      if (service?.importAssetsModal?.partialObject?.data) {
        files.value = [...service.importAssetsModal.partialObject.data];
        await calculateProductUploadMatches();
      }

      // Disable next button if a product has not been matched
      if (service != null) {
        service.importAssetsModal.partialObject.product_upload_length = productUploads.value.length;
      }
    }
  },
);
</script>
<template>
  <div>
    <div v-if="unmatchedUploads.length" class="col field mb-3 mt-6">
      <label>{{
        ts.tModule('import_assets.unmatched_assets', {
          params: { amount: unmatchedUploads.length },
        })
      }}</label>
      <div class="border-2 border-blue-50 flex flex-column">
        <div class="m-3">
          <div class="flex justify-content-between">
            <p>
              {{
                ts.tModule('import_assets.selected', {
                  params: { amount: selectedUploadIds.length },
                })
              }}
            </p>
            <div>
              <p-button
                :label="
                  !selectedUploadIds.length ? ts.tGlobal('select_all') : ts.tGlobal('deselect_all')
                "
                :icon="selectedUploadIds.length ? 'mdi mdi-select-off' : 'mdi mdi-select-group'"
                @click="toggleSelect"
              />
              <p-button
                icon="mdi mdi-account-box-multiple-outline"
                :disabled="!selectedUploadIds.length"
                :label="ts.tModule('import_assets.match_product')"
                class="ml-3"
                @click="matchToProduct(true)"
              />
            </div>
          </div>
          <div class="flex flex-wrap pt-5">
            <div
              v-for="upload in unmatchedUploads"
              :key="upload.id"
              class="align-items-center cursor-pointer flex flex-column hover:surface-50 mb-3 mr-3 p-2 pb-3 pr-3 relative"
              style="width: 200px"
              :class="{ 'surface-50': selectedUploadIds.includes(upload.id) }"
              @click="toggleSelectUpload(upload)"
            >
              <upload-component
                :upload="upload"
                class="mb-2 w-5rem"
                :size="'100'"
                :preview="true"
              />
              <span>{{ upload.filename }}</span>

              <p-button
                icon="mdi mdi-close"
                text
                plain
                class="absolute"
                style="top: -10px; right: -10px"
                @click="remove(undefined, upload)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator v-if="loading" />
    <div v-else>
      <div v-if="productUploads.length" class="col field mb-3 mt-6">
        <label>{{ ts.tModule('import_assets.matched_assets') }}</label>

        <div
          v-for="productUpload in productUploads"
          :key="productUpload.product?.id"
          class="border-2 border-blue-50 border-blue-50 border-solid mb-6 pl-4 pr-4 pt-4"
        >
          <div class="align-items-center border-blue-50 border-bottom-2 flex pb-4">
            <upload-component class="mr-4 w-5rem" :upload="productUpload.product?.thumbnail" />
            <span>{{ productUpload.product?.display_name }}</span>
          </div>

          <div class="flex flex-wrap pt-4">
            <div
              v-for="(upload, index) in productUpload.thumbnails"
              :key="upload.id"
              class="align-items-center flex flex-column mb-5 mr-3 relative upload"
              style="width: 200px"
            >
              <p-button
                v-if="index > 0"
                icon="mdi mdi-chevron-left"
                plain
                text
                rounded
                class="absolute opacity-20"
                style="top: 50%; left: -10px; transform: translateY(-50%)"
                @click="
                  moveUpload({
                    product: productUpload.product,
                    fromIndex: index,
                    toIndex: index - 1,
                  })
                "
              />
              <p-button
                v-if="index < productUpload.thumbnails.length - 1"
                icon="mdi mdi-chevron-right"
                plain
                text
                rounded
                class="absolute opacity-20"
                style="top: 50%; right: -10px; transform: translateY(-50%)"
                @click="
                  moveUpload({
                    product: productUpload.product,
                    fromIndex: index,
                    toIndex: index + 1,
                  })
                "
              />
              <upload-component
                class="mb-2 w-5rem"
                :upload="upload"
                :size="'100'"
                :preview="true"
              />
              <span>{{ upload.filename }}</span>
              <p-button
                icon="mdi mdi-close"
                plain
                text
                class="absolute"
                style="top: -10px; right: -10px"
                @click="remove(productUpload.product, upload)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p-message class="pup-m-4" p-message severity="error" :closable="false">
          {{ ts.tModule('import_assets.match_failed') }}
        </p-message>
      </div>
    </div>

    <MatchToProductModal
      v-if="matchToProductModalActive"
      :in-action="matchToProductModalActive"
      :uploads="selectedUploads"
      @hide="matchToProduct(false)"
    />
  </div>
</template>
