<script setup lang="ts">
import { inject, ref } from 'vue';
import { productTableServiceKey } from '../../key/product-table-service.key';

const productTableService = inject(productTableServiceKey)!;

const op = ref();

const toggle = (event: PointerEvent): void => {
  op.value.toggle(event);
};
</script>

<template>
  <pButton
    v-tooltip.bottom="{ value: productTableService.ts.tModule('gtin_search'), class: 'text-sm' }"
    class="bg-white border-100 p-button-sm text-800"
    aria:haspopup="true"
    :label="productTableService.ts.tModule('gtins')"
    icon="mdi mdi-magnify text-xl"
    icon-pos="right"
    aria-controls="overlay_panel"
    @click="toggle"
  />
  <pPopover
    id="overlay_panel"
    ref="op"
    append-to="body"
    :show-close-icon="true"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <div>
      <h3 class="font-bold mb-3 text-2xl">{{ productTableService.ts.tModule('filters') }}</h3>
      <div class="field mb-3">
        <label class="mb-1" for="eans">{{ productTableService.ts.tModule('gtins') }}</label>
        <pTextarea
          id="eans"
          v-model="productTableService.gtinsSearch"
          class="w-full"
          rows="12"
          @change="productTableService.formatText()"
        />
        <small class="flex justify-content-end"
          ><span
            :class="
              productTableService.gtinsSearch.split('\n').length > 500
                ? 'text-red-500 font-bold'
                : ''
            "
          >
            {{
              productTableService.gtinsSearch
                ? productTableService.gtinsSearch.split('\n').length
                : 0
            }}</span
          >
          / {{ 500 }}</small
        >
      </div>
      <div
        class="bg-white border-100 border-top-1 bottom-0 justify-content-between mt-5 py-3 sticky"
      >
        <div class="flex justify-content-between ml-auto mr-0">
          <pButton
            plain
            text
            :label="productTableService.ts.tGlobal('clear')"
            class="mr-2"
            @click="
              (productTableService.gtinsSearch = ''),
                productTableService.applyGtinFilter(true),
                toggle($event)
            "
          />
          <pButton
            :label="productTableService.ts.tGlobal('apply')"
            @click="(event: PointerEvent ) =>{productTableService.applyGtinFilter(), toggle(event)}"
          />
        </div>
      </div>
    </div>
  </pPopover>
</template>
