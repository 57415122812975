import { ServiceManager } from './service.manager';
import { InjectionKey } from 'vue';
import { useRouter } from 'vue-router';

export function useService<T>(injectKey: string, type: T, ...args: any[]): any;

export function useService<T extends { new (): T } | { new (args: any): T }>(
  injectKey: InjectionKey<T>,
  type: T,
  ...args: any[]
): InstanceType<T>;

/**
 * Creates a service and keeps it alive until the route changes to a route with a different path.
 * Path is defined as the first part of the path, e.g. /supplier/overview -> supplier.
 * @param injectKey Key to inject service
 * @param type Type of service
 * @param args Arguments to pass to service
 */
export function useService<T extends { new (): T } | { new (args: any): T }>(
  injectKey: string | InjectionKey<T>,
  type: T,
  ...args: any[]
): InstanceType<T> {
  const serviceManager = new ServiceManager();
  const router = useRouter();

  router.beforeResolve((to, from, next) => {
    if (to.fullPath === '/productsup') {
      window.open('https://platform.productsup.com', '_blank');
    } else {
      const toPath = to.path.split('/')[1];
      const fromPath = from.path.split('/')[1];

      if (toPath !== fromPath) {
        serviceManager.unregisterService(injectKey);
      }

      next();
    }
  });

  return serviceManager.getService(injectKey, type, args);
}
