<script setup lang="ts">
import { inject, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { TextAreaFieldService } from '@/general/services/attribute-fields/field-services/text-area-field.service';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import WrapperField from './wrapper-field.vue';

/** Props */
interface Props {
  attributeField: AttributeField<string | undefined>;
  productAttributeField: ProductAttributeField<string | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

/** Services */
const textAreaFieldService = ref(
  new TextAreaFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
const ts = new TranslationService('retailer', 'products');
</script>

<template>
  <WrapperField :attribute-field-service="textAreaFieldService">
    <p-textarea
      v-model="textAreaFieldService.productAttributeFieldValue"
      :auto-resize="true"
      rows="5"
      class="w-full"
      :class="textAreaFieldService.getErrorTypeClass()"
      :placeholder="ts.tModule('textAreaField.placeholder')"
      :disabled="textAreaFieldService.productAttributeField.locked"
    />
  </WrapperField>
</template>
