<script setup lang="ts">
import { ref } from 'vue';
import TaskSelect from '@/general/ui/components/selects/task-select.vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { Action } from 'platform-unit2-api/actions';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');

/** Constants */
const store = useStore();
const selectedTask = ref<Action>();

const update = async () => {
  try {
    await store.dispatch('products/ATTACH_TO_TASK', {
      ids: props.ids,
      actionId: selectedTask.value ? selectedTask.value.id : null,
    });
    toastService.displaySuccessToast(ts.tModule('product_tasks.attached_successfully'));
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('product_tasks.attach_failed'));
  } finally {
    emit('hide');
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const updateButton = new CreateButton({
  label: ts.tGlobal('update'),
  position: CrudButtonPosition.RIGHT,
  onClick: update,
  isEnabled: () => selectedTask.value != null,
});

const handleUpdateVisible = () => {
  selectedTask.value = undefined;
  emit('hide');
};

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, updateButton],
});
</script>

<template>
  <BaseDialog
    :title="ts.tModule('product_tasks.attach_header', { params: { name: ids.length } })"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="handleUpdateVisible()"
  >
    <p-message severity="info" :closable="false" class="my-5">
      {{ ts.tModule('product_tasks.attach_info') }}
    </p-message>
    <TaskSelect v-model="selectedTask" :show-clear="false" />
  </BaseDialog>
</template>
