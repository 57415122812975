<script setup lang="ts">
import UserAvatar from 'ui/components/user-avatar.vue';
import { useRoute } from 'vue-router';
import { ref, onMounted, computed } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Action } from 'platform-unit2-api/actions';
import StatusChip from '@/general/ui/components/status-chip.vue';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { ProductsRestService } from 'platform-unit2-api/products';
import { formatDate } from '@/general/utils/format-date';

/** Props */
withDefaults(
  defineProps<{
    isActive: boolean;
  }>(),
  {
    isActive: false,
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const productApi = new ProductsRestService();

/** Consts */
const route = useRoute();
const loading = ref(false);
const tasks = ref<Action[]>([]);

const total = computed(() => {
  return tasks.value.length;
});
const loadAsyncData = async (): Promise<void> => {
  loading.value = true;
  try {
    if (Number.isNaN(route.params.id)) {
      return;
    }

    tasks.value = [];
    tasks.value = await productApi.getProductTasks(+route.params.id);
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed(ts.tModule('tasks.title')));
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  loadAsyncData();
});
</script>

<template>
  <pDrawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    :modal="false"
    :header="ts.tModule('tasks.title')"
    position="right"
    @update:visible="emit('hide')"
  >
    <div>
      <div v-if="loading">
        <div v-for="i in 3" :key="i" class="border-1 border-100 border-round mb-5 p-2">
          <p-skeleton width="18rem" height="2rem" class="mb-2" />
          <p-skeleton width="4rem" height="1.5rem" class="mb-5" />
          <p-skeleton width="100%" class="mb-3" />
          <p-skeleton width="100%" class="mb-3" />
          <p-skeleton width="100%" class="mb-3" />
          <p-skeleton width="100%" class="mb-3" />
          <p-skeleton width="100%" class="mb-3" />
        </div>
      </div>

      <div v-else-if="!(loading || total)">
        <p-message severity="info" :closable="false" class="m-2">
          {{ ts.tModule('tasks.noTasksFound') }}
        </p-message>
      </div>

      <div v-else>
        <p-card
          v-for="task in tasks"
          :key="task.id"
          class="mb-5 text-left"
          @click="$router.push({ name: 'update-task', params: { id: task.id.toString() } })"
        >
          <template #title>{{ task.title }}</template>
          <template #subtitle>
            <StatusChip
              :label="task.status?.label"
              :severity="ActionStatusSeverityEnum[task.status?.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
              label-only
            />
          </template>
          <template #content>
            <div>
              <table>
                <tbody>
                  <tr class="block mb-3">
                    <td style="width: 8rem" class="font-bold">
                      {{ ts.tModule('product_tasks.assignee') }}
                    </td>
                    <td>{{ task.owner?.name }}</td>
                  </tr>
                  <tr class="block mb-3">
                    <td style="width: 8rem" class="font-bold">
                      {{ ts.tModule('product_tasks.due_date') }}
                    </td>
                    <td>{{ formatDate(new Date(task.date)) }}</td>
                  </tr>
                  <tr class="block mb-3">
                    <td style="width: 8rem" class="font-bold">{{ ts.tGlobal('description') }}</td>
                    <td>{{ task.description }}</td>
                  </tr>
                  <tr class="block mb-3">
                    <td style="width: 8rem" class="font-bold">
                      {{ ts.tModule('product_tasks.related_products') }}
                    </td>
                    <td>
                      <p-avatar-group class="mb-3">
                        <p-avatar
                          v-for="product in task.products"
                          :key="product.id"
                          :image="
                            product.thumbnail?.public_thumbnail_url
                              ? product.thumbnail?.public_thumbnail_url
                              : '@/assets/images/no_thumbnail.svg'
                          "
                          size="small"
                          shape="circle"
                        />
                      </p-avatar-group>
                    </td>
                  </tr>
                  <p-inplace v-if="task.comments.length" class="p-0">
                    <template #display>
                      <tr class="block mb-3">
                        <td style="width: 8rem" class="font-bold">
                          {{ ts.tModule('product_tasks.comments') }}
                        </td>
                        <td>{{ ts.tModule('product_tasks.view_comments') }}</td>
                      </tr>
                    </template>

                    <template #content>
                      <tr class="block mb-3">
                        <td style="width: 8rem" class="font-bold">
                          {{ ts.tModule('product_tasks.comments') }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          v-for="comment in task.comments"
                          :key="comment.id"
                          class="block mb-3"
                          style="width: 100%"
                        >
                          <UserAvatar :username="comment.user?.name" class="mr-3" />
                          {{ comment.comment }}
                        </td>
                      </tr>
                    </template>
                  </p-inplace>
                </tbody>
              </table>
            </div>
          </template>
        </p-card>
      </div>
    </div>
  </pDrawer>
</template>
