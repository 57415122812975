<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { CreateExport } from 'retailer/modules/products/products.types';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Locale } from 'platform-unit2-api/locales';

/** Props */
interface Props {
  formData: CreateExport;
}
const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (
    e: 'nextPage',
    pageObject: {
      pageIndex: number;
      selectedLocale?: Locale;
      includeAssets: boolean;
      includeData: boolean;
      useOverwrittenNames: boolean;
    },
  ): void;
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

const ts = new TranslationService('retailer', 'products');
const selectedLocale = ref<Locale>();
const includeAssets = ref(false);
const includeData = ref(true);
const useOverwrittenNames = ref<boolean>(false);
const loading = ref(true);
const valid = ref(true);

const nextPage = () => {
  emit('nextPage', {
    pageIndex: 0,
    selectedLocale: selectedLocale.value,
    includeAssets: includeAssets.value,
    includeData: includeData.value,
    useOverwrittenNames: useOverwrittenNames.value,
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 0,
  });
};

onMounted(() => {
  setTimeout(() => {
    if (props.formData.selectedLocale) {
      selectedLocale.value = props.formData.selectedLocale;
    }

    if (props.formData.includeData) {
      includeData.value = props.formData.includeData;
    } else if (props.formData.includeData == null) {
      includeData.value = true;
    }

    if (props.formData.includeAssets) {
      includeAssets.value = props.formData.includeAssets;
    }

    if (props.formData.useOverwrittenNames) {
      useOverwrittenNames.value = props.formData.useOverwrittenNames;
    }

    loading.value = false;
  }, 500);
});
</script>
<template>
  <div>
    <LoadingIndicator v-if="loading" class="pb-4 px-4" />
    <div v-else class="px-4">
      <div class="mb-3">
        <div class="mb-5">
          <div class="block mb-0 text-center">
            <h3 class="text-4xl">
              {{ ts.tModule('export_modal.generic.title') }}
            </h3>
          </div>

          <div class="block my-2 text-center">
            <p class="font-normal text-gray-700 text-xl">
              {{ ts.tModule('export_modal.generic.question') }}
            </p>
          </div>
        </div>
        <div class="field mb-3">
          <LocaleSelect v-model="selectedLocale" hide-label />
        </div>
        <div class="field-checkbox">
          <p-checkbox v-model="useOverwrittenNames" binary="true" />
          <label>{{ ts.tModule('export_modal.generic.use_overwritten_names') }}</label>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!loading">
    <p-divider class="flex my-0 px-0 w-full" />
    <div
      class="border-round-bottom-md flex flex-row justify-content-between px-4 py-4"
      style="background-color: #f5f7fa"
    >
      <div>
        <p-button
          :label="ts.tGlobal('cancel')"
          plain
          text
          class="mr-2"
          @click="emit('cancel-export')"
        />
      </div>
      <div>
        <p-button
          :label="ts.tGlobal('previous')"
          severity="secondary"
          class="mr-2"
          icon="mdi mdi-chevron-left"
          icon-pos="left"
          @click="previousPage()"
        />
        <p-button
          :label="ts.tGlobal('next')"
          :disabled="!valid || !selectedLocale"
          icon="mdi mdi-chevron-right"
          icon-pos="right"
          @click="nextPage"
        />
      </div>
    </div>
  </div>
</template>
