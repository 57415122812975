<script setup lang="ts">
import AssetsUploader from 'supplier/modules/uploads/components/assets-uploader.vue';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';

// Constants
const service = useService(assetViewServiceKey, AssetViewService);
</script>

<template>
  <div class="pup-m-4">
    <AssetsUploader
      :has-assets="(service?.importAssetsModal.partialObject.data?.length ?? 0) > 0"
      @uploaded="(assets) => service?.importAssetsModal.upload(assets ?? [])"
    />
  </div>
</template>
