<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <div class="flex pb-0">
    <pInputText
      v-if="service.datamodel"
      v-model="service.datamodel.name"
      type="text"
      class="align-items-center mr-2 w-8"
      :placeholder="service.ts.tModule('datamodel_attributes.input_datamodel_name')"
    />
    <!-- Search Bar -->
    <pIconField icon-position="left" class="w-4">
      <pInputIcon class="pi pi-search" />
      <pInputText
        v-model="service.searchKey"
        type="text"
        class="w-full"
        :placeholder="service.ts.tModule('datamodel_attributes.search_attribute')"
      />
    </pIconField>
  </div>
</template>
