//vue
import { Component } from 'vue';

//utils
import { v4 as uuidv4 } from 'uuid';

export abstract class BaseStoryModule<StoryModule> {
  abstract component: Component;
  data: StoryModule;
  uuid: string;
  abstract moduleTranslationKey: string;

  constructor(data?: StoryModule) {
    this.uuid = uuidv4();
    this.data = {} as StoryModule;
    if (data != null) {
      this.data = data;
    }
  }
}
