<script setup lang="ts">
import { inject } from 'vue';

import pDataTable from 'primevue/datatable';
import { tsKey } from '@/general/services/translations/translation.key';
import ImportMappingEmptyState from './import-mapping-empty-state.vue';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import { MenuItem } from 'primevue/menuitem';
import { ImportMapping } from 'platform-unit2-api/import-mappings';
import { formatDate } from '@/general/utils/format-date';
import { useRouter } from 'vue-router';
import { useRetailerImportMappingService } from '../../ts/useImportMappingService';
import { RouterService } from '@/core/router/router.service';

const ts = inject(tsKey)!;
const service = useRetailerImportMappingService();

const menuItems = (mapping: ImportMapping): MenuItem[] => [
  {
    label: ts.value.tGlobal('edit'),
    command: () => {
      RouterService.getInstance().router.push({
        name: 'edit-import-mapping',
        params: { id: mapping.id },
      });
    },
    icon: 'mdi mdi-pencil',
  },
  {
    label: ts.value.tGlobal('rename'),
    command: () => service.openCrudComponent(mapping, false),
    icon: 'mdi mdi-form-textbox',
  },
  {
    label: ts.value.tGlobal('duplicate'),
    command: () => service.openDuplicateDialog(mapping),
    icon: 'mdi mdi-content-copy',
  },
  {
    separator: true,
  },
  {
    label: ts.value.tGlobal('delete'),
    command: () => service.deleteAction(mapping.id),
    icon: 'mdi mdi-delete',
    class: 'delete',
  },
];

const router = useRouter();
</script>

<template>
  <pDataTable
    v-if="service != null && ts != null"
    scrollable
    scroll-height="flex"
    :paginator="service.total > (service.limit ?? 0)"
    lazy
    removable-sort
    row-hover
    auto-layout
    :loading="service.isLoadingOverView"
    :value="service.mappings"
    :rows="service.limit"
    :first="service.first"
    :total-records="service.total"
    :sort-field="service.sortField"
    :sort-order="service.sortOrder"
    @page="service?.onPageChange($event.page)"
    @row-click="router.push({ name: 'edit-import-mapping', params: { id: $event.data.id } })"
    @sort="service?.onSortChange($event)"
  >
    <p-column field="name" :header="ts.tGlobal('name')" />

    <p-column :header="ts.tGlobal('datamodel')">
      <template #body="{ data }">
        {{ data.datamodel?.name }}
      </template>
    </p-column>
    <p-column :header="ts.tModule('attributes_mapped')">
      <template #body="{ data }">
        {{ data.mapping_items_count }}/{{ data.datamodel?.attributes_count }}
      </template>
    </p-column>
    <p-column :header="ts.tGlobal('lastUpdate')">
      <template #body="{ data }">
        {{ formatDate(data.updated_at) }}
      </template>
    </p-column>
    <p-column :header="ts.tGlobal('actions')">
      <template #body="{ data }">
        <ActionsMenu :menu-items="menuItems(data)" />
      </template>
    </p-column>
    <template #empty>
      <ImportMappingEmptyState />
    </template>
  </pDataTable>
</template>

<style scoped lang="scss">
:deep(.p-datatable-wrapper) {
  height: 100% !important;
}
</style>
