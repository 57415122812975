import { RouteRecordRaw } from 'vue-router';
import Editor from 'supplier/modules/editor/views/editor.view.vue';
import { RouteFactory } from '@/core/router/route.factory';

const toolsRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/tools/editor',
    name: 'Html editor',
    props: {
      header: {
        createButtonText: 'Insert HTML',
      },
    },
    component: Editor,
    title: 'Tools',
    translations: {
      platform: 'supplier',
      module: 'editor',
    },
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-html-editor',
      }),
    ],
  }),
];

export default toolsRoutes;
