<script setup lang="ts">
import Prism from 'prismjs';
import { onMounted, ref, watch } from 'vue';

// Props
interface Props {
  modelValue: string;
  plugins?: string;
  language: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  plugins: '',
  language: '',
});

// Emits
const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void;
}>();

// Constants
const highlightedCode = ref('');

const highlight = () => {
  highlightedCode.value = Prism.highlight(
    props.modelValue.trim(),
    Prism.languages[props.language],
    props.language,
  );
  emit('update:modelValue', highlightedCode.value);
};

// Lifecycle hooks
watch(
  () => props.modelValue,
  () => highlight(),
);

onMounted(() => {
  highlight();
});
</script>

<template>
  <pre :class="[`language-${language}`, plugins]" class="">
    <code ref="code" :class="`language-${language}`" v-html="highlightedCode"/>
  </pre>
</template>
