import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { KeyValueOptions } from 'platform-unit2-api/attributes';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class KeyValueFieldService extends AttributeFieldService<KeyValueOptions[] | undefined> {
  constructor(
    attributeField: AttributeField<KeyValueOptions[] | undefined>,
    productAttributeField: ProductAttributeField<KeyValueOptions[] | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }

  /*
   * Modifyes the array into a string so that it can be used in the p-dropdown component
   */
  get productAttributeFieldSingleArrayItem(): KeyValueOptions | undefined {
    return this.productAttributeField.value?.[0] ?? undefined;
  }

  /*
   * Sets the selected option back into array so that it can be send to the backend in the correct type
   */
  set productAttributeFieldSingleArrayItem(value: KeyValueOptions | undefined) {
    this._productAttributeField.value = value != null ? [value as KeyValueOptions] : undefined;
    this.updateValue(this.productAttributeField.value);
  }

  public validate(): boolean {
    // Remove null and undefined values from array
    if (this.productAttributeField.value?.filter(Boolean).length === 0) {
      this._productAttributeField.value = undefined;
    }

    return super.validate();
  }
}
