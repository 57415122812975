import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Retrieve JSON Schema',
  subTitle: 'Here you can retrieve a JSON Schema.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete JSON Schema',
};

const update: TranslationObjectAction = {
  title: 'Update the JSON Schema',
  subTitle: '',
};

const translationObject: TranslationObject = {
  title: 'JSON Schema',
  create: create,
  delete: deleteTranslation,
  update: update,

  emptyStateTitle: 'There are no JSON schema retrieved yet.',
  emptyStateSubtitle:
    'Start by retrieving a JSON schema. When a JSON schema is retirieved it will show here.',

  tableHeaders: {
    productType: 'Product Type',
    channel: 'Channel',
    marketplaceId: 'Marketplace ID',
    merchant: 'Merchant',
    language: 'Language',
    lastUpdated: 'Last updated on',
  },

  menuItems: {
    viewInBrowser: 'View in browser',
  },

  // CRUD Dialog
  retrieveJsonSchema: {
    dialogTitle: 'Retrieve JSON Schema',
    amazonCentral: 'Amazon Central',
    submitButtonLabel: 'Retrieve',
    amazonMerchant: 'Amazon Merchant',
    vendor: 'Vendor',
    seller: 'Seller',
    selectLanguage: 'Select language',
    retrieveSuccess: 'JSON schema retrieved successfully',
    retrieveError: 'Error while retrieving JSON schema',
  },

  downloadJsonSchema: {
    downloadSuccess: 'JSON schema downloaded successfully',
    downloadError: 'There was an error in downloading the JSON schema. Please try again.',
  },
  openJsonSchema: { openError: 'There was an error in opening the JSON schema. Please try again.' },
};

const translation: Translation = {
  jsonSchemas: translationObject,
};

export default translation;
