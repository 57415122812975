export enum ContentStoryModulesEnum {
  ThreeImageText = 'three_image_text',
  SingleImageLogo = 'single_image_logo',
  SingleImageSpecsDetail = 'standard_single_image_specs_detail',
  FourImageText = 'four_image_text',
  FourQuadrantText = 'four_quadrant_image_text',
  StandardText = 'standard_text',
  ProductDescriptionText = 'product_description_text',
  HeaderImageText = 'header_image_text',
  SingleSideImage = 'single_side_image',
  MultipleImageText = 'multiple_image_text',
  ImageSidebar = 'standard_image_sidebar',
  SingleImageHighlights = 'standard_single_image_highlights',
}
