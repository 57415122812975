import { AttributeField } from 'platform-unit2-api/attribute-fields';
import { AttributeTypeEnum, KeyValueOptions } from 'platform-unit2-api/attributes';
import { Brand, BrandCompact } from 'platform-unit2-api/brands';
import { Category, CategoryCompact } from 'platform-unit2-api/categories';
import { InputSelectType } from '../interfaces/input-select.interface';
import { AdvancedFieldService } from '../advanced-field.service';
import { AdvancedFieldPanel } from '../interfaces/advanced-field-tab.interface';

export class GroupFieldService extends AdvancedFieldService {
  currentInstances(localeId: number): AttributeField<any>[][] {
    return this.panels.get(localeId)?.map((tab) => tab.attributes) ?? [];
  }

  public toStringField<T>(attributeField: AttributeField<T>, value?: T | null): string {
    if (value == null) {
      return '...';
    }

    switch (attributeField.attribute.options.type) {
      case AttributeTypeEnum.BRAND_FIELD:
      case AttributeTypeEnum.CATEGORY_FIELD:
        return ((<any>value) as Brand | BrandCompact | Category | CategoryCompact).name;
      case AttributeTypeEnum.DATE_TIME_FIELD:
        return new Date(value as string).toDateString();
      case AttributeTypeEnum.FINANCIAL_FIELD:
      case AttributeTypeEnum.INPUT_SELECT_FIELD:
        return `${(value as InputSelectType).decimal ?? ''} ${
          (value as InputSelectType).unit ?? ''
        }`;
      case AttributeTypeEnum.KEY_VALUE_FIELD:
        return (
          (attributeField as unknown as AttributeField<KeyValueOptions[] | undefined>).values?.[0]
            .value?.[0].value ?? '...'
        );
      case AttributeTypeEnum.MULTIPLE_CHOICE_FIELD:
      case AttributeTypeEnum.TAG_FIELD:
        return value.toString();
      case AttributeTypeEnum.RICHT_TEXT_FIELD:
        return (value as string).replace('&nbsp;', ' ')?.replace(/<[^>]*>?/gm, '') ?? '...';
      case AttributeTypeEnum.SWITCH_FIELD:
      case AttributeTypeEnum.GROUP_FIELD:
        return attributeField.attribute.key;
      default:
        return value.toString() ?? '...';
    }
  }

  public getAccordionValue<T>(attributeFields: AttributeField<T>[], localeId?: number): string {
    return this.toStringField<T>(
      attributeFields[0],
      attributeFields[0]?.values?.find((val) => val.locale.id === localeId)?.value,
    );
  }

  public resetGroupConfirm(localeId: number, path: string | null, accept: any) {
    this._confirmService.confirmReset({
      group: this._confirmGroup,
      message: this.ts.t('general.components.groupField.resetsConfirmMessage'),
      callback: async () => {
        this._deleteGroupChild(localeId, path);
        await accept();
        if (this.panels.get(localeId)?.every((tab) => tab.deleted)) {
          await this.addNewPreset(localeId);
        }
      },
    });
  }

  public deleteGroupConfirm(localeId: number, path: string | null, accept: any) {
    this._confirmService.confirmDelete({
      group: this._confirmGroup,
      message: this.ts.t('general.components.groupField.deleteConfirmMessage'),
      callback: async () => {
        this._deleteGroupChild(localeId, path);
        await accept();
        if (this.panels.get(localeId)?.every((tab) => tab.deleted)) {
          await this.addNewPreset(localeId);
        }
      },
    });
  }

  protected _deleteGroupChild(localeId: number, path: string | null) {
    const group = this.panels.get(localeId)?.find((tab) => tab.path === path);

    if (group) {
      group.deleted = true;
      const tabs = this.panels.get(localeId);
      if (tabs?.indexOf(group)) {
        tabs[tabs.indexOf(group)] = group;
        this.panels.set(localeId, tabs);
      }
    }

    this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(localeId));
    this._fieldFunctions?.handleDirtyState?.(this.attributeField, this.createPayload(localeId));
  }

  public changeTheIndexInGroupMap(localeId: number, newItems: AdvancedFieldPanel[]) {
    this.reordered = true;
    const attributesArray = newItems.map((tab) => tab.attributes);
    const child = this._attributeField.children?.find((attr) => attr.locale?.id === localeId);

    if (child?.instances) {
      child.instances = attributesArray;
    }

    this.panels.set(localeId, newItems);
    this._fieldFunctions?.addToUpdatedFields?.(this.createPayload(localeId));
    this._fieldFunctions?.handleDirtyState?.(this.attributeField, this.createPayload(localeId));
  }
}
