<script setup lang="ts">
import { Product } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import ProductSearchSelect from '@/general/ui/components/selects/product-search-select.vue';

// Properties
export interface Props {
  uploads: (Upload | undefined)[];
  inAction?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inAction: false,
});

// Emits
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'imports');

// Constants
const store = useStore();
const selectedProduct = ref<Product>();

const match = () => {
  if (selectedProduct.value) {
    store.dispatch('matchMedia/CONNECT_UPLOADS_TO_PRODUCT', {
      uploads: props.uploads,
      product: selectedProduct.value,
    });
  }

  emit('hide');
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const attachButton = new CreateButton({
  label: ts.tModule('import_assets.match_product'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => selectedProduct.value != null,
  onClick: match,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, attachButton],
});
</script>

<template>
  <BaseDialog
    :title="ts.tModule('import_assets.match_product')"
    :visible="inAction"
    size="medium"
    :buttons-options="buttonsOptions"
    @update:visible="emit('hide')"
  >
    <div class="field mb-3">
      <label>{{ ts.tModule('import_assets.select_product') }}</label>
      <ProductSearchSelect v-model="selectedProduct" hide-label />
    </div>
  </BaseDialog>
</template>
