<script setup lang="ts">
import { ref, onMounted } from 'vue';
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Locale } from 'platform-unit2-api/locales';
import { Module } from 'platform-unit2-api/modules';
import { CreateExport } from '../../ts/products.types';

/** Props */
interface Props {
  formData: CreateExport;
}
const props = defineProps<Props>();

/** Emits */
const emit = defineEmits<{
  (
    e: 'nextPage',
    pageObject: {
      pageIndex: number;
      selectedLocale?: Locale;
      selectedModule?: Module;
    },
  ): void;
  (
    e: 'prevPage',
    pageObject: {
      pageIndex: number;
    },
  ): void;
  (e: 'cancel-export'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const selectedLocale = ref<Locale>();
const selectedChannel = ref<Module>();
const loading = ref(true);

const nextPage = () => {
  emit('nextPage', {
    pageIndex: 0,
    selectedLocale: selectedLocale.value,
    selectedModule: selectedChannel.value,
  });
};

const previousPage = () => {
  emit('prevPage', {
    pageIndex: 0,
  });
};

onMounted(() => {
  setTimeout(() => {
    if (props.formData.selectedLocale) {
      selectedLocale.value = props.formData.selectedLocale;
    }

    if (props.formData.selectedModule) {
      selectedChannel.value = props.formData.selectedModule;
    }

    loading.value = false;
  }, 500);
});
</script>

<template>
  <div class="mt-5">
    <LoadingIndicator v-if="loading" />
    <div v-else class="px-4">
      <div class="field mb-3">
        <ClientModuleSelect v-model="selectedChannel" type="export" />
      </div>

      <div class="field mb-3">
        <label>{{ ts.tGlobal('select_language') }}</label>
        <LocaleSelect v-model="selectedLocale" hide-label />
      </div>
    </div>

    <div
      class="border-round-bottom-md flex flex-row justify-content-between px-4 py-4"
      style="background-color: #f5f7fa"
    >
      <div>
        <p-button
          :label="ts.tGlobal('cancel')"
          plain
          text
          class="mr-2"
          @click="emit('cancel-export')"
        />
      </div>
      <div>
        <p-button
          :label="ts.tGlobal('previous')"
          severity="secondary"
          class="mr-2"
          icon="mdi mdi-chevron-left"
          icon-pos="left"
          @click="previousPage()"
        />
        <p-button
          :label="ts.tGlobal('next')"
          :disabled="!selectedLocale || !selectedChannel"
          icon="mdi mdi-chevron-right"
          icon-pos="right"
          @click="nextPage"
        />
      </div>
    </div>
  </div>
</template>
