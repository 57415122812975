<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { ModulesRestService, Module } from 'platform-unit2-api/modules';
import { ProductsRestService } from 'platform-unit2-api/products';
import { DefaultSelectProps } from './ts/default-select-props.interface';
import { PaginationObject } from 'platform-unit2-api/core';

interface Props extends DefaultSelectProps<Module> {
  type?: string;
  workspaceId?: number;
  onlyUsedByProducts?: boolean;
  paginationParams?: PaginationObject;
}

const props = defineProps<Props>();
const restService: any = props.onlyUsedByProducts ? ProductsRestService : ModulesRestService;
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    :fetch-function-name="onlyUsedByProducts ? 'searchProductModules' : 'getAll'"
    :custom-fetch-params="
      onlyUsedByProducts ? [] : [{ type: props.type, workspace_id: props.workspaceId }]
    "
    :pagination-params="props.paginationParams"
    option-label="name"
    module-name="channel"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
