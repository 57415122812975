<script setup lang="ts">
import { inject, watchEffect } from 'vue';
import StepperManager from '@/general/ui/components/stepper-manager.vue';
import { taskViewServiceKey } from '../ts/keys/task-view-service.key';
import { TranslationService } from '@/general/services/translations/translation.service';

// Props
interface Props {
  ids?: number[];
}
const props = defineProps<Props>();
const service = inject(taskViewServiceKey);
const ts = new TranslationService('supplier', 'tasks');

watchEffect(() => service && (service.productsIds = props.ids ?? []));
</script>
<template>
  <StepperManager
    :is-active="service?.isCreating ?? false"
    size="medium"
    :header="ts.moduleCreateTitle"
    :disable-buttons="service?.getDisabledButtonsConditions"
    :child-routes="service?.createTaskChildRoutes ?? []"
    @hide="service?.closeCrudComponent()"
    @complete="service?.save()"
  />
</template>
