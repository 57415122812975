export class QueueService {
  private static _instance: QueueService;

  private static _getInstance(): QueueService {
    if (QueueService._instance == null) {
      QueueService._instance = new QueueService();
    }

    return QueueService._instance;
  }

  public static getInstance(): QueueService {
    return QueueService._getInstance();
  }

  public static get instance(): QueueService {
    return QueueService._getInstance();
  }

  constructor() {
    if (QueueService._instance) {
      return QueueService._instance;
    }

    QueueService._instance = this;
  }

  private keys = new Map();

  private async wait(key: string, ms = 100, timeout = 10000) {
    const max = timeout / ms;
    let i = 0;

    await new Promise((resolve, reject) => {
      const check = setInterval(() => {
        if (!this.keys.get(key)) {
          clearInterval(check);

          //Value doesn't matter, just to resolve the promise.
          resolve(undefined);
        }

        if (i > max) {
          clearInterval(check);
          reject(new Error(`'Timeout while waiting job to finish, key: '${key}' `));
        }

        i++;
      }, ms);
    });
  }

  private async executeFunc(key: string, func: () => Promise<any>) {
    this.keys.set(key, true);
    try {
      return await func();
    } finally {
      this.keys.set(key, false);
    }
  }

  public async queue(key: string, func: () => Promise<any>, ms?: number, timeout?: number) {
    await this.wait(key, ms, timeout);
    return await this.executeFunc(key, func);
  }
}
