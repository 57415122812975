import { App } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import { Router } from 'vue-router';

export function installSentry(app: App, router: Router) {
  const isLocal =
    location.href.includes('localhost') ||
    location.href.includes('127.0.0.1') ||
    location.href.includes(':8080') ||
    location.href.includes(':8081');

  if (isLocal) {
    return;
  }

  let environment = 'development';
  const tracingOrigins = [];

  if (location.href.indexOf('staging') !== -1) {
    environment = 'staging';
    tracingOrigins.push('worldofcontent.com');
  }

  if (location.href.indexOf('retail.productsup.com') !== -1) {
    environment = 'production';
    tracingOrigins.push('retail.productsup.com');
  }

  Sentry.init({
    app,
    dsn: 'https://4614690b4d5f405aa4748f55723894c9@o321398.ingest.sentry.io/5219071',
    environment: environment,
    attachProps: true,
    logErrors: true,
    release: `woc-frontend@${import.meta.env.VITE_APP_VERSION}`,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: tracingOrigins,
      }),
    ],
  });

  Sentry.setTag('commit', import.meta.env.VITE_APP_COMMIT);
}
