<template>
  <span class="loader-wrapper">
    <div class="loader">
      <svg
        width="63"
        height="63"
        viewBox="0 0 63 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.9969 2C22.9338 2 21.9145 2.4232 21.164 3.17613L2.73136 21.6682C2.10266 22.2989 2.54992 23.3749 3.44046 23.3742L37.723 23.3447C38.8269 23.3437 39.7228 24.2373 39.7247 25.3412L39.7837 59.5888C39.7852 60.4793 40.8623 60.9238 41.4914 60.2936L59.8596 41.8948C60.6083 41.1449 61.0288 40.1285 61.0288 39.0687V4C61.0288 2.89543 60.1334 2 59.0288 2H23.9969Z"
          stroke="#DEE5EC"
          stroke-width="3"
        />
      </svg>
      <svg
        class="trace"
        width="63"
        height="63"
        viewBox="0 0 63 63"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="path"
          d="M23.9969 2C22.9338 2 21.9145 2.4232 21.164 3.17613L2.73136 21.6682C2.10266 22.2989 2.54992 23.3749 3.44046 23.3742L37.723 23.3447C38.8269 23.3437 39.7228 24.2373 39.7247 25.3412L39.7837 59.5888C39.7852 60.4793 40.8623 60.9238 41.4914 60.2936L59.8596 41.8948C60.6083 41.1449 61.0288 40.1285 61.0288 39.0687V4C61.0288 2.89543 60.1334 2 59.0288 2H23.9969Z"
          stroke="#DEE5EC"
          stroke-width="3"
        />
      </svg>
    </div>
  </span>
</template>

<style lang="scss" scoped>
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.loader {
  display: inline-block;
  margin: auto;
  position: relative;
}
.trace {
  position: absolute;
  left: 0;
  top: 0;
  stroke-linecap: round;
}
.path {
  fill: #fff0;
  stroke: #0089d7;
  stroke-dasharray: 34.67 69.34;
  stroke-dashoffset: 208.03;
  animation: dash 3s linear infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 208.03;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
