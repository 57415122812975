import { Component, markRaw } from 'vue';
import { MultipleImageText } from '../story-types/modules/multiple-image-text.interface';
import { BaseStoryModule } from './base-story-module.class';
import StandardMultipleImageText from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-multiple-image-text.vue';
import { IContentImage } from './interfaces/IContentImage.interface';
import { Upload } from 'platform-unit2-api/uploads';
import i18n from '@/core/i18n';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ImageTextCaptionBlock } from '../story-types/image-text-caption-block.interface';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export class MultipleImageTextClass
  extends BaseStoryModule<MultipleImageText>
  implements IContentImage<0 | 1 | 2 | 3 | undefined>
{
  ts: TranslationService;
  minimumImageSize: `${string} : ${string}` = '300 : 300';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';
  component: Component = markRaw(StandardMultipleImageText);
  moduleTranslationKey = 'storyBuilder.multipleImageTextModuleDisplayName';

  highlightedBlock: ImageTextCaptionBlock | undefined;

  highlightedBlockIndex?: 0 | 1 | 2 | 3;

  private _ImageValidatorService: ImageValidatorService;

  /**
   *
   */
  constructor(data?: MultipleImageText) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._ImageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (!data) {
      this.data.type = ContentStoryModulesEnum.MultipleImageText;
      this.data.blocks = [
        {
          type: ModuleComponentTypes.ImageTextCaption,
          caption: { type: ModuleComponentTypes.Text, value: '' },
          block: {
            type: ModuleComponentTypes.ImageTextBlock,
            image: {
              uploadDestinationId: undefined,
              altText: '',
              type: ModuleComponentTypes.Image,
            },
            body: {
              type: ModuleComponentTypes.TextArea,
              value: '',
            },
            headline: {
              type: ModuleComponentTypes.Text,
              value: '',
            },
          },
        },
        {
          type: ModuleComponentTypes.ImageTextCaption,
          caption: { type: ModuleComponentTypes.Text, value: '' },
          block: {
            type: ModuleComponentTypes.ImageTextBlock,
            image: {
              uploadDestinationId: undefined,
              altText: '',
              type: ModuleComponentTypes.Image,
            },
            body: {
              type: ModuleComponentTypes.TextArea,
              value: '',
            },
            headline: {
              type: ModuleComponentTypes.Text,
              value: '',
            },
          },
        },
        {
          type: ModuleComponentTypes.ImageTextCaption,
          caption: { type: ModuleComponentTypes.Text, value: '' },
          block: {
            type: ModuleComponentTypes.ImageTextBlock,
            image: {
              uploadDestinationId: undefined,
              altText: '',
              type: ModuleComponentTypes.Image,
            },
            body: {
              type: ModuleComponentTypes.TextArea,
              value: '',
            },
            headline: {
              type: ModuleComponentTypes.Text,
              value: '',
            },
          },
        },
        {
          type: ModuleComponentTypes.ImageTextCaption,
          caption: { type: ModuleComponentTypes.Text, value: '' },
          block: {
            type: ModuleComponentTypes.ImageTextBlock,
            image: {
              uploadDestinationId: undefined,
              altText: '',
              type: ModuleComponentTypes.Image,
            },
            body: {
              type: ModuleComponentTypes.TextArea,
              value: '',
            },
            headline: {
              type: ModuleComponentTypes.Text,
              value: '',
            },
          },
        },
      ];
    }

    this.highlightedBlockIndex = 0;
    this.highlightedBlock = (this.data.blocks ?? [])[0] ?? undefined;
  }

  getAltText(selector?: 0 | 2 | 1 | 3 | undefined): string | undefined {
    if (selector == null && selector != 0) {
      return '';
    }

    if (this.data.blocks == null) {
      return '';
    }

    return this.data.blocks[selector]?.block?.image?.altText ?? '';
  }

  setAltText(altText?: string | undefined, selector?: 0 | 2 | 1 | 3 | undefined): void {
    if (selector == null && selector != 0) {
      return;
    }

    if (this.data.blocks != null) {
      this.data.blocks[selector] = {
        type: ModuleComponentTypes.ImageTextCaption,
        caption: this.data.blocks[selector].caption,
        block: {
          type: ModuleComponentTypes.ImageTextBlock,
          image: {
            uploadDestinationId: this.data.blocks[selector].block?.image?.uploadDestinationId ?? '',
            altText: altText ?? '',
            type: ModuleComponentTypes.Image,
          },
          body: this.data.blocks[selector].block?.body,
          headline: this.data.blocks[selector].block?.headline,
        },
      };
    }
  }

  //#region images
  setUploadData(upload: Upload, index: 0 | 1 | 2 | 3): void {
    if (this.data.blocks != null && this._ImageValidatorService.hasCorrectDimensions(upload)) {
      this.data.blocks[index] = {
        type: ModuleComponentTypes.ImageTextCaption,
        caption: this.data.blocks[index]
          ? this.data.blocks[index].caption
          : { type: ModuleComponentTypes.Text, value: '' },
        block: {
          type: ModuleComponentTypes.ImageTextBlock,
          image: {
            uploadDestinationId: upload.public_url,
            altText: this.data.blocks[index]?.block?.image?.altText ?? '',
            type: ModuleComponentTypes.Image,
          },
          body: this.data.blocks[index]
            ? this.data.blocks[index].block?.body
            : {
                type: ModuleComponentTypes.TextArea,
                value: '',
              },
          headline: this.data.blocks[index]
            ? this.data.blocks[index].block?.headline
            : {
                type: ModuleComponentTypes.Text,
                value: '',
              },
        },
      };
    }

    this.setHighlightedBlock(index);
  }
  hasUploadSet(index: 0 | 1 | 2 | 3): boolean {
    if (this.data.blocks != null && this.data.blocks[index] != null) {
      return this.data.blocks[index].block?.image?.uploadDestinationId ? true : false;
    }

    return false;
  }
  getUploadUrl(index: 0 | 1 | 2 | 3): string | undefined {
    if (this.data.blocks != null && this.data.blocks[index] != null) {
      return this.data.blocks[index].block?.image?.uploadDestinationId ?? undefined;
    }

    return undefined;
  }
  //#endregion

  //#region captions
  setCaption(index: 0 | 1 | 2 | 3, value?: string): void {
    if (this.data.blocks != null && value) {
      this.data.blocks[index] = {
        type: ModuleComponentTypes.ImageTextCaption,
        caption: { type: ModuleComponentTypes.Text, value: value },
        block: {
          type: ModuleComponentTypes.ImageTextBlock,
          image: this.data.blocks[index]
            ? this.data.blocks[index].block?.image
            : {
                uploadDestinationId: undefined,
                altText: '',
                type: ModuleComponentTypes.Image,
              },
          body: this.data.blocks[index]
            ? this.data.blocks[index].block?.body
            : {
                type: ModuleComponentTypes.TextArea,
                value: '',
              },
          headline: this.data.blocks[index]
            ? this.data.blocks[index].block?.headline
            : {
                type: ModuleComponentTypes.Text,
                value: '',
              },
        },
      };
    }
  }

  getCaption(index: 0 | 1 | 2 | 3): string | undefined {
    if (this.data.blocks != null && this.data.blocks[index] != null) {
      return this.data.blocks[index].caption?.value ?? undefined;
    }

    return undefined;
  }

  //#endregion

  //#region headline
  setHeadline(index?: 0 | 1 | 2 | 3, value?: string): void {
    if (index == undefined && index != 0) {
      return;
    }

    if (this.data.blocks != null && value) {
      this.data.blocks[index] = {
        type: ModuleComponentTypes.ImageTextCaption,
        caption: this.data.blocks[index]
          ? this.data.blocks[index].caption
          : { type: ModuleComponentTypes.Text, value: '' },
        block: {
          type: ModuleComponentTypes.ImageTextBlock,
          image: this.data.blocks[index]
            ? this.data.blocks[index].block?.image
            : {
                uploadDestinationId: undefined,
                altText: '',
                type: ModuleComponentTypes.Image,
              },
          body: this.data.blocks[index]
            ? this.data.blocks[index].block?.body
            : {
                type: ModuleComponentTypes.TextArea,
                value: '',
              },
          headline: {
            type: ModuleComponentTypes.Text,
            value: value,
          },
        },
      };
    }
  }

  //#endregion

  //#region body

  setBody(index?: 0 | 1 | 2 | 3, value?: string): void {
    if (index == undefined && index != 0) {
      return;
    }

    if (this.data.blocks != null && value) {
      this.data.blocks[index] = {
        type: ModuleComponentTypes.ImageTextCaption,
        caption: this.data.blocks[index]
          ? this.data.blocks[index].caption
          : { type: ModuleComponentTypes.Text, value: '' },
        block: {
          type: ModuleComponentTypes.ImageTextBlock,
          image: this.data.blocks[index]
            ? this.data.blocks[index].block?.image
            : {
                uploadDestinationId: undefined,
                altText: '',
                type: ModuleComponentTypes.Image,
              },
          body: {
            type: ModuleComponentTypes.TextArea,
            value: value,
          },
          headline: this.data.blocks[index]
            ? this.data.blocks[index].block?.headline
            : {
                type: ModuleComponentTypes.Text,
                value: '',
              },
        },
      };
    }
  }

  //#endregion

  //#region highlight

  setHighlightedBlock(index: 0 | 1 | 2 | 3): void {
    this.highlightedBlockIndex = index;
    this.highlightedBlock = this.data.blocks![index];
  }

  getHighlightedBlock(): ImageTextCaptionBlock | undefined {
    return this.highlightedBlock;
  }
  //#endregion
}
