import { RouteFactory } from '@/core/router/route.factory';
import Assign from 'supplier/modules/tasks/components/add-task/assign.view.vue';
import Describe from 'supplier/modules/tasks/components/add-task/describe.view.vue';
import Settings from 'supplier/modules/tasks/components/add-task/settings.view.vue';
import Tasks from 'supplier/modules/tasks/views/tasks.view.vue';
import { RouteRecordRaw } from 'vue-router';

const factory = new RouteFactory({
  platform: 'retailer',
  module: 'tasks',
});

const taskRoutes: RouteRecordRaw[] = [
  factory.createAppRoute({
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    title: 'Tasks',
    children: [
      factory.createChildRoute({
        path: ':id',
        name: 'update-task',
        title: 'View Task',
      }),
      factory.createChildRoute({
        path: 'new',
        name: 'new-task',
        redirect: { name: 'new-task-describe' },
      }),
      factory.createChildRoute({
        path: 'new/describe',
        name: 'new-task-describe',
        component: Describe,
      }),
      factory.createChildRoute({
        path: 'new/assign',
        name: 'new-task-assign',
        component: Assign,
      }),
      factory.createChildRoute({
        path: 'new/settings',
        name: 'new-task-settings',
        component: Settings,
      }),
    ],
  }),
];

export default taskRoutes;
