<script setup>
import { ref } from 'vue';
import { AppService } from '../ts/services/App.service';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import PuPLogo from '@/assets/images/pup-logo-black.svg';
import { TranslationService } from '@/general/services/translations/translation.service';

const service = ref(new AppService());
const ts = ref(new TranslationService('global'));
</script>

<template>
  <div class="align-items-center flex justify-content-center">
    <div class="align-items-center flex flex-column justify-content-center">
      <div class="mb-5" style="width: 15vw">
        <LoadingIndicator v-if="!service.errorHasOccured" />
        <img v-else class="logo" :src="PuPLogo" />
      </div>

      <div style="width: 30vw">
        <h4 v-if="!service.errorHasOccured" class="text-center">
          {{ service.getRandomMessage() }}
        </h4>

        <h4 v-else class="p-error text-center">
          {{ ts.tGlobal('errorLoadingPlatform') }}
        </h4>
      </div>
    </div>
  </div>
</template>
