import { RouteRecordRaw } from 'vue-router';
import ImportMapping from 'supplier/modules/import-mappings/views/import-mapping.view.vue';
import ExportMapping from 'supplier/modules/export-mappings/views/export-mappings.view.vue';
import { RouteFactory } from '@/core/router/route.factory';
import ImportMappingItems from 'supplier/modules/import-mapping-items/views/import-mapping-items.view.vue';
import ImportMappingItemsHeader from 'supplier/modules/import-mapping-items/components/import-mapping-items-header.vue';
import importMappingAdvancedItemsHeader from '../../import-mapping-items/components/import-mapping-advanced-items-header.vue';
import { RouterService } from '@/core/router/router.service';
import ImportMappingAdvancedItems from '../../import-mapping-items/views/import-mapping-advanced-items.vue';
import ImportMappingSettingHeader from '../../import-mapping-setting/components/import-mapping-setting-header.vue';
import ImportMappingSetting from '../../import-mapping-setting/views/import-mapping-setting.vue';

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'mappings',
  navigationGroup: 'mappings',
  tabs: [
    {
      label: 'Import',
      key: 'import-mappings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'import-mappings' });
      },
    },
    {
      label: 'Export',
      key: 'export-mappings',
      command: () => {
        RouterService.getInstance().router.push({ name: 'export-mappings' });
      },
    },
  ],
});

const importMappingItemFactory = new RouteFactory({
  platform: 'supplier',
  module: 'mappings',
  navigationGroup: 'mappings',
});

const mappingRoutes: RouteRecordRaw[] = [
  routeFactory.createAppRoute({
    path: '/mappings',
    name: 'mappings',
    redirect: { name: 'import-mappings' },
    title: 'Mappings',
  }),

  routeFactory.createAppRoute({
    path: '/mappings/import',
    name: 'import-mappings',
    component: ImportMapping,
    title: 'Import mappings',
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-import-mapping',
      }),
    ],
  }),

  importMappingItemFactory.createAppRoute({
    path: '/mappings/import/:id/edit',
    name: 'edit-import-mapping',
    component: ImportMappingItems,
    header: ImportMappingItemsHeader,
    title: 'Simple mappings',
  }),

  importMappingItemFactory.createAppRoute({
    path: '/mappings/import/:id/advanced',
    name: 'advanced-import-mapping',
    component: ImportMappingAdvancedItems,
    header: importMappingAdvancedItemsHeader,
    title: 'Advanced mappings',
  }),

  routeFactory.createAppRoute({
    path: '/mappings/import/:id/settings',
    name: 'import-mapping-settings',
    component: ImportMappingSetting,
    header: ImportMappingSettingHeader,
    title: 'Mapping settings',
  }),

  routeFactory.createAppRoute({
    path: '/mappings/export',
    name: 'export-mappings',
    component: ExportMapping,
    title: 'Export mappings',
  }),
];

export default mappingRoutes;
