<script setup lang="ts">
import ModuleCompleteness from 'retailer/modules/dashboard/components/module-completeness.vue';
import OverallCompleteness from '@/platforms/retailer/modules/dashboard/components/overall-completeness.vue';
import OrganisationLabels from 'retailer/modules/dashboard/components/organisation-labels.vue';
import Activity from 'retailer/modules/dashboard/components/activity.vue';
import Metric from 'retailer/modules/dashboard/components/metric.vue';
import UpdatedProducts from 'retailer/modules/dashboard/components/updated-products.vue';
import Tasks from 'retailer/modules/dashboard/components/tasks.vue';
import FullCalendar from '@fullcalendar/vue3';
import * as cronParser from 'cron-parser';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import { useStore } from 'vuex';
import { RouteLocationRaw, RouteParamsRaw, useRouter } from 'vue-router';
import { onMounted, computed, ref, Ref, watch, onUnmounted } from 'vue';
import moment from 'moment';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { CalendarOptions } from '@fullcalendar/core';
import { DashboardRestService } from 'platform-unit2-api/dashboard';
import { Action } from 'platform-unit2-api/actions';

//Services
const ts = new TranslationService('retailer', 'dashboard');
const dashboardApi = new DashboardRestService();

//** Constants */
const store = useStore();
const router = useRouter();
const dates = ref();
const loadingCalander = ref(true);
const renderKey = ref(0);
const maxDate = ref(new Date());
const dashboardUpdatedAt = ref('');

const options = ref<CalendarOptions>({
  plugins: [listPlugin, momentPlugin],
  initialDate: new Date().toISOString(),
  initialView: 'listWeek',
  buttonIcons: {
    prev: ' mdi mdi-chevron-left',
    next: ' mdi mdi-chevron-right',
  },
  listDaySideFormat: 'DD MMM Y',
  events: (fetchInfo: any, successCallback: ([]) => void, failureCallback: () => void) => {
    const events: Ref<any[]> = ref([]);
    const start = fetchInfo.start;
    const end = fetchInfo.end;

    Promise.all([
      dashboardApi.getDashboardProducts(start, end),
      dashboardApi.getDashboardTasks(start, end),
      dashboardApi.getPipelineData(),
    ])
      .then((values) => {
        events.value = [];

        values[2].forEach((item) => {
          const dates: Ref<Date[]> = ref([]);
          const startDate =
            fetchInfo.start.getTime() > new Date().getTime() ? fetchInfo.start : new Date();
          const cron = cronParser.parseExpression(item.cron, { currentDate: startDate });

          while (
            fetchInfo.end.getTime() >
            (dates.value[dates.value.length - 1]?.getTime() ?? startDate.getTime())
          ) {
            dates.value.push(cron.next().toDate());
          }

          events.value = events.value.concat(
            dates.value.map((date: Date) => {
              return {
                title: ts.tModule('userDashboard.calendar.scheduled', {
                  params: {
                    name: item.name,
                    type: item.type,
                  },
                }),
                start: date.toISOString(),
              };
            }),
          );
        });

        events.value = events.value.concat(
          values[0].created.map(
            (item: { count: number; date: string; ids: number[] }, i: number) => ({
              id: i,
              title: ts.tModule('userDashboard.calendar.productsAdded', {
                params: { count: item.count },
              }),
              ids: item.ids,
              start: item.date,
              click: (event: any) => {
                router.push({
                  name: 'products',
                  params: { productIds: event.ids } as RouteParamsRaw,
                } as RouteLocationRaw);
              },
              backgroundColor: 'green',
            }),
          ),
        );

        events.value = events.value.concat(
          values[0].updated.map(
            (item: { count: number; date: string; ids: number[] }, i: number) => ({
              id: i,
              title: ts.tModule('userDashboard.calendar.productsEdited', {
                params: { count: item.count },
              }),
              ids: item.ids,
              start: item.date,
              click: (event: any) => {
                router.push({
                  name: 'products',
                  params: { productIds: event.ids } as RouteParamsRaw,
                } as RouteLocationRaw);
              },
              backgroundColor: '#007aee',
            }),
          ),
        );

        events.value = events.value.concat(
          values[0].deleted.map(
            (item: { count: number; date: string; ids: number[] }, i: number): any => ({
              id: i,
              title: ts.tModule('userDashboard.calendar.productsDeleted', {
                params: { count: item.count },
              }),
              ids: item.ids,
              start: item.date,
              backgroundColor: 'red',
            }),
          ),
        );

        events.value = events.value.concat(
          values[1].map((item: Action, i: number): any => ({
            id: i,
            title: item.title,
            start: item.date,
            click: () => {
              router.push({
                name: 'update-task',
                params: { id: item.id.toString() } as RouteParamsRaw,
              } as RouteLocationRaw);
            },
            backgroundColor: '#007aee',
          })),
        );
        loadingCalander.value = false;
        successCallback(events.value);
      })
      .catch(() => {
        loadingCalander.value = false;
        failureCallback();
      });
  },
  headerToolbar: {
    left: 'prev,next',
    center: 'title',
    right: 'today',
  },
  dayMaxEventRows: 5,
  height: 'auto',
  editable: true,
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  eventClick: (info: any) => {
    if (!info.event.extendedProps.click) return;

    info.event.extendedProps.click(info.event.extendedProps);
  },
} as CalendarOptions);
//todo this type might be wrong

const setStartDate = () => {
  const today = new Date();
  const firstDate = new Date();
  firstDate.setDate(today.getDate() - 7);
  dates.value = [firstDate, today];
};

const refresh = async () => {
  const toastService = ToastService.getInstance();

  if (getDiffBetweenDates(store.getters['dashboard/cachedAt']) > 1) {
    try {
      await refreshDashboard();
      setStartDate();
      renderKey.value = workspaceId.value;
      dashboardUpdatedAt.value = moment(store.getters['dashboard/cachedAt']).fromNow();
    } catch (error: any) {
      if (error.response.status === 429) {
        toastService.displayErrorToast(ts.tModule('refreshLimit'));
      }
    }
  } else {
    toastService.displayErrorToast(ts.tModule('refreshLimit'));
  }
};

const getDiffBetweenDates = (start: Date, end: Date | null = null) => {
  if (start == null) {
    return 0;
  }

  if (end == null) {
    end = new Date();
  }

  return Math.round(Math.abs(end.valueOf() - start.valueOf()) / 60000);
};

const refreshDashboard = async (): Promise<number> => {
  let rangeStart = new Date();
  let rangeEnd = new Date();

  if (
    dates.value == null ||
    dates.value === undefined ||
    dates.value[0] == null ||
    dates.value[1] == null
  ) {
    rangeStart.setDate(rangeStart.getDate());
    rangeStart.setHours(0, 0, 0, 0);

    rangeEnd.setHours(23, 59, 59, 999);
  } else {
    rangeStart = new Date(dates.value[0]);
    rangeEnd = new Date(dates.value[1]);
  }

  return await dashboardApi.refreshMetricData(rangeStart, rangeEnd);
};

watch(
  () => store.getters['dashboard/cachedAt'],
  () => {
    dashboardUpdatedAt.value = moment(store.getters['dashboard/cachedAt']).fromNow();
  },
);

let interval: NodeJS.Timer;
onMounted(() => {
  setStartDate();

  interval = setInterval(() => {
    dashboardUpdatedAt.value = moment(store.getters['dashboard/cachedAt']).fromNow();
  }, 60000);
});

onUnmounted(() => {
  clearInterval(interval);
});

//Reload the dashboard on workspace change
const workspaceId = computed((): number => {
  return store.getters['users/currentUser'].workspace?.id ?? 0;
});

watch(
  () => workspaceId.value,
  () => {
    renderKey.value = workspaceId.value;
  },
);
</script>
<template>
  <section class="pt-4 px-5" style="background-color: #f2f4f6">
    <div
      class="align-items-start flex flex-column justify-content-between lg:align-items-center mb-3 md:flex-row update-details"
    >
      <div class="align-items-center flex">
        <span>{{ ts.tGlobal('updated') }} {{ dashboardUpdatedAt }}</span>
        <div
          v-tooltip.bottom="{
            value:
              getDiffBetweenDates(store.getters['dashboard/cachedAt']) > 1
                ? ts.tModule('userDashboard.metrics.refreshData')
                : ts.tModule('refreshButtonToggle'),
          }"
        >
          <pButton
            text
            plain
            icon="mdi mdi-refresh"
            :disabled="getDiffBetweenDates(store.getters['dashboard/cachedAt']) <= 1"
            @click="() => refresh()"
          />
        </div>
      </div>

      <div class="align-items-baseline align-self-end flex">
        <span class="mr-2">
          {{
            dates && dates !== undefined
              ? dates[1]
                ? moment(new Date(dates[0])).format('DD MMM Y') +
                  ' - ' +
                  moment(new Date(dates[1])).format('DD MMM Y')
                : moment(new Date(dates[0])).format('DD MMM Y')
              : ''
          }}
        </span>
        <pDatePicker
          id="range"
          v-model="dates"
          class="mb-1"
          placeholder="Select date"
          :max-date="maxDate"
          selection-mode="range"
          :manual-input="false"
          :show-button-bar="true"
          :show-icon="true"
        />
      </div>
    </div>

    <div class="grid p-ai-stretch vertical-container w-full">
      <div class="col-12 flex flex-wrap p-0">
        <div class="col-12 lg:col-6">
          <Metric
            :selected-dates="dates"
            :refresh-key="renderKey"
            uri-key="products-metric"
            class="min-h-full"
          />
        </div>
        <div class="col-12 lg:col-6">
          <Metric
            :selected-dates="dates"
            :refresh-key="renderKey"
            uri-key="product-fields-metric"
            class="min-h-full"
          />
        </div>
      </div>
      <div class="col-12 lg:col-8">
        <Activity
          :selected-dates="dates"
          :refresh-key="renderKey"
          uri-key="completeness-trend"
          class="min-h-full"
        />
      </div>

      <div class="col-12 lg:col-4">
        <OverallCompleteness
          :refresh-key="`${renderKey}`"
          uri-key="overall-completeness-metric"
          class="min-h-full"
        />
      </div>

      <div class="col-12 lg:col-6">
        <Tasks class="min-h-full" />
      </div>

      <div class="col-12 lg:col-6">
        <p-card class="min-h-full">
          <template #content>
            <h4 class="mb-4 text-left">{{ ts.tModule('calendarTitle') }}</h4>
            <pSkeleton v-if="loadingCalander" class="h-25rem w-full" />
            <FullCalendar v-show="!loadingCalander" class="fc-calendar" :options="options" />
          </template>
        </p-card>
      </div>
      <div class="col-12">
        <OrganisationLabels class="min-h-full" />
      </div>
      <div class="lg:col-12">
        <ModuleCompleteness class="min-h-full" />
      </div>
      <div class="col-12">
        <UpdatedProducts class="min-h-full" />
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.p-card {
  border: 1px solid $gray-border-color;
  box-shadow: none;
}

.update-details {
  color: $primary-text-color;
  font-size: $font-size-s;
}
.pup-app .fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 14px;
}

.fc :deep(.fc-button-primary) {
  background-color: #0089d7;
  border-color: #0089d7;
}

.fc-calendar :deep(.fc-scroller) {
  overflow: scroll !important;
}
</style>
