<script setup lang="ts">
//vue
import { computed } from 'vue';

//props
interface Props {
  customStyle?: string;
  fileName: string;
}
const props = withDefaults(defineProps<Props>(), {
  customStyle: '',
});

//consts
const computedFilename = computed(() => {
  return `/src/assets/images/storyContent/${props.fileName}.svg`;
});
</script>

<template>
  <div
    data-testid="story-module"
    class="align-items-center flex h-full justify-content-center module-background"
  >
    <img
      :src="computedFilename"
      :alt="fileName"
      :class="
        customStyle ? 'mandatory-image-styles' : 'story-module-image-styles mandatory-image-styles'
      "
      :style="customStyle"
    />
  </div>
</template>

<style lang="scss" scoped>
$borderRadius: 6px;
$moduleXandYinPx: 100px;
.module-background {
  width: $moduleXandYinPx;
  height: $moduleXandYinPx;
  border-radius: $borderRadius;
  background: #f0f8ff;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbing-story-module {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

// prevents the image from being draggable
.mandatory-image-styles {
  user-drag: none; // nost suppoerted by most browsers that's why we list all other variants
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.story-module-image-styles {
  width: 90%;
  height: 90%;

  object-fit: contain;
}
</style>
