<script setup lang="ts">
//components
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import ContentTextarea from '../content-components/content-textarea.vue';
import StandardTextClass from '@/api/v1/content-story/ts/classes/standard-text-class';

interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);

// const / refs
const storyModule = storyService?.value.getModule<StandardTextClass>(props.uuid);
</script>

<template>
  <BaseContentComponent custom-height="50%" :uuid="uuid">
    <template #content>
      <div class="flex flex-column gap-3 h-full justify-content-around p-3">
        <ContentHeadline
          :headline="storyModule?.data.headline.value"
          @headline-value="storyModule?.setHeadline($event)"
        />
        <ContentTextarea
          :additional-container-style="'80%'"
          :textbox="storyModule?.data.body.value"
          @html-text-area="storyModule?.setTextArea($event)"
        />
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped></style>
