export enum PipelineMessageType {
  UNKNOWN = 'Unknown',
  MISSING_ATTRIBUTE = 'Missing attribute',
  VALUE_TOO_SHORT = 'Value too short',
  VALUE_TOO_LONG = 'Value too long',
  CONNECTION_PROBLEM = 'Connection problem',
  INVALID_ENUMERATED_VALUE = 'Invalid enumerated value',
  INVALID_CHECKSUM = 'Invalid checksum',
  INVALID_PATTERN = 'Invalid regex pattern',
}
