import { Attribute } from 'platform-unit2-api/attributes';
import {
  Datamodel,
  GetDatamodelsRequest,
  DatamodelsRestService,
} from 'platform-unit2-api/datamodels';
import { PaginationObject } from 'platform-unit2-api/core';

export class DatamodelService {
  private readonly datamodelApi = new DatamodelsRestService();

  public getAllODMAttributes = async (): Promise<Attribute[]> => {
    return this.datamodelApi.getAllODMAttributes().then((res) => {
      return res.data;
    });
  };

  public searchDatamodels = async ({
    pagination: { page = 1, limit = 15, query = '', sortBy = '' },
    data,
  }: {
    pagination: PaginationObject;
    data: GetDatamodelsRequest;
  }): Promise<Datamodel[]> => {
    return await (
      await this.datamodelApi.getAll({ page, limit, query, sortBy }, data)
    ).data;
  };
}
