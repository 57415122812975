//Types
import { ClientCompact } from 'platform-unit2-api/clients';
import {
  Datamodel,
  DatamodelsRestService,
  DatamodelAttributeConfig,
} from 'platform-unit2-api/datamodels';
import { ModuleDetail } from 'platform-unit2-api/modules';
import { User } from 'platform-unit2-api/users';
import { MappingItem } from 'platform-unit2-api/mapping-items';
import { TemplateEndpoint } from 'platform-unit2-api/template-endpoints';

// Core & Services
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { useStore } from 'vuex';

//API
import { MappingItemRestService } from 'platform-unit2-api/mapping-items';
import { TemplatesRestService } from 'platform-unit2-api/templates';

export class ExportMappingService {
  //Export Mapping
  private _datamodelApiService = new DatamodelsRestService();
  private _mappingItemApiService = new MappingItemRestService();

  private _templatesRestService = new TemplatesRestService();
  loading = false;
  selectedDatamodel: Datamodel | undefined = undefined;
  selectedModule: ModuleDetail | undefined = undefined;
  attributeConfigs: DatamodelAttributeConfig[] = [];
  endpoints: TemplateEndpoint[] = [];
  private _currentUser: User;

  private _mappingItems: MappingItem[] = [];
  private _mappingItemsOwners: ClientCompact[] = [];

  constructor(
    private _emit: (e: any, payload?: any) => void = () => {},
    private _store = useStore(),
    private _toast = ToastService.getInstance(),
    private _translation = new TranslationService('supplier', 'export_mappings'),
  ) {
    this._currentUser = this._store.getters['users/currentUser'];
  }

  //#region Export Mapping

  async getMappingItems() {
    if (!this.selectedModule || !this.selectedDatamodel) return;
    this.loading = true;
    try {
      this.attributeConfigs = await this._datamodelApiService.getDatamodelAttributeConfig(
        this.selectedDatamodel.id,
      );

      this._mappingItems = [];
      this._mappingItems = (
        await this._mappingItemApiService.getAll(undefined, {
          module_id: this.selectedModule.id,
          datamodel_id: this.selectedDatamodel.id,
        })
      ).data;

      this._mappingItemsOwners = await this._mappingItemApiService.getMappingItemsOwners(
        this.selectedModule.id,
        this.selectedDatamodel.id,
      );

      const workspace = this._currentUser.workspace;
      if (
        workspace &&
        !this._mappingItemsOwners.some((mappingItem) => mappingItem.id === workspace.id)
      ) {
        this._mappingItemsOwners.push({
          id: workspace.id,
          name: workspace.name,
        });
      }
    } catch (err) {
      this._toast.displayErrorToast(
        this._translation.loadFailed(this._translation.tGlobal('data')),
      );
    } finally {
      this.loading = false;
    }
  }

  async getTemplateEndpoint() {
    await this.getMappingItems();
    this.endpoints = [];
    if (!this.selectedModule?.template_id) return;
    try {
      this.endpoints = (
        await this._templatesRestService.getTemplateEndpoints(this.selectedModule.template_id)
      ).data;
    } catch (err) {
      this._toast.displayErrorToast(
        this._translation.loadFailed(this._translation.tGlobal('data')),
      );
    }
  }

  filteredMappingItemOwner() {
    return this._mappingItemsOwners.find((item) => item.id != null);
  }

  get mappingItems() {
    return this._mappingItems;
  }

  //#endregion

  isDuplicationDisabled() {
    return !this.selectedDatamodel || !this.selectedModule;
  }
}
