<script setup lang="ts">
//vue
import { ref } from 'vue';

//components
import HoverState from './hover-state.vue';

/** define props */
interface Props {
  uuid: string;
  customHeight?: string;
}

defineProps<Props>();

// const / refs
const isHovered = ref(false);
const toggleHoveredTrue = () => {
  isHovered.value = true;
};

const toggleHoveredFalse = () => {
  isHovered.value = false;
};
</script>
<template>
  <div
    data-testid="base-content-component"
    class="h-full relative w-full wrapperDiv"
    :style="`${customHeight ? `height: ${customHeight} !important;` : ''}margin: 10px 0 10px 0`"
    @mouseover="toggleHoveredTrue"
    @mouseleave="toggleHoveredFalse"
  >
    <slot name="content" />
    <HoverState v-if="isHovered" :uuid="uuid" />
  </div>
</template>
<style lang="scss" scoped>
.wrapperDiv:hover {
  border: 1px solid #0089d7 !important;
  border-radius: 0.27rem;
}
</style>
