import { Ref, UnwrapRef, onMounted, ref, watch } from 'vue';
import usePagination from '../usePagination/pagination';
import { PaginationObject } from 'platform-unit2-api/core';
import {
  DataTableFilterMeta,
  DataTableFilterMetaData,
  DataTablePageEvent,
} from 'primevue/datatable';
import { TableProps } from '@/general/composables/useTable/table-props';
import useCheckable from '../checkable';
import { ITable } from './table.interface';
import TableColumn from './table-column';

export default function useTable<T>(
  tableProps: TableProps<T>,
  loadDataOnMount?: boolean,
): ITable<T> {
  const loading = ref(false);
  const rows = ref<T[]>([]);
  const total = ref(0);
  const { page, perPage, onPageChange, onRowChange, query } = usePagination();
  const { checkedRows, checkedIds } = useCheckable();
  const checkAll = ref(false);
  const shouldLoadData = ref(loadDataOnMount ?? true);
  const showedColumns = ref<TableColumn<T>[]>(
    (tableProps.columns ?? []).filter((c) => c.label !== ''),
  );

  let finalPagination: PaginationObject = {
    page: page.value,
    limit: perPage.value,
    sortBy: '',
    query: query.value,
  };
  const filters: Record<string, (number | string)[]> = {};

  const fetchFromApi = async (func: Function, ...params: any) => {
    loading.value = true;
    const response = await func(...params);
    rows.value = response.data ?? response;
    total.value = response.meta?.total ?? rows.value.length ?? 0;
    if ((tableProps.columns ?? []).length === 0) {
      rows.value = [];
      total.value = 0;
      console.error('Define table columns');
    }

    loading.value = false;
  };

  const changeFilters = async (newFilters: DataTableFilterMeta) => {
    Object.entries(newFilters).forEach(([key, value]) => {
      filters[tableProps.getFilterKeyFromColumnFilter(key)] = (
        value as Omit<DataTableFilterMetaData, 'value'> & {
          value: { id: number | string; name: string }[];
        }
      ).value?.map((val) => val.id);
    });
    resetPagination();
    await fetchData();
  };

  const changeSearch = async (value: string) => {
    finalPagination.query = value;
    resetPagination();
    await fetchData();
  };

  const changeSortField = async (key: string) => {
    const sortKey: string = tableProps.getColumn(key)?.sortingColumn ?? key;
    if (finalPagination.sortBy !== sortKey) {
      finalPagination = { ...finalPagination, sortBy: sortKey };
      resetPagination();
      await fetchData();
    }
  };

  const changeSortDirection = async (direction?: number) => {
    if (direction == null || direction === 1) {
      return;
    }

    finalPagination = {
      ...finalPagination,
      sortBy: `-${finalPagination.sortBy}`,
    };
    await fetchData();
  };

  const changePagination = async (event: DataTablePageEvent) => {
    const pageToGoTo = event.page + 1;
    if (pageToGoTo !== page.value) {
      onPageChange(event.page + 1);
      finalPagination = { ...finalPagination, page: page.value };
    }

    if (event.rows !== perPage.value) {
      onRowChange(event.rows);
      finalPagination = { ...finalPagination, limit: perPage.value };
    }

    await fetchData();
  };

  const resetPagination = () => {
    onPageChange(1);
    finalPagination = { ...finalPagination, page: page.value };
  };

  const fetchData = async () => {
    checkedRows.value = [];
    await fetchFromApi(tableProps.loadData, { ...finalPagination, ...filters });
  };

  const toggleCheckAll = () => {
    const rowIds = (rows.value ?? [])
      .map((row) => (row as UnwrapRef<T & { id?: number }>).id)
      .filter(Boolean);
    if (checkAll.value) {
      checkedRows.value = [
        ...checkedRows.value,
        ...(rows.value ?? []).filter(
          (row) => !(checkedIds.value ?? []).includes((row as UnwrapRef<T & { id?: number }>).id),
        ),
      ];
    } else {
      checkedRows.value = (checkedRows.value ?? []).filter(
        (row: T & { id?: number }) => !rowIds.includes(row?.id),
      );
    }
  };

  const toggleShownColumns = (e: TableColumn<T>[]) => {
    showedColumns.value = e as UnwrapRef<TableColumn<T>[]>;
  };

  onMounted(async () => {
    if (shouldLoadData.value) {
      await fetchData();
    }
  });

  watch(
    () => shouldLoadData.value,
    async () => await fetchData(),
  );

  return {
    rows: rows as Ref<T[]>,
    loading: loading,
    page: page,
    perPage: perPage,
    total: total,
    changePagination: changePagination,
    changeFilters: changeFilters,
    changeSortField: changeSortField,
    changeSortDirection: changeSortDirection,
    checkedRows: checkedRows,
    checkedIds: checkedIds,
    checkAll: checkAll,
    showedColumns: showedColumns as Ref<TableColumn<T>[]>,
    toggleShownColumns: toggleShownColumns,
    toggleCheckAll: toggleCheckAll,
    fetchData: fetchData,
    changeSearch: changeSearch,
    shouldLoadData: shouldLoadData,
  };
}
