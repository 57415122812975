<script setup lang="ts">
import { Ref, provide, ref } from 'vue';

// Services
import { ExportViewService } from '@/general/services/overview-service/modules/export-view.service';
import { exportServiceKey } from '../ts/keys/exports.keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';

// Components
import OverView from '@/general/ui/views/over-view/over-view.vue';
import OverviewSidebar from '@/general/ui/components/over-view/overview-sidebar.vue';
import ExportTable from '@/platforms/supplier/modules/export/components/export-table.vue';
import ExportRestart from '@/platforms/supplier/modules/export/components/export-restart.vue';

const ts = ref(new TranslationService('supplier', 'exports'));
const exportService = ref(new ExportViewService(ts.value as TranslationService));

provide(exportServiceKey, exportService as Ref<ExportViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>
<template>
  <OverView platform="supplier" module-name="exports" :service="exportService">
    <template #content>
      <ExportTable />
    </template>
    <template #crudComponent>
      <OverviewSidebar
        :service="exportService"
        :title="ts.tModule('export_restart.title')"
        subtitle=""
        ><template #form>
          <ExportRestart />
        </template>
      </OverviewSidebar>
    </template>
    <template #others>
      <pConfirmDialog group="export-row" />
    </template>
  </OverView>
</template>
