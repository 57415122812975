<script setup lang="ts">
//Core
import { onMounted, ref, watch } from 'vue';

//Services
import { ProductVueService } from '../../services/product-vue.service';
import { TranslationService } from '@/general/services/translations/translation.service';

//types
import { ModuleDetail } from 'platform-unit2-api/modules';
import { StartExportRequest, ExportTypeEnum } from 'platform-unit2-api/exports';

// Components
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';

/** Props */
interface Props {
  isActive: boolean;
  channel?: ModuleDetail;
  productIds: number[];
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  productIds: () => [],
  channel: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

function hide(): void {
  emit('hide');
}

/** Services */
const ts = new TranslationService('supplier', 'products');
const productService = ref(new ProductVueService({ emits: { hide } }));

const makeRequest = async () => {
  const payload: StartExportRequest = {
    mail_to: [],
    custom_message: '',
    module_id: props.channel?.id ?? 0,
    product_ids: props.productIds,
    locale_id: productService.value.currentUser?.locale?.id ?? 0,
    changed_products: [],
    include_data: true,
    include_assets: false,
    export_type: ExportTypeEnum.MAPPING_EXPORT,
    prompt_fields: productService.value.promptFields,
  };
  await productService.value.publicationRequest(payload);
  emit('hide');
};

watch(
  () => props.channel,
  () => {
    props.channel && productService.value.createPropmtObject(props.channel);
  },
);

onMounted(() => {
  props.channel && productService.value.createPropmtObject(props.channel);
});
</script>
<template>
  <BaseDialog
    :visible="isActive"
    :title="channel?.name"
    size="small"
    @update:visible="emit('hide')"
  >
    <!-- Showing Prompt fields -->
    <div v-if="channel && channel.settings_fields">
      <div v-for="setting of channel.settings_fields.prompt_fields" :key="setting.key">
        <!-- If it's Radio button -->
        <div
          v-if="productService.showField(setting) && setting.type === 'radio'"
          class="flex flex-wrap mb-5"
        >
          <label class="mb-2 w-full">
            <span v-if="setting.required" class="text-pink-600">*</span>
            {{ setting.label }}
          </label>

          <div
            v-for="(option, index) in setting.options"
            :key="index"
            :style="{ width: 100 / (setting?.options?.length || 0) + '%' }"
            class="align-items-center flex"
          >
            <p-radio-button
              v-model="productService.promptFields![setting.key!]"
              :input-id="option.label"
              name="category"
              :value="option.value"
            />
            <label :for="option.label" class="cursor-pointer px-2">{{ option.label }}</label>
          </div>
        </div>

        <!-- If it's a text input -->
        <div v-else-if="productService.showField(setting) && setting.type === 'string'">
          <div class="field mb-3">
            <label>
              <span v-if="setting.required" class="text-pink-600">*</span>
              {{ setting.label }}
            </label>
            <p-input-text
              v-model="productService.promptFields![setting.key!]"
              class="input-text w-full"
              type="tel"
              :pattern="setting.rules?.pattern"
              :required="setting.required"
            />
            <small class="p-error">input is not valid.</small>
          </div>
        </div>

        <!-- If it's a checkbox -->
        <div v-else-if="productService.showField(setting) && setting.type === 'boolean'">
          <div class="field mb-3">
            <p-checkbox
              v-model="productService.promptFields![setting.key!]"
              :binary="true"
              class="mx-2"
            />
            <label>
              <span v-if="setting.required" class="text-pink-600">*</span>
              {{ setting.label }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex flex-row justify-content-end w-full">
        <pButton :label="ts.tGlobal('cancel')" text plain @click="emit('hide')" />

        <pButton
          label="Start"
          :loading="false"
          :disabled="!productService.isFormValid()"
          @click="makeRequest()"
        />
      </div>
    </template>
  </BaseDialog>
</template>
<style lang="scss">
.input-text + .p-error {
  visibility: hidden;
}
.input-text:invalid {
  border: 1px solid red !important;

  &:focus + .p-error {
    visibility: visible;
  }
}
.input-text:valid:focus {
  background: rgb(231, 252, 231);
}
</style>
