import { RouteFactory } from '@/core/router/route.factory';
import Editor from 'retailer/modules/editor/views/editor.view.vue';
import { RouteRecordRaw } from 'vue-router';

const editorRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/tools/editor',
    name: 'editor',
    translations: {
      module: 'editor',
      platform: 'retailer',
    },
    component: Editor,
    title: 'HTML Editor',
  }),
];

export default editorRoutes;
