<script setup lang="ts">
import { ref, onBeforeMount } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';

import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { useRouter } from 'vue-router';

const props = defineProps<{
  isActive: boolean;
  modelValue: string;
}>();

// Emits
const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void;
}>();

const router = useRouter();
const rawHtmlInputValue = ref('');
const editorValue = ref('');
const value = ref('');

/** Services */
const ts = new TranslationService('supplier', 'editor');

const indentHtml = (html: string) => {
  const tabSize = '  ';
  let result = '';
  let indent = '';
  html.split(/>\s*</).forEach(function (element: any) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tabSize.length);
    }

    result += indent + '<' + element + '>\r\n';
    if (element.match(/^<?\w[^>]*[^\/]$/)) {
      indent += tabSize;
    }
  });
  return result.substring(1, result.length - 3);
};

const confirmReplaceContent = () => {
  editorValue.value = indentHtml(rawHtmlInputValue.value.trim());

  emit('update:modelValue', editorValue.value);
  rawHtmlInputValue.value = '';
  router.push({ name: 'editor' });
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    router.push({ name: 'editor' });
  },
});

const insertButton = new CreateButton({
  label: ts.tModule('insert'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => confirmReplaceContent(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, insertButton],
});

onBeforeMount(() => {
  value.value = ts.tModule('instructions').toString();
  editorValue.value = indentHtml(value.value);
});
</script>

<template>
  <BaseDialog
    :visible="props.isActive"
    :title="ts.tModule('dialog.title')"
    :subtitle="ts.tModule('dialog.subtitle')"
    :maximizable="true"
    :buttons-options="buttonsOptions"
    size="medium"
    close-on-escape
    @update:visible="router.push({ name: 'editor' })"
  >
    <p-textarea
      v-model="rawHtmlInputValue"
      class="w-full"
      rows="10"
      :placeholder="ts.tModule('insert_html_placeholder')"
    />
  </BaseDialog>
</template>
