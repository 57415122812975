export default function (): any {
  const generateKey = (key: string) => {
    return `${encodeURIComponent(key.toLowerCase())}`;
  };

  const setStorageItem = (key: string, value: any) => {
    if (value instanceof Object) {
      JSON.stringify(value);
    }

    const storeValue = typeof value !== 'string' ? JSON.stringify(value) : value;

    localStorage.setItem(generateKey(key), storeValue);
  };

  const getStorageItem = (key: string) => {
    let value;
    try {
      value = localStorage.getItem(generateKey(key));
    } catch {
      return null;
    }

    if (value == null) {
      return value;
    }

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  return {
    setStorageItem,
    getStorageItem,
  };
}
