import { AttributeOptions } from 'platform-unit2-api/attributes';

export default function (): any {
  const getFieldDefinitionOption = <T extends AttributeOptions, K extends keyof T>(
    options: T,
    property: K,
    moduleId: number | null | undefined,
  ): any => {
    if (options.overrides && (moduleId != null || moduleId !== undefined)) {
      const override = options.overrides.find((o) => o.module_id === moduleId);
      if (override && override.hasOwnProperty(property)) {
        // @ts-ignore
        return override[property];
      }
    }

    if (options[property]) {
      return options[property];
    }

    return null;
  };

  return {
    getFieldDefinitionOption,
  };
}
