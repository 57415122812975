import { GrowthBook } from '@growthbook/growthbook';
import { FeatureFlagSetupService } from './feature-flags-setup.service';
import { User } from 'platform-unit2-api/users';
import { DebugLogger } from '@/core/main/debug.logger';

/**
 * Feature flag service uses the GrowthBook library to get the feature flags from the backend.
 * The encryption is done manually in the backend and the frontend only needs to decrypt the
 * base64 encoded string.
 *
 * To get a instance of the feature flag service use the `FeatureFlagService.getInstance()` method,
 * or inject it through vue.
 * @example
 * //Singleton get instance
 * const featureFlagService = FeatureFlagService.getInstance();
 *
 * //Injection of the service can also be done in vue through the `inject` option.
 * const featureFlagService = inject(FeatureFlagServiceKey);
 */
export class FeatureFlagService {
  private static _instance: FeatureFlagService;

  private _growthBook?: GrowthBook;
  private _featureFlagSetupService: FeatureFlagSetupService;

  constructor(featureFlagSetupService = new FeatureFlagSetupService()) {
    if (FeatureFlagService._instance) {
      throw new Error(
        'Another instance already exists, use FeatureFlagSerivce.getInstance() instead',
      );
    }

    this._featureFlagSetupService = featureFlagSetupService;
    FeatureFlagService._instance = this;
  }

  /**
   * Get the instance of the feature flag service.
   * @returns The instance of the feature flag service.
   */
  public static getInstance(): FeatureFlagService {
    if (FeatureFlagService._instance) {
      return FeatureFlagService._instance;
    }

    const featureFlagSetupService = new FeatureFlagSetupService();

    return new FeatureFlagService(featureFlagSetupService);
  }

  /**
   * Fetch a new growthbook instance with the latest feature flags.
   */
  public async fetchGrowthBook(force = false) {
    this._growthBook = await this._featureFlagSetupService.getGrowthBook(force);
  }

  /**
   * set user in growthbook instance
   * @param user
   */
  public setUser(user: User) {
    this._featureFlagSetupService.setUser(user);
  }

  /**
   * Check if a feature flag is on.
   * @param flag The name of the feature flag.
   * @returns boolean
   */
  public async isOn(flag: string): Promise<boolean> {
    try {
      await this.fetchGrowthBook();

      if (this._growthBook != null) {
        const isOn = this._growthBook.isOn(flag);

        DebugLogger.info('[FeatureFlag]', flag, isOn);

        return isOn;
      }
    } catch (e) {
      DebugLogger.warn('Something went wrong while getting the feature flag', e);
    }

    return false;
  }
}
