import { FeaturePermission } from 'platform-unit2-api/roles';
import Categories from 'supplier/modules/categories/views/categories.view.vue';
import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';

const categoryRoutes: RouteRecordRaw[] = [
  RouteFactory.createAppRoute({
    path: '/categories',
    name: 'categories',
    component: Categories,
    translations: {
      module: 'categories',
      platform: 'supplier',
    },
    title: 'Categories',
    rights: [FeaturePermission.MANAGE_CATEGORIES],
    children: [
      RouteFactory.createChildRoute({
        path: 'new',
        name: 'new-category',
        title: 'New category',
        rights: [FeaturePermission.MANAGE_CATEGORIES],
      }),
      RouteFactory.createChildRoute({
        path: ':id',
        name: 'edit-category',
        title: 'Edit category',
        rights: [FeaturePermission.MANAGE_CATEGORIES],
      }),
    ],
  }),
];

export default categoryRoutes;
