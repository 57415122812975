import { ImageSidebar } from '../story-types/modules/image-sidebar-interface';
import StandardImageSidebar from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-image-sidebar.vue';

//services
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';
import { Component } from 'vue';
import { IContentImage } from './interfaces/IContentImage.interface';
import { Upload } from 'platform-unit2-api/uploads';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { TextItem } from '../story-types/text-item.interface';
import { IBulletPoint } from './interfaces/IBulletPoint.interface';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export default class StandardImageSidebarClass
  extends BaseStoryModule<ImageSidebar>
  implements
    IContentImage<
      keyof Omit<
        ImageSidebar,
        'type' | 'headline' | 'descriptionTextBlock' | 'sidebarListBlock' | 'descriptionListBlock'
      >
    >,
    IBulletPoint<
      keyof Omit<
        ImageSidebar,
        'type' | 'headline' | 'descriptionTextBlock' | 'imageCaptionBlock' | 'sidebarImageTextBlock'
      >
    >
{
  ts: TranslationService;
  component: Component = StandardImageSidebar;
  moduleTranslationKey = 'storyBuilder.imageSidebarDisplayName';
  minimumImageSize: `${string} : ${string}`[] = ['300 : 300', '350 : 175'];
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';

  /**
   *
   */
  constructor(data?: ImageSidebar) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);

    if (!data) {
      this.data = {
        type: ContentStoryModulesEnum.ImageSidebar,
        descriptionListBlock: { type: ModuleComponentTypes.StandardTextListBlock, textList: [] },
        sidebarListBlock: { type: ModuleComponentTypes.StandardTextListBlock, textList: [] },
      };
    }
  }
  getAltText(
    selector?: 'imageCaptionBlock' | 'sidebarImageTextBlock' | undefined,
  ): string | undefined {
    if (selector == null) {
      return '';
    }

    if (selector === 'imageCaptionBlock') {
      return this.data.imageCaptionBlock?.altText ?? '';
    }

    return this.data.sidebarImageTextBlock?.image?.altText ?? '';
  }
  setAltText(
    altText?: string | undefined,
    selector?: 'imageCaptionBlock' | 'sidebarImageTextBlock' | undefined,
  ): void {
    if (selector == null || altText == null) {
      return;
    }

    if (selector === 'imageCaptionBlock') {
      this.data.imageCaptionBlock = {
        type: ModuleComponentTypes.Image,
        altText: altText ?? '',
        uploadDestinationId: this.data.imageCaptionBlock?.uploadDestinationId ?? '',
      };
      return;
    }

    this.data.sidebarImageTextBlock = {
      type: ModuleComponentTypes.ImageTextBlock,
      image: {
        type: ModuleComponentTypes.Image,
        altText: altText ?? '',
        uploadDestinationId: this.data.sidebarImageTextBlock?.image?.uploadDestinationId ?? '',
      },
    };
  }

  //#region image
  setUploadData(
    upload: Upload,
    selector:
      | keyof Omit<
          ImageSidebar,
          'type' | 'headline' | 'descriptionTextBlock' | 'sidebarListBlock' | 'descriptionListBlock'
        >
      | undefined,
  ): void {
    if ((selector as string) === 'imageCaptionBlock') {
      if (this.hasCorrectDimensions(upload, this.minimumImageSize[0])) {
        this.setUploadDataForImageCaptionBlock(upload);
      }
    } else {
      if (this.hasCorrectDimensions(upload, this.minimumImageSize[1])) {
        this.setUploadDataForSidebarImageTextBlock(upload);
      }
    }
  }

  private setUploadDataForImageCaptionBlock(upload: Upload): void {
    this.data.imageCaptionBlock = {
      type: ModuleComponentTypes.Image,
      uploadDestinationId: upload.public_url,
      altText: this.data.imageCaptionBlock?.altText ?? '',
    };
  }

  private setUploadDataForSidebarImageTextBlock(upload: Upload): void {
    this.data.sidebarImageTextBlock = {
      ...this.data.sidebarImageTextBlock,
      type: ModuleComponentTypes.ImageTextBlock,
      image: {
        type: ModuleComponentTypes.Image,
        uploadDestinationId: upload.public_url,
        altText: this.data.sidebarImageTextBlock?.image?.altText ?? '',
      },
    };
  }
  hasUploadSet(
    selector:
      | keyof Omit<
          ImageSidebar,
          'type' | 'headline' | 'descriptionTextBlock' | 'sidebarListBlock' | 'descriptionListBlock'
        >
      | undefined,
  ): boolean {
    if ((selector as string) === 'imageCaptionBlock') {
      return this.data.imageCaptionBlock?.uploadDestinationId ? true : false;
    }

    return this.data.sidebarImageTextBlock?.image?.uploadDestinationId ? true : false;
  }
  getUploadUrl(
    selector:
      | keyof Omit<
          ImageSidebar,
          'type' | 'headline' | 'descriptionTextBlock' | 'sidebarListBlock' | 'descriptionListBlock'
        >
      | undefined,
  ): string | undefined {
    if ((selector as string) === 'imageCaptionBlock') {
      return this.data.imageCaptionBlock?.uploadDestinationId;
    }

    return this.data.sidebarImageTextBlock?.image?.uploadDestinationId;
  }
  hasCorrectDimensions(upload: Upload, minimumImageSize: `${string} : ${string}`): boolean {
    const subStrings: string[] = minimumImageSize.split(' ');
    const sizes: number[] = [Number(subStrings[0]), Number(subStrings[2])];

    if (
      upload.resolution_in_pixels_height < sizes[1] &&
      upload.resolution_in_pixels_width < sizes[0]
    ) {
      ToastService.getInstance().displayErrorToast(this.ts.tModule('storyBuilder.wrongImageSize'));
      return false;
    }

    if (upload.resolution_in_pixels_height < sizes[1]) {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.wrongImageHeight'),
      );
      return false;
    }

    if (upload.resolution_in_pixels_width < sizes[0]) {
      ToastService.getInstance().displayErrorToast(this.ts.tModule('storyBuilder.wrongImageWidth'));
      return false;
    }

    return true;
  }

  //#endregion

  //#region headline
  setHeadline(
    selector: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarListBlock' | 'descriptionListBlock'
    >,
    value?: string,
  ): void {
    if (value == null) {
      return;
    }

    switch (selector) {
      case 'headline':
        this.data.headline = {
          ...this.data.headline,
          type: ModuleComponentTypes.Text,
          value: value,
        };
        break;
      case 'descriptionTextBlock':
        this.data.descriptionTextBlock = {
          type: ContentStoryModulesEnum.StandardText,
          headline: {
            type: ModuleComponentTypes.Text,
            value: value,
          },
          body: {
            type: ModuleComponentTypes.TextArea,
            value: this.data.descriptionTextBlock?.body?.value ?? '',
          },
        };
        break;

      case 'sidebarImageTextBlock':
        this.data.sidebarImageTextBlock = {
          ...this.data.sidebarImageTextBlock,
          type: ModuleComponentTypes.ImageTextBlock,
          headline: {
            type: ModuleComponentTypes.Text,
            value: value,
          },
        };
        break;
    }
  }

  getHeadline(
    selector: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarListBlock' | 'descriptionListBlock'
    >,
  ): string {
    switch (selector) {
      case 'headline':
        return this.data.headline?.value ?? '';
      case 'descriptionTextBlock':
        return this.data.descriptionTextBlock?.headline?.value ?? '';
      case 'sidebarImageTextBlock':
        return this.data.sidebarImageTextBlock?.headline?.value ?? '';
      default:
        return '';
    }
  }
  //#endregion

  //#region TextArea

  getTextArea(
    selector: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarListBlock' | 'headline' | 'descriptionListBlock'
    >,
  ) {
    if (selector === 'descriptionTextBlock') {
      return this.data.descriptionTextBlock?.body?.value ?? '';
    }

    return this.data.sidebarImageTextBlock?.body?.value ?? '';
  }
  setTextArea(
    selector: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarListBlock' | 'headline' | 'descriptionListBlock'
    >,
    value?: string,
  ) {
    if (value == null) {
      return;
    }

    if (selector === 'descriptionTextBlock') {
      this.data.descriptionTextBlock = {
        type: ContentStoryModulesEnum.StandardText,
        headline: {
          type: ModuleComponentTypes.Text,
          value: this.data.descriptionTextBlock?.headline?.value ?? '',
        },
        body: {
          type: ModuleComponentTypes.TextArea,
          value: value,
        },
      };
      return;
    }

    this.data.sidebarImageTextBlock = {
      ...this.data.sidebarImageTextBlock,
      type: ModuleComponentTypes.ImageTextBlock,
      body: {
        type: ModuleComponentTypes.TextArea,
        value: value,
      },
    };
  }
  //#endregion

  //#region Bulletpoints
  addBulletPoint(
    value: string,
    selector?: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarImageTextBlock' | 'headline' | 'descriptionTextBlock'
    >,
  ): void {
    if (selector == null) {
      return;
    }

    if (value === '') {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.bulletPoints.empty'),
      );
      return;
    }

    const newBulletPoint: TextItem = {
      type: ModuleComponentTypes.TextItem,
      text: {
        type: ModuleComponentTypes.Text,
        value: value,
      },
    };

    if (selector === 'sidebarListBlock') {
      if ((this.data.sidebarListBlock?.textList ?? []).length >= 8) {
        ToastService.getInstance().displayErrorToast(
          this.ts.tModule('storyBuilder.bulletPoints.maxAmountOfBulletPoints'),
        );
        return;
      }

      this.data.sidebarListBlock?.textList.push(newBulletPoint);
      return;
    }

    if ((this.data.descriptionListBlock?.textList ?? []).length >= 8) {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.bulletPoints.maxAmountOfBulletPoints'),
      );
      return;
    }

    this.data.descriptionListBlock?.textList.push(newBulletPoint);
  }
  removeBulletPoint(
    index: number,
    selector?: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarImageTextBlock' | 'headline' | 'descriptionTextBlock'
    >,
  ): void {
    if (selector == null) {
      return;
    }

    if (selector === 'sidebarListBlock') {
      this.data.sidebarListBlock?.textList.splice(index, 1);
      return;
    }

    this.data.descriptionListBlock?.textList.splice(index, 1);
  }
  getBulletPoints(
    selector?: keyof Omit<
      ImageSidebar,
      'type' | 'imageCaptionBlock' | 'sidebarImageTextBlock' | 'headline' | 'descriptionTextBlock'
    >,
  ): TextItem[] {
    if (selector == null) {
      return [];
    }

    if (selector === 'sidebarListBlock') {
      return this.data.sidebarListBlock?.textList ?? [];
    }

    return this.data.descriptionListBlock?.textList ?? [];
  }
  //#endregion
}
