import { Navigation } from 'platform-unit2-api/core';
import { FeaturePermission } from 'platform-unit2-api/roles';

const navigations: Navigation[] = [
  {
    title: 'dashboard',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline',
    group: 'dashboard',
  },
  {
    title: 'products',
    to: '/products',
    icon: 'mdi-shopping-outline',
  },
  {
    title: 'imports',
    to: '/import',
    group: 'import',
    icon: 'mdi-import',
  },
  {
    title: 'exports',
    to: '/export',
    group: 'export',
    icon: 'mdi-swap-vertical',
  },
  {
    title: 'task',
    to: '/tasks',
    icon: 'mdi-clipboard-list-outline',
  },

  {
    title: 'users',
    to: '/users',
    icon: 'mdi-account-outline',
    permission: FeaturePermission.MANAGE_USERS,
  },
  {
    title: 'channels',
    to: '/channels',
    icon: 'mdi-share-variant',
    permission: FeaturePermission.MANAGE_MODULES,
  },
  {
    title: 'categories',
    to: '/categories',
    icon: 'mdi-folder-multiple-outline',
    permission: FeaturePermission.MANAGE_CATEGORIES,
  },
  {
    title: 'datamodels',
    to: '/datamodels',
    icon: 'mdi-format-list-text',
    permission: FeaturePermission.MANAGE_DATAMODELS,
  },
  {
    title: 'mappings',
    to: '/mappings',
    icon: 'mdi-shuffle',
    group: 'mappings',
    permission: FeaturePermission.MANAGE_MAPPINGS,
  },
  {
    title: 'tools',
    to: '/tools/editor',
    icon: 'mdi-tools',
    group: 'tools',
  },
  {
    title: 'developers',
    to: '/developers/api-key',
    icon: 'mdi mdi-xml',
  },
];

export default navigations;
