//vue
import { Component, markRaw } from 'vue';

//components
import StandardThreeImageText from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-three-image-text.vue';

//types
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { ThreeImageText } from '../story-types/modules/three-image-text.interface';
import { IContentImage } from './interfaces/IContentImage.interface';
import { Upload } from 'platform-unit2-api/uploads';

//services
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export default class StandardThreeImageTextClass
  extends BaseStoryModule<ThreeImageText>
  implements IContentImage<'block1' | 'block2' | 'block3' | undefined>
{
  ts: TranslationService;
  moduleTranslationKey = 'storyBuilder.threeImageTextModuleDisplayName';
  component: Component = markRaw(StandardThreeImageText);
  minimumImageSize: `${string} : ${string}` = '300 : 300';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';

  private _imageValidatorService: ImageValidatorService;

  constructor(data?: ThreeImageText) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._imageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (this.data?.type == null) {
      this.data.type = ContentStoryModulesEnum.ThreeImageText;
    }
  }
  public getAltText(selector?: 'block1' | 'block2' | 'block3' | undefined): string | undefined {
    if (selector == null) {
      return '';
    }

    return this.data[selector]?.image?.altText ?? '';
  }
  public setAltText(
    altText?: string | undefined,
    selector?: 'block1' | 'block2' | 'block3' | undefined,
  ): void {
    if (selector != null && altText != null) {
      this.data[selector] = {
        type: ModuleComponentTypes.ImageTextBlock,
        image: {
          type: ModuleComponentTypes.Image,
          altText: altText,
          uploadDestinationId: this.data[selector]?.image?.uploadDestinationId ?? '',
        },
        headline: this.data[selector]?.headline ?? { type: ModuleComponentTypes.Text, value: '' },
        body: this.data[selector]?.body ?? { type: ModuleComponentTypes.TextArea, value: '' },
      };
    }
  }

  public getUploadUrl(block?: 'block1' | 'block2' | 'block3'): string | undefined {
    if (block) {
      return this.data[block]?.image?.uploadDestinationId;
    }

    return undefined;
  }

  public hasUploadSet(block?: 'block1' | 'block2' | 'block3'): boolean {
    if (block) {
      return this.data[block]?.image?.uploadDestinationId ? true : false;
    }

    return false;
  }
  public setHeadline(block: 'block1' | 'block2' | 'block3', headlineText?: string) {
    if (headlineText) {
      this.data[block] = {
        type: ModuleComponentTypes.ImageTextBlock,
        image: this.data[block]?.image ?? {
          type: ModuleComponentTypes.Image,
          uploadDestinationId: '',
          altText: '',
        },
        headline: { type: ModuleComponentTypes.Text, value: headlineText },
        body: this.data[block]?.body ?? { type: ModuleComponentTypes.TextArea, value: '' },
      };
    }
  }

  public setUploadData(upload: Upload, block?: 'block1' | 'block2' | 'block3'): void {
    if (block && this._imageValidatorService.hasCorrectDimensions(upload)) {
      this.data[block] = {
        type: ModuleComponentTypes.ImageTextBlock,
        image: {
          type: ModuleComponentTypes.Image,
          altText: this.data[block]?.image?.altText ?? '',
          uploadDestinationId: upload.public_url,
        },
        headline: this.data[block]?.headline ?? { type: ModuleComponentTypes.Text, value: '' },
        body: this.data[block]?.body ?? { type: ModuleComponentTypes.TextArea, value: '' },
      };
    }
  }

  public setTextArea(block: 'block1' | 'block2' | 'block3', textAreaValue: string) {
    this.data[block] = {
      type: ModuleComponentTypes.ImageTextBlock,
      image: this.data[block]?.image ?? {
        type: ModuleComponentTypes.Image,
        uploadDestinationId: '',
        altText: '',
      },
      headline: this.data[block]?.headline ?? { type: ModuleComponentTypes.Text, value: '' },
      body: { type: ModuleComponentTypes.TextArea, value: textAreaValue },
    };
  }

  public setMainHeadline(headlineText?: string) {
    if (headlineText) {
      this.data.headline = {
        type: ModuleComponentTypes.Text,
        value: headlineText,
      };
    }
  }
}
