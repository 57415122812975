<script lang="ts" setup>
import { ref } from 'vue';
import useCheckable from 'composables/checkable';
import ProductsTable from 'retailer/modules/products/components/products-table.vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

interface Props {
  visible: boolean;
  selectedAssets: Upload[];
}

const props = withDefaults(defineProps<Props>(), {
  selectedAssets: () => [],
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'backToImageSelection'): void;
}>();

// Services
const ts = new TranslationService('retailer', 'media');
const toastService = ToastService.getInstance();

// Constants
const store = useStore();
const { checkedRows } = useCheckable();
const loading = ref(false);

// Helper functions
const updateCheckedRows = (rows: Product[]) => {
  checkedRows.value = rows;
};

const closeDialog = () => {
  checkedRows.value = [];
  emit('close');
};

const attachMediaToProducts = async () => {
  loading.value = true;
  try {
    await store.dispatch('products/ATTACH_MANY', {
      productIds: checkedRows.value.map((product: Product) => product.id),
      uploadIds: props.selectedAssets.map((file) => file.id),
    });
    toastService.displaySuccessToast(ts.tModule(`attach_media_dialog.success`));
  } catch {
    toastService.displayErrorToast(ts.tModule('attach_media_dialog.error'));
  } finally {
    loading.value = false;
    closeDialog();
  }
};

const prevButton = new CancelButton({
  label: ts.tGlobal('previous'),
  position: CrudButtonPosition.RIGHT,
  buttonIcon: 'mdi mdi-chevron-left',
  styleClasses: 'p-button-outlined',
  isEnabled: () => !loading.value,
  onClick: () => emit('backToImageSelection'),
});

const attachButton = new CreateButton({
  label: () => {
    return ts.tModule(`attach_media_dialog.attach_to`, {
      params: {
        imageCount: props.selectedAssets.length,
        productCount: checkedRows.value.length,
      },
    });
  },
  position: CrudButtonPosition.CENTER,
  styleClasses: 'p-button-rounded',
  buttonIcon: 'mdi mdi-plus-box-multiple',
  isEnabled: () => checkedRows.value.length > 0,
  onClick: attachMediaToProducts,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [prevButton, attachButton],
});
</script>

<template>
  <BaseDialog
    :visible="visible"
    :title="ts.tModule(`attach_media_dialog.title`)"
    :subtitle="ts.tModule(`attach_media_dialog.subtitle`)"
    :buttons-options="buttonsOptions"
    size="large"
    @update:visible="closeDialog"
  >
    <ProductsTable
      :selected-products="checkedRows"
      module="media"
      :attaching-media="loading"
      @update:checked-rows="updateCheckedRows"
    />
  </BaseDialog>
</template>
