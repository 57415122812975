import { App } from 'vue';

import pButton from 'primevue/button';
import pInputText from 'primevue/inputtext';
import pTextarea from 'primevue/textarea';
import pMultiSelect from 'primevue/multiselect';
import pPassword from 'primevue/password';
import pMessage from 'primevue/message';
import pInputMask from 'primevue/inputmask';
import pInputNumber from 'primevue/inputnumber';
import pAvatar from 'primevue/avatar';
import pAvatarGroup from 'primevue/avatargroup';
import pDivider from 'primevue/divider';
import pSelect from 'primevue/select';
import pMenu from 'primevue/menu';
import pTooltip from 'primevue/tooltip';
import pCheckbox from 'primevue/checkbox';
import pToggleSwitch from 'primevue/toggleswitch';
import pChart from 'primevue/chart';
import pDataTable from 'primevue/datatable';
import pDataView from 'primevue/dataview';
import pColumn, { ColumnContext } from 'primevue/column';
import pProgressBar from 'primevue/progressbar';
import pSkeleton from 'primevue/skeleton';
import pDatePicker from 'primevue/datepicker';
import pCard from 'primevue/card';
import pDrawer from 'primevue/drawer';
import pFileUpload from 'primevue/fileupload';
import pPaginator from 'primevue/paginator';
import pBreadcrumb from 'primevue/breadcrumb';
import pDialog from 'primevue/dialog';
import pConfirmPopup from 'primevue/confirmpopup';
import pToast from 'primevue/toast';
import pConfirmDialog from 'primevue/confirmdialog';
import pBadge from 'primevue/badge';
import pOverlayBadge from 'primevue/overlaybadge';
import pFieldset from 'primevue/fieldset';
import pImage from 'primevue/image';
import pTree from 'primevue/tree';
import pTreeTable from 'primevue/treetable';
import pChip from 'primevue/chip';
import pToggleButton from 'primevue/togglebutton';
import pProgressSpinner from 'primevue/progressspinner';
import pTimeline from 'primevue/timeline';
import pContextMenu from 'primevue/contextmenu';
import pListbox from 'primevue/listbox';
import pCarousel from 'primevue/carousel';
import pTag from 'primevue/tag';
import pRadioButton from 'primevue/radiobutton';
import pMenubar from 'primevue/menubar';
import pColorPicker from 'primevue/colorpicker';
import pScrollPanel from 'primevue/scrollpanel';
import pSplitButton from 'primevue/splitbutton';
import pAutoComplete from 'primevue/autocomplete';
import pSpeedDial from 'primevue/speeddial';
import pSelectButton from 'primevue/selectbutton';
import pPanel from 'primevue/panel';
import pSplitter from 'primevue/splitter';
import pSplitterPanel from 'primevue/splitterpanel';
import pPopover from 'primevue/popover';
import pOrderList from 'primevue/orderlist';
import pPickList from 'primevue/picklist';
import pTreeSelect from 'primevue/treeselect';
import pInplace from 'primevue/inplace';
import pVirtualScroller from 'primevue/virtualscroller';
import pEditor from 'primevue/editor';
import pTabs from 'primevue/tabs';
import pTab from 'primevue/tab';
import pTabList from 'primevue/tablist';
import OrganizationChart from 'primevue/organizationchart';
import InputIcon from 'primevue/inputicon';
import IconField from 'primevue/iconfield';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import pAccordion from 'primevue/accordion';
import pAccordionPanel from 'primevue/accordionpanel';
import pAccordionHeader from 'primevue/accordionheader';
import pAccordionContent from 'primevue/accordioncontent';

// Soon to be depricated
import pTabView from 'primevue/tabview';
import pTabPanel from 'primevue/tabpanel';
import pTabMenu from 'primevue/tabmenu';
import pSteps from 'primevue/steps';

import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';
import '@/assets/theme/theme.scss';
import 'primeflex/primeflex.css';
import { colorPalette, semantic } from '@/assets/theme/primeVueTheme/colorPalette';
import { components } from '@/assets/theme/primeVueTheme/componentsStyling';
import { definePreset } from '@primevue/themes';
import Lara from '@primevue/themes/lara';

const pupTheme = definePreset(Lara, {
  // Specifying the color palette
  primitive: colorPalette,
  // Defining theme colors & main styling
  semantic: semantic,
  // Modifying theme components using design tokens
  components: components,
});

export function installPrimeVue(app: App) {
  app.use(PrimeVue, {
    theme: {
      preset: pupTheme,
      options: {
        darkModeSelector: 'light',
      },
    },
    pt: {
      datatable: {
        column: {
          pcColumnFilterButton: ({ context }: { context: ColumnContext }) => ({
            style: context.active ? 'background: var(--primary); color: white;' : '',
            class: 'w-2rem h-2rem',
          }),
        },
      },
    },
  });
  app.component('PButton', pButton);
  app.component('PInputText', pInputText);
  app.component('PTextarea', pTextarea);
  app.component('PMultiselect', pMultiSelect);
  app.component('PPassword', pPassword);
  app.component('PMessage', pMessage);
  app.component('PInputMask', pInputMask);
  app.component('PInputNumber', pInputNumber);
  app.component('PCard', pCard);
  app.component('PAvatar', pAvatar);
  app.component('PAvatarGroup', pAvatarGroup);
  app.component('PDivider', pDivider);
  app.component('PSelect', pSelect);
  app.component('PMenu', pMenu);
  app.component('PCheckbox', pCheckbox);
  app.component('PToggleSwitch', pToggleSwitch);
  app.component('PSteps', pSteps);
  app.component('PChart', pChart);
  app.component('PDataTable', pDataTable);
  app.component('PDataView', pDataView);
  app.component('PColumn', pColumn);
  app.component('PProgressBar', pProgressBar);
  app.component('PSkeleton', pSkeleton);
  app.component('PDatePicker', pDatePicker);
  app.component('PDrawer', pDrawer);
  app.component('PFileUpload', pFileUpload);
  app.component('PPaginator', pPaginator);
  app.component('PBreadcrumb', pBreadcrumb);
  app.component('PDialog', pDialog);
  app.component('PConfirmPopup', pConfirmPopup);
  app.component('PToast', pToast);
  app.component('PConfirmDialog', pConfirmDialog);
  app.component('PTabView', pTabView);
  app.component('PTabPanel', pTabPanel);
  app.component('PBadge', pBadge);
  app.component('POverlayBadge', pOverlayBadge);
  app.component('PFieldset', pFieldset);
  app.component('PTree', pTree);
  app.component('PTreeTable', pTreeTable);
  app.component('PTreeSelect', pTreeSelect);
  app.component('PChip', pChip);
  app.component('PToggleButton', pToggleButton);
  app.component('PProgressSpinner', pProgressSpinner);
  app.component('PTimeline', pTimeline);
  app.component('PContextMenu', pContextMenu);
  app.component('PListbox', pListbox);
  app.component('PCarousel', pCarousel);
  app.component('PTag', pTag);
  app.component('PRadioButton', pRadioButton);
  app.component('PMenubar', pMenubar);
  app.component('PAccordion', pAccordion);
  app.component('PAccordionPanel', pAccordionPanel);
  app.component('PAccordionHeader', pAccordionHeader);
  app.component('PAccordionContent', pAccordionContent);
  app.component('PColorPicker', pColorPicker);
  app.component('PScrollPanel', pScrollPanel);
  app.component('PSplitButton', pSplitButton);
  app.component('PAutoComplete', pAutoComplete);
  app.component('PImage', pImage);
  app.component('PSpeedDial', pSpeedDial);
  app.component('PSelectButton', pSelectButton);
  app.component('PPanel', pPanel);
  app.component('PSplitter', pSplitter);
  app.component('PSplitterPanel', pSplitterPanel);
  app.component('PPopover', pPopover);
  app.component('POrderList', pOrderList);
  app.component('PPickList', pPickList);
  app.component('PInplace', pInplace);
  app.component('PVirtualScroller', pVirtualScroller);
  app.component('PTabMenu', pTabMenu);
  app.component('PEditor', pEditor);
  app.component('POrganizationChart', OrganizationChart);
  app.component('PInputIcon', InputIcon);
  app.component('PIconField', IconField);
  app.component('PInputGroup', InputGroup);
  app.component('PInputGroupAddon', InputGroupAddon);
  app.component('PTabs', pTabs);
  app.component('PTab', pTab);
  app.component('PTabList', pTabList);

  app.directive('tooltip', pTooltip);
}
