/**
 * Converts a date-time to a UTC date.
 * @param dateTime The date-time to convert.
 * @returns The UTC date.
 */

export const dateTimeToUTCDate = (dateTime: Date): Date =>
  new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()));

/**
 * converts a date-time to an ISO string. time is always 00:00
 * @param dateTime The date-time to convert.
 * @returns The ISO string.
 */
export const dateTimeToIsoString = (dateTime: Date): string =>
  new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate())).toISOString();
