<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { FileUploadSelectEvent } from 'primevue/fileupload';
import { categoryServiceKey } from 'supplier/modules/categories/ts/keys/categories.keys';
import { inject, onMounted } from 'vue';

const ts = inject(tsKey)!;
const service = inject(categoryServiceKey)!;

onMounted(async () => {
  await service.value.getCategoryTreeIndex();
});
</script>

<template>
  <div>
    <div class="field mb-3">
      <label for="name">{{ ts.tForms('name') }}</label>
      <pInputText
        id="name"
        v-model="service.partialObject.name"
        :class="{ 'p-invalid': service?.hasError('name') }"
        class="w-full"
        type="text"
      />
      <small
        v-if="service?.hasError('name')"
        :class="{ 'p-error block': service?.hasError('name') }"
        class="hidden"
        >{{ service?.fieldErrorMessage('name').toString() }}</small
      >
    </div>
    <div class="field mb-3">
      <label for="parent">{{ ts.tModule('parent') }}</label>
      <div>
        <small>{{ ts.tModule('parentDescription') }}</small>
      </div>
      <pTreeSelect
        v-model="service.parentCategory"
        :options="service.nodes"
        selection-mode="single"
        placeholder="Select Item"
        class="w-full"
      />
      <small
        v-if="service?.hasError('parent_id')"
        :class="{ 'p-error block': service?.hasError('parent_id') }"
        class="hidden"
        >{{ service?.fieldErrorMessage('parent_id').toString() }}</small
      >
    </div>
    <div v-if="service.isCreating">
      <pDivider align="center">
        <p class="font-bold my-3 px-5 text-xl">{{ ts.tGlobal('or') }}</p>
      </pDivider>
      <div class="mb-5">
        <h3 class="font-bold mb-3 text-2xl">
          {{ ts.tModule('import.title') }}
        </h3>
        <label for="name">{{ ts.tForms('chooseFile') }}</label>
        <pFileUpload
          id="name"
          class="font-light mt-2"
          :class="{ 'p-invalid': service?.hasError('name') }"
          mode="basic"
          :auto="false"
          :choose-label="ts.tGlobal('upload')"
          @select="(event: FileUploadSelectEvent) => service.onFileSelect(event)"
        />
        <small
          v-if="service?.hasError('name')"
          :class="{ 'p-error block': service?.hasError('name') }"
          class="hidden"
          >{{ service?.fieldErrorMessage('name').toString() }}</small
        >
      </div>
    </div>
  </div>
</template>
