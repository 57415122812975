<script setup lang="ts">
import { inject, ref } from 'vue';
import { datamodelServiceKey } from '../../ts/keys/datamodels.keys';
import pDataTable from 'primevue/datatable';
import { tsKey } from '@/general/services/translations/translation.key';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { MenuItem } from 'primevue/menuitem';
import EmptyState from '@/general/ui/components/empty-state.vue';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import DatamodelDuplicateModal from 'supplier/modules/datamodels/components/datamodel-duplicate/datamodel-duplicate-modal.vue';
import { formatDate } from '@/general/utils/format-date';
import { useRouter } from 'vue-router';

const ts = inject(tsKey)!;
const service = inject(datamodelServiceKey)!;
const selectedDatamodel = ref<Datamodel>();
const router = useRouter();

const menuItems = (datamodel: Datamodel): MenuItem[] => [
  {
    label: ts.value.tModule('datamodel_edit.title'),
    icon: 'mdi mdi-pencil',
    command: () => {
      router.push({ name: 'datamodels-attributes', params: { id: datamodel.id } });
    },
  },
  {
    label: ts.value.tModule('datamodelRename.title'),
    icon: 'mdi mdi-form-textbox',
    command: () => {
      service.value.openCrudComponent(datamodel, false);
    },
  },
  {
    label: ts.value.tModule('datamodel_download.title'),
    icon: 'mdi mdi-tray-arrow-down',
    command: () => {
      service.value.downloadDatamodelExcel(datamodel);
    },
  },

  {
    label: ts.value.tModule('datamodel_duplicate.title'),
    icon: 'mdi mdi-content-copy',
    command: () => {
      service.value.duplicationModalVisible = true;
      selectedDatamodel.value = datamodel;
    },
  },
  {
    separator: true,
  },
  {
    label: ts.value.tModule('datamodel_delete.title'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      service.value.confirmTableDelete(datamodel);
    },
  },
];
</script>

<template>
  <pDataTable
    scrollable
    scroll-height="flex"
    :value="service.datamodels"
    removable-sort
    lazy
    :row-hover="true"
    :paginator="service.limit ? (service.total ?? 0) > service.limit : false"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    @row-click="
      (event) => {
        router.push({ name: 'datamodels-attributes', params: { id: event.data.id } });
      }
    "
    @page="service.onPageChange($event.page)"
    @sort="service.onSortChange($event)"
  >
    <p-column field="name" :header="ts.tGlobal('name')" sortable />
    <p-column
      field="created_at"
      :header="ts.tModule('datamodel_info_header.date_created')"
      sortable
    >
      <template #body="slotProps">
        <span>{{ formatDate(slotProps.data.created_at) }}</span>
      </template>
    </p-column>
    <p-column
      field="updated_at"
      :header="ts.tModule('datamodel_info_header.date_updated')"
      sortable
    >
      <template #body="slotProps">
        <span>{{ formatDate(slotProps.data.updated_at) }}</span>
      </template></p-column
    >
    <p-column
      field="attributes_count"
      :header="ts.tModule('datamodel_info_header.attribute_count')"
    />
    <p-column>
      <template #body="slotProps">
        <ActionsMenu :menu-items="menuItems(slotProps.data)" />
      </template>
    </p-column>
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :icon-name="'datamodels'"
        :button-icon="'mdi mdi-plus'"
        @clicked="service.openCrudComponent()"
      />
    </template>
  </pDataTable>

  <DatamodelDuplicateModal
    v-if="selectedDatamodel"
    size="medium"
    :selected-datamodel="selectedDatamodel"
    :visible="service.duplicationModalVisible"
    @hide="service.duplicationModalVisible = false"
    @reload="service.refetch()"
  />

  <p-confirm-dialog group="datamodels" />
</template>
