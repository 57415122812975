<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { useRoute } from 'vue-router';

/** Props */
export interface Props {
  title?: string;
  subtitle?: string;
  disableSave?: boolean;
  saving?: boolean;
  buttons?: boolean;
  hasDeleteButton?: boolean;
}

withDefaults(defineProps<Props>(), {
  title: undefined,
  subtitle: undefined,
  saving: false,
  buttons: true,
  hasDeleteButton: true,
  disableSave: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'save'): void;
  (e: 'cancel'): void;
  (e: 'delete', event: any): void;
}>();

//Composables
const route = useRoute();

//Services
const ts = new TranslationService('general', 'components');
</script>
<template>
  <main style="height: 100%">
    <div style="height: 100%; display: flex; flex-direction: column">
      <h3 v-if="title" class="font-bold mb-1 text-2xl">
        {{ title }}
      </h3>
      <p v-if="subtitle" class="mb-5 text-lg">
        {{ subtitle }}
      </p>
      <slot name="sidebar-data" />
      <div
        v-if="buttons"
        class="bg-white border-100 border-top-1 bottom-0 flex justify-content-between mt-5 py-3 sticky z-1"
        style="margin-top: auto !important"
      >
        <p-button
          v-if="hasDeleteButton && route.name?.toString()?.match(/new|restart|duplicate/) == null"
          icon="mdi mdi-delete-outline"
          :label="ts.tGlobal('delete')"
          severity="danger"
          text
          @click="emit('delete', $event)"
        />
        <div class="ml-auto mr-0">
          <p-button :label="ts.tGlobal('cancel')" text plain class="mr-2" @click="emit('cancel')" />
          <p-button
            :disabled="saving || disableSave"
            :label="
              route.name?.toString().includes('restart') ? ts.tGlobal('start') : ts.tGlobal('save')
            "
            :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
            icon-pos="right"
            @click="emit('save')"
          />
        </div>
      </div>
    </div>
  </main>
</template>
