<script setup lang="ts">
import { Action, ActionRestService } from 'platform-unit2-api/actions';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<Action>>();
const actionRestService = ActionRestService;
</script>
<template>
  <BaseSelect
    :rest-service="actionRestService"
    option-label="title"
    class="w-full"
    module-name="task"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
