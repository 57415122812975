<script setup lang="ts">
import { inject } from 'vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { datamodelServiceKey } from '../../ts/keys/datamodels.keys';

const ts = inject(tsKey)!;
const service = inject(datamodelServiceKey)!;
</script>

<template>
  <div class="field p-3">
    <label for="name">{{ ts.tGlobal('name') }}</label>
    <p-input-text
      id="name"
      v-model="service.partialObject.name"
      class="w-full"
      :class="{ 'p-invalid': service.hasError('name') }"
      type="text"
    />
    <small
      v-if="service.hasError('name')"
      :class="{ 'p-error block': service.hasError('name') }"
      class="hidden"
      >{{ service.fieldErrorMessage('name').toString() }}</small
    >
  </div>
</template>
