<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import EmptyState from '@/general/ui/components/empty-state.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { DataTableRowExpandEvent } from 'primevue/datatable';
import { TreeNode } from 'primevue/treenode';
import { categoryServiceKey } from 'supplier/modules/categories/ts/keys/categories.keys';

import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const ts = inject(tsKey)!;
const service = inject(categoryServiceKey)!;
const router = useRouter();

const expandedRows = ref<number[]>([]);

onMounted(async () => {
  service.value.treeViewNodes = await service.value.getTreeViewNodes();
});
</script>
<template>
  <pDataTable
    v-if="service.selectedTab.key === 'list'"
    v-model:expanded-rows="expandedRows"
    scrollable
    scroll-height="flex"
    data-key="id"
    :value="service.categories"
    responsive-layout="scroll"
    row-hover
    lazy
    :paginator="service.limit ? (service.total ?? 0) > service.limit : false"
    :loading="service.isLoadingOverView"
    :rows="service.limit"
    :total-records="service.total"
    :first="service.first"
    @page="service.onPageChange($event.page)"
    @row-expand="(event: DataTableRowExpandEvent) => service.loadAttributes((event.data as any).id)"
  >
    <p-column class="flex-none" body-style="width: 5rem" expander header-style="width: 5rem" />
    <p-column field="name" header="Name" />
    <p-column header="Parent">
      <template #body="slotProps">
        {{ slotProps.data.parent && slotProps.data.parent.name }}</template
      >
    </p-column>
    <p-column class="flex-none" header-style="width: 10rem" body-style="width: 10rem">
      <template #body="slotProps">
        <div class="flex justify-content-end">
          <p-button
            text
            icon="mdi mdi-pencil-outline"
            class="mr-2"
            @click="service.openCrudComponent(slotProps.data)"
          />
          <p-button
            text
            severity="danger"
            icon="mdi mdi-delete-outline"
            @click="service.confirmCategoryDelete(slotProps.data)"
          />
        </div>
      </template>
    </p-column>
    <template #expansion="slotProps">
      <div
        v-if="service.categoryAttributes[slotProps.data.id]"
        class="bg-cyan-50 border-round-md p-4"
      >
        <div v-if="!service.categoryAttributes[slotProps.data.id].length" class="text-center">
          {{ ts.tModule('noAttributes') }}
        </div>
        <div
          v-for="(attr, index) in service.categoryAttributes[slotProps.data.id]"
          v-else
          :key="index"
        >
          <div class="flex justify-content-between py-2 w-full">
            <div class="w-2">{{ attr.key }}</div>
            <div class="w-8">
              <span v-for="(option, i) in attr.options?.choices" :key="i">{{ option }} , </span>
            </div>

            <p-button
              text
              severity="danger"
              @click="service.detachAttribute(slotProps.data.id, attr.id)"
              >{{ ts.tModule('detach') }}</p-button
            >
          </div>
        </div>
      </div>
      <LoadingIndicator v-else-if="service.loadingAttributes" />
    </template>
    <template #empty>
      <EmptyState
        platform="supplier"
        :translation-service="ts"
        :icon-name="'categories'"
        :button-icon="'mdi mdi-plus'"
        @clicked="service.openCrudComponent()"
      />
    </template>
  </pDataTable>

  <pTree
    v-if="service.selectedTab.key === 'tree'"
    :value="service.treeViewNodes"
    selection-mode="single"
    @node-select="(node: TreeNode) => router.push({ name: 'edit-category', params: { id: node.id } })"
  />
</template>
