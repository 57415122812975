<script setup lang="ts">
import { Attribute } from 'platform-unit2-api/attributes';
import { TranslationService } from '@/general/services/translations/translation.service';

defineProps<{
  attribute: Attribute;
}>();

const ts = new TranslationService('supplier', 'datamodels');
</script>

<template>
  <div class="flex flex-column" :style="{ width: '47%' }">
    <span class="font-bold text-lg">{{ ts.tModule('datamodels_overwrite.body.default') }}</span>
    <div class="bg-gray-50 border-1 border-gray-100 flex flex-grow-1 my-2 p-3 pb-0">
      <div class="grid justify-content-center place-items-center text-center w-full">
        <div class="flex flex-column mx-2 p-3 w-full">
          <div class="flex mb-3 text-left w-full">
            <span class="font-bold w-5">{{ ts.tModule('datamodels_overwrite.body.name') }}</span>
            <span>{{ attribute.key }}</span>
          </div>
          <div class="flex mb-3 text-left w-full">
            <span class="font-bold w-5">{{
              ts.tModule('datamodels_overwrite.body.field_type')
            }}</span>
            <span>{{ attribute.options && attribute.options['type'] }}</span>
          </div>
          <div class="flex mb-3 text-left w-full">
            <span class="font-bold w-5">{{
              ts.tModule('datamodels_overwrite.body.required')
            }}</span>
            <span>{{ attribute.required ? 'Yes' : 'No' }}</span>
          </div>
          <div class="flex mb-3 text-left w-full">
            <span class="font-bold w-5">{{
              ts.tModule('datamodels_overwrite.body.help_text')
            }}</span>
            <span>{{ attribute.options && attribute.options['helpText'] }}</span>
          </div>

          <div v-for="(value, key) in attribute.options" :key="key">
            <div v-if="key !== 'type' && key !== 'helpText'" class="flex mb-3 text-left w-full">
              <span class="font-bold w-5">{{ key }}</span>
              <span>{{ value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
