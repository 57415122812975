<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';

/** Props */
interface Props {
  cron: string;
}

const props = withDefaults(defineProps<Props>(), {
  cron: '',
});

/** Emit */
const emit = defineEmits<{
  (e: 'generatedCrone', value: string): void;
  (
    e: 'disableNextButton',
    value: {
      daily: boolean;
      weekly: boolean;
      monthly: boolean;
    },
  ): void;
}>();

/** Constants */
const exportCron = ref<string>();
const valid = ref<boolean>(false);
const scheduleType = ref<'Daily' | 'Weekly' | 'Monthly'>('Daily');
const scheduleTypes = ref(['Daily', 'Weekly', 'Monthly']);
const dailySchedule = ref<number>(1);
const time = ref<Date>(new Date());
const weekDays = ref<string[]>(['1']);
const ts = new TranslationService('general', 'components');
const weekDaysOptions = ref<{ day: string; value: string }[]>([
  {
    day: 'Mo',
    value: '1',
  },
  {
    day: 'Tue',
    value: '2',
  },
  {
    day: 'Wed',
    value: '3',
  },
  {
    day: 'Thr',
    value: '4',
  },
  {
    day: 'Fri',
    value: '5',
  },
  {
    day: 'Sat',
    value: '6',
  },
  {
    day: 'Sun',
    value: '0',
  },
]);
const daysOfMonth = ref<string[]>(['1']);
const days = ref<{ day: string; value: string }[]>([
  { day: 'First day', value: '1' },
  { day: '2nd', value: '2' },
  { day: '3rd', value: '3' },
  { day: '4th', value: '4' },
  { day: '5th', value: '5' },
  { day: '6th', value: '6' },
  { day: '7th', value: '7' },
  { day: '8th', value: '8' },
  { day: '9th', value: '9' },
  { day: '10th', value: '10' },
  { day: '11th', value: '11' },
  { day: '12th', value: '12' },
  { day: '13th', value: '13' },
  { day: '14th', value: '14' },
  { day: '15th', value: '15' },
  { day: '16th', value: '16' },
  { day: '17th', value: '17' },
  { day: '18th', value: '18' },
  { day: '19th', value: '19' },
  { day: '20th', value: '20' },
  { day: '21th', value: '21' },
  { day: '22th', value: '22' },
  { day: '23th', value: '23' },
  { day: '24th', value: '24' },
  { day: '25th', value: '25' },
  { day: '26th', value: '26' },
  { day: '27th', value: '27' },
  { day: '28th', value: '28' },
  { day: '29th', value: '29' },
  { day: 'Last day', value: 'L' },
]);

const validDailyNumber = (): boolean => {
  return dailySchedule.value > 0 && dailySchedule.value < 30;
};

const validTime = (): boolean => {
  return time.value ? true : false;
};

const validWeekDays = (): boolean => {
  return weekDays.value.length > 0;
};

const validMonthDays = (): boolean => {
  return daysOfMonth.value.length > 0;
};

const validateInputChange = (e: any) => {
  e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
};

/** Lifecycles */
watchEffect(() => {
  const d = new Date(time.value);
  switch (scheduleType.value) {
    case 'Daily':
      valid.value = validDailyNumber() && validTime();
      exportCron.value = `${d.getMinutes()} ${d.getHours()} */${dailySchedule.value} * *`;
      valid.value && emit('generatedCrone', exportCron.value);
      break;
    case 'Weekly':
      valid.value = validWeekDays() && validTime();
      exportCron.value = `${d.getMinutes()} ${d.getHours()} * * ${weekDays.value.toString()}`;
      valid.value && emit('generatedCrone', exportCron.value);
      break;

    case 'Monthly':
      valid.value = validMonthDays() && validTime();
      exportCron.value = `${d.getMinutes()} ${d.getHours()} ${daysOfMonth.value.toString()} * *`;
      valid.value && emit('generatedCrone', exportCron.value);
      break;
  }
});

onMounted(() => {
  if (props.cron) {
    const cronArray = props.cron.trim().split(' ');
    scheduleType.value =
      cronArray[2] === '*' ? 'Weekly' : cronArray[2].includes('/') ? 'Daily' : 'Monthly';
    time.value = new Date();
    time.value.setHours(+cronArray[1]);
    time.value.setMinutes(+cronArray[0]);
    switch (scheduleType.value) {
      case 'Daily': {
        dailySchedule.value = +cronArray[2].replace(/\D/g, '');
        emit('disableNextButton', {
          daily: dailySchedule.value > 0 && dailySchedule.value < 30,
          weekly: false,
          monthly: false,
        });
        break;
      }

      case 'Monthly': {
        daysOfMonth.value = cronArray[2].split(',');
        emit('disableNextButton', {
          daily: false,
          weekly: false,
          monthly: true,
        });
        break;
      }

      case 'Weekly': {
        weekDays.value = cronArray[4].split(',');
        emit('disableNextButton', {
          daily: false,
          weekly: true,
          monthly: false,
        });
        break;
      }

      default:
        break;
    }
  }
});

watchEffect(() => {
  switch (scheduleType.value) {
    case 'Daily': {
      emit('disableNextButton', {
        daily: dailySchedule.value < 1 || dailySchedule.value > 29,
        weekly: false,
        monthly: false,
      });
      break;
    }

    case 'Monthly': {
      emit('disableNextButton', {
        daily: false,
        weekly: false,
        monthly: daysOfMonth.value.length === 0,
      });
      break;
    }

    case 'Weekly': {
      emit('disableNextButton', {
        daily: false,
        weekly: weekDays.value.length === 0,
        monthly: false,
      });
      break;
    }

    default:
      break;
  }
});
</script>
<template>
  <div class="mb-3">
    <label class="block font-bold mb-1">{{ ts.tModule('cronGenerator.title') }} </label>
    <label class="block mb-3 text-orange-500">{{ ts.tModule('cronGenerator.warning') }} </label>
    <p-select-button v-model="scheduleType" class="mb-3 types" :options="scheduleTypes" />
  </div>
  <div class="mb-3 p-2">
    <!-- Daily -->
    <div v-if="scheduleType === 'Daily'" class="mb-3 p-2">
      <div class="align-items-center flex lg-flex-row md-flex-column w-full">
        {{ ts.tModule('cronGenerator.every') }}
        <span class="mx-2 w-5">
          <p-input-text
            id="daily"
            v-model="dailySchedule"
            type="number"
            class="w-full"
            :placeholder="ts.tModule('cronGenerator.validDay')"
            @blur="validDailyNumber"
            @input="validateInputChange"
          />
        </span>
        {{ ts.tModule('cronGenerator.days') }} &nbsp; {{ ts.tModule('cronGenerator.at') }}
        <pDatePicker
          v-model="time"
          class="flex-grow-1 mx-2"
          :time-only="true"
          @change="validTime"
        />
        <small v-if="!validTime()" id="username2-help" class="p-error">
          {{ ts.tModule('cronGenerator.validation.time') }}
        </small>
      </div>
      <label v-if="!validDailyNumber()" id="username2-help" class="ml-6 p-error text-sm">
        {{ ts.tModule('cronGenerator.validation.day') }}
      </label>
    </div>

    <!-- Weekly -->
    <div v-if="scheduleType === 'Weekly'" class="align-items-center flex flex-wrap mb-3 p-2">
      <div>
        <div class="align-items-center flex">
          {{ ts.tModule('cronGenerator.every') }}
          <p-select-button
            v-model="weekDays"
            option-label="day"
            option-value="value"
            class="m-2 weekdays"
            :style="'min-width: 340px;'"
            :options="weekDaysOptions"
            multiple
          />
        </div>
        <small v-if="!validWeekDays()" id="username2-help" class="block p-error">
          {{ ts.tModule('cronGenerator.validation.week') }}
        </small>
      </div>
      <div class="flex flex-column flex-grow-1">
        <div class="align-items-center flex w-full">
          {{ ts.tModule('cronGenerator.at') }}
          <pDatePicker v-model="time" class="flex-grow-1 ml-2" :time-only="true" />
        </div>
        <small v-if="!validTime()" id="username2-help" class="p-error">
          {{ ts.tModule('cronGenerator.validation.time') }}
        </small>
      </div>
    </div>

    <!-- Monthly -->
    <div v-if="scheduleType === 'Monthly'" class="align-items-center flex mb-3 p-2">
      {{ ts.tModule('cronGenerator.every') }}
      <pMultiselect
        v-model="daysOfMonth"
        option-label="day"
        option-value="value"
        class="flex-grow-1 m-2 overflow-auto"
        :options="days"
        placeholder="Select days"
        display="chip"
      />

      {{ ts.tModule('cronGenerator.at') }}
      <pDatePicker v-model="time" class="flex-grow-1 m-2" :time-only="true" />
      <small v-if="!validTime()" id="username2-help" class="p-error">
        {{ ts.tModule('cronGenerator.validation.time') }}
      </small>
      <small v-if="!validMonthDays()" id="username2-help" class="block p-error">
        {{ ts.tModule('cronGenerator.validation.month') }}
      </small>
    </div>
  </div>
</template>
<style lang="scss" scoped>
:deep(.types) div {
  width: 33%;
}

:deep(.weekdays) div {
  font-size: 0.8rem;
}
</style>
