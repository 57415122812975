import { Component, markRaw } from 'vue';
import { BaseStoryModule } from './base-story-module.class';
import StandardSingleImageSpecsDetail from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-single-image-specs-detail.vue';
import { IContentImage } from './interfaces/IContentImage.interface';
import { Upload } from 'platform-unit2-api/uploads';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { SingleImageSpecsDetail } from '../story-types/modules/single-image-specs-detail.interface';
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { IBulletPoint } from './interfaces/IBulletPoint.interface';
import { TextItem } from '../story-types/text-item.interface';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ContentHeadline } from '../story-types/content-headline.interface';
import { StandardTextBlock } from '../story-types/standard-text-block.interface';
import { ImageValidatorService } from '@/general/services/image-validator/image-validator.service';
import { ModuleComponentTypes } from '../enums/component-types.enum';
export class SingleImageSpecsDetailClass
  extends BaseStoryModule<SingleImageSpecsDetail>
  implements IContentImage<unknown>, IBulletPoint<unknown>
{
  ts: TranslationService;
  minimumImageSize: `${string} : ${string}` = '300 : 300';
  acceptedFileTypes = 'image/png,image/jpg,image/jpeg';
  component: Component = markRaw(StandardSingleImageSpecsDetail);
  moduleTranslationKey = 'storyBuilder.singleImageSpecsDetailDisplayName';

  private _imageValidatorService: ImageValidatorService;
  constructor(data?: SingleImageSpecsDetail) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);
    this._imageValidatorService = new ImageValidatorService(this.minimumImageSize);
    if (this.data?.type == null) {
      this.data.type = ContentStoryModulesEnum.SingleImageSpecsDetail;
      this.data.specificationListBlock = {
        type: ModuleComponentTypes.StandardHeaderTextListBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: '',
        },
        block: {
          type: ModuleComponentTypes.StandardTextListBlock,
          textList: [],
        },
      };
    }
  }

  getAltText(): string | undefined {
    return this.data.image?.altText ?? '';
  }

  setAltText(altText?: string | undefined): void {
    this.data.image = {
      type: ModuleComponentTypes.Image,
      altText: altText ?? '',
      uploadDestinationId: this.data.image?.uploadDestinationId,
    };
  }

  //#region image
  setUploadData(upload: Upload): void {
    if (this._imageValidatorService.hasCorrectDimensions(upload)) {
      this.data.image = {
        type: ModuleComponentTypes.Image,
        altText: this.data.image?.altText ?? '',
        uploadDestinationId: upload.public_url,
      };
    }
  }
  hasUploadSet(): boolean {
    return this.data.image?.uploadDestinationId != null;
  }
  getUploadUrl(): string | undefined {
    return this.data.image?.uploadDestinationId;
  }
  //#endregion

  //#region BulletPoint
  addBulletPoint(value: string): void {
    if (value === '') {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.bulletPoints.empty'),
      );
      return;
    }

    if ((this.data.specificationListBlock?.block?.textList ?? []).length >= 8) {
      ToastService.getInstance().displayErrorToast(
        this.ts.tModule('storyBuilder.bulletPoints.maxAmountOfBulletPoints'),
      );
      return;
    }

    const newBulletPoint: TextItem = {
      type: ModuleComponentTypes.TextItem,
      text: {
        type: ModuleComponentTypes.Text,
        value: value,
      },
    };
    this.data.specificationListBlock?.block?.textList.push(newBulletPoint);
  }
  removeBulletPoint(index: number): void {
    this.data.specificationListBlock?.block?.textList.splice(index, 1);
  }
  getBulletPoints(): TextItem[] {
    if (this.data.specificationListBlock == null) {
      this.data.specificationListBlock = {
        type: ModuleComponentTypes.StandardHeaderTextListBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: '',
        },
        block: { type: ModuleComponentTypes.StandardTextListBlock, textList: [] },
      };
    }

    return this.data.specificationListBlock?.block?.textList ?? [];
  }

  //#endregion

  //#region headline of the bulletpoint
  setBulletPointHeadline(value?: string): void {
    if (value != null) {
      this.data.specificationListBlock = {
        type: ModuleComponentTypes.StandardHeaderTextListBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: value,
        },
        block: this.data.specificationListBlock?.block ?? {
          type: ModuleComponentTypes.StandardTextListBlock,
          textList: [],
        },
      };
    }
  }

  getBulletPointHeadline(): string {
    return this.data.specificationListBlock?.headline?.value ?? '';
  }
  //#endregion

  //#region headlines

  setHeadline(key: keyof SingleImageSpecsDetail, value?: string): void {
    if (value != null) {
      (this.data[key] as ContentHeadline) = {
        type: ModuleComponentTypes.Text,
        value: value,
      };
    }
  }

  getHeadline(key: keyof SingleImageSpecsDetail): string {
    return (this.data[key] as ContentHeadline)?.value ?? '';
  }

  //#endregion

  //#region standardTextBlock
  setStandardTextBlockHeadline(key: keyof SingleImageSpecsDetail, value?: string) {
    if (value != null) {
      (this.data[key] as StandardTextBlock) = {
        type: ModuleComponentTypes.StandardTextBlock,
        headline: {
          type: ModuleComponentTypes.Text,
          value: value,
        },
        body: (this.data[key] as StandardTextBlock)?.body ?? {
          type: ModuleComponentTypes.TextArea,
          value: '',
        },
      };
    }
  }
  getStandardTextBlockHeadline(key: keyof SingleImageSpecsDetail) {
    return (this.data[key] as StandardTextBlock)?.headline?.value ?? '';
  }

  setStandardTextBlockText(key: keyof SingleImageSpecsDetail, value?: string) {
    if (value != null) {
      (this.data[key] as StandardTextBlock) = {
        type: ModuleComponentTypes.StandardTextBlock,
        headline: (this.data[key] as StandardTextBlock)?.headline ?? {
          type: ModuleComponentTypes.Text,
          value: '',
        },
        body: {
          type: ModuleComponentTypes.TextArea,
          value: value,
        },
      };
    }
  }

  getStandardTextBlockText(key: keyof SingleImageSpecsDetail) {
    return (this.data[key] as StandardTextBlock)?.body?.value ?? '';
  }
  //#endregion
}
