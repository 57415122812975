import { TableColumnInterface } from '@/platforms/supplier/modules/products/services/product-table.constants';
import { FilterMatchMode } from '@primevue/core/api';

export type DataTableFilterInterface<T> = {
  [key in keyof T]: FilterValueInterface | undefined;
} & {
  table_sort: TableSortFilter;
  columns: TableColumnInterface[];
  gtinsSearch: string;
};

export interface FilterValueInterface {
  value: Record<'id' | 'name', string>[];
  filtered: boolean;
  matchMode?: string;
}

export interface TableSortFilter {
  fieldName: string | null;
  sortOrder: number | null;
}

export enum FilterPageKey {
  PRODUCT_OVERVIEW = 'product-overview',
}

export class DataTableFilterService<T> {
  private _filterPageKey: FilterPageKey;
  private filters: DataTableFilterInterface<T> = {} as DataTableFilterInterface<T>;

  constructor(pageKey: FilterPageKey) {
    this._filterPageKey = pageKey;
  }
  public setFilters(workspaceKey: string, filters: DataTableFilterInterface<T>) {
    this.filters = filters;
    localStorage.setItem(workspaceKey + '-' + this._filterPageKey, JSON.stringify(filters));
  }

  public setFilterProperty(key: keyof T, value?: FilterValueInterface) {
    (this.filters[key] as FilterValueInterface | undefined) = value;
    if (this.filters[key] == null && key !== 'table_sort') {
      (this.filters[key] as unknown as FilterValueInterface) = {
        value: [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      };
    }
  }

  public resetFilterByKey(workspaceKey: string, key: keyof T) {
    this.filters = this.getFiltersForWorkspace(workspaceKey);
    (this.filters[key] as FilterValueInterface) = {
      value: [],
      matchMode: FilterMatchMode.STARTS_WITH,
      filtered: false,
    };
    this.setFilters(workspaceKey, this.filters);
  }

  public getFiltersForWorkspace(workspaceKey: string): DataTableFilterInterface<T> {
    const result = localStorage.getItem(workspaceKey + '-' + this._filterPageKey);

    if (result != null) {
      const parsedResult = JSON.parse(result) as DataTableFilterInterface<T>;
      Object.keys(parsedResult).forEach((key: string) => {
        this.setFilterProperty(key as keyof T, parsedResult[key as keyof T]);
      });
    }

    return this.filters;
  }

  // Usage
  public resetFilters(workspaceKey: string): DataTableFilterInterface<T> {
    this.getFiltersForWorkspace(workspaceKey);

    Object.keys(this.filters).forEach((key) => {
      if (key === 'table_sort') {
        this.filters.table_sort = { fieldName: null, sortOrder: null };
        return;
      }

      if (key === 'gtinsSearch') {
        this.filters.gtinsSearch = '';
        return;
      }

      if (key === 'columns') {
        return;
      }

      (this.filters[key as keyof T] as FilterValueInterface) = {
        value: [],
        matchMode: FilterMatchMode.STARTS_WITH,
        filtered: false,
      };
    });
    this.setFilters(workspaceKey, this.filters);

    return this.filters;
  }

  public clearFilters(workspaceKey: string) {
    localStorage.removeItem(workspaceKey + '-' + this._filterPageKey);
  }
}
