import {
  ProductsRestService,
  Product,
  ProductLinkedChild,
  ProductLinkedChildrenRequest,
} from 'platform-unit2-api/products';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

/**
 * @description The service that holds the logic for the product hierarchies
 */

export class LinkProductService {
  private _toastService: ToastService;
  private _translationService: TranslationService;

  private _productAPI: ProductsRestService;
  private _isLoading = false;
  private _emitHide: (() => void) | undefined;
  private _linkedProducts: ProductLinkedChild[] = [];

  private _selectedProduct: Product | undefined;
  private _selectedQuantity = 1;

  private _excludedProducts: number[] = [];

  private _currentProductChildren: { id: number; quantity: number }[] = [];

  constructor(options: { emits?: { hide: () => void } }) {
    this._productAPI = new ProductsRestService();
    this._toastService = ToastService.getInstance();
    this._translationService = new TranslationService('supplier', 'products');
    this._selectedProduct = undefined;
    this._linkedProducts = [];
    this._emitHide = options.emits?.hide;
  }

  init({
    currentProduct,
    excludedProducts = [],
  }: {
    currentProduct?: Product;
    excludedProducts?: number[];
  }) {
    this._linkedProducts = currentProduct?.childs ?? [];

    this._currentProductChildren =
      currentProduct?.childs?.map((child) => ({
        id: child.product.id,
        quantity: child.quantity,
      })) ?? [];

    this._excludedProducts = excludedProducts;
  }

  /**
   * Attaches the linked products to the original product.
   * @param productId Id of the product to which linked products are attached
   */
  public async saveLinkedProducts(productId?: number) {
    this._isLoading = true;
    if (productId == null) {
      return;
    }

    try {
      const linkedProductsRequest: ProductLinkedChildrenRequest[] = this._linkedProducts.map(
        (linkedProduct) => ({
          product_id: linkedProduct.product.id,
          quantity: linkedProduct.quantity,
        }),
      );

      await this._productAPI.saveLinkedProducts(productId, linkedProductsRequest);

      this._toastService.displaySuccessToast(
        this._translationService.tModule('product_details.link_products.success'),
      );
    } catch (e) {
      this._toastService.displayErrorToast(this._translationService.unexpectedError);
    } finally {
      this._isLoading = false;
      this._emitHide?.();
    }
  }

  addLinkedProduct() {
    if (this._selectedProduct) {
      this._linkedProducts.push({
        product: {
          id: this._selectedProduct.id,
          display_name: this._selectedProduct.display_name,
          gtin: this._selectedProduct.gtin,
          brand_id: this._selectedProduct.brand_id,
          module_id: this._selectedProduct.module_id,
          completeness: this._selectedProduct.completeness,
          created_at: this._selectedProduct.created_at,
          updated_at: this._selectedProduct.updated_at,
          public: this._selectedProduct.public,
          variant_uuid: this._selectedProduct.variant_uuid,
          thumbnail: this._selectedProduct.thumbnail,
        },
        quantity: this._selectedQuantity,
      });

      //Add the selected product to the excluded products list
      this._excludedProducts.push(this._selectedProduct.id);
    }

    this._selectedProduct = undefined;
    this._selectedQuantity = 1;
  }

  removeLinkedProduct(selectedProductId: number) {
    this._linkedProducts = this._linkedProducts.filter((product: ProductLinkedChild) => {
      return product.product.id !== selectedProductId;
    }); //Remove the deleted product from the excluded products list

    const productPlaceInArray = this._excludedProducts.indexOf(selectedProductId);
    productPlaceInArray != -1 && //if  the id esists in the array
      this._excludedProducts.splice(this._excludedProducts.indexOf(selectedProductId), 1);
  }

  doesNewLinkedProductExist() {
    const newLinkedProductIds = this._linkedProducts.map((child) => ({
      id: child.product.id,
      quantity: child.quantity,
    }));

    // Check if the arrays have the same length
    if (this._currentProductChildren.length !== newLinkedProductIds.length) {
      return true;
    }

    // Make a sorted copy of each array
    const currentChildrenSorted = this._currentProductChildren.slice().sort();
    const newProductsSorted = newLinkedProductIds.slice().sort();

    // Compare each element in order
    for (let i = 0; i < currentChildrenSorted.length; i++) {
      // Use JSON.stringify to compare the objects
      if (JSON.stringify(currentChildrenSorted[i]) !== JSON.stringify(newProductsSorted[i])) {
        return true;
      }
    }

    // If the arrays are the same length and all elements are the same
    return false;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get linkedProducts(): ProductLinkedChild[] {
    return this._linkedProducts;
  }

  set linkedProducts(value: ProductLinkedChild[]) {
    this._linkedProducts = value;
  }

  get selectedProduct(): Product | undefined {
    return this._selectedProduct;
  }

  set selectedProduct(value: Product | undefined) {
    this._selectedProduct = value;
  }

  get selectedQuantity(): number {
    return this._selectedQuantity;
  }

  set selectedQuantity(value: number) {
    this._selectedQuantity = value;
  }

  set excludedProducts(value: number[]) {
    this._excludedProducts = value;
  }

  get currentProductChildren(): { id: number; quantity: number }[] {
    return this._currentProductChildren;
  }

  set currentProductChildren(value: { id: number; quantity: number }[]) {
    this._currentProductChildren = value;
  }
}
