import { actions } from 'supplier/modules/uploads/store/uploads.actions';
import { Module } from 'vuex';

const namespaced = true;
export const state = {};

export const uploadStore: Module<{}, {}> = {
  namespaced,
  state,
  actions,
};
