import { DebounceService } from '../debounce-service/debounce.service';
import { ApiVariableService } from '../api/api-variable.service';

import { DataTableSortEvent, DataTablePageEvent } from 'primevue/datatable';

export class RefetchService<F = {}> extends ApiVariableService<F> {
  //#region constants
  protected _total = 0;
  protected _sortField = '';
  protected _sortOrder = 0;

  protected _debounceService = new DebounceService(500);
  //#endregion

  //#region getters and setters

  //#region total
  public get total(): number {
    return this._total;
  }

  protected set total(value: number | undefined) {
    if (value == null) {
      this._total = 0;
      return;
    }

    this._total = value;
  }
  //#endregion total

  //#region sortfield
  public get sortField(): string {
    return this._sortField;
  }

  public set sortField(value: string | undefined) {
    if (value == null) {
      this._sortField = '';
      return;
    }

    this._sortField = value;
  }
  //#endregion sortfield

  //#region sortOrder

  public get sortOrder(): number {
    return this._sortOrder;
  }

  public set sortOrder(value: number | undefined) {
    if (value == null) {
      this._sortOrder = 1;
      return;
    }

    this._sortOrder = value;
  }

  //#endregion sortOrder

  //#region Query

  public set query(value: string | undefined) {
    this._paginationParams.query = value;
    this._paginationParams.page = 0;

    this._debounceService.debounce(() => this.refetch());
  }

  public get query(): string | undefined {
    return this._paginationParams.query;
  }

  //#endregion Query

  //#region page
  public set page(value: number | undefined) {
    this._paginationParams.page = value ?? 1;
    this.refetch();
  }

  public get page(): number | undefined {
    return this._paginationParams.page;
  }
  //#endregion page

  //#region rows
  public get limit(): number | undefined {
    return this._paginationParams.limit;
  }

  public set limit(value: number | undefined) {
    this._paginationParams.limit = value ?? 15;
    this.refetch();
  }

  public get rows(): number | undefined {
    return this.limit;
  }

  public set rows(value: number | undefined) {
    this.limit = value;
  }
  //#endregion rows

  //#region sortBy
  public get sortBy(): string | undefined {
    return this._paginationParams.sortBy;
  }

  public set sortBy(value: string | undefined) {
    this._paginationParams.sortBy = value;
  }

  public get filterParams(): F | undefined {
    return this._filterParams;
  }

  public set filterParams(value: F | undefined) {
    this._filterParams = value;
  }
  //#endregion sortBy

  //#region others
  public get first(): number {
    if (this.page == null || this.limit == null) {
      return 0;
    }

    const page = this.page === 0 ? 1 : this.page;

    return (page - 1) * this.limit;
  }

  //#endregion others

  //#endregion getters and setters

  //#region Event functions
  public onDatatablePageEvent(event: DataTablePageEvent) {
    this._paginationParams = {
      ...this._paginationParams,
      page: event.page + 1,
      limit: event.rows,
    };
    this.refetch();
  }

  public onPageChange(page: number) {
    this.page = page + 1;
  }

  public onLimitChange(rows: number) {
    this.limit = rows;
  }

  public onSortChange(sortEvent: DataTableSortEvent) {
    this.sortOrder = sortEvent.sortOrder != null ? (sortEvent.sortOrder > 0 ? 1 : -1) : undefined;

    this.sortField = (sortEvent.sortField as string) ?? '';
    this.sortBy = (this.sortOrder < 0 ? '-' : '') + this.sortField;

    this.page = 1;
  }

  public onQueryChange(query: string) {
    this.query = query;
  }
  //#endregion Event functions

  protected refetch() {
    throw new Error('Method not implemented.');
  }

  constructor() {
    super();
  }
}
