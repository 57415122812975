<script setup lang="ts">
import { ref, provide, Ref } from 'vue';
import { serviceKey } from '../ts/keys/asset-types.keys';
import { SupplierAssetTypeViewService } from '../ts/asset-type-view.service';
import OverViewSideBar from 'ui/components/over-view/overview-sidebar.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';
import AssetTypeForm from 'supplier/modules/asset-types/components/asset-type-form.vue';
import AssetTypeTable from '../components/asset-type-table.vue';
import OverView from 'ui/views/over-view/over-view.vue';

const ts = ref(new TranslationService('supplier', 'assetTypes'));
const service = ref(new SupplierAssetTypeViewService());

provide(serviceKey, service as Ref<SupplierAssetTypeViewService>);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView platform="supplier" module-name="assetTypes" :service="service">
    <template #content>
      <AssetTypeTable />
    </template>
    <template #crudComponent>
      <OverViewSideBar :service="service"
        ><template #form>
          <AssetTypeForm />
        </template>
      </OverViewSideBar>
    </template>
  </OverView>
</template>
