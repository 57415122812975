<script setup lang="ts">
import { UserRestService, User } from 'platform-unit2-api/users';
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectProps<User>>();
const userRestService = UserRestService;
</script>
<template>
  <BaseSelect
    :rest-service="userRestService"
    option-label="name"
    display="chip"
    class="w-full"
    module-name="user"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
