<script setup lang="ts">
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ref } from 'vue';
import { assetViewServiceKey } from '../keys/asset-view-service.key';
import { useService } from '@/general/composables/useService';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = ref<TranslationService>(new TranslationService('supplier', 'media'));
</script>
<template>
  <div class="flex flex-column h-full overflow-x-hidden">
    <div class="w-full">
      <div class="justify-content-center m-6">
        <p>
          {{ ts.tModule('import_assets.no_asset_info') }}
          <pTag class="mr-2" severity="primary" value="0536114842118_1.jpg" />
          <pTag severity="primary" value="0536114842118_2.pdf" />
        </p>
      </div>
    </div>
    <pSteps
      :model="service.importAssetsModal.importModalChildRoutes"
      exact
      :active-step="service.importAssetsModal.activeStep"
      class="pup-m-4"
      :pt="{
        action: {
          class: 'bg-gray-50',
        },
      }"
    />
    <RouterView v-slot="{ Component }" class="pup-my-4">
      <KeepAlive>
        <Component :is="Component" />
      </KeepAlive>
    </RouterView>
    <div class="bottom-0 fixed flex justify-content-end pup-m-4 right-0">
      <pButton
        v-if="
          service?.importAssetsModal.importModalChildRoutes[
            service.importAssetsModal.activeStep - 1
          ] && service
        "
        :disabled="service.importAssetsModal.disablePrevButton"
        outlined
        :label="ts.tGlobal('previous')"
        @click="service?.importAssetsModal.prevStep()"
      />
      <pButton
        class="pup-ml-3"
        :label="service.importAssetsModal.getNextButtonLabel()"
        :disabled="service.importAssetsModal.disableNextButton()"
        @click="service.importAssetsModal.getNextButtonAction()()"
      />
    </div>
  </div>
</template>
