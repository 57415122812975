import { Metric } from 'platform-unit2-api/dashboard';
import { DashboardData, DashboardState } from 'supplier/modules/dashboard/dashboard.types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<DashboardState> = {
  setData(state: DashboardState, data: DashboardData | undefined) {
    state.data = data;
  },
  setMetrics(state: DashboardState, data: Metric[]) {
    state.metrics = data;
  },
  setCachedAt(state: DashboardState, data: Date) {
    state.cached_at = data;
  },
};
