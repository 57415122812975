import { TranslationFormLabels } from '../../ts/interfaces/translation-forms.interface';
import { Translation } from '../../ts/interfaces/translation.interface';

const labels: TranslationFormLabels = {
  name: '@:global.name',
  chooseFile: 'Choose your file',
  description: '@:global.description',
  logo: 'Logo',
  type: 'Type',
  required: 'Required',
  options: 'Options',
  maxLength: 'Maximum length',
  minLength: 'Minimum length',
  email: '@:global.email',
  mobile: '@:global.mobile',
  address: '@:global.address',
  country: '@:global.country',
  position: '@:global.position',
  region: '@:global.region',
  avatar: '@:global.avatar',
  languages: '@:global.languages',
};

const forms: Translation = {
  forms: {
    labels: labels,
  },
};
export default forms;
