import { ValidationModel, ValidationModelsRestService } from 'platform-unit2-api/validation-models';
import { RefetchService } from '../overview-service/refetch.service';
import { ValidationSet } from 'platform-unit2-api/validation-sets';
import { chain } from 'lodash';
import {
  ValidationRule,
  ValidationRuleError,
  ValidationRuleItemAttribute,
  ValidationRuleItemType,
} from 'platform-unit2-api/validation-rules';
import store from '@/core/store';
import { FieldErrorEnum } from 'platform-unit2-api/attribute-fields';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';

export interface ValidationRulesGroupedByErrorAndAttributeName {
  [severity: string]: (ValidationRule & { linkedAttributes: ValidationRuleItemAttribute[] })[];
}

export class ProductAttributesValidationService extends RefetchService {
  private _navigationSidebarService = NavigationSidebarService.getNavigationSidebarService();
  private validationModelsRestService = new ValidationModelsRestService();
  private _validationModels: ValidationModel[] = [];
  public selectedValidationModel?: ValidationModel;
  public filterByErrors = false;
  public filterByWarnings = false;
  public validationPanelVisible = false;
  public validationModelSelectDialogOpen = false;
  public loading = false;
  public selectedValidationPanelTab = '';

  public get validationSets() {
    return this.selectedValidationModel?.validation_sets ?? [];
  }

  public get validationRulesBySeverityAndAttributeName(): ValidationRulesGroupedByErrorAndAttributeName {
    const validationRulesBySeverityAndAttributeName = this._groupBySeverityAndAttributeName(
      this.validationSets,
    );

    if (this.filterByErrors) {
      delete validationRulesBySeverityAndAttributeName['Warning'];
    }

    if (this.filterByWarnings) {
      delete validationRulesBySeverityAndAttributeName['Error'];
    }

    return validationRulesBySeverityAndAttributeName;
  }

  public get validationModelsSelectOpenCondition() {
    return !this.loading && this.validationPanelVisible && this.validationModelSelectDialogOpen;
  }

  public get validationModels() {
    return this._validationModels;
  }

  public get selectedModule() {
    return store.getters['products/currentProduct']?.module;
  }

  public get showFilters(): boolean {
    return (
      this.validationRulesBySeverityAndAttributeName['Error'] != null &&
      this.validationRulesBySeverityAndAttributeName['Warning'] != null
    );
  }

  public get errorsCount(): number {
    return (
      Object.keys(this._groupBySeverityAndAttributeName(this.validationSets)?.['Error'] ?? {})
        .length ?? 0
    );
  }
  public get warningsCount(): number {
    return (
      Object.keys(this._groupBySeverityAndAttributeName(this.validationSets)?.['Warning'] ?? {})
        .length ?? 0
    );
  }
  public get validationRulesCount(): number {
    return this.errorsCount + this.warningsCount;
  }

  toggleValidationPanel(state?: boolean) {
    this.validationPanelVisible = state ?? !this.validationPanelVisible;
    this.validationModelSelectDialogOpen = false;

    if (this.validationPanelVisible) {
      this.fetchAllValidationModels();
      this._navigationSidebarService.setNavigationStatus(false);
    }
  }

  public async assignValidationModelToProduct(chosenValidationModelId: number | null) {
    if (store.getters['products/currentProduct']?.id == null) {
      return;
    }

    // has to go through the store in order to set the product anew
    await store.dispatch('products/UPDATE_PRODUCT', {
      productId: store.getters['products/currentProduct'].id,
      data: {
        validation_model_id: chosenValidationModelId,
      },
    });
  }

  async fetchValidationModelFromProduct() {
    if (store.getters['products/currentProduct']?.validation_model?.id == null) {
      return;
    }

    const response = await this.validationModelsRestService.get(
      store.getters['products/currentProduct']?.validation_model?.id,
    );

    // fetch all regardless because of other functionalities
    await this.fetchValidationModelFromVariants();

    this.selectedValidationModel = response;

    if (store.getters['products/currentProduct']?.validation_model?.id !== response.id) {
      await this.assignValidationModelToProduct(response.id);
    }
  }

  async fetchValidationModelFromVariants() {
    const response = await this.validationModelsRestService.getAll(this._paginationParams, {
      module_id: store.getters['products/currentProduct']?.module?.id,
    });
    this._validationModels = response.data;
    if (this._validationModels.length === 1 && this.selectedValidationModel == null) {
      this.selectedValidationModel = this._validationModels[0];
      this.assignValidationModelToProduct(this.selectedValidationModel.id);
    }
  }

  fetchAllValidationModels() {
    this.loading = true;
    if (
      store.getters['products/currentProduct']?.id != null &&
      store.getters['products/currentProduct']?.validation_model?.id != null &&
      store.getters['products/currentProduct']?.module?.id ===
        store.getters['products/currentProduct']?.validation_model?.module?.id
    ) {
      this.fetchValidationModelFromProduct().finally(() => {
        this._storeRulesInStore();
        this.loading = false;
      });
      return;
    }

    if (store.getters['products/currentProduct']?.module?.id == null) {
      this.loading = false;
      return;
    }

    this.fetchValidationModelFromVariants().finally(() => {
      this._storeRulesInStore();
      this.loading = false;
    });
  }

  toggleFilterByErrors(value: boolean) {
    this.filterByWarnings = false;
    this.filterByErrors = value;
  }

  toggleFilterByWarnings(value: boolean) {
    this.filterByErrors = false;
    this.filterByWarnings = value;
  }

  toggleShowAll() {
    this.toggleFilterByErrors(false);
    this.toggleFilterByWarnings(false);
  }

  resetSelectedValidationModel() {
    this.selectedValidationModel = undefined;
    this.validationModelSelectDialogOpen = true;
  }

  async onApplyClick(intermediateSelectedValidationModel?: ValidationModel) {
    this.selectedValidationModel = intermediateSelectedValidationModel;
    this.validationModelSelectDialogOpen = false;
    if (
      store.getters['products/currentProduct']?.validation_model?.id !==
      intermediateSelectedValidationModel?.id
    ) {
      this.loading = true;
      await this.assignValidationModelToProduct(intermediateSelectedValidationModel?.id ?? null);
      this.loading = false;
    }
  }

  // Function to group validation rules by severity using reduce
  private _groupBySeverityAndAttributeName(
    data: ValidationSet[],
  ): ValidationRulesGroupedByErrorAndAttributeName {
    let newData = chain(data)
      .flatMap((validationSet) => {
        return validationSet.validation_rules.map((validationRule) => {
          // Extract linked_attributes from rules where type === 'attributes'
          const linkedAttributes: ValidationRuleItemAttribute[] = validationRule.rule
            ?.flat()
            .filter((r) => r.type === 'attributes')
            .map((r) => r.attribute)
            .filter((r) => r != null) as ValidationRuleItemAttribute[];

          return {
            ...validationRule,
            linkedAttributes: linkedAttributes,
          };
        });
      })
      .groupBy('severity')
      .value();

    // Having errors on top instead of warnings
    newData = {
      Error: newData['Error'],
      Warning: newData['Warning'],
    };

    return newData;
  }

  private _storeRulesInStore() {
    const rules = this._getValidationErrors();
    store.dispatch('products/SET_VALIDATION_ERRORS', rules);
  }

  // Returns the error messages for all the attributes in the validation rules
  private _getValidationErrors(): (ValidationRuleError | undefined)[] {
    const rules = this.validationSets
      .flatMap((validationSet) => validationSet.validation_rules)
      .map((validationRule) => ({
        rule: validationRule.rule,
        message: `Validation ${validationRule.severity.toLowerCase()}: ${validationRule.name}`,
        severity: this._getFieldErrorEnumSeverity(validationRule.severity.toUpperCase()),
      }))
      .map((ruleObject) => {
        const hasThen = ruleObject.rule
          ?.flat()
          .some(
            (rule) =>
              rule.type === ValidationRuleItemType.statement && rule.action?.name === 'THEN',
          );

        // If there is no then statement, then get all attributes
        if (!hasThen) {
          return ruleObject.rule
            ?.flat()
            .filter((rule) => rule.type === ValidationRuleItemType.attribute)
            .map(
              (attribute) =>
                ({
                  attributeId: attribute?.attribute?.id,
                  message: ruleObject.message,
                  severity: ruleObject.severity,
                } as ValidationRuleError),
            );
        }

        // From the rules extract the attributes which are after the THEN statement
        const thenIndex = (ruleObject.rule ?? [])
          .flat()
          .findIndex(
            (rule) =>
              rule.type === ValidationRuleItemType.statement && rule.action?.name === 'THEN',
          );

        return ruleObject.rule
          ?.flat()
          .filter(
            (rule, index) => rule.type === ValidationRuleItemType.attribute && thenIndex < index,
          )
          .map(
            (attribute) =>
              ({
                attributeId: attribute?.attribute?.id,
                message: ruleObject.message,
                severity: ruleObject.severity,
              } as ValidationRuleError),
          );
      })
      .flat();

    return rules;
  }

  // Cast 'error' severity to FieldErrorEnum.VALIDATION_ERROR, so that it doesn't block saving the product
  private _getFieldErrorEnumSeverity(severity: string) {
    if (severity === 'ERROR') {
      return FieldErrorEnum.VALIDATION_ERROR;
    }

    return FieldErrorEnum[severity as keyof typeof FieldErrorEnum];
  }
}
