<script setup lang="ts">
import Tiptap from 'supplier/modules/editor/components/tiptap.vue';

import PrismCode from 'supplier/modules/editor/components/prism-code.vue';
import { ref, onBeforeMount, watch, onMounted } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import InsertHtml from '../components/insert-html.vue';
import { useRoute } from 'vue-router';
import { ToastService } from '@/general/services/toasts/toast.service';

const editorValue = ref('');
const value = ref('');
const route = useRoute();
const newHmlt = ref(false);

/** Services */
const ts = new TranslationService('supplier', 'editor');

const indentHtml = (html: string) => {
  const tabSize = '  ';
  let result = '';
  let indent = '';
  html.split(/>\s*</).forEach(function (element: any) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tabSize.length);
    }

    result += indent + '<' + element + '>\r\n';
    if (element.match(/^<?\w[^>]*[^\/]$/)) {
      indent += tabSize;
    }
  });
  return result.substring(1, result.length - 3);
};

const indentEditorValue = (input: string) => {
  editorValue.value = indentHtml(input);
};

const copyToClipboard = () => {
  const copyText = document.querySelector('.ProseMirror')!.innerHTML;
  navigator.clipboard.writeText(copyText);
  ToastService.getInstance().displaySuccessToast(ts.tModule('copiedToClipboard'));
};

onBeforeMount(() => {
  value.value = ts.tModule('instructions').toString();
  editorValue.value = indentHtml(value.value);
});

function resolveInsertHml() {
  if (route.name == 'new-html-editor') {
    newHmlt.value = true;
  } else {
    newHmlt.value = false;
  }
}

onMounted(() => {
  resolveInsertHml();
});

watch(
  () => route.name,
  () => {
    resolveInsertHml();
  },
);
</script>
<template>
  <section class="h-full pt-5 px-4">
    <div class="grid">
      <div class="col-6">
        <div class="border-1 border-200 w-full">
          <Tiptap
            :model-value="editorValue"
            :allow-images="false"
            :allow-videos="false"
            @update:model-value="indentEditorValue"
          ></Tiptap>
        </div>
      </div>
      <div class="col-6">
        <PrismCode :model-value="editorValue" class="highlighted-code" language="html" />

        <p-button class="flex ml-auto mt-3 p-button-secondary" @click="copyToClipboard">
          {{ ts.tModule('copy_contents') }}
        </p-button>
      </div>
    </div>

    <InsertHtml v-model="editorValue" :is-active="newHmlt" />
  </section>
</template>
<style lang="scss" scoped>
/* Basic editor styles */
.ProseMirror {
  padding: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  ul {
    list-style-type: disc !important;
  }
  ol {
    list-style-type: decimal !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  &.ProseMirror-focused {
    outline: none;
  }
}
</style>
