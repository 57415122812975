<script lang="ts" setup>
import { ref, computed, onMounted, watchEffect, watch, inject } from 'vue';

import usePagination from 'composables/usePagination/pagination';
import axios from 'axios';

import MediaCard from '../components/media-card.vue';

import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { assetIds, colSize, disable } from '../media.keys';
import AttachMediaDialog from './attach-media-dialog.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { UploadsRestService, Upload } from 'platform-unit2-api/uploads';
import { SortValuePair } from '../media.types';
import { PaginationObject } from 'platform-unit2-api/core';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import UploadVue from '@/general/ui/components/upload.vue';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';

defineProps<{ isUploading?: boolean }>();
const emit = defineEmits<{
  (e: 'selectImage', image: Upload): void;
  (e: 'deselectImage', image: Upload): void;
  (e: 'isLoading', value: boolean): void;
  (e: 'selectMultipleImages', images: Upload[]): void;
  (e: 'deselectMultiple', images: Upload[]): void;
  (e: 'uploadAssets', event: FileUploadUploaderEvent): void;
}>();

// Services
const ts = new TranslationService('retailer', 'media');
const toastService = ToastService.getInstance();
const uploadApi = new UploadsRestService();

// Constants
const columnSize = inject(colSize);
const selectedIds = ref(inject(assetIds));
const disabled = inject(disable);
const router = useRouter();
const route = useRoute();
const { page, perPage, onRowChange: $onRowChange, query } = usePagination();
const loading = ref(true);
const sortFields: SortValuePair[] = [
  { label: ts.tModule('sort.new_old'), field: '-created_at' },
  { label: ts.tModule('sort.old_new'), field: 'created_at' },
  { label: 'A - Z', field: 'filename' },
  { label: 'Z - A', field: '-filename' },
];
const sort = ref<SortValuePair>(sortFields[0]);
const checked = ref<Upload[]>([]);
const assets = ref<Upload[]>([]);
const assetsIds = ref<number[]>([]);
const total = ref(0);
const actionMenu = ref();
const checkedIds = ref<number[]>([]);
const showSidebar = ref(false);
const displayAttachDialog = ref(false);
const selectedStatus = ref<'all' | 'some' | 'none'>('none');
const layout = ref<{ value: string; icon: string }>({
  value: 'grid',
  icon: 'mdi mdi-format-list-bulleted',
});
const listTreeViewOptions = ref([
  {
    value: 'grid',
    icon: 'mdi mdi-view-grid-outline',
  },
  {
    value: 'list',
    icon: 'mdi mdi-format-list-bulleted',
  },
]);

const actionItems = ref([
  {
    label: ts.tModule(`actions.attach_labels`),
    icon: 'mdi mdi-plus-box-multiple',
    command: async () => {
      openAttachDialog();
    },
  },
  {
    label: ts.tModule('actions.download_images'),
    icon: 'mdi  mdi-tray-arrow-down',
    command: async () => {
      bulkDownload();
    },
  },
  {
    label: ts.tModule('actions.delete_images'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: async () => {
      Promise.all(checked.value.map(async (asset) => await uploadApi.delete(asset.id)))

        .then(async () => {
          toastService.displaySuccessToast(ts.deleteSuccess());
          checked.value = [];
          await loadAsyncData();
        })
        .catch(() => {
          toastService.displayErrorToast(ts.deleteFailed());
        });
    },
  },
]);
const renderSelectButtonLabel = computed(() => {
  const text =
    selectedStatus.value === 'none' ? ts.tGlobal('select_all') : ts.tGlobal('deselect_all');
  return text + `( ${checked.value.length}   /  ${total.value} )`;
});

const renderSelectedStatusIcon = computed(() => {
  return selectedStatus.value === 'none'
    ? 'mdi mdi-checkbox-blank-outline'
    : selectedStatus.value === 'some'
    ? 'mdi mdi-minus-box-outline'
    : 'mdi mdi-checkbox-marked-outline';
});

// Helper functions
const manageSelectingAllFiles = (): void => {
  if (selectedStatus.value === 'none') {
    checked.value.push(...assets.value.filter((asset) => !selectedIds.value!.includes(asset.id)));
    emit('selectMultipleImages', checked.value);
    selectedStatus.value = 'all';
  } else {
    checked.value = checked.value.filter((asset) => !assetsIds.value.includes(asset.id));
    emit('deselectMultiple', checked.value);
    selectedStatus.value = 'none';
  }
};

const openAttachDialog = () => {
  displayAttachDialog.value = true;
};

const closeAttachDialog = () => {
  checked.value = [];
  displayAttachDialog.value = false;
};

const toggleActionMenu = (event: any): void => {
  actionMenu.value.toggle(event);
};

const setQuery = (searchQuery?: string): void => {
  query.value = searchQuery;
};

const addImage = (image: Upload) => {
  if (!checked.value.includes(image)) {
    checked.value.push(image);
    if (disabled) emit('selectImage', image);
  } else {
    checked.value.splice(checked.value.indexOf(image), 1);
    if (disabled) emit('deselectImage', image);
  }
};

const stopPropagation = (e: Event): void => {
  e.stopPropagation();
};

const onPageChange = async (event: any): Promise<void> => {
  page.value = event.page + 1;
  if (!disable) $onRowChange(event.rows);
  await loadAsyncData(query.value);
};

const changePage = async (): Promise<void> => {
  $onRowChange(perPage.value);
  await loadAsyncData();
};

const onSortChange = async () => {
  await loadAsyncData();
};

const hideDetails = (): void => {
  router.push({
    name: 'media',
  } as RouteLocationRaw);
};

const loadAsyncData = async (searchQuery?: string): Promise<void> => {
  emit('isLoading', (loading.value = true));
  if (searchQuery !== undefined) {
    setQuery(searchQuery);
  }

  try {
    const response = await uploadApi.getAll({
      page: page.value,
      limit: perPage.value,
      query: query.value,
      sortBy: sort.value.field,
    } as PaginationObject);

    assets.value = response.data;
    assetsIds.value = response.data.map((asset: Upload) => asset.id);
    total.value = response.meta?.total ?? 0;
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  } finally {
    setQuery(searchQuery);
    emit('isLoading', (loading.value = false));
  }
};

const bulkDownload = (): void => {
  checked.value.forEach((asset: Upload) => {
    download(asset);
  });
};

const optionItems = (asset: Upload): Array<Object> => {
  const items = [
    {
      label: ts.tGlobal('edit'),
      icon: 'mdi mdi-pencil-outline',
      command: () => {
        router.push({
          name: 'edit-media',
          params: { id: asset.id.toString() } as RouteParamsRaw,
        } as RouteLocationRaw);
      },
    },
    {
      label: ts.tGlobal('delete'),
      icon: 'mdi mdi-delete-outline',
      class: 'delete',
      command: () => {
        remove(asset.id);
      },
    },
    {
      label: ts.tGlobal('download'),
      icon: 'mdi mdi-download-box-outline',
      command: () => {
        download(asset);
      },
    },
  ];

  return items;
};

const remove = async (id: number): Promise<void> => {
  try {
    await uploadApi.delete(id);
    toastService.displaySuccessToast(ts.deleteSuccess());
    await loadAsyncData();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  }
};

const download = async (asset: Upload): Promise<void> => {
  try {
    axios
      .get(asset.public_url, {
        responseType: 'blob',
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', asset.filename);
        link.setAttribute('target', '_blank');
        link.click();
        URL.revokeObjectURL(link.href);

        checked.value = [];
        toastService.displaySuccessToast(ts.downloadSuccess());
      });
  } catch (err) {
    toastService.displayErrorToast(ts.downloadFailed());
  }
};

// Expose function to parent
defineExpose({
  loadAsyncData,
  setQuery,
});

// Lifecycle hooks
watch(
  () => route,
  () => {
    if (route.name === 'edit-media') {
      showSidebar.value = true;
    }

    if (route.name === 'media') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

watchEffect(() => {
  checkedIds.value = checked.value.map((asset) => asset.id);
});

watchEffect(() => {
  selectedStatus.value = assetsIds.value.every((assetId) => checkedIds.value.includes(assetId))
    ? 'all'
    : assetsIds.value.some((assetId) => checkedIds.value.includes(assetId))
    ? 'some'
    : 'none';
});

onMounted(async () => {
  if (route.name === 'edit-media') {
    showSidebar.value = true;
  }

  await loadAsyncData();
});
</script>
<template>
  <div class="flex-auto">
    <div v-if="loading || isUploading" class="cursor-pointer mt-3 p-2">
      <p-skeleton width="100%" height="50px" class="mb-2" />
      <div class="grid">
        <div
          v-for="i in 12"
          :key="i"
          class="col-12 mb-2 md:col-4 mt-2 p-1"
          :class="'lg:col-' + columnSize"
        >
          <div class="p-3 shadow-1">
            <p-skeleton width="100%" height="250px" class="mb-2" />
            <div class="align-items-center flex justify-content-between mb-3">
              <div class="mr-3 w-full"></div>
            </div>
            <p-skeleton width="100%" height="2rem" border-radius="2rem" class="mb-2" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-full">
      <div v-if="assets.length === 0" class="empty-state-container">
        <EmptyState
          :empty-state-title="ts.tGlobal('emptyStateTitle', { choice: 2, entity: ts.title })"
          :translation-service="ts"
          :icon-name="'media'"
          :button-visible="false"
        >
          <template #body>
            <p-file-upload
              ref="uploader"
              class="mr-2"
              mode="basic"
              :choose-label="ts.tGlobal('upload')"
              :multiple="true"
              :auto="true"
              :disabled="isUploading"
              :custom-upload="true"
              @uploader="(event: FileUploadUploaderEvent) => emit('uploadAssets', event)"
            />
          </template>
        </EmptyState>
      </div>
      <p-data-view
        v-else
        :value="assets"
        :layout="layout.value"
        :paginator="total > perPage"
        :rows="perPage"
        :lazy="true"
        :first="(page - 1) * perPage"
        :total-records="total"
        class="flex flex-column h-full"
        @page="onPageChange($event)"
      >
        <template #header>
          <div class="flex justify-content-between">
            <div class="align-items-center flex justify-content-between">
              <p class="mr-2 text-400 text-sm">
                <p-button
                  text
                  :label="renderSelectButtonLabel"
                  :icon="renderSelectedStatusIcon"
                  @click="manageSelectingAllFiles"
                />
              </p>
              <pSelect
                v-model="sort"
                :options="sortFields"
                option-label="label"
                @change="onSortChange"
              />
            </div>
            <div v-if="!disabled" class="align-items-center flex justify-content-end">
              <pSelect
                v-if="!disable"
                v-model="perPage"
                class="mr-2"
                :options="[15, 20, 50, 100]"
                @change="changePage()"
              />
              <pSelectButton
                v-model="layout"
                :options="listTreeViewOptions"
                class="mr-2"
                option-label="value"
                data-key="value"
              >
                <template #option="slotProps"> <i :class="slotProps.option.icon" /> </template
              ></pSelectButton>
              <p-button
                :disabled="checked.length ? false : true"
                :model="actionItems"
                :label="ts.tGlobal('actions')"
                class="p-button-outlined"
                icon="mdi mdi-chevron-down"
                icon-pos="right"
                aria-haspopup="true"
                aria-controls="action_menu"
                @click="toggleActionMenu"
              />
              <p-menu id="action_menu" ref="actionMenu" :model="actionItems" :popup="true" />
            </div>
          </div>
        </template>

        <template v-if="!disabled" #list="slotProps">
          <div class="align-items-center col-12 flex">
            <p-checkbox
              v-model="checked"
              :value="slotProps.data"
              @click="disabled ? addImage(slotProps.data) : stopPropagation($event)"
            />
            <div class="col-fixed relative w-5rem">
              <UploadVue :upload="slotProps.data" preview class="h-6rem ml-3" />
            </div>

            <div class="col">
              <div class="align-items-center flex flex-row ml-5">
                <div class="col-3">
                  <p class="inline mr-3 overflow-hidden text-overflow-ellipsis w-4">
                    {{ slotProps.data.filename }}
                  </p>
                </div>
                <div class="col-3">
                  <span class="w-4">
                    <p-chip
                      class="text-xs"
                      :label="slotProps.data.filename.split('.')[1].toUpperCase()"
                    />
                  </span>
                </div>
                <div class="col-2">
                  <label class="font-bold mr-auto">{{ slotProps.data?.asset_type?.name }}</label>
                </div>
                <div class="align-items-center col-3 flex justify-content-center">
                  <ActionsMenu :menu-items="optionItems(slotProps.data)" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #grid="slotProps">
          <media-card :image="slotProps.data" :checked="checked" @add-image="addImage" />
        </template>
      </p-data-view>
    </div>
    <pDrawer
      v-model:visible="showSidebar"
      class="p-drawer-md"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <router-view @hide="hideDetails" @refresh="loadAsyncData" />
    </pDrawer>
    <!-- Attach Media Dialog -->
    <attach-media-dialog
      :visible="displayAttachDialog"
      :selected-assets="checked"
      @close="closeAttachDialog"
      @back-to-image-selection="displayAttachDialog = false"
    />
  </div>
</template>

<style lang="scss" scoped>
#action_menu {
  background-color: #fff !important;
  width: 204px !important;

  .delete {
    border-top: 1px solid #ccc;

    span {
      color: rgb(179, 0, 0) !important;
    }
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.p-dataview :deep(.p-dataview-content) {
  flex: 1;
}
</style>
