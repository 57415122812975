import SupplierTranslations from '@/platforms/supplier/translations/en';
import RetailerTranslations from 'retailer/translations/en';
import GeneralTranslations from '@/general/translations/en';

import ActionsTranslations from './translations/en/actions.translation';
import ErrorsTranslations from './translations/en/errors.translation';
import GlobalTranslations from './translations/en/global.translation';
import ValidationTranslations from './translations/en/validation.translation';
import WarningsTranslations from './translations/en/warnings.translation';
import InfoTranslations from './translations/en/info.translation';
import SuccessTranslations from './translations/en/success.translation';
import FormsTranslations from './translations/en/forms.translation';

export default {
  global: GlobalTranslations,
  actions: ActionsTranslations,
  errors: ErrorsTranslations,
  info: InfoTranslations,
  warnings: WarningsTranslations,
  success: SuccessTranslations,
  ...GeneralTranslations,
  ...RetailerTranslations,
  ...SupplierTranslations,
  ...ValidationTranslations,
  ...FormsTranslations,
};
