import { ToastServiceMethods } from 'primevue/toastservice';
import { useToast } from 'primevue/usetoast';
import { TranslationService } from '@/general/services/translations/translation.service';

/**
 * This service is used to display toasts.
 * It is a singleton, so you can use it anywhere in the application.
 * @example
 * // Display a success toast
 * ToastService.getInstance().displaySuccessToast('This is a success toast');
 */
export class ToastService {
  private static _instance?: ToastService;

  private _toast: ToastServiceMethods;
  private _ts: TranslationService;

  /**
   * Create a new instance of the ToastService, this is a singleton so you should not use this constructor.
   * @throws Error when you try to create a new instance of the ToastService
   */
  constructor(toast = useToast(), ts = new TranslationService('global')) {
    if (ToastService._instance) {
      throw new Error(
        'Error: Instantiation failed: Use "ToastService.getInstance()" instead of "new ToastService()".',
      );
    }

    this._toast = toast;
    this._ts = ts;

    ToastService._instance = this;
  }

  /**
   * Get the instance of the ToastService
   * @returns The instance of the ToastService
   */
  public static getInstance(): ToastService {
    if (!ToastService._instance) {
      ToastService._instance = new ToastService();
    }

    return ToastService._instance;
  }

  /**
   * Display a toast
   * @param title the title of the toast
   * @param message the message the toast displays
   * @param severity the severity of the toast
   */
  private displayToast(
    title: string,
    message: string,
    severity: 'error' | 'success' | 'info' | 'warn',
  ) {
    this._toast.add({
      severity: severity,
      summary: title,
      detail: message,
      life: 3000,
    });
  }

  /**
   * Display a success toast
   * @param message the message the toast displays
   * @param title the title of the toast, default is the 'Success' translations
   */
  public displaySuccessToast(message: string, title?: string) {
    this.displayToast(title ?? this._ts.t('success.title'), message, 'success');
  }

  /**
   * Display a error toast
   * @param message the message the toast displays
   * @param title the title of the toast, default is the 'Error' translations
   */
  public displayErrorToast(message: string, title?: string) {
    this.displayToast(title ?? this._ts.t('errors.title'), message, 'error');
  }

  /**
   * Display a warning toast
   * @param message the message the toast displays
   * @param title the title of the toast, default is the 'Warning' translations
   */
  public displayWarningToast(message: string, title?: string) {
    this.displayToast(title ?? this._ts.t('warnings.title'), message, 'warn');
  }
}
