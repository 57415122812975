import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { AttributeTypeEnum, Attribute, AttributeOptions } from 'platform-unit2-api/attributes';
import { DatamodelsRestService, DatamodelAttributeConfig } from 'platform-unit2-api/datamodels';
import { Datamodel, UpdateDatamodelAttributeConfigRequest } from 'platform-unit2-api/datamodels';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

export class OverrideViewService {
  public get override(): DatamodelAttributeConfig | Partial<DatamodelAttributeConfig> {
    if (this._override == null) {
      this._override = {
        name: this._attribute.key,
        attribute_id: this._attribute.id,
        datamodel_id: this._datamodel.id,
        enabled: false,
        options: {
          ...this._attribute.options,
          type: this._attribute.options?.type ?? AttributeTypeEnum.DEFAULT,
        },
      };
    }

    return this._override;
  }

  public set override(value: DatamodelAttributeConfig | undefined) {
    if (value) {
      this.creating = false;
    } else {
      this.creating = true;
    }

    this._override = value;
  }

  public get validated(): boolean {
    if (this.override.name == null || this.override.name == '') {
      return false;
    }

    return true;
  }

  public options: Partial<AttributeOptions> = {};

  private _loading = false;

  public get loading(): boolean {
    return this._loading;
  }

  private _restService: DatamodelsRestService = new DatamodelsRestService();
  private _toastService: ToastService = ToastService.getInstance();
  private _confirmService = new ConfirmService();

  public get title() {
    return `${this._ts.tModule('datamodels_overwrite.header.name_prepend')} "${
      this._attribute.key
    }" ${this._ts.tModule('datamodels_overwrite.header.name_append')}`;
  }

  private _override?: DatamodelAttributeConfig | Partial<DatamodelAttributeConfig> = undefined;

  public creating = false;

  constructor(
    private _datamodel: Datamodel,
    private _attribute: Attribute,
    private _close: () => void,
    private _created: (override: DatamodelAttributeConfig) => void,
    private _deleted: () => void,
    private _ts: TranslationService,
    override?: DatamodelAttributeConfig,
  ) {
    if (override) {
      this.creating = false;
      this._override = override;
      this.options = { ...override.options };
    } else {
      this.creating = true;
      this.options = { ...this._attribute.options };
    }
  }

  public conditionallyRender = (requiredType: string) => {
    return this._attribute.options?.type === requiredType;
  };

  private _getConfigUpdateBody(): UpdateDatamodelAttributeConfigRequest {
    return {
      name: this.override.name,
      options: this.options,
      enabled: true,
    };
  }

  /** Override the overrides */
  public createOverride() {
    if (!this.validated) return;

    this._loading = true;

    this._restService
      .setDatamodelFieldDefinitionsConfig(
        this._datamodel.id,
        this._attribute.id,
        this._getConfigUpdateBody(),
      )
      .then((override) => {
        this._created(override);
        this._override = override;
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.createFailed());
      })
      .finally(() => {
        this._loading = false;
        this._close();
      });
  }

  public confirmResetOverride(event: PointerEvent) {
    this._confirmService.confirmDelete({
      event: event,
      callback: () => this.resetOverride(),
      group: 'resetOverrides',
      message: this._ts.tModule('confirm_reset_override'),
    });
  }

  private resetOverride() {
    if (!this._datamodel.id) {
      this._toastService.displayErrorToast(this._ts.tModule('override_rejected'));
      return;
    }

    this._loading = true;
    this._restService
      .deleteDatamodelAttributeOverride(this._datamodel.id, [this._attribute.id])
      .then(() => {
        this._deleted();
        this._override = undefined;
        this.options = { ...this._attribute.options };
        this._close();

        this._toastService.displaySuccessToast(this._ts.tModule('override_deleted'));
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('override_rejected'));
      })
      .finally(() => {
        this._loading = false;
      });
  }
}
