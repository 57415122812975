//vue
import { Component, markRaw } from 'vue';

//components
import StandardProductsDescriptionVue from 'supplier/modules/story-manager/components/story-builder/content-modules/standard-products-description.vue';

//types
import { ContentStoryModulesEnum } from '../enums/content-story-modules.enums';
import { ProductDescriptionText } from '../story-types/modules/product-description-text.interface';

//services
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';
import { TranslationService } from '@/general/services/translations/translation.service';
import i18n from '@/core/i18n';
import { ModuleComponentTypes } from '../enums/component-types.enum';

export default class ProductDescriptionTextClass extends BaseStoryModule<ProductDescriptionText> {
  ts: TranslationService;
  moduleTranslationKey = 'storyBuilder.productDescriptionTextModuleDisplayName';
  component: Component = markRaw(StandardProductsDescriptionVue);

  constructor(data?: ProductDescriptionText) {
    super(data);
    this.ts = new TranslationService('supplier', 'storyManager', false, i18n.global as any);

    if (this.data?.type == null) {
      this.data.type = ContentStoryModulesEnum.ProductDescriptionText;
      this.data.body = { type: ModuleComponentTypes.TextArea, value: '' };
    }
  }
  public setTextArea(textAreaValue: string) {
    this.data.body = { type: ModuleComponentTypes.TextArea, value: textAreaValue };
  }
}
