<script setup lang="ts">
import { computed, onMounted, watchEffect, ref } from 'vue';
import {
  CreateDatamodelRequest,
  Datamodel,
  DatamodelsRestService,
} from 'platform-unit2-api/datamodels';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ModuleDetail } from 'platform-unit2-api/modules';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { useRouter } from 'vue-router';
import { ModulesRestService } from 'platform-unit2-api/modules';
import { PaginationObject } from 'platform-unit2-api/core';
import { Defaults } from '@/general/utils/constants/defaults';

/** Emits */
const emit = defineEmits<{
  (e: 'createdDatamodel', datamodel: Datamodel): void;
  (e: 'close'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'datamodels');
const moduleApi = new ModulesRestService();
const dataModelApi = new DatamodelsRestService();

/** Constants */
const router = useRouter();

/** Create datamodel and pass the id */
const datamodelName = ref<string>();
const selectedchannel = ref<ModuleDetail>();
const datamodelNameEl = ref();

function close() {
  emit('close');
  router.push({ name: 'datamodels' });
}

const createDatamodel = async () => {
  if (submitDisabled.value) return;

  buttonsOptions.value.saving = true;
  try {
    const datamodel: CreateDatamodelRequest = {
      name: datamodelName.value!,
      template_id: selectedchannel.value?.template_id,
    };

    const newDatamodel = await dataModelApi.post(datamodel);

    emit('createdDatamodel', newDatamodel);
  } catch (error) {
    toastService.displayErrorToast(ts.createFailed());
  } finally {
    buttonsOptions.value.saving = false;

    close();
  }
};

/** Select all the channels */
const channels = ref<ModuleDetail[]>([]);
const getChannels = async () => {
  try {
    channels.value = (
      await moduleApi.getAll({ page: 1, limit: Defaults.REQUEST_LIMIT } as PaginationObject)
    ).data;
  } catch (err) {
    console.log(err);
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: close,
});

const createButton = new CreateButton({
  label: ts.tGlobal('create'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !submitDisabled.value,
  onClick: createDatamodel,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, createButton],
});

onMounted(() => {
  getChannels();
});

watchEffect(() => {
  if (datamodelNameEl.value) {
    datamodelNameEl.value.$el.focus();
  } else {
    // not mounted yet, or the element was unmounted (e.g. by v-if)
  }
});

/** return disable/enable status of submit button */
const submitDisabled = computed(() => {
  return !datamodelName.value;
});
</script>

<template>
  <BaseDialog
    :visible="true"
    :buttons-options="buttonsOptions"
    size="small"
    :title="ts.tModule('datamodel_name.title')"
    :subtitle="ts.tModule('datamodel_name.detail')"
    class="name-dialog"
    @update:visible="close()"
  >
    <!-- #region: Attribute override  -->
    <div class="flex flex-column my-3 text-gray-800">
      <p-input-text
        ref="datamodelNameEl"
        v-model="datamodelName"
        class="w-full"
        :disabled="buttonsOptions.saving"
        type="text"
        :placeholder="ts.tModule('datamodel_new.title')"
        @keyup.enter="createDatamodel"
      >
      </p-input-text>
    </div>
  </BaseDialog>
</template>
@/general/utils/constants/_constants
