import { TranslationGlobal } from '../../ts/interfaces/translation-global.interface';

const global: TranslationGlobal = {
  verify: 'Verify',
  avatar: 'Avatar',
  account: 'Account',
  actions: 'Actions',
  add: 'Add',
  admin_mode: 'Admin Mode',
  and: 'And',
  approve: 'Approve',
  apply: 'Apply',
  attach: 'Attach',
  cancel: 'Cancel',
  reset: 'Reset',
  change: 'Change',
  completeness_score: 'Completeness Score',
  completenessScore: 'Completeness Score',
  new: 'New',
  contact: 'Contact',
  contactSales: 'Contact sales',
  clear: 'Clear',
  close: 'Close',
  create: 'Create',
  created_at: 'Created at',
  decline: 'Decline',
  disable: 'Disable',
  day: 'Day',
  data: 'data',
  duplicate: 'Duplicate',
  delete: 'Delete',
  deletion: 'Deletion',
  download: 'Download',
  edit: 'Edit',
  enable: 'Enable',
  emptyStateTitle: 'There are no {entity} yet | {entity} folder is empty',
  files: 'File | files',
  from: 'From',
  inherited: 'Inherited',
  languages: 'Language | Languages',
  lastUpdate: 'Last updated',
  loading: 'loading...',
  locale: 'Locale',
  logo: 'Logo',
  master_data: 'Master Data',
  me: 'Me',
  month: 'Month',
  name: 'Name',
  never: 'Never',
  next: 'Next',
  no: 'No',
  nothingFound: 'Nothing found',
  optional: 'optional',
  options: 'Options',
  pause: 'Pause',
  previous: 'Previous',
  process: 'Process | Processes',
  public: 'Public',
  publish: 'Publish',
  published: 'Published',
  refresh: 'Refresh',
  remove: 'Remove',
  reset_filter: 'Reset Filter',
  restart: 'Restart',
  retry: 'Retry',
  revert: 'Revert',
  save: 'Save',
  saved: 'Saved',
  save_changes: 'Save changes',
  search: 'Search...',
  select: 'Select...',
  share: 'Share',
  sign_out: 'Sign Out',
  skip: 'Skip',
  start: 'Start',
  status: 'Status',
  system: 'System',
  time: 'Time',
  title: 'Title',
  to: 'To',
  today: 'Today',
  total: 'Total',
  type: 'Type',
  unknown_user: 'Unknown user',
  update: 'Update',
  updated_at: 'Updated At',
  variant: 'Variant name',
  variants: 'Variant | Variants',
  warning: 'Warning',
  week: 'Week',
  pup: 'Productsup',
  yes: 'Yes',
  country: 'Country',
  my_profile: 'My Profile',
  general: 'General',
  configuration: 'Configuration',
  administrator: 'Administrator',
  no_search_results: 'No search results',
  contact_productsup: 'contact Productsup.',
  transition: 'Change',
  transition_state: 'Change status',
  concept: 'Concept',
  feedback: 'Feedback',
  replace: 'Replace',
  gtin: 'GTIN',
  since: 'Since',
  none: 'None',
  leave: 'Leave',
  sync: 'Sync',
  click_to_select: 'Click to select...',
  selected: 'Selected',
  user_management: 'User management',
  select_all: 'Select all',
  deselect_all: 'Deselect all',
  updated: 'Last updated',
  upload: 'Upload',
  mobile: 'Mobilephone',
  address: 'Address',
  parent: 'Parent',
  description: 'Description',
  email: 'Email',
  color: 'Colour',
  thumbnail: 'Thumbnail',
  position: 'Position',
  unknown: 'Unknown',
  assign: 'Assign',
  select_language: 'Select language',
  send: 'Send',
  incorrectFileType: 'Incorrect File Type, allow file types: {allowedTypes}',
  chat: 'Chat',
  or: 'Or',
  password: 'Password',
  newPassword: 'New password',
  entityImport: 'Entity Import',
  entityExport: 'Entity Export',
  finish: 'Finish',
  continue: 'Continue',
  placeholder: 'Enter a value',
  //needed for product state
  completeness: 'Completeness',
  owner: 'Owner',
  sharedRetailer: 'Shared Retailer',
  variantName: 'Variant Name',
  region: 'Region',
  supplier: 'Supplier',
  notUpdated: 'Not updated',
  draft: 'Draft',
  dirtyState: 'Are you sure you want to leave?',
  dirtyStateMessage: `You have unsaved changes that will be lost.`,
  exit: 'Exit',
  gln: 'GLN',
  comingSoon: 'Coming soon',
  library: 'Library',
  here: 'here',
  rename: 'Rename',
  autosaved: 'Autosaved',
  submit: 'Submit',
  original: 'Original: {original}',
  visible: 'Visible',

  // Module names
  attributes: 'Attribute | Attributes',
  assets: 'Assets',
  assetType: 'Asset Type | Asset Types',
  brand: 'Brand | Brands',
  channel: 'Channel | Channels', // module
  category: 'Category | Categories',
  datamodel: 'Datamodel | Datamodels',
  developers: 'Developers | Developers',
  export: 'Export',
  import: 'Import',
  importMapping: 'Import Mapping | Import Mappings',
  label: 'Label | Labels',
  module: 'Module',
  organisation: 'Organisation | Organisations',
  products: 'Product | Products',
  productOverview: 'Product overview',
  profile: 'Profile',
  role: 'Role | Roles',
  retailer: 'Retailer | Retailers',
  settings: 'Settings',
  task: 'Task | Tasks',
  taskStatus: 'Task Status | Task Statuses',
  taskType: 'Task Type | Task Type',
  template: 'Template | Templates',
  user: 'User | Users',
  workspace: 'Workspace | Workspaces', // clients
  variantValidationModel: 'Variant validation model | Variant validation models',
  unit: 'Unit | Units',
  productTypes: 'Product type | Product types',
  marketplaceIds: 'Marketplace ID | Marketplace IDs',
  errorLoadingPlatform:
    'An error has occured while loading the application. Please try again later.',
};

export default global;
