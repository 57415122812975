<script setup lang="ts">
import { onMounted, ref } from 'vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import UserSelect from '@/general/ui/components/selects/user-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import ThumbnailUploader from 'ui/components/thumbnail-uploader.vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { useStore } from 'vuex';
import useFormValidation from 'composables/form-validation';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Locale } from 'platform-unit2-api/locales';
import { Module } from 'platform-unit2-api/modules';
import { ClientsRestService, Client, UpdateClientRequest } from 'platform-unit2-api/clients';
import { DirtyStateService } from '@/general/services/dirty-state/dirty-state.service';
import { onBeforeRouteLeave } from 'vue-router';

/* Composables */
const store = useStore();
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

/* Services */
const ts = new TranslationService('supplier', 'settings');
const toastService = ToastService.getInstance();
const clientApi = new ClientsRestService();
const dirtyStateService = new DirtyStateService<Client>();

/* Constants */
const loading = ref(true);
const saving = ref(false);
const editorWorkspace = ref<Client>();

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const client: UpdateClientRequest = {
      id: editorWorkspace.value!.id,
      name: editorWorkspace.value?.name ?? '',
      general_contact_id: editorWorkspace.value?.general_contact
        ? editorWorkspace.value.general_contact.id
        : undefined,
      financial_contact_id: editorWorkspace.value?.financial_contact
        ? editorWorkspace.value.financial_contact.id
        : undefined,
      thumbnail_id: editorWorkspace.value?.thumbnail?.id ?? null,
      locale_ids: editorWorkspace.value?.locales?.map((locale: Locale) => locale.id) ?? [],
      module_ids: editorWorkspace.value?.modules?.map((module: Module) => module.id) ?? [],
      organisation_id: editorWorkspace.value?.organisation_id ?? 0,
    };

    await clientApi.update(editorWorkspace.value!.id, client);

    toastService.displaySuccessToast(ts.updateSuccess());
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.updateFailed());
    });
  } finally {
    saving.value = false;
    dirtyStateService.changeInitialData(editorWorkspace.value!);
  }
};

onBeforeRouteLeave((_, __, next) => {
  dirtyStateService.showDirtyDialog(next, undefined, next);
});

// Lifecycle hooks
onMounted(async () => {
  loading.value = true;
  if (store.getters['users/currentUser']?.workspace?.id) {
    editorWorkspace.value = await clientApi.get(store.getters['users/currentUser'].workspace.id);
    dirtyStateService.changeInitialData(editorWorkspace.value);
    dirtyStateService.changeDataToCompare(editorWorkspace.value);
  }

  loading.value = false;
});
</script>

<template>
  <section class="h-full pt-3 px-4">
    <LoadingIndicator v-if="loading" />
    <div v-else>
      <div v-if="editorWorkspace" class="field mb-3">
        <label for="name">{{ ts.tForms('name') }}</label>
        <p-input-text
          v-model="editorWorkspace.name"
          class="w-full"
          :class="{ 'p-invalid': hasError('name') }"
          type="text"
          :disabled="true"
        />
        <small
          v-if="hasError('name')"
          :class="{ 'p-error block': hasError('name') }"
          class="hidden"
          >{{ fieldErrorMessage('name').toString() }}</small
        >
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <label for="email">{{ ts.tModule('workspace.generalContact') }}</label>
        <UserSelect
          v-model="editorWorkspace.general_contact"
          :class="{ 'p-invalid': hasError('general_contact_id') }"
          hide-label
        />
        <small
          v-if="hasError('general_contact_id')"
          :class="{ 'p-error block': hasError('general_contact_id') }"
          class="hidden"
          >{{ fieldErrorMessage('general_contact_id').toString() }}</small
        >
      </div>

      <div v-if="editorWorkspace" class="field mb-3">
        <label for="email">{{ ts.tModule('workspace.financialContact') }}</label>
        <UserSelect
          v-model="editorWorkspace.financial_contact"
          :class="{ 'p-invalid': hasError('financial_contact_id') }"
          hide-label
        />
        <small
          v-if="hasError('financial_contact_id')"
          :class="{ 'p-error block': hasError('financial_contact_id') }"
          class="hidden"
          >{{ fieldErrorMessage('financial_contact_id').toString() }}</small
        >
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <label for="logo">{{ ts.tGlobal('logo') }}</label>
        <div class="align-items-center flex formgroup-inline">
          <ThumbnailUploader
            :is-avatar="editorWorkspace.thumbnail == null"
            :username="editorWorkspace.name"
            :thumbnail="editorWorkspace.thumbnail"
            @uploaded="(value) => (editorWorkspace!.thumbnail = value)"
          />
        </div>
      </div>
      <div v-if="editorWorkspace" class="field mb-3">
        <ModuleSelect
          v-model="editorWorkspace.modules"
          :class="{ 'p-invalid': hasError('module_ids') }"
          :disabled="true"
          multiselect
        />
        <small
          v-if="hasError('module_ids')"
          :class="{ 'p-error block': hasError('module_ids') }"
          class="hidden"
          >{{ fieldErrorMessage('module_ids').toString() }}</small
        >
      </div>
      <div v-if="editorWorkspace" class="field mb-4">
        <label>{{ ts.tGlobal('languages') }}</label>
        <LocaleSelect
          v-model="editorWorkspace.locales"
          multiselect
          hide-label
          @fetch-finished="dirtyStateService.changeInitialData(editorWorkspace)"
        />
      </div>

      <div class="col field flex flex-row justify-content-end mt-3">
        <p-button
          :disabled="saving || !dirtyStateService.isTouched()"
          :label="ts.tGlobal('save')"
          :icon="saving ? 'mdi mdi-loading pi-spin' : ''"
          icon-pos="right"
          @click="handleSubmit"
        />
        <p-confirm-dialog />
      </div>
    </div>
    <pConfirmDialog group="dirty-dialog" />
  </section>
</template>
