<script setup lang="ts">
import { inject, onMounted, reactive } from 'vue';
import { GenerateFieldDataService } from '../../ts/generate-field-data.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import AttributeSelect from '@/general/ui/components/selects/attribute-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { DropdownChangeEvent } from 'primevue/dropdown';

const service = reactive(new GenerateFieldDataService());

const getProductData: () => Promise<void> = inject('loadAsync')!;
const getProductFields: () => Promise<void> = inject('loadFields')!;
const loading = inject('loadingProduct')!;
const ts = new TranslationService('supplier', 'ai-enhancements');

onMounted(async () => {
  await service.init();
});
</script>

<template>
  <section v-if="!loading">
    <p-button
      id="ai-button"
      class="mx-2"
      plain
      outlined
      icon="mdi mdi-robot"
      label="Generate field data"
      @click="service.openModal()"
    />

    <BaseDialog
      :visible="service.modalVisability"
      size="medium"
      @update:visible="service.closeModal()"
    >
      <template #header>
        <div class="pt-2">
          <h2 class="text-gray-500">Generate field data</h2>
          <p>Generate field data for your product</p>
        </div>
      </template>
      <div id="generateFieldDataBody" class="flex py-2">
        <div class="w-full">
          <div class="px-2">
            <div class="pb-2">
              <label class="mb-4">
                Target attribute: <span class="font-bold text-red-500">&ast;</span>
              </label>

              <AttributeSelect
                v-model="service.attribute"
                :options="service.attributes"
                hide-label
              />
            </div>

            <div class="pb-2">
              <label class="mb-4"> Source language: </label>

              <LocaleSelect
                v-model="service.sourceLocale"
                hide-label
                @change="
                  (event: DropdownChangeEvent) => {
                    service.sourceLocale = event.value;
                    service.getAvailableReferences();
                  }
                "
              />
            </div>

            <div class="pb-2">
              <label class="mb-4">
                Target language: <span class="font-bold text-red-500">&ast;</span></label
              >

              <LocaleSelect v-model="service.targetLocale" hide-label />
            </div>

            <div class="pb-2">
              <label class="mb-4"> References: </label>

              <pMultiselect
                id="attribute-multi-select"
                v-model="service.references"
                :disabled="service.sourceLocale == null ?? 'disabled'"
                :options="service.availableReferences"
                option-label="key"
                placeholder="References"
                :filter="true"
                :display="'chip'"
                empty-filter-message="No references found"
                class="w-full"
              />
            </div>

            <div class="pb-2">
              <label class="mb-4"> Additional request: </label>

              <p-input-text
                v-model="service.customPrompt"
                class="w-full"
                type="text"
                placeholder="Additional request for your data"
              />
            </div>
          </div>
        </div>
        <pDivider layout="vertical" />
        <div class="h-full w-full">
          <div class="h-full px-2">
            <label class="text-3xl"> Result: </label>

            <p v-if="service.response" class="p-2 text-2xl w-full">
              {{ service.response }}
            </p>

            <EmptyState
              v-else
              :translation-service="ts"
              :empty-state-title="'Generated field data'"
              :empty-state-subtitle="'Start generating your field!'"
              :button-visible="false"
              :icon-name="'master-data'"
            ></EmptyState>
          </div>
        </div>
      </div>

      <div>
        <pButton
          :label="service.generateButtonLabel"
          :disabled="(service.canGenerate || service.isGenerating) ?? 'disabled'"
          @click="service.generate()"
        />
      </div>
      <template #footer>
        <div class="flex flex gap-3 justify-content-end w-full">
          <pButton class="p-button-danger" label="Close" @click="service.closeModal()" />
          <pButton
            label="Accept"
            :disabled="(service.prompt == null || service.isGenerating) ?? 'disabled'"
            @click="
              () => {
                service.submit(getProductData, getProductFields);
              }
            "
          />
        </div>
      </template>
    </BaseDialog>
  </section>
</template>

<style scoped lang="scss">
#generateFieldDataBody {
  width: 50vw;
}
</style>
