import { Ref, ref, unref } from 'vue';
import { UseFetchProps } from './types';
import { useDispatch } from '@/general/composables/UseDispatch';
import { DispatchSuccess, DisplayToastSetting } from '@/general/composables/UseDispatch/types';
import { ListResponse, MetaResponse } from 'platform-unit2-api/core';
import { handleSuccess } from '@/general/composables/UseDispatch/helpers';
import { useRoute } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';

export function useFetch<T>({ action, options }: UseFetchProps<T>) {
  const { dispatch, loading } = useDispatch();
  const ts = new TranslationService('general', 'components');
  const route = useRoute();

  const data = ref<T[]>([]) as Ref<T[]>;
  const meta = ref<MetaResponse>();
  const total = ref<number>();
  const page = ref<string | number>();
  const perPage = ref<string | number>();

  const fetchData = () => {
    page.value =
      unref(options).page ?? (route.query.page ? parseInt(route.query.page.toString()) : 1);
    perPage.value =
      unref(options).perPage ?? (route.query.limit ? parseInt(route.query.limit.toString()) : 15);

    if (unref(options).toast?.displaySetting === undefined) {
      unref(options).toast = { ...unref(options).toast, displaySetting: DisplayToastSetting.NONE };
    }

    dispatch(unref(action), {
      parameters: unref(options).parameters,
      toast: {
        displaySetting: unref(options).toast?.displaySetting,
        successMessage: unref(options).toast?.successMessage || ts.loadSuccess(),
        rejectMessage: unref(options).toast?.rejectMessage,
      },
      pagination: unref(options).pagination ?? true,
      page: page.value,
      perPage: perPage.value,
      successCallback: (success: DispatchSuccess<ListResponse<T> | T[]>) => {
        if (unref(options).pagination) {
          data.value = (success.data as ListResponse<T>)?.data;
          meta.value = (success.data as ListResponse<T>)?.meta;
          total.value = (success.data as ListResponse<T>)?.meta?.total;
        } else {
          data.value = success.data as T[];
        }

        handleSuccess(unref(options), data.value);
      },
      rejectCallback: unref(options).rejectCallback,
    });
  };

  (unref(options).autoLoad || unref(options).autoLoad === undefined) && fetchData();

  return { loading, fetchData, data, meta, total, page, perPage };
}
