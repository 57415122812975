import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Search',
  placeholder: '',

  advancedSearchPlaceholder: 'Start typing to search or select a saved search',
  confirmSave: 'Are you sure you want to save this search?',

  advanced_search: {
    only_show_selected_products: 'Only show the seleted products',
  },
};

const translation: Translation = {
  search: translationObject,
};

export default translation;
