import { FeaturePermission, UserPermission } from 'platform-unit2-api/roles';
import { featurePermissionMap } from 'platform-unit2-api/roles';

import { User } from 'platform-unit2-api/users';
import { UserMode } from 'platform-unit2-api/core';
import { RoleCompact } from 'platform-unit2-api/roles';
import store from '@/core/store/index';

export const authorizationMethods = {
  hasAllPermissions(...args: FeaturePermission[]): boolean {
    const currentUser = store?.getters?.['users/currentUser'] as User;

    if (currentUser == null) {
      return false;
    }

    return args.every((permission) => {
      return featurePermissionMap[permission].every((userPermission) => {
        return currentUser.permissions?.includes(userPermission);
      });
    });
  },
  hasSomePermissions(...args: FeaturePermission[]): boolean {
    const currentUser = store?.getters?.['users/currentUser'] as User;
    if (!currentUser) {
      return false;
    }

    return args.some((permission) => {
      return featurePermissionMap[permission].every((userPermission) => {
        return currentUser.permissions?.includes(userPermission);
      });
    });
  },
  hasPermission(permission: FeaturePermission): boolean {
    const currentUser = store?.getters?.['users/currentUser'] as User;
    if (!currentUser) {
      return false;
    }

    return featurePermissionMap[permission].every((userPermission) => {
      return currentUser.permissions?.includes(userPermission);
    });
  },
  hasUserPermission(permission: UserPermission): boolean {
    const currentUser = store?.getters?.['users/currentUser'] as User;
    if (!currentUser) {
      return false;
    }

    return currentUser.permissions?.includes(permission) ?? false;
  },
  hasRole(role: Omit<RoleCompact, 'id'>): boolean {
    const currentUser = store?.getters?.['users/currentUser'] as User;
    if (!currentUser) {
      return false;
    }

    return currentUser.roles.map((role) => role.name).includes(role.name);
  },

  hasModePermission(mode: UserMode): boolean {
    const currentUser = store?.getters?.['users/currentUser'] as User;
    if (currentUser.admin_mode) return mode === UserMode.Admin ? true : false;
    else return mode === UserMode.User ? true : false;
  },
};

export default {
  methods: {
    $hasAllPermissions: authorizationMethods.hasAllPermissions,
    $hasSomePermissions: authorizationMethods.hasSomePermissions,
    $hasPermission: authorizationMethods.hasPermission,
    $hasUserPermission: authorizationMethods.hasUserPermission,
    $hasRole: authorizationMethods.hasRole,
  },
};
