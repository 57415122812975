import { Directive } from 'vue';

export default {
  beforeMount(el: any, binding: any) {
    el.clickOutsideEvent = function (event: any) {
      if (
        !(el === event.target || el.contains(event.target)) &&
        event.target.nodeName !== 'INPUT'
      ) {
        binding.value(event, el);
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el: any) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
} as Directive<any, any>;
