import { RouteFactory } from '@/core/router/route.factory';
import { RouteRecordRaw } from 'vue-router';
import GenerateView from 'supplier/modules/ai-enhancements/views/generate.view.vue';
import EditImageView from 'supplier/modules/ai-enhancements/views/edit-image.view.vue';
import PromptsView from 'supplier/modules/ai-enhancements/views/prompts.view.vue';
import { FeatureFlagService } from '@/core/featureflags/ts/service/feature-flags.service';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { RouterService } from '@/core/router/router.service';

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'ai-enhancements',
  navigationGroup: 'ai-enhancements',
  tabs: [
    {
      label: 'Prompts',
      key: 'Prompts',
      command: () => {
        RouterService.getInstance().router.push({
          name: 'Prompts',
        });
      },
    },
    {
      label: 'Generate',
      key: 'Generate',
      command: () => {
        RouterService.getInstance().router.push({
          name: 'Generate',
        });
      },
    },

    {
      label: 'Edit image',
      key: 'Edit image',
      command: () => {
        RouterService.getInstance().router.push({
          name: 'Edit image',
        });
      },
    },
  ],
});

export async function getAIRoutes(): Promise<RouteRecordRaw[]> {
  const service = FeatureFlagService.getInstance();

  const isOn = await service.isOn(featureFlagEnum.AI_ENHANCEMENTS);

  if (!isOn) {
    return [];
  }

  return [
    routeFactory.createAppRoute({
      path: '/ai-enhancements',
      name: 'AI enhancements',
      redirect: { name: 'Prompts' },
    }),
    routeFactory.createAppRoute({
      path: '/ai-enhancements/generate',
      name: 'Generate',
      component: GenerateView,
    }),
    routeFactory.createAppRoute({
      path: '/ai-enhancements/prompts',
      name: 'Prompts',
      component: PromptsView,
    }),
    routeFactory.createAppRoute({
      path: '/ai-enhancements/edit-image',
      name: 'Edit image',
      component: EditImageView,
    }),
  ];
}

export default getAIRoutes;
