import { CoreRestService } from 'platform-unit2-api/core';
import { ThumbnailsRestService, CreateThumbnail, Thumbnail } from 'platform-unit2-api/thumbnails';
import axios, { Method } from 'axios';
import { ActionTree } from 'vuex';

const coreApi = new CoreRestService();
const thumbnailApi = new ThumbnailsRestService();

export const actions: ActionTree<{}, {}> = {
  async STORE_THUMBNAIL({}, file): Promise<Thumbnail> {
    const sign = await coreApi.signFile(file);

    await axios.request({
      url: sign.url,
      method: sign.method as Method,
      data: file,
    });

    const createThumbnail: CreateThumbnail = {
      filename: file.name,
      contenttype: file.type,
      reference: sign.reference,
    };

    return await thumbnailApi.post(createThumbnail);
  },
};
