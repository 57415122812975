import { ToastService } from '@/general/services/toasts/toast.service';
import { ProductService } from '@/general/services/products/product.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ModulesRestService, IntegrationField, ModuleDetail } from 'platform-unit2-api/modules';
import { useRouter } from 'vue-router';
import { ExportRestService, StartExportRequest } from 'platform-unit2-api/exports';
import { User, UserRestService } from 'platform-unit2-api/users';

/**
 * @description The service that holds the logic for the product view & components.
 */
export class ProductVueService {
  public isLoading = false;
  private _productService: ProductService;
  private _channelsRestService: ModulesRestService;
  private _userRestService: UserRestService;
  private _exportRestService: ExportRestService;
  private _currentUser: User | undefined;

  private emitHide: (() => void) | undefined;

  // #region vue and prime related
  private ts: TranslationService;
  private toast: ToastService;
  private router = useRouter();
  // #endregion

  constructor(options?: { emits?: { hide: () => void }; toastService?: ToastService }) {
    this._productService = new ProductService();
    this._channelsRestService = new ModulesRestService();
    this._userRestService = new UserRestService();
    this._exportRestService = new ExportRestService();
    this.ts = new TranslationService('supplier', 'products');

    this.toast = options?.toastService ?? ToastService.getInstance();
    this.emitHide = options?.emits?.hide;
    this.#setCurrentUser();
  }

  #setCurrentUser = async () => {
    this._currentUser = await this._userRestService.getCurrentUser();
  };

  // #region Publication

  private _showPublicationMenu = false;
  private _publicationModalActive = false;
  private _publicationChannel: ModuleDetail | undefined = undefined;

  public get showPublicationMenu() {
    return this._showPublicationMenu;
  }

  public set showPublicationMenu(status: boolean) {
    this._showPublicationMenu = status;
  }

  public get publicationModalActive() {
    return this._publicationModalActive;
  }

  public set publicationModalActive(status: boolean) {
    this._publicationModalActive = status;
  }
  public get publicationChannel() {
    return this._publicationChannel;
  }

  public set publicationChannel(channel: ModuleDetail | undefined) {
    this._publicationChannel = channel;
  }

  public get currentUser() {
    return this._currentUser;
  }

  generatePublicationButton = async () => {
    const publicationChannelKey = 'gs1-cip-messages';
    const publicationChanneltype = 'export';
    try {
      const publicationChannelSearchResult = await this.getPublicationChannels(
        publicationChannelKey,
        publicationChanneltype,
      );

      //Check to see the user has access to publish/unpublish channels
      this.publicationChannel = publicationChannelSearchResult.find(
        (channel) => channel.key === publicationChannelKey,
      );

      this.showPublicationMenu = Boolean(this.publicationChannel);
    } catch (e: any) {
      this.toast.displayErrorToast(this.ts.tModule('publication_modal.get_channel_failed'));
    }
  };

  private getPublicationChannels = async (
    channelKey: string,
    channelType: string,
  ): Promise<ModuleDetail[]> => {
    let result = [];
    try {
      result = (
        await this._channelsRestService.getAll(
          {
            query: channelKey,
          },
          { type: channelType },
        )
      ).data;
    } catch {
      throw new Error('couldnt get the search result for the channels');
    }

    return result;
  };

  private _promptFields: Record<string, string> = {};
  private _requiredFields: string[] = []; //For validation
  private _conditionalRequiredFields: string[] = []; //For validation. Some fields are required based on the selection of other fields. It is found under 'rules.required_if'
  private _fieldsWithRegexPattern: Record<string, string>[] = []; //For validation

  createPropmtObject(channel: ModuleDetail) {
    this.publicationChannel = channel;
    channel.settings_fields?.prompt_fields?.forEach((option) => {
      this._promptFields = { ...this._promptFields, ...{ [option.key!]: option.default ?? '' } };
      if (option.required) {
        option.rules?.required_if
          ? this._conditionalRequiredFields.push(option.key!)
          : this._requiredFields.push(option.key!);
      }

      option.rules?.pattern &&
        this._fieldsWithRegexPattern.push({ [option.key!]: option.rules?.pattern });
    });
  }

  private _hiddenFields: string[] = []; //the fields that are conditionally hidden

  showField = (field: IntegrationField): boolean => {
    let showField = true;
    if (!field.rules || !field.rules?.required_if) {
      showField = true;
    } else if (field.rules?.required_if) {
      const requiredArray = field.rules.required_if.split(',');
      showField =
        this.promptFields[requiredArray[0]]?.toString().trim() ===
        requiredArray[1].toString().trim();
    }

    this.#manageHiddenFields({ key: field.key!, showField });
    return showField;
  };

  #manageHiddenFields = ({ key, showField }: { key: string; showField: boolean }) => {
    const fieldIndexinarray = this._hiddenFields.indexOf(key);

    if (showField) {
      //Remove from _hiddenFields if exists there
      fieldIndexinarray !== -1 && this._hiddenFields.splice(fieldIndexinarray);
    } else {
      //Add to _hiddenFields if it's not already exists'
      fieldIndexinarray === -1 && this._hiddenFields.push(key!);
    }
  };

  isFormValid = () => {
    return !this.isThereEmptyValue() && !this.isThereWrongInput();
  };

  private isThereEmptyValue = () => {
    let isThereEmptyValue = false;

    //if the fields is always required
    this.requiredFields.forEach((option: string): true | void => {
      if (!this.promptFields || this.promptFields[option] == '') {
        isThereEmptyValue = true;
      }
    });

    //If the field is conditionally required and is not hidden at the moment, shouldn't be empty
    this._conditionalRequiredFields.forEach((option: string): true | void => {
      const isTheFieldVisible = this._hiddenFields.indexOf(option) === -1; //Check if the field is visible
      if (isTheFieldVisible && (!this.promptFields || this.promptFields[option] == '')) {
        isThereEmptyValue = true;
      }
    });
    return isThereEmptyValue;
  };

  private isThereWrongInput = () => {
    let isThereWrongInput = false;
    this.fieldsWithRegexPattern.forEach((option): true | void => {
      Object.entries(option).forEach(([key, value]) => {
        const isTheFieldVisible = this._hiddenFields.indexOf(key) === -1; //Check if the field is visible

        const regex = new RegExp(value);
        const valueToTest = this.promptFields ? this.promptFields[key] : '';
        if (isTheFieldVisible && !regex.test(valueToTest)) {
          isThereWrongInput = true;
        }
      });
    });
    return isThereWrongInput;
  };

  publicationRequest = async (payload: StartExportRequest) => {
    this.#removeHiddenFieldsFromPayload(payload);
    try {
      await this._exportRestService.startExport(payload);
      this.toast.displaySuccessToast(this.ts.tModule('publication_modal.started_successfully'));
    } catch (err: any) {
      this.toast.displayErrorToast(this.ts.tModule('publication_modal.starting_failed'));
      await this.router.push({ name: 'products' });
    }
  };

  #removeHiddenFieldsFromPayload = (payload: StartExportRequest) => {
    this._hiddenFields.forEach((key) => {
      if (payload.prompt_fields != null) {
        payload.prompt_fields[key] = '';
      }
    });
  };

  public get promptFields() {
    return this._promptFields;
  }

  public set promptFields(value) {
    this._promptFields = value;
  }

  public get requiredFields() {
    return this._requiredFields;
  }

  public set requiredFields(value) {
    this._requiredFields = value;
  }

  public get fieldsWithRegexPattern() {
    return this._fieldsWithRegexPattern;
  }

  public set fieldsWithRegexPattern(value) {
    this._fieldsWithRegexPattern = value;
  }

  public get conditionalRequiredFields() {
    return this._conditionalRequiredFields;
  }

  // #endregion
}
