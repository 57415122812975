import { ListResponse, PaginationObject } from 'platform-unit2-api/core';
import tags from 'language-tags';
import { ActionTree } from 'vuex';
import { Locale, LocalesRestService } from 'platform-unit2-api/locales';
import { Defaults } from '@/general/utils/constants/defaults';
import { LocaleState } from '../locales.types';

const localeApi = new LocalesRestService();

export const actions: ActionTree<LocaleState, {}> = {
  async SEARCH_LOCALES(
    {},
    { page, limit = Defaults.REQUEST_LIMIT, query, sortBy }: PaginationObject,
  ): Promise<ListResponse<Locale>> {
    const locales = await localeApi.getAll({ page, limit, query, sortBy });

    locales.data = locales.data.map((locale: Locale) => {
      const language = tags(locale.value)?.language()?.descriptions();
      const region = tags(locale.value)?.region()?.descriptions();
      const format = tags(locale.value)?.region()?.format();

      return {
        id: locale.id,
        supported: locale.supported,
        value: locale.value,
        language: language?.[0],
        region: region?.[0],
        format: format?.toLowerCase(),
      };
    });

    return locales;
  },

  async SET_CURRENT_LOCALE({ commit }, locale: Locale) {
    commit('setCurrentLocale', locale);
  },
};
