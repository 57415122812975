//vue
import { useRoute } from 'vue-router';

//types
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';

//services
import { ContentStoryRestService } from '@/api/v1/content-story/content-story.rest';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { PaginationObject } from 'platform-unit2-api/core';
import { AxiosError } from 'axios';

export default class StoryManagerService {
  loading = false;
  api = new ContentStoryRestService();
  confirmService = new ConfirmService();
  ts = new TranslationService('supplier', 'storyManager');
  route = useRoute();
  toastService = ToastService.getInstance();

  chosenStory: ContentStory | undefined;

  async getContentStories(params: PaginationObject) {
    try {
      return await this.api.getAll(params);
    } catch (e) {
      this.toastService.displayErrorToast(this.ts.getFailed());
      return [];
    }
  }

  async getStory(id?: number): Promise<ContentStory> {
    const finalId = id ?? Number(this.route.params.id);

    this.loading = true;
    const result = await this.api.get(finalId);
    this.chosenStory = result;
    this.loading = false;
    return result;
  }

  async createNewStory(name: string, is_draft: boolean) {
    this.loading = true;
    try {
      this.chosenStory = await this.api.post({ story_name: name, is_draft: is_draft });
    } catch (e) {
      this.toastService.displayErrorToast(this.ts.createFailed());
    } finally {
      this.loading = false;
    }
  }

  async duplicate(storyId: number) {
    this.loading = true;
    try {
      await this.api.duplicate(storyId);
    } catch (e) {
      this.toastService.displayErrorToast(this.ts.duplicateFailed());
    } finally {
      this.loading = false;
    }
  }

  async deleteStory(storyId: number) {
    this.loading = true;
    try {
      await this.api.delete(storyId);
    } catch (e) {
      if (
        (e as AxiosError<{ message: string }, string>).response?.status === 400 &&
        (e as AxiosError<{ message: string }, string>).response?.data?.message?.includes(
          'products attached',
        )
      ) {
        this.toastService.displayWarningToast(
          this.ts.tModule('deletion.message'),
          this.ts.tModule('deletion.title'),
        );
        throw e;
      }

      this.toastService.displayErrorToast(this.ts.deleteFailed());
    } finally {
      this.loading = false;
    }
  }

  resetStory() {
    this.chosenStory = undefined;
  }

  async getModules() {
    try {
      this.loading = true;
      return await this.api.getModules();
    } catch (error) {
      this.toastService.displayErrorToast(this.ts.loadFailed());
      return;
    } finally {
      this.loading = false;
    }
  }

  async updateStoryName(id: number, name: string) {
    if (id == null || id === 0) {
      return;
    }

    try {
      this.chosenStory = (await this.api.update(id, {
        id: id,
        story_name: name,
        is_draft: true,
      })) as ContentStory | undefined;
    } catch (e) {
      this.toastService.displayErrorToast(this.ts.createFailed());
    } finally {
      this.loading = false;
    }
  }
}
