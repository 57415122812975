import { ref, Ref } from 'vue';

export default function (): any {
  const query: Ref<string> = ref('');

  const onSearch = (value: string, callback?: () => void): void => {
    query.value = value;
    callback ? callback() : null;
  };

  return {
    onSearch,
    query,
  };
}
