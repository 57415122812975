<script setup lang="ts">
//vue
import { inject } from 'vue';

//components
import EmptyDndState from '../../components/story-builder/content-components/empty-dnd-state.vue';

//types
import { ContentStoryModulesEnum } from '@/api/v1/content-story/ts/enums/content-story-modules.enums';
import { storyBuilderServiceKey } from '../../ts/helpers/story-manager-keys';

//services / composables
import { TranslationService } from '@/general/services/translations/translation.service';

//props
interface Props {
  isDragged: boolean;
  chosenModule?: ContentStoryModulesEnum;
}
defineProps<Props>();

//services
const ts = new TranslationService('supplier', 'storyManager');

//inject/provide
const storyService = inject(storyBuilderServiceKey);
</script>

<template>
  <div
    class="bg-white h-full overflow-auto p-5 story-viewer w-full"
    data-testid="story-builder-viewer"
  >
    <div v-if="storyService?.modules.length === 0" class="two-empty-states-wrapper">
      <EmptyDndState
        icon-name="empty_state_a"
        :drag-and-drop="isDragged"
        :empty-state-subtitle="ts.tModule('storyBuilder.emptyState.message')"
        @on-drop="storyService?.addStoryModule(chosenModule)"
      />
      <EmptyDndState
        icon-name="empty_state_a"
        :drag-and-drop="isDragged"
        :empty-state-subtitle="ts.tModule('storyBuilder.emptyState.message')"
        @on-drop="storyService?.addStoryModule(chosenModule)"
      />
    </div>
    <div v-if="(storyService?.modules?.length ?? 0) > 0" class="two-empty-states-wrapper">
      <TransitionGroup name="viewer">
        <component
          :is="storyModule.component"
          v-for="storyModule in storyService?.modules ?? []"
          :key="storyModule.uuid"
          :uuid="storyModule.uuid"
        />
      </TransitionGroup>

      <EmptyDndState
        icon-name="empty_state_a"
        :drag-and-drop="isDragged"
        :empty-state-subtitle="ts.tModule('storyBuilder.emptyState.message')"
        @on-drop="storyService?.addStoryModule(chosenModule)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$border-radius: 6px;
$border-color: #dce5ef;
.story-viewer {
  border: 1px solid $border-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
.two-empty-states-wrapper {
  height: 100%;
}

/* moving */
.viewer-move {
  transition: all 600ms ease-in-out 50ms;
}

/* appearing */
.viewer-enter-active {
  transition: all 400ms ease-in;
}

/* disappearing */
.viewer-leave-active {
  opacity: 0;
  transition: all 400ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.viewer-enter-to .viewer-leave-to {
  opacity: 0;
}
</style>
