<script setup lang="ts">
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import { ref, computed, onMounted, inject } from 'vue';
import { ProductVueService } from 'retailer/modules/products/services/product-vue.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Product, ProductLinkedChild } from 'platform-unit2-api/products';
import ProductSearchSelect from '@/general/ui/components/selects/product-search-select.vue';

/**Props */
interface Props {
  currentProduct?: Product;
  isActive: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

const loadAsyncProduct: (() => void | undefined) | undefined = inject('loadAsync');

function emitHide(): void {
  loadAsyncProduct?.();
  emit('hide');
}

/** Constants */
const ts = new TranslationService('retailer', 'products');

const productVueService = ref(new ProductVueService(emitHide));

const selectedProduct = ref<Product>();
const selectedQuantity = ref<number>(1);
const linkedProducts = ref<ProductLinkedChild[]>([]);

const removeLinkedProduct = (selectedProductId: number) => {
  linkedProducts.value = linkedProducts.value.filter((product: ProductLinkedChild) => {
    return product.product.id !== selectedProductId;
  });
};

const addLinkedProduct = () => {
  selectedProduct.value &&
    linkedProducts.value.push({
      product: selectedProduct.value,
      quantity: selectedQuantity.value,
    });
  selectedQuantity.value = 1;
};

const excludes = computed(() => {
  if (!props.currentProduct) return [];

  const excludedProducts = linkedProducts.value.map((item: ProductLinkedChild) => item.product.id);
  excludedProducts.push(props.currentProduct?.id);
  return excludedProducts;
});

const linkedProductsRequest = computed(() =>
  linkedProducts.value.map((product) => {
    return {
      product_id: product.product.id,
      quantity: product.quantity,
    };
  }),
);

onMounted(() => {
  linkedProducts.value =
    props.currentProduct?.childs.map(
      (child) =>
        ({
          product: {
            display_name: child.product.display_name,
            id: child.product.id,
          },
          quantity: child.quantity,
        } as ProductLinkedChild),
    ) ?? [];
});
</script>

<template>
  <pDrawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    position="right"
    @update:visible="$emit('hide')"
  >
    <template #header>
      <div>
        <h3 class="font-bold mb-3 text-2xl">
          {{
            ts.tModule('product_details.link_products.title', {
              params: { name: currentProduct?.display_name ?? '' },
            })
          }}
        </h3>
        <p class="text-lg">{{ ts.tModule('product_details.link_products.description') }}</p>
      </div>
    </template>
    <div>
      <sidebar-skeleton v-if="!currentProduct" />
      <div class="fields flex justify-content-between">
        <label class="w-9" for="linked-product">{{
          ts.tModule('product_details.link_products.product_gtin')
        }}</label>
        <label class="w-4" for="quantity">{{
          ts.tModule('product_details.link_products.linked_product_amount.title')
        }}</label>
      </div>

      <div class="fields flex justify-content-between mb-3">
        <div class="w-9">
          <ProductSearchSelect v-model="selectedProduct" :excludes="excludes" />
        </div>
        <div class="ml-2 w-3">
          <p-input-text
            id="quantity"
            v-model="selectedQuantity"
            class="w-full"
            type="number"
            :min="1"
          />
        </div>
        <p-button
          class="ml-2"
          icon="mdi mdi-check"
          :disabled="!selectedProduct || selectedQuantity < 1"
          @click="addLinkedProduct"
        />
      </div>
      <div class="border-100 border-top-1 mt-5 pt-5">
        <h3 class="font-bold mb-3 text-xl">
          {{ ts.tModule('product_details.link_products.subtitle') }}
        </h3>

        <pDataTable :value="linkedProducts" style-class="noHeader">
          <p-column field="display_name" header-style=" display: none;">
            <template #body="slotProps">
              {{ slotProps.data.display_name }}
              {{ slotProps.data.variant_name ? `(${slotProps.data.variant_name})` : '' }}
            </template>
          </p-column>
          <p-column field="quantity" header-style="display: none;"></p-column>
          <p-column field="delete" header-style="display: none;">
            <template #body="slotProps">
              <div class="flex justify-content-end">
                <p-button
                  icon="mdi mdi-close"
                  text
                  plain
                  rounded
                  @click="removeLinkedProduct(slotProps.data.product_id)"
                />
              </div>
            </template>
          </p-column>
        </pDataTable>
      </div>
      <div class="bottom-0 flex justify-content-end mt-5 py-3 sticky">
        <p-button :label="ts.tGlobal('cancel')" text plain class="mr-2" @click="$emit('hide')" />
        <p-button
          :disabled="productVueService.isLoading"
          :label="ts.tGlobal('save')"
          :icon="productVueService.isLoading ? 'mdi mdi-loading pi-spin' : ''"
          icon-pos="right"
          @click="
            productVueService.saveLinkedProducts({
              productId: currentProduct!.id,
              linkedProducts: linkedProductsRequest,
            })
          "
        />
      </div>
    </div>
  </pDrawer>
</template>
