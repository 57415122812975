<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AppLayout from './app-layout.vue';

import { AppService } from './ts/services/App.service';

const service = ref(new AppService());

onMounted(async () => {
  await service.value.loadApp();
});
</script>

<template>
  <AppLayout />
</template>
