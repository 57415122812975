<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { DefaultSelectProps } from './ts/default-select-props.interface';
import { ActionType, ActionTypesRestService } from 'platform-unit2-api/action-types';

const props = defineProps<DefaultSelectProps<ActionType>>();
const actionTypeRestService: any = ActionTypesRestService;
</script>
<template>
  <BaseSelect
    :rest-service="actionTypeRestService"
    option-label="name"
    class="w-full"
    :filter="false"
    module-name="taskType"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
