import { unref } from 'vue';

/**
 * Generic sorting function
 */
export function useSort() {
  /**
   * @param array - array to sort
   * @param _key - key to sort by
   * @param order - order to sort by
   */
  function sortArray<T>(array: T[], _key: keyof T, order: 1 | -1 = 1) {
    const key = unref(_key);

    return unref(array).sort((a, b) => {
      const valueA: string = String(a[key]).toLowerCase();
      const valueB: string = String(b[key]).toLowerCase();
      return valueA > valueB ? order : valueA < valueB ? -order : 0;
    });
  }

  return { sortArray };
}
