<script setup lang="ts">
import { watch, inject, ref, onMounted } from 'vue';
import { ProductDatamodelSelectModel } from '../ts/products.types';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { isNegative } from '@/general/utils/isNegative';
/** Props */
interface Props {
  datamodel?: ProductDatamodelSelectModel;
  isSelected: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  datamodel: undefined,
  isSelected: false,
});

const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

const calculatedFilledInFields = ref<number>(0);

const setFilledIn = () => {
  const dmAttributes = props.datamodel?.attributes.map((attribute) => attribute.id);

  calculatedFilledInFields.value =
    productAttributeFieldService.value.getFilledInAttributesForDatamodel(dmAttributes ?? []) ??
    calculatedFilledInFields.value;
};

watch(
  () =>
    productAttributeFieldService.value.isSaving ||
    productAttributeFieldService.value.hasLocalesChanged ||
    props.datamodel?.name,
  () => {
    setFilledIn();
  },
  {
    deep: true,
  },
);

onMounted(() => {
  setFilledIn();
});
</script>
<template>
  <div
    id="datamodel-select"
    class="border-1 border-200 border-round cursor-pointer flex flex-column hover:bg-primary mb-3 p-3"
    :class="isSelected ? 'bg-primary' : 'bg-white'"
  >
    <h5 :class="datamodel?.hide_options ? 'm-0' : 'm-0 mb-2'">{{ datamodel?.name }}</h5>

    <ProgressBar
      v-if="!datamodel?.hide_options"
      :progress="
        (isNegative(calculatedFilledInFields)
          ? 0
          : calculatedFilledInFields / (datamodel?.attributes_count ?? 1)) * 100
      "
      :label="
        isNegative(calculatedFilledInFields)
          ? '0' + '/' + datamodel?.attributes_count
          : calculatedFilledInFields + '/' + datamodel?.attributes_count
      "
      :tooltip="
        Math.round(
          (isNegative(calculatedFilledInFields) ? 0 : calculatedFilledInFields * 100) /
            (datamodel?.attributes_count ?? 1),
        ) + '%'
      "
      :class="isSelected ? 'is-selected' : ''"
    />
  </div>
</template>
<style lang="scss" scoped>
#datamodel-select:hover :deep(.p-progressbar) {
  background-color: #0066c7;
}
#datamodel-select:hover :deep(.p-progressbar .p-progressbar-value) {
  background-color: #ffffff;
}

.is-selected :deep(.p-progressbar) {
  background-color: #0066c7;
}
.is-selected :deep(.p-progressbar .p-progressbar-value) {
  background-color: #ffffff;
}
</style>
