import { ApplicationTaskService } from '../application.task.service';
import { HeapService } from '../heap.service';

export class InitHeapTask extends ApplicationTaskService {
  public async handle(): Promise<void> {
    const heapService = new HeapService();

    heapService.init();

    this._complete();
  }

  constructor() {
    super('InitHeapTask');
  }
}
