export default function (): any {
  const debounce = <F extends (...args: any[]) => ReturnType<F>>(
    func: F,
    wait: number,
    immediate?: boolean,
  ) => {
    let timeout = 0;

    const debounced = function (this: void) {
      const context: any = this;
      const args = Array.from(arguments);

      const later = function () {
        timeout = 0;
        if (!immediate) func.call(context, ...args);
      };

      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = window.setTimeout(later, wait);
      if (callNow) func.call(context, ...args);
    };

    debounced.cancel = function () {
      clearTimeout(timeout);
      timeout = 0;
    };

    return debounced;
  };

  return {
    debounce,
  };
}
