import { RouteRecordRaw } from 'vue-router';
import { RouteFactory } from '@/core/router/route.factory';
import { RouterService } from '@/core/router/router.service';
import DevelopersApiKeyView from '../views/developers-api-key.view.vue';
import DevelopersApiLogView from '../views/developers-api-log.view.vue';

const factory = new RouteFactory({
  platform: 'supplier',
  module: 'developers',
  tabs: [
    {
      label: 'API key',
      key: 'api-key',
      command: () => {
        RouterService.getInstance().router.push({ name: 'api-key' });
      },
    },
    {
      label: 'API log',
      key: 'api-log',
      command: () => {
        RouterService.getInstance().router.push({ name: 'api-log' });
      },
    },
  ],
});

const developerRoutes: RouteRecordRaw[] = [
  {
    name: 'developers',
    path: '/developers',
    redirect: { name: 'api-key' },
  },

  factory.createAppRoute({
    path: '/developers/api-key',
    name: 'api-key',
    component: DevelopersApiKeyView,
    title: 'Developers',
    rights: [],
  }),
  factory.createAppRoute({
    path: '/developers/api-log',
    name: 'api-log',
    component: DevelopersApiLogView,
    title: 'Developers',
    rights: [],
  }),
];

export default developerRoutes;
