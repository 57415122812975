<script setup lang="ts">
import { inject, ref } from 'vue';
import Tiptap from 'supplier/modules/editor/components/tiptap.vue';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { productAttributeFieldsServiceKey } from 'services/attribute-fields/service-keys';
import { RichTextFieldService } from 'services/attribute-fields/field-services/rich-text-field.service';
import WrapperField from './wrapper-field.vue';

/** Props */
interface Props {
  attributeField: AttributeField<string | undefined>;
  productAttributeField: ProductAttributeField<string | undefined>;
}

const props = defineProps<Props>();

const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;

const isTiptapFocused = ref(false);
/** Services */
const richTextFieldService = ref(
  new RichTextFieldService(
    props.attributeField,
    props.productAttributeField,
    productAttributeFieldsService.value,
  ),
);
</script>
<template>
  <WrapperField :attribute-field-service="richTextFieldService">
    <div
      class="border-1 editor-wrapper p-component p-input-group w-full"
      :class="
        richTextFieldService.getErrorTypeClass() != ''
          ? richTextFieldService.getErrorTypeClass()
          : isTiptapFocused
          ? 'focused-field'
          : 'border-200'
      "
    >
      <Tiptap
        v-model="richTextFieldService.productAttributeFieldValue"
        :allow-images="false"
        :allow-videos="false"
        :disabled="richTextFieldService.productAttributeField.locked"
        @update:model-value="(value: string) => richTextFieldService.updateValue(value)"
        @focus-changed="(value: boolean) => isTiptapFocused = value"
      />
    </div>
  </WrapperField>
</template>
<style scoped lang="scss">
.focused-field {
  border-color: #33a1df !important;
}
</style>
