//vue
import { useRoute, useRouter } from 'vue-router';

//types
import { ContentStory } from '@/api/v1/content-story/ts/content-story.interface';
import { ContentStoryModulesEnum } from '@/api/v1/content-story/ts/enums/content-story-modules.enums';
import { StoryModule } from '@/api/v1/content-story/ts/story-types/modules/story-modules.type';
import { ReorderModuleEnum } from '../ts/enums/reorder-module.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { MenuItem, MenuItemCommandEvent } from 'primevue/menuitem';
import cloneDeep from 'lodash/cloneDeep';
//services
import { ContentStoryRestService } from '@/api/v1/content-story/content-story.rest';
import { BaseStoryModule } from '@/api/v1/content-story/ts/classes/base-story-module.class';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { SingleImageLogoModule } from '@/api/v1/content-story/ts/classes/single-image-logo.class';
import StandardThreeImageTextClass from '@/api/v1/content-story/ts/classes/three-image-text.class';
import StoryManagerService from './story-manager.service';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { GetAttachableProductsRequest } from '@/api/v1/content-story/ts/interfaces/getAttachableProducts.interface';
import { SingleImageSpecsDetailClass } from '@/api/v1/content-story/ts/classes/single-image-specs-detail.class';
import StandardFourImageTextClass from '@/api/v1/content-story/ts/classes/four-image-text.class';
import StandardFourQuadrantImageTextClass from '@/api/v1/content-story/ts/classes/four-image-text-quadrant.class';
import StandardTextClass from '@/api/v1/content-story/ts/classes/standard-text-class';
import ProductDescriptionTextClass from '@/api/v1/content-story/ts/classes/product-description-text.class';
import StandardHeaderImageTextClass from '@/api/v1/content-story/ts/classes/header-image-text.class';
import { StandardSingleSideImageClass } from '@/api/v1/content-story/ts/classes/single-side-image.class';
import { MultipleImageTextClass } from '@/api/v1/content-story/ts/classes/multiple-image-text.class';
import StandardImageSidebarClass from '@/api/v1/content-story/ts/classes/image-sidebar.class';
import { SingleImageHighlightsClass } from '@/api/v1/content-story/ts/classes/single-image-highlights.class';
import { UpdateContentStoryRequest } from '@/api/v1/content-story/ts/update-content-story-request.interface';

export class StoryBuilderViewerService {
  //#region services
  private _storyManagerService = new StoryManagerService();
  private _api = new ContentStoryRestService();
  private _ts: TranslationService = new TranslationService('supplier', 'storyManager');
  private _confirmService = new ConfirmService();
  private _router = useRouter();
  private _route = useRoute();
  private _toastService = ToastService.getInstance();

  //#endregion

  //#region modal buttons / menu item
  private getCreateStoryModalButtons(): CrudButtonsOptions {
    return {
      saving: false,
      buttons: [
        new CancelButton({
          label: this._ts.tGlobal('cancel'),
          position: CrudButtonPosition.RIGHT,
          onClick: () => this.toggleDialog(false),
        }),
        new CreateButton({
          label: this._ts.tModule('renameButton'),
          position: CrudButtonPosition.RIGHT,
          type: 'submit',
          styleClasses: 'white-space-nowrap',
          onClick: () => this.submitForm(),
        }),
      ],
    };
  }

  private getMenuItems(): MenuItem[] {
    return [
      {
        label: this._ts.tModule('renameButton'),
        icon: 'mdi mdi-pencil-outline',
        disabled: !this.currentStory?.is_draft,
        command: () => this.toggleDialog(true),
      },
      { separator: true },
      {
        label: this._ts.tGlobal('delete'),
        icon: 'mdi mdi-delete-outline',
        class: 'delete',
        command: (menuItemEvent: MenuItemCommandEvent) =>
          this.handleDeleteCallback(menuItemEvent, this.currentStory),
      },
    ];
  }
  //#endregion

  //#region constants
  private _modules: BaseStoryModule<StoryModule>[] = [];
  private _currentStory: ContentStory | undefined;
  private _isSaved = false;
  private _oldData: StoryModule[] = [];
  private _newName = '';
  private _isRenameDialogOpen = false;
  private _listOfModules: { type: ContentStoryModulesEnum }[] | undefined = [];
  private _isDragged = false;
  private _chosenModule: ContentStoryModulesEnum | undefined;
  private _hasChanges = false;

  //#endregion

  //#region setter getters
  public set currentStory(contentStory: ContentStory | undefined) {
    if (contentStory) {
      this._currentStory = contentStory;
      this._newName = this._currentStory.story_name ?? '';
      this.setOldModuleData();
      this.generateServices();
      return;
    }

    this._currentStory = contentStory;
  }

  public get currentStory(): ContentStory | undefined {
    return this._currentStory;
  }

  public get newName() {
    return this._newName;
  }

  public set newName(newName) {
    this._newName = newName;
  }

  public get isSaved() {
    return this._isSaved;
  }

  public set isSaved(isSaved: boolean) {
    this._isSaved = isSaved;
  }

  public get modules() {
    return this._modules;
  }

  public get breadcrumbs(): string[] {
    return [
      'Story Manager',
      [this._route.meta.breadCrumbTitle, this._currentStory?.story_name ?? ''].join(' - '),
    ];
  }

  public get isRenameDialogOpen() {
    return this._isRenameDialogOpen;
  }

  public get listOfModules() {
    return this._listOfModules;
  }

  public set listOfModules(listOfModules: { type: ContentStoryModulesEnum }[] | undefined) {
    this._listOfModules = listOfModules;
  }

  public get isDragged() {
    return this._isDragged;
  }

  public get chosenModule() {
    return this._chosenModule;
  }

  public get menuItems() {
    return this.getMenuItems();
  }

  public get createStoryModalButtons() {
    return this.getCreateStoryModalButtons();
  }

  public get moduleData(): StoryModule[] {
    return this.getModuleData();
  }

  //#endregion

  //#region private functions
  private setOldModuleData() {
    this._oldData = this._currentStory?.story_data ?? [];
  }

  private generateServices() {
    if (this._currentStory?.story_data) {
      this._modules = this._currentStory?.story_data.map((mod) =>
        this.getCorrectModule(cloneDeep(mod)),
      );
    }
  }

  private getCorrectModule(module: StoryModule): BaseStoryModule<StoryModule> {
    switch (module.type) {
      case ContentStoryModulesEnum.SingleImageLogo:
        return new SingleImageLogoModule(module);
      case ContentStoryModulesEnum.ThreeImageText:
        return new StandardThreeImageTextClass(module);
      case ContentStoryModulesEnum.SingleImageSpecsDetail:
        return new SingleImageSpecsDetailClass(module);
      case ContentStoryModulesEnum.FourImageText:
        return new StandardFourImageTextClass(module);
      case ContentStoryModulesEnum.FourQuadrantText:
        return new StandardFourQuadrantImageTextClass(module);
      case ContentStoryModulesEnum.StandardText:
        return new StandardTextClass(module);
      case ContentStoryModulesEnum.ProductDescriptionText:
        return new ProductDescriptionTextClass(module);
      case ContentStoryModulesEnum.HeaderImageText:
        return new StandardHeaderImageTextClass(module);
      case ContentStoryModulesEnum.SingleSideImage:
        return new StandardSingleSideImageClass(module);
      case ContentStoryModulesEnum.MultipleImageText:
        return new MultipleImageTextClass(module);
      case ContentStoryModulesEnum.ImageSidebar:
        return new StandardImageSidebarClass(module);
      case ContentStoryModulesEnum.SingleImageHighlights:
        return new SingleImageHighlightsClass(module);
      default:
        return new SingleImageLogoModule();
    }
  }

  private getModuleData(): StoryModule[] {
    return this._modules.map((mod: BaseStoryModule<StoryModule>) => {
      return mod.data;
    });
  }

  private getIndexFromUUID(uuid: string): number | undefined {
    let storyIndex: number | undefined = undefined;
    this.modules.map((mod: BaseStoryModule<StoryModule>, index: number) => {
      if (mod.uuid === uuid) {
        storyIndex = index;
      }
    });
    return storyIndex;
  }

  public async getModuleForMarketplace() {
    const result = await this._storyManagerService.getModules().then((res) => {
      return res?.modules.map((mod) => {
        return { type: mod.type } as { type: ContentStoryModulesEnum };
      });
    });

    this.listOfModules = result;
  }

  //#endregion

  //#region public functions
  public getModule<StoryModule>(uuid: string): StoryModule | undefined {
    return this._modules.find((module) => module.uuid === uuid) as StoryModule;
  }

  public addStoryModule(storyType?: ContentStoryModulesEnum) {
    if (this.modules.length === 5) {
      this._toastService.displayWarningToast(
        this._ts.tModule('tooltips.maxModules'),
        this._ts.tModule('tooltips.maxModuleTitle'),
      );
      return;
    }

    switch (storyType) {
      case ContentStoryModulesEnum.SingleImageLogo:
        this._modules.push(new SingleImageLogoModule());
        break;
      case ContentStoryModulesEnum.ThreeImageText:
        this._modules.push(new StandardThreeImageTextClass());
        break;
      case ContentStoryModulesEnum.SingleImageSpecsDetail:
        this._modules.push(new SingleImageSpecsDetailClass());
        break;
      case ContentStoryModulesEnum.FourImageText:
        this._modules.push(new StandardFourImageTextClass());
        break;
      case ContentStoryModulesEnum.FourQuadrantText:
        this._modules.push(new StandardFourQuadrantImageTextClass());
        break;
      case ContentStoryModulesEnum.StandardText:
        this._modules.push(new StandardTextClass());
        break;
      case ContentStoryModulesEnum.ProductDescriptionText:
        this._modules.push(new ProductDescriptionTextClass());
        break;
      case ContentStoryModulesEnum.HeaderImageText:
        this._modules.push(new StandardHeaderImageTextClass());
        break;
      case ContentStoryModulesEnum.SingleSideImage:
        this._modules.push(new StandardSingleSideImageClass());
        break;
      case ContentStoryModulesEnum.MultipleImageText:
        this._modules.push(new MultipleImageTextClass());
        break;
      case ContentStoryModulesEnum.ImageSidebar:
        this._modules.push(new StandardImageSidebarClass());
        break;
      case ContentStoryModulesEnum.SingleImageHighlights:
        this._modules.push(new SingleImageHighlightsClass());
        break;
      default:
        this._toastService.displayWarningToast(this._ts?.tModule('invalidModuleType') ?? '');
        break;
    }
  }

  public hasChanges(): boolean {
    if (JSON.stringify(this._oldData) !== JSON.stringify(this.getModuleData())) {
      this._isSaved = false;
      this._hasChanges = true;
      return true;
    }

    this._hasChanges = false;
    return false;
  }

  public isAbleToReorder(uuid: string, direction: ReorderModuleEnum): boolean {
    const storyModuleIndex = this.getIndexFromUUID(uuid);
    const lengthOfModules = this._modules.length - 1;

    if (storyModuleIndex != null) {
      if (storyModuleIndex === 0 && direction == ReorderModuleEnum.UP) {
        return false;
      }

      return !(storyModuleIndex === lengthOfModules && direction == ReorderModuleEnum.DOWN);
    }

    return false;
  }

  public reorderModule(uuid: string, indexChange: number) {
    const storyModule = this._modules.filter((module) => module.uuid === uuid);
    const storyModuleIndex = this.getIndexFromUUID(uuid);
    if (storyModuleIndex != null) {
      this._modules.splice(storyModuleIndex, 1);
      this._modules.splice(storyModuleIndex + indexChange, 0, storyModule[0]);
    }
  }

  public deleteStoryModule(uuid: string) {
    this._modules = this._modules.filter((module) => module.uuid !== uuid);
  }

  public async saveStory() {
    if (this._currentStory == null) {
      return;
    }

    const updateContentStory: UpdateContentStoryRequest = {
      id: this._currentStory.id,
      is_draft: true,
      story_data: this._modules.map((mod: BaseStoryModule<StoryModule>) => {
        return mod.data;
      }),
    };

    await this._api
      .update(this._currentStory.id, updateContentStory)
      .then((_res) => {
        this._isSaved = true;
        this._toastService.displaySuccessToast(this._ts.updateSuccess() ?? '');
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.updateFailed() ?? '');
      });
  }

  public async setIsDraft(isDraft: boolean) {
    if (this._currentStory == null) {
      return;
    }

    await this._api
      .update(this._currentStory.id, { is_draft: isDraft, id: this._currentStory.id })
      .then((res) => {
        this._isSaved = true;
        this.currentStory = res as ContentStory | undefined;
        this._toastService.displaySuccessToast(this._ts.updateSuccess() ?? '');
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.updateFailed() ?? '');
      });
  }
  //#endregion

  //#region vue functions

  public submitForm() {
    if (this._currentStory == null) {
      return;
    }

    this._storyManagerService.updateStoryName(this._currentStory?.id, this._newName).then(() => {
      this.saveStory();
      this.toggleDialog(false);
    });
  }

  private handleDeleteCallback(menuItemEvent: MenuItemCommandEvent, story?: ContentStory) {
    if (story == null) {
      return;
    }

    this._confirmService.confirmDelete({
      event: menuItemEvent.originalEvent as PointerEvent,
      group: 'story-builder-dialog',
      message: this._ts.deleteConfirm(story.story_name ?? ''),
      callback: () => {
        this._storyManagerService
          .deleteStory(story.id)
          .then(() => {
            this._hasChanges = false;
            this._router.push({ name: 'story-manager' });
          })
          .catch((e) => {
            console.error(e.response.data.message);
          });
      },
    });
  }

  public toggleDialog(status: boolean) {
    this._isRenameDialogOpen = status;
  }

  public moduleIsDragged(newModule?: ContentStoryModulesEnum) {
    this._isDragged = !this._isDragged;
    this._chosenModule = newModule;
  }

  //dirty state. can be removed after the transition to the new layout
  public dirtyStateDialog = async (onContinue: () => void, onCancel: () => void) => {
    if (this._hasChanges) {
      this._confirmService.dirtyState({
        group: 'story-builder-dialog',
        titleParam: this._ts.tModule('dirtyState.action'),
        messageParams: {
          entity: this._ts.tModule('dirtyState.entity'),
          action: this._ts.tModule('dirtyState.action'),
        },
        accept: async () => {
          await this.saveStory();
          onContinue();
        },
        reject: async () => {
          onCancel();
        },
      });
      return;
    }

    onContinue();
    return;
    //#endregion
  };

  async getContentStoryProducts(storyId: number) {
    try {
      return await this._api.getContentStoryProducts(storyId);
    } catch (e) {
      this._toastService.displayErrorToast(this._ts.getFailed());
      return [];
    }
  }

  async getProducts(params: GetAttachableProductsRequest) {
    try {
      params.variant_keys = ['amazon'];
      if (this._currentStory?.id != 0 && this._currentStory?.id != null) {
        params.story_id = this._currentStory?.id;
        return await this._api.getAttachableProducts(params);
      }

      return [];
    } catch (e) {
      this._toastService.displayErrorToast(this._ts.getFailed());
      return [];
    }
  }

  async attachProductToStory(storyId: number, productsIds: number[]) {
    try {
      return await this._api.attachProductsToStory(storyId, productsIds);
    } catch (e) {
      this._toastService.displayErrorToast(this._ts.tModule('attachFailed'));
      // must return to prevent freezing the loading
      return [];
    }
  }

  async detachProductFromStory(storyId: number, productsIds: number[], callback?: () => void) {
    this._confirmService.confirmDelete({
      group: 'publish-content-dialog',
      message: this._ts.tModule('confirmDetach'),
      header: this._ts.tModule('detachProduct'),
      callback: async () => {
        try {
          await this._api.detachProductsToStory(storyId, productsIds);
          callback?.();
        } catch (e) {
          this._toastService.displayErrorToast(this._ts.tModule('detachFailed'));
        }
      },
    });
  }
}
