<script setup lang="ts">
import { JsonSchemasRestService } from 'platform-unit2-api/json-schemas';
import { DefaultSelectProps } from '../../ts/default-select-props.interface';
import BaseSelect from '../../base-select.vue';

interface Props extends DefaultSelectProps<any> {}

const props = defineProps<Props>();
const restService = JsonSchemasRestService;
</script>
<template>
  <BaseSelect
    :rest-service="restService"
    fetch-function-name="getMarketplaceIds"
    option-label="value"
    data-key="key"
    module-name="marketplaceIds"
    :filter="false"
    class="w-full"
    v-bind="{ ...$attrs, ...props }"
  >
  </BaseSelect>
</template>
