<script setup lang="ts">
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { onBeforeMount, ref, watch } from 'vue';
import { CrudButtonService } from '@/general/ui/components/crud-buttons/ts/services/crud-button.service';
import { CrudButtonPosition } from './ts/enums/crud-button-position.enum';

import BaseButton from './ts/classes/base-button.class';

/** Props */
export interface Props {
  options: CrudButtonsOptions;
  additionalClasses?: string;
}

const props = defineProps<Props>();

/** Constants */
const crudButtonService = new CrudButtonService();
const sortedButtons = ref<{ start: BaseButton[]; center: BaseButton[]; end: BaseButton[] }>({
  start: [],
  center: [],
  end: [],
});

const setButtons = () => {
  sortedButtons.value.start = crudButtonService.filterButtonPosition(
    CrudButtonPosition.LEFT,
    props.options.buttons,
  );
  sortedButtons.value.center = crudButtonService.filterButtonPosition(
    CrudButtonPosition.CENTER,
    props.options.buttons,
  );

  sortedButtons.value.end = crudButtonService.filterButtonPosition(
    CrudButtonPosition.RIGHT,
    props.options.buttons,
  );
};

const isSaving = (): boolean => {
  if (typeof props.options.saving === 'function') {
    return props.options.saving();
  }

  return props.options.saving ?? false;
};

/** LifeCycle */
onBeforeMount(() => {
  setButtons();
});

watch(
  () => props.options,
  () => setButtons(),
  {
    deep: true,
  },
);
</script>

<template>
  <div class="flex w-full">
    <div
      v-for="buttonGroup of Object.keys(sortedButtons)"
      :key="buttonGroup"
      :class="`w-4 flex justify-content-${buttonGroup} gap-2 ${additionalClasses}`"
    >
      <div
        v-for="button of (sortedButtons[buttonGroup as keyof typeof sortedButtons])"
        :key="typeof button.label === 'string' ? button.label : button.label()"
      >
        <pButton
          :icon="isSaving() ? 'mdi mdi-loading pi-spin' : button.buttonIcon"
          :icon-pos="button.iconPos"
          :class="[button.class]"
          :type="button.type"
          :disabled="!button.isEnabled?.() || isSaving()"
          :label="typeof button.label === 'string' ? button.label : button.label()"
          @click="button.onClick"
        />
      </div>
    </div>
  </div>
</template>
