import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';
import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const create: TranslationObjectAction = {
  title: 'Create a story',
  subTitle: 'Here you can create a new story.',
};

const deleteTranslation: TranslationObjectAction = {
  title: 'Delete story',
};

const update: TranslationObjectAction = {
  title: 'Edit story',
  subTitle: 'Here you can edit a story.',
};

const columnLables = {
  products: 'Products linked',
};

const storyBuilderTranslations = {
  title: 'Story builder | Story builders',
  create: {
    title: 'Create Story',
    subTitle: 'Here you can create a new story through the story builder.',
  },
  update: {
    title: 'Edit Story',
    subTitle: 'Here you can edit a story through the story builder.',
  },

  placeholder: 'Select a story',
  brandModuleDisplayName: 'Brand Image',
  threeImageTextModuleDisplayName: 'Three image text',
  singleImageSpecsDetailDisplayName: 'Image specs detail',
  fourImageTextModuleDisplayName: 'Four image text',
  fourQuadrantTextModuleDisplayName: 'Four quadrants',
  standardTextModuleDisplayName: 'Text module',
  productDescriptionTextModuleDisplayName: 'Product description',
  headerImageTextModuleDisplayName: 'Header image text',
  singleSideImageModule: 'Single side image',
  multipleImageTextModuleDisplayName: 'Multiple image text',
  imageSidebarDisplayName: 'Image sidebar',
  singleImageHighlightsModuleDisplayName: 'Single image highlights',

  wrongImageSize: "The uploaded image doesn't match the required height and width",
  wrongImageHeight: "The uploaded image doesn't match the required height",
  wrongImageWidth: "The uploaded image doesn't match the required width",

  captionHeadline: 'Enter caption text',
  bulletPoints: {
    maxAmountOfBulletPoints: 'You reached the max amount of bullet points.',
    noBulletPoints: 'No bullet points added yet.',
    pressEnterToSubmit: 'Press enter to submit the bullet point.',
    inputPlaceHolder: 'Enter a bullet point',
    empty: 'Please enter a value',
  },
  header: {
    linkProducts: 'Linked products',
    autoSaveTooltip: 'Your story will automatically be saved.',
  },
  modulePicker: {
    storyModules: 'Story modules',
  },

  tooltips: {
    maxModules: 'you reached the max amount of modules for this story.',
  },

  emptyHoverState: {
    defaultModule: 'Module',
  },

  threeImageAndText: {
    headline: 'Enter headline text',
    paragraph: 'Enter description text',
  },

  emptyState: {
    dropIt: 'Drop it here.',
    message: 'Add modules to your story by dragging them from the menu left.',
  },

  customHeadline: {
    subheadline: 'Enter subheadline text',
    mainheadline: 'Enter mainheadline text',
    imageKeyWords: 'Enter image keywords',
  },

  storyStatusBar: {
    changeAsset: 'Change asset',
    StoryBuilder: 'Story builder',
    LinkPublish: 'Link & Publish',
    saveAsDraft: 'Save as draft',
    changesSaved: 'Changes saved.',
  },

  publish: 'Publish',
};

const linkAndPublish = {
  title: 'Link and publish',
  titlePage: 'Linked products',
  linkProducts: 'Link products to your story',
  subtitle:
    'Here you can link products to your created story. You can then select which products you want to publish the story to.',
  createVariants: 'Create product variants',
  noVariants: 'There are no product variants to display',
  linkedProducts: 'Linked products',
  addProducts: 'Add products',
  selectProducts: 'Select products',
  selectProductsSubtitle: 'Select the products you want to link to ',
  addCountProducts: 'Add {count} products',
};

const translationObject: TranslationObject = {
  title: 'Story | Stories',
  storyManagerTitle: 'Story manager',
  storyName: 'Story name',
  placeholder: 'Select a story | Select stories',
  emptyStateTitle: 'Empty stories',
  emptyStateSubtitle: '',
  createdStories: 'Created story | Created stories',
  createQuestion: 'What should we call this story?',
  attachFailed: 'Attaching products to this story has failed',
  confirmDetach: 'Are you sure you want to detach this product?',
  detachFailed: 'Detaching products from this story has failed',
  detachProduct: 'Detach product',
  overwriteCreateTile: 'Create story',
  overwriteUpdateTile: 'Rename story',

  waitingForPublish: 'Waiting for publish',

  renameStory: 'Rename story',
  renameQuestion: 'What should we call this story?',
  renameButton: 'Rename',
  publishedStory: 'Published story',

  tooltips: {
    maxModuleTitle: 'Module limit reached',
    maxModules: 'You reached the maximum allowed amount of modules for this story.',
  },

  dirtyState: {
    action: 'Story Builder',
    entity: 'story',
  },

  unDraft: {
    title: 'Complete story',
    subTitle: `If you want to continue attaching products to this story, you need to mark it as complete.
       This can be done by clicking the continue button below.
       Your story cant be edited after it has been marked as complete.`,
  },

  deletion: {
    title: 'Delete module',
    message:
      'Deletion of this story has failed, This is most likely because the story has products attached.',
  },

  invalidModuleType: "The selected module isn't supported",

  storyBuilder: storyBuilderTranslations,
  create: create,
  delete: deleteTranslation,
  update: update,
  columnLables: columnLables,
  linkAndPublish: linkAndPublish,
};

const translation: Translation = {
  storyManager: translationObject,
};

export default translation;
