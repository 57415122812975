<script setup lang="ts">
//components
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import StandardHeaderImageTextClass from '@/api/v1/content-story/ts/classes/header-image-text.class';
import ContentImageAndText from '../content-components/content-image-and-text.vue';

interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);

// const / refs
const storyModule = storyService?.value.getModule<StandardHeaderImageTextClass>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid">
    <template #content>
      <div class="flex flex-column gap-3 h-full justify-content-around p-3">
        <ContentHeadline
          :headline="storyModule?.data.headline.value"
          @headline-value="storyModule?.setMainHeadline($event)"
        />
        <ContentImageAndText :custom-style="'width: 100%;'" :uuid="uuid" :block="'block1'" />
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped></style>
