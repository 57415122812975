<script setup lang="ts">
//components
import { inject } from 'vue';
import { storyBuilderServiceKey } from '../../../ts/helpers/story-manager-keys';
import BaseContentComponent from '../content-components/base-content-component.vue';
import ContentHeadline from '../content-components/content-headline.vue';
import ContentTextarea from '../content-components/content-textarea.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { SingleImageHighlightsClass } from '@/api/v1/content-story/ts/classes/single-image-highlights.class';
import { Upload } from 'platform-unit2-api/uploads';
import ContentImage from '../content-components/content-image.vue';
import ContentBulletPoints from '../content-components/content-bullet-points.vue';

interface Props {
  uuid: string;
}

const props = defineProps<Props>();

// inject / provide
const storyService = inject(storyBuilderServiceKey);
const ts = new TranslationService('supplier', 'storyManager');

// const / refs
const storyModule = storyService?.value.getModule<SingleImageHighlightsClass>(props.uuid);
</script>

<template>
  <BaseContentComponent :uuid="uuid">
    <template #content>
      <div class="flex flex gap-3 h-full justify-content-between p-3">
        <div class="custom-image-class w-3">
          <ContentImage
            :is-upload-set="storyModule?.hasUploadSet()"
            :upload-url="storyModule?.getUploadUrl()"
            :minimal-image-size="storyModule?.minimumImageSize"
            :accepted-file-types="storyModule?.acceptedFileTypes"
            @uploaded-image="(event: Upload) => storyModule?.setUploadData(event)"
          />
          <ContentHeadline
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.imageKeyWords')"
            :custom-class="'mt-3'"
            :headline="storyModule?.getAltText()"
            @headline-value="storyModule?.setAltText($event)"
          />
        </div>
        <div class="flex flex-column w-5">
          <ContentHeadline
            custom-class="mb-3"
            :headline="storyModule?.getMainHeadline()"
            @headline-value="storyModule?.setMainHeadline($event)"
          />
          <ContentHeadline
            :headline="storyModule?.getStandardTextBlockHeadline('textBlock1')"
            @headline-value="storyModule?.setStandardTextBlockHeadline('textBlock1', $event)"
          />
          <ContentTextarea
            :textbox="storyModule?.getStandardTextBlockText('textBlock1')"
            @html-text-area="storyModule?.setStandardTextBlockText('textBlock1', $event)"
          />
          <ContentHeadline
            :headline="storyModule?.getStandardTextBlockHeadline('textBlock2')"
            @headline-value="storyModule?.setStandardTextBlockHeadline('textBlock2', $event)"
          />
          <ContentTextarea
            :textbox="storyModule?.getStandardTextBlockText('textBlock2')"
            @html-text-area="storyModule?.setStandardTextBlockText('textBlock2', $event)"
          />
          <ContentHeadline
            :headline="storyModule?.getStandardTextBlockHeadline('textBlock3')"
            @headline-value="storyModule?.setStandardTextBlockHeadline('textBlock3', $event)"
          />
          <ContentTextarea
            :textbox="storyModule?.getStandardTextBlockText('textBlock3')"
            @html-text-area="storyModule?.setStandardTextBlockText('textBlock3', $event)"
          />
        </div>
        <div class="w-4">
          <ContentHeadline
            custom-class="mb-3"
            :custom-place-holder="ts.tModule('storyBuilder.customHeadline.subheadline')"
            :headline="storyModule?.getBulletPointHeadline()"
            @headline-value="storyModule?.setBulletPointHeadline($event)"
          />
          <ContentBulletPoints :uuid="uuid" />
        </div>
      </div>
    </template>
  </BaseContentComponent>
</template>

<style lang="scss" scoped>
.custom-image-class {
  height: 33%;
  aspect-ratio: 1/1;
}
</style>
