<script setup lang="ts">
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

/** Constants */
const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <section>
    <div class="flex pb-0">
      <pMultiselect
        v-model="service.selectedODMs"
        :options="service.odmDatamodels"
        option-label="name"
        placeholder="Select datamodels"
        :filter="true"
        display="chip"
        class="align-items-center mr-2 w-8"
      >
        <template #value="slotProps">
          <div v-for="option of slotProps.value" :key="option.id" class="inline">
            {{ option.name }},
          </div>
          <template v-if="!slotProps.value || slotProps.value.length === 0">
            Select datamodels
          </template>
        </template>
        <template #option="slotProps">
          <div class="flex justify-content-between w-full">
            <span>{{ slotProps.option.name }}</span>
            <span>{{ slotProps.option.attributes.length }}</span>
          </div>
        </template>
      </pMultiselect>

      <pIconField icon-position="left" class="w-4">
        <pInputIcon class="pi pi-search" />
        <pInputText
          v-model="service.odmSearchQuery"
          type="text"
          class="w-full"
          :placeholder="service.ts.searchTitle"
        />
      </pIconField>
    </div>

    <!-- divider -->
    <p-divider />

    <div class="align-items-center flex justify-content-between mb-3">
      <span class="font-bold"
        >{{ service.ts.tModule('datamodel_attributes.name') }}
        <span class="bg-gray-800 border-round-sm px-1 text-sm text-white">
          {{ service.attributesCount }}
        </span>
      </span>

      <p-button
        v-if="service.attributesCount !== 0"
        text
        primary
        :label="service.ts.tModule('addAll')"
        @click="service.addAllODMsAttributes()"
      />
    </div>
  </section>
</template>
