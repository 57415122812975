<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import LocaleSelect from '@/general/ui/components/selects/locale-select.vue';
import ImportMappingSelect from '@/general/ui/components/selects/import-mapping-select.vue';
import { useService } from '@/general/composables/useService';
import { ScheduledImportViewService } from '@/general/services/overview-service/modules/scheduled-import-view.service';
import { scheduledImportServiceKey } from '../../ts/keys/scheduled-import-service.key';

const ts = new TranslationService('retailer', 'imports');
const service = useService(scheduledImportServiceKey, ScheduledImportViewService);
</script>
<template>
  <section class="m-4">
    <div class="field mb-3">
      <label for="name">{{ ts.tGlobal('name') }}</label>
      <pInputText id="name" v-model="service.partialObject.name" class="w-full" type="text" />
    </div>
    <div class="field mb-3">
      <ClientModuleSelect v-model="service.partialObject.module" type="import" />
    </div>
    <div class="field mb-3">
      <ImportMappingSelect v-model="service.partialObject.mapping" />
    </div>
    <div class="field mb-3">
      <label>{{ ts.tModule('language') }}</label>
      <LocaleSelect v-model="service.partialObject.locale" hide-label />
    </div>
  </section>
</template>
