<script setup lang="ts">
//vue
import { inject } from 'vue';

//components
import ModulePicker from './module-picker.vue';
import StoryBuilderViewer from './story-builder-viewer.vue';

//types
import { storyBuilderServiceKey } from '../../ts/helpers/story-manager-keys';

interface Props {
  currentStoryLoading: boolean;
}

defineProps<Props>();

//inject / provide
const storyBuilderService = inject(storyBuilderServiceKey);
</script>
<template>
  <div v-if="!currentStoryLoading" class="flex gap-4 h-full overflow-auto pt-4 px-4">
    <ModulePicker
      class="story-module-select"
      @toggle-is-dragged="(e) => storyBuilderService?.moduleIsDragged(e)"
    />
    <StoryBuilderViewer
      :is-dragged="storyBuilderService?.isDragged ?? false"
      :chosen-module="storyBuilderService?.chosenModule"
    />
  </div>
  <div v-else class="flex gap-4 h-full loading-container overflow-auto pt-4 px-4">
    <p-skeleton width="18vw" class="h-full story-module-select" />
    <p-skeleton class="h-full story-module-select w-full" />
  </div>
</template>
<style lang="scss" scoped>
.story-module-select {
  width: 250px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.loading-container {
  display: grid;
  gap: 2px;
}
</style>
