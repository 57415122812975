import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Mapping | Mappings',
  result: 'result',
  duplicate_mappings: {
    title: 'Duplicate mappings',
    modal_header: 'Duplicate {type} mappings',
    modal_description:
      'Choose the destination for the {type} mapping duplicate by selecting the appropriate workspace, data model and channel.',
    modal_client_placeholder: 'Select workspace',
    duplication_fail: 'Duplicating mappings failed. Please try again.',
    duplication_success: 'Mapping has been successfully duplicated',
    duplication_overwrite_success: 'Mapping has been successfully overwritten',
    duplication_warning:
      'Some attributes do not match in the destination of the duplicated mapping',
    duplication_error_same_destination:
      'The destination of the duplicate mapping is the same as the original',
    overwrite_title: 'Overwrite {type} mapping',
    overwrite_description:
      'An {type} mapping already exists for the selected destination. By choosing ‘overwrite’ this {type} mapping will be replaced with the duplicate. This can not be undone.',
    overwrite: 'Overwrite',
  },
};

const tranlation: Translation = {
  mappings: translationObject,
};

export default tranlation;
