<script lang="ts" setup>
import { ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Product } from 'platform-unit2-api/products';
import ProductTable from 'supplier/modules/products/components/table/products-table.vue';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { assetViewServiceKey } from '../keys/asset-view-service.key';
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';

// Services
const ts = new TranslationService('supplier', 'media');
const service = useService(assetViewServiceKey, AssetViewService);

const prevButton = new CancelButton({
  label: ts.tGlobal('previous'),
  position: CrudButtonPosition.RIGHT,
  buttonIcon: 'mdi mdi-chevron-left',
  styleClasses: 'p-button-outlined',
  isEnabled: () => !service?.attachAssetsModal.loading,
  onClick: () => service?.attachAssetsModal.toggleVisible(),
});

const attachButton = new CreateButton({
  label: () => {
    return ts.tModule(`attach_media_dialog.attach_to`, {
      params: {
        imageCount: service?.checkedAssets.length,
        productCount: service?.attachAssetsModal.checkedProducts.length,
      },
    });
  },
  position: CrudButtonPosition.CENTER,
  styleClasses: 'p-button-rounded',
  buttonIcon: 'mdi mdi-plus-box-multiple',
  isEnabled: () => (service?.checkedAssets.length ?? 0) > 0,
  onClick: () => service?.completeAttachAssets?.(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [prevButton, attachButton],
});

const checkProducts = (products: Product[]) => {
  service?.attachAssetsModal?.checkMultipleProducts(products);
};
</script>

<template>
  <BaseDialog
    v-if="service != null"
    :visible="service.attachAssetsModal.visible"
    :title="ts.tModule(`attach_media_dialog.title`)"
    :subtitle="ts.tModule(`attach_media_dialog.subtitle`)"
    :buttons-options="buttonsOptions"
    size="large"
    @update:visible="service.attachAssetsModal.toggleVisible()"
  >
    <ProductTable
      :selected-products="service.attachAssetsModal.checkedProducts"
      module="media"
      :attaching-media="service.attachAssetsModal.loading"
      @update:checked-rows="checkProducts"
    />
  </BaseDialog>
</template>
