<script setup lang="ts">
//Types
import { Client } from 'platform-unit2-api/clients';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { Module } from 'platform-unit2-api/modules';

//Services
import { TranslationService } from '@/general/services/translations/translation.service';
import { MappingService } from 'supplier/modules/mappings/services/mappings.service';

//Components
import { ref } from 'vue';
import { MappingType } from 'supplier/modules/mappings/ts/enums/mapping-type.enum';

interface Props {
  visible: boolean;
  type: MappingType;
  clientToDuplicate?: Client;
  datamodelToDuplicate?: Datamodel;
  moduleToDuplicate?: Module;
  sourceDatamodel: Datamodel;
  sourceModule: Module;
}

/** Props */
const props = withDefaults(defineProps<Props>(), {
  visible: false,
  type: MappingType.EXPORT,
  clientToDuplicate: undefined,
  datamodelToDuplicate: undefined,
  moduleToDuplicate: undefined,
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide', closeAllDialogs?: { closeAllDialogs: boolean }): void;
}>();

const ts = new TranslationService('supplier', 'mappings');
const mappingService = ref(new MappingService(emit));

const duplicateMapping = () => {
  if (
    props.clientToDuplicate != null &&
    props.moduleToDuplicate != null &&
    props.datamodelToDuplicate != null
  ) {
    mappingService.value.duplicateMapping({
      type: props.type,
      payload: {
        clientId: props.clientToDuplicate.id,
        moduleId: props.moduleToDuplicate.id,
        datamodelId: props.datamodelToDuplicate.id,
        sourceDatamodelId: props.sourceDatamodel.id,
        sourceModuleId: props.sourceModule.id,
      },
    });
  }
};
</script>
<template>
  <p-dialog :visible="visible" :closable="false" modal :style="{ width: '40vw' }">
    <template #header>
      <div>
        <h2 class="mb-2 text-800">
          {{
            ts.tModule('duplicate_mappings.overwrite_title', {
              params: { type },
            })
          }}
        </h2>
      </div>
    </template>

    <p class="text-600">
      {{
        ts.tModule('duplicate_mappings.overwrite_description', {
          params: { type },
        })
      }}
    </p>

    <template #footer>
      <div>
        <p-button plain text :label="ts.tGlobal('cancel')" class="mr-3" @click="emit('hide')" />
        <p-button
          :label="ts.tModule('duplicate_mappings.overwrite')"
          class="p-button-danger"
          @click="() => duplicateMapping()"
        />
      </div>
    </template>
  </p-dialog>
</template>

<style scoped lang="scss">
.p-button :deep(.p-button-label) {
  font-weight: normal;
}
</style>
