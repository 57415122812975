<script setup lang="ts">
import CrudSidebar from '../crud-sidebar.vue';
import TableColumnSelectInterface from './table-column-select.interface';
import TableSkeleton from '../skeletons/table-skeleton.vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { DataTableRowReorderEvent } from 'primevue/datatable';
import { onMounted } from 'vue';

interface Props {
  service: TableColumnSelectInterface;
}
const props = defineProps<Props>();

const ts = new TranslationService('general', 'components');

const onRowReorder = (event: DataTableRowReorderEvent) => {
  const { dragIndex, dropIndex } = event;
  const { tableAllColumns } = props.service;

  // Remove the dragged item from its original position
  const draggedItem = tableAllColumns.splice(dragIndex, 1)[0];

  // Insert the dragged item at the dropIndex
  tableAllColumns.splice(dropIndex, 0, draggedItem);
};

onMounted(() => props.service.saveColumns());
</script>

<template>
  <pDrawer
    :visible="service.isColumnSelectOpen"
    class="p-drawer-md"
    position="right"
    @update:visible="
      service.closeColumnSelect(),
        service.resetColumnFiltersOfDeselectedColumns(),
        service.saveColumns()
    "
  >
    <template #header>
      <div>
        <h3 class="font-bold mb-1 text-2xl">{{ ts.tModule('tableColumns.title') }}</h3>
        <p class="text-lg">{{ ts.tModule('tableColumns.subtitle') }}</p>
      </div>
    </template>
    <CrudSidebar
      v-if="service"
      :loading="service.isColumnSelectLoading"
      :has-delete-button="false"
      :buttons="false"
    >
      <template #sidebar-data>
        <TableSkeleton v-if="service.isColumnSelectLoading" />

        <pDataTable
          scrollable
          scroll-height="flex"
          :value="service.tableAllColumns"
          lazy
          :row-hover="true"
          @row-reorder="onRowReorder($event)"
        >
          <p-column row-reorder header-style="width: 3rem" />

          <p-column field="type" :header="ts.tGlobal('name')">
            <template #body="slotProps">
              <span>{{ slotProps.data.header }}</span>
            </template>
          </p-column>

          <p-column field="selected" :header="ts.tGlobal('visible')">
            <template #body="slotProps">
              <pCheckbox
                v-model="slotProps.data.selected"
                :binary="true"
                :value="slotProps.data.selected"
              />
            </template>
          </p-column>
        </pDataTable>
      </template>
    </CrudSidebar>
  </pDrawer>
</template>
