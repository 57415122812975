<script setup lang="ts">
import Upload from '@/general/ui/components/upload.vue';
import usePagination from 'composables/usePagination/pagination';
import { onMounted, ref, computed, provide, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { DataTablePageEvent } from 'primevue/datatable';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import {
  IncompleteProductsRestService,
  IncompleteProductsAuditorEnum,
} from 'platform-unit2-api/incomplete-products';
import { Product } from 'platform-unit2-api/products';
import { isNegative } from '@/general/utils/isNegative';
import { formatDate } from '@/general/utils/format-date';
import ProductAttributesView from '../../products/views/product-attributes.view.vue';
import { ProductAttributeFieldsService } from '@/general/services/attribute-fields/product-attribute-fields.service';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { useStore } from 'vuex';
import EmptyState from '@/general/ui/components/empty-state.vue';
import pButton from 'primevue/button';
import TableSkeleton from '@/general/ui/components/skeletons/table-skeleton.vue';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'incompleteProducts');
const incompleteProductsApi = new IncompleteProductsRestService();
const store = useStore();
// Constants
const route = useRoute();
const router = useRouter();
const { page, perPage, onPageChange: $onPageChange } = usePagination();
const loading = ref(true);

const productAttributeFieldsService = ref(new ProductAttributeFieldsService());
provide(
  productAttributeFieldsServiceKey,
  productAttributeFieldsService as Ref<ProductAttributeFieldsService>,
);

const products = ref<Product[]>([]);
const total = computed(() => {
  return products.value.length;
});

const loadAsyncData = async (showLoadingIcon = true): Promise<void> => {
  if (isNegative(route.params.id)) {
    toastService.displayErrorToast(ts.getFailed());
    return;
  }

  loading.value = showLoadingIcon;
  try {
    let auditor;
    if (route.name === 'retailer-products-incomplete') {
      auditor = IncompleteProductsAuditorEnum.RETAILERS;
    } else if (route.name === 'brand-products-incomplete') {
      auditor = IncompleteProductsAuditorEnum.BRANDS;
    } else if (route.name === 'module-products-incomplete') {
      auditor = IncompleteProductsAuditorEnum.MODULES;
    } else {
      throw Error;
    }

    const response = await incompleteProductsApi.getIncompleteProductsForAuditor(
      auditor,
      Number(route.params.id),
      page.value,
      perPage.value,
    );

    products.value = response.data;
  } catch (err) {
    toastService.displayErrorToast(ts.getFailed());
  } finally {
    loading.value = false;
  }
};

const getVariantName = (value?: string) => {
  if (value == null) {
    return 'Master Data';
  }

  return value;
};

const onPageChange = (event: DataTablePageEvent) => {
  $onPageChange(event.page + 1, loadAsyncData);
};

onMounted(() => {
  loadAsyncData();
});

function saveConfirm() {
  productAttributeFieldsService.value
    .saveAttributeFields({ attributes: [], variants: [] })
    .then(async () => {
      store.dispatch('products/SET_LOADING_COMPLETENESS', true);
      loadAsyncData(true);
      toastService.displaySuccessToast(
        ts.updateSuccess(productAttributeFieldsService.value.currentProduct?.display_name),
      );
    })
    .catch(() => {
      toastService.displayErrorToast(
        ts.updateFailed(productAttributeFieldsService.value.currentProduct?.display_name),
      );
    });
}
</script>

<template>
  <section class="flex flex-column h-full pt-4 px-4">
    <pSplitter class="h-full">
      <pSplitterPanel :size="50" :min-size="25">
        <TableSkeleton v-if="loading" />
        <EmptyState
          v-if="!total && !loading"
          style="max-width: 80vw"
          class="pt-5"
          :translation-service="ts"
          :empty-state-title="ts.tModule('emptyStateTitle')"
          :icon-name="'products'"
          :button-visible="false"
        />
        <pDataTable
          v-model:selection="productAttributeFieldsService.currentProduct"
          selection-mode="single"
          scrollable
          scroll-height="flex"
          data-key="id"
          :value="products"
          :paginator="total > perPage"
          :rows="perPage"
          :total-records="total"
          :first="(page - 1) * perPage"
          @page="(event: DataTablePageEvent) => onPageChange(event)"
        >
          <p-column header-style="width: 6rem" field="thumbnail" :header="ts.tGlobal('thumbnail')">
            <template #body="slotProps">
              <upload :key="slotProps.data.id" :upload="slotProps.data.thumbnail" />
            </template>
          </p-column>
          <p-column field="display_name" :header="ts.tModule('product_name')">
            <template #body="slotProps">
              {{
                slotProps.data.display_name +
                ' (' +
                getVariantName(slotProps.data.variant_name) +
                ')'
              }}
            </template>
          </p-column>
          <p-column field="category.name" :header="ts.tGlobal('category')" />
          <p-column field="completeness" :header="ts.tGlobal('completeness_score')">
            <template #body="slotProps">
              <ProgressBar
                :progress="Math.round(slotProps.data.completeness) ?? 0"
                width="w-7rem"
              />
            </template>
          </p-column>
          <p-column field="updated_at" :header="ts.tGlobal('lastUpdate')">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.updated_at) }}
            </template>
          </p-column>
        </pDataTable>
      </pSplitterPanel>
      <pSplitterPanel
        v-if="productAttributeFieldsService.currentProduct != null"
        :size="50"
        class="overflow-y-scroll"
      >
        <div class="col-12 pup-mt-5 pup-mx-4">
          <strong>{{ productAttributeFieldsService.currentProduct?.display_name }}</strong>
        </div>
        <ProductAttributesView class="pup-m-4" />
        <div
          v-if="productAttributeFieldsService.currentProduct != null"
          class="bg-white bottom-0 flex gap-3 justify-content-end pup-p-4 sticky"
        >
          <pButton
            v-tooltip.top="{ value: ts.tModule('goToProduct') }"
            text
            plain
            icon="mdi mdi-open-in-new"
            @click="
              router.push({
                name: 'product-attributes',
                params: { id: productAttributeFieldsService.currentProduct?.id },
              })
            "
          />
          <pButton
            :disabled="
              !productAttributeFieldsService.currentProduct ||
              !productAttributeFieldsService.hasAttributeFieldChanges ||
              productAttributeFieldsService.isSaveButtonDisabled() ||
              productAttributeFieldsService.loading
            "
            :label="ts.tGlobal('save')"
            :loading="productAttributeFieldsService.isSaving"
            severity="success"
            @click="saveConfirm()"
          />
        </div>
      </pSplitterPanel>
    </pSplitter>
  </section>
</template>
