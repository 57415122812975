import { TranslationObject } from '@/core/i18n/ts/interfaces/translation-object.interface';
import { Translation } from '@/core/i18n/ts/interfaces/translation.interface';

const translationObject: TranslationObject = {
  title: 'Activity | Activities',
  placeholder: 'Select an activity | Select activities',
  userUploaded: 'uploaded {count} image | has uploaded {count} images',
  userCreated: 'created a product',
  userDeletedStory: 'deleted a story',
  userRemovedImages: 'removed {count} image | removed {count} images',
  userChangedMedia: 'changed media',
  userUpdatedAttributes: 'updated {count} attribute | updated {count} attributes',
  userChangedProductStatus: 'changed product status',
  userAddedNewStory: 'added new story',
  mediaChanges: 'Media changes',
};

const translation: Translation = {
  activities: translationObject,
};

export default translation;
