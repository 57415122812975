import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';
import { MaxLengthAttributeFieldService } from 'services/attribute-fields/max-length-attribute-field.service';
import { FieldFunctions } from '../interfaces/field-functions.interface';

export class TextAreaFieldService extends MaxLengthAttributeFieldService {
  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
  }
}
