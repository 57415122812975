<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import StoryModule from './story-module.vue';
import { inject, onMounted, ref, watch } from 'vue';
import Sortable from 'sortablejs';
import { ContentStoryModulesEnum } from '@/api/v1/content-story/ts/enums/content-story-modules.enums';
import { storyBuilderServiceKey } from '../../ts/helpers/story-manager-keys';

const emit = defineEmits<{
  (e: 'toggle-is-dragged', chosenModule?: ContentStoryModulesEnum): void;
}>();

const ts = new TranslationService('supplier', 'storyManager');
const listRef = ref<HTMLElement>();
const storyService = inject(storyBuilderServiceKey);

function createSortable() {
  if (listRef.value == null) {
    return;
  }

  Sortable.create(listRef.value, {
    group: {
      name: 'modulePickerGroup',
      pull: 'clone',
      put: false,
    },
    animation: 150,
    sort: false,

    onStart: (e) => {
      e.item.children[0].classList.add('grabbing-story-module'); // see story-module.vue for this class
      emit('toggle-is-dragged', e.item.dataset['moduleType'] as ContentStoryModulesEnum);
    },
    onEnd: (e) => {
      e.item.children[0].classList.remove('grabbing-story-module');
      emit('toggle-is-dragged', undefined);
    },
  });
}

onMounted(async () => {
  await storyService?.value.getModuleForMarketplace();
});

watch(() => listRef, createSortable, { deep: true });
</script>

<template>
  <div class="bg-white container-module-picker h-full" data-testid="story-module-picker">
    <div class="align-items-center flex h-5rem p-3 w-full">
      <h3 class="mr-3">{{ ts.tModule('storyBuilder.modulePicker.storyModules') }}</h3>
      <div>
        <i
          v-if="storyService?.modules.length === 5"
          v-tooltip.right="{
            value: ts.tModule('storyBuilder.tooltips.maxModules'),
          }"
          class="pi pi-exclamation-triangle text-yellow-500"
        ></i>
      </div>
    </div>
    <ul
      v-if="(storyService?.listOfModules ?? []).length > 0"
      ref="listRef"
      class="module-picker p-3"
      data-testid="module-picker-grid"
    >
      <li
        v-for="(module, index) in storyService?.listOfModules ?? []"
        :key="index"
        class="mb-2"
        :data-module-type="module.type"
      >
        <StoryModule data-testid="story-module" :file-name="module.type" />
      </li>
    </ul>
    <ul v-else class="p-3 skeleton-picker" data-testid="module-picker-grid">
      <li v-for="(_, index) in 6" :key="index">
        <pSkeleton class="story-module-skeletons" />
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
$border-radius: 6px;
$border-color: #dce5ef;

.container-module-picker {
  border: 1px solid $border-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.module-picker {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 8rem;
  list-style: none;
}

.skeleton-picker {
  display: grid;
  row-gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.story-module-skeletons {
  object-fit: contain;
  min-height: 90px;
}
</style>
