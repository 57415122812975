import {
  JsonSchemasRestService,
  JsonSchema,
  GetJsonSchemaRequest,
  AmazonModule,
  AmazonSchema,
} from 'platform-unit2-api/json-schemas';
import { BaseViewService } from '../view/base-view.service';
import { TranslationService } from '../../translations/translation.service';
import { AmazonLocales } from '@/platforms/supplier/modules/json-schemas/ts/amazon-locales.enum';

export class JsonSchemasService extends BaseViewService<
  JsonSchemasRestService,
  JsonSchema,
  GetJsonSchemaRequest,
  {} & { id: number }
> {
  // Custom locales from Amazon
  public get localesOptions() {
    const amazonLocales = Object.entries(AmazonLocales).map(([key, value]) => ({
      key,
      value,
    }));
    return amazonLocales;
  }

  /**
   * Get json schemas.
   */
  public get jsonSchemas(): JsonSchema[] {
    return this._data;
  }

  constructor(ts: TranslationService) {
    super({
      Api: JsonSchemasRestService,
      fetchAllFunction: 'getAll',
      ts: ts,
      overviewRouteName: 'json-schemas',
      createRouteName: 'new-schema',
      updateRouteName: 'edit-schema',
      confirmPopUpGroup: 'json-schemas',
    });
  }

  public module?: AmazonModule = undefined;

  public createBody(): GetJsonSchemaRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const createBody: GetJsonSchemaRequest = {
      marketplaceId: this.current.marketplace_id?.key as string,
      locale: this.current.locale as string,
      productType: this.current.product_type as string,
      moduleId: this.module?.id as number,
    };

    return createBody;
  }

  // Retrieve JSON schema (Does not create a new JSON schema, but stores an exisitng one)
  protected _create(createBody?: GetJsonSchemaRequest | undefined): void {
    const payload = createBody ?? this.createBody();

    if (payload != null && this.validated) {
      this._isLoadingCrudComponent = true;

      this._restService
        .fetchJsonSchema(payload)
        .then(() => {
          this._toastService.displaySuccessToast(
            this._ts.tModule('retrieveJsonSchema.retrieveSuccess'),
          );
          this._reloadPage();
        })
        .catch((e) => {
          this._formValidationService.handleError(e, () => {
            this._toastService.displayErrorToast(
              this._ts.tModule('retrieveJsonSchema.retrieveError'),
            );
          });
        })
        .finally(() => {
          this._isLoadingCrudComponent = false;
        });
    }
  }

  public updateBody(): ({} & { id: number }) | undefined {
    throw new Error('Method not implemented.');
  }
  public get validated(): boolean {
    if (
      this.module == null ||
      this.current?.marketplace_id == null ||
      this.current?.locale == null ||
      this.current?.product_type == null
    ) {
      return false;
    }

    return true;
  }

  public closeCrudComponent(): void {
    super.closeCrudComponent();
    this.module = undefined;
  }

  public async getJsonSchemaRequest(jsonSchema: JsonSchema): Promise<AmazonSchema> {
    const payload: GetJsonSchemaRequest = {
      marketplaceId: jsonSchema.marketplace_id.key,
      locale: jsonSchema.locale,
      productType: jsonSchema.product_type,
      moduleId: jsonSchema.module_id,
    };

    return await this._restService.fetchJsonSchema(payload);
  }

  public async downloadJsonSchema(jsonSchema: JsonSchema) {
    this.getJsonSchemaRequest(jsonSchema)
      .then((schema) => {
        const dataStr =
          'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(schema));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', schema.$comment + '.json');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
        this._toastService.displaySuccessToast(
          this._ts.tModule('downloadJsonSchema.downloadSuccess'),
        );
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('downloadJsonSchema.downloadError'));
      });
  }

  public async viewInBrowser(jsonSchema: JsonSchema) {
    this.getJsonSchemaRequest(jsonSchema)
      .then((schema) => {
        const win = window.open();
        win!.document.body.innerHTML = '<pre>' + JSON.stringify(schema, null, 2) + '</pre>';
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.tModule('openJsonSchema.openError'));
      });
  }

  public confirmDeleteJsonSchema(jsonSchema: JsonSchema) {
    this._confirmService.confirmDelete({
      callback: () => this.deleteJsonSchema(jsonSchema.id),
      group: this.confirmPopUpGroup,
      message: this._ts.deleteConfirm(jsonSchema.product_type),
    });
  }

  private deleteJsonSchema(id: number): void {
    this._restService
      .delete(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this.refetch();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.deleteFailed());
      });
  }
}
