<script setup lang="ts">
import { DataTableFilterEvent } from 'primevue/datatable';
import usePagination from 'composables/usePagination/pagination';
import { inject, onMounted, ref } from 'vue';
import { DataTablePageEvent } from 'primevue/datatable';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { tsKey } from '@/general/services/translations/translation.key';
import { exportServiceKey } from '../ts/keys/exports.keys';
import { formatDate } from '@/general/utils/format-date';

interface Props {
  pipelineId: number;
}
/** Initialize services. */
const props = defineProps<Props>();
const ts = inject(tsKey)!;
const exportService = inject(exportServiceKey)!;
const { page, perPage, onPageChange: onPageChange } = usePagination();

/** Constants. */
const rawCode = ref();
const expandedRows = ref<number[]>([]);

/**
 * Handles the pagination change
 * @param event
 */
const handlePageChange = (event: any) => {
  onPageChange(event.page + 1, () =>
    exportService.value.getPipelineMessages(props.pipelineId, event.page + 1),
  );
};

/**
 * Copies raw code from export log details, to clipboard
 */
const copyToClipboard = () => {
  exportService.value.copyToClipboard(rawCode.value.innerText);
};

onMounted(() => {
  exportService.value.getPipelineMessages(props.pipelineId, page.value);
  exportService.value.loadGtinFilter(props.pipelineId);
});
</script>

<template>
  <pDataTable
    v-model:expanded-rows="expandedRows"
    v-model:filters="exportService.messagesFilters"
    class="overflow-hidden p-0"
    filter-display="menu"
    data-key="id"
    :value="exportService.pipelineMessages"
    row-hover
    :loading="exportService.messagesLoading"
    lazy
    :paginator="exportService.totalLogs > perPage"
    :rows="perPage"
    :total-records="exportService.totalLogs"
    :first="(page - 1) * perPage"
    @filter="(event: DataTableFilterEvent) => exportService.applyMessagesFilter(event, props.pipelineId)"
    @page="(event: DataTablePageEvent) => handlePageChange(event)"
  >
    <pColumn :expander="true" />
    <!-- #region column names-->
    <pColumn
      :header="ts.tModule('details_modal.gtin')"
      field="gtin"
      :show-filter-match-modes="false"
    >
      <template #body="slotProps">
        <span v-if="slotProps?.data?.product?.gtin">
          {{ slotProps.data.product.gtin }}
        </span>
      </template>
      <template #filter="{ filterModel }">
        <pSelect
          v-model="filterModel.value"
          :options="exportService.filterAllGtins"
          placeholder="Search filters"
          display="chip"
          class="w-full"
        />
      </template>
    </pColumn>
    <pColumn :header="ts.tModule('details_modal.message')" field="retailer">
      <template #body="slotProps">
        <span v-if="slotProps?.data?.message_type">
          {{ exportService.processMessageType(slotProps.data.message_type) }}
        </span>
      </template>
    </pColumn>
    <pColumn field="user" :header="ts.tModule('details_modal.status')">
      <template #body>
        <span>Failed</span>
      </template>
    </pColumn>
    <pColumn field="status" :header="ts.tModule('details_modal.timestamp')">
      <template #body="slotProps">
        <span v-if="slotProps?.data?.created_at">
          {{ formatDate(slotProps.data.created_at) }}
        </span>
      </template>
    </pColumn>
    <!-- #endregion-->

    <!-- empty table message-->
    <template #empty>
      <EmptyState
        :translation-service="ts"
        :button-visible="false"
        :button-label="ts.tGlobal('productOverview')"
        :icon-name="'exports'"
        :empty-state-title="ts.tModule('details_modal.empty_table_title')"
        :empty-state-subtitle="ts.tModule('details_modal.empty_table_subtitle')"
        @clicked="exportService.goToProducts()"
      />
    </template>
    <!-- region dropdown expansion per export-->
    <template #expansion="slotProps">
      <div class="log-details">
        <div class="product">
          <div v-if="slotProps?.data?.submission_id" class="text">
            {{ ts.tModule('details_modal.submission_id') }}:
            {{ slotProps.data.submission_id }}
          </div>
          <div v-if="slotProps?.data?.product?.id" class="link">
            <RouterLink
              :to="{
                name: 'product-attributes',
                params: {
                  id: slotProps.data.product.id,
                },
              }"
            >
              <p-button
                :label="ts.tModule('details_modal.goto')"
                icon="pi pi-external-link"
                class="p-button-outlined"
              />
            </RouterLink>
          </div>
        </div>
        <div class="message">
          <div class="title">{{ ts.tModule('details_modal.message') }}:</div>
          <div v-if="slotProps?.data?.message" class="text">{{ slotProps.data.message }}</div>
        </div>
        <div class="code">
          <div class="title">{{ ts.tModule('details_modal.raw_code') }}:</div>
          <div v-if="slotProps?.data?.raw_payload" class="code-raw">
            <pre ref="rawCode">
              {{ JSON.stringify(JSON.parse(slotProps.data.raw_payload), undefined, 2) }}
                <pButton
                  icon="pi pi-copy"
                  class="p-button-copy"
                  @click="copyToClipboard()"
                />
            </pre>
          </div>
        </div>
      </div>
      <!-- endregion-->
    </template>
  </pDataTable>
</template>

<style lang="scss" scoped>
.log-details {
  padding: 25px;

  .product {
    display: flex;
    justify-content: space-between;

    button {
      border: 1px solid #cad9e7;
      border-radius: 3px;
      color: #335270;

      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 16px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 8px;
  }

  pre {
    background-color: #172533;
    border: 1px solid #335270;
    border-radius: 3px;
    color: #fff;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

:deep .pColumn-filter-menu {
  margin-left: 6px;
}

:deep .p-datatable-wrapper {
  height: unset;
  padding-bottom: unset;
}

:deep .p-paginator-bottom {
  bottom: -28px !important;
}

pre {
  position: relative;
}

.p-button-copy {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 0;
  right: 0;
  background: #223950;
  border: 1px solid #335270;
}
</style>
