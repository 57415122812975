import { AttributeFieldPath } from '../attribute-field-path';
import { TranslationService } from '../translations/translation.service';
import { FieldFunctions } from './interfaces/field-functions.interface';
import { UpdateProductField } from 'platform-unit2-api/products';
import { ConfirmService } from '../confirm/confirm.service';
import { Locale } from 'platform-unit2-api/locales';
import { ToastService } from '../toasts/toast.service';
import { AdvancedFieldPanel } from './interfaces/advanced-field-tab.interface';
import {
  AttributeField,
  AttributeFieldsRestService,
  ProductAttributeField,
} from 'platform-unit2-api/attribute-fields';

export class AdvancedFieldService {
  protected _attributeField: AttributeField<null | string[]>;
  private _attributeFieldsRestService: AttributeFieldsRestService;
  protected _fieldFunctions: FieldFunctions;
  protected toastService;
  protected ts;

  public isLoadingPreset = false;

  protected _confirmService = new ConfirmService();

  public get attributeField() {
    return this._attributeField;
  }

  public panels = new Map<number, AdvancedFieldPanel[]>();

  public locales: Locale[] = [];

  protected reordered = false;

  public flushing = false;

  protected _parentPath: AttributeFieldPath;

  protected deletedPaths: (string | null)[] = [];

  protected reorderPaths:
    | {
        old_path: string | null;
        new_path: string | null;
      }[]
    | undefined = undefined;

  constructor(
    attributeField: AttributeField<any>,
    fieldFunctions: FieldFunctions,
    protected _confirmGroup: string,
  ) {
    this._fieldFunctions = fieldFunctions;
    this._attributeField = attributeField;
    this._attributeFieldsRestService = new AttributeFieldsRestService();

    this.toastService = ToastService.getInstance();
    this.ts = new TranslationService('global');

    this.locales =
      (this.attributeField.children?.map((child) => child.locale).filter(Boolean) as Locale[]) ??
      [];
    this._parentPath = new AttributeFieldPath(this._attributeField.path);

    this.initTabs();
  }

  public getLocalesToRender(localeIds: number[]): Locale[] {
    return localeIds
      ? this.locales.filter((l) => l.value === 'global' || localeIds?.includes(l.id))
      : this.locales;
  }

  protected initTabs() {
    this.locales.forEach((locale) => {
      const tabs: AdvancedFieldPanel[] = [];

      const instances = this._attributeField.children?.find((child) => {
        return child.locale?.id === locale?.id;
      })?.instances;

      // If field is tab field -> values of the tabs
      const values = this.attributeField.values?.find((val) => val?.locale?.id === locale?.id);
      let headerIndex = 0;
      instances?.forEach((instance, index) => {
        const isTabDeleted =
          this.panels.get(locale?.id)?.[index]?.deleted ??
          !this._fieldFunctions.advancedFieldExists(
            this._attributeField.attribute.id,
            locale?.id,
            this._parentPath?.path,
            this._parentPath?.nextLevel()?.increaseLevel(index)?.path ?? null,
            this._attributeField.attribute.parent_id,
          );
        if (isTabDeleted && headerIndex !== 0) {
          headerIndex--;
        }

        const tab: AdvancedFieldPanel = {
          header:
            this.panels.get(locale?.id)?.filter((t) => !t.deleted)[index]?.header ??
            values?.value?.[headerIndex] ??
            '',
          attributes: instance ?? [],
          path: this._parentPath?.nextLevel()?.increaseLevel(index)?.path,
          deleted: isTabDeleted,
        };
        headerIndex++;
        tabs.push(tab);
      });

      this.panels.set(locale?.id, tabs);
    });
  }

  public async addNewPreset(localeId: number) {
    this.isLoadingPreset = true;
    try {
      const tabLength = this.panels.get(localeId)?.length ?? 0;

      const newPath = this._parentPath.nextLevel().increaseLevel(tabLength - 1);

      const response = await this._attributeFieldsRestService.getFieldPreset({
        attribute_id: this._attributeField.attribute.id,
        locales: this._attributeField.attribute.global_attribute
          ? [...(this._fieldFunctions.locales ?? [])]
          : [localeId],
        path: newPath.path,
      });

      this._attributeField.children
        ?.find((attr) => attr.locale?.id === localeId)
        ?.instances?.push(response.attributes);

      this._fieldFunctions?.handleDirtyState?.(
        this.attributeField,
        this.getPayloadObject(localeId),
        null,
        response.attributes,
      );

      this.initTabs();
    } catch (e) {
      this.toastService?.displayErrorToast(this.ts.t('errors.unexpectedError'));
    } finally {
      this.isLoadingPreset = false;
    }
  }

  protected handleReorderedAndDeletedPaths(localeId: number) {
    const tabs = this.panels.get(localeId) ?? [];

    if (this.reordered) {
      this.reorderPaths = [];
      const parentPath = new AttributeFieldPath(this._attributeField.path);

      if (tabs) {
        tabs.forEach((tab, index) => {
          const newPath = parentPath?.nextLevel()?.increaseLevel(index)?.path;
          this.reorderPaths?.push({
            old_path: tab.path,
            new_path: newPath,
          });
        });
      }
    }

    this.deletedPaths = tabs?.filter((tab) => tab.deleted).map((tab) => tab.path) ?? [];
  }

  protected getPayloadObject(localeId: number): UpdateProductField {
    return {
      attribute_id: this._attributeField.attribute.id,
      locale_id: localeId,
      delete_paths: this.deletedPaths,
      overwrite: false,
      value: null,
      path: this._attributeField.path,
      reorder_paths: this.reorderPaths,
    };
  }

  protected createPayload(localeId: number): UpdateProductField {
    this.handleReorderedAndDeletedPaths(localeId);
    return this.getPayloadObject(localeId);
  }

  protected updatePathRecursive(
    attributeField: AttributeField<any>,
    localeId: number,
    path: AttributeFieldPath,
  ): AttributeField<any> {
    attributeField.path = path?.path;

    const value: ProductAttributeField<any> | null =
      attributeField.values?.find((value) => value.locale.id === localeId) ?? null;

    if (value != null) {
      value.path = path?.path;
    }

    const productUpdateField: UpdateProductField = {
      attribute_id: attributeField.attribute.id,
      value: value?.value ?? null,
      locale_id: localeId,
      path: path?.path,
      overwrite: false,
    };

    this._fieldFunctions?.addToUpdatedFields?.(productUpdateField);
    this._fieldFunctions?.handleDirtyState?.(
      this.attributeField,
      this.createPayload(localeId),
      null,
    );

    if (attributeField.children) {
      let childPath = path?.nextLevel();

      attributeField.children = attributeField.children.map((child) => {
        if (child.instances) {
          child.instances = child.instances.map((instance) => {
            const newInstance = instance.map((attribute) => {
              return this.updatePathRecursive(attribute, localeId, childPath);
            });

            childPath = childPath?.increaseLevel();

            return newInstance;
          });
        }

        return child;
      });
    }

    return attributeField;
  }
}
