<script setup lang="ts">
//services
import { TranslationService } from '@/general/services/translations/translation.service';
import { DeveloperService } from '@/platforms/retailer/modules/developers/services/developers.service';
import { formatDate } from '@/general/utils/format-date';

/* Emits */
defineEmits<{
  (e: 'delete-key', id: number): void;
  (e: 'refresh-token', id: number): void;
}>();

/* Props */
defineProps<{
  developerService: Partial<DeveloperService>;
}>();

/* Services */
const ts = new TranslationService('retailer', 'developers');
</script>

<template>
  <div class="token-overview">
    <div class="flex gap-5 justify-content-between">
      <p class="text-600 w-8">
        {{ ts.tModule('overview_description') }}
      </p>
      <a href="https://api.worldofcontent.com/docs/" target="_blank"
        ><pButton outlined>{{ ts.tModule('guide') }}</pButton></a
      >
    </div>

    <div
      v-for="token in developerService.apiKey"
      :key="token.id"
      class="flex justify-content-center"
    >
      <div class="overview-card">
        <!-- Header -->
        <div class="align-items-center group-field header">
          <div class="font-bold text-800 text-lg">{{ token.name }}</div>
        </div>

        <!-- API key -->
        <div class="group-field">
          <div class="text-600">{{ ts.tModule('api_key') }}</div>

          <div class="text-800" :class="{ regenerating: developerService.regenerateLoading }">
            {{ developerService.hiddenKey?.(token.access_token) }}

            <!-- Eye Icon -->
            <span
              class="cursor-pointer ml-1 mr-2"
              @click="developerService.changeApiKeyVisibility?.()"
            >
              <i
                :class="
                  developerService.isApiKeyHidden
                    ? 'mdi mdi-eye-outline'
                    : 'mdi mdi-eye-off-outline'
                "
              />
            </span>

            <!-- Copy Icon -->
            <div
              v-tooltip.top="ts.tModule('copy_to_clipboard')"
              class="copy-button"
              @click="developerService.copyToClipboard?.(token.access_token)"
            >
              <i class="mdi mdi-content-copy" />
              <i
                v-if="developerService.isCopiedToClipboard"
                class="copy-confirmation mdi mdi-check"
              />
            </div>
          </div>
        </div>

        <!-- Creation Date -->
        <div class="group-field">
          <div class="text-600">{{ ts.tModule('created_on') }}</div>

          <div class="text-800">
            {{ formatDate(token.created_at) }}
          </div>
        </div>

        <!-- Expiration Date -->
        <div class="group-field">
          <div class="text-600">
            {{ ts.tModule('expires_on') }}
            <!-- Help icon (?) -->
            <span
              v-tooltip.top="{
                value: `${ts.tModule('expiration_guide')}`,
                escape: true,
              }"
            >
              <i class="mdi mdi-help-circle-outline" />
            </span>
          </div>

          <div class="text-800">
            {{ formatDate(token.expires_on) }}
          </div>
        </div>

        <!-- Action buttons -->
        <div class="actions group-field">
          <p-button
            icon="mdi mdi-delete-outline"
            :label="ts.tModule('delete_key')"
            text
            severity="danger"
            @click="developerService.confirmApiDelete?.(token.id)"
          />

          <p-button
            icon="mdi mdi-refresh"
            :label="ts.tModule('regenerate_api_key')"
            outlined
            @click="developerService.confirmApiGenerate?.(token.id)"
          />
        </div>
      </div>
    </div>
  </div>

  <p-confirm-dialog group="api" :style="{ width: '30vw' }" />
</template>
<style lang="scss">
.token-overview {
  .overview-card {
    border: 1px solid #dce5ef;
    border-radius: 3px;
    width: clamp(400px, 80%, 600px);
    margin-top: 5rem;
    background-color: white;
  }

  .group-field {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;

    &.header {
      border-bottom: 1px solid #dce5ef;
      padding: 22px 24px;
    }

    &.actions {
      padding-right: 15px;
      padding-left: 5px;
      border-top: 1px solid #dce5ef;
    }

    .status {
      width: 12px;
      height: 12px;
      border-radius: 50%;

      &.active {
        background-color: #2ecc71;
      }

      &.deactive {
        background-color: #e74c3c;
      }
    }
  }

  .p-button {
    padding: 0.5rem 1rem;
  }

  .p-button :deep(.p-button-icon) {
    font-size: 16px;
  }

  .copy-button {
    position: relative;
    display: inline;
    cursor: pointer;

    .copy-confirmation {
      position: absolute;
      right: -15px;
      color: #2ecc71;
    }
  }

  .regenerating {
    opacity: 1;
    animation: fade 0.6s ease-in-out infinite alternate;
  }
}

@keyframes fade {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
