<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import { LocaleDetails } from '../../locales/locales.types';
import { DefaultFieldService } from '../ts/services/default-fields.service';
import { Ref, computed, ref } from 'vue';
import { MultiSelectChangeEvent } from 'primevue/multiselect';

/** Props */
export interface Props {
  service: DefaultFieldService;
  locale: LocaleDetails;
}

const props = defineProps<Props>();

const ts = new TranslationService('supplier', 'datamodelsConfig');

const selectedOptions: Ref<string[]> = ref([]);
const selectAllOptions = computed<boolean>(
  () =>
    selectedOptions.value.length ===
    options.value.filter((option) => !(option?.disabled === true)).length,
);

const options = ref([
  {
    name: ts.tModule('configuration.default.existingCheckBox'),
    value: 'existingSettingBoxValue',
    disabled: props.service.defaultField(props.locale.id)?.isExistingDisabled,
  },
  { name: ts.tModule('configuration.default.emptyCheckBox'), value: 'emptySettingBoxValue' },
  { name: ts.tModule('configuration.default.editedCheckBox'), value: 'editedSettingsBoxValue' },
]);

const toggleAllSelected = () => {
  if (
    selectedOptions.value.length !==
    options.value.filter((option) => !(option?.disabled === true)).length
  ) {
    selectedOptions.value = options.value
      .filter((option) => !(option?.disabled === true))
      .map((option) => option.value);
  } else {
    selectedOptions.value = [];
  }

  props.service.setState(props.locale.id, selectedOptions.value);
};
</script>
<template>
  <pMultiselect
    v-model="selectedOptions"
    option-label="name"
    option-value="value"
    panel-class="p-2"
    class="h-3rem"
    :options="options"
    placeholder="Select fields"
    display="comma"
    :show-toggle-all="false"
    option-disabled="disabled"
    @change="
      (event: MultiSelectChangeEvent) => {
        service.setState(locale.id, event.value);
      }
    "
  >
    <template #header>
      <div class="flex m-4 mx-2">
        <i class="mdi mdi-information-outline mr-1" />
        <p>{{ ts.tModule('configuration.default.optionInformation') }}</p>
      </div>
      <div class="align-items-center flex ml-2 py-2">
        <pCheckbox
          v-model="selectAllOptions"
          input-id="allSetting"
          binary
          @change="toggleAllSelected"
        />
        <label for="allSetting" class="cursor-pointer ml-2">
          {{ ts.tModule('configuration.default.allCheckBox') }}</label
        >
      </div>
    </template>
    <template #option="slotProps">
      <div class="align-items-center flex">
        <div>{{ slotProps.option.name }}</div>
      </div>
    </template>
  </pMultiselect>
</template>
