<script lang="ts" setup>
import { Upload } from 'platform-unit2-api/uploads';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import AssetsList from '../assets/components/assets-list.vue';
import { useService } from '@/general/composables/useService';
import { assetViewServiceKey } from '../assets/keys/asset-view-service.key';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';
import { FileUploadUploaderEvent } from 'primevue/fileupload';

interface Props {
  multiple: boolean;
  selectedIds?: number[];
  acceptFiles?: string;
  disabled?: boolean;
  colSize?: number;
  checkedAssets?: Upload[];
}
const props = withDefaults(defineProps<Props>(), {
  selectedIds: () => [],
  acceptFiles: '*',
  disabled: true,
  colSize: 2,
  checkedAssets: () => [],
});

const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'choose-image'): void;
  (e: 'choose-multiple-images', images: Upload[]): void;
}>();

const ts = new TranslationService('supplier', 'media');
const service = useService(assetViewServiceKey, AssetViewService);
const visible = ref(false);

const setImages = (): void => {
  emit('choose-multiple-images', service.checkedAssets);
  service.checkedAssets = [];
  service.page = 1;
  service.fetchAll();
  emit('hide');
};

const closeMediaPicker = (): void => {
  service.page = 1;
  service.fetchAll();
  emit('hide');
};

onBeforeUnmount(() => {
  service.disabled = false;
});

onMounted(() => {
  service.disabled = true;
  service.checkedAssets = props.checkedAssets;
  if (service.assets.length === 0) {
    service.fetchAll();
  }
});
</script>

<template>
  <pDrawer v-model:visible="visible" position="full" @hide="closeMediaPicker">
    <template #header>
      <div class="flex justify-content-between mr-3 w-full">
        <h2>{{ ts.tModule('media_picker.title') }}</h2>

        <div class="flex">
          <pInputText
            v-model="service.query"
            type="text"
            class="mr-3"
            :placeholder="ts.tModule('media_picker.search_placeholder')"
          />
          <pFileUpload
            mode="basic"
            choose-label="Upload"
            :accept-files="acceptFiles"
            :disabled="service.isUploading || service.isLoadingOverView"
            :multiple="true"
            :auto="true"
            :custom-upload="true"
            @uploader="(e: FileUploadUploaderEvent) => service.uploadAssets(e)"
          />
        </div>
      </div>
    </template>
    <div class="flex flex-column h-full">
      <CardSkeleton v-if="service.isLoadingOverView" />
      <AssetsList v-else />
      <div
        v-if="service.checkedAssets.length"
        class="align-items-center bg-white bottom-0 flex justify-content-between left-0 mt-5 p-3 shadow-3 sticky w-full z-5"
      >
        <p>
          {{
            ts.tModule('media_picker.image_selected', {
              params: { count: service.checkedAssets.length },
            })
          }}
        </p>
        <pButton :label="ts.tModule('media_picker.choose_image')" @click="setImages"></pButton>
      </div>
    </div>
  </pDrawer>
</template>
<style lang="scss" scoped>
:deep(.p-speeddial-action) {
  width: 3rem;
  height: 3rem;
}
</style>
