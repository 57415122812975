<script setup lang="ts">
import { ref, provide, Ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { tsKey } from '@/general/services/translations/translation.key';
import OverView from 'ui/views/over-view/over-view.vue';
import { TaskViewService } from '@/general/services/overview-service/modules/task-view.service';
import TaskTable from '../components/task-table.vue';
import { taskViewServiceKey } from '../ts/keys/task-view-service.key';
import OverviewSidebar from 'ui/components/over-view/overview-sidebar.vue';
import TaskEditForm from '../components/task-edit-form.vue';
import AddTaskModal from '../components/add-task-modal.vue';

const ts = ref(new TranslationService('supplier', 'tasks'));
const service = ref(new TaskViewService());

provide(taskViewServiceKey, service.value as TaskViewService);
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <OverView :has-search="false" platform="supplier" module-name="tasks" :service="service">
    <template #content>
      <TaskTable />
    </template>
    <template #crudComponent>
      <AddTaskModal />
      <OverviewSidebar v-if="service.crudComponent && !service.isCreating" :service="service">
        <template #form>
          <TaskEditForm />
        </template>
      </OverviewSidebar>
    </template>
    <template #others>
      <pConfirmPopup group="task-view" />
    </template>
  </OverView>
</template>
