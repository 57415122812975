<script setup lang="ts">
import { Datamodel } from 'platform-unit2-api/datamodels';
import { inject } from 'vue';
import { datamodelAttributeServiceKey } from '../ts/datamodel-attribute.keys';

interface Props {
  datamodel: Datamodel;
}
defineProps<Props>();

const service = inject(datamodelAttributeServiceKey)!;
</script>

<template>
  <span>
    {{
      service.ts.tModule('datamodel_attributes.taken_from_datamodel', {
        params: { name: datamodel.name },
      })
    }}
  </span>
</template>
