import { AttributeFieldService } from 'services/attribute-fields/attribute-field.service';
import { AttributeField, ProductAttributeField } from 'platform-unit2-api/attribute-fields';

import { FieldFunctions } from '../interfaces/field-functions.interface';

export class ChoiceFieldService extends AttributeFieldService<string | undefined> {
  constructor(
    attributeField: AttributeField<string | undefined>,
    productAttributeField: ProductAttributeField<string | undefined>,
    fieldFunctions: FieldFunctions,
  ) {
    super(attributeField, productAttributeField, fieldFunctions);
    this.validate();
  }
}
