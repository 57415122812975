import { ApplicationTaskService } from '../application.task.service';
import { installRouter } from '@/core/main/router.install';

export class InstallRouterTask extends ApplicationTaskService {
  public async handle(): Promise<void> {
    await installRouter();

    this._complete();
  }

  constructor() {
    super('getMe');
  }
}
