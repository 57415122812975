<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Product } from 'platform-unit2-api/products';
import { Attribute } from 'platform-unit2-api/attributes';
import { Datamodel } from 'platform-unit2-api/datamodels';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';

/**Props */
interface Props {
  isActive?: boolean;
  productId: number;
}
withDefaults(defineProps<Props>(), {
  isActive: false,
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'saved', overrides: { attributes: number[]; variants: number[] }): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

/**Consts */
const store = useStore();
const selectedVariants = ref<number[]>([]);
const selectedFields = ref<number[]>([]);

const reset = () => {
  selectedVariants.value = [];
  selectedFields.value = [];
};

const allFieldsSelected = computed(() => {
  if (getAllFieldDefinitions.value.length === selectedFields.value.length) return true;
  else return false;
});

const allVariantsSelected = computed(() => {
  // currentProductVariants -1 the master data
  if (currentProductVariants.value.length - 1 === selectedVariants.value.length) return true;
  else return false;
});

const selectAllVariants = (event: boolean) => {
  if (!event) {
    selectedVariants.value = [];
  } else {
    selectedVariants.value = store.getters['products/currentProductVariants']
      .filter((item: Product) => item.variant_name != null)
      .map((item: Product) => item.id);
  }
};

const submit = async () => {
  emit('saved', { attributes: selectedFields.value, variants: selectedVariants.value });
  reset();
  emit('hide');
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const saveButton = new CreateButton({
  label: ts.tGlobal('save'),
  position: CrudButtonPosition.RIGHT,
  onClick: submit,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, saveButton],
});

const currentProductVariants = computed(() => {
  return store.getters['products/currentProductVariants'];
});

// Recursivelly check if a child has been updated
function hasChildWithAttribute(attribute: Attribute, attributeId: number) {
  if (attribute.id === attributeId) {
    return true;
  }

  if (attribute.children) {
    for (const child of attribute.children) {
      if (hasChildWithAttribute(child, attributeId)) {
        return true;
      }
    }
  }

  return false;
}

const getAllFieldDefinitions = computed(() => {
  let fields: Attribute[] = [];
  store.getters['products/currentProductDatamodels'].data.forEach((datamodel: Datamodel) => {
    datamodel.attributes?.forEach((field: Attribute) => {
      fields.push(field);
    });
  });

  fields = fields.filter((attribute: Attribute) => {
    let result = false;

    productAttributeFieldService.value.updatedFields.forEach((field) => {
      if (
        field.attribute_id === attribute.id ||
        hasChildWithAttribute(attribute, field.attribute_id)
      ) {
        result = true;
      }
    });

    return result;
  });

  return [...new Map(fields.map((item: Attribute) => [item.id, item])).values()];
});

const selectAllFields = (value: boolean) => {
  selectedFields.value = value ? getAllFieldDefinitions.value.map((item) => item.id) : [];
};
</script>
<template>
  <BaseDialog
    :title="ts.tModule('save.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="emit('hide')"
  >
    <p-message severity="info" class="mb-5 text-lg">
      {{ ts.tModule('save.description') }}
    </p-message>
    <div class="flex">
      <div class="col-6 flex-column">
        <div class="field-checkbox mb-3">
          <p-checkbox v-model="allFieldsSelected" :binary="true" @input="selectAllFields($event)" />
          <label class="font-bold">{{ ts.tModule('save.fields') }}</label>
        </div>
        <div>
          <template v-for="attribute in getAllFieldDefinitions" :key="attribute.id">
            <div class="field-checkbox mb-1">
              <p-checkbox v-model="selectedFields" :value="attribute.id" />
              <label>{{ attribute.key }}</label>
            </div>
          </template>
        </div>
      </div>
      <div class="col-6 flex-column">
        <div class="field-checkbox mb-3">
          <p-checkbox
            v-model="allVariantsSelected"
            :binary="true"
            @input="selectAllVariants($event)"
          />
          <label class="font-bold">{{ ts.tModule('save.variants') }}</label>
        </div>
        <div>
          <template v-for="variant in currentProductVariants" :key="variant.id">
            <div v-if="variant.variant_name != null" class="field-checkbox mb-1">
              <p-checkbox v-model="selectedVariants" :value="variant.id" />
              <label>{{ variant.variant_name }}</label>
            </div>
          </template>
        </div>
      </div>
    </div>
  </BaseDialog>
</template>
