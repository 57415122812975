import { Locale, LocaleDetails } from 'platform-unit2-api/locales';
import tags from 'language-tags';

export function getLocaleDetails(locale: Locale): LocaleDetails {
  const language = tags(locale.value).language()?.descriptions();
  const region = tags(locale.value).region()?.descriptions();
  const format = tags(locale.value).region()?.format();
  return {
    ...locale,
    language: language?.[0],
    region: region?.[0],
    format: format?.toLowerCase(),
  };
}
