<script setup lang="ts">
import BaseSelect from './base-select.vue';
import { Category, CategoryCompact, CategoryRestService } from 'platform-unit2-api/categories';
import { DefaultSelectPropsWithCompact } from './ts/default-select-props.interface';

const props = defineProps<DefaultSelectPropsWithCompact<Category, CategoryCompact>>();
const categoryRestService = CategoryRestService;
</script>
<template>
  <BaseSelect
    :rest-service="categoryRestService"
    fetch-function-name="getQueuedCategory"
    option-label="name"
    class="w-full"
    display="chip"
    module-name="category"
    v-bind="{ ...$attrs, ...props }"
  />
</template>
